import React from "react";
import { Frames, CardFrame } from "frames-react";
import { t } from "i18next";

const REACT_APP_CHECKOUT_PUBLIC_KEY = process.env.REACT_APP_CHECKOUT_PUBLIC_KEY;
const CheckoutContainer = ({ capturePayment }) => {
  return (
    <div className="mx-1 375:mx-2 px-2 pt-6  bg-gray-100 shadow-md">
      <span className="flex items-center py-2 bg-white border border-gray-300 rounded-sm ">
        <CheckoutDotCom capturePayment={capturePayment} />
      </span>
      <div className="flex justify-between items-center py-4">
        <div className="flex items-center">
          <span className="text-12x">{t("Powered by")}</span>
          <img
            alt="Checkout.com"
            src="/images/checkout.png"
            className="inline-block w-24 ms-2"
          />
        </div>
        <div className="text-green-500 font-semibold items-center h-3.5 hidden 375:!flex">
          <svg
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            className="w-3.5 h-3.5 text-green-500 inline-block me-1"
          >
            <path d="M75.5 39.1h-.8v-9.3C74.7 16.1 63.6 5 50 5S25.3 16.1 25.3 29.7V39h-.8c-5.2 0-9.5 4.2-9.5 9.5v37c0 5.2 4.2 9.5 9.5 9.5h51c5.2 0 9.5-4.2 9.5-9.5v-37c0-5.2-4.3-9.4-9.5-9.4zm-18 41.4c.3 1.6-.9 3-2.5 3H45c-1.6 0-2.8-1.4-2.5-3l2.7-15.1c-2-1.5-3.3-3.9-3.3-6.6 0-4.5 3.7-8.2 8.2-8.2s8.2 3.7 8.2 8.2c0 2.7-1.3 5.1-3.3 6.6l2.5 15.1zm7.2-41.4H35.3v-9.3c0-8.1 6.6-14.7 14.7-14.7s14.7 6.6 14.7 14.7v9.3z"></path>
          </svg>
          <span className="text-12x">{t("256-bit encryption")}</span>
        </div>
      </div>
    </div>
  );
};

const CheckoutDotCom = ({ capturePayment }) => {
  return (
    <>
      <Frames
        config={{
          publicKey: REACT_APP_CHECKOUT_PUBLIC_KEY,
          localization: {
            cardNumberPlaceholder: t("cardNumber"),
            expiryMonthPlaceholder: t("MM"),
            expiryYearPlaceholder: t("YY"),
            cvvPlaceholder: t("CVV"),
          },
          style: {
            base: {
              color: "black",
              fontSize: "14px",
            },
            valid: {
              color: "green",
            },
            invalid: {
              color: "red",
            },
          },
        }}
        cardTokenized={(e) => capturePayment(e.token)}
      >
        <CardFrame />
      </Frames>
    </>
  );
};

export default CheckoutContainer;
