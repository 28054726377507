import React from "react";
import VerificationInput from "./VerificationInput";
import { t } from "i18next";

const Form = (props) => {
  const {
    phoneNumber,
    verificationCode,
    handleChange,
    seconds,
    error,
    resendCode,
    phoneCountryObj,
  } = props;

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <h4 className="pb-5 text-26x text-outer-space font-bold leading-24x">
        {t("Verify your WhatsApp")}
      </h4>
      <p className="text-15x leading-22x text-outer-space">
        {t("We've sent you a message with verification code to")}
        <span dir="ltr" className="l-d mx-1">
          +{phoneCountryObj && phoneCountryObj.dialCode}{" "}
          {phoneNumber && phoneNumber.replace(/^0+/, "")}
        </span>
      </p>
      <div className="py-6 l-d">
        <div className="text-center">
          <VerificationInput
            id={verificationCode}
            value={verificationCode}
            handleChange={handleChange}
          />
        </div>

        {error && (
          <div className="text-red-500 text-center mt-2">
            {typeof error === "string"
              ? error
              : error.message
              ? error.message
              : t("Something was incorrect.")}
          </div>
        )}
      </div>
      {seconds > 0 ? (
        <div className="text-center">
          <span className=" text-sm  text-15x ">
            {t("I haven't received the code")} • {seconds} {t("sec")}
          </span>
        </div>
      ) : (
        <div className="text-center cursor-pointer" onClick={resendCode}>
          <span className=" text-sm underline text-15x text-brand-base-primary cursor-pointer">
            {t("I haven't received the code")}
          </span>
        </div>
      )}
    </form>
  );
};

export default Form;
