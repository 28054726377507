import React from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";

import ItemFooter from "components/item/ItemFooter";
import useOpenSavedAddressListModal from "service-hooks/useOpenSavedAddressListModal";

import { useLang } from "hooks";

import C from "Constants";
import { isItemOutOfStock } from "utils/Utils";

const RenderFooter = ({
  goToHomePage,
  addSelectedToBasket,
  singleItem,
  getBasketValue,
}) => {
  const { currentLang } = useLang();
  const { orderType } = useSelector((state) => state.AppReducer);
  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state.store
  );
  const { selectedItem } = useSelector((state) => state?.item);
  const { openAddressListModal } = useOpenSavedAddressListModal();

  return (
    <>
      {!selectedStore ||
      (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
        !outletBasedSelectedStoreArea) ? (
        <ItemFooter
          onButtonClick={openAddressListModal}
          btnText1={
            orderType?.serviceType === C.SERVICE_TYPE.DELIVERY
              ? t("Select your location")
              : t("Select store")
          }
        />
      ) : isItemOutOfStock(selectedItem?.itemInfo, selectedStore) ? (
        <ItemFooter
          onButtonClick={goToHomePage}
          {...{ selectedItem }}
          btnText1={t("continue shopping")}
        />
      ) : (
        <ItemFooter
          onButtonClick={addSelectedToBasket}
          btnText1={`${t("Add")} ${singleItem?.quantity ?? 1} ${t(
            "to basket"
          )}`}
          btnText2={`${
            currentLang === C.AR
              ? getBasketValue() + " " + t("AED")
              : t("AED") + " " + getBasketValue()
          }`}
          {...{ selectedItem }}
        />
      )}
    </>
  );
};

export default RenderFooter;
