import React, { useEffect } from "react";
import { t } from "i18next";

function VersionUpdate({ heading, message, btnText, onClick }) {
  useEffect(() => {
    sessionStorage.setItem("APP-version", process.env.REACT_APP_VERSION);
  }, []);

  return (
    <div className="flex  min-h-scrn px-4 relative mx-auto my-auto bg-gray-300 min-h-screen items-center justify-center">
      <div className="p-6 md:max-w-lg  bg-white ">
        <div className="">
          <div className="p-5 flex-auto justify-center">
            <h2 className="text-16px text-blue-600 font-bold pt-4 pb-2 text-center">
              {t(heading)}
            </h2>

            <p className="text-sm text-gray-500 px-8  text-center">
              {t(message)}
            </p>
          </div>
          <div className="pb-5 text-center md:block">
            <button
              className="bg-blue-500 p-2 rounded text-white"
              type="primary"
              size="large"
              onClick={onClick}
            >
              {t(btnText)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VersionUpdate;
