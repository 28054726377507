import React from "react";
import moment from "moment";
import { t } from "i18next";
import SmallBrandHeader from "components/layouts/SmallBrandHeader";
import CheckoutDotCom from "components/payment-gateway/CheckoutDotCom";
import Card from "components/Card";
import ButtonSpinner from "components/ButtonSpinner";
import { useLang } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import StripeForm from "components/payment-gateway/stripe/StripeForm";
import { logPopupError } from "utils/Utils";
import C from "Constants";
import StripeHandler from "../../StripeHandler";
import { useDetectBrowserWallets } from "hooks";
import SupportedWalletList from "components/checkout/SupportedWalletList";
import { PaymentLinkAction } from "store/actions";
import { SVGICONS } from "config";
import { PlusIconn } from "icons";
import WalletPay from "modules/payment-links/WalletPay";
import useScrollToBottomPaymentWidget from "hooks/useScrollToBottomPaymentWidget";
function VerfiedLinkUi({
  paymentLinkInfo,
  captureCheckoutDotComPayment,
  payNow,
  cardProcessing,
  setcardProcessing,
  fetchPaymentLinkInfo,
}) {
  const dispatch = useDispatch();
  const language = useLang();
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentLinkReducer
  );
  const scrollToBottom = useScrollToBottomPaymentWidget(selectedPaymentType);
  const { initiateStripePayment } = StripeHandler(
    setcardProcessing,
    paymentLinkInfo,
    fetchPaymentLinkInfo
  );

  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  const supportedBrowserWallets = useDetectBrowserWallets();
  const initiatePayment = async () => {
    if (!selectedPaymentType)
      return logPopupError("Please select payment method", true);
    try {
      setcardProcessing(true);
      if (paymentLinkInfo?.paymentGateway === C.STRIPE) {
        await initiateStripePayment(
          setcardProcessing,
          paymentLinkInfo,
          fetchPaymentLinkInfo
        );
      } else if (paymentLinkInfo?.paymentGateway === C.CHECKOUT_DOT_COM) {
        payNow();
      } else {
        logPopupError("Invalid Payment Gateway", true);
      }
    } catch (error) {
      setcardProcessing(false);
      logPopupError(error.message || t(C.DEFAULT_ERROR_MSG), true);
    }
  };

  const handleClick = (a, type) =>
    dispatch(PaymentLinkAction.setSelectedPaymentType(type));

  return (
    <div className="mx-1 375:mx-4">
      <SmallBrandHeader
        logoLink={brandInfo?.setting?.brandLogoFirst}
        text={brandInfo?.brand?.brandName}
      />
      <Card>
        <div className="flex justify-between mx-4 border-b py-4  text-gunsmoke text-15x">
          <div>#{paymentLinkInfo?.cartId}</div>
          <div>
            {moment
              .unix(paymentLinkInfo?.linkCreationDate)
              .format("DD-MM-YYYY")}
          </div>
        </div>
        <div className="py-4">
          <div className="mx-4 ">
            <h2 className="text-24x font-bold">
              {paymentLinkInfo?.amount.toLocaleString(language?.currentLang, {
                style: "currency",
                currency: "AED",
              })}
            </h2>
            <p>{t("Tax included")}</p>
            <div className="py-4 text-15x">
              <p className="text-gray-800 font-medium">
                {t("Purpose of payment")}
              </p>
              <p>{paymentLinkInfo?.purposeOfPayment}</p>
            </div>

            <p className="text-gray-800 text-15x font-medium">{t("Message")}</p>
            <p className="text-15x mb-6">{paymentLinkInfo?.message}</p>
          </div>
          {paymentLinkInfo?.paymentGateway === "CHECKOUTDOTCOM" ? (
            <CheckoutDotCom
              capturePayment={(token) => captureCheckoutDotComPayment(token)}
            />
          ) : (
            <>
              <>
                <SupportedWalletList
                  {...{
                    supportedBrowserWallets,
                  }}
                  handleClick={handleClick}
                  selectedPayment={{
                    paymentType: "CREDIT_CARD",
                  }}
                  selectedPaymentType={selectedPaymentType}
                />

                <div
                  className="p4 mb-4 border border-gray-200 mx-4 rounded-md px-4 py-3"
                  onClick={() => {
                    handleClick(null, C.WALLET.NEW_CARD);
                    scrollToBottom();
                  }}
                >
                  <div className="flex justify-between">
                    <div className="text-16x font-normal">
                      <span className="me-3">
                        <PlusIconn />
                      </span>
                      {t("Pay with new card")}
                    </div>
                    <div className="flex">
                      <span className="inline-flex w-6 h-6">
                        <input
                          type="radio"
                          name="Select Type"
                          className="hidden"
                        />
                        <span className="w-6 h-6 inline-flex ">
                          {selectedPaymentType === C.WALLET.NEW_CARD ? (
                            <SVGICONS.RadioCheck />
                          ) : (
                            <SVGICONS.RadioUnCheck />
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </>
              {selectedPaymentType === "new_card" && <StripeForm />}
            </>
          )}

          <WalletPay
            paymentLinkInfo={paymentLinkInfo}
            setcardProcessing={setcardProcessing}
            fetchPaymentLinkInfo={fetchPaymentLinkInfo}
            cardProcessing={cardProcessing}
          >
            <div className="mx-4 ">
              <button
                onClick={initiatePayment}
                className="mt-6 bg-brand-base-primary w-full py-3 rounded px-6 text-white text-17x font-bold"
              >
                {cardProcessing && (
                  <ButtonSpinner
                    height="30px"
                    width="30px"
                    color="text-white mx-1 inline-block"
                  />
                )}
                <span className="font-bold text-17x">
                  {cardProcessing
                    ? t("Payment processing…")
                    : t("continue to payment")}
                </span>
              </button>
            </div>
          </WalletPay>
        </div>
      </Card>
    </div>
  );
}

export default VerfiedLinkUi;
