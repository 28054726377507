import CustomModal from "components/CustomModal";
import React from "react";
import { StoreListForPickup } from "components/store/StoreList";
import { NotPersistAction } from "store/actions";
import { useLang } from "hooks";
import { useSelector } from "react-redux";

function PickupStoresModal() {
  const { pickupStoreModal } = useSelector((state) => state.NotPersist);
  const { currentLang } = useLang();
  const { stores } = useSelector((state) => state?.brand);

  return (
    <>
      <CustomModal
        shouldCloseOnEsc="No"
        shouldCloseOnOverlayClick="No"
        open={pickupStoreModal}
        reduxAction={NotPersistAction.togglePickupStoreModal(false)}
        bodyclasses="flex"
        overlayClasses={`fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30 ${
          currentLang === "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <StoreListForPickup
          stores={stores}
          header={false}
          headerLabel=""
          styleClasses={`fixed bottom-0 h-5 bg-white min-h-70p max-460 o111verflow-y-auto rounded-t-lg min-h-60p  ${
            currentLang === "AR" ? "border-sc-l" : "border-sc"
          }`}
        />
      </CustomModal>
    </>
  );
}

export default PickupStoresModal;
