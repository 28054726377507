import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { t } from "i18next";

//components
import CategoriesList from "components/menu/CategoriesList";
import SelectDeliveryArea from "components/outlet-based-areas/SelectDeliveryArea";
import ViewBasket from "components/basket/ViewBasket";
import { CustomModal, FixedFooter } from "components";
import WithLoading from "components/Hoc/WithLoading";
import { StoreListForDelivery } from "components/store/StoreList";
import Footer from "components/Footer";
import Search from "components/Search";
//hooks
import { useLang, useCustomHistory } from "hooks";
//constants
import * as ROUTE_CONSTANTS from "routes/constants";
import { NotPersistAction } from "store/actions";
import { StoreListForPickup } from "components/store/StoreList";

const MenuContainer = ({ menu, children, searchTerm, setSearch }) => {
  //hooks
  const params = useParams();
  const { currentLang } = useLang();
  const router = useCustomHistory();
  //local state
  const [inputFocus, setInputFocus] = useState(false);
  //global state
  const { areasError, stores } = useSelector((state) => state.brand);
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  const { storeModal, areaModal, pickupStoreModal } = useSelector(
    (state) => state.NotPersist
  );

  // Search store
  const handleSearch = (e) => {
    setSearch(e.target?.value?.toLowerCase());
  };

  //view basket page
  const viewBasketPage = () => {
    let url = ROUTE_CONSTANTS.OUTLET_BASKET[1];
    router(url, `/${params?.domainName}${url}`, DomainType);
  };
  useEffect(() => {
    searchTerm && setSearch("");
  }, [orderType?.serviceType]);

  return (
    <WithLoading>
      <div className="sticky top-0 z-10 px-4 bg-white">
        <Search
          label={t("Search for menu items")}
          onChange={handleSearch}
          value={searchTerm}
          setInputFocus={setInputFocus}
        />
        {searchTerm?.length <= 0 && <CategoriesList categoriesList={menu} />}
      </div>
      {menu?.length === 0 && inputFocus ? (
        <div className="h-app-height">
          {searchTerm?.length > 0 && (
            <h2 className="text-center mt-4 font-semibold">
              {t("We didn’t find any results for", {
                searchTerm: searchTerm,
              })}
            </h2>
          )}
        </div>
      ) : (
        children
      )}

      <Footer />
      <FixedFooter basketFooter={true} page={true} from="menu">
        <ViewBasket {...{ viewBasketPage }} />
      </FixedFooter>

      <CustomModal
        open={storeModal}
        reduxAction={NotPersistAction.setModalClose()}
        bodyclasses="flex"
        overlayClasses="fixed ccc h-app-height w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30"
      >
        <StoreListForDelivery
          updateTemporaryLocation={() => {}}
          shouldCloseOnEsc="No"
          shouldCloseOnOverlayClick="No"
          stores={stores}
          header={false}
          headerLabel=""
          styleClasses={`fixed bottom-0 h-5 bg-white min-h-70p max-460 o1111verflow-y-auto rounded-t-lg min-h-60p ${
            currentLang === "AR" ? "border-sc-l" : "border-sc"
          }`}
        />
      </CustomModal>
      <CustomModal
        open={areaModal}
        reduxAction={NotPersistAction.toggleAreaModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30"
      >
        <SelectDeliveryArea
          header={false}
          error={areasError}
          headerLabel=""
          styleClasses={`fixed bottom-0 h-5 bg-white min-h-70p max-460  rounded-t-lg min-h-60p ${
            currentLang === "AR" ? "border-sc-l" : "border-sc"
          }`}
        />
      </CustomModal>
      <CustomModal
        shouldCloseOnEsc="No"
        shouldCloseOnOverlayClick="No"
        open={pickupStoreModal}
        reduxAction={NotPersistAction.togglePickupStoreModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-20"
      >
        <StoreListForPickup
          stores={stores}
          header={false}
          headerLabel=""
          styleClasses="fixed bottom-0 h-5 bg-white min-h-70p max-460 oooooverflow-y-auto rounded-t-lg min-h-60p  border-sc"
        />
      </CustomModal>
    </WithLoading>
  );
};
export default MenuContainer;
