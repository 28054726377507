import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

import { logPopupError } from "utils/Utils";
import { handleLocalStorage } from "utils/storage";
import { DeliveryAddressAction } from "store/actions";
import { useDeliveryAddressContext } from "contexts/userAddressess";
import { useGetUserAddressListMutation } from "store/deliveryAddress/delivery-address-service";

import C from "Constants";
import { UTILS } from "utils";
import { errorHandler } from "apiHelpers/api/errorHandler";

function useGetSavedAddressList({ fetch, autoSelectAddress = false }) {
  const dispatch = useDispatch();

  const { setAddresses } = useDeliveryAddressContext();
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const { orderType, shippingMethod } = useSelector(
    (state) => state.AppReducer
  );
  const { userdeliveryAdress } = useSelector(
    (state) => state.DeliveryAddressReducer
  );
  const [getUserAddressList] = useGetUserAddressListMutation();
  const { outletBasedSelectedStoreArea, selectedStore } = useSelector(
    (state) => state.store
  );

  // Get saved Address List
  async function getAddresses(addedAddressId, type) {
    try {
      // dispatch(Loader.togggleCheckoutLoader(true));
      const currentUser = UTILS.getCurrentUser(brandInfo?.brand?._id);
      if (!currentUser?.token) return null;
      let params = getParams();
      if (!params) return null;
      if (type === "all") {
        params = {
          deliveryMethod: shippingMethod,
        };
      }

      // Construct payload for address request
      const payload = {
        ...params,
        ...((addedAddressId || userdeliveryAdress?._id) && {
          selectedUserAddressId: addedAddressId ?? userdeliveryAdress._id,
        }),
      };
      const response = await getUserAddressList(payload);
      const success_response = handleAddressResponse(response);
      return success_response;
    } catch (error) {
      handleAddressError(error);
      return null;
    } finally {
      // dispatch(Loader.togggleCheckoutLoader(false));
    }
  }

  // Function to handle address fetching response
  const handleAddressResponse = (response) => {
    if (response?.data?.success === 1 && response?.data?.data?.userAddresses) {
      setAddresses(response?.data?.data?.userAddresses);
      if (
        !userdeliveryAdress &&
        response?.data?.data?.userAddresses?.length >= 1 &&
        autoSelectAddress
      ) {
        setAddress(response?.data?.data?.userAddresses[0]);
        return response?.data?.data?.userAddresses;
      } else return response?.data?.data?.userAddresses?.length >= 1;
    } else {
      handleAddressError(response);
      return null;
    }
  };

  // Function to handle address fetching error
  const handleAddressError = (error) => {
    dispatch(DeliveryAddressAction.setDeliveryAddress(null));
    logPopupError(errorHandler(error?.error), true);
  };

  const getParams = () => {
    if (!shippingMethod) {
      logPopupError(
        t(
          "There is no delivery method available in store, please contact the business"
        ),
        true
      );
      return false;
    }

    if (shippingMethod === C.SHIPPING_METHODS.MAPPED_AREA_BASED) {
      const objectParams = {
        deliveryMethod: shippingMethod,
        ...(outletBasedSelectedStoreArea?.mappedAreaType === C.DISTANCE && {
          distance: outletBasedSelectedStoreArea?.distance,
        }),

        ...(selectedStore?.storeLocation?.coordinates?.[0] && {
          storeLocation: {
            lat: selectedStore?.storeLocation?.coordinates?.[0],
            lng: selectedStore?.storeLocation?.coordinates?.[1],
          },
        }),
        ...(outletBasedSelectedStoreArea?.mappedAreaInfo?.mappedAreaId?._id && {
          mappedAreaId:
            outletBasedSelectedStoreArea?.mappedAreaInfo?.mappedAreaId?._id,
        }),
      };
      return objectParams;
    } else if (
      shippingMethod === C.SHIPPING_METHODS.AREA_BASED &&
      outletBasedSelectedStoreArea?.areaID?._id
    ) {
      const objectParams = {
        deliveryMethod: shippingMethod,
        areaId: outletBasedSelectedStoreArea?.areaID?._id,
      };

      return objectParams;
    } else return null;
  };

  useEffect(() => {
    const currentUser = UTILS.getCurrentUser(brandInfo?.brand?._id);
    const delserviceType = orderType?.serviceType === C.SERVICE_TYPE.DELIVERY;
    if (!fetch || !currentUser?.token || !delserviceType) return;

    const params = getParams();
    if (params) getAddresses();
  }, [
    outletBasedSelectedStoreArea?.areaID?._id,
    outletBasedSelectedStoreArea?.mappedAreaInfo?.mappedAreaId?._id,
  ]);

  // Set selected address as delivery address from saved Address List
  const setAddress = (address) => {
    dispatch(DeliveryAddressAction.setDeliveryAddress(address));
    handleLocalStorage.setItem("deliveryAddress", userdeliveryAdress);
    // setAddressState("savedAddressListModal", false);
  };
  useEffect(() => {
    if (userdeliveryAdress?.street) {
      handleLocalStorage.setItem("deliveryAddress", userdeliveryAdress);
    }
  }, [
    userdeliveryAdress?.coordinates?.lat,
    userdeliveryAdress?.coordinates?.lng,
    userdeliveryAdress?.street,
  ]);

  return {
    getAddresses,
    setAddress,
  };
}

export default useGetSavedAddressList;
