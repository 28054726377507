import axios from "axios";
import store from "store/store";
import { UTILS } from "utils";
import C from "Constants";
import { NotPersistAction } from "store/actions";
import { logErrorOnSentry } from "utils/Utils";

// Create axios client, pre-configured with baseURL
let APIKit = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 15000,
});

// Set JSON Web Token in Client to be included in all calls before calling an APi
export const setClientToken = (token) => {
  APIKit.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

const AppActions = (data) => {
  const ActionArray = data?.reduce((acc, file) => {
    let check = file?.actions?.find((action) => action?.refresh === true);
    if (check) {
      acc.push(check);
    }
    return acc;
  }, []);
  return ActionArray;
};

APIKit.interceptors.request.use(function (config) {
  config.params = {
    app_version: process.env.REACT_APP_VERSION,
    app_name: C.WEB_STORE_FRONT,
  };
  const state = store.getState();
  const brand = state?.brandStorageReducer.brandInfo;
  const currentUser = UTILS.getCurrentUser(brand?._id);

  if (currentUser?.token) {
    config.headers.Authorization = `${currentUser?.token}`;
  }
  if (process.env.REACT_APP_REFRRER) {
    config.params.referrer = process.env.REACT_APP_REFRRER;
  }

  return config;
});

APIKit.interceptors.response.use(
  function (response) {
    if (
      response?.data?.error_category === "new_version_enforced" &&
      response?.data?.success === 0 &&
      response?.data?.data
    ) {
      let RefreshActions = AppActions(response?.data?.data);
      if (RefreshActions?.length >= 1) {
        store.dispatch(NotPersistAction.refreshApp());
      }
    }
    return response;
  },

  function (error) {
    console.log("error-----", error);
    const code = error?.response?.status;
    const data = error?.response?.data;
    const originalRequest = error?.config?.url;

    logErrorOnSentry(
      `Error in Fetching Data: ${error} end-point->${originalRequest}  status->${code}`,
      "error",
      JSON.stringify(data)
    );
    if (
      error?.code === C.ERRORS.ECONNABORTED ||
      error?.code === C.ERRORS.ETIMEDOUT
    ) {
      let errorMsg = `APIKit Error: Timeout Exceeded 15000Ms ${originalRequest}${code}`;
      logErrorOnSentry(
        `Api Timeout Exceeded 15000Ms ${errorMsg}`,
        "error",
        JSON.stringify(data)
      );

      setTimeout(() => {
        store.dispatch(NotPersistAction.setInternetOffline());
      }, 2000);
      return Promise.reject(data);
    }

    if (error?.toJSON().message === C.ERRORS.NETWORK_ERROR) {
      const errorMsg = `APIKit Error: Network Error ${originalRequest}${code}`;
      logErrorOnSentry(
        `User is not connected to network : ${errorMsg}`,
        "error",
        JSON.stringify(data)
      );
      setTimeout(() => {
        store.dispatch(NotPersistAction.setInternetOffline());
      }, 2000);
      return Promise.reject(data);
    } else {
      const errorMsg = `APIKit Error: ${error} end-point->${originalRequest} status->${code}`;
      logErrorOnSentry(
        `APIKit Error: ${errorMsg}`,
        "error",
        JSON.stringify(data)
      );
      return Promise.reject(data);
    }
  }
);

export async function get(url, config = {}) {
  store.dispatch(NotPersistAction.setInternetOnline());
  return await APIKit.get(url, { ...config }).then((response) => response.data);
}

export async function post(url, data, config = {}) {
  store.dispatch(NotPersistAction.setInternetOnline());
  let language = UTILS.getLanguage();
  let res = await APIKit.post(url, { ...data, language }, { ...config });
  return res.data;
}

export default APIKit;
