import React from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import CustomModal from "components/CustomModal";
import { ErrorModal } from "components";

function MenuDisabledModal({ handleRefresh }) {
  const { ismenuDisabled } = useSelector((state) => state.item);

  return (
    <>
      <CustomModal
        shouldCloseOnEsc="No"
        shouldCloseOnOverlayClick="No"
        open={ismenuDisabled}
        reduxAction={handleRefresh}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ErrorModal
          reduxAction={handleRefresh}
          message={t(
            "We apologize for the inconvenience. Our menu has just been updated. Please refresh the page to see the latest version"
          )}
          btnTitle={t("Refresh")}
        />
      </CustomModal>
    </>
  );
}

export default MenuDisabledModal;
