import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savedPaymentCards: [],
  selectedCard: {
    cardInfo: {},
    isStoredCard: false,
  },
  stripeSavedPaymentCards: [],
  stripeSelectedCard: {
    cardInfo: {},
    isStoredCard: false,
  },
};

const userSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setSavedPaymentCards: (state, action) => {
      state.savedPaymentCards = action.payload;
    },
    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },
    setstripePaymentCards: (state, action) => {
      state.stripeSavedPaymentCards = action.payload;
    },
    setstripeSelectedCard: (state, action) => {
      state.stripeSelectedCard = action.payload;
    },
    resetSelectedCards: (state, action) => {
      state.selectedCard = {
        cardInfo: {},
        isStoredCard: false,
      };
      state.stripeSelectedCard = {
        cardInfo: {},
        isStoredCard: false,
      };
    },
  },
});

export const {
  resetSelectedCards,
  setstripePaymentCards,
  setstripeSelectedCard,
  setSelectedCard,
  setSavedPaymentCards,
  setSelectedTab,
} = userSlice.actions;
export default userSlice.reducer;
