import React from "react";

const ButtonSpinner = ({ height, width, color }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        className={color}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-1.0416666666666665s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(30 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.9469696969696969s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(60 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.8522727272727272s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.7575757575757576s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(120 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.6628787878787878s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(150 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.5681818181818181s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.47348484848484845s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(210 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.3787878787878788s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(240 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.28409090909090906s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.1893939393939394s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(300 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="-0.0946969696969697s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(330 50 50)">
          <rect
            x="48.5"
            y="24"
            rx="1.5"
            ry="4.5600000000000005"
            width="3"
            height="12"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1363636363636365s"
              begin="0s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
      </svg>
    </>
  );
};

export default ButtonSpinner;
