import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SVGICONS } from "config";
import useGetStoresByAreaId from "service-hooks/useGetStoresByAreaId";
import * as Actions from "store/actions";

const MapAreaList = ({ area, lang, page }) => {
  const dispatch = useDispatch();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const getStoresByArea = useGetStoresByAreaId();
  const getStoresByAreaId = async (area) => {
    area && dispatch(Actions.DeliverAreaAction.setSelectedArea(area));
    if (brandInfo && area?.mappedAreaId) {
      await getStoresByArea(area.mappedAreaId, "map-area-list", page);
      dispatch(Actions.NotPersistAction.toggleAreaModal(false));
    }
  };

  return (
    <>
      {area?.areas?.map((area, key) => (
        <li
          onClick={() => {
            getStoresByAreaId(area);
          }}
          key={area?.mappedAreaId || key}
        >
          <span className="mx-4 py-3   text-16x flex items-center border-b border-gray-200">
            <SVGICONS.LocationIcon />
            <span className="mx-2 text-outer-space">
              {lang === "EN"
                ? area?.translations.en?.name
                : area?.translations.ar?.name}
            </span>
          </span>
        </li>
      ))}
    </>
  );
};

export default MapAreaList;
