import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FixedFooter from "components/FixedFooter";
import PageHeader from "components/PageHeader";
import TrackOrderFooter from "components/track-order/TrackOrderFooter";
import StepTracking from "components/track-order/StepTracking";
import TrackOrderHeader from "components/track-order/TrackOrderHeader";
import TrackOrderStatus from "components/track-order/TrackOrderStatus";
import { OrderAction } from "store/actions";
import { UTILS } from "utils";
import { Spinner } from "components";
import { ErrorComponent } from "components";
import C from "Constants";
import { useCustomHistory } from "hooks";
function TrackOrder(props) {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(1);
  const params = useParams();
  const history = useHistory();
  const { isLoading } = useSelector((state) => state?.loader);
  const { loading } = useSelector((state) => state?.brand);
  const { orderStatus, orderInfo, error } = useSelector((state) => state.order);
  const { DomainType } = useSelector((state) => state.AppReducer);
  const router = useCustomHistory();
  useEffect(() => {
    if (params?.orderId) {
      if (params?.domainName && DomainType === C.TEMPORARAY_DOMAIN) {
        const orderId = params.orderId;
        const obj = {
          orderId,
          history,
        };
        dispatch(OrderAction.getOrderStatus(obj));
      } else {
        const orderId = params.orderId;
        const obj = {
          orderId,
          history,
        };
        dispatch(OrderAction.getOrderStatus(obj));
      }
    }
  }, []);

  const getOrderStatus = (orderInfo) => {
    if (orderInfo) {
      const status = UTILS.getOrderStatus(orderInfo);
      if (status)
        return <StepTracking orderStatus={orderStatus} orderInfo={orderInfo} />;
      else
        return (
          <TrackOrderStatus orderStatus={orderStatus} orderInfo={orderInfo} />
        );
    }
  };
  const goBack = () => {
    router("/", `/${params?.domainName}`, DomainType);
  };

  if (isLoading || loading) return <Spinner />;
  return (
    <div className="create-address fixced top-0 left-0 h-screen bg-white">
      <PageHeader
        pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full shadow-sm relative"
        title={t("Track Your Order")}
        url={true}
        handleClick={goBack}
      />

      <div className="flex flex-col flex-grow text-15x">
        <div className="flex flex-col flex-grow h-full py-7 px-4">
          <div className="flex flex-col flex-grow h-full">
            <TrackOrderHeader
              orderStatus={orderStatus}
              orderInfo={orderInfo}
              status={status}
            />
            <hr className="my-4 border-gray-200" />
            {getOrderStatus(orderInfo)}
          </div>
        </div>
        {error && <ErrorComponent error={error} />}
        <TrackOrderFooter orderStatus={orderStatus} orderInfo={orderInfo} />
        <FixedFooter />
      </div>
    </div>
  );
}

export default TrackOrder;
