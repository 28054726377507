import React, { useEffect, useState } from "react";
import i18next from "i18next";

function useLang() {
  const [currentLang, setLang] = useState("EN");
  const getLanguage = () => i18next.language || window.localStorage.i18nextLng;
  useEffect(() => {
    const l = getLanguage();
    if (l) {
      let lang = l.toUpperCase();
      setLang(lang);
    }
  }, [getLanguage]);

  return {
    currentLang,
  };
}

export default useLang;
