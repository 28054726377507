import { useEffect } from "react";
import { UTILS } from "utils";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

function usePlatform() {
  const location = useLocation();
  const platform = queryString.parse(location.search);

  useEffect(() => {
    UTILS.storePlatFormInfo(platform);
  }, []);
}

export default usePlatform;
