import React from "react";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import CloseButton from "components/CloseButton";
import { Carousel } from "antd";
import { useLang, useWindowDimensions, useCustomHistory } from "hooks";
import C from "Constants";
import { UTILS } from "utils";
import { useSelector, useDispatch } from "react-redux";
import * as ROUTE_CONST from "routes/constants";
import useQueryParam from "modules/dine-in/useQueryParam";
import { ItemAction } from "store/actions";
function ItemHeader({ selectedItem, menuType, stripText, closeItemDetail }) {
  const params = useParams();
  const { currentLang } = useLang();
  const queryParam = useQueryParam();
  const dispatch = useDispatch();

  const { DomainType } = useSelector((state) => state.AppReducer);
  const router = useCustomHistory();
  const { width } = useWindowDimensions();
  let currentWidth = width >= 460 ? 458 : width;
  const onChange = (currentSlide) => {};
  const brandItemImage = (val, index, url, q = "") => {
    let state = {
      urls: selectedItem?.itemInfo?.itemImageURLs,
      val,
      index,
    };
    router(url, `/${params.domainName}${url}`, DomainType, state, q);
  };

  const showItem = (val, index) => {
    switch (menuType) {
      case C.OUTLET_BASED_CATALOGUE:
        brandItemImage(val, index, ROUTE_CONST.OUTLET_ITEM_IMAGE[1]);
        break;
      case C.BRAND_BASED_CATALOGUE:
        brandItemImage(val, index, ROUTE_CONST.ECOMMERCE_ITEM_IMAGE[1]);
        break;
      case C.PREVIEW:
        brandItemImage(val, index, "/preview/item-image");
        break;
      case "DINE_IN":
        brandItemImage(val, index, "/dine-in/item-image", queryParam);
        break;
      default:
        break;
    }
  };

  const DisplayPrice = () => {
    const price = selectedItem?.itemInfo?.price;
    const currency = t("AED");
    const formattedPrice = !UTILS.ifNullOrUndefined(price)
      ? `${
          currentLang === C.AR ? `${price} ${currency}` : `${currency}${price}`
        }`
      : "";
    return (
      <div className="mb-2">
        <span className="text-0.875">{formattedPrice}</span>
      </div>
    );
  };

  return (
    <>
      <div className="border-b-8 border-slate-100">
        <style>
          {`\
        .wrapImageSetting{\
          line-height:${currentWidth}px;\
          height:${currentWidth}px;\
          display:block;
        }\
        .slider-container{\
          height:${currentWidth}px;
        }\
      `}
        </style>
        <div className="w-full">
          <div className="absolute z-10">
            <CloseButton
              handleClick={() => {
                closeItemDetail();
                dispatch(ItemAction.clearItem());
              }}
            />
          </div>
          <Carousel
            autoplay={false}
            style={{ height: currentWidth + "px" }}
            className="w-full bg-cover cars-slider slider-container"
            afterChange={onChange}
          >
            {selectedItem?.itemInfo?.itemImageURLs?.length > 0 ? (
              selectedItem?.itemInfo?.itemImageURLs?.map((val, index) => {
                return (
                  <div key={index} onClick={() => showItem(val, index)}>
                    <div className="text-center preview-panel_image wrapImageSetting">
                      <img src={val} alt="items" />
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <div className="text-center preview-panel_image wrapImageSetting">
                  <img src={C.PLACEHODERIMAGE} alt="item" />
                </div>
              </div>
            )}
          </Carousel>
        </div>

        <div className="py-5 px-4 border-b-10 border-black-100">
          {stripText && (
            <div className="flex justify-center mt-8">
              <div className="text-white bg-red-700 px-2 py-1 text-11x">
                {stripText}
              </div>
            </div>
          )}
          <h4 className="text-1.5 leading-normal font-black mt-6 mb-2">
            {selectedItem?.itemInfo?.baseLanguage?.title}
          </h4>
          <DisplayPrice />
          <p className="leading-normal text-16x overflow-wrap-anywhere">
            {selectedItem?.itemInfo?.baseLanguage?.description}
          </p>
        </div>
      </div>
    </>
  );
}

export default ItemHeader;
