import { createAction } from "@reduxjs/toolkit";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { PaymentCardsAction } from "store/actions";
import { StoreAction } from "store/actions";
import { PaymentMethodAction } from "store/actions";
import { AppAction } from "store/actions";
import C from "Constants";
import { orderTimingAction } from "store/actions";

function useChangeOrderType({
  setConfirmModal,
  setConfirmModalTitle,
  setConfirmationCallback,
  getMenuForPickup,
  getMenuForDelivery,
}) {
  const { orderType } = useSelector((state) => state.AppReducer);
  const dispatch = useDispatch();

  const removeData = createAction("removeOrderData");
  const openModal = (type) => {
    if (orderType?.EN?.name === type?.serviceType || !type) return;
    dispatch(orderTimingAction.resetOrderTiming());
    if (
      orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY &&
      type?.serviceType === C.SERVICE_TYPE.PICKUP
    ) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use X service", {
          serviceType: t("PICKUP"),
        }),
        message: t("Are you sure you want to choose the service", {
          serviceType: t("PICKUP"),
        }),
      });

      setConfirmationCallback(() => () => {
        dispatch(removeData());
        dispatch(
          PaymentCardsAction.setstripeSelectedCard({
            cardInfo: {},
            isStoredCard: false,
          })
        );
        dispatch(PaymentCardsAction.setstripePaymentCards([]));
        dispatch(StoreAction.setSelectedPaymentMethod(null));
        dispatch(PaymentMethodAction.setSelectedPaymentType(null));

        dispatch(AppAction.setOrderType(type));
        getMenuForPickup();
      });
    } else if (
      orderType?.EN?.name === C.SERVICE_TYPE.PICKUP &&
      type?.serviceType === C.SERVICE_TYPE.DELIVERY
    ) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use X service", {
          serviceType: t("DELIVERY"),
        }),
        message: t("Are you sure you want to choose the service", {
          serviceType: t("DELIVERY"),
        }),
      });
      setConfirmationCallback(() => () => {
        dispatch(removeData());
        dispatch(
          PaymentCardsAction.setstripeSelectedCard({
            cardInfo: {},
            isStoredCard: false,
          })
        );
        dispatch(PaymentMethodAction.setSelectedPaymentType(null));
        dispatch(PaymentCardsAction.setstripePaymentCards([]));

        dispatch(StoreAction.setSelectedPaymentMethod(null));

        dispatch(AppAction.setOrderType(type));
        getMenuForDelivery();
      });
    } else {
      dispatch(AppAction.setOrderType(type));
    }
  };
  return openModal;
}

export default useChangeOrderType;
