import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

import * as CommonUI from "components";
import WithLoading from "components/Hoc/WithLoading";
import AddGoogleMapLocation from "./components/AddGoogleMapLocation";
import ChangeActionPopup from "components/Modals/ChangeActionPopup";

import useCheckStoresInDeliveryRange from "service-hooks/useCheckStoresInDeliveryRange";

import * as Actions from "store/actions";
import * as ROUTE_CONSTANTS from "routes/constants";
import useSetStoreMenu from "service-hooks/useSetStoreMenu";
import { useMapContext } from "contexts/googleMap";
import useCheckIfStoreAndCart from "hooks/useCheckIfStoreAndCart";
import DeliveryStoreList from "components/Modals/DeliveryStoreList";

function SelectMapLocation() {
  const dispatch = useDispatch();
  const setStoreMenu = useSetStoreMenu();
  useCheckIfStoreAndCart();

  // redux selectors
  const { stores } = useSelector((state) => state.brand);
  const { temporarymapAddress, temporaryuserLocation, temporaryzoom } =
    useSelector((state) => state.DeliveryAddressReducer);
  const { error, errorModaL } = useSelector((rstate) => rstate.errorSlice);
  const [state, localState] = useState({
    error: null,
    open: false,
    changeLocation: false,
    mapref: null,
  });
  const stateLocalState = (key, value) =>
    localState((prevState) => ({ ...prevState, [key]: value }));

  const updateTemporaryLocation = () => {
    dispatch(
      Actions.DeliveryAddressAction.setTempMapAddress(
        googlemapState?.mapAddress
      )
    );
    dispatch(
      Actions.DeliveryAddressAction.setTempZoom(googlemapState?.zoom || 16)
    );
    dispatch(
      Actions.DeliveryAddressAction.setTempUserLocation(
        googlemapState?.userLocation?.coordinates
      )
    );
  };
  const handleCheckStoresInDeliveryRange = useCheckStoresInDeliveryRange({
    stateLocalState,
    updateTemporaryLocation,
  });

  const changeStore = () => {
    updateTemporaryLocation();
    stateLocalState("changeLocation", false);
    setStoreMenu(
      stores,
      googlemapState?.userLocation?.coordinates,
      updateTemporaryLocation
    );
    dispatch(Actions.DeliveryAddressAction.setisGeoLocationAdded(true));
    dispatch(Actions.ResetAction.resetOnChangeCartWithStore());
    dispatch(Actions.DeliveryAddressAction.resetDeliveryAddress(null));
  };
  const { googlemapState, setUserLocation, updateMapContext } = useMapContext();

  useEffect(() => {
    updateMapContext("zoom", temporaryzoom);
    setUserLocation(temporaryuserLocation?.coordinates);
    updateMapContext("mapAddress", temporarymapAddress);
    updateMapContext("addressUpdated", !googlemapState?.addressUpdated);
  }, [temporarymapAddress?.streetAddress, temporaryuserLocation?.coordinates]);

  return (
    <WithLoading>
      <AddGoogleMapLocation
        checkoutPageUrl={ROUTE_CONSTANTS.OUTLET_CHECKOUT[1]}
        handleConfirmLocation={handleCheckStoresInDeliveryRange}
        googlemapState={googlemapState}
        {...{
          error: state.error,
          open: state.open,

          setOpen: () => stateLocalState("open", false),
          mapref: state.mapref,
          setMapRef: (ref) => stateLocalState("mapref", ref),
        }}
      />

      <CommonUI.CustomModal
        open={state.changeLocation}
        setOpen={() => stateLocalState("changeLocation", false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ChangeActionPopup
          title={t(
            "Unfortunately, the selected branch does not deliver to your location. Thankfully, we’ve found other nearby branches ready to serve you. Changing your location will change the branch & clear your basket. Do you want to continue"
          )}
          handleClickYes={changeStore}
          handleClickNo={() => {
            stateLocalState("changeLocation", false);
          }}
        />
      </CommonUI.CustomModal>
      <CommonUI.CustomModal
        open={errorModaL}
        reduxAction={Actions.ErrorAction.setErrorModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-90 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <CommonUI.ErrorModal
          reduxAction={Actions.ErrorAction.setErrorModal(false)}
          message={error}
        />
      </CommonUI.CustomModal>
      <DeliveryStoreList updateTemporaryLocation={updateTemporaryLocation} />
    </WithLoading>
  );
}
export default SelectMapLocation;
