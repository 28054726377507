import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  message: null,
  currentUser: null,
  brandInfo: {
    id: null,
    domainName: null,
  },
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    optSuccess: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
      state.error = null;
    },
    setBrandInfo: (state, action) => {
      state.brandInfo = action.payload;
    },
    optFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    sendVerificationCode: (state) => {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    sendVerificationCodeSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
      state.error = null;
    },
    sendVerificationCodeFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    reSendVerificationCodeSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
      state.error = null;
    },
    reSendVerificationCodeFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.message = action.payload;
    },
  },
});

export const {
  setBrandInfo,
  sendVerificationCode,
  sendVerificationCodeFailure,
  sendVerificationCodeSuccess,
  reSendVerificationCodeFailure,
  reSendVerificationCodeSuccess,
  optSuccess,
  optFailure,
} = registerSlice.actions;
export default registerSlice.reducer;
