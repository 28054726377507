import { useEffect } from "react";
function useCategoryScroll(selectedCategory) {
  const scrollStyles = {
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  };
  const element = document.getElementById(selectedCategory);
  if (element) {
    element?.scrollIntoView(scrollStyles);
  }
  // useEffect(() => {
  //   if (selectedCategory) {
  //     const element = document.getElementById(selectedCategory);
  //     if (element) {
  //       element.scrollIntoView(scrollStyles);
  //     }
  //   }
  // }, [selectedCategory]);
}

export default useCategoryScroll;
