import { apiSlice, postConfig } from "apiHelpers/ApiSlice";
import * as URL from "apiHelpers/api/ApiUrls";
import { Loader, ErrorAction } from "store/actions";
import { errorHandler } from "apiHelpers/api/errorHandler";

const onlinePaymentService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    initiateCheckoutRequest: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_CHECKOUT_DOT_COM_PAYMENT,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        try {
          const { data } = await queryFulfilled;
          if (data) {
          } else {
            let error = {
              error: errorHandler(data),
              modal: true,
            };
            dispatch(ErrorAction.setError(error));
          }
        } catch (err) {
          let error = {
            error: errorHandler(err.error),
            modal: true,
          };
          dispatch(ErrorAction.setError(error));
        }
        setTimeout(() => {
          dispatch(Loader.disableLoader());
        }, 4000);
      },
    }),
    verifyCheckoutDotComPayment: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.VERIFY_CHECKOUT_DOT_COM_PAYMENT + "/" + body,
        ...postConfig.query(),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        try {
          const { data } = await queryFulfilled;
          if (data) {
          } else {
            let error = {
              error: errorHandler(data),
              modal: true,
            };
            dispatch(ErrorAction.setError(error));
          }
        } catch (err) {
          let error = {
            error: errorHandler(err.error),
            modal: true,
          };
          dispatch(ErrorAction.setError(error));
        }
        dispatch(Loader.disableLoader());
      },
    }),
  }),
  overrideExisting: true,
});

export default onlinePaymentService;
export const {
  useInitiateCheckoutRequestMutation,
  useVerifyCheckoutDotComPaymentMutation,
} = onlinePaymentService;
