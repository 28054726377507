import React from "react";
import CategoryListItem from "./CategoryListItem";
function CategoriesList({ categoriesList }) {
  return (
    <React.Fragment>
      <nav className="bg-white flex overflow-auto py-3 cursor-pointer">
        {categoriesList?.map((category, index) => (
          <CategoryListItem category={category?.categoryId} key={index} />
        ))}
      </nav>
    </React.Fragment>
  );
}

export default CategoriesList;
