import React from "react";
import C from "Constants";
import StripeForm from "components/payment-gateway/stripe/StripeForm";
import CheckoutDotComContainer from "components/payment-gateway/CheckoutDotCom";

function CardWidgets({
  integrationsByName,
  captureCheckoutDotComPayment,
  redirectLink,
}) {
  if (integrationsByName[C.STRIPE]) {
    return (
      <div className="mt-4">
        <StripeForm />
      </div>
    );
  } else if (integrationsByName[C.CHECKOUT_DOT_COM]) {
    return (
      <div className="mt-4">
        <CheckoutDotComContainer
          capturePayment={(token) =>
            captureCheckoutDotComPayment(token, redirectLink)
          }
          redirectLink={redirectLink}
        />
      </div>
    );
  }

  return null;
}

export default CardWidgets;
