import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

import API from "apiHelpers";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { useDeliveryAddressContext } from "contexts/userAddressess";
import { AppAction } from "store/actions";
import { UTILS } from "utils";
import { isOpenedAnyStore } from "service-hooks/utils";

function useCheckOpenedStoresByLatLng() {
  const dispatch = useDispatch();
  const { selectedStore } = useSelector((state) => state.store);
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const { setAddressState } = useDeliveryAddressContext();

  const checkOpenedStoresByLatLng = async (latlng) => {
    try {
      const response = await API.getStoresBylatLng({
        brandId: brandInfo?.brand?._id,
        ...latlng,
      });
      if (response?.success === 1 && response?.data) {
        const isOpened = isOpenedAnyStore(response?.data);
        if (isOpened) {
          if (
            checkIfSelectedStoreIsInList(selectedStore, response.data, dispatch)
          ) {
            return response?.data;
          } else {
            setAddressState("latLongNotFallInCurrentStore", true);
            return false;
          }
        } else {
          handleFailureResponse({
            error: t(
              "Unfortunately, the store has paused delivery in your area for a short time. Change your delivery location or try again later"
            ),
          });

          return false;
        }
      } else {
        handleFailureResponse(response?.error);
        return false;
      }
    } catch (error) {
      handleFailureResponse(error);
      return false;
    } finally {
    }
  };
  function handleFailureResponse(error) {
    UTILS.logPopupError(errorHandler(error?.error), true);
    return false;
  }

  return checkOpenedStoresByLatLng;
}

const checkIfSelectedStoreIsInList = (selectedStore, stores, dispatch) => {
  if (!selectedStore?._id || !stores?.length) {
    dispatch(
      AppAction.setTriggerStoreApiAfterAddressSaveOnAppStrt({
        status: true,
        page: null,
      })
    );

    return true;
  }
  return stores?.some((store) => store?.storeId?._id === selectedStore._id);
};

export default useCheckOpenedStoresByLatLng;
