import { useEffect } from "react";

function useSyncTabs() {
  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === "logoutEvent") {
        // localStorage.clear();
        // window.location.href = "/login";
        window.location.reload();
      }
      if (event.key === "loginEvent") {
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleStorageEvent);
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);
}

export default useSyncTabs;
