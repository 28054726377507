import API from "apiHelpers";
import { t } from "i18next";
import { logPopupError } from "utils/Utils";

let fallBackMessage = t("Bank declined payment, please try again");

//create payment intent
export const stripeCreatePaymentLinkIntent = async (brandId, cartId) => {
  try {
    const createStripePaymentIntent = await API.createStripePaymentLink({
      brandId,
      cartId,
    });
    return createStripePaymentIntent;
  } catch (error) {
    logPopupError(error.message || fallBackMessage);
    return error;
  }
};

//verify payment intent
export const stripeVerifyPaymentLinkIntent = async (obj) => {
  try {
    const verifyStripePaymentIntent = await API.verifyStripePaymentLink(obj);
    return verifyStripePaymentIntent;
  } catch (error) {
    logPopupError(error.message || fallBackMessage);
    return error;
  }
};
