import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { useSelector } from "react-redux";
import C from "Constants";
const TrackOrderFooter = ({ orderStatus, orderInfo }) => {
  const params = useParams();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const [logo, setLogo] = useState(null);
  const shareOnWhatsapp = () => {
    const num = orderInfo?.storeId?.whatsAppContact;
    const message = `Hi , I need help regarding this order number: ${orderInfo?.orderNumber}.`;
    window.open(`https://wa.me/${num}?text=${message}`, "_blank");
  };
  useEffect(() => {
    const getBrand = async () => {
      if (brandInfo?.setting) {
        const { setting } = brandInfo;
        setLogo(setting?.brandLogoFirst);
      }
    };
    getBrand();

    return () => {
      setLogo(null);
    };
  }, []);
  return (
    <div className="rounded-t-xl shadow-top text-15x">
      <div className="flex p-4">
        <div className="px-2">
          <div
            className="w-12 h-12 bg-cover 
              bg-center  bg-black-100"
            style={{
              backgroundImage: `url(${logo ?? C.Logo})`,
            }}
          ></div>
        </div>
        <div className="w-full px-2">
          <p className="font-bold text-21x leading-normal">
            {brandInfo ? brandInfo?.brand?.brandName : params?.domainName}
          </p>
          {orderStatus && orderInfo && (
            <p
              data-test="addressLine"
              className="block text-gray-400 text-15x mb-1"
            >
              {orderInfo?.storeInfo?.baseLanguage?.name}
            </p>
          )}
        </div>
      </div>
      {orderInfo?.storeId?.whatsAppContact && (
        <div className="px-4 pb-4 cursor-pointer">
          <span
            onClick={shareOnWhatsapp}
            className="mt-2 py-4 w-full bg-wagreen flex items-center justify-center rounded text-16x text-white hover:bg-wagreenhover"
          >
            <svg
              viewBox="0 0 21 21"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
              className="w-5 h-5 text-white mx-2"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.071 4.013a9.192 9.192 0 016.502-2.663 9.192 9.192 0 019.206 9.143v.01A9.223 9.223 0 015.803 18.33l-2.92.925a.75.75 0 01-.937-.954l.932-2.763a9.026 9.026 0 01-1.528-5.042v-.003a9.192 9.192 0 012.721-6.48zM10.572 2.1l-.002.75a7.692 7.692 0 00-7.72 7.65 7.527 7.527 0 001.464 4.473.75.75 0 01.107.685l-.58 1.72 1.84-.583a.75.75 0 01.638.088 7.722 7.722 0 0011.96-6.387 7.692 7.692 0 00-7.705-7.646l-.002-.75zM8.548 5.878c.223.115.47.313.606.628.055.126.132.314.212.51l.177.43c.07.167.134.316.186.428a2.556 2.556 0 00.059.122 1.149 1.149 0 01.058 1.17 1.27 1.27 0 01-.282.38 5.126 5.126 0 01-.07.063l-.05.045a6.172 6.172 0 002.387 2.02 6.96 6.96 0 00.252-.367c.156-.246.41-.47.777-.513.277-.032.52.062.613.098l.01.004c.2.076 1.063.497 1.425.673a37.676 37.676 0 00.2.096c.077.037.173.082.246.123.085.047.297.166.433.4.04.068.068.14.084.213a1.812 1.812 0 01-.216 1.572c-.796 1.379-2.173 1.81-3.55 1.667-1.356-.14-2.785-.827-3.963-1.76-1.177-.934-2.189-2.183-2.629-3.538-.452-1.393-.293-2.904.899-4.163.4-.436 1-.53 1.428-.496.234.018.483.078.708.195zm5.91 7.139l-.066-.031-.175-.085-.585-.283a50.245 50.245 0 00-.471-.225c-.133.19-.28.386-.39.508-.133.15-.35.327-.67.366a1.22 1.22 0 01-.686-.133 7.673 7.673 0 01-3.436-2.979l-.01-.016a1.033 1.033 0 01-.085-.922c.086-.216.248-.379.276-.408l.004-.004a8.417 8.417 0 01.237-.23 15.217 15.217 0 01-.242-.552c-.067-.16-.132-.317-.191-.463a46.22 46.22 0 00-.15-.365.524.524 0 00-.313.012c-.76.8-.876 1.717-.565 2.672.323.995 1.113 2.016 2.134 2.826 1.022.81 2.189 1.34 3.184 1.443.964.1 1.684-.193 2.109-.943a.755.755 0 01.038-.061.31.31 0 00.052-.127zM7.515 7.194a.187.187 0 01-.002.003l.002-.003zm.346.018H7.86h.002zm4.226 4.58zm-2.29-3.779z"
                fill="currentColor"
              ></path>
            </svg>
            {t("Contact us on Whatsapp")}
          </span>
        </div>
      )}
      {orderInfo?.storeInfo?.mobileNumber && (
        <div className="px-4 pb-4">
          <a
            href={`tel:${orderInfo?.storeInfo?.mobileNumber}`}
            className="mt-2 py-4 w-full border flex items-center justify-center text-16x text-brand-base-primary rounded border-brand-base-primary hover:text-white hover:bg-brand-base-primary"
          >
            {t("Call us")}
          </a>
        </div>
      )}
    </div>
  );
};

export default TrackOrderFooter;
