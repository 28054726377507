import { put, call, takeLatest, delay } from "redux-saga/effects";
import API from "apiHelpers";
import { DiscountAction, CartAction, NotPersistAction } from "store/actions";
import { UTILS } from "utils";
import i18next from "i18next";
import { calculateCartGrossAmount } from "utils/Utils";

let message = i18next?.t(
  "We're sorry, but something went wrong. Please try again"
);

function* validateDiscount({
  payload: { payload, cart, discount, selectedStore },
}) {
  try {
    const response = yield call(API.validateDiscount, payload);
    if (response.success === 1) {
      yield delay(300);
      yield put(DiscountAction.validateDiscountSuccess(response.data));
      yield call(handleDiscount, cart, discount, selectedStore);
    } else {
      yield put(
        DiscountAction.validateDiscountFailure(response?.message ?? message)
      );
      yield put(DiscountAction.setCoponValidation(false));
    }
  } catch (error) {
    yield put(DiscountAction.setCoponValidation(false));
    yield put(
      DiscountAction.validateDiscountFailure(error?.message ?? message)
    );
  }
}

//
function* handleDiscount(cart, discount, selectedStore) {
  const { message, success } = yield UTILS.handleDiscount(
    cart,
    discount,
    selectedStore
  );
  if (success === 1) {
    yield put(NotPersistAction.toggleDiscountModal(false));
    yield put(DiscountAction.setDiscountCode(discount));
    yield put(DiscountAction.setCoponValidation(true));
  } else {
    yield put(DiscountAction.validateDiscountFailure(message));
    yield put(DiscountAction.setCoponValidation(false));
  }
  const { itemsGrossAmount, itemsNetPrice, orderGrossAmount } =
    calculateCartGrossAmount(cart, discount);
  yield put(
    CartAction.updateCartPrice({
      originalPrice: itemsNetPrice,
      amountAfterDiscount: itemsGrossAmount,
      itemsGrossAmount,
      orderGrossAmount,
    })
  );
}

function* discountSaga() {
  yield takeLatest(DiscountAction.validateDiscount.type, validateDiscount);
}

export default discountSaga;
