import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ItemAction } from "store/actions";
import { CartAction } from "store/actions";
import { NotPersistAction } from "store/actions";
import { TRACKING_EVENTS } from "utils";
import { errorNotification } from "utils/Utils";
import { t } from "i18next";
import { useEffect } from "react";

function useAddItemToBasket() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { requiredMissingSections } = useSelector((state) => state?.NotPersist);

  useEffect(() => {
    showError();
  }, [requiredMissingSections]);

  const showError = (id) => {
    if (requiredMissingSections?.length > 0) {
      const section = requiredMissingSections?.[0]?.modifierGroupId;
      if (section?._id || id) {
        const optionId = document.getElementById(section._id || id);
        optionId?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const checkItemAndOptions = (userSections, currentItem) => {
    const missingSections = [];
    let optionsValid = true;
    const userSectionsMap = new Map(
      userSections?.map((section) => [section?._id, section])
    );

    for (const group of currentItem?.itemInfo?.modifierGroups || []) {
      const numSelectedOptions = (
        userSectionsMap?.get(group?.modifierGroupId?._id) || { options: [] }
      )?.options.length;
      if (numSelectedOptions < group?.modifierGroupId.minimumAllowedSelection) {
        errorNotification(
          t(
            "Please select at least xoptions options from the xgroup section to proceed",
            {
              minimum: group?.modifierGroupId?.minimumAllowedSelection,
              groupname: group?.modifierGroupId?.baseLanguage?.name,
              option:
                group.modifierGroupId.minimumAllowedSelection === 1
                  ? t("side")
                  : t("sides"),
            }
          )
        );
        missingSections.push(group);
        showError(group?.modifierGroupId?._id);
        dispatch(NotPersistAction.setRequiredMissingSections(missingSections));

        optionsValid = false;
        return false;
      }
      if (
        group?.modifierGroupId.minimumAllowedSelection > 0 &&
        !userSectionsMap.has(group?.modifierGroupId?._id)
      ) {
        missingSections.push(group);
      }
    }

    // if (missingSections?.length > 0) {
    //   dispatch(NotPersistAction.setRequiredMissingSections(missingSections));
    //   showError();
    //   errorNotification(
    //     t(
    //       "Please select at least xoptions options from the xgroup section to proceed",
    //       {
    //         minimum:
    //           missingSections[0]?.modifierGroupId?.minimumAllowedSelection,
    //         groupname: missingSections[0]?.modifierGroupId?.baseLanguage?.name,
    //         option:
    //           missingSections[0].modifierGroupId.minimumAllowedSelection === 1
    //             ? t("side")
    //             : t("sides"),
    //       }
    //     )
    //   );

    //   return false;
    // }

    return optionsValid;
  };

  const goBack = window?.history?.back || history.goBack;

  const addItemToBasket = (
    brandInfo,
    selectedItem,
    singleItem,
    itemSection,
    handleBack
  ) => {
    if (!brandInfo?.brand?._id || !selectedItem?.itemInfo || !singleItem) {
      goBack();
      return;
    }
    const { itemInfo } = selectedItem;
    const itemObj = {
      brandId: brandInfo?.brand._id,
      sku: itemInfo?.sku,
      itemId: itemInfo?._id,
      quantity: singleItem?.quantity,
      price: singleItem?.price,
      baseLanguage: {
        ...itemInfo?.baseLanguage,
        ingredients: "",
      },
      sections: itemSection,
      categoryId: params?.catId,
      ...(itemInfo?.externalData && {
        externalData: itemInfo?.externalData,
      }),
    };

    const isItemAndOptionsValid = checkItemAndOptions(
      itemObj.sections,
      selectedItem
    );

    if (isItemAndOptionsValid) {
      dispatch(CartAction.addItemToCart(itemObj));
      TRACKING_EVENTS.AddToCart();
      handleBack();
      dispatch(ItemAction.clearItem());
    }
  };
  return {
    addItemToBasket,
  };
}

export default useAddItemToBasket;
