import { takeEvery, put, call, delay } from "redux-saga/effects";
import API from "apiHelpers";
import i18next from "i18next";
import { OrderAction, Loader } from "store/actions";
let message = i18next.t(
  "We're sorry, but something went wrong. Please try again"
);

function* getMyOrders() {
  yield put(Loader.enableLoader());
  yield delay(100);
  try {
    const response = yield call(API.getMyOrders);
    if (response.success === 1) {
      yield put(OrderAction.getMyOrdersSuccess(response.data));
    } else {
      yield put(OrderAction.getMyOrdersFailure(response?.message ?? message));
    }
  } catch (error) {
    yield put(OrderAction.getMyOrdersFailure(error?.message ?? message));
  } finally {
    yield put(Loader.disableLoader());
  }
}
function* getOrderStatus({ payload: { orderId } }) {
  yield put(Loader.enableLoader());
  yield delay(100);
  try {
    const response = yield call(API.getOrderStatus, orderId);
    if (response.success === 1 && response.orders) {
      yield put(OrderAction.getOrderStatusSuccess(response.orders));
    } else {
      yield put(
        OrderAction.getOrderStatusFailure(response?.message ?? message)
      );
    }
  } catch (error) {
    yield put(OrderAction.getOrderStatusFailure(error?.message ?? message));
  } finally {
    yield put(Loader.disableLoader());
  }
}

function* orderSaga() {
  yield takeEvery(OrderAction.getMyOrders.type, getMyOrders);
  yield takeEvery(OrderAction.getOrderStatus.type, getOrderStatus);
}

export default orderSaga;
