import React from "react";
import { useParams } from "react-router-dom";
import OrderContainer from "components/order/OrdersContainer";
import { useSelector } from "react-redux";
import { useCustomHistory } from "hooks";
import useQueryParam from "../useQueryParam";
function Orders() {
  const params = useParams();
  const router = useCustomHistory();
  const queryParam = useQueryParam();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const handleBack = () => {
    router(
      `/dine-in/auth/settings`,
      `/${params.domainName}/dine-in/auth/settings`,
      DomainType,
      undefined,
      `?${queryParam}`
    );
  };

  const handleOrderDetail = (state) => {
    router(
      `/dine-in/order-detail`,
      `/${params.domainName}/dine-in/order-detail`,
      DomainType,
      state,
      `?${queryParam}`
    );
  };

  return <OrderContainer {...{ handleBack, handleOrderDetail }} />;
}

export default Orders;
