import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfilePageHeader from "components/ProfilePageHeader";
import SettingLinks from "components/settings/SettingLinks";
import { useParams } from "react-router-dom";
import { useCustomHistory, useWindowLocation } from "hooks";
import * as ROUTE_CONSTANTS from "routes/constants";
import { Logout } from "store/actions";
import WithLoading from "components/Hoc/WithLoading";
import C from "Constants";
const Setting = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const windowRouter = useWindowLocation();
  const [settings, setSettings] = useState();
  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state.store
  );
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  const { brandInfo: Brand } = useSelector(
    (state) => state?.brandStorageReducer
  );

  useEffect(() => {
    const getBrand = async () => {
      if (Brand) {
        const { setting } = Brand;
        setSettings(setting);
      }
    };
    getBrand();
  }, []);

  const homePageUrl = ROUTE_CONSTANTS.HOME[1];
  const storePageUrl = ROUTE_CONSTANTS.OUTLET_STORE[1];

  const gotoStorePage = () =>
    router(storePageUrl, `/${params?.domainName}${storePageUrl}`, DomainType);

  const goBack = () => {
    if (
      orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
      selectedStore &&
      outletBasedSelectedStoreArea
    )
      gotoStorePage();
    else if (orderType?.serviceType === C.SERVICE_TYPE.PICKUP && selectedStore)
      gotoStorePage();
    else router("/", `/${params?.domainName}${homePageUrl}`, DomainType);
  };

  const logout = () => {
    localStorage.setItem("logoutEvent", Date.now().toString());
    localStorage.removeItem(brandInfo?.brand?._id);
    // window.sessionStorage.clear();
    dispatch(Logout.logout());
    windowRouter("/", `/${params?.domainName}`, DomainType);
  };

  const showMyOrders = () => {
    let url = ROUTE_CONSTANTS.ECOMMERCE_MY_ORDERS[1];
    if (params) {
      router(url, `/${params?.domainName}${url}`, DomainType);
    }
  };

  // if (isLoading) return <Spinner />;
  return (
    <WithLoading>
      <div className="profile-page min-h-app-height max-460">
        {params && (
          <React.Fragment>
            <ProfilePageHeader
              pageHeaderClasses="flex justify-between px-5 w-full"
              url={true}
              image={true}
              handleClick={goBack}
              img={settings}
            />
            <SettingLinks
              handleAllOrders={showMyOrders}
              handleLogout={logout}
            />
          </React.Fragment>
        )}
      </div>
    </WithLoading>
  );
};

export default Setting;
