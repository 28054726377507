import React from "react";
import SmallBrandHeader from "components/layouts/SmallBrandHeader";
import { useSelector } from "react-redux";
function Error({ error }) {
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  return (
    <>
      <SmallBrandHeader
        logoLink={brandInfo?.setting?.brandLogoFirst}
        text={brandInfo?.brand?.brandName}
      />
      <div className="flex items-center justify-center h-full text-2xl font-bold text-center mb-4 text-red-500 ">
        <div className="mt-4 py-4 px-6">
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-40 h-40 text-red-500"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM4 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
              <path d="M0 4a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v4a.5.5 0 0 1-1 0V7H1v5a1 1 0 0 0 1 1h5.5a.5.5 0 0 1 0 1H2a2 2 0 0 1-2-2V4Zm1 2h13V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2Z" />
              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
            </svg>
          </div>
          <p className="text-sm mt-8 font-medium">{error}</p>
        </div>
      </div>
    </>
  );
}

export default Error;
