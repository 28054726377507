import React from "react";
import Verification from "components/verification/containers/Verification";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCustomHistory } from "hooks";
import * as ROUTE_CONSTANTS from "routes/constants";
import C from "Constants";

let url = ROUTE_CONSTANTS.OUTLET_LOGIN[1];
let url2 = ROUTE_CONSTANTS.OUTLET_BASKET[1];
function VerificationC() {
  const params = useParams();
  const router = useCustomHistory();
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state?.store
  );
  const goToLogin = () => {
    router(url, `/${params?.domainName}${url}`, DomainType);
  };

  const goToBasket = () => {
    router(url2, `/${params?.domainName}${url2}`, DomainType);
  };

  const goToHomePage = () => {
    if (
      orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
      outletBasedSelectedStoreArea &&
      selectedStore
    ) {
      router(
        "/store/store-detail",
        `/${params?.domainName}/store/store-detail`,
        DomainType
      );
    } else if (
      orderType?.serviceType === C.SERVICE_TYPE.PICKUP &&
      selectedStore
    ) {
      router(url, `/${params?.domainName}/store/store-detail`, DomainType);
    } else router("/", `/${params?.domainName}/`, DomainType);
  };

  return (
    <Verification
      {...{
        goToLogin,
        goToBasket,
        goToHomePage,
      }}
    />
  );
}

export default VerificationC;
