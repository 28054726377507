import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { t } from "i18next";

import { useCustomHistory } from "hooks";
import * as Actions from "store/actions";

import { logErrorOnSentry } from "utils/Utils";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { getAreaCityAndCountry } from "utils/googleGeoCode";

import * as ROUTE_CONSTANTS from "routes/constants";
import { UTILS } from "utils";
import API from "apiHelpers";
import C from "Constants";
import { errorNotification } from "utils/Utils";
import { useLang } from "hooks";
import { useMapContext } from "contexts/googleMap";
import { Loader } from "store/actions";
import { isOpenedAnyStore } from "./utils";
function useCheckStoresInDeliveryRange({ stateLocalState }) {
  const manulAddressPageUrl = ROUTE_CONSTANTS.OUTLET_MANUAL_DELIVERY_ADDRESS[1];

  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { currentLang } = useLang();
  const { googlemapState, updateMapContext } = useMapContext();

  const { shippingMethod, DomainType } = useSelector(
    (state) => state.AppReducer
  );

  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state.store
  );

  const updateTemporaryLocation = (geoAddress) => {
    dispatch(Actions.DeliveryAddressAction.setTempMapAddress(geoAddress));
    dispatch(
      Actions.DeliveryAddressAction.setTempZoom(googlemapState?.zoom || 16)
    );
    dispatch(
      Actions.DeliveryAddressAction.setTempUserLocation(
        googlemapState?.userLocation?.coordinates
      )
    );
  };

  const updateStreetAddress = async () => {
    try {
      dispatch(Loader.setLoading(true));
      if (!googlemapState?.userLocation?.coordinates) return false;
      const fomattedAddress = await getAreaCityAndCountry(
        googlemapState?.userLocation?.coordinates.lat,
        googlemapState?.userLocation?.coordinates.lng,
        "en"
      );
      if (!fomattedAddress?.streetAddress) {
        stateLocalState(
          "error",
          t("Please make sure to type an address in the search bar")
        );
        stateLocalState("open", true);
        return false;
      }

      const isValidateZoom = UTILS.validateZoom(googlemapState);
      if (!isValidateZoom?.isValid) {
        stateLocalState("error", isValidateZoom?.error);
        stateLocalState("open", true);
        return false;
      }
      const isValidStreetAddress = UTILS.isValidStreetAddress(
        googlemapState?.mapAddress?.streetAddress,
        googlemapState
      );

      if (!isValidStreetAddress?.isValid) {
        stateLocalState("error", isValidStreetAddress?.error);
        stateLocalState("open", true);
        return false;
      }
      updateMapContext("mapAddress", fomattedAddress);

      return fomattedAddress;
    } catch (error) {
      logErrorOnSentry(
        `useCheckStoresInDeliveryRange---error in updateMapStreetAddress ${error?.message}`,
        "error",
        JSON.stringify(error)
      );
    } finally {
      dispatch(Loader.setLoading(false));
    }
  };

  const goToManualAddAddressScreen = async (data) => {
    router(
      manulAddressPageUrl,
      `/${params?.domainName}${manulAddressPageUrl}`,
      DomainType,
      { data }
    );
  };

  const checkCoordinatesInStoreDeliveryRange = async () => {
    try {
      const isValidMapAddress = await updateStreetAddress();
      if (!isValidMapAddress) return;
      else {
        dispatch(Actions.Loader.setLoading(true));
        if (shippingMethod === C.SHIPPING_METHODS.MAPPED_AREA_BASED) {
          const response = await API.checkIfLAtLngInPolygon({
            brandId: brandInfo?.brand._id,
            storeId: selectedStore?._id,
            mappedAreaId:
              outletBasedSelectedStoreArea?.mappedAreaInfo?.mappedAreaId?._id,
            lat: googlemapState?.userLocation?.coordinates?.lat,
            lng: googlemapState?.userLocation?.coordinates?.lng,
          });
          if (response?.success === 1 && response?.data) {
            handleSuccessResponse(
              isValidMapAddress,
              googlemapState,
              dispatch,
              response,
              currentLang,
              goToManualAddAddressScreen,
              stateLocalState
            );
          } else errorNotification(response?.message);
        } else {
          const isValidateZoom = UTILS.validateZoom(googlemapState);
          if (!isValidateZoom?.isValid) {
            UTILS.logError(errorHandler(isValidateZoom?.error), true);
            return;
          }
          const isValidStreetAddress = UTILS.isValidStreetAddress(
            googlemapState?.mapAddress?.streetAddress,
            googlemapState
          );
          if (!isValidStreetAddress?.isValid) {
            UTILS.logError(errorHandler(isValidStreetAddress?.error), true);
            return;
          }
          updateTemporaryLocation(isValidMapAddress);
          goToManualAddAddressScreen(null);
        }
      }
    } catch (error) {
      logErrorOnSentry("checkIfLAtLngInPolygon api issue", "error", error);
      UTILS.logError(errorHandler(error), true);
      return;
    } finally {
      dispatch(Actions.Loader.setLoading(false));
    }
  };
  return checkCoordinatesInStoreDeliveryRange;
}

const handleSuccessResponse = async (
  isValidMapAddress,
  googlemapState,
  dispatch,
  response,
  currentLang,
  addManualAddress,
  stateLocalState
) => {
  try {
    dispatch(Actions.Loader.setLoading(true));
    if (response?.data?.foundInSelectedStore) {
      //Found in same store
      // if (!checkIsStoreAvailable(response?.data)) {
      //   stateLocalState(
      //     "error",
      //     t(
      //       "Unfortunately, the store has paused delivery in your area for a short time. Change your delivery location or try again later."
      //     )
      //   );
      //   stateLocalState("open", true);
      //   return;
      // }
      dispatch(
        Actions.DeliveryAddressAction.setTempMapAddress(isValidMapAddress)
      );
      dispatch(
        Actions.DeliveryAddressAction.setTempZoom(googlemapState?.zoom || 16)
      );
      dispatch(
        Actions.DeliveryAddressAction.setTempUserLocation(
          googlemapState?.userLocation?.coordinates
        )
      );
      if (response?.data?.mappedAreaType === C.DISTANCE) {
        const storeAreaName = await getAreaCityAndCountry(
          googlemapState?.userLocation?.coordinates?.lat,
          googlemapState?.userLocation?.coordinates?.lng,
          currentLang
        );
        const storearea = {
          ...response?.data,
          areaName: storeAreaName,
        };

        addManualAddress(storearea);
      } else {
        addManualAddress(response?.data);
      }
      return;
    }
    // Found in other stores
    else if (response?.data?.foundInSelectedStore === false) {
      if (!isOpenedAnyStore(response?.data?.stores)) {
        stateLocalState(
          "error",
          t(
            "Unfortunately, the store has paused delivery in your area for a short time. Change your delivery location or try again later."
          )
        );
        stateLocalState("open", true);
        return;
      }
      const stores = response?.data?.stores;
      dispatch(Actions.BrandAction.getBrandStoresSuccess(stores));
      stateLocalState("changeLocation", true);
    }
  } catch (error) {
    UTILS.logError(errorHandler(error), true);
  } finally {
    dispatch(Actions.Loader.setLoading(false));
  }
};

export default useCheckStoresInDeliveryRange;
