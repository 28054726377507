import Logo from "../assets/images/logo.png";
export const COUPON_NOT_FOUND =
  "Sorry, We didn't find any coupon with provided code";
export const DIRECTION = "__DIRECTION___";
export const CREDIT_CARD = "CREDIT_CARD";
export const PAYMENT_GATEWAY = "PAYMENT_GATEWAY";
export const CHECKOUT_DOT_COM = "CHECKOUTDOTCOM";
export const TELR = "TELR";
export const PAYMENT_REDIRECT_URL = window.location.origin + "/";
export const DEFAULT_LAT = {
  lat: 23,
  lng: 54,
};
export const BRAND = "brand";
export const STORE = "store";
export const PREVIEW = "preview";
export const PLACEHODERIMAGE =
  "https://uapp-live-com.s3.amazonaws.com/UAPP-MEDIA/placeholder.jpeg";
export const EN = "EN";
export const AR = "AR";
export const BRAND_BASED_CATALOGUE = "BRAND_BASED_CATALOGUE";
export const OUTLET_BASED_CATALOGUE = "OUTLET_BASED_CATALOGUE";
export const WEB_STORE_FRONT = "WEB_STORE_FRONT";
export const CUSTOM_DOMAIN = "CUSTOM_DOMAIN";
export const TEMPORARAY_DOMAIN = "TEMPORARAY_DOMAIN";
export const CUSTOMER = "CUSTOMER";
export const DEFAULT_COUNTRY = "UAE";
export const DEFAULT_COUNTRY_CODE = "+971";
export const CHANGE_ADDRESS = "change";
export const ADD_NEW_ADDRESS = "addnew";
export const REFINE_LOCATION = "refineLocation";
export const MAP_MODEL = "mapmodal";
export const AED = "AED";
export const MANUAL_ADDRESS = "manualAddress";
export const DEFAULT_ERROR_MSG =
  "We're sorry, but something went wrong. Please try again";
export const ECOMMERCE = "ecommerce";
export const FACEBOOK_PIXEL = "FACEBOOK_PIXEL";
export const Root_ID_Issue =
  "Header brandId not coming on root domain or User trying to Access temporary domain without adding domain in url";
export const refreshAppProps = {
  heading: "new update available",
  message:
    "We made bug fixes and improvements to keep your operations smooth. Please click below button to get latest application version.",

  btnText: "get latest version",
};

export const noInternetProps = {
  heading: "Oops",
  message:
    "Looks like you're offline. Try reconnecting to a working internet connection.",
  btnText: "Refresh",
};

// item open medium
export const SHARED_LINK = "shared-link";
export const WEB = "web";

export const APP_LOADED_THROUGH = {
  DirectLink: "directLink",
  normalFlow: "normalFlow",
};

export const SHIPPING_METHODS = {
  AREA_BASED: "AREA_BASED_DELIVERY_METHOD",
  CITY_BASED: "CITY_BASED_DELIVERY_METHOD",
  COUNTRY_BASED: "COUNTRY_BASED_DELIVERY_METHOD",
  MAPPED_AREA_BASED: "MAPPED_AREA_BASED_DELIVERY_METHOD",
};
export const DISTANCE = "DISTANCE";
export const POLYGON = "POLYGON";

export const PAYMENT_LINKS = {
  VERIFIED: "VERIFIED",
  NOT_VERIFIED: "NOT_VERIFIED",
};
export const LOYALITY = "LOYALITY";
export const DISCOUNT = "DISCOUNT";

export const COUPUN_APPLICATION_TYPE = {
  MANUAL: "manual",
  AUTOMATIC: "automatic",
  HIDDEN: "hidden",
};
export { Logo };

export const SERVICE_TYPE = {
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
  DINE_IN: "DINE IN",
};

export const DINE_SERVICE_TYPE = {
  QUERY_PARAM: "dine-in",
  SERVICE_TYPE: "service-type",
  STORE_ID: "store-id",
  TABLE_NUMBER: "table-number",
};
export const COMPANY_NAMES = {
  RESTHERO: "Resthero",
  CUSINETTE: "cuisinette",
};

export const STRIPE = "STRIPE";

export const PAYMENT_RDX_LINKS = {
  OUTLET_BASE: {
    success: "store/checkout/?payment=success",
    failure: "store/checkout/?payment=failure",
  },
  BRAND_BASE: {
    success: "order/checkout/?payment=success",
    failure: "order/checkout/?payment=failure",
  },
};
export const WALLET = {
  APPLE_PAY: "applePay",
  GOOGLE_PAY: "googlePay",
  NEW_CARD: "new_card",
  SAVED_CARDS: "saved_card",
  CASH: "cash",
  CARD_MACHINE: "CARD_MACHINE",
  CASH_MACHINE: "CASH",
};
export const COUPONS_POPUP = "coupons_popup";

export const ERRORS = {
  ECONNABORTED: "ECONNABORTED",
  NETWORK_ERROR: "Network Error",
  ETIMEDOUT: "ETIMEDOUT",
};

export const ORDER_TIMING_TYPE = {
  ASAP: "ASAP",
  PREORDER: "PREORDER",
};

export const TODAY = "Today";

export const WITH_OUT_STORE_ID = "withoutStoreId";
export const WITH_STORE_ID = "withStoreId";
