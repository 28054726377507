import React, { useState, useEffect } from "react";
import Spinner from "./Spinner";
import { UTILS } from "utils";
import i18n from "i18n/i18n";

const NotFound = ({
  message = "Please, check the link and try again!",
  location,
  sentryError,
}) => {
  const [timer, setTimer] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setTimer(false);
    }, 1000);
    return () => {
      setTimer(false);
    };
  }, []);

  let msg = message || `No match for ${location?.pathname}`;

  UTILS.logErrorOnSentry(sentryError || msg, "info");
  return (
    <div className="flex item-center justify-center flex-col h-screen w-full text-center p-5">
      <div>
        {timer ? (
          <Spinner />
        ) : (
          <>
            <h2 className="text-24x text-bold">
              {typeof message === "string" ? (
                message
              ) : (
                <div>
                  No match for <code>{location?.pathname}</code>
                </div>
              )}
            </h2>

            <div className="mt-6">
              <span
                onClick={() => window.location.reload()}
                className="bg-brand-base-primary w-full py-3 rounded px-6 text-white text-17x font-bold cursor-pointer"
              >
                {i18n.t("Refresh")}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotFound;
