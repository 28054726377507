import React from "react";
import { t } from "i18next";
import { useLang } from "hooks";
import { isItemOutOfStock } from "utils/Utils";
import { useSelector } from "react-redux";

const StoreItem = ({ item, showItem, categoryId, page }) => {
  const { selectedStore } = useSelector((state) => state.store);
  const { currentLang } = useLang();
  return (
    <React.Fragment>
      <div
        className="relative flex-grow cursor-pointer"
        onClick={() => showItem(item, categoryId)}
      >
        <p className="text-16x font-normal text-outer-space">
          {item?.itemId?.baseLanguage?.title}
        </p>
        <div>
          <p className="text-14x text-gunsmoke leading-19x mt-2 truncate-line-2 overflow-wrap-anywhere">
            {item?.itemId?.baseLanguage?.description}
          </p>
        </div>
        {page !== "home" && isItemOutOfStock(item?.itemId, selectedStore) ? (
          <div>
            <p className="text-14x  mt-4">
              <span className="h-8 py-1 px-4 rounded-full border text-brand-text-primary bg-brand-base-primary border-brand-base-primary">
                {t("Out of stock")}{" "}
              </span>
            </p>
          </div>
        ) : item?.itemId?.price <= 0 ? (
          <div>
            <p className="text-14x  mt-4">
              <span className="h-8 py-1 px-4 rounded-full border text-brand-text-primary bg-brand-base-primary border-brand-base-primary">
                {t("Price On Selection")}{" "}
              </span>
            </p>
          </div>
        ) : (
          <div>
            <p className="text-16x  mt-2">
              {currentLang === "AR"
                ? `${item?.itemId?.price?.toFixed(2)} ` + t("AED")
                : t("AED") + ` ${item?.itemId?.price?.toFixed(2)}`}
            </p>
          </div>
        )}
      </div>
      <div
        className="relative flex-shrink-0 h-96x w-96x mx-3"
        onClick={() => showItem(item, categoryId)}
      >
        <div
          className="absolute w-full h-full top-0 left-0 rounded-md cursor-pointer"
          style={{
            backgroundImage:
              item?.itemId?.itemImageURLs?.length > 0 &&
              `url(${item.itemId.itemImageURLs[0]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </React.Fragment>
  );
};

export default StoreItem;
