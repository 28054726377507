import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "components";
import { SVGICONS } from "config";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { useLang } from "hooks";
import C from "Constants";
const Login = (props) => {
  const history = useHistory();
  const params = useParams();
  const { currentLang } = useLang();
  const { isLoading } = useSelector((state) => state.loader);
  const [pageContent, setPageContent] = useState("");
  const { brandInfo: brand } = useSelector(
    (state) => state?.brandStorageReducer
  );
  const pageId = params?.pageId;
  const historyState = history?.location?.state;
  const page = brand?.setting?.staticPages[pageId];
  const getContent = () => {
    return historyState || page;
  };

  useEffect(() => {
    try {
      window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      let pageStaticData = getContent();
      setPageContent(pageStaticData);
    } catch (error) {}
  }, [params?.pageId, history?.location?.state, page]);

  if (isLoading) return <Spinner />;
  return (
    <div className="max-w-400x register-page min-h-app-height my-0 mx-auto py-4 px-5 bg-white">
      <div style={{ color: "white", backgroundColor: "red" }}></div>
      <React.Fragment>
        <div className="mb-5">
          <button className="w-5 h-5" onClick={() => history.goBack()}>
            <SVGICONS.BackArrow />
          </button>
        </div>
        <h2 className="pb-5 text-26x text-outer-space font-bold leading-24x text-center">
          {currentLang === C.EN
            ? pageContent?.linkTitle?.en
            : pageContent?.linkTitle?.ar}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html:
              currentLang === C.EN
                ? pageContent?.formattedData?.en
                : pageContent?.formattedData?.ar,
          }}
        ></div>
      </React.Fragment>
    </div>
  );
};
export default Login;
