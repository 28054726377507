import React from "react";
import { t } from "i18next";
import { PlusIcon, MinusIcon } from "icons";
const Quantity = ({ singleItem, decreaseQuantity, increaseQuantity }) => {
  return (
    <div className="py-6">
      <p className="font-semibold px-4 text-16x">{t("Quantity")}</p>
      <div className=" car-add-decrrese w-150x my-0 mx-auto">
        <div className="flex justify-between relative items-center mt-6">
          <span className="inline-flex" onClick={decreaseQuantity}>
            <button className="cursor-pointer">
              <div className="text-theme-green">
                <span className="w-6 h-6">
                  <MinusIcon />
                </span>
              </div>
            </button>
          </span>
          <span className="inline-flex justify-center text-center mx-1">
            <span className="">
              <span className="text-32x font-bold text-black">
                {singleItem?.quantity}
              </span>
            </span>
          </span>

          <span className="inline-flex" onClick={increaseQuantity}>
            <button className="cursor-pointer">
              <div className="text-theme-green">
                <span className="w-6 h-6">
                  <PlusIcon />
                </span>
              </div>
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Quantity;
