import React from "react";
const SmallBrandHeader = ({ logoLink, text }) => {
  return (
    <div className="flex flex-col items-center justify-center my-4">
      <div className="relative flex-shrink-0 h-96x w-96x">
        <div
          className="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${logoLink || "https://via.placeholder.com/150"})`,
          }}
        ></div>
      </div>
      <div className="text-19x text-center font-medium pt-2 mx-4">
        {text}
      </div>
    </div>
  );
};

export default SmallBrandHeader;
