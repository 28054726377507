import React, { Fragment } from "react";
import { Button } from "antd";
import { useLang } from "hooks";
import C from "Constants";
import { t } from "i18next";
const DeliveryPickupButton = ({
    activeServices,
    handleServiceType,
    orderType,
}) => {
    const { currentLang } = useLang();
    return (
        <div className="flex">
            {activeServices &&
                activeServices.map((service, index) => {
                    const isSelected = orderType?.["EN"]?.name === service?.["EN"]?.name;
                    const length = activeServices.length;
                    return (
                        <Fragment key={index}>
                            {service?.["EN"]?.name === C.SERVICE_TYPE.DELIVERY && (
                                <Button
                                    onClick={() => handleServiceType(service)}
                                    className={`border border-brand-base-primary  
                                     ${length <= 1 ? "rounded-lg " : `${currentLang === "EN" ? `rounded-l-lg` : "rounded-r-lg"}`} 
                                      ${isSelected ? "text-white bg-brand-base-primary " : ""
                                        } py-1 px-2`}
                                >
                                    {t("DELIVERY")}
                                </Button>
                            )}
                            {service?.["EN"]?.name === C.SERVICE_TYPE.PICKUP && (
                                <Button
                                    onClick={() => handleServiceType(service)}
                                    className={`border border-brand-base-primary  ${length > 1 ? `${currentLang === "EN" ? `rounded-r-lg` : "rounded-l-lg"}` : " rounded-lg"
                                        }  ${isSelected ? "text-white bg-brand-base-primary " : ""
                                        } py-1 px-2`}
                                >
                                    {t("PICKUP")}
                                </Button>
                            )}
                        </Fragment>
                    );
                })}
        </div>
    );
};

export default DeliveryPickupButton;
