import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartAction } from "store/actions";
import { calculateCartGrossAmount } from "utils/Utils";
import useUserWallet from "./useUserWallet";
import C from "Constants";

function useUpdateCart() {
  const dispatch = useDispatch();
  const userWallet = useUserWallet();
  const { cart } = useSelector((state) => state.cart);
  const { orderType } = useSelector((state) => state.AppReducer);
  const { discountInfo } = useSelector((state) => state.Discounts);
  const { outletBasedSelectedStoreArea } = useSelector((state) => state?.store);

  useEffect(() => {
    updateCart();
    if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
      dispatch(
        CartAction.setDeliveryFee(outletBasedSelectedStoreArea?.deliveryFee)
      );
    } else {
      dispatch(CartAction.setDeliveryFee(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discountInfo, outletBasedSelectedStoreArea, orderType]);

  const updateCart = () => {
    const { itemsGrossAmount, itemsNetPrice, orderGrossAmount } =
      calculateCartGrossAmount(cart, discountInfo, userWallet);
    dispatch(
      CartAction.updateCartPrice({
        originalPrice: itemsNetPrice,
        amountAfterDiscount: itemsGrossAmount,
        itemsGrossAmount,
        orderGrossAmount,
      })
    );
  };
  return updateCart;
}

export default useUpdateCart;
