import React from "react";
import { useLang } from "hooks";

import { SVGICONS } from "config";
import { UNCHECKED } from "icons";
import { t } from "i18next";
import C from "Constants";
import useOpenSavedAddressListModal from "service-hooks/useOpenSavedAddressListModal";

function OutOfStockModifer({
  modifer,
  isSingleSelection,
  isStoreSelected,
  isModifierOutOFStock,
}) {
  const { currentLang } = useLang();
  const { openAddressListModal } = useOpenSavedAddressListModal();

  return (
    <div key={modifer?._id}>
      <span className="inline-flex flex-grow w-full">
        <div className="flex flex-grow  w-full">
          <label className="flex-grow flex-shrink inline-flex items-center">
            <div className="flex flex-1 items-center py-3">
              <div
                className="flex"
                onClick={() => {
                  if (isStoreSelected === false) {
                    openAddressListModal();
                  }
                }}
              >
                {isSingleSelection === false ? (
                  <span className="inline-flex w-6 h-6">
                    <input type="checkbox" className="hidden" />
                    <span className="w-6 h-6 inline-flex ">
                      <UNCHECKED />
                    </span>
                  </span>
                ) : (
                  <span key={modifer?._id} className="inline-flex w-6 h-6">
                    <input type="radio" className="hidden" />
                    <span className="w-6 h-6 inline-flex ">
                      <SVGICONS.RadioUnCheck />
                    </span>
                  </span>
                )}
              </div>

              <div className="flex flex-1 items-center">
                <div className="flex flex-1 flex-col px-1.5">
                  <p className="text-16x text-outer-space">
                    {modifer?.baseLanguage?.name}
                  </p>
                  <p className="text-13x text-gunsmoke">
                    +
                    {currentLang === C.AR
                      ? modifer?.price?.toFixed(2) + " " + t("AED")
                      : t("AED") + " " + modifer?.price?.toFixed(2)}
                  </p>
                </div>
                {isStoreSelected === false ? null : isStoreSelected &&
                  isModifierOutOFStock ? (
                  <span className="text-13x text-brand-base-primary">
                    {t("Out of stock")}
                  </span>
                ) : null}
              </div>
            </div>
          </label>
        </div>
      </span>
    </div>
  );
}

export default OutOfStockModifer;
