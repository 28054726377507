import * as ROUTE_CONSTANTS from "routes/constants";
import React, { useState } from "react";
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";

import AddMapAddress from "components/checkout/SelectMapLocation";
import { geoCodeConverter } from "utils/googleGeoCode";
import { DeliveryAddressAction } from "store/actions";
import { UTILS } from "utils";
import { useCustomHistory } from "hooks";
import { useParams } from "react-router-dom";
import { CustomModal } from "components";
import { ErrorAction } from "store/actions";
import { ErrorModal } from "components";
function SelectMapLocation() {
  const manulAddressPageUrl =
    ROUTE_CONSTANTS.ECOMMERCE_MANUAL_DELIVERY_ADDRESS[1];
  const dispatch = useDispatch();
  const params = useParams();
  const router = useCustomHistory();
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [mapref, setMapRef] = useState(null);

  const { mapAddress, userLocation } = useSelector(
    (state) => state.DeliveryAddressReducer
  );
  const { temporaryzoom } = useSelector(
    (state) => state.DeliveryAddressReducer
  );

  const { errorModaL } = useSelector((rstate) => rstate.errorSlice);

  const { DomainType } = useSelector((state) => state.AppReducer);
  const updateMapStreetAddress = async () => {
    const fomattedAddress = await geoCodeConverter(userLocation?.coordinates);
    if (fomattedAddress) {
      let m = { ...mapAddress };
      m.streetAddress = fomattedAddress;
      dispatch(DeliveryAddressAction.setMapAddress(m));
      return fomattedAddress;
    } else return null;
  };

  const handleConfirmLocation = async () => {
    const streetAddres = await updateMapStreetAddress();
    if (!streetAddres) {
      setError(
        t(
          "Try to choose the exact location so that riders can deliver as soon as possible"
        )
      );
      setOpen(true);
      return;
    }
    const { error, isValid } = UTILS.isValidLocation(
      streetAddres,
      temporaryzoom
    );
    if (!isValid) {
      setError(error);
      setOpen(true);
      return;
    } else {
      router(
        manulAddressPageUrl,
        `/${params?.domainName}${manulAddressPageUrl}`,
        DomainType
      );
    }
  };

  return (
    <>
      <AddMapAddress
        manulAddressPageUrl={
          ROUTE_CONSTANTS.ECOMMERCE_MANUAL_DELIVERY_ADDRESS[1]
        }
        checkoutPageUrl={ROUTE_CONSTANTS.ECOMMERCE_CHECKOUT[1]}
        {...{
          handleConfirmLocation,
          error,
          open,
          setOpen,
          mapref,
          setMapRef,
        }}
      />
      <CustomModal
        open={errorModaL}
        reduxAction={ErrorAction.setErrorModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ErrorModal
          reduxAction={ErrorAction.setErrorModal(false)}
          message={error}
        />
      </CustomModal>
    </>
  );
}

export default SelectMapLocation;
