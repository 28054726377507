import FixedFooter from "components/FixedFooter";
import React from "react";

function ItemFooter({ onButtonClick, selectedItem, btnText1, btnText2 }) {
  return (
    <FixedFooter>
      <div className="flex flex-col flex-grow flex-shrink">
        <button
          onClick={() => onButtonClick(selectedItem)}
          className="py-4 border border-transparent rounded text-white bg-brand-base-primary font-bold text-16x focus:outline-none"
        >
          <div className="flex justify-between text-brand-text-primary">
            <div className="my-0 mx-auto">{btnText1}</div>
            <div className="mx-2">{btnText2}</div>
          </div>
        </button>
      </div>
    </FixedFooter>
  );
}

export default ItemFooter;
