import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//components
import { CustomModal } from "components";
import UserLogo from "components/UserLogo";
import { ErrorComponent } from "components";
import ImageHeader from "components/ImageHeader";
import Menu from "features/menu/containers/Menu";
import WithLoading from "components/Hoc/WithLoading";
import DeliveringTo from "../components/DeliveringTo";
import PickupChangeStoreTo from "../components/PickupChangeStoreTo";
import ConfirmModal from "components/Modals/ConfirmModal";
import LoginSignupButton from "components/LoginSignupButton";
import StoreHeaderDetail from "../components/StoreHeaderDetail";
import DeliveryPickupButton from "components/DeliveryPickupButton";
import StoreCategoryWithMenu from "components/menu/StoreCategoryWithMenu";
import DiscountHomeContainer from "components/discounts/DiscountHomeContainer";
import LoyaltyPolicyWidget from "components/brand-loyalty/LoyaltyPolicyWidget";
import AddressList from "features/delivery-address/modals/AddressList";
//actions
import { CartAction } from "store/actions";
//hooks
import useCurrentUser from "hooks/useCurrentUser";
import useChangeOrderType from "./useChangeOrderType";
import {
  useLang,
  useCustomHistory,
  useMenuSearch,
  useUpdateDeliveryFee,
} from "hooks";
//utils
import { calculateCartGrossAmount, createServiceTypeArray } from "utils/Utils";
//constants
import C from "Constants";
import * as ROUTE_CONSTANTS from "routes/constants";
import useOpenStoreITem from "./useOpenStoreITem";
import { PreOrderingDisplayTimingWidget } from "components/Pre-ordering/index";
import DeliveryButtonBar from "components/DeliveryButtonBar";
import AddressErrorModalPopup from "components/Modals/AddressErrorModalPopup";
import DeliveryStoreListModal from "components/Modals/DeliveryStoreList";
import LatLatNotFallInCurrentStore from "components/Modals/LatLatNotFallInCurrentStore";
import useGetRecommendedItems from "service-hooks/useGetRecommendedItems";
import RecommendedITemList from "../../../components/recommended-item/RecommendedITemList";

const StoreContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { currentLang } = useLang();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearch] = useState("");
  const { cart } = useSelector((state) => state?.cart);
  const { currentUser } = useCurrentUser();
  const openStoreItem = useOpenStoreITem();
  useUpdateDeliveryFee();
  const { itemList } = useGetRecommendedItems();

  const { storeError } = useSelector((state) => state?.errorSlice);
  const { discountInfo } = useSelector((state) => state?.Discounts);
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  const { discounts } = useSelector((state) => state?.brand);
  const { selectedStore, outletBasedSelectedStoreArea, storeMenu } =
    useSelector((state) => state?.store);
  const { loyaltyPolicy, brandInfo } = useSelector(
    (state) => state?.brandStorageReducer
  );
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);
  const { filteredMenu } = useMenuSearch(searchTerm, storeMenu);

  useEffect(() => {
    if (
      ((orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
        !outletBasedSelectedStoreArea) ||
        !selectedStore) &&
      !storeError?.error
    ) {
      router(`/`, `/${params?.domainName}`, DomainType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletBasedSelectedStoreArea, selectedStore]);

  //update cart price
  const updateCart = () => {
    const { itemsGrossAmount, itemsNetPrice, orderGrossAmount } =
      calculateCartGrossAmount(cart, discountInfo);
    dispatch(
      CartAction.updateCartPrice({
        originalPrice: itemsNetPrice,
        amountAfterDiscount: itemsGrossAmount,
        itemsGrossAmount,
        orderGrossAmount,
      })
    );
  };

  useEffect(() => {
    updateCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discountInfo, outletBasedSelectedStoreArea, orderType]);

  //go to settings page
  const goToSettings = () => {
    let url = ROUTE_CONSTANTS.OUTLET_SETTINGS[1];
    if (params) {
      router(url, `/${params?.domainName}${url}`, DomainType);
    }
  };

  //go to login page
  const goToLogin = () => {
    let url = ROUTE_CONSTANTS.OUTLET_LOGIN[1];
    router(url, `/${params?.domainName}${url}`, DomainType);
  };

  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState({
    title: "",
    message: "",
  });
  const [confirmationCallback, setConfirmationCallback] = useState(
    () => () => {}
  );
  const handleConfirmModal = () => {
    setConfirmModal(false);
    confirmationCallback();
  };

  const handleCancelModal = () => {
    setConfirmModal(false);
  };

  const openModal = useChangeOrderType({
    setConfirmModal,
    setConfirmModalTitle,
    setConfirmationCallback,
    setOpen,
  });
  const openITemDetailPage = (item, category) => {
    openStoreItem(item, category, setOpen, params);
  };

  return (
    <WithLoading>
      <div className="store-page store-page-popup">
        <div className="location-bar bg-location py-2 px-4">
          <div className="flex justify-between items-center">
            <DeliveryPickupButton
              activeServices={createServiceTypeArray(brandInfo?.setting)}
              orderType={orderType}
              handleServiceType={openModal}
            />
            <div>
              {currentUser?.token ? (
                <UserLogo {...{ goToSettings }} />
              ) : (
                <LoginSignupButton {...{ goToLogin }} />
              )}
            </div>
          </div>
        </div>

        <ImageHeader page={true} />
        <StoreHeaderDetail
          selectedStore={selectedStore}
          selectedArea={outletBasedSelectedStoreArea}
          activeServices={createServiceTypeArray(brandInfo?.setting)}
          DomainType={DomainType}
          onClick={openModal}
        />
        <DiscountHomeContainer
          {...{ discounts }}
          selectedStore={selectedStore}
        />
        {loyaltyPolicy?.isLoyaltyActive && (
          <LoyaltyPolicyWidget {...{ loyaltyPolicy, goToLogin }} />
        )}
        <DeliveryButtonBar
          activeServices={createServiceTypeArray(brandInfo?.setting)}
          handleServiceType={openModal}
          orderType={orderType}
        />
        {orderType?.serviceType === C.SERVICE_TYPE.DELIVERY && (
          <DeliveringTo
            selectedArea={outletBasedSelectedStoreArea}
            lang={currentLang}
            selectedStore={selectedStore}
          />
        )}
        {orderType?.serviceType === C.SERVICE_TYPE.PICKUP && (
          <PickupChangeStoreTo
            selectedStore={selectedStore}
            lang={currentLang}
          />
        )}
        {storeError && <ErrorComponent error={storeError?.error} />}
        <PreOrderingDisplayTimingWidget
          orderTiming={orderTiming}
          route="store"
        />
        <RecommendedITemList
          itemidList={itemList}
          setOpen={setOpen}
          openStoreItem={openStoreItem}
        />
        <Menu
          menu={filteredMenu}
          menuType="store"
          {...{ searchTerm, setSearch }}
        >
          <StoreCategoryWithMenu
            menu={filteredMenu}
            menuType="store"
            onItemClick={openITemDetailPage}
            {...{ open, setOpen }}
            page="store"
          />
        </Menu>
      </div>
      <CustomModal
        open={confirmModal}
        setOpen={setConfirmModal}
        bodyclasses="flex"
        overlayClasses={`fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30 ${
          currentLang == "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <ConfirmModal
          onConfirm={handleConfirmModal}
          onCancel={handleCancelModal}
          title={confirmModalTitle}
        />
      </CustomModal>
      <AddressErrorModalPopup />
      <DeliveryStoreListModal updateTemporaryLocation={() => {}} />
      <AddressList page="/store/store-detail" pageFrom="store" />
      <LatLatNotFallInCurrentStore />
    </WithLoading>
  );
};

export default StoreContainer;
