import API from "apiHelpers";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "store/actions";
import { AppAction } from "store/actions";
import { logErrorOnSentry } from "utils/Utils";

function useGetShippingMethod() {
  const dispatch = useDispatch();
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const { selectedStore } = useSelector((state) => state.store);

  const getShippingMethod = async () => {
    try {
      if (!brandInfo?.brand?._id) return;
      if (selectedStore) return;
      dispatch(Loader.setLoading(true));
      const response = await API.getBrandShippingMethod({
        brandId: brandInfo?.brand?._id,
      });
      if (response?.data?.deliveryMethod) {
        const method = response?.data?.deliveryMethod;
        dispatch(AppAction.setShippingMethod(method));
        dispatch(AppAction.setShippingMethodTemp(method));
        return response?.data;
      } else {
        logErrorOnSentry(
          `Error fetching shipping methods ${brandInfo?.brand?._id}`,
          response?.data
        );
        return;
      }
    } catch (error) {
      console.error("Error fetching shipping methods", error);
      logErrorOnSentry(
        `Error fetching shipping methods`,
        error,
        JSON.stringify(error)
      );
    } finally {
      dispatch(Loader.setLoading(false));
    }
  };
  return getShippingMethod;
}

export default useGetShippingMethod;
