//auth urls
export const LOGIN_USER = "user/password-less-login-v2";
export const VERRIFY_VERIFICATION_CODE = "user/verify-account-v2";
export const RESEND_VERIFICATION_CODE = "user/send-otp-code";
export const REGISTER_USER = "auth/register";

//brand urls
export const GET_BRAND_SETTINGS = "branding/getBrandSettings";
export const GET_BRAND_MENU = "menus/get-brand-menu";
export const GET_BRAND_ACTIVE_SERVICES = "brands/get-brand-active-services";
export const GET_BRAND_SETTINGS_BYID =
  "branding/get-brand-settings-by-brand-id-for-store-front";

export const GET_BRAND_ACTIVE_SHIPPING_METHOD =
  "brands/get-brand-active-delivery-method";

//store urls
export const GET_BRAND_PICKUP_STORES = "/store/get-stores-for-pickup";
export const GET_STORES_BY_AREA_ID = "store/getStoresByDeliveryArea";
export const GET_STORE_INFO_POPULATED = "store/getStoreInfoPopulated";

//store menu urls
export const GET_DELIVERY_STORE_MENU = "menus/get-delivery-store-menu-v2";
export const GET_STORE_MENU_FOR_PICKUP = "menus/get-pickup-store-menu";
export const GET_STORE_MENU_FOR_DINE_IN = "menus/get-dine-in-store-menu";

//brand based urls
export const GET_STORE_DELIVERY_AREAS =
  "delivery-method/get-delivery-areas-of-single-store-business";

//user urls
export const GET_USER_ADDRESSES_WITH_AREAID =
  "user/getCurrentUserAddressesWithAreaId";
export const ADD_USER_ADDRESSES = "user/addUserAddress";
export const GET_USER_SAVED_PAYMENT_CARDS = "user/get-user-payment-cards";

//order urls
export const PLACE_ORDER = "order/place-order-v2";
export const GET_MY_ORDERS = "order/getCustomerOrders";
export const GET_ORDER_INFO_POPULATED = "order/get-order-tracking-info/";

//discount urls
export const GET_DISCOUNTS = "discounts/get-all-active-discounts-v2";
export const VALIDATE_DISCOUNT = "discounts/validate-discount-V2";

//payments
export const GET_HOSTED_PAYMENT_PAGE =
  "ordersCheckoutPayment/getHostedPaymentPage";

export const VERIFY_PAYMENT = "ordersCheckoutPayment/verifyPayment";

//checkout.com payments
export const GET_CHECKOUT_DOT_COM_PAYMENT =
  "orders-checkout-payment/checkout-dot-com/initiate-frames-payment-request/";
export const VERIFY_CHECKOUT_DOT_COM_PAYMENT =
  "orders-checkout-payment/checkout-dot-com/verify-payment-request";

//items
export const GET_DIRECT_ITEM_DETAIL = "items/get-item-detail";
export const GET_MENU_ITEM_DETAIL = "menus/get-menu-item-detail";
//user delivery Address

export const UPDATE_USER_DELIVERY_ADDRESS = "user/updateUserAddress";
export const GET_USER_DELIVERY_ADDRESS = "user/get-current-user-addresses-v2";

//payment Links

//1-checkout.com
export const GET_PAYMENT_LINK_INFO =
  "payment-links/get-payment-link-info-by-cart-id";
export const INITIATE_PAYMENT_LINK_REQUEST =
  "payment-links/checkout-dot-com/initiate-payment-request";
export const VERIFY_PAYMENT_LINK =
  "payment-links/checkout-dot-com/verify-payment-request";

//2-stripe
export const CREATE_STRIPE_PL_INTENT =
  "payment-links/stripe/create-payment-intent";
export const VERIFY_STRIPE_PL_INTENT =
  "payment-links/stripe/verify-payment-intent";
// loyalty policy

export const GET_BRAND_LOYALTY_POLICY = "loyalty/get-by-brand";
export const GET_USER_WALLET = "wallet/get-user-wallet";

// Stripe
export const CREATE_STRIPE_PAYMENT_INTENT =
  "orders-checkout-payment/stripe/create-payment-intent-v2";
export const VERIFY_STRIPE_PAYMENT_INTENT =
  "orders-checkout-payment/stripe/verify-payment-intent";
export const GET_STRPE_SAVED_CARDS =
  "orders-checkout-payment/stripe/get-customer-cards";

//user addresses
export const GET_USER_ADDRESSES = "user/get-user-addresses-v2";
export const ADD_USER_ADDRESS = "user/add-user-address";
export const DELETE_USER_ADDRESS = "user/remove-user-address";
export const GET_SINGLE_STORE_DELIVERY_AREA =
  "delivery-method/get-delivery-area-info";

//user feedback
export const SUBMIT_USER_FEEDBACK = "feedbacks/create";
export const GET_ORDER_DETAIL_BY_ID = "order/get-order-info/";

export const GET_STORES_BY_LAT_LNG = "store/get-stores-by-coordinates";
//MAP areas
export const GET_MAP_DELIVERY_AREAS =
  "delivery-method/get-brand-delivery-areas";
export const GET_STORES_BY_MAP_AREA = "store/get-stores-by-mapped-area";

export const CHECK_POLYGON_CONTAIN =
  "store/check-coordinates-in-store-delivery-range";

export const GET_MENU_BY_ID = "menus/get-menu-by-id";
export const GET_RECOMMENDED_ITEMS = "menus/get-recommend-items";

export const CHECK_STORE_AVAILABILITY = "store/check-store-availability";

export const UPDATE_USER_PROFILE = "user/update-user-profile";
