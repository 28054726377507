import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  singleStoreAreas: [],
  selectedArea: {},
};

const singleStoreReducer = createSlice({
  name: "singleStoreReducer",
  initialState,
  reducers: {
    setsingleStoreAreas: (state, action) => {
      state.singleStoreAreas = action.payload;
    },
    setSeletedArea: (state, action) => {
      state.selectedArea = action.payload;
    },
  },
});

export const { setsingleStoreAreas, setSeletedArea } =
  singleStoreReducer.actions;

export default singleStoreReducer.reducer;
