import { takeEvery, put, call } from "redux-saga/effects";
import API from "apiHelpers";
import i18next from "i18next";
import { BrandAction, Loader } from "store/actions";
import { UTILS } from "utils";
import { createAction } from "@reduxjs/toolkit";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { logErrorOnSentry } from "utils/Utils";
import C from "Constants";
import { AppAction } from "store/actions";
const resetAreaData = createAction("resetAreaData");

function* getBrandDeliveryAreas({ payload }) {
  yield put(Loader.setModalLoader(true));
  try {
    const response = yield call(API.getBrandMappedDeliveryAreas, {
      brandId: payload.brandId,
    });
    if (response?.success === 1 && response?.data?.deliveryAreas?.length >= 1) {
      yield put(BrandAction.getBrandDeliveryAreasSuccess(response?.data));
      if (response?.data?.deliveryMethod && payload?.updateMethod) {
        // yield put(AppAction.setShippingMethod(response?.data?.deliveryMethod));
        yield put(
          AppAction.setShippingMethodTemp(response?.data?.deliveryMethod)
        );
      }
    } else {
      yield put(
        BrandAction.getBrandDeliveryAreasFailure(errorHandler(response?.error))
      );
    }
  } catch (error) {
    yield put(
      BrandAction.getBrandDeliveryAreasFailure(errorHandler(error?.message))
    );
  } finally {
    yield put(Loader.disableLoader());
    yield put(Loader.setModalLoader(false));
  }
}

function* getBrandPickupStores(payload) {
  yield put(Loader.setModalLoader(true));
  try {
    const response = yield call(API.getBrandPickupStores, {
      brandId: payload.payload,
    });
    if (response?.success === 1 && response?.stores?.length >= 1) {
      yield put(BrandAction.getBrandStoresSuccess(response.stores));
    } else if (response?.success === 1 && response?.stores?.length === 0) {
      yield put(resetAreaData());
      UTILS.errorNotification(
        i18next?.t(
          "Unfortunately, the store has paused pickup service for a short time. Please try again after some time"
        )
      );
    } else {
      yield put(resetAreaData());
      yield put(
        BrandAction.getBrandStoresFailure(errorHandler(response?.error))
      );
    }
  } catch (error) {
    yield put(resetAreaData());
    yield put(BrandAction.getBrandStoresFailure(errorHandler(error?.message)));
  } finally {
    yield put(Loader.disableLoader());
    yield put(Loader.setModalLoader(false));
  }
}

function* getAllDiscounts({ payload }) {
  try {
    const user = UTILS.getCurrentUser(payload);
    const apiPayload = {
      brandId: payload?.brandId,
      ...(user?.user?.userId && { userId: user.user.userId }),
    };
    const response = yield call(API.getAllDiscounts, apiPayload);
    if (response?.success === 1 && response?.discount) {
      yield put(BrandAction.getDiscountsSuccess(response.discount));
    } else {
      if (payload?.page === C.COUPONS_POPUP) {
        yield put(
          BrandAction.getDiscountsFailure({
            page: C.COUPONS_POPUP,
            error: null,
          })
        );
        logErrorOnSentry(
          `critical issue ->coupons not fetching on checkout Page coupons popup modal ${response?.message}`
        );
      } else {
        yield put(
          BrandAction.getDiscountsFailure({
            error: errorHandler(response?.message),
          })
        );
      }
      yield put(Loader.disableLoader());
    }
  } catch (error) {
    if (payload?.page === C.COUPONS_POPUP) {
      yield put(
        BrandAction.getDiscountsFailure({
          page: C.COUPONS_POPUP,
          error: null,
        })
      );
      logErrorOnSentry(
        `critical issue ->coupons not fetching on checkout Page coupons popup modal ${error}`
      );
    } else {
      yield put(
        BrandAction.getDiscountsFailure({
          error: errorHandler(error?.message),
        })
      );
    }
    yield put(Loader.disableLoader());
  }
}
function* userSaga() {
  yield takeEvery(BrandAction.getDiscounts.type, getAllDiscounts);
  yield takeEvery(BrandAction.getBrandStores.type, getBrandPickupStores);
  yield takeEvery(
    BrandAction.getBrandDeliveryAreas.type,
    getBrandDeliveryAreas
  );
}

export default userSaga;
