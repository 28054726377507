import { call, put, takeLatest } from "redux-saga/effects";
import API from "apiHelpers";
import C from "Constants";
import { ItemAction } from "store/actions";
import { Loader } from "store/actions";
import i18next from "i18next";

//This is for Outlet Based
let message = i18next?.t(
  "We're sorry, but something went wrong. Please try again"
);
function* getItemInfoById({ payload }) {
  yield put(Loader.setLoading(true));

  const { type } = payload;
  try {
    const response =
      type === C.WITH_OUT_STORE_ID
        ? yield call(API.getItemDetailWithOutStoreId, payload)
        : yield call(API.getItemDetailWithStoreId, payload);
    if (response?.success === 1 && response?.data?.itemInfo) {
      if (response?.data?.isMenuActive === false) {
        yield put(ItemAction.setMenuActiveStatus(true));
      }

      yield put(ItemAction.getItemInfobyIdSuccess(response.data));
      yield put(ItemAction.setCategory(payload.categoryId));
    } else {
      yield put(
        ItemAction.getItemInfobyIdFailed({
          message: response?.message || response?.data?.message || message,
          type: "error",
        })
      );
    }
  } catch (error) {
    yield put(
      ItemAction.getItemInfobyIdFailed({
        message: error?.error?.message || message,
        type: "error",
      })
    );
  } finally {
    yield put(Loader.setLoading(false));
  }
}

function* itemSaga() {
  yield takeLatest(ItemAction.getItemInfobyIdStart.type, getItemInfoById);
}

export default itemSaga;
