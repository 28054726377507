import { apiSlice } from "apiHelpers/ApiSlice";
import * as URL from "apiHelpers/api/ApiUrls";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { StoreAction } from "store/actions";
import { Loader } from "store/actions";
import { errorNotification } from "utils/Utils";
import { logErrorOnSentry } from "utils/Utils";

const menuService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGetMenuById: builder.mutation({
      query: (body) => ({
        url: URL.GET_MENU_BY_ID,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        try {
          const { data } = await queryFulfilled;
          if (data?.success && data?.data) {
            dispatch(StoreAction.setMenu(data?.data));
            if (data?.data?.categories?.length >= 1) {
              const firstCategory = data?.data?.categories[0]?.categoryId?._id;
              dispatch(StoreAction.setSelectedCategory(firstCategory));
            }
          } else {
            errorNotification(errorHandler(data?.message));
            logErrorOnSentry(
              "re-oulling menu failed",
              "error",
              JSON.stringify(data)
            );
            dispatch(StoreAction.setMenu(null));
          }
        } catch (err) {
          errorNotification(errorHandler(err?.error?.message));
          logErrorOnSentry(
            "re-oulling menu failed",
            "error",
            JSON.stringify(err)
          );
        } finally {
          dispatch(Loader.disableLoader());
        }
      },
    }),
  }),
});

export default menuService;
export const { useGetGetMenuByIdMutation, useLazyGetGetMenu } = menuService;
