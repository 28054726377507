import React from "react";
import { t } from "i18next";
const StoreTable = ({ tabelNo }) => {
  return (
    <span
      href="#"
      className="bg-gray-100 flex items-center rounded bordekr border-brand-base-primary mx-4 shadow-sm borjder-dashed py-4 cursor-pointer hover:border-solid px-2"
    >
      <div className="px-2">
        <svg
          fill="currentColor"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          className="promo-code__icon text-brand-base-primary w-12 h-12"
          viewBox="0 0 545.062 545.062"
        >
          <g>
            <polygon
              points="24.91,320.344 103.744,320.344 114.387,406.406 124.312,406.406 124.312,320.344 124.312,291.656 100.196,291.656 
		27.416,291.656 26.833,291.656 17.394,138.656 0,138.656 0,291.656 0,301.219 0,320.344 0,406.406 17.394,406.406 	"
            />
            <path
              d="M33.469,282.094h86.062c2.639,0,4.781-2.142,4.781-4.781s-2.142-4.781-4.781-4.781H33.469
		c-2.639,0-4.781,2.142-4.781,4.781S30.83,282.094,33.469,282.094z"
            />
            <polygon
              points="515.84,291.656 515.256,291.656 442.476,291.656 411.188,291.656 411.188,320.344 411.188,406.406 
		428.285,406.406 438.929,320.344 517.762,320.344 525.277,406.406 545.062,406.406 545.062,320.344 545.062,301.219 
		545.062,291.656 545.062,138.656 525.277,138.656 	"
            />
            <path
              d="M411.188,277.312c0,2.64,2.142,4.781,4.781,4.781h86.062c2.64,0,4.781-2.142,4.781-4.781s-2.142-4.781-4.781-4.781h-86.062
		C413.329,272.531,411.188,274.673,411.188,277.312z"
            />
            <path
              d="M84.925,205.594h77.638v200.812h14.784l11.484-162.562h158.804l11.485,162.562h13.817V205.594h87.2
		c4.657,0,8.425-3.768,8.425-8.425v-2.275c0-4.657-3.768-8.425-8.425-8.425H84.925c-4.657,0-8.425,3.768-8.425,8.425v2.275
		C76.5,201.826,80.268,205.594,84.925,205.594z M344.929,205.594l1.349,19.125H190.179l1.348-19.125H344.929z"
            />
          </g>
        </svg>
      </div>
      <div className=" w-full px-2 text-17x text-brand-base-primary">
        {t("Selected Table Number is")}
        <span className="font-bold px-1"> {tabelNo}</span>
      </div>
    </span>
  );
};
export default StoreTable;
