import React from "react";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { SVGICONS } from "config";
import { AppleIcon, GooglePay, PlusIconn } from "icons";
import { StoreAction } from "store/actions";
import { useDetectBrowserWallets } from "hooks";
import { PaymentCardsAction } from "store/actions";
import C from "Constants";
import { PaymentMethodAction } from "store/actions";
import { useLang } from "hooks";
import useBrandLosgisticIntegrations from "hooks/useBrandLosgisticIntegrations";
import CardMachine from "icons/CardMachine";
import CashMachine from "icons/CashMachine";
import { getCreditCardObjByOrderType } from "utils/Utils";
import useScrollToBottomPaymentWidget from "hooks/useScrollToBottomPaymentWidget";

const SortPaymentOptions = (paymentOptions) => {
  if (!paymentOptions || paymentOptions?.length <= 0) return [];
  const options = [...paymentOptions];
  const creditCard = options?.find(
    (paymentOption) => paymentOption.paymentType === C.CREDIT_CARD
  );
  if (creditCard) {
    const creditCardIndex = options?.indexOf(creditCard);
    options.splice(creditCardIndex, 1);
    options.unshift(creditCard);
  }
  return options;
};

const CheckoutPaymentMethod = ({ paymentOptions, children }) => {
  const dispatch = useDispatch();
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );
  const scrollToBottom = useScrollToBottomPaymentWidget(selectedPaymentType);
  const { currentLang } = useLang();
  const { integrationsByName } = useBrandLosgisticIntegrations();
  const supportedBrowserWallets = useDetectBrowserWallets();
  const { selectedPayment, selectedStore } = useSelector(
    (state) => state.store
  );
  const { orderType } = useSelector((state) => state.AppReducer);

  const setPaymentMEthod = (payment, type) => {
    dispatch(StoreAction.setSelectedPaymentMethod(payment));
    dispatch(PaymentMethodAction.setSelectedPaymentType(type));
    dispatch(PaymentCardsAction.resetSelectedCards());
  };

  const IsONlinePaymentEnabled = (paymentOptions) => {
    if (!paymentOptions || paymentOptions?.length <= 0) return null;
    if (!GetcardText()) return null;

    const creditCard = paymentOptions?.find(
      (paymentOption) => paymentOption.paymentType === C.CREDIT_CARD
    );
    if (creditCard) {
      return creditCard;
    } else return null;
  };
  const GetcardText = () => {
    if (integrationsByName[C.STRIPE] || integrationsByName[C.CHECKOUT_DOT_COM])
      return t("Add new card");
    if (integrationsByName[C.TELR]) return t("Pay with card");
    return null;
  };

  return (
    <>
      <div className="bg-white mt-5 py-4 border-t-4 border-gray-200">
        <h4 className="text-outer-space text-17x font-semibold mx-4 mb-4">
          {t("Pay with")}
        </h4>
        {IsONlinePaymentEnabled(paymentOptions) &&
          integrationsByName[C.STRIPE] && (
            <>
              {supportedBrowserWallets?.applePay && (
                <div
                  className="p4 mb-3 border border-gray-200 text-black mx-4 rounded-md px-4 py-3"
                  onClick={() => {
                    setPaymentMEthod(
                      getCreditCardObjByOrderType(orderType, selectedStore),
                      C.WALLET.APPLE_PAY
                    );
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div className="text-16x font-normal">
                      <span className="me-3">
                        <AppleIcon color="text-black" />
                      </span>
                      {t("Apple pay")}
                    </div>
                    <div className="flex">
                      <span className="inline-flex w-6 h-6">
                        <input
                          type="radio"
                          name="Select Type"
                          className="hidden"
                        />
                        <span className="w-6 h-6 inline-flex ">
                          {selectedPayment?.paymentType === C.CREDIT_CARD &&
                          selectedPaymentType === C.WALLET.APPLE_PAY ? (
                            <SVGICONS.RadioCheck color="text-black" />
                          ) : (
                            <SVGICONS.RadioUnCheck color="text-black" />
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {supportedBrowserWallets?.googlePay && (
                <div
                  className="p4 mb-3 border border-gray-200 mx-4 rounded-md px-4 py-3"
                  onClick={() => {
                    setPaymentMEthod(
                      getCreditCardObjByOrderType(orderType, selectedStore),
                      C.WALLET.GOOGLE_PAY
                    );
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div className="text-16x font-normal">
                      <span className="me-3">
                        <GooglePay color="text-black" />
                      </span>
                      {t("Google pay")}
                    </div>
                    <div className="flex">
                      <span className="inline-flex w-6 h-6">
                        <input
                          type="radio"
                          name="Select Type"
                          className="hidden"
                        />
                        <span className="w-6 h-6 inline-flex ">
                          {selectedPayment?.paymentType === C.CREDIT_CARD &&
                          selectedPaymentType === C.WALLET.GOOGLE_PAY ? (
                            <SVGICONS.RadioCheck color="text-black" />
                          ) : (
                            <SVGICONS.RadioUnCheck color="text-black" />
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        {IsONlinePaymentEnabled(paymentOptions) &&
          children &&
          children?.length >= 1 &&
          children[0]}

        {SortPaymentOptions(paymentOptions)?.map((paymentMethod, i) => {
          if (paymentMethod?.paymentType === C.CREDIT_CARD && !GetcardText())
            return null;
          return (
            <div
              key={i}
              className="p4 mb-3 border border-gray-200 mx-4 rounded-md px-4 py-3"
              onClick={() => {
                if (paymentMethod?.paymentType === C.CREDIT_CARD) {
                  setPaymentMEthod(paymentMethod, C.WALLET.NEW_CARD);
                  scrollToBottom();
                } else {
                  setPaymentMEthod(paymentMethod, C.WALLET.CASH);
                }
              }}
            >
              <div className="flex justify-between">
                <div className="text-16x font-normal">
                  <span className="me-3">
                    {paymentMethod?.paymentType === C.CREDIT_CARD && (
                      <PlusIconn color="text-black" />
                    )}
                    {paymentMethod?.paymentType === "CASH" && <CashMachine />}
                    {paymentMethod?.paymentType === "CARD_MACHINE" && (
                      <CardMachine />
                    )}
                  </span>
                  {paymentMethod?.paymentType === C.CREDIT_CARD
                    ? GetcardText()
                    : paymentMethod?.[currentLang ?? "EN"].name}
                </div>
                <div className="flex">
                  <span className="inline-flex w-6 h-6">
                    <input type="radio" name="Select Type" className="hidden" />
                    <span className="w-6 h-6 inline-flex ">
                      {selectedPaymentType === C.WALLET.CASH &&
                      paymentMethod?.paymentType !== C.CREDIT_CARD &&
                      selectedPayment?._id === paymentMethod?._id ? (
                        <SVGICONS.RadioCheck color="text-black" />
                      ) : selectedPayment?.paymentType === C.CREDIT_CARD &&
                        selectedPaymentType === C.WALLET.NEW_CARD &&
                        paymentMethod?.paymentType === C.CREDIT_CARD ? (
                        <SVGICONS.RadioCheck color="text-black" />
                      ) : (
                        <SVGICONS.RadioUnCheck color="text-black" />
                      )}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
        {children && children?.length >= 2 && children[1]}
      </div>
      <div id="payment-methods"></div>
    </>
  );
};

export default CheckoutPaymentMethod;
