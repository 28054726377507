export const libraries = ["geometry", "places"];
export const circleOptions = {
  strokeColor: "#3d85c6",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#66666",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 5,
  zIndex: 12,
};
