import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { UTILS } from "utils";
function useIsLoggedIn() {
  const history = useHistory();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  useEffect(() => {
    const user = UTILS.getCurrentUser(brandInfo?.brand?._id);
    if (user?.token) {
      history.goBack();
    }
  }, [brandInfo?.brand?._id]);
}

export default useIsLoggedIn;
