import React from "react";
import { t } from "i18next";
const RestheroSmallFooter = () => {
  return (
    <div className="text-center mb-4">
      <p>
        <img
          src={process.env.REACT_APP_COMPANY_LOGO}
          alt="resthero"
          className="me-2 inline-block w-5"
        />
        {t("PoweredBy", {
          companyName: process.env.REACT_APP_COMPANY_NAME,
        })}
      </p>
    </div>
  );
};

export default RestheroSmallFooter;
