import React, { useEffect } from "react";
import API from "apiHelpers";
import { t } from "i18next";

import { errorHandler } from "apiHelpers/api/errorHandler";
import { useDispatch, useSelector } from "react-redux";
import { RegisterAction } from "store/actions";
import { UTILS } from "utils";
import { handleLocalStorage } from "utils/storage";

function useUpdateUserProfile({ setUserInfoModal, isOpenUserInfoModal }) {
  const dispatch = useDispatch();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  const [formstate, setFormState] = React.useState({
    values: {
      email: "",
      name: "",
      loading: false,
      error: "",
    },
    errors: {
      email: "",
      name: "",
    },
  });

  const onFinish = async () => {
    if (!validateForm()) return;
    const obj = {
      brandId: brandInfo?.brand?._id,
      name: formstate?.values?.name,
      email: formstate?.values?.email,
    };
    await updateUserProfile(
      obj,
      updateFormState,
      setUserInfoModal,
      updateFormError
    );
  };

  const updateFormState = (key, val) => {
    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const updateFormError = (key, val) => {
    setFormState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [key]: val,
      },
    }));
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateForm = () => {
    if (!brandInfo?.brand?._id) {
      updateFormError("name", t("Please login to continue"));
      return false;
    }

    if (!formstate?.values?.name) {
      updateFormError("name", t("Please enter your name"));
      return false;
    }
    if (
      formstate?.values?.name &&
      formstate?.values?.name?.trim()?.length < 1
    ) {
      updateFormError("name", t("Name must be at least 1 character"));
      return false;
    }
    if (formstate?.values?.email && !validateEmail(formstate?.values?.email)) {
      updateFormError("email", t("Please enter a valid email address"));
      return false;
    }
    return true;
  };

  //load the values from the store
  useEffect(() => {
    const userdata = UTILS.getCurrentUser(brandInfo?.brand?._id);
    if (userdata?.user) {
      userdata?.user?.name && updateFormState("name", userdata?.user?.name);
      userdata?.user?.email && updateFormState("email", userdata?.user?.email);
    }
  }, [isOpenUserInfoModal]);

  async function updateUserProfile() {
    try {
      updateFormState("loading", true);
      const data = {
        brandId: brandInfo?.brand?._id,
        name: formstate?.values?.name,
        email: formstate?.values?.email,
      };
      const response = await API.updateUserProfile(data);
      if (response?.success === 1) {
        const user = UTILS.getCurrentUser(data.brandId);
        const name = response.data.name;
        const email = response.data.email;
        const updatedUser = {
          ...user,
          user: {
            ...user?.user,
            name,
            email,
          },
        };
        handleLocalStorage.setItem(data.brandId, updatedUser);
        dispatch(RegisterAction.optSuccess(updatedUser));

        updateFormState("name", "");
        updateFormState("email", "");
        return response.data;
      } else {
        const errorMsg = response?.message || response.data;
        updateFormState("error", errorHandler(errorMsg));
      }
    } catch (error) {
      const errorMsg = error?.error?.message || error?.message;
      updateFormState("error", errorHandler(errorMsg));
    } finally {
      setUserInfoModal(false);
      updateFormState("loading", false);
    }
  }
  return {
    updateUserProfile,
    formstate,
    setFormState,
    onFinish,
    updateFormState,
  };
}

export default useUpdateUserProfile;
