import { useMemo } from "react";
function useMenuSearch(searchTerm, menu) {
  const Menu = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      return menu?.categories;
    }
    return menu?.categories?.reduce((r, { categoryId, items }) => {
      let item = items?.filter((item) =>
        item?.itemId?.baseLanguage?.title
          ?.toLowerCase()
          ?.includes(searchTerm?.trim())
      );
      if (item && item.length) r.push({ categoryId, items: [...item] });
      return r;
    }, []);
  }, [searchTerm, menu?.categories]);
  return {
    filteredMenu: Menu,
  };
}

export default useMenuSearch;
