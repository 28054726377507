import { createSlice } from "@reduxjs/toolkit";
import C from "Constants";
const initialState = {
  catalogueType: "OUTLET_BASED_CATALOGUE",
  DomainType: C.TEMPORARAY_DOMAIN,
  shippingMethod: null,
  shippingMethodTemp: null,
  orderType: null,
  language: "en",
  welcomeCouponModal: true,
  triggerStoreApiAfterAddressSaveOnAppStrt: {
    status: false,
    page: null,
  },
};
const AppReducer = createSlice({
  name: "AppReducer",
  initialState,
  reducers: {
    setcatalogueType: (state, action) => {
      state.catalogueType = action.payload;
    },
    setTriggerStoreApiAfterAddressSaveOnAppStrt: (state, action) => {
      state.triggerStoreApiAfterAddressSaveOnAppStrt = action.payload;
    },
    setDomainType: (state, action) => {
      state.DomainType = action.payload;
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setLocale: (state, action) => {
      state.language = action.payload;
    },
    setWelcomeCouponModal: (state, action) => {
      state.welcomeCouponModal = action.payload;
    },
    setShippingMethod: (state, action) => {
      state.shippingMethod = action.payload;
    },
    setShippingMethodTemp: (state, action) => {
      state.shippingMethodTemp = action.payload;
    },
  },
});

export const {
  setTriggerStoreApiAfterAddressSaveOnAppStrt,
  setShippingMethodTemp,
  setcatalogueType,
  setDomainType,
  setOrderType,
  setLocale,
  setWelcomeCouponModal,
  setShippingMethod,
} = AppReducer.actions;
export default AppReducer.reducer;
