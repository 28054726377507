import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { OrderSuccessTick } from "icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FixedFooter from "components/FixedFooter";
import PageHeader from "components/PageHeader";
import { NotPersistAction } from "store/actions";
import C from "Constants";
import { useLang } from "hooks";
import { useCustomHistory } from "hooks";
import useQueryParam from "modules/dine-in/useQueryParam";
import useEventTracker from "hooks/useEventTracker";

function OrderPlaced(props) {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { currentLang } = useLang();
  const queryParam = useQueryParam();
  const [shopUrl, setShopUrl] = useState(null);
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  useEventTracker({ name: "ORDER_PLACED", shouldLog: true, orderType: null });
  useEventTracker({ name: "ORDER_PLACED", shouldLog: true, orderType });

  const share = (DOMAIN_NAME, url) => {
    dispatch(NotPersistAction.toggleOrderPlaceModel(false));
    const message = `Hi, I have just ordered from ${brandInfo?.brand?.brandName} You can also avail BIG discounts as soon as you place your first order. ${url} `;
    window.open(`https://wa.me/?text=${message}`, "_blank");
  };

  const goToDineHomePage = (url) => {
    history.push({
      pathname: url,
      search: queryParam,
    });
  };

  const shareOnWhatsapp = () => {
    if (DomainType === C.TEMPORARAY_DOMAIN) {
      const DOMAIN_NAME = params?.domainName;
      let url = C.PAYMENT_REDIRECT_URL + DOMAIN_NAME;
      share(DOMAIN_NAME, url);
      if (DOMAIN_NAME) {
        if (orderType?.serviceType === C.SERVICE_TYPE.DINE_IN) {
          goToDineHomePage(`/${DOMAIN_NAME}/dine-in`);
        } else {
          history.push(`/${DOMAIN_NAME}/`);
        }
      }
    } else {
      let url = C.PAYMENT_REDIRECT_URL || "";
      share(brandInfo.brand?.brandName, url);
      if (orderType?.serviceType === C.SERVICE_TYPE.DINE_IN) {
        goToDineHomePage(`/dine-in`);
      } else {
        history.push(`/`);
      }
    }
  };

  useEffect(() => {
    if (brandInfo?.storeFrontURL) {
      setShopUrl(brandInfo.storeFrontURL);
    }
  }, []);

  const goBack = () => {
    dispatch(NotPersistAction.toggleOrderPlaceModel(false));
    if (props?.gotoHomePage) {
      props.gotoHomePage();
    } else {
      router("/", `/${params?.domainName}`, DomainType);
    }
  };

  return (
    <div
      className={`create-address fixced top-0 left-0 min-h-app-height bg-white overflow-y-auto ${
        currentLang === "AR" ? "border-sc-l" : "border-sc"
      }`}
    >
      <PageHeader
        pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full shadow-sm relative"
        title={t("Order Placed")}
        url={true}
        handleClick={goBack}
      />

      <div className="felx jsutify-center px-4 pt-7 cursor-pointer">
        <div className="py-20 px-4 ">
          <OrderSuccessTick />
          <h1 className="text-black font-medium leading-normal mt-6 text-center text-24x">
            {t("Thanks, Friend!")}
            <br />
            {t("Your order has been sent")}
          </h1>
        </div>
        {shopUrl && (
          <div className="success-order__share">
            <span
              onClick={shareOnWhatsapp}
              className="flex items-center justify-center px-12 py-2 border border-brand-base-primary text-brand-base-primary rounded"
            >
              <svg
                xmlns="https://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
                fill="currentColor"
                className="w-5 h-5  text-brand-base-primary"
              >
                <path d="M13 5.419V8h6a1 1 0 011 1v12a1 1 0 01-1 1H5a1 1 0 01-1-1V9a1 1 0 011-1h6V5.416L9.87 6.545a.993.993 0 01-1.407-.007 1 1 0 01-.007-1.407l2.842-2.842A.984.984 0 0111.995 2l.008.003c.254 0 .506.097.696.286l2.841 2.842a.993.993 0 01-.006 1.407 1 1 0 01-1.408.007L13 5.419zM11 10V8H7a1 1 0 110 2h4zm2 0h5V8h-5v2zm-2 0H6v10h12V10h-5v5.001a1 1 0 01-2 0V10zm7 0h-1a1 1 0 010-2h1v2z"></path>
              </svg>
              <div className="px-2 text-14x font-bold">{t("Share")}</div>
            </span>
            <p className="mt-6 text-14x">{t("Share with your friends!")}</p>
          </div>
        )}
      </div>

      <FixedFooter page={false} from="orderPlced ">
        <span className="block w-full" onClick={props?.trackOrder}>
          <span className="block text-center py-4 rounded text-brand-text-primary bg-brand-base-primary cursor-pointer text-16x w-full">
            <span className="">
              <div className="">
                <span className="font-bold">{t("Track Order")}</span>
              </div>
            </span>
          </span>
        </span>
      </FixedFooter>
    </div>
  );
}

export default OrderPlaced;
