import React from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BasketBody from "../components/BasketBody";
import BasketFooterOverview from "../components/BasketFooterOverview";

import { Spinner } from "components";
import PageHeader from "components/PageHeader";
import FixedFooter from "components/FixedFooter";
import AddUserInfoForm from "components/Modals/AddUserInfoForm";

import { useCustomHistory, useUpdateDeliveryFee } from "hooks";
import * as ROUTE_CONSTANTS from "routes/constants";
import useShowUserInfoModal from "../useShowUserInfoModal";

const Basket = () => {
  const params = useParams();
  const router = useCustomHistory();
  const { DomainType } = useSelector((state) => state.AppReducer);
  let url = ROUTE_CONSTANTS.OUTLET_STORE[1];
  const { isOpenUserInfoModal, setUserInfoModal } = useShowUserInfoModal();

  const goBack = () => router(url, `/${params?.domainName}${url}`, DomainType);
  useUpdateDeliveryFee();

  return (
    <div className="basket-wrapper store-page-popup basket-wrapper--mobile relative">
      <div className="basket basket--mobile w-full bg-white">
        <div className="basket-contents basket-contents--mobile relative">
          <div>
            <div className="w-full bg-white flex flex-nowrap flex-col justify-start items-start">
              <PageHeader
                pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full border-b-2 relative"
                title={t("Your Basket")}
                url={true}
                handleClick={goBack}
              />
              <BasketBody />
            </div>
          </div>
        </div>

        <AddUserInfoForm
          {...{ isOpenUserInfoModal, setUserInfoModal }}
          closeOnBgClick={() => {}}
        />

        <FixedFooter page={true} from="basket">
          <BasketFooterOverview />
        </FixedFooter>
      </div>
    </div>
  );
};

const BasketContainer = () => {
  const { isLoading } = useSelector((state) => state?.loader);
  const { loading } = useSelector((state) => state?.brand);
  if (isLoading || loading) return <Spinner />;
  return <Basket />;
};
export default BasketContainer;
