import { t } from "i18next";
import { useSelector } from "react-redux";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import usePlaceOrderHelper from "../../../usePlaceOrderHelper";

import {
  stripeCreatePaymentMethod,
  verifyStripePaymentIntent,
} from "helpers/payment_helpers/stripe/utils";

import { getStripeWalletCreateIntentObj } from "utils/payments";
import useScrollToBottomPaymentWidget from "hooks/useScrollToBottomPaymentWidget";
import { logErrorOnSentry } from "utils/Utils";

function StripeHelper(setcardProcessing, handleSuceessOrderRoute) {
  const { logError, brand_based_place_order, outlet_based_place_order } =
    usePlaceOrderHelper(handleSuceessOrderRoute);
  let fallBackMessage = t("Bank declined payment, please try again");
  const { stripeSelectedCard } = useSelector(
    (state) => state.PaymentCardsReducer
  );
  //services
  // stripe
  const stripe = useStripe();
  const elements = useElements();
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );
  const scrollToBottom = useScrollToBottomPaymentWidget(selectedPaymentType);

  const process_stripe_payment = async ({ amount, storeId, placeMyOrder }) => {
    try {
      if (!stripe || !elements) {
        logError(
          t(
            "Online payment is facing some issues, please use other payment method"
          ),
          true,
          "error"
        );
        logErrorOnSentry(
          "stripe or elements are not available",
          "error",
          JSON.stringify({ stripe, elements })
        );
        return;
      }

      const cardElement = elements?.getElement(CardElement);
      setcardProcessing(true);
      if (
        !stripeSelectedCard?.isStoredCard &&
        !stripeSelectedCard?.cardInfo?.id
      ) {
        const { error } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
        if (error) {
          logError(error.message, true, "error");
          setcardProcessing(false);
          scrollToBottom();
          return;
        } else {
          //create payment intent with new card
          const createStripePaymentIntent = await stripeCreatePaymentMethod(
            getStripeWalletCreateIntentObj({
              amount,
              storeId,
              stripeSelectedCard: null,
            })
          );
          //create payment intent
          if (
            createStripePaymentIntent?.success === 1 &&
            createStripePaymentIntent?.gatewayData?.data?.client_secret &&
            createStripePaymentIntent?.gatewayData?.isVerified === false
          ) {
            const clientSecret =
              createStripePaymentIntent?.gatewayData?.data?.client_secret;
            //confirm payment
            const confirmpayload = await stripe.confirmCardPayment(
              clientSecret,
              {
                payment_method: {
                  card: elements.getElement(CardElement),
                },
              }
            );
            if (
              confirmpayload?.paymentIntent?.status === "succeeded" &&
              confirmpayload?.paymentIntent?.id
            ) {
              verifyStripePaymentIntent(
                confirmpayload?.paymentIntent?.id,
                placeMyOrder
              );
            }
            //confirm payment failed
            else {
              logError(
                confirmpayload?.error?.message || fallBackMessage,
                true,
                "error"
              );
            }
          }
          //create payment intent failed
          else {
            logError(
              createStripePaymentIntent?.error?.message || fallBackMessage,
              true,
              "error"
            );
          }
        }
      } else if (
        stripeSelectedCard?.isStoredCard &&
        stripeSelectedCard?.cardInfo?.id
      ) {
        //create payment intent with stored card

        const createStripePaymentIntent = await stripeCreatePaymentMethod(
          getStripeWalletCreateIntentObj({
            amount,
            storeId,
            stripeSelectedCard,
          })
        );
        if (
          createStripePaymentIntent?.success === 1 &&
          createStripePaymentIntent?.gatewayData?.data?.id &&
          createStripePaymentIntent?.gatewayData?.isVerified === true
        ) {
          verifyStripePaymentIntent(
            createStripePaymentIntent?.gatewayData?.data?.id,
            placeMyOrder
          );
        }
        //create payment intent failed
        else {
          logError(
            createStripePaymentIntent?.error?.message || fallBackMessage,
            true,
            "error"
          );
        }
      } else {
        logError(
          t(
            "If you have chosen an online payment method, you can select an existing card or add a new one"
          ),
          true,
          "error"
        );
      }
    } catch (error) {
      logError(error?.message || fallBackMessage, true, "error");
    } finally {
      setcardProcessing(false);
    }
  };

  const process_stripe_payment_brand_based = async ({ amount, storeId }) => {
    process_stripe_payment({
      amount,
      storeId,
      placeMyOrder: brand_based_place_order,
    });
  };

  const process_stripe_payment_outlet_based = async ({ amount, storeId }) => {
    process_stripe_payment({
      amount,
      storeId,
      placeMyOrder: outlet_based_place_order,
    });
  };

  return {
    process_stripe_payment,
    process_stripe_payment_brand_based,
    process_stripe_payment_outlet_based,
  };
}

export default StripeHelper;
