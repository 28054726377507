import React from "react";
import moment from "moment";
import C from "Constants";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { FormatPreOrderingTime } from "utils/OrderUtils";

function PreOrderingDisplayTimingWidget({
  setPreOrderingSlotWidget = () => {},
  route,
}) {
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);

  return (
    <div
      onClick={() => setPreOrderingSlotWidget(true)}
      className={`px-4  ${route === "checkout" ? "" : "mt-2"}`}
    >
      {orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER
        ? orderTiming?.isPreOrderAvailable &&
          orderTiming?.preOrderSlot && (
            <div className="border border-red-600 p-2 py-1 mx-2 px-4 rounded">
              {t("You are ordering for")}
              <span className="text-red-k500">
                {orderTiming?.preOrderSlot?.day === C.TODAY
                  ? t(orderTiming?.preOrderSlot?.day)
                  : moment(orderTiming?.preOrderSlot?.day).format(
                      "dddd, DD MMM"
                    )}
                {", "}

                {FormatPreOrderingTime(orderTiming?.preOrderSlot?.time)}
              </span>
            </div>
          )
        : null}
    </div>
  );
}

export default PreOrderingDisplayTimingWidget;
