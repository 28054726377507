import { useMapContext } from "contexts/googleMap";
import { useState } from "react";
import { UTILS } from "utils";
import { logErrorOnSentry } from "utils/Utils";
import { getAreaCityAndCountry } from "utils/googleGeoCode";
import { useDispatch } from "react-redux";
import { DeliveryAddressAction } from "store/actions";

function useConfirmGeoLocation() {
  const [error, setError] = useState({
    open: false,
    message: "",
  });
  const setState = (key, value) => {
    setError((prev) => ({ ...prev, [key]: value }));
  };
  const dispatch = useDispatch();
  const { googlemapState, updateMapContext } = useMapContext();
  const updateMapStreetAddress = async () => {
    try {
      if (!googlemapState?.userLocation?.coordinates) return false;
      const fomattedAddress = await getAreaCityAndCountry(
        googlemapState?.userLocation?.coordinates.lat,
        googlemapState?.userLocation?.coordinates.lng,
        "en"
      );
      if (fomattedAddress?.streetAddress) {
        updateMapContext("mapAddress", fomattedAddress);
        return fomattedAddress;
      } else return false;
    } catch (error) {
      logErrorOnSentry(
        `Add address on home-page---error in updateMapStreetAddress ${error?.message}`,
        "error",
        JSON.stringify(error)
      );
      setState("open", true);
      setState("message", error);
      return false;
    }
  };

  const confirmGeoLocation = async () => {
    try {
      const isValidateZoom = UTILS.validateZoom(googlemapState);
      if (!isValidateZoom?.isValid) {
        setState("open", true);
        setState("message", isValidateZoom?.error);
        return {
          isValid: false,
          message: isValidateZoom?.error,
        };
      }
      const geocodeByAddress = await updateMapStreetAddress();
      const isValidStreetAddress = UTILS.isValidStreetAddress(
        googlemapState?.mapAddress?.streetAddress,
        googlemapState
      );

      if (!isValidStreetAddress?.isValid || !geocodeByAddress?.streetAddress) {
        setState("open", true);
        setState("message", isValidStreetAddress?.error);
        return {
          isValid: false,
          message: isValidStreetAddress?.error,
        };
      }
      const { zoom, userLocation } = googlemapState;

      dispatch(DeliveryAddressAction.setTempZoomHome(zoom));
      dispatch(
        DeliveryAddressAction.setTempUserLocationHome(userLocation.coordinates)
      );
      dispatch(DeliveryAddressAction.setTempMapAddressHome(geocodeByAddress));
      return {
        isValid: true,
        zoom,
        userLocation: userLocation?.coordinates,
        mapAddress: geocodeByAddress,
      };
    } catch (error) {
      setState("open", true);
      setState("message", error);
      return {
        isValid: false,
        message: error,
      };
    } finally {
    }
  };
  return {
    geoLocation: error,
    setGeoLocationError: setState,
    confirmGeoLocation,
  };
}

export default useConfirmGeoLocation;
