import React from "react";
import { useSelector } from "react-redux";
import OrderSuccess from "components/checkout/OrderPlaced";
import { useCustomHistory } from "hooks";
import { useParams } from "react-router-dom";
import { errorNotification } from "utils/Utils";

function Index() {
  const params = useParams();
  const router = useCustomHistory();
  const { placedOrder } = useSelector((rstate) => rstate.order);
  const { DomainType } = useSelector((rstate) => rstate.AppReducer);

  const trackOrder = () => {
    let url = "/track-order/";
    document.body.removeAttribute("style");
    if (placedOrder?.savedOrder) {
      const { savedOrder } = placedOrder;
      router(
        `${url}${savedOrder?.orderNumber}`,
        `/${params.domainName}${url}${savedOrder?.orderNumber}`,
        DomainType
      );
    } else if (params?.orderId) {
      router(
        `/track-order/${params?.orderId}`,
        `/${params?.domainName}/track-order/${params?.orderId}`,
        DomainType
      );
    } else {
      errorNotification("Order not found");
    }
  };

  return <OrderSuccess {...{ trackOrder }} />;
}

export default Index;
