import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import useGetStoreInfo from "./useGetStoreInfo";
import { getAreaCityAndCountry } from "utils/googleGeoCode";
import C from "Constants";
import { useLang } from "hooks";

function useSetStoreMenu() {
  const dispatch = useDispatch();
  const { currentLang } = useLang();

  const getStoreInfoWithMenu = useGetStoreInfo();
  const { shippingMethod } = useSelector((state) => state?.AppReducer);
  const getAreaId = (store) => {
    if (
      shippingMethod === C.SHIPPING_METHODS.MAPPED_AREA_BASED &&
      store?.mappedAreaInfo
    ) {
      return store?._id;
    } else if (
      shippingMethod === C.SHIPPING_METHODS.AREA_BASED &&
      store?.areaID
    ) {
      return store?.areaID?._id;
    }
    return null;
  };
  const setstoremenu = async (
    stores,
    latlng,
    updateTemporaryLocation = () => {}
  ) => {
    try {
      dispatch(Actions.BrandAction.getBrandStoresSuccess(stores));
      const firstDataItem = stores?.[0];
      if (stores?.length === 1 && firstDataItem?._id) {
        updateTemporaryLocation();
        if (
          latlng?.lat &&
          latlng?.lng &&
          firstDataItem?.mappedAreaType === C.DISTANCE
        ) {
          const storeAreaName = await getAreaCityAndCountry(
            latlng.lat,
            latlng.lng,
            currentLang
          );
          const storearea = {
            ...firstDataItem,
            areaName: storeAreaName,
          };
          getStoreInfoWithMenu(firstDataItem?.storeId?._id, null, storearea);
        } else {
          const areaId = getAreaId(firstDataItem);
          getStoreInfoWithMenu(firstDataItem?.storeId?._id, areaId, null);
        }
        dispatch(Actions.NotPersistAction.setModalClose());
      } else if (stores?.length > 1) {
        dispatch(Actions.NotPersistAction.setModalOpen());
        handleEnd();
      }
    } catch (error) {
      handleEnd();
    }
  };

  function handleEnd() {
    dispatch(Actions.Loader.disableLoader());
    dispatch(Actions.Loader.setModalLoader(false));
  }
  return setstoremenu;
}

export default useSetStoreMenu;
