import React from "react";
import { useSelector } from "react-redux";
import C from "Constants";
import {
  StripeSaveCardComponent,
  CheckoutSaveCardComponent,
} from "./SaveCardComponent";

function SavedCardsList({ integrationsByName }) {
  const { savedPaymentCards, stripeSavedPaymentCards } = useSelector(
    (state) => state.PaymentCardsReducer
  );

  if (integrationsByName[C.STRIPE]) {
    if (stripeSavedPaymentCards?.length > 0) {
      return (
        <div className="mt-4">
          <StripeSaveCardComponent />
        </div>
      );
    } else return null;
  } else if (integrationsByName[C.CHECKOUT_DOT_COM]) {
    if (savedPaymentCards?.length > 0) {
      return (
        <div className="mt-4">
          <CheckoutSaveCardComponent />
        </div>
      );
    } else return null;
  }

  return null;
}

export default SavedCardsList;
