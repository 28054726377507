import { useMemo } from "react";
import { useSelector } from "react-redux";
import { keyBy } from "lodash";
import C from "Constants";
function useBrandLosgisticIntegrations() {
  const { activeIntegrations } = useSelector(
    (state) => state?.brandStorageReducer?.brandInfo
  );
  const integrationsByName = useMemo(
    () =>
      keyBy(
        activeIntegrations?.filter(
          (ai) => ai.category === C.PAYMENT_GATEWAY && ai.isActive
        ),
        "name"
      ),
    [activeIntegrations]
  );

  return {
    integrationsByName,
  };
}

export default useBrandLosgisticIntegrations;
