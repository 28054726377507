import React from "react";
import LoginHeader from "components/login/LoginHeader";
import LoginForm from "components/login/LoginForm";
import { useIsLoggedIn } from "hooks";
import { ErrorComponent } from "components";
import WithLoading from "components/Hoc/WithLoading";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCustomHistory } from "hooks";
import useQueryParam from "../useQueryParam";

const Login = () => {
  useIsLoggedIn();
  const params = useParams();
  const router = useCustomHistory();
  const queryParam = useQueryParam();
  const { registerError } = useSelector((state) => state.errorSlice);
  const { DomainType } = useSelector((state) => state.AppReducer);

  const gotoVerification = (state) => {
    router(
      `/dine-in/auth/verification`,
      `/${params.domainName}/dine-in/auth/verification`,
      DomainType,
      state,
      `?${queryParam}`
    );
  };

  const goBack = () => {
    router(
      `/dine-in/`,
      `/${params.domainName}/dine-in/`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };

  return (
    <WithLoading>
      <div className="max-w-400x register-page min-h-app-height my-0 mx-auto py-4 px-5 bg-white">
        <div style={{ color: "white", backgroundColor: "red" }}></div>
        <LoginHeader {...{ goBack }} />
        <LoginForm gotoVerification={gotoVerification} />
        {registerError && (
          <ErrorComponent error={registerError.message ?? registerError} />
        )}
      </div>
    </WithLoading>
  );
};
export default Login;
