import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import WithLoading from "components/Hoc/WithLoading";
import AddressErrorModalPopup from "components/Modals/AddressErrorModalPopup";
import AddDeliveryAddress from "components/checkout/AddDeliveryAddress";
import DeliveryStoreListModal from "components/Modals/DeliveryStoreList";

import { useCustomHistory, useLang } from "hooks";
import useCheckIFQueryParmsExist from "../../hooks/useCheckIFQueryParmsExist";
import useAddUserDeliveryAddress from "service-hooks/useAddUserDeliveryAddress";
import useOpenSavedAddressListModal from "service-hooks/useOpenSavedAddressListModal";

import { errorNotification, logErrorOnSentry } from "utils/Utils";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { DeliveryAddressAction } from "store/actions";
import { useMapContext } from "contexts/googleMap";
import C from "Constants";
import { getAreaCityAndCountry } from "utils/googleGeoCode";
import { StoreAction } from "store/actions";

function AddDeliveryManualaddress() {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { currentLang } = useLang();
  const [historyArea, setHistoryArea] = useState(null);
  const { DomainType, triggerStoreApiAfterAddressSaveOnAppStrt } = useSelector(
    (state) => state.AppReducer
  );
  const {
    temporarymapAddressHome,
    temporaryuserLocationHome,
    temporaryzoomHome,
  } = useSelector((state) => state.DeliveryAddressReducer);
  const addUserDeliveryAddress = useAddUserDeliveryAddress(history, true);
  const { fetchStoresBySelectedAddressLatLng } = useOpenSavedAddressListModal();
  const { reset, setUserLocation } = useMapContext();
  const { selectedStore } = useSelector((state) => state?.store);

  const redirectToNewMapAddressPage = () => {
    const NEW_MAP_ADD_URL = "/address/add-new-map-address";
    router(
      NEW_MAP_ADD_URL,
      `/${params.domainName}${NEW_MAP_ADD_URL}`,
      DomainType,
      { page: history?.location?.state?.page }
    );
  };
  const redirectToHomePage = () => {
    if (history?.location?.state?.page) {
      router(
        history?.location?.state?.page,
        `/${params.domainName}${history?.location?.state?.page}`,
        DomainType
      );
    } else {
      router("/", `/${params.domainName}/`, DomainType);
    }
  };

  const updateStoreAreaName = async (formdata) => {
    if (!selectedStore) return;
    if (historyArea?.length > 0) {
      if (
        historyArea[0]?.mappedAreaType === C.POLYGON &&
        historyArea[0]?.mappedAreaInfo?.mappedAreaId?._id
      ) {
        // update areaname
        const storeAreaName = historyArea[0];
        dispatch(StoreAction.setSelectedArea(storeAreaName));

        return historyArea[0]?.mappedAreaInfo?.mappedAreaId?._id;
      } else if (historyArea[0]?.mappedAreaType === C.DISTANCE) {
        const storeAreaName = await getAreaCityAndCountry(
          formdata?.coordinates?.lat,
          formdata?.coordinates?.lng,
          currentLang
        );
        const firstDataItem = historyArea[0];
        const storearea = {
          ...firstDataItem,
          areaName: storeAreaName,
        };
        dispatch(StoreAction.setSelectedArea(storearea));
        // update area name
        return null;
      } else return null;
    }
    return null;
  };

  const getAreaId = async () => {
    if (historyArea?.length > 0) {
      if (
        historyArea[0]?.mappedAreaType === C.POLYGON &&
        historyArea[0]?.mappedAreaInfo?.mappedAreaId?._id
      ) {
        return historyArea[0]?.mappedAreaInfo?.mappedAreaId?._id;
      } else if (historyArea[0]?.mappedAreaType === C.DISTANCE) {
        return null;
      } else return null;
    }
    return null;
  };

  const addnewaddress = async (formData) => {
    const area = await getAreaId(formData);
    try {
      const payload = {
        ...formData,
        ...(area
          ? {
              mappedAreaId: area,
            }
          : {
              area_type: "distance",
            }),
      };
      const response = await addUserDeliveryAddress(payload);
      if (response) {
        updateStoreAreaName(formData);
        reset();
        if (triggerStoreApiAfterAddressSaveOnAppStrt?.status) {
          const latlng = {
            lat: temporaryuserLocationHome.coordinates.lat,
            lng: temporaryuserLocationHome.coordinates.lng,
          };
          setUserLocation(latlng);
          await fetchStoresBySelectedAddressLatLng(response);
          if (triggerStoreApiAfterAddressSaveOnAppStrt?.page) {
            router("/", `/${params.domainName}/`, DomainType);
          } else {
            redirectToHomePage();
          }
        } else {
          redirectToHomePage();
        }
        dispatch(DeliveryAddressAction.resetTempDeliveryAddressHome());
        dispatch(DeliveryAddressAction.resetTempDeliveryAddress());
      }
    } catch (error) {
      errorNotification(errorHandler(error?.error));
      logErrorOnSentry(
        "addnewaddress on item page",
        "error",
        JSON.stringify(error)
      );
    }
  };

  useEffect(() => {
    if (history?.location?.state?.areas?.length > 0)
      setHistoryArea(history?.location?.state?.areas);
  }, [history?.location?.state?.areas]);
  useCheckIFQueryParmsExist();

  return (
    <div className="store-page-popup w-full 45">
      <WithLoading>
        <AddDeliveryAddress
          {...{
            temporarymapAddress: temporarymapAddressHome,
            temporaryuserLocation: temporaryuserLocationHome,
            temporaryzoom: temporaryzoomHome,
          }}
          goBacktomapscreen={redirectToNewMapAddressPage}
          isSaveAddress={false}
          gotoCheckoutPage={redirectToNewMapAddressPage}
          isEnabled={true}
          onSubmit={addnewaddress}
          handleRefineLocation={redirectToNewMapAddressPage}
        />

        <AddressErrorModalPopup />
        <DeliveryStoreListModal updateTemporaryLocation={() => {}} />
      </WithLoading>
    </div>
  );
}

export default AddDeliveryManualaddress;
