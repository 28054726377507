import C from "Constants";
import { logErrorOnSentry } from "utils/Utils";
export const isStoreIsOpen = (store, orderType) => {
  if (
    ((store?.isOpened ||
      (store?.isPreOrderEnabledForDelivery &&
        store?.availablePreOrderSlotsForDelivery?.length > 0)) &&
      orderType === C.SERVICE_TYPE.DELIVERY) ||
    ((store?.isOpened ||
      (store?.isPreOrderEnabledForPickup &&
        store?.availablePreOrderSlotsForPickup?.length > 0)) &&
      orderType === C.SERVICE_TYPE.PICKUP)
  ) {
    return true;
  }
  return false;
};

const getPreOrderSlot = (storeInfo, serviceType) => {
  const slot = {
    day: null,
    time: null,
    slotIndex: 0,
  };

  if (
    serviceType === C.SERVICE_TYPE.DELIVERY &&
    storeInfo?.availablePreOrderSlotsForDelivery?.length > 0 &&
    storeInfo?.availablePreOrderSlotsForDelivery[0]?.slots?.length > 0
  ) {
    slot.day = storeInfo?.availablePreOrderSlotsForDelivery[0]?.day;
    slot.time = storeInfo?.availablePreOrderSlotsForDelivery[0]?.slots[0];
    slot.slotIndex = 0;
  } else if (serviceType === C.SERVICE_TYPE.PICKUP) {
    if (
      storeInfo?.availablePreOrderSlotsForPickup?.length > 0 &&
      storeInfo?.availablePreOrderSlotsForPickup[0]?.slots?.length > 0
    ) {
      slot.day = storeInfo?.availablePreOrderSlotsForPickup[0]?.day;
      slot.time = storeInfo?.availablePreOrderSlotsForPickup[0]?.slots[0];
      slot.slotIndex = 0;
    }
  } else {
    logErrorOnSentry(
      "store is not open and pre order is not available",
      "error"
    );
    return {
      TimingType: null,
      isPreOrderAvailable: false,
      preOrderSlot: {
        day: null,
        time: null,
        slotIndex: null,
      },
    };
  }

  return slot;
};

export const getOrderTimingType = (storeInfo, serviceType) => {
  if (storeInfo?.isOpened) {
    return {
      TimingType: C.ORDER_TIMING_TYPE.ASAP,
      isPreOrderAvailable: false,
      preOrderSlot: {
        day: null,
        time: null,
        slotIndex: null,
      },
    };
  } else {
    if (serviceType === C.SERVICE_TYPE.DELIVERY) {
      if (
        storeInfo?.isPreOrderEnabledForDelivery &&
        storeInfo?.availablePreOrderSlotsForDelivery?.length > 0 &&
        storeInfo?.availablePreOrderSlotsForDelivery[0]?.slots?.length > 0
      ) {
        return {
          TimingType: C.ORDER_TIMING_TYPE.PREORDER,
          isPreOrderAvailable: true,
          preOrderSlot: getPreOrderSlot(storeInfo, serviceType),
        };
      } else return null;
    } else if (serviceType === C.SERVICE_TYPE.PICKUP) {
      if (
        storeInfo?.isPreOrderEnabledForPickup &&
        storeInfo?.availablePreOrderSlotsForPickup?.length > 0 &&
        storeInfo?.availablePreOrderSlotsForPickup[0]?.slots?.length > 0
      ) {
        return {
          TimingType: C.ORDER_TIMING_TYPE.PREORDER,
          isPreOrderAvailable: true,
          preOrderSlot: getPreOrderSlot(storeInfo, serviceType),
        };
      } else {
        return null;
      }
    } else {
      logErrorOnSentry(
        "store is not open and pre order is not available",
        "error"
      );
      return {
        TimingType: null,
        isPreOrderAvailable: false,
        preOrderSlot: {
          day: null,
          time: null,
          slotIndex: null,
        },
      };
    }
  }
};

export const getStoreAreaName = (
  currentLang,
  shippingMethod,
  outletBasedSelectedStoreArea
) => {
  if (outletBasedSelectedStoreArea?.mappedAreaType === C.DISTANCE) {
    return (
      outletBasedSelectedStoreArea?.areaName?.area ||
      outletBasedSelectedStoreArea?.areaName?.city ||
      outletBasedSelectedStoreArea?.areaName?.streetAddress
    );
  }
  const areaName =
    shippingMethod === C.SHIPPING_METHODS?.AREA_BASED
      ? outletBasedSelectedStoreArea?.areaID?.[`areaName${currentLang}`] || ""
      : outletBasedSelectedStoreArea?.mappedAreaInfo?.translations?.[
          currentLang?.toLowerCase()
        ]?.name || "";
  return areaName;
};
