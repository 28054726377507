import React from "react";
import OrderContainer from "components/order/OrdersContainer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as ROUTE_CONSTANTS from "routes/constants";
import { useCustomHistory } from "hooks";
import { useSelector } from "react-redux";
function Orders() {
  const params = useParams();
  const router = useCustomHistory();
  const { DomainType } = useSelector((state) => state.AppReducer);
  let url = ROUTE_CONSTANTS.ECOMMERCE_SETTINGS[1];
  const goBack = () => router("/", `/${params?.domainName}${url}`, DomainType);

  const getOrderDetail = (order) => {
    let url = ROUTE_CONSTANTS.OUTLET_ORDER_DETAIL[1];
    router(url, `/${params?.domainName}${url}`, DomainType, order);
  };

  return (
    <OrderContainer handleBack={goBack} handleOrderDetail={getOrderDetail} />
  );
}

export default Orders;
