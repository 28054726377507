import React, { useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "components/CustomModal";
import C from "Constants";
import { t } from "i18next";
import { UTILS } from "utils";
import Close from "icons/Close";
import { orderTimingAction } from "store/actions";
import { FormatPreOrderingTime } from "utils/OrderUtils";

const SlotList = ({ availableSlots }) => {
  const dispatch = useDispatch();
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);

  const changeSlotDay = (day, slotIndex) => {
    //if same day is selected then do nothing
    if (orderTiming?.preOrderSlot?.day === day) return;
    const slot = {
      day,
      time: availableSlots[slotIndex]?.slots[0],
      slotIndex,
    };

    const orderTimingObj = {
      TimingType: C.ORDER_TIMING_TYPE.PREORDER,
      isPreOrderAvailable: true,
      preOrderSlot: slot,
    };

    dispatch(orderTimingAction.setOrderTiming(orderTimingObj));
  };
  const changeSlotTiming = (time, day, slotIndex) => {
    if (orderTiming?.preOrderSlot?.time === time) return;
    const orderTimingObj = {
      TimingType: C.ORDER_TIMING_TYPE.PREORDER,
      isPreOrderAvailable: true,
      preOrderSlot: {
        day,
        time,
        slotIndex,
      },
    };

    dispatch(orderTimingAction.setOrderTiming(orderTimingObj));
  };

  return (
    <>
      <div className="flex ">
        {/* Left side - Day List */}
        <div
          style={{ maxHeight: "200px", overflowY: "auto" }}
          className="w-1/2 px-4 py-1"
        >
          <ul className="list-none">
            {availableSlots.map((daySlot, index) => (
              <li
                key={index}
                className={`cursor-pointer text-center font-normal mb-2 p-2 rounded-md transition-all  ${
                  orderTiming?.preOrderSlot?.slotIndex === index
                    ? "bg-brand-base-primary text-brand-text-primary"
                    : "bg-gray-100 text-gray-600"
                }`}
                onClick={() => changeSlotDay(daySlot.day, index)}
              >
                {daySlot.day === C.TODAY
                  ? t(daySlot.day)
                  : moment(daySlot.day).format("dddd, DD MMM YYYY")}
              </li>
            ))}
          </ul>
        </div>

        {/* Right side - Slots */}
        <div className="w-1/2 px-4 py-1 rounded-r-lg list-disc">
          {orderTiming?.TimingType !== null && (
            <ul className="list-disc">
              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                {availableSlots[
                  orderTiming?.preOrderSlot?.slotIndex || 0
                ]?.slots?.map((time, slotIndex) => (
                  <li
                    onClick={() =>
                      changeSlotTiming(
                        time,
                        availableSlots[orderTiming?.preOrderSlot?.slotIndex]
                          ?.day,
                        orderTiming?.preOrderSlot?.slotIndex
                      )
                    }
                    key={slotIndex}
                    className={`scroll-x font-normal cursor-pointer mb-2 p-2 text-center rounded-md transition-all  ${
                      orderTiming?.preOrderSlot?.time === time
                        ? "bg-brand-base-primary text-brand-text-primary"
                        : "bg-gray-100 text-gray-600"
                    }`}
                  >
                    {FormatPreOrderingTime(time)}
                  </li>
                ))}
              </div>
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

function PreOrderingSlotWidget({
  isPreOrderingSlotWidget,
  setPreOrderingSlotWidget,
  selectedStore,
}) {
  const elementRef = useRef(null);
  const dispatch = useDispatch();
  const { orderType } = useSelector((state) => state.AppReducer);
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);

  const validatePreOrdering = () => {
    if (
      orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER &&
      orderTiming?.preOrderSlot?.day &&
      orderTiming?.preOrderSlot?.time &&
      orderTiming?.preOrderSlot?.slotIndex !== null
    ) {
      setPreOrderingSlotWidget(false);
      return true;
    } else if (
      orderTiming?.TimingType === C.ORDER_TIMING_TYPE.ASAP &&
      selectedStore?.isOpened
    ) {
      setPreOrderingSlotWidget(false);
      return true;
    } else {
      UTILS.errorNotification("Please select a slot", "top-center");
      return false;
    }
  };

  const handleAsapOrders = (e) => {
    if (!e.target.checked) return;
    const obj = {
      TimingType: e.target.checked ? C.ORDER_TIMING_TYPE.ASAP : null,
      isPreOrderAvailable: false,
      preOrderSlot: {
        day: null,
        time: null,
        slotIndex: null,
      },
    };
    dispatch(orderTimingAction.setOrderTiming(obj));
  };
  const handlePreOrders = (e) => {
    if (!e.target.checked) return;
    if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
      const obj = {
        TimingType: e.target.checked ? C.ORDER_TIMING_TYPE.PREORDER : null,
        isPreOrderAvailable: true,
        preOrderSlot: {
          day: e.target.checked
            ? selectedStore?.availablePreOrderSlotsForDelivery[0]?.day
            : null,
          time: e.target.checked
            ? selectedStore?.availablePreOrderSlotsForDelivery[0]?.slots[0]
            : null,
          slotIndex: e.target.checked ? 0 : null,
        },
      };
      dispatch(orderTimingAction.setOrderTiming(obj));
    }
    if (orderType?.serviceType === C.SERVICE_TYPE.PICKUP) {
      const obj = {
        TimingType: e.target.checked ? C.ORDER_TIMING_TYPE.PREORDER : null,
        isPreOrderAvailable: true,
        preOrderSlot: {
          day: e.target.checked
            ? selectedStore?.availablePreOrderSlotsForPickup[0]?.day
            : null,
          time: e.target.checked
            ? selectedStore?.availablePreOrderSlotsForPickup[0]?.slots[0]
            : null,
          slotIndex: e.target.checked ? 0 : null,
        },
      };
      dispatch(orderTimingAction.setOrderTiming(obj));
    }
  };
  return (
    <>
      <CustomModal
        open={isPreOrderingSlotWidget}
        setOpen={setPreOrderingSlotWidget}
        bodyclasses="flex"
        overlayClasses=" fixed h-screen overflow-y-auto w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40 border-sc"
      >
        <div
          ref={elementRef}
          id="discount-info-popup"
          className=" fixed inset-x-0 bottom-0 flex justify-center"
        >
          <div className="bg-white relative w-full max-460  shadow-md ">
            <h2 className="w-fudll absolute max-460 -top-9 flex justify-end px-1">
              <Close
                onClick={() => {
                  setPreOrderingSlotWidget(false);
                }}
              />
            </h2>
            <div className="px-4 pt-3">
              <h2 className="text-21x font-bold">
                {" "}
                {orderType?.serviceType === C.SERVICE_TYPE.DELIVERY
                  ? t("Delivery Timing")
                  : t("Pickup Timing")}{" "}
              </h2>
              {orderType?.serviceType === C.SERVICE_TYPE.DELIVERY ? (
                <p>{t("Schedule when you want to receive the order")}</p>
              ) : (
                <p>{t("Schedule when you want to pickup the order")}</p>
              )}
            </div>
            {selectedStore?.isOpened && (
              <div className="mx-4">
                <>
                  <div className="mt-3 py-1 flex items-center w-full  text-start  transition-all duration-300 ease-in-out">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="checkbox-brand  mx-2"
                        value={
                          orderTiming?.TimingType === C.ORDER_TIMING_TYPE.ASAP
                        }
                        onChange={handleAsapOrders}
                        checked={
                          orderTiming?.TimingType === C.ORDER_TIMING_TYPE.ASAP
                        }
                      />
                      {t("ASAP (Now)")}
                    </label>
                  </div>
                  {((orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
                    selectedStore?.availablePreOrderSlotsForDelivery?.length >
                      0) ||
                    (orderType?.serviceType === C.SERVICE_TYPE.PICKUP &&
                      selectedStore?.availablePreOrderSlotsForPickup?.length >
                        0)) && (
                    <div className="py-1 w-full   flex items-center text-start transition-all duration-300 ease-in-out">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="checkbox-brand  mx-2"
                          value={
                            orderTiming?.TimingType ===
                            C.ORDER_TIMING_TYPE.PREORDER
                          }
                          onChange={handlePreOrders}
                          checked={
                            orderTiming?.TimingType ===
                            C.ORDER_TIMING_TYPE.PREORDER
                          }
                        />
                        {t("Later (Future orders)")}
                      </label>
                    </div>
                  )}
                </>
              </div>
            )}

            {orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER && (
              <>
                <div>
                  {orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
                    selectedStore?.isPreOrderEnabledForDelivery && (
                      <div className="mb-4r text-2xl font-bold p-2">
                        {selectedStore?.availablePreOrderSlotsForDelivery
                          ?.length > 0 ? (
                          <SlotList
                            setPreOrderingSlotWidget={setPreOrderingSlotWidget}
                            selectedStore={selectedStore}
                            orderTiming={orderTiming}
                            availableSlots={
                              selectedStore.availablePreOrderSlotsForDelivery
                            }
                          />
                        ) : null}
                      </div>
                    )}
                </div>
                <div>
                  {orderType?.serviceType === C.SERVICE_TYPE.PICKUP &&
                    selectedStore?.isPreOrderEnabledForPickup && (
                      <div className="mb-6 text-2xl font-bold p-2">
                        {selectedStore?.availablePreOrderSlotsForPickup
                          ?.length > 0 ? (
                          <SlotList
                            setPreOrderingSlotWidget={setPreOrderingSlotWidget}
                            selectedStore={selectedStore}
                            orderTiming={orderTiming}
                            availableSlots={
                              selectedStore.availablePreOrderSlotsForPickup
                            }
                          />
                        ) : null}
                      </div>
                    )}
                </div>
              </>
            )}

            <div className="mx-4 ">
              <button
                onClick={validatePreOrdering}
                className=" w-full px-1 py-3 font-semibold text-center  bg-brand-base-primary rounded-md p-2 text-brand-text-primary transition-all duration-300 ease-in-out mb-2"
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default PreOrderingSlotWidget;
