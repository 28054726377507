import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoyaltyAction } from "store/actions";
import { NotPersistAction } from "store/actions";
import { DiscountAction } from "store/actions";
import { t } from "i18next";
import C from "Constants";

function useToggleLoyalty({ updateCart, discountInfo }) {
  const { isDiscountApplied } = useSelector((state) => state?.Discounts);
  const { loyaltyApplied } = useSelector((state) => state?.Loyalty);
  const dispatch = useDispatch();
  const [confirmationCallback, setConfirmationCallback] = useState(
    () => () => {}
  );
  const [confirmModalTitle, setConfirmModalTitle] = useState({
    title: "",
    message: "",
  });
  const [confirmModal, setConfirmModal] = useState(false);

  const openModal = () => dispatch(NotPersistAction.toggleDiscountModal(true));

  const switchLoyaltyStatus = (redeemAmount) => {
    if (loyaltyApplied) {
      dispatch(LoyaltyAction.removeLoyalty());
      updateCart();
    } else {
      dispatch(LoyaltyAction.applyLoyalty(Number(redeemAmount)));
      updateCart();
    }
  };
  const handleConfirmDiscountLoyalty = (wantsToApply) => {
    if (loyaltyApplied && wantsToApply === C.DISCOUNT) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use discount code"),
        message: t(
          "To use discount code, the loyalty points will be removed. Do you want to continue"
        ),
      });

      setConfirmationCallback(() => () => {
        dispatch(LoyaltyAction.removeLoyalty());
        openModal();
        updateCart();
      });
    } else if (
      (isDiscountApplied || discountInfo) &&
      wantsToApply === C.LOYALTY
    ) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use loyalty credits"),
        message: t(
          "To use loyalty points, the discount code will be removed. Do you want to continue"
        ),
      });
      setConfirmationCallback(() => () => {
        dispatch(DiscountAction.setCoponValidation(false));
        dispatch(DiscountAction.removeDiscountCode());
        switchLoyaltyStatus();
        updateCart();
      });
    } else if (wantsToApply === C.LOYALTY) {
      switchLoyaltyStatus();
    } else if (wantsToApply === C.DISCOUNT) {
      openModal();
    }
  };
  const handleConfirmModal = () => {
    setConfirmModal(false);
    confirmationCallback();
  };

  const handleCancelModal = () => setConfirmModal(false);

  return {
    handleConfirmDiscountLoyalty,
    confirmModal,
    confirmModalTitle,
    handleConfirmModal,
    setConfirmModal,
    handleCancelModal,
  };
}

export default useToggleLoyalty;
