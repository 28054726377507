import moment from "moment";
import React from "react";
import ShowDeliveryTime from "components/ShowDeliveryTime";
const RenderTime = ({ deliveryTime, orderPlacedtime }) => {
  const nowCopy = moment(moment().valueOf());
  const ordertime = moment.unix(orderPlacedtime);
  var minutesDiff = nowCopy.diff(ordertime, "minutes");
  const diff = parseInt(deliveryTime) - minutesDiff;

  return <ShowDeliveryTime val={diff} />;
};

export default RenderTime;
