import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

export function ScrollToTop() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    try {
      const isRedirectingToHome =
        location?.state && location?.state?.fromItemScreen;
      const p = sessionStorage.getItem("scroll-positon");
      if (history.action === "PUSH" && isRedirectingToHome && p >= 1) {
        window.scrollTo(0, p);
      } else {
        window.scrollTo(0, 0);
      }
    } catch (error) {}
  }, [location, history]);

  return null;
}
