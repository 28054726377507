import React from "react";
import { t } from "i18next";
import { useLang } from "hooks";
import { useSelector } from "react-redux";
import useEventTracker from "hooks/useEventTracker";

function SelectDeliveryAddress({ children, handleAddressClick }) {
  const { currentLang } = useLang();
  const { orderType } = useSelector((state) => state?.AppReducer);

  useEventTracker({ name: "CHANGE_ADDRESS", shouldLog: true, orderType });
  return (
    <>
      <div
        className={`fixed bottom-0 max-460 rounded-t-lg bg-white   ${
          currentLang === "AR" ? "border-sc-l" : "border-sc"
        } `}
      >
        <div className="address-book relative">
          <ul className="overflow-y-auto rounded-t-lg save-address-listing">
            {children && children}
          </ul>
          <span onClick={handleAddressClick} className="flex py-6  px-4">
            <div className="w-full text-15x text-black leading-normal cursor-pointer uppercase font-bold px-2">
              {t("Add new address")}
            </div>
            <div className="">
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                className={`h-5 w-5 transform  text-brand-base-primary ${
                  currentLang === "AR" ? "rotate-90" : "-rotate-90"
                }`}
              >
                <path
                  fill="currentColor"
                  d="M4.88398 7.11612L3.11621 8.88389L12.0001 17.7678L20.884 8.88389L19.1162 7.11612L12.0001 14.2322L4.88398 7.11612Z"
                ></path>
              </svg>
            </div>
          </span>
        </div>
      </div>
    </>
  );
}

export default SelectDeliveryAddress;
