import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "./Spinner";
function ImageHeader(props) {
  const page = props.page ? true : false;
  const [storeInfo, setStoreInfo] = useState(null);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { isLoading } = useSelector((state) => state?.loader);
  useEffect(() => {
    setStoreInfo(brandInfo);
  }, []);

  if (isLoading) return <Spinner />;
  return (
    <div
      className={`bg-cover w-full bg-center ${page === true ? "pb-56p" : "pb-56p"
        }`}
      style={{
        backgroundImage: `url(${brandInfo?.setting?.brandImageHomeBG ||
          storeInfo?.setting?.brandImageHomeBG
          })`,
      }}
    >
      {props?.children ? props?.children : null}
    </div>
  );
}

export default ImageHeader;
