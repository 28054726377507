import React, { useEffect } from "react";
import { t } from "i18next";
import { useLang } from "hooks";
import { Close, Tick } from "config/Svgs";
import { useUserWallet } from "hooks";
import CustomModal from "components/CustomModal";
import useCurrentUser from "hooks/useCurrentUser";
const LoyaltyPolicyPopup = React.memo(
  ({ open, setOpen, loyaltyPolicy, goToLogin }) => {
    const language = useLang();
    const userWallet = useUserWallet();
    const { currentUser } = useCurrentUser();

    useEffect(() => {
      let root = document.getElementById("root");
      if (open) {
        root.style.overflow = "hidden";
        root.style.position = "fixed";
      } else {
        root.removeAttribute("style");
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
      <CustomModal
        isScrollable={true}
        open={open}
        setOpen={setOpen}
        bodyclasses="flex"
        overlayClasses={` fixed h-app-height w-screen top-0 bg-white overflow-y-auto  transition ease-linear duration-700 z-50 ${
          language?.currentLang === "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <div className="absolute z-10">
          <div className="p-4">
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="cursor-pointer"
            >
              <span className="rounded-full flex items-center  justify-center bg-circle p-2 border border-white hover:border hover:border-brand-base-primary cursor-pointer">
                <Close />
              </span>
            </button>
          </div>
        </div>
        <div className="mt-10">
          <div className="p-5">
            <div className="text-center text-48x leading-normal pb-5">🎉</div>
            <h2 className="font-bold leading-normal my-5 text-22x">
              {t("Loyalty program")}
            </h2>
            <p className="text-black-500 text-16x">
              {t(
                "Collect points on every order you complete. Use them to get discounts on your next orders."
              )}
            </p>
            {currentUser && Number(userWallet) > 0 && (
              <div className="px-4 py-2 border  inline-block mt-8 border-brand-base-primary text-brand-base-primary rounded-full font-semibold text-15x leading-normal">
                {t("You have in your wallet", {
                  balance: Number(userWallet).toFixed(2),
                })}
              </div>
            )}
            <h5 className="mt-8 mb-5 font-bold text-16x leading-normal">
              {t("How it works")}
            </h5>

            <ul>
              <li className="flex mb-4">
                <Tick />
                <div className="mx-2  text-15x">
                  {t("Get back as loyalty credits.", {
                    balance:
                      loyaltyPolicy?.specificToDelivery?.cashbackPercentage,
                  })}
                </div>
              </li>
              <li className="flex mb-4">
                <Tick />
                <div className="mx-2 text-15x">
                  {t("Redeem your credits get up to off on your next order.", {
                    percentage: loyaltyPolicy?.redeemablePercentagePerOrder,
                  })}
                </div>
              </li>
              <li className="flex mb-4">
                <Tick />
                <div className="mx-2  text-15x">
                  <span>
                    {t("Limited to maximum per order.", {
                      amount: loyaltyPolicy?.maxRedeemableValuePerOrder,
                    })}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          {!currentUser && (
            <div className="flex items-center justify-center mb-8">
              <button
                className="rounded mx-4 w-full  text-brand-text-primary bg-brand-base-primary  px-10 py-2 h-12 text-16x text-bold"
                onClick={() => goToLogin()}
              >
                {t("Login")}
              </button>
            </div>
          )}
        </div>
      </CustomModal>
    );
  }
);

export default LoyaltyPolicyPopup;
