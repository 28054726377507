import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SelectDeliveryArea from "components/outlet-based-areas/SelectDeliveryArea";
import CustomModal from "components/CustomModal";
import { BrandAction } from "store/actions";
import ChangeDeliveringToModalContainer from "./ChangeDeliveringToModalContainer";
import PreOrderingSlotWidget from "components/Pre-ordering/PreOrderingSlotWidget";

import C from "Constants";
import moment from "moment";
import { FormatPreOrderingTime } from "utils/OrderUtils";
import { useCustomHistory } from "hooks";
import { getStoreAreaName } from "utils/storeUtils";
import useGetSavedAddressList from "service-hooks/useGetSavedAddressList";

function DeliveringTo({ selectedArea, lang }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const router = useCustomHistory();

  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const [isChngeAreaModalopen, setChngeAreaModalopen] = useState(false);
  const [isContainerModalopen, setContainerModalopen] = useState(false);
  const { shippingMethod, DomainType } = useSelector(
    (state) => state?.AppReducer
  );
  const { selectedStore } = useSelector((state) => state?.store);
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);

  const [isPreOrderingSlotWidget, setPreOrderingSlotWidget] = useState(false);

  const handleChangeOrderStore = () => {
    if (shippingMethod === C.SHIPPING_METHODS.MAPPED_AREA_BASED) {
      router(
        "/address/select-delivery-area",
        `/${params.domainName}/address/select-delivery-area`,
        DomainType
      );
      return;
    }
    dispatch(
      BrandAction.getBrandDeliveryAreas({
        brandId: brandInfo?.brand?._id,
        updateMethod: true,
      })
    );
    setChngeAreaModalopen(true);
  };

  const { getAddresses } = useGetSavedAddressList({
    fetch: false,
    type: "filtered",
  });
  const fetchUserAddresses = () => {
    getAddresses(null, "all");
    setContainerModalopen(true);
  };

  return (
    <>
      <div className="bg-white px-4 pb-2 border-t border-gray-200 mt-4">
        <div className="flex items-center pt-2">
          <span className=" px-2 content-area cursor-pointer w-full grow">
            <p className="font-semibold mb-2">{t("Delivering to")}</p>
            <div
              className="flex w-fit items-center rounded bg-brand-base-primary p-1 py-2"
              onClick={fetchUserAddresses}
            >
              <span className="inline-block">
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  fill="currentColor"
                  className="w-3 h-3 text-brand-text-primary"
                >
                  <path d="M49.6 95c-3.3 0-5.4-2.4-6.5-3.6l-.3-.4c-.3-.3-6.9-7.4-13.3-17.2C21 60.9 16.6 49.5 16.6 39.9c0-19.1 14.5-34 33-34s33 14.9 33 34c0 9.6-4.3 21-12.9 33.9-6.4 9.8-13 16.9-13.3 17.2l-.3.3C55 92.6 52.9 95 49.6 95zm0-79.1c-12.9 0-23 10.5-23 24 0 7.5 3.9 17.3 11.2 28.4 5 7.6 10.2 13.5 11.8 15.3 1.6-1.8 6.7-7.6 11.8-15.3 7.3-11.1 11.2-20.9 11.2-28.4 0-13.5-10.1-24-23-24z"></path>
                  <ellipse cx="49.6" cy="38.4" rx="8.5" ry="8.6"></ellipse>
                </svg>
              </span>
              <div className="inline-block px-2 text-brand-text-primary text-xs">
                {getStoreAreaName(lang, shippingMethod, selectedArea)} (
                {orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER &&
                orderTiming?.preOrderSlot?.day &&
                orderTiming?.preOrderSlot?.time ? (
                  <span className=" text-brand-text-primary text-xs">
                    {orderTiming?.preOrderSlot?.day === C.TODAY
                      ? t(orderTiming?.preOrderSlot?.day)
                      : moment(orderTiming?.preOrderSlot?.day).format(
                          "dddd, DD MMM"
                        )}
                    {", "}
                    {FormatPreOrderingTime(orderTiming?.preOrderSlot?.time)}
                  </span>
                ) : (
                  <span className=" text-brand-text-primary text-xs">
                    {t("ASAP")}
                  </span>
                )}
                )
              </div>
            </div>
          </span>
        </div>
      </div>
      <CustomModal
        open={isChngeAreaModalopen}
        setOpen={setChngeAreaModalopen}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-20"
      >
        <SelectDeliveryArea
          header={false}
          headerLabel=""
          styleClasses="fixed bottom-0 h-5 bg-white min-h-70p max-460  rounded-t-lg min-h-60p  border-sc"
        />
      </CustomModal>

      <ChangeDeliveringToModalContainer
        isContainerModalopen={isContainerModalopen}
        setContainerModalopen={setContainerModalopen}
        handleChangeOrderStore={handleChangeOrderStore}
        setPreOrderingSlotWidget={setPreOrderingSlotWidget}
        preORderSlots={selectedStore?.availablePreOrderSlotsForDelivery}
        isPreORderEnabled={selectedStore?.isPreOrderEnabledForDelivery}
      />

      <PreOrderingSlotWidget
        selectedStore={selectedStore}
        isPreOrderingSlotWidget={isPreOrderingSlotWidget}
        setPreOrderingSlotWidget={setPreOrderingSlotWidget}
      />
    </>
  );
}

export default DeliveringTo;
