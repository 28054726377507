import React, { Fragment, useState } from "react";
import { t } from "i18next";
import { useHistory } from "react-router-dom";

import WithMap from "components/Hoc/WithMap";
import PageHeader from "components/PageHeader";
import TextInput from "components/TextInput";
import AddressHome from "icons/AddressHome";
import AddressOffice from "icons/AddressOffice";
import FixedFooter from "components/FixedFooter";

import useFormAddDeliveryAddress from "./useFormAddDeliveryAddress";
import CustomModal from "components/CustomModal";
import ChangeActionPopup from "../Modals/ChangeActionPopup";

function AddDeliveryAddress(props) {
  const {
    errors,
    children,
    showAreaPoup,
    onSubmit,
    isEnabled,
    gotoCheckoutPage,
    handleRefineLocation,
    goBacktomapscreen,
    isSaveAddress,
    temporarymapAddress,
    temporaryuserLocation,
    temporaryzoom,
  } = props;
  const [mapref, setMapRef] = useState(null);
  const history = useHistory();

  const { formState, handleFormChange, handleFormSubmit } =
    useFormAddDeliveryAddress({
      gotoCheckoutPage,
      onSubmit,
    });

  const addnewaddress = (e) => {
    const geoLocationObj = {
      temporarymapAddress,
      temporaryuserLocation,
      temporaryzoom,
    };
    handleFormSubmit(e, geoLocationObj);
  };

  const [isOpenChangeStreetAddressModal, setIsOpenChangeStreetAddressModal] =
    useState(false);

  const AddressTypeObj = [
    {
      value: "Home",
      label: t("Home"),
      icon: <AddressHome />,
    },
    {
      value: "Office",
      label: t("Office"),
      icon: <AddressOffice />,
    },
    {
      value: "Other",
      label: t("Other"),
      icon: <></>,
    },
  ];

  return (
    <div className="create-address fixced  bg-white">
      <PageHeader
        pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full relative"
        title={t("Create address")}
        url={true}
        handleClick={() => {
          goBacktomapscreen(true);
        }}
      />
      <div className="w-full bg-gray-100 h-56">
        <WithMap
          class="map-height"
          width="100%"
          showInput={false}
          mapref={mapref}
          setMapRef={setMapRef}
          zoomChanging={false}
          height="224px"
          googlemapState={{
            userLocation: {
              lat: temporaryuserLocation?.coordinates?.lat,
              lng: temporaryuserLocation?.coordinates?.lng,
            },
            zoom: temporaryzoom,
            mapAddress: temporarymapAddress,
          }}
          zoomerror={null}
          isZoomModalOpen={false}
          setZoomErrorModal={() => {}}
        />
      </div>
      <div
        className="flex w-full justify-center   py-2 text-15x"
        onClick={handleRefineLocation}
      >
        <span className="cursor-pointer text-brand-base-primary rounded border-brand-base-primary border px-2">
          {t("Change Location")}
        </span>
      </div>

      <div className="felx jsutify-center mx-4 pt-7">
        <TextInput
          label={t("Full Address")}
          value={formState.values?.address}
          onChange={(e) => handleFormChange(e)}
          name="address"
          mandatory={true}
          labelClasses="text-14x text-black"
          inputClasses="focus:outline-none mb-4 flex-1 text-16x block w-full rounded border shadow-input px-2 py-3 border-gray-200"
          placeHolder={t("Enter your complete address")}
          error={formState.errors?.address}
        />
        {/* <div onClick={() => setIsOpenChangeStreetAddressModal(true)}>
          <TextInput
            label={t("Map Address")}
            value={temporarymapAddress?.streetAddress}
            onChange={() => {}}
            name="mapAddress"
            mandatory={true}
            labelClasses="text-14x text-black"
            inputClasses="focus:outline-none mb-4 flex-1 text-16x block w-full rounded border shadow-input px-2 py-3 border-gray-200 cursor-pointer"
            placeHolder={t("Google Map Address")}
            readOnly={true}
            error={errors?.streetAddress}
          />
        </div> */}

        {children &&
          children?.map((child, index) => {
            return <div key={index}>{child}</div>;
          })}
        <TextInput
          label={t("Apartment No.")}
          value={formState.values?.appartmentNo}
          onChange={(e) => handleFormChange(e)}
          name="appartmentNo"
          mandatory={false}
          labelClasses="text-14x text-black"
          inputClasses="focus:outline-none mb-4 flex-1 text-16x block w-full rounded border shadow-input px-2 py-3 border-gray-200"
          placeHolder={t("Enter your apartment no.")}
          error={formState.errors?.appartmentNo}
        />
        <TextInput
          label={t("Building Name")}
          value={formState.values?.building}
          onChange={(e) => handleFormChange(e)}
          name="building"
          mandatory={false}
          labelClasses="text-14x text-black"
          inputClasses="focus:outline-none mb-4 flex-1 text-16x block w-full rounded border shadow-input px-2 py-3 border-gray-200"
          placeHolder={t("Enter your building name")}
          error={formState.errors?.building}
        />
        <TextInput
          label={t("Floor number")}
          value={formState.values?.floor}
          onChange={(e) => handleFormChange(e)}
          name="floor"
          mandatory={false}
          labelClasses="text-14x text-black"
          inputClasses="focus:outline-none mb-4 flex-1 text-16x block w-full rounded border shadow-input px-2 py-3 border-gray-200"
          placeHolder={t("Enter your floor number")}
          error={formState.errors?.floor}
        />
        <CustomModal
          open={isOpenChangeStreetAddressModal}
          setOpen={setIsOpenChangeStreetAddressModal}
          bodyclasses="flex"
          overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
        >
          <ChangeActionPopup
            title={t(
              "Your selected delivery location is x do you wish to change it",
              {
                streetAddress: temporarymapAddress?.streetAddress,
              }
            )}
            handleClickYes={handleRefineLocation}
            handleClickNo={() => {
              setIsOpenChangeStreetAddressModal(false);
            }}
          />
        </CustomModal>
        <CustomModal
          open={isSaveAddress}
          setOpen={goBacktomapscreen}
          bodyclasses="flex"
          overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
        >
          <ChangeActionPopup
            title={t("Would you like to save the address")}
            handleClickYes={(e) => {
              goBacktomapscreen(false);
              addnewaddress(e);
            }}
            handleClickNo={() => {
              goBacktomapscreen(false);
              history.goBack();
            }}
          />
        </CustomModal>
        <div className="py-2 cursor-pointer">
          <label className="text-14x text-black">
            {t("Address label")} <span className="text-red-500">*</span>
          </label>
          <div className="text-red-500">{formState.errors?.addressType}</div>

          <div className="flex  switch-field mt-50">
            {AddressTypeObj.map((type, index) => {
              const isSelected = formState?.values?.addressType === type.value;
              return (
                <Fragment key={index}>
                  <input
                    onChange={handleFormChange}
                    type="radio"
                    name="addressType"
                    id={`radio-${index}`}
                    value={type.value}
                    className="me-2"
                    style={{ width: "1px", height: "1px" }}
                  />
                  <label
                    htmlFor={`radio-${index}`}
                    className={`flex items-center justify-center border ${
                      isSelected
                        ? "border-brand-base-primary"
                        : "border-gray-200"
                    } flex items-center py-3 px-3 350:px-5 380:px-6 border me-2 cursor-pointer`}
                  >
                    {type.icon}
                    {t(type.label)}
                  </label>
                </Fragment>
              );
            })}
          </div>
        </div>
        <div id="addressLabel"></div>
      </div>
      {!showAreaPoup && (
        <FixedFooter page={false} from="add address">
          <button
            type="submit"
            onClick={addnewaddress}
            disabled={!isEnabled}
            className=" cursor-pointer block text-center py-4 rounded text-brand-text-primary bg-brand-base-primary  text-16x w-full"
          >
            <span className="flex items-center justify-center text-center">
              <div className="flex justify-center relative w-full items-center pointer">
                <span className="font-bold text-center">
                  {t("Save Address")}
                </span>
              </div>
            </span>
          </button>
        </FixedFooter>
      )}
    </div>
  );
}

export default AddDeliveryAddress;
