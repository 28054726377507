import { apiSlice, postConfig, getConfig } from "../../apiHelpers/ApiSlice";
import * as URL from "../../apiHelpers/api/ApiUrls";
import { ErrorAction, Loader } from "../actions";
import { errorHandler } from "apiHelpers/api/errorHandler";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["partialdeliveryAddress"],
});

const storeApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    updateUserDeliveryAddress: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.UPDATE_USER_DELIVERY_ADDRESS,
        ...postConfig.query(body),
      }),
      invalidatesTags: ["partialdeliveryAddress", "updateAddress"],
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(
          ErrorAction.setError({
            error: null,
            modal: false,
          })
        );
        dispatch(Loader.togggleCheckoutLoader(true));

        try {
          const { data } = await queryFulfilled;
          if (data) {
          } else {
            dispatch(
              ErrorAction.setError({ error: errorHandler(data), modal: false })
            );
          }
        } catch (err) {
          dispatch(
            ErrorAction.setError({ error: errorHandler(err), modal: false })
          );
        }
        dispatch(Loader.togggleCheckoutLoader(false));
      },
    }),
    getUserDeliveryAddress: builder.query({
      ...getConfig,
      query: (body) => ({
        url: URL.GET_USER_DELIVERY_ADDRESS,
        ...getConfig.query(body),
      }),
      providesTags: ["partialdeliveryAddress"],
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.togggleCheckoutLoader(true));

        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data?.fetchedUser) {
            if (data?.data?.fetchedUser?.userAddress) {
            }
          } else {
            dispatch(
              ErrorAction.setError({ error: errorHandler(data), modal: false })
            );
          }
        } catch (err) {
          dispatch(
            ErrorAction.setError({ error: errorHandler(err), modal: false })
          );
        }

        dispatch(Loader.togggleCheckoutLoader(false));
      },
    }),
    getUserAddressList: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_USER_ADDRESSES,
        ...postConfig.query(body),
      }),
      providesTags: ["partialdeliveryAddress", "userAddressList"],
    }),
    addNewUserAddress: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.ADD_USER_ADDRESS,
        ...postConfig.query(body),
      }),
      providesTags: ["partialdeliveryAddress"],
    }),
    deleteAddress: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.DELETE_USER_ADDRESS,
        ...postConfig.query(body),
      }),
      providesTags: ["partialdeliveryAddress", "updateAddress"],
    }),
    getDeliveryFeeOfSingleStore: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_SINGLE_STORE_DELIVERY_AREA,
        ...postConfig.query(body),
      }),
      invalidatesTags: ["partialdeliveryAddress"],
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
          } else {
            dispatch(
              ErrorAction.setError({ error: errorHandler(data), modal: true })
            );
          }
        } catch (err) {
          dispatch(
            ErrorAction.setError({
              error: errorHandler(err?.error?.data),
              modal: true,
            })
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});

export default storeApi;
export const {
  useGetUserDeliveryAddressQuery,
  useUpdateUserDeliveryAddressMutation,
  useGetUserAddressListMutation,
  useAddNewUserAddressMutation,
  useDeleteAddressMutation,
  useGetDeliveryFeeOfSingleStoreMutation,
} = storeApi;
