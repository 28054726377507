export const isOpenedAnyStore = (data) => {
  if (data?.length >= 1) {
    return data?.some((store) => {
      return (
        store?.storeId?.isOpened || store?.storeId?.isPreOrderEnabledForDelivery
      );
    });
  }
  return false; // Return false if data is empty or undefined
};
export const checkIsStoreAvailable = (store) => {
  if (store?.storeId?.isOpened || store?.storeId?.isPreOrderEnabledForDelivery)
    return true;
  return false;
};
