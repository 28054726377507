import { UTILS } from "utils";
import { logError } from "utils/Utils";

import reduxstore from "store/store";
import { t } from "i18next";
import C from "Constants";
import { apiSlice } from "apiHelpers/ApiSlice";
const getHostedPage = apiSlice.endpoints.getHostedPage;

export const process_telr = async (
  totalOrderPrice,
  params,
  selectedStore,
  DomainType,
  redirectUrl
) => {
  const hostedObj = {
    domainName: params.domainName,
    amountPaid: totalOrderPrice,
    storeId: selectedStore._id,
    DomainType,
    successRedirectUrl: redirectUrl.success,
    failureRedirectUrl: redirectUrl.failure,
  };
  const paymentObj = UTILS.getHostedEcommerceObj(hostedObj);
  if (!paymentObj?.isValid) {
    logError(t(C.DEFAULT_ERROR_MSG), true, "error");
    return;
  }
  const { data } = await reduxstore.dispatch(
    getHostedPage.initiate(paymentObj.value)
  );
  if (data?.success === 0) {
    return;
  }
};
