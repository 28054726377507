import { useEffect } from "react";
import { ifNullOrUndefined } from "utils/Utils";
import { useHistory } from "react-router-dom";

function useBrand(data, domainName) {
  const history = useHistory();
  useEffect(() => {
    try {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      if (ifNullOrUndefined(data) && domainName) {
        history.push(`/${domainName}`);
      } else if (ifNullOrUndefined(data)) {
        history.goBack();
      }
    } catch (error) {}
  }, [data, domainName]);
}

export default useBrand;
