import React, { createContext, useContext, useReducer } from "react";

// Create a context
const UserAddressContext = createContext();

// Define action types

// Reducer function
const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_ADDRESSES":
      return {
        ...state,
        userAddresses: action.payload,
      };
    case "SET_STATE":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
};

// Create a provider component
export const UserAddressContextProvider = ({ children }) => {
  const [userAddressState, dispatch] = useReducer(appReducer, {
    userAddresses: [],
    savedAddressListModal: false,
    mapAddressModal: false,
    manualAddressModal: false,
    latLongNotFallInCurrentStore: false,
    zoomErrorModal: {
      open: false,
      message: "",
    },
  });

  const setAddresses = (val) => {
    dispatch({
      type: "SET_ADDRESSES",
      payload: val,
    });
  };
  const setAddressState = (key, value) => {
    dispatch({
      type: "SET_STATE",
      payload: { key, value },
    });
  };

  const reset = () => {
    dispatch({
      type: "RESET",
    });
  };

  return (
    <UserAddressContext.Provider
      value={{
        userAddressState,
        setAddresses,
        reset,
        setAddressState,
      }}
    >
      {children}
    </UserAddressContext.Provider>
  );
};

// Create a custom hook for using the context
export const useDeliveryAddressContext = () => {
  const context = useContext(UserAddressContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
};
