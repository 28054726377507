import API from "apiHelpers";
import { errorNotification } from "utils/Utils";
import { t } from "i18next";
import { Loader } from "store/actions";
import { useDispatch } from "react-redux";

function useCheckStoreAvailability() {
  const dispatch = useDispatch();
  async function checkStoreAvailability(payload) {
    try {
      dispatch(Loader.togggleCheckoutLoader(true));
      const response = await API.checkStoreAvailability(payload);
      if (response.success === 1 && response?.data === true) {
        return true;
      } else {
        errorNotification(
          t(
            "We apologize, but our store is closed right now. We hope to see you soon!"
          )
        );
        return false;
      }
    } catch (error) {
      errorNotification(
        t(
          "We apologize, but our store is closed right now. We hope to see you soon!"
        )
      );
      return false;
    } finally {
      dispatch(Loader.togggleCheckoutLoader(false));
    }
  }
  return { checkStoreAvailability };
}

export default useCheckStoreAvailability;
