import { useDispatch, useSelector } from "react-redux";
import { NotPersistAction } from "store/actions";
import { BrandAction } from "store/actions";

function useChangePickupStore() {
  const dispatch = useDispatch();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const handleChangePickupStore = () => {
    dispatch(NotPersistAction.togglePickupStoreModal(true));
    dispatch(BrandAction.getBrandStores(brandInfo?.brand._id));
  };

  return { handleChangePickupStore };
}

export default useChangePickupStore;
