import React from "react";
import SingleModifer from "./SingleModifer";
function ModifiersList({
  modifierGroup,
  selectedModifierGroups,
  radioButtonClick,
  checkboxClick,
  QuantityChange,
  updateRadioQuantity,
  isStoreSelected,
}) {
  return (
    <div>
      {modifierGroup?.modifiers.map((modifier, index) => {
        return (
          <SingleModifer
            {...{
              radioButtonClick,
              checkboxClick,
              QuantityChange,
              updateRadioQuantity,
            }}
            selectedModifierGroups={selectedModifierGroups}
            modifer={modifier.modifierId}
            key={modifier.modifierId._id}
            modifierGroup={modifierGroup}
            index={index} // Pass the index as a prop
            isStoreSelected={isStoreSelected}
          />
        );
      })}
    </div>
  );
}

export default ModifiersList;
