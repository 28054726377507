import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import C from "Constants";
import { BrandAction } from "store/actions";
import { NotPersistAction } from "store/actions";
import { logErrorOnSentry } from "utils/Utils";
import { errorNotification } from "utils/Utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { t } from "i18next";
import { useWindowLocation } from "hooks";

function useGetBrandAres() {
  const dispatch = useDispatch();
  const params = useParams();
  const windowRouter = useWindowLocation();

  const { orderType, DomainType } = useSelector((state) => state.AppReducer);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  const fetchBrandAreas = () => {
    if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
      dispatch(NotPersistAction.toggleAreaModal(true));
    } else if (orderType?.serviceType === C.SERVICE_TYPE.PICKUP) {
      dispatch(BrandAction.getBrandStores(brandInfo?.brand._id));
      dispatch(NotPersistAction.togglePickupStoreModal(true));
    } else {
      logErrorOnSentry(
        "invalid service type--on add address on home page",
        "error",
        JSON.stringify(orderType)
      );
      errorNotification(
        t("We're sorry, but something went wrong. Please try again")
      );
      windowRouter(`/`, `/${params.domainName}/`, DomainType);
    }
  };

  useEffect(() => {
    if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
      dispatch(
        BrandAction.getBrandDeliveryAreas({
          brandId: brandInfo?.brand?._id,
          updateMethod: true,
        })
      );
    }
  }, [brandInfo?.brand?._id, orderType?.serviceType]);

  return { fetchBrandAreas };
}

export default useGetBrandAres;
