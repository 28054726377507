import { createRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { StoreAction } from "store/actions";

function useIntersectionObsever(menu, menuType) {
  const dispatch = useDispatch();
  const refs = [];
  const newRef = () => {
    const ref = createRef();
    refs.push(ref);
    return ref;
  };
  useEffect(() => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          for (let entry of entries) {
            if (entry?.isIntersecting) {
              dispatch(StoreAction.setSelectedCategory(entry.target.id));
              const parentElement = document.querySelector("nav");
              const element = document.querySelector(
                `[data-key="${entry?.target?.id}"]`
              );

              const clicked = entry.target?.dataset?.clicked;
              if (clicked === entry.target.id) {
                entry.target.removeAttribute("data-clicked");
              } else {
                if (parentElement) {
                  parentElement?.scroll({
                    left: element?.offsetLeft - 120,
                    behavior: "smooth",
                  });
                }
              }
            }
          }
        },
        {
          threshold: 0.2,
          rootMargin: `-140px 0% -60% 0%`,
        }
      );

      refs.forEach((ref) => observer.observe(ref.current));
      return () => {
        refs.forEach((ref) => ref.current && observer.unobserve(ref.current));
      };
    } else {
    }
  }, [menu, menuType]);
  return {
    newRef,
    refs,
  };
}

export default useIntersectionObsever;
