import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CartAction } from "store/actions";
import moment from "moment";
import C from "Constants";

function useUpdateDeliveryFee() {
  const dispatch = useDispatch();
  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state?.store
  );
  const { amountAfterDiscount, cartTotal, orderGrossAmount, itemsGrossAmount } =
    useSelector((state) => state?.cart);
  const { orderType } = useSelector((state) => state.AppReducer);

  const calculateDeliveryFee = () => {
    if (orderType?.serviceType !== C.SERVICE_TYPE.DELIVERY) {
      dispatch(CartAction.setDeliveryFee(0));
      return 0;
    }
    if (selectedStore?.deliveryChargesWaiverInfo?.onOrderAmount?.isEnabled) {
      if (
        amountAfterDiscount >=
        selectedStore?.deliveryChargesWaiverInfo?.onOrderAmount?.amount
      ) {
        const obj = {
          isWaiver: true,
          waiverAmount: outletBasedSelectedStoreArea?.deliveryFee || 0,
          WaiverInfo: {
            onOrderAmount:
              selectedStore?.deliveryChargesWaiverInfo?.onOrderAmount,
          },
        };
        dispatch(CartAction.setDeliveryFee(0));
        dispatch(CartAction.setdeliveryChargesWaiverInfo(obj));
        return 0;
      }
    } else if (
      selectedStore?.deliveryChargesWaiverInfo?.onDateRange?.isEnabled
    ) {
      const { startDateTime, endDateTime } =
        selectedStore?.deliveryChargesWaiverInfo?.onDateRange;

      const currentMoment = moment();
      const endMoment = moment.unix(endDateTime);
      const startMoment = moment.unix(startDateTime);
      const offerEndTime = moment(endMoment);

      // Check if the current time is within the offer period
      if (currentMoment.isBetween(startMoment, offerEndTime)) {
        const obj = {
          isWaiver: true,
          waiverAmount: outletBasedSelectedStoreArea?.deliveryFee || 0,
          WaiverInfo: {
            onDateRange: selectedStore?.deliveryChargesWaiverInfo?.onDateRange,
          },
        };
        dispatch(CartAction.setDeliveryFee(0));
        dispatch(CartAction.setdeliveryChargesWaiverInfo(obj));
        return 0;
      } else {
        const obj = {
          isWaiver: false,
          waiverAmount: 0,
          WaiverInfo: null,
        };
        dispatch(
          CartAction.setDeliveryFee(
            outletBasedSelectedStoreArea?.deliveryFee || 0
          )
        );
        dispatch(CartAction.setdeliveryChargesWaiverInfo(obj));
        return outletBasedSelectedStoreArea?.deliveryFee || 0;
      }
    }
    const obj = {
      isWaiver: false,
      waiverAmount: 0,
      WaiverInfo: null,
    };
    dispatch(
      CartAction.setDeliveryFee(outletBasedSelectedStoreArea?.deliveryFee || 0)
    );
    dispatch(CartAction.setdeliveryChargesWaiverInfo(obj));
    return outletBasedSelectedStoreArea?.deliveryFee || 0;
  };
  useEffect(() => {
    calculateDeliveryFee();
  }, [
    selectedStore?._id,
    amountAfterDiscount,
    cartTotal,
    orderGrossAmount,
    itemsGrossAmount,
    orderType?.serviceType,
  ]);
}

export default useUpdateDeliveryFee;
