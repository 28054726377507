import { t } from "i18next";
import React from "react";
const PowerBy = () => {
  return (
    <div className="text-center p-5">
      <a
        href="https://resthero.io/"
        target={"_blank"}
        rel="noreferrer"
        className="px-1 cursor-pointer"
      >
        <img
          src={process.env.REACT_APP_COMPANY_LOGO}
          alt="resthero"
          className="inline-block w-6 h-6 mx-2"
        />
        <span className=" text-gray-500">{t("PoweredBy", {
          companyName: process.env.REACT_APP_COMPANY_NAME,
        })}</span>
      </a>
    </div>
  );
};

export default PowerBy;
