import { useHistory } from "react-router-dom";
import C from "Constants";
import { logErrorOnSentry } from "utils/Utils";

function useCustomHistory() {
  const history = useHistory();

  const redirectPage = (
    customDomainPath,
    temporaryDomainPath,
    DomainType,
    state,
    search = ""
  ) => {
    const validatePath = (path) => {
      if (
        path === null ||
        path === "null" ||
        path === undefined ||
        path === "undefined" ||
        path === ""
      ) {
        logErrorOnSentry(`route Path is not valid ${path}`);
        return false;
      } else return true;
    };

    if (DomainType === C.CUSTOM_DOMAIN && validatePath(customDomainPath)) {
      const path = validatePath(customDomainPath) ? customDomainPath : "/";
      const location = {
        pathname: path,
        state: state,
        search: search,
      };
      if (state) {
        history.replace(location);
      } else {
        history.replace({ ...location, state: undefined });
      }
    } else if (validatePath(temporaryDomainPath)) {
      const path = validatePath(temporaryDomainPath)
        ? temporaryDomainPath
        : "/";
      const location = {
        pathname: path,
        state: state,
        search: search,
      };
      if (state) {
        history.replace(location);
      } else {
        history.replace({ ...location, state: undefined });
      }
    } else {
      window.location.reload();
    }
  };

  return redirectPage;
}

export default useCustomHistory;
