import React from "react";
import { useParams } from "react-router-dom";
import { useLang } from "hooks";
import C from "Constants";
function StoreHeaderDetail({ page, brandInfo }) {
  const pageBrand = page === "brand" ? true : false;
  const params = useParams();
  const language = useLang();
  return (
    <div
      className={`${
        pageBrand === true ? "pt-5" : "py-5"
      } px-4 bg-white flex justify-between flex-col`}
    >
      <div>
        <h1 className="font-nunito text-1.5 font-extrabold text-black mb-1">
          {brandInfo ? brandInfo?.brand?.brandName : params?.domainName}
        </h1>
      </div>

      {brandInfo?.setting?.taglineAR?.trim() &&
        language?.currentLang === C.AR && (
          <div className="p-3 bg-grey-green-off rounded text-sm leading-normal text-black mt-4">
            {brandInfo?.setting?.taglineAR}
          </div>
        )}
      {brandInfo?.setting?.taglineEN?.trim() &&
        language?.currentLang === C.EN && (
          <div className="p-3 bg-grey-green-off rounded text-sm leading-normal text-black mt-4">
            {brandInfo?.setting?.taglineEN}
          </div>
        )}
    </div>
  );
}

export default StoreHeaderDetail;
