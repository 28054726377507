import CustomModal from "components/CustomModal";
import React, { useRef } from "react";
import Close from "icons/Close";
import C from "Constants";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { FormatPreOrderingTime } from "utils/OrderUtils";

function ChangePickupStoreModalContainer({
  isContainerModalopen,
  setContainerModalopen,
  handleChangeOrderStore,
  setPreOrderingSlotWidget,
  preORderSlots,
  isPreORderEnabled,
}) {
  const elementRef = useRef(null);
  const { selectedStore } = useSelector((state) => state?.store);
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);

  return (
    <>
      <CustomModal
        open={isContainerModalopen}
        setOpen={setContainerModalopen}
        bodyclasses="flex"
        overlayClasses=" fixed h-screen overflow-y-auto w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40 border-sc"
      >
        <div
          ref={elementRef}
          id="discount-info-popup"
          className=" fixed inset-x-0 bottom-0 flex justify-center"
        >
          <div className="bg-white w-full max-460  shadow-md ">
            <h2 className="w-fudll absolute max-460 -top-9 flex justify-end px-1">
              <Close onClick={() => setContainerModalopen(false)} />
            </h2>
            <div
              className="mb-2 text-2xl font-bold p-2"
              onClick={() => {
                handleChangeOrderStore();
                setContainerModalopen(false);
              }}
            >
              <div className="flex justify-between items-center">
                <div className="text center">
                  <h2 className="text-outer-space text-17x font-semibold my-4">
                    {t("Selected Store")}
                  </h2>
                  <h2 className="block cursor-pointer text-15x w-full font-semibold text-gray-400 capitalize">
                    {selectedStore?.baseLanguage?.name
                      ? selectedStore?.baseLanguage?.name
                      : t("Select Store")}{" "}
                  </h2>
                </div>
                <div className="cursor-pointer">
                  <h2 className="text-brand-base-primary cursor-pointer rounded border-brand-base-primary border px-1">
                    {t("Change")}
                  </h2>
                </div>
              </div>
            </div>
            <div className="mb-6 text-2xl font-bold p-2">
              <div
                className="flex justify-between items-center"
                onClick={() => {
                  if (isPreORderEnabled && preORderSlots?.length > 0) {
                    setPreOrderingSlotWidget(true);
                    setContainerModalopen(false);
                  }
                }}
              >
                <div className="text center">
                  <h2 className="text-outer-space text-17x font-semibold my-4">
                    {t("Pickup Time")}
                  </h2>

                  {orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER &&
                  orderTiming?.preOrderSlot?.day &&
                  orderTiming?.preOrderSlot?.time ? (
                    <span className="block cursor-pointer text-15x w-full font-semibold text-gray-400 capitalize">
                      {orderTiming?.preOrderSlot?.day}{" "}
                      {FormatPreOrderingTime(orderTiming?.preOrderSlot?.time)}
                    </span>
                  ) : (
                    <span className="block cursor-pointer text-15x w-full font-semibold text-gray-400 capitalize">
                      {t("ASAP")}
                    </span>
                  )}
                </div>
                {isPreORderEnabled && preORderSlots?.length > 0 ? (
                  <div>
                    <h2 className="cursor-pointer text-brand-base-primary rounded border-brand-base-primary border px-1 ">
                      {t("Change")}
                    </h2>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default ChangePickupStoreModalContainer;
