import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NotPersistAction } from "store/actions";
import { logErrorOnSentry } from "utils/Utils";
function useOffLine() {
  const [isOnline, setIsOnline] = useState(navigator?.onLine);
  const dispatch = useDispatch();
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
      dispatch(NotPersistAction.setInternetOnline());
      window.location.reload();
    }

    function handleOffline() {
      logErrorOnSentry("user is not connected to internet", "info");
      setIsOnline(false);
      dispatch(NotPersistAction.setInternetOffline());
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return {
    isOnline,
  };
}

export default useOffLine;
