import { useState, useEffect } from "react";
import C from "Constants";
function useScrollToBottomPaymentWidget(selectedPaymentType) {
  const [scrollPage, setScrollPage] = useState(false);
  const [calledOnce, setCalledOnce] = useState(false);

  const scrollToBottom = () => {
    setScrollPage(!scrollPage);
    setCalledOnce(true); // Set the flag to true when the function is called
  };

  useEffect(() => {
    if (calledOnce && selectedPaymentType === C.WALLET.NEW_CARD) {
      document.body.scrollTop = document.body.scrollHeight;
      document.documentElement.scrollTop =
        document.documentElement.scrollHeight;
    }
  }, [scrollPage, selectedPaymentType, calledOnce]);

  return scrollToBottom;
}

export default useScrollToBottomPaymentWidget;
