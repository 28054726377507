import React from "react";
import { useSelector } from "react-redux";
const CategoryListItem = ({ category }) => {
  const { selectedCategory } = useSelector((state) => state?.store);

  const handleClick = (id) => {
    try {
      const targetEle = document.getElementById(id);
      if (targetEle) {
        document.querySelectorAll("scroll-checker").forEach(function (element) {
          element.setAttribute("data-clicked", id);
        });

        const position = targetEle.getBoundingClientRect();
        window?.scrollTo({
          top: position.top + window.scrollY - 120,
          behavior: "smooth",
        });
      }
    } catch (error) {}
  };

  return (
    <span
      key={category?._id}
      data-key={category?._id}
      className="px-1 "
      onClick={() => handleClick(category?._id)}
    >
      <span
        className={`flex whitespace-nowrap items-center h-8 px-4 rounded-full border ${
          selectedCategory === category?._id
            ? "text-brand-text-primary bg-brand-base-primary border-brand-base-primary"
            : "border-gray-200"
        }`}
      >
        {category?.baseLanguage?.name}
      </span>
    </span>
  );
};

export default CategoryListItem;
