import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import Modifiers from "./ModifiersList";
import { getModifierDumbObj } from "utils/Utils";

const SingleModifierGroup = ({
  modifierGroup,
  setModifierGroups,
  selectedModifierGroups,
  setSingleItem,
  isStoreSelected,
}) => {
  const { requiredMissingSections } = useSelector((state) => state?.NotPersist);
  const {
    maximumAllowedSelection,
    baseLanguage,
    minimumAllowedSelection,
    isSingleSelection,
  } = modifierGroup;

  useEffect(() => {
    if (requiredMissingSections?.length > 0) {
      const section = requiredMissingSections?.[0]?.modifierGroupId;
      if (section?._id) {
        const optionId = document.getElementById(section._id);
        optionId?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [requiredMissingSections, modifierGroup]);

  // Adding And removing section options on checkBox click
  const checkboxClick = (item, secId) => {
    const newItems = [...selectedModifierGroups];
    // check if clicked  option section is already added
    const check = !!selectedModifierGroups?.find((i) => i?._id === secId);
    if (check) {
      // find section
      const section = selectedModifierGroups?.find((i) => i?._id === secId);
      // find index of option seclected
      const index = section?.options?.findIndex((i) => i?._id === item?._id);
      //  if option already added then remove it
      if (index !== -1) {
        //get clicked option
        const selectedOption = section?.options[index];
        //remove clicked option
        section?.options?.splice(index, 1);
        //if this was last option-- remove the section as well
        if (section?.options?.length === 0) {
          const index = newItems?.findIndex((i) => i?._id === section?._id);
          newItems?.splice(index, 1);
        }

        setSingleItem((prevState) => ({
          ...prevState,
          price: prevState?.price - item.price * selectedOption?.quantity,
        }));
      }
      // else add the option in section
      else if (
        index === -1 &&
        section?.options?.length < maximumAllowedSelection
      ) {
        setSingleItem((prevState) => ({
          ...prevState,
          price: prevState?.price + item.price,
        }));

        let Option = { ...item, quantity: 1 };
        const dumbOptObj = getModifierDumbObj(Option);
        section?.options.push({
          ...dumbOptObj,
        });
      }
    }

    // if this is the first option to be added update single item price and add new section in the cart
    else {
      let Option = { ...item, quantity: 1 };
      const dumbOptObj = getModifierDumbObj(Option);
      setSingleItem((prevState) => ({
        ...prevState,
        price: prevState.price + item?.price,
      }));
      newItems.push({
        baseLanguage: {
          sectionName: baseLanguage?.name,
        },
        _id: secId,
        options: [dumbOptObj],
        isSingleSelection: isSingleSelection,
        minimumAllowedSelection: minimumAllowedSelection,
        maximumAllowedSelection: maximumAllowedSelection,
        numOfSelectedOptions: 1,
      });
    }
    setModifierGroups(newItems);
  };

  //Check-Box Quantity change
  const QuantityChange = (item, secId, action) => {
    const newItems = [...selectedModifierGroups];
    // Check if the clicked option section is already added
    const sectionIndex = newItems?.findIndex((i) => i?._id === secId);

    if (sectionIndex !== -1) {
      // The section is already added
      const section = newItems[sectionIndex];
      const optionIndex = section?.options?.findIndex(
        (i) => i?._id === item?._id
      );

      if (optionIndex !== -1) {
        // The option is already added to the section
        if (action === "increment") {
          // Increment the quantity
          section.options[optionIndex].quantity += 1;
          setSingleItem((prevState) => ({
            ...prevState,
            price: prevState?.price + item.price,
          }));
        } else if (action === "decrement") {
          // Decrement the quantity
          if (section.options[optionIndex]?.quantity > 1) {
            section.options[optionIndex].quantity -= 1;
            setSingleItem((prevState) => ({
              ...prevState,
              price: prevState.price - item?.price,
            }));
          }
        }
      } else if (
        section?.options?.length < maximumAllowedSelection &&
        action === "increment"
      ) {
        const option = {
          ...item,
          quantity: 1,
        };
        const dumbOptObj = getModifierDumbObj(option);
        // Add the option to the section with quantity 1
        section.options.push(dumbOptObj);
        setSingleItem((prevState) => ({
          ...prevState,
          price: prevState.price + item?.price,
        }));
      }
    }
    //New section
    else {
      // The section is not added yet
      if (action === "increment") {
        // Create a new section with the option and quantity 1
        let option = { ...item, quantity: 1 };
        const dumbOptObj = getModifierDumbObj(option);
        newItems.push({
          baseLanguage: {
            sectionName: baseLanguage.name,
          },
          _id: secId,
          options: [dumbOptObj],
          isSingleSelection: isSingleSelection,
          minimumAllowedSelection: minimumAllowedSelection,
          maximumAllowedSelection: maximumAllowedSelection,
        });
        setSingleItem((prevState) => ({
          ...prevState,
          price: prevState.price + item?.price,
        }));
      }
    }

    setModifierGroups(newItems);
  };

  //Radio Button
  const radioButtonClick = (item, secId) => {
    const dumbOptObj = getModifierDumbObj({ ...item, quantity: 1 });
    const obj = {
      baseLanguage: {
        sectionName: baseLanguage.name,
      },
      _id: secId,
      options: [dumbOptObj],
      isSingleSelection: isSingleSelection,
      minimumAllowedSelection: minimumAllowedSelection,
      maximumAllowedSelection: maximumAllowedSelection,
    };
    var newItemss = [...selectedModifierGroups];

    //check if same section is added already
    const check = !!selectedModifierGroups?.find((i) => i?._id === secId);
    if (check) {
      //find the selected section
      const deleted = selectedModifierGroups?.find((i) => i?._id === secId);
      //find the index of section
      const index = newItemss?.findIndex((i) => i?._id === deleted?._id);

      //find the option at zero loc Price  (single selection can have only one selected option)
      const deletedItemPrice =
        deleted?.options?.length > 0 ? deleted?.options[0]?.price : 0;

      const addedOption = deleted?.options[0];
      //remove price of selected  option

      //if its same option --> then un-uncheck and subtract price
      if (item?._id === addedOption?._id) {
        setSingleItem((prevState) => ({
          ...prevState,
          price: prevState?.price - deletedItemPrice * addedOption?.quantity,
        }));

        newItemss.splice(index, 1);
      } else {
        //if its differnt option subtract selected Price with quantity and update new price
        setSingleItem((prevState) => ({
          ...prevState,
          price:
            prevState?.price +
            item?.price -
            deletedItemPrice * addedOption.quantity,
        }));
        //remove the section with Price
        newItemss?.splice(index, 1);
        //add same section  entry with new option
        newItemss?.push(obj);
      }
    }
    //if section is not there
    else {
      newItemss.push(obj);
      setSingleItem((prevState) => ({
        ...prevState,
        price: prevState.price + item.price,
      }));
    }
    setModifierGroups(newItemss);
  };

  const updateRadioQuantity = (item, secId, action) => {
    const newItems = [...selectedModifierGroups];

    // Check if the clicked option section is already added
    const sectionIndex = newItems?.findIndex((i) => i?._id === secId);

    if (sectionIndex !== -1) {
      // The section is already added
      const section = newItems[sectionIndex];
      const selectedOption = section?.options[0];

      //check if differnt option action is fired
      if (selectedOption?._id !== item?._id) {
        if (action === "increment") {
          // Remove the previously selected option and add the new one
          setSingleItem((prevState) => ({
            ...prevState,
            price:
              prevState.price -
              selectedOption.price * selectedOption?.quantity +
              item?.price,
          }));
          section?.options?.pop(); // Remove the previously selected option

          // Create a new section with the new option and quantity 1
          const newOption = { ...item, quantity: 1 };
          const dumbOptObj = getModifierDumbObj(newOption);
          section?.options?.push(dumbOptObj);
        } else {
        }
      } else {
        if (action === "increment") {
          // Check if the action is "increment" or "decrement"

          // Increment the quantity
          selectedOption.quantity += 1;
          setSingleItem((prevState) => ({
            ...prevState,
            price: prevState.price + item.price,
          }));
        } else if (action === "decrement") {
          // Decrement the quantity if it's greater than 1
          if (selectedOption.quantity > 1) {
            selectedOption.quantity -= 1;
            setSingleItem((prevState) => ({
              ...prevState,
              price: prevState.price - item?.price,
            }));
          }
        }
      }
    } else {
      // The section is not added yet
      if (action === "increment") {
        // Create a new section with the option and quantity 1
        let newOption = { ...item, quantity: 1 };
        const dumbOptObj = getModifierDumbObj(newOption);
        newItems.push({
          baseLanguage: {
            sectionName: baseLanguage.name,
          },
          _id: secId,
          options: [dumbOptObj],
          isSingleSelection: isSingleSelection,
          minimumAllowedSelection: minimumAllowedSelection,
          maximumAllowedSelection: 1, // Set maximumAllowedSelection to 1 for radio buttons
        });
        setSingleItem((prevState) => ({
          ...prevState,
          price: prevState?.price + item?.price,
        }));
      }
    }

    setModifierGroups(newItems);
  };

  const requiredMissing = () => {
    return requiredMissingSections?.find(
      (option) => option?.modifierGroupId?._id === modifierGroup?._id
    );
  };

  //increase decrease

  return (
    <div className="pt-2 border-b-8 border-slate-100">
      <div className="p-4">
        <div className="flex">
          <div className="w-full pb-2" id={modifierGroup?._id}>
            <p className="text-outer-space text-16x font-bold leading-19x">
              {baseLanguage?.name}
            </p>
            {requiredMissing() ? (
              <p className="text-14x  leading-19x pt-2 text-red-600">
                {t("choose upto")} {`${maximumAllowedSelection}`}
              </p>
            ) : (
              <p className="text-14x text-gunsmoke leading-19x pt-2">
                {t("choose upto")} {`${maximumAllowedSelection}`}
              </p>
            )}
          </div>
          <div className="px-2">
            {requiredMissing()
              ? minimumAllowedSelection > 0 && (
                  <p className="font-bold   text-14x  leading-19x border border-red-500 text-red-500 px-2">
                    {t("Required")}
                  </p>
                )
              : minimumAllowedSelection > 0 && (
                  <p className="text-14x text-gunsmoke leading-19x  font-bold pt-2 ">
                    {t("Required")}
                  </p>
                )}
          </div>
        </div>
        <Modifiers
          isStoreSelected={isStoreSelected}
          {...{
            modifierGroup,
            selectedModifierGroups,
            radioButtonClick,
            checkboxClick,
            QuantityChange,
            updateRadioQuantity,
          }}
        />
      </div>
    </div>
  );
};

export default SingleModifierGroup;
