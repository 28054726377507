import React from "react";
function TickIcon({ classNames }) {
  return (
    <svg
      fill="currentColor"
      xmlns="https://www.w3.org/2000/svg"
      viewBox="20 20 60 60"
      // className="w-4 h-4 fill-success text-brand-base-primary"
      className={`${
        classNames ? classNames : "w-4 h-4"
      } fill-success text-brand-base-primary`}
    >
      <path
        d="M72.88 28.971a5 5 0 00-3.438 1.531c-9.685 9.706-18.52 19.253-27.875 28.781l-11.47-9.156a5.002 5.002 0 10-6.25 7.813l15 12a5 5 0 006.657-.375c10.77-10.793 20.436-21.414 31-32a5 5 0 00-3.625-8.594z"
        overflow="visible"
        color="currentColor"
      ></path>
    </svg>
  );
}
export default TickIcon;
