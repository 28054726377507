import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UTILS } from "utils";
function useCurrentUser() {
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const [currentUser, setUser] = useState({});

  const setUserInfo = () => {
    const user = UTILS.getCurrentUser(brandInfo?.brand?._id);
    if (user) setUser(user);
  };
  useEffect(() => {
    if (brandInfo?.brand?._id) {
      setUserInfo();
    }
  }, [brandInfo?.brand?._id]);
  return {
    currentUser,
    setUserInfo,
  };
}

export default useCurrentUser;
