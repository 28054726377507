import React from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";

const LoyaltyRedeemSwitch = ({ switchLoyaltyStatus, redeemAmount }) => {
  const { loyaltyApplied } = useSelector((state) => state?.Loyalty);
  return (
    <>
      {Number(redeemAmount) > 0 && (
        <div className="mx-4 py-3 flex items-center bg-gray-100 px-2 rounded-lg">
          <div>
            <div className="flex items-center w-full">
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    className="sr-only"
                    readOnly
                    checked="checked"
                    onClick={() => switchLoyaltyStatus()}
                  />
                  <div
                    className={
                      loyaltyApplied
                        ? "block bg-brand-base-primary w-12 h-6 rounded-full"
                        : "block bg-gray-300 w-12 h-6 rounded-full"
                    }
                  ></div>
                  <div
                    className={
                      loyaltyApplied
                        ? "dot absolute right-1 top-1 bg-white w-4 h-4 rounded-full transition"
                        : "dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"
                    }
                  ></div>
                </div>
                <div className="text-gray-600 text-14px"></div>
              </label>
            </div>
          </div>
          <div className="text-15x text-black mx-2 ms-4">
            {t("Use your loyalty balance to get", {
              amount: Number(redeemAmount).toFixed(2),
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default LoyaltyRedeemSwitch;
