import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { HomePageHeader } from "features";
import Menu from "features/menu/containers/Menu";
import CustomModal from "components/CustomModal";
import ConfirmModal from "components/Modals/ConfirmModal";
import DeliveryButtonBar from "components/DeliveryButtonBar";
import { Spinner, NotFound, ErrorComponent } from "components";
import { StoreListForPickup } from "components/store/StoreList";
import StoreCategoryWithMenu from "components/menu/StoreCategoryWithMenu";
import DiscountHomeContainer from "components/discounts/DiscountHomeContainer";
import LoyaltyPolicyWidget from "components/brand-loyalty/LoyaltyPolicyWidget";
import AddressErrorModalPopup from "components/Modals/AddressErrorModalPopup";

import * as commonHooks from "hooks";

import C from "Constants";
import useChangeOrderType from "../../home-page-content-header/useChangeOrderType";

import { RESET_ACTIONS } from "utils";
import { createServiceTypeArray } from "utils/Utils";
import AddressList from "features/delivery-address/modals/AddressList";

const HomeContainer = () => {
  const params = useParams();
  commonHooks.usePlatform();
  const { currentLang } = commonHooks.useLang();
  const router = commonHooks.useCustomHistory();
  const getShippingMethod = commonHooks.useGetShippingMethod();
  const {
    setServiceType,
    confirmModal,
    setConfirmModal,
    confirmModalTitle,
    handleConfirmModal,
    handleCancelModal,
  } = useChangeOrderType();

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearch] = useState("");
  const [storeOptionOpen, setStoreOptionOpen] = useState(false);

  const { stores, error, notFound, brandMenu, discounts, brandMenuError } =
    useSelector((state) => state?.brand);
  const { loyaltyPolicy, brandInfo } = useSelector(
    (state) => state?.brandStorageReducer
  );
  const { isLoading } = useSelector((state) => state.loader);
  const { filteredMenu } = commonHooks.useMenuSearch(searchTerm, brandMenu);

  const { DomainType } = useSelector((state) => ({
    DomainType: state.AppReducer.DomainType,
  }));
  const { orderType } = useSelector((state) => state?.AppReducer);

  commonHooks.useEventTracker({ name: "HOME", shouldLog: true, orderType });

  const openStoreItem = async (item, category) => {
    await getShippingMethod();
    RESET_ACTIONS.resetCart();
    RESET_ACTIONS.resetSelectedStore();
    RESET_ACTIONS.resetDeliveryArea();
    RESET_ACTIONS.resetDeliveryAddress();
    if (item?.itemId?._id && category?._id) {
      let MainURl = "/store/item/";
      let TempUrl = `/${params?.domainName}${MainURl}${item?.itemId?._id}/${category?._id}`;
      let RootUrl = `${MainURl}${item?.itemId?._id}/${category?._id}`;
      router(RootUrl, TempUrl, DomainType, item?.itemId);
    }
  };

  const LOGIN_PAGE_URL = "/auth/login";

  const goToLogin = () =>
    router(
      LOGIN_PAGE_URL,
      `/${params?.domainName}${LOGIN_PAGE_URL}`,
      DomainType
    );

  if (isLoading) return <Spinner />;
  else if (!isLoading && notFound) return <NotFound message={notFound} />;
  return (
    <div className="store-page store-page-popup">
      <HomePageHeader />
      <div>{brandMenuError && <ErrorComponent error={brandMenuError} />}</div>
      <DiscountHomeContainer {...{ discounts }} selectedStore={null} />
      {loyaltyPolicy?.isLoyaltyActive && (
        <LoyaltyPolicyWidget {...{ loyaltyPolicy, goToLogin }} />
      )}
      <DeliveryButtonBar
        activeServices={createServiceTypeArray(brandInfo?.setting)}
        handleServiceType={setServiceType}
        orderType={orderType}
      />
      <div>{error && <ErrorComponent error={error} />}</div>
      <Menu
        menu={filteredMenu}
        menuType={C.OUTLET_BASED_CATALOGUE}
        {...{ searchTerm, setSearch }}
      >
        <StoreCategoryWithMenu
          menu={filteredMenu}
          menuType={C.OUTLET_BASED_CATALOGUE}
          onItemClick={openStoreItem}
          {...{ open, setOpen }}
          page="home"
        />
      </Menu>
      <CustomModal
        shouldCloseOnEsc="No"
        shouldCloseOnOverlayClick="No"
        open={storeOptionOpen}
        setOpen={setStoreOptionOpen}
        bodyclasses="flex"
        overlayClasses={`fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30 ${
          currentLang === "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <StoreListForPickup
          stores={stores}
          header={false}
          headerLabel=""
          styleClasses={`fixed bottom-0 h-5 bg-white min-h-70p max-460 o111verflow-y-auto rounded-t-lg min-h-60p  ${
            currentLang === "AR" ? "border-sc-l" : "border-sc"
          }`}
        />
      </CustomModal>

      <CustomModal
        open={confirmModal}
        setOpen={setConfirmModal}
        bodyclasses="flex"
        overlayClasses={`fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30 ${
          currentLang === "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <ConfirmModal
          onConfirm={handleConfirmModal}
          onCancel={handleCancelModal}
          title={confirmModalTitle}
        />
      </CustomModal>
      <AddressList page="/" pageFrom="home" />
      <AddressErrorModalPopup />
    </div>
  );
};

export default HomeContainer;
