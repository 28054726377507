import React from "react";
import TextareaInput from "./TextareaInput";
import { CartAction } from "store/actions";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import C from "Constants";

function PickupVehicleInfo({ className }) {
  const dispatch = useDispatch();
  const { pickupVehicleInfo } = useSelector((state) => state.cart);
  const { selectedStore } = useSelector((state) => state.store);
  const { orderType } = useSelector((state) => state.AppReducer);

  if (!selectedStore?.vehicleInfoForPickup?.isActive) return null;
  if (orderType?.EN?.name !== C.SERVICE_TYPE.PICKUP) return null;
  return (
    <div className={`px-4 ${className}`}>
      <label className="text-16x font-bold mb-4">{t("Add vehicle info")}</label>
      <TextareaInput
        id="pickupVehicleInfo"
        onChange={(e) =>
          dispatch(CartAction.setPickupVehicleInfo(e.target.value))
        }
        value={pickupVehicleInfo}
        placeholder={t("E.g plate 18692, color black, brand lexus")}
        styleClasses="mt-1 block w-full text-16x text-outer-space mt-2 p-1 bg-gray-100  border border-gray-300 rounded focus:outline-none"
      />
    </div>
  );
}

export default PickupVehicleInfo;
