import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ImageHeader from "components/ImageHeader";
import StoreHeaderDetail from "../components/StoreHeaderDetail";
import LocationBar from "../components/LocationBar";
import UserLogo from "components/UserLogo";
import LoginSignupButton from "components/LoginSignupButton";
import { useCustomHistory } from "hooks";
import * as ROUTE_CONSTANTS from "routes/constants";
import { createServiceTypeArray } from "utils/Utils";
import useCurrentUser from "hooks/useCurrentUser";

const HomePageHeader = () => {
  const params = useParams();
  const router = useCustomHistory();
  // const { currentUser } = useSelector((state) => state?.register);
  const { currentUser } = useCurrentUser();

  const { DomainType } = useSelector((state) => state.AppReducer);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  //go to settings
  const goToSettings = () => {
    let url = ROUTE_CONSTANTS.OUTLET_SETTINGS[1];
    if (params) {
      router(url, `/${params?.domainName}${url}`, DomainType);
    }
  };
  //go to login
  const goToLogin = () => {
    let url1 = ROUTE_CONSTANTS.OUTLET_LOGIN[1];
    router(url1, `/${params?.domainName}${url1}`, DomainType);
  };

  return (
    <>
      <LocationBar services={createServiceTypeArray(brandInfo?.setting)}>
        {currentUser?.token ? (
          <UserLogo {...{ goToSettings }} />
        ) : (
          <LoginSignupButton {...{ goToLogin }} />
        )}
      </LocationBar>
      <ImageHeader />
      <StoreHeaderDetail page="brand" brandInfo={brandInfo} />
    </>
  );
};

export default HomePageHeader;
