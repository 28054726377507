import React, { useState } from "react";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import WithMap from "components/Hoc/WithMap";
import PageHeader from "components/PageHeader";
import FixedFooter from "components/FixedFooter";
import { useMapContext } from "contexts/googleMap";
import WithLoading from "components/Hoc/WithLoading";
import AddressErrorModalPopup from "components/Modals/AddressErrorModalPopup";
import LatLatNotFallInCurrentStore from "components/Modals/LatLatNotFallInCurrentStore";

import { Loader, AppAction } from "store/actions";
import { useDeliveryAddressContext } from "contexts/userAddressess";
import useConfirmGeoLocation from "service-hooks/useConfirmGeoLocation";
import usePreFillLocalStorageAddress from "../../hooks/usePreFillLocalStorageAddress";
import useCheckOpenedStoresByLatLng from "service-hooks/useCheckOpenedStoresByLatLng";
import { useCustomHistory } from "hooks";
import ZoomErrorModal from "components/Modals/ZoomErrorModal";

function AddAddressItemPage() {
  usePreFillLocalStorageAddress();

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const router = useCustomHistory();

  const { DomainType } = useSelector((state) => state.AppReducer);

  const [mapref, setMapRef] = useState(null);
  const { confirmGeoLocation } = useConfirmGeoLocation();
  const { zoom } = useSelector((state) => state.DeliveryAddressReducer);
  const { googlemapState } = useMapContext();

  const { setAddressState, userAddressState } = useDeliveryAddressContext();
  const checkOpenedStoresByLatLng = useCheckOpenedStoresByLatLng();

  const goBack = () => {
    if (history?.location?.state?.page) {
      router(
        history?.location?.state?.page,
        `/${params.domainName}${history?.location?.state?.page}`,
        DomainType
      );
    } else {
      router("/", `/${params.domainName}/`, DomainType);
    }
  };

  const redirectToNewManualAddressPage = (areas) => {
    const NEW_MAP_ADD_URL = "/address/add-new-manual-address";
    router(
      NEW_MAP_ADD_URL,
      `/${params.domainName}${NEW_MAP_ADD_URL}`,
      DomainType,
      {
        page: history?.location?.state?.page,
        areas,
      },
      "redirectedFrom=map-address"
    );
  };

  const handleGeoLocation = async () => {
    dispatch(
      AppAction.setTriggerStoreApiAfterAddressSaveOnAppStrt({
        status: false,
        page: null,
      })
    );
    dispatch(Loader.setLoading(true));
    const res = await confirmGeoLocation();
    if (res?.isValid) {
      const isOpenedAnyStore = await checkOpenedStoresByLatLng(
        googlemapState?.userLocation?.coordinates
      );
      if (isOpenedAnyStore) {
        redirectToNewManualAddressPage(isOpenedAnyStore);
      }
    } else {
      setAddressState("zoomErrorModal", {
        open: true,
        message: res.message,
      });
    }
    dispatch(Loader.setLoading(false));
  };

  return (
    <WithLoading>
      <div className="relative w-screen h-app-height bg-white store-page-popup">
        <PageHeader
          pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full relative"
          title={t("Set Delivery Location")}
          page="selectMap"
          url={true}
          handleClick={goBack}
        />

        <div className="bg-gray-100">
          <div className="felx jsutify-center relative">
            <WithMap
              class={"map-height1"}
              width="100%"
              height="100%"
              showInput={true}
              zoomChanging={true}
              zoom={zoom}
              setMapRef={setMapRef}
              mapref={mapref}
              googlemapState={{
                userLocation: {
                  lat: googlemapState?.userLocation?.coordinates?.lat,
                  lng: googlemapState?.userLocation?.coordinates?.lng,
                },
                zoom: googlemapState?.zoom,
                mapAddress: googlemapState?.mapAddress,
              }}
              zoomerror={userAddressState?.zoomErrorModal?.message?.msg}
              isZoomModalOpen={userAddressState?.zoomErrorModal?.open}
              setZoomErrorModal={() =>
                setAddressState("zoomErrorModal", { open: false, message: "" })
              }
            />
          </div>

          <FixedFooter page={false} from="selec location">
            <span className="block w-full">
              <button
                onClick={handleGeoLocation}
                className="block text-center py-3 rounded text-brand-text-primary bg-brand-base-primary cursor-pointer text-16x w-full"
              >
                <span className="">
                  <div className="">
                    <span className="font-bold">{t("Confirm Location")}</span>
                  </div>
                </span>
              </button>
            </span>
          </FixedFooter>
        </div>
      </div>
      <AddressErrorModalPopup />
      {/* <ZoomErrorModal /> */}

      <LatLatNotFallInCurrentStore />
    </WithLoading>
  );
}

export default AddAddressItemPage;
