import CustomModal from "components/CustomModal";
import { StoreListForDelivery } from "components/store/StoreList";
import { useLang } from "hooks";
import React from "react";
import { useSelector } from "react-redux";
import { NotPersistAction } from "store/actions";

function DeliveryStoreList(props) {
  const { currentLang } = useLang();
  const { stores } = useSelector((state) => state?.brand);
  const { storeModal } = useSelector((state) => state.NotPersist);
  return (
    <>
      <CustomModal
        shouldCloseOnEsc="No"
        shouldCloseOnOverlayClick="No"
        open={storeModal}
        reduxAction={NotPersistAction.setModalClose()}
        bodyclasses="flex"
        overlayClasses="fixed ccc h-app-height w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30"
      >
        <StoreListForDelivery
          updateTemporaryLocation={props.updateTemporaryLocation}
          page="home-page"
          stores={stores}
          header={false}
          headerLabel=""
          styleClasses={`fixed bottom-0 h-5 bg-white min-h-70p max-460 o1111verflow-y-auto rounded-t-lg min-h-60p ${
            currentLang === "AR" ? "border-sc-l" : "border-sc"
          }`}
        />
      </CustomModal>
    </>
  );
}

export default DeliveryStoreList;
