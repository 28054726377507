import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  registerError: null,
  isError: false,
  storeError: null,
  errorModaL: false,
};

const errorSlice = createSlice({
  name: "errorSlice",
  initialState,
  reducers: {
    setRegisterError: (state, action) => {
      state.registerError = action.payload;
    },
    setError: (state, action) => {
      state.errorModaL = action.payload?.modal;
      state.isError = action.payload?.error ? true : false;
      state.error = action.payload?.error;
    },
    setErrorModal: (state, action) => {
      state.errorModaL = action.payload;
    },

    resetRegisterError: (state) => {
      state.isError = false;
      state.registerError = null;
    },
    setStoreError: (state, action) => {
      state.isError = true;
      state.storeError = action.payload;
    },
    resetErrorReducer: (state, action) => {
      state.error = null;
      state.registerError = null;
      state.isError = false;
      state.storeError = null;
      state.errorModaL = false;
    },
  },
});

export const {
  resetErrorReducer,
  setErrorModal,
  setError,
  setRegisterError,
  resetRegisterError,
  setStoreError,
} = errorSlice.actions;
export default errorSlice.reducer;
