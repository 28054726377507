import CustomModal from "components/CustomModal";
import ErrorModal from "components/ErrorModel";
import React from "react";
import { t } from "i18next";
import { useMapContext } from "contexts/googleMap";
import { useDeliveryAddressContext } from "contexts/userAddressess";

function ZoomErrorModal() {
  const { setZoom } = useMapContext();
  const { setAddressState, userAddressState } = useDeliveryAddressContext();

  const handleIncresezoom = () => {
    setAddressState("zoomErrorModal", {
      open: false,
      message: "",
    });
    if (userAddressState?.zoomErrorModal?.message?.type === "zoom") {
      setZoom(16);
    }
  };
  return (
    <>
      <CustomModal
        open={userAddressState?.zoomErrorModal?.open}
        setOpen={() =>
          setAddressState("zoomErrorModal", { open: false, message: "" })
        }
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-50"
      >
        <ErrorModal
          handleClik={handleIncresezoom}
          message={userAddressState?.zoomErrorModal?.message?.msg}
          btnTitle={
            userAddressState?.zoomErrorModal?.message?.type === "zoom"
              ? t("Increase zoom")
              : null
          }
        />
      </CustomModal>
    </>
  );
}

export default ZoomErrorModal;
