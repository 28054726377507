import React, { useState } from "react";
import AreaList from "./AreaList";
const CityList = ({ city, opened, lang, page }) => {
  const [open, setOpen] = useState(false);
  const setOpenState = () => {
    open ? setOpen(false) : setOpen(true);
  };
  return (
    <div onClick={setOpenState} className="cursor-pointer ">
      <h2 className="flex mx-4 py-4 items-center justify-between text-16x font-semibold border-b border-area_heading-b">
        {
          <span className="text-outer-space">
            {lang === "EN"
              ? city?.cityInfo?.cityNameEN
              : city?.cityInfo?.cityNameAR}
          </span>
        }

        <svg
          viewBox="0 0 24 24"
          className={`h-18x w-18x transform  text-brand-base-primary
                    ${open ? "rotate-180" : ""}
                  `}
        >
          <path
            fill="CurrentColor"
            d="M4.88398 7.11612L3.11621 8.88389L12.0001 17.7678L20.884 8.88389L19.1162 7.11612L12.0001 14.2322L4.88398 7.11612Z"
          ></path>
        </svg>
      </h2>

      <ul className="bg-area-bg cursor-pointer">
        {(open || opened) && <AreaList area={city} lang={lang} />}
      </ul>
    </div>
  );
};

export default CityList;
