import React from "react";
const UserLogo = ({ goToSettings }) => {
  return (
    <button
      onClick={goToSettings}
      className="h-9 flex items-center border border-brand-base-primary justify-center w-9 rounded-full mx-3 bg-white cursor-pointer"
    >
      <svg
        viewBox="0 0 21 22"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
        className="h-6 w-6 text-brand-base-primary"
      >
        <path
          d="M7.269 9.062a3.23 3.23 0 106.461 0 3.23 3.23 0 00-6.461 0zM4.98 17.332a6.462 6.462 0 0111.04 0"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M2.1 11a8.4 8.4 0 1016.8 0 8.4 8.4 0 00-16.8 0v0z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </button>
  );
};

export default UserLogo;
