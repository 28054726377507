import CustomModal from "components/CustomModal";
import React from "react";
import SelectDeliveryArea from "components/outlet-based-areas/SelectDeliveryArea";
import { useSelector } from "react-redux";
import { NotPersistAction } from "store/actions";
import { useLang } from "hooks";

function DeliveryAreaModal() {
  const { areaModal } = useSelector((state) => state.NotPersist);
  const { currentLang } = useLang();

  return (
    <>
      <CustomModal
        open={areaModal}
        reduxAction={NotPersistAction.toggleAreaModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30"
      >
        <SelectDeliveryArea
          header={false}
          // error={areasError}
          headerLabel=""
          styleClasses={`fixed bottom-0 h-5 bg-white min-h-70p max-460  rounded-t-lg min-h-60p ${
            currentLang === "AR" ? "border-sc-l" : "border-sc"
          }`}
        />
      </CustomModal>
    </>
  );
}

export default DeliveryAreaModal;
