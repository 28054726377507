import { UTILS } from "utils";
import { apiSlice } from "apiHelpers/ApiSlice";
import reduxstore from "store/store";
import { NotPersistAction } from "store/actions";
import { LoyaltyAction } from "store/actions";
import { ErrorAction } from "store/actions";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { t } from "i18next";
import C from "Constants";
import { logErrorOnSentry } from "utils/Utils";
import {
  validateBrandOrderObject,
  getBrandBasedOrderObject,
} from "utils/OrderUtils";
const placeMyOrder = apiSlice.endpoints.placeMyOrder;
// const getHostedPage = apiSlice.endpoints.getHostedPage;
export const PLACE_order = async (obj) => {
  const { data } = await reduxstore.dispatch(placeMyOrder.initiate(obj));
  if (data?.success === 1) {
    reduxstore.dispatch(NotPersistAction.toggleOrderPlaceModel(true));
    reduxstore.dispatch(LoyaltyAction.removeLoyalty());
  } else {
    reduxstore.dispatch(NotPersistAction.toggleOrderPlaceModel(false));
    logError(data.message || t(C.DEFAULT_ERROR_MSG), true, "error");
    return;
  }
};
export const brand_based_place_order = async (paymentObj = null) => {
  const { errors, isValid, obj } = await validateBrandOrderObject();
  if (!isValid) {
    showErrors(errors);
    return;
  }
  obj.transactionInfo = paymentObj?.paymentIntent;
  const orderObject = getBrandBasedOrderObject(obj);
  PLACE_order(orderObject);
};

export const outlet_based_place_order = async (paymentObj = null) => {
  const { orderObject, isValid, errors } = UTILS.validateOutletOrderObject();
  if (!isValid) {
    showErrors(errors);
    return;
  }
  orderObject.transactionInfo = paymentObj?.paymentIntent;
  const orderobj = UTILS.getOutletOrderObject(orderObject);
  PLACE_order(orderobj);
};

export const showErrors = (errors) => {
  const error = Object.values(errors).find((value) => value !== null);
  if (error) {
    logError(errorHandler(error), true, "error");
  }
};

export const logError = (msg, modal, level) => {
  reduxstore.dispatch(
    ErrorAction.setError({
      error: msg,
      modal: modal,
    })
  );
  if (msg && msg !== C.DEFAULT_ERROR_MSG) {
    logErrorOnSentry(msg, level);
  }
};

export const validateSavedCard = (cardType, selectedCard) => {
  if (
    selectedCard?.cardInfo?._id &&
    selectedCard?.cardInfo?.paymentGateway === cardType
  ) {
    return true;
  }
  return false;
};
