import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { logPopupError } from "utils/Utils";
import C from "Constants";
import {
  stripeCreatePaymentLinkIntent,
  stripeVerifyPaymentLinkIntent,
} from "./utils";
import { useSelector } from "react-redux";
import { t } from "i18next";
function StripeHandler() {
  const stripe = useStripe();
  const elements = useElements();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  const initiateStripePayment = async (
    setcardProcessing,
    paymentLinkInfo,
    fetchPaymentLinkInfo
  ) => {
    try {
      setcardProcessing(true);
      const cardElement = elements?.getElement(CardElement);
      const { error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (error) {
        logPopupError(error.message);
        setcardProcessing(false);
        return;
      } else {
        const createStripePaymentIntent = await stripeCreatePaymentLinkIntent(
          brandInfo?.brand?._id,
          paymentLinkInfo?.cartId
        );

        //create payment intent
        if (
          createStripePaymentIntent?.success === 1 &&
          createStripePaymentIntent?.gatewayData?.fetchedLink?.transactionDetail
            ?.client_secret &&
          createStripePaymentIntent?.gatewayData?.isVerified === false
        ) {
          const clientSecret =
            createStripePaymentIntent?.gatewayData?.fetchedLink
              ?.transactionDetail?.client_secret;
          //confirm payment
          const confirmpayload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          });
          if (
            confirmpayload?.paymentIntent?.status === "succeeded" &&
            confirmpayload?.paymentIntent?.id
          ) {
            const verifyStripePaymentIntent =
              await stripeVerifyPaymentLinkIntent({
                brandId: brandInfo?.brand?._id,
                cartId: paymentLinkInfo?.cartId,
                paymentIntentId: confirmpayload?.paymentIntent?.id,
              });
            if (
              verifyStripePaymentIntent?.success === 1 &&
              verifyStripePaymentIntent?.gatewayData?.isVerified === true
            ) {
              await fetchPaymentLinkInfo();
            }
          }
          //confirm payment failed
          else {
            logPopupError(confirmpayload?.error?.message);
          }
        } else {
          logPopupError("Invalid Payment Intent", true);
        }
      }
      setcardProcessing(false);
    } catch (error) {
      setcardProcessing(false);
      logPopupError(error.message || t(C.DEFAULT_ERROR_MSG), true);
    }
  };
  return {
    initiateStripePayment,
  };
}

export default StripeHandler;
