import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetRecommendedItemsListMutation } from "store/item/itemService";

function useGetRecommendedItems() {
  const { cart } = useSelector((state) => state.cart);
  const [getRecommendedItemsList] = useGetRecommendedItemsListMutation();
  const { selectedStore, storeMenu } = useSelector((state) => state.store);
  const { orderType } = useSelector((state) => state.AppReducer);
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const [itemList, setItemList] = useState([]);
  const getRecommendedItems = async () => {
    if (cart?.length <= 0) return [];
    try {
      const latestAddedITem = cart[cart?.length - 1];
      const obj = {
        itemId: latestAddedITem.itemId,
        brandId: brandInfo?.brand?._id,
        storeId: selectedStore?._id,
        menuId: storeMenu?._id,
        serviceType: orderType?.serviceType,
        categoryId: latestAddedITem?.categoryId,
      };
      if (
        !obj?.brandId ||
        !obj?.storeId ||
        !obj?.menuId ||
        !obj?.serviceType ||
        !obj?.itemId ||
        !obj?.categoryId
      )
        return [];

      const response = await getRecommendedItemsList(obj).unwrap();
      if (response?.success === 1) {
        setItemList(response?.data);
        return response?.data;
      } else {
        setItemList([]);

        return [];
      }
    } catch (error) {
      setItemList([]);
      return [];
    }
  };

  useEffect(() => {
    getRecommendedItems();
  }, [cart?.length]);

  return {
    itemList,
    getRecommendedItems,
  };
}

export default useGetRecommendedItems;
