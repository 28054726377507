import { useLang } from "hooks";
import React from "react";

const ModalCloseButton = ({ onClick }) => {
  const { currentLang } = useLang();
  return (
    <div
      onClick={onClick}
      className={`absolute -top-10 h-8 w-8 font-semibold ${
        currentLang === "EN" ? "right-2" : "left-2"
      } bg-white rounded-full`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        className="text-red-600 h-6 w-6 mx-1 mt-1 cursor-pointer"
        viewBox="0 0 16 16"
      >
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
      </svg>
    </div>
  );
};

export default ModalCloseButton;
