import React from "react";
const getMandatoryField = (man) => {
  if (man) {
    return <span className="text-red-600"> *</span>;
  }
};
const TextInput = (props) => {
  const {
    mandatory,
    labelClasses,
    inputClasses,
    label,
    placeHolder,
    value,
    readOnly,
    onChange,
    name,
    error,
    id,
  } = props;
  const mandatoryField = getMandatoryField(mandatory);
  return (
    <>
      <label className={labelClasses}>
        {label}
        {mandatoryField}
      </label>
      {error && <div className="text-red-500">{error}</div>}

      <input
        autoComplete="off"
        type="text"
        className={inputClasses}
        placeholder={placeHolder}
        value={value ? value : ""}
        readOnly={readOnly ? readOnly : false}
        onChange={onChange ? onChange : () => {}}
        name={name ? name : ""}
        id={id}
      />
    </>
  );
};
export default TextInput;
