import React from "react";
const CloseButton = (props) => {
  return (
    <div className="p-4">
      <button type="button" onClick={props.handleClick}>
        <span className="rounded-full flex items-center  justify-center bg-circle p-2 border border-white hover:border hover:border-brand-base-primary cursor-pointer">
          <svg
            height="24"
            width="24"
            viewBox="0 0 24 24"
            className="h-4 w-4 text-brand-base-primary"
          >
            <path
              fill="currentColor"
              d="M12.0001 10.2322L5.88398 4.11612L4.11621 5.88389L10.2323 12L4.11621 18.1161L5.88398 19.8839L12.0001 13.7678L18.1162 19.8839L19.884 18.1161L13.7679 12L19.884 5.88389L18.1162 4.11612L12.0001 10.2322Z"
            ></path>
          </svg>
        </span>
      </button>
    </div>
  );
};

export default CloseButton;
