import React from "react";

function AddressHome() {
  return (
    <svg
      fill="currentColor"
      className="h-4 w-4 text-brand-base-primary inline-block mx-1"
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 346 279"
    >
      <path d="M342 142L182 3c-4-4-11-4-15 0L4 142c-11 10 4 27 15 18l20-17v125c0 6 5 11 12 11h244c7 0 12-5 12-11V143l20 17c11 10 26-8 15-18zM62 123l112-96 110 95v134H62V123z"></path>
    </svg>
  );
}

export default AddressHome;
