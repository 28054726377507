import React, { useMemo, useState, useRef } from "react";
import PopupHeader from "components/PopupHeader";
import Search from "components/Search";
import { useLang } from "hooks";
import { t } from "i18next";
import useTouchMoveBlur from "hooks/useTouchMoveBlur";

const getHeader = (header, headerLabel) => {
  if (header) {
    return <PopupHeader heading={headerLabel} />;
  }
};

// Parent
function CityList(props) {
  const {
    styleClasses,
    header,
    headerLabel,
    handleSelectedArea,
    deliveryAreas,
  } = props;
  const [searchTerm, setSearch] = useState("");
  const { currentLang } = useLang();
  const inputRef = useRef(null);
  useTouchMoveBlur({ inputRef });
  const handleSearch = (e) => {
    let term = e.target.value?.toLowerCase();
    setSearch(term);
  };

  const Cities = useMemo(() => {
    const lowerCaseSearchTerm = searchTerm?.toLowerCase() ?? "";
    const countryDeliveryData = deliveryAreas;
    if (!lowerCaseSearchTerm) {
      return countryDeliveryData;
    }

    return countryDeliveryData.filter(
      (city) =>
        city?.cityID?.cityNameEN.toLowerCase().includes(lowerCaseSearchTerm) ||
        city?.cityID?.cityNameAR.toLowerCase().includes(lowerCaseSearchTerm)
    );
  }, [searchTerm, deliveryAreas]);

  return (
    <div className="max-460">
      <div className={styleClasses}>
        {getHeader(header, headerLabel)}
        <div className="px-4">
          <Search
            label={t("Search for a city")}
            onChange={handleSearch}
            value={searchTerm}
            inputRef={inputRef}
          />
        </div>
        <></>
        <div className="scr-area-set">
          {Cities?.map((city) => {
            return (
              <div
                className="cursor-pointer "
                key={city?._id}
                onClick={() => handleSelectedArea(city, "city")}
              >
                <h2 className="flex mx-4 py-4 items-center justify-between text-16x font-semibold border-b border-area_heading-b">
                  {
                    <span className="text-outer-space">
                      {city?.cityID?.[`cityName${currentLang}`]}
                    </span>
                  }
                </h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CityList;
