import { useCustomHistory } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Actions from "store/actions";

function useTrackOrder({ placedOrder }) {
  const dispatch = useDispatch();
  const params = useParams();
  const router = useCustomHistory();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const trackOrder = () => {
    let url = "/track-order/";
    document.body.removeAttribute("style");
    dispatch(Actions.NotPersistAction.toggleOrderPlaceModel(false));
    if (placedOrder?.savedOrder) {
      const { savedOrder } = placedOrder;
      router(
        `${url}${savedOrder?.orderNumber}`,
        `/${params.domainName}${url}${savedOrder?.orderNumber}`,
        DomainType
      );
    }
  };
  return trackOrder;
}

export default useTrackOrder;
