import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Frames } from "frames-react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { t } from "i18next";
//components
import CheckoutDeliveryArea from "components/checkout/CheckoutDeliveryArea";
import CheckoutPaymentMethod from "components/checkout/CheckoutPaymentMethod";
import OrderSlice from "store/checkout/order/orderService";
import BasketItemList from "components/basket/BasketItemList";
import DiscountButton from "components/basket/DiscountButton";
import WithLoading from "components/Hoc/WithLoading";
import { PageHeader, ErrorComponent } from "components";
import EmptyBasket from "components/basket/EmptyBasket";
import CheckoutModals from "../components/Modals";
//actions
import {
  // DiscountAction,
  CartAction,
  NotPersistAction,
  OrderAction,
  ErrorAction,
  Loader,
} from "store/actions";

//custom hooks
import {
  useLang,
  useCustomHistory,
  useWindowLocation,
  useSetDefaultPaymentType,
  useUserWallet,
} from "hooks";

//constants
import { UTILS } from "utils";
import C from "Constants";
//services
import {
  useInitiateCheckoutRequestMutation,
  useVerifyCheckoutDotComPaymentMutation,
} from "store/checkout/payments/service";
//helper functions
import { errorHandler } from "apiHelpers/api/errorHandler";
import {
  isValidValue,
  calculateCartGrossAmount,
  logErrorOnSentry,
} from "utils/Utils";
import LoyaltyCreditWidget from "components/brand-loyalty/LoyaltyCreditWidget";
import { LoyaltyAction } from "store/actions";
import { min } from "lodash";
import TextareaInput from "components/TextareaInput";
import useBrandLosgisticIntegrations from "hooks/useBrandLosgisticIntegrations";
import {
  brand_based_place_order,
  logError,
  showErrors,
  validateSavedCard,
} from "components/checkout/Common_Modules/utils";
import SavedAddressHandling from "../components/SavedAddressHandling";
import { process_telr } from "helpers/payment_helpers/telr/TelrCardPayment";
import StripeHelper from "helpers/payment_helpers/stripe/StripeCardPay";
import SavedCardsList from "components/checkout/SavedCardsList";
import CardWidgets from "components/checkout/CardWidgets";
import PickupVehicleInfo from "components/PickupVehicleInfo";
import FooterWithWallet from "components/checkout/FooterWithWallet";
import useScrollToBottomPaymentWidget from "hooks/useScrollToBottomPaymentWidget";
import { getDeliveryAreaFeeForBrandBased } from "utils/OrderUtils";
import CartDetail from "../components/CartDetail";
import { validateBrandOrderObject } from "utils/OrderUtils";
import DeliveryTime from "components/checkout/DeliveryTime";
import { PreOrderingSlotWidget } from "components/Pre-ordering";
import useToggleLoyalty from "../useToggleLoyalty";
const redirectUrl = C.PAYMENT_RDX_LINKS.BRAND_BASE;
function Checkout() {
  const params = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { currentLang } = useLang();
  useSetDefaultPaymentType();
  const windowRouter = useWindowLocation();

  const [verifyPayment] = OrderSlice.useVerifyPaymentMutation();
  const [initiateCheckoutRequest, { isLoading: initiateRequestLoader }] =
    useInitiateCheckoutRequestMutation();
  const [verifyCheckoutDotComPayment, { isLoading: verifyCheckoutDotLoader }] =
    useVerifyCheckoutDotComPaymentMutation();

  const reduxState = useSelector((state) => state);
  const { orderSuccess } = reduxState.NotPersist;
  const { DomainType, orderType, shippingMethod } = reduxState.AppReducer;
  const { error: discountError } = reduxState.Discounts;
  const { transactionInfo, orderRef } = reduxState.order;
  const { discountModal } = reduxState.NotPersist;
  const { discountInfo } = reduxState.Discounts;
  const { selectedPayment, selectedStore } = reduxState.store;
  const [cardProcessing, setcardProcessing] = useState(false);
  const { integrationsByName } = useBrandLosgisticIntegrations();
  const { selectedCard } = useSelector((state) => state.PaymentCardsReducer);
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );
  const scrollToBottom = useScrollToBottomPaymentWidget(selectedPaymentType);
  const { userdeliveryAdress } = reduxState.DeliveryAddressReducer;
  const { selectedArea, selectedCountry, selectedCity } = useSelector(
    (state) => state.DeliveryAreaReducer
  );
  const {
    pullAddress,
    cart,
    cartTotal,
    amountAfterDiscount,
    orderSplReq,
    orderGrossAmount,
  } = reduxState.cart;
  const { loyaltyPolicy } = reduxState.brandStorageReducer;
  const userWallet = useUserWallet();
  const [redeemAmount, setRedeemAmount] = useState(0);

  const {
    getAddresses,
    addressList,
    deleteSlectedAddress,
    setDeliveryAddress,
    changeAddressModal,
    setChangeAddressModal,
  } = SavedAddressHandling();

  const { process_stripe_payment_brand_based } =
    StripeHelper(setcardProcessing);
  const [isPreOrderingSlotWidgetOpen, setPreOrderingSlotWidget] =
    useState(false);

  const updateCart = () => {
    const { itemsGrossAmount, itemsNetPrice, orderGrossAmount } =
      calculateCartGrossAmount(cart, discountInfo, userWallet);
    dispatch(
      CartAction.updateCartPrice({
        originalPrice: itemsNetPrice,
        amountAfterDiscount: itemsGrossAmount,
        itemsGrossAmount,
        orderGrossAmount,
      })
    );
  };

  const {
    handleConfirmDiscountLoyalty,
    confirmModal,
    confirmModalTitle,
    handleConfirmModal,
    setConfirmModal,
    handleCancelModal,
  } = useToggleLoyalty({
    updateCart,
    discountInfo,
  });

  useEffect(() => {
    updateCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discountInfo, redeemAmount]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "POP") {
        dispatch(NotPersistAction.toggleOrderPlaceModel(false));
        if (cart?.length <= 0) {
          sessionStorage.removeItem("paymentVerified");
          windowRouter("/", `/${params.domainName}/`, DomainType);
        }
      }
    });

    const returnToHome = () => {
      if (cart?.length <= 0 && !orderSuccess) {
        sessionStorage.removeItem("paymentVerified");
        windowRouter("/", `/${params.domainName}/`, DomainType);
      }
    };
    returnToHome();
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSuccess, history]);

  const process_checkout_dotCom = async () => {
    const isSavedCard = validateSavedCard("CHECKOUTDOTCOM", selectedCard);
    if (isSavedCard) {
      captureCheckoutDotComPayment();
    } else {
      const isCardValid = Frames.isCardValid();
      if (isCardValid) {
        setcardProcessing(true);
        await Frames.submitCard()
          .then(() => {
            setcardProcessing(false);
          })
          .catch((e) => {
            setcardProcessing(false);
            logError(errorHandler(e.error), true, "error");
          });
      } else {
        scrollToBottom();
        logError(t("pleaseEnterValidCardDetails"), true, "error");
      }
    }
  };

  const placeOrder = async () => {
    logError(null, false, "info");
    sessionStorage.removeItem("paymentVerified");
    const { errors, isValid, obj } = validateOrderShipping();
    if (!isValid) {
      showErrors(errors);
      return;
    }
    const { selectedPayment, totalOrderPrice } = obj;
    if (selectedPayment?.paymentType === C.CREDIT_CARD) {
      if (+parseFloat(orderGrossAmount) <= 2) {
        logError(t("addMoreThanTwoAED"), true, "info");
        return;
      } else {
        if (integrationsByName[C.STRIPE])
          process_stripe_payment_brand_based({
            amount: totalOrderPrice,
            storeId: selectedStore._id,
          });
        else if (integrationsByName[C.CHECKOUT_DOT_COM]) {
          dispatch(CartAction.togglePullAddress(false));

          process_checkout_dotCom();
        } else if (integrationsByName[C.TELR]) {
          dispatch(CartAction.togglePullAddress(false));

          process_telr(
            totalOrderPrice,
            params,
            selectedStore,
            DomainType,
            redirectUrl
          );
        }
      }
    } else {
      brand_based_place_order();
    }
  };

  const validateOrderShipping = () => {
    const { errors, isValid, obj } = validateBrandOrderObject();
    return {
      errors,
      isValid,
      obj,
    };
  };

  const verifyPaymentStatus = async (paymentId) => {
    dispatch(Loader.togggleCheckoutLoader(true));
    try {
      const { errors, isValid, obj } = await validateBrandOrderObject();
      if (isValid) {
        // chekout dot com
        if (integrationsByName[C.CHECKOUT_DOT_COM]) {
          const { data: paymentStatus } = await verifyCheckoutDotComPayment(
            paymentId
          );
          if (
            paymentStatus?.success === 1 &&
            paymentStatus?.gatewayData?.isVerified &&
            paymentStatus?.gatewayData
          ) {
            obj.transactionInfo = paymentStatus?.gatewayData;
            await brand_based_place_order({
              paymentIntent: paymentStatus?.gatewayData,
            });
          } else {
            dispatch(
              ErrorAction.setError({
                error: errorHandler(paymentStatus?.error),
                modal: true,
              })
            );
            verficationError(errorHandler(paymentStatus?.error));
          }
        }
        // telr flow
        else if (integrationsByName[C.TELR]) {
          const info = {
            storeId: transactionInfo.storeId,
            cartId: transactionInfo.cartId,
            obj,
          };

          const paymentStatus = await verifyPayment(info).unwrap();
          if (paymentStatus?.success === 1 && paymentStatus?.data) {
            obj.transactionInfo = paymentStatus?.data;
            await brand_based_place_order({
              paymentIntent: paymentStatus?.data,
            });
          } else if (paymentStatus?.success === 0) {
            const error = {
              error: t("Payment failed or cancelled"),
              modal: true,
            };
            dispatch(ErrorAction.setError(error));
            verficationError("Payment failed or cancelled");
          }
        }
      }
      // order object is not valid
      else {
        if (errors) {
          showErrors(errors);
        } else {
          dispatch(
            ErrorAction.setError({
              error: t(C.DEFAULT_ERROR_MSG),
              modal: true,
            })
          );
        }
        verficationError(C.DEFAULT_ERROR_MSG);
      }
    } catch (e) {
      dispatch(
        ErrorAction.setError({
          error: errorHandler(e),
          modal: true,
        })
      );
      verficationError(errorHandler(e));
    }
  };

  const verficationError = (err) => {
    logErrorOnSentry(err);
    dispatch(Loader.togggleCheckoutLoader(false));
  };

  const captureCheckoutDotComPayment = async (token) => {
    if (
      !isValidValue(token) &&
      !validateSavedCard(C.CHECKOUT_DOT_COM, selectedCard)
    ) {
      logError(t("cardProccesingError"), true, "error");
      return;
    }
    try {
      const { errors, isValid, obj } = await validateBrandOrderObject();
      if (!isValid) {
        showErrors(errors);
        return;
      }
      const { totalOrderPrice } = obj;
      const hostedObj = {
        domainName: params.domainName,
        amountPaid: totalOrderPrice,
        storeId: selectedStore._id,
        DomainType,
        successRedirectUrl: "order/checkout/?payment=success",
        failureRedirectUrl: "order/checkout/?payment=failure",
      };
      const paymentObj = UTILS.getHostedEcommerceObj(hostedObj);
      if (!paymentObj?.isValid) {
        logError(t("cardProccesingError"), true, "error");
        return;
      }
      const { data: response } = await initiateCheckoutRequest({
        isStoredCard: selectedCard?.isStoredCard,
        ...(selectedCard?.cardInfo?._id
          ? {
              storeCardId: selectedCard?.cardInfo?._id,
            }
          : {
              token: token,
            }),
        currency: C.AED,
        amount: paymentObj.value.amountPaid,
        storeId: paymentObj.value.storeId,
        successUrl: paymentObj.value.successRedirectUrl,
        failureUrl: paymentObj.value.failureRedirectUrl,
      });
      if (
        response?.success === 1 &&
        response?.gatewayData?.axiosResData?.framesResponse?.id
      ) {
        let paymentId = response.gatewayData.axiosResData.framesResponse.id;
        await dispatch(OrderAction.setOrderRef(paymentId));
        if (
          response?.gatewayData?.three_d_secure_redirection_required &&
          response.gatewayData?.axiosResData?.framesResponse?.redirectLink
        ) {
          window.location.replace(
            response.gatewayData?.axiosResData?.framesResponse?.redirectLink
          );
        } else if (
          response?.gatewayData?.three_d_secure_redirection_required === false
        ) {
          verifyPaymentStatus(paymentId);
        }
        //
        else {
          logErrorOnSentry(
            `3Dsecure_redirection_required is undefined + ${errorHandler(
              response.message
            )}`
          );
          logError(errorHandler(response.message), true, "error");
          return;
        }
      } else if (
        response?.success === 1 &&
        !isValidValue(response?.gatewayData?.axiosResData?.framesResponse?.id)
      ) {
        logErrorOnSentry(
          `framesResponse.id orderRef is undefined ${C.DEFAULT_ERROR_MSG}`
        );
        logError(t(C.DEFAULT_ERROR_MSG), true, "error");
        return;
      }
      //Initiate frames request failed
      else {
        logError(errorHandler(response?.error), true);
        return;
      }
    } catch (e) {
      // catch all
      logError(errorHandler(e), true, "error");
    }
  };

  const queryParams = queryString.parse(search);
  useEffect(() => {
    const paymentVerified = sessionStorage.getItem("paymentVerified");
    const sessionId = queryParams["cko-session-id"];
    if (paymentVerified) {
      return;
    }

    // Checkout.com Payment
    if (
      integrationsByName[C.CHECKOUT_DOT_COM] &&
      orderRef &&
      ["success", "failure"].includes(queryParams.payment) &&
      sessionId
    ) {
      verifyPaymentStatus(orderRef);
    }

    // Telr Payment
    else if (
      integrationsByName[C.TELR] &&
      selectedPayment?.paymentType === C.CREDIT_CARD
    ) {
      if (queryParams.payment === "failure") {
        dispatch(
          ErrorAction.setError({
            error: t("Payment failed or cancelled"),
            modal: true,
          })
        );
      } else if (queryParams.payment === "success") {
        verifyPaymentStatus();
        sessionStorage.setItem("paymentVerified", true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderRef, queryParams.payment]);

  const increaseItemQuantity = (id, index) => {
    const payload = { id, index };
    dispatch(CartAction.increaseItemQuantity(payload));
    updateCart();
  };
  const decreaseItemQuantity = (id, index) => {
    const payload = { id, index };
    dispatch(CartAction.decreaseItemQuantity(payload));
    updateCart();
  };

  // const openModal = () => dispatch(NotPersistAction.toggleDiscountModal(true));
  const goBack = () => router("/", `/${params?.domainName}`, DomainType);
  // const handleCancelModal = () => setConfirmModal(false);

  const getAreaDeliverFee = () => {
    return getDeliveryAreaFeeForBrandBased(
      shippingMethod,
      selectedCountry,
      selectedArea,
      selectedCity
    );
  };

  useEffect(() => {
    if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
      dispatch(
        CartAction.setDeliveryFee(getAreaDeliverFee()?.deliveryFee || 0)
      );
    } else {
      dispatch(CartAction.setDeliveryFee(0));
    }
    updateCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingMethod, selectedCity, selectedArea, selectedCountry, orderType]);

  const calculateRedeemAmount = () => {
    const maxRedeemableValuePerOrder =
      loyaltyPolicy?.maxRedeemableValuePerOrder || 0;
    const redeemablePercentagePerOrder =
      loyaltyPolicy?.redeemablePercentagePerOrder || 0;
    const redeemableAmount = Number(
      (cartTotal / 100) * redeemablePercentagePerOrder
    );
    let minimum = min([
      maxRedeemableValuePerOrder,
      redeemableAmount,
      userWallet,
    ])?.toFixed(2);
    setRedeemAmount(Number(minimum));
    dispatch(LoyaltyAction.updateloyaltyAmount(Number(minimum)));
  };

  useEffect(() => {
    calculateRedeemAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartTotal, loyaltyPolicy, cart, userWallet]);

  useEffect(() => {
    if (
      orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
      pullAddress !== false
    )
      getAddresses();
  }, []);

  const isLoading = initiateRequestLoader || verifyCheckoutDotLoader;
  return (
    <WithLoading loading={isLoading}>
      {cart?.length <= 0 && !orderSuccess ? (
        <EmptyBasket backToHome={goBack} />
      ) : (
        <div className="checkout-page min-h-app-height store-page-popup">
          <PageHeader
            pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full border-b-2 relative"
            url={true}
            handleClick={goBack}
            title={t("Your Order")}
          />
          <BasketItemList {...{ increaseItemQuantity, decreaseItemQuantity }} />
          {orderType?.serviceType === C.SERVICE_TYPE.DELIVERY && (
            <CheckoutDeliveryArea
              {...{ userdeliveryAdress, setChangeAddressModal }}
            />
          )}

          <DeliveryTime setPreOrderingSlotWidget={setPreOrderingSlotWidget} />
          <PreOrderingSlotWidget
            isPreOrderingSlotWidget={isPreOrderingSlotWidgetOpen}
            {...{ setPreOrderingSlotWidget }}
            selectedStore={selectedStore}
          />

          <div className="px-4 pb-6 mt-6">
            <label className="text-16x font-bold mb-4">
              {t("Special instructions")}
            </label>
            <TextareaInput
              onChange={(e) =>
                dispatch(CartAction.setOrderSplreq(e.target.value))
              }
              value={orderSplReq}
              placeholder={t("Add a note or any instructions.")}
              styleClasses="mt-1 block w-full text-16x text-outer-space mt-2 p-1 bg-gray-100  border border-gray-300 rounded focus:outline-none"
            />
          </div>

          <PickupVehicleInfo className="pb-4" />

          <div className="pt-5">
            <DiscountButton
              buttonText={t("Get discount code")}
              handleDiscount={() => handleConfirmDiscountLoyalty(C.DISCOUNT)}
            />
          </div>

          {!discountModal && discountError && cart?.length >= 1 && (
            <ErrorComponent error={discountError} />
          )}
          {loyaltyPolicy?.isLoyaltyActive && (
            <LoyaltyCreditWidget {...{ loyaltyPolicy }} />
          )}
          <CartDetail
            {...{
              getAreaDeliverFee,
              loyaltyPolicy,
              amountAfterDiscount,
              cartTotal,
              selectedStore,
              userWallet,
              handleConfirmDiscountLoyalty,
              redeemAmount,
            }}
          />
          <CheckoutPaymentMethod
            paymentOptions={
              orderType?.serviceType === C.SERVICE_TYPE.DELIVERY
                ? selectedStore?.paymentOptions
                : selectedStore?.pickupPaymentOptions
            }
          >
            <SavedCardsList
              {...{
                integrationsByName,
                captureCheckoutDotComPayment,
              }}
            />
            {selectedPayment?.paymentType === C.CREDIT_CARD &&
            selectedPaymentType === C.WALLET.NEW_CARD ? (
              <CardWidgets
                {...{
                  integrationsByName,
                  captureCheckoutDotComPayment,
                  redirectLink: redirectUrl,
                }}
              />
            ) : null}
          </CheckoutPaymentMethod>
          <CheckoutModals
            changeAddressModal={changeAddressModal}
            setChangeAddressModal={setChangeAddressModal}
            confirmModal={confirmModal}
            setDeliveryAddress={setDeliveryAddress}
            setConfirmModal={setConfirmModal}
            handleConfirmModal={handleConfirmModal}
            handleCancelModal={handleCancelModal}
            confirmModalTitle={confirmModalTitle}
            addressList={addressList}
            deleteSlectedAddress={deleteSlectedAddress}
            userdeliveryAdress={userdeliveryAdress}
          />
          <FooterWithWallet
            storePageUrl={`/`}
            minOrder={getAreaDeliverFee()?.minOrder || 0}
            placeORder={brand_based_place_order}
            Place_Order={placeOrder}
            {...{
              cartTotal,
              integrationsByName,
              selectedStore,
              cardProcessing,
              validateOrderShipping,
            }}
          />
        </div>
      )}
    </WithLoading>
  );
}

export default Checkout;
