import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Frames } from "frames-react";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import { t } from "i18next";
//services
import {
  useGetHostedPageMutation,
  useVerifyPaymentMutation,
} from "store/checkout/order/orderService";
import {
  useInitiateCheckoutRequestMutation,
  useVerifyCheckoutDotComPaymentMutation,
} from "store/checkout/payments/service";
//custom hooks
import { useLang } from "hooks";
//actions
import { ErrorAction, OrderAction } from "store/actions";
//helper functions
import { errorHandler } from "apiHelpers/api/errorHandler";
import { isValidValue, logErrorOnSentry } from "utils/Utils";
//constants
import C from "Constants";
import { UTILS } from "utils";
import useBrandLosgisticIntegrations from "hooks/useBrandLosgisticIntegrations";
import StripeHelper from "./helpers/payment_helpers/stripe/StripeCardPay";
import usePlaceOrderHelper from "./usePlaceOrderHelper";
import { useSetDefaultPaymentType } from "hooks";
import useScrollToBottomPaymentWidget from "hooks/useScrollToBottomPaymentWidget";

function usePaymentHelper(redirectLink, handleSuceessOrderRoute) {
  //hooks

  const params = useParams();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { currentLang } = useLang();

  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );
  const scrollToBottom = useScrollToBottomPaymentWidget(selectedPaymentType);

  const { integrationsByName } = useBrandLosgisticIntegrations();
  useSetDefaultPaymentType();
  const { outlet_based_place_order, logError } = usePlaceOrderHelper(
    handleSuceessOrderRoute
  );
  //state
  const [cardProcessing, setcardProcessing] = useState(false);
  //selectors
  const { orderGrossAmount } = useSelector((state) => state?.cart);
  const { DomainType } = useSelector((state) => state?.AppReducer);
  const { transactionInfo, orderRef } = useSelector((state) => state?.order);
  const { selectedPayment, selectedStore } = useSelector(
    (state) => state?.store
  );
  const { selectedCard } = useSelector((state) => state.PaymentCardsReducer);
  //services

  const { process_stripe_payment_outlet_based } = StripeHelper(
    setcardProcessing,
    handleSuceessOrderRoute
  );
  const [getHostedPage] = useGetHostedPageMutation();
  const [verifyPayment] = useVerifyPaymentMutation();
  const [initiateCheckoutRequest, { isLoading: initiateRequestLoader }] =
    useInitiateCheckoutRequestMutation();
  const [verifyCheckoutDotComPayment, { isLoading: verifyCheckoutDotLoader }] =
    useVerifyCheckoutDotComPaymentMutation();
  const validateSavedCard = (cardType) => {
    if (
      selectedCard?.cardInfo?._id &&
      selectedCard?.cardInfo?.paymentGateway === cardType
    ) {
      return true;
    }
    return false;
  };
  const process_checkout_dotCom_payment = async () => {
    const isSavedCard = validateSavedCard("CHECKOUTDOTCOM");
    if (isSavedCard) {
      captureCheckoutDotComPayment(null, redirectLink);
    } else {
      const isCardValid = Frames.isCardValid();
      if (isCardValid) {
        setcardProcessing(true);
        await Frames.submitCard()
          .then(() => {
            setcardProcessing(false);
          })
          .catch((e) => {
            setcardProcessing(false);
            logError(errorHandler(e.error), true, "error");
          });
      } else {
        scrollToBottom();
        logError(t("pleaseEnterValidCardDetails"), true, "info");
      }
    }
  };

  const process_telr_payment = async () => {
    const hostedObj = {
      domainName: params.domainName,
      amountPaid: orderGrossAmount,
      storeId: selectedStore._id,
      DomainType,
      successRedirectUrl: redirectLink.success,
      failureRedirectUrl: redirectLink.failure,
    };
    const paymentObj = UTILS.getHostedPaymentPageObj(hostedObj);
    if (!paymentObj?.isValid) {
      dispatch(
        ErrorAction.setError({
          error: t(C.DEFAULT_ERROR_MSG),
          modal: true,
        })
      );
      return;
    }
    if (paymentObj?.isValid) {
      const { data } = await getHostedPage(paymentObj.value);
      if (data?.success === 0) {
        return;
      }
    }
  };

  const placeOrder = async () => {
    logError(null, false, "info");
    try {
      sessionStorage.removeItem("paymentVerified");
      const { isValid, errors } = UTILS.validateOutletOrderObject();
      if (!isValid) {
        showErrors(errors);
        return;
      } else {
        if (selectedPayment?.paymentType === C.CREDIT_CARD) {
          if (+parseFloat(orderGrossAmount) <= 2) {
            logError(t("addMoreThanTwoAED"), true, "info");
            return;
          } else {
            if (integrationsByName[C.STRIPE])
              process_stripe_payment_outlet_based({
                amount: orderGrossAmount,
                storeId: selectedStore._id,
              });
            else if (integrationsByName[C.CHECKOUT_DOT_COM]) {
              process_checkout_dotCom_payment();
            } else if (integrationsByName[C.TELR]) {
              process_telr_payment(redirectLink);
            }
          }
        } else {
          await outlet_based_place_order();
        }
      }
    } catch (err) {
      logError(errorHandler(err), true, "error");
    }
  };

  async function verifyPaymentStatus(paymentId) {
    try {
      if (integrationsByName[C.CHECKOUT_DOT_COM]) {
        const { data: paymentStatus } = await verifyCheckoutDotComPayment(
          paymentId
        );
        if (
          paymentStatus?.success === 1 &&
          paymentStatus?.gatewayData?.isVerified &&
          paymentStatus?.gatewayData
        ) {
          sessionStorage.setItem("paymentVerified", true);
          await outlet_based_place_order({
            paymentIntent: paymentStatus?.gatewayData,
          });
        }
        // Payment verfication Error
        else if (paymentStatus?.success === 0) {
          dispatch(
            ErrorAction.setError({
              error: errorHandler(paymentStatus.message),
              modal: true,
            })
          );
        }
      }
      // Telr Payment
      else if (integrationsByName[C.TELR]) {
        const info = {
          storeId: transactionInfo.storeId,
          cartId: transactionInfo.cartId,
          language: currentLang || C.EN,
          // orderObject,
        };
        const paymentStatusData = await verifyPayment(info).unwrap();
        if (paymentStatusData?.success === 1 && paymentStatusData?.data) {
          await outlet_based_place_order({
            paymentIntent: paymentStatusData?.data,
          });
        }
        // Telr Payment verfication Error
        else if (paymentStatusData?.success === 0) {
          dispatch(
            ErrorAction.setError({
              error: t("Payment failed or cancelled"),
              modal: true,
            })
          );
        }
      }
    } catch (error) {
      logError(errorHandler(error), true, "error");
    }
  }

  const captureCheckoutDotComPayment = async (token) => {
    if (!isValidValue(token) && !validateSavedCard(C.CHECKOUT_DOT_COM)) {
      logError(t("cardProccesingError"), true, "error");
      return;
    }

    try {
      const { errors, isValid, orderObject } =
        await UTILS.validateOutletOrderObject();
      if (!isValid) {
        showErrors(errors);
        return;
      }
      const { totalOrderPrice } = orderObject;
      const hostedObj = {
        domainName: params.domainName,
        amountPaid: totalOrderPrice,
        storeId: selectedStore._id,
        DomainType,
        successRedirectUrl: redirectLink.success,
        failureRedirectUrl: redirectLink.failure,
      };
      const paymentObj = UTILS.getHostedPaymentPageObj(hostedObj);
      if (!paymentObj?.isValid) {
        logError(t(C.DEFAULT_ERROR_MSG), true, "error");
        return;
      }

      const { data: response } = await initiateCheckoutRequest({
        isStoredCard: selectedCard?.isStoredCard,
        ...(selectedCard?.cardInfo?._id
          ? {
              storeCardId: selectedCard?.cardInfo?._id,
            }
          : {
              token: token,
            }),
        currency: C.AED,
        amount: paymentObj.value.amountPaid,
        storeId: paymentObj.value.storeId,
        language: paymentObj.value.language,
        successUrl: paymentObj.value.successRedirectUrl,
        failureUrl: paymentObj.value.failureRedirectUrl,
      });
      if (
        response?.success === 1 &&
        response?.gatewayData?.axiosResData?.framesResponse?.id
      ) {
        let paymentId = response.gatewayData.axiosResData.framesResponse.id;
        await dispatch(OrderAction.setOrderRef(paymentId));
        if (response?.gatewayData?.three_d_secure_redirection_required) {
          window.location.replace(
            response?.gatewayData?.axiosResData?.framesResponse?.redirectLink
          );
        } else if (
          response?.gatewayData?.three_d_secure_redirection_required === false
        ) {
          verifyPaymentStatus(paymentId);
        } else {
          logErrorOnSentry(
            `3Dsecure_redirection_required is undefined+ ${response.message}`,
            "error"
          );
          logError(errorHandler(response.message), true, "error");
          return;
        }
      } else {
        logError(errorHandler(response.error), true, "error");
        return;
      }
    } catch (e) {
      logError(errorHandler(e), true, "error");
    }
  };

  const queryParams = queryString.parse(search);
  useEffect(() => {
    const paymentVerified = sessionStorage.getItem("paymentVerified");
    const sessionId = queryParams["cko-session-id"];
    if (paymentVerified) {
      return;
    }

    // Checkout.com Payment
    if (
      integrationsByName[C.CHECKOUT_DOT_COM] &&
      orderRef &&
      ["success", "failure"].includes(queryParams.payment) &&
      sessionId
    ) {
      verifyPaymentStatus(orderRef);
    }

    // Telr Payment
    else if (
      integrationsByName[C.TELR] &&
      selectedPayment?.paymentType === C.CREDIT_CARD
    ) {
      if (queryParams.payment === "failure") {
        dispatch(
          ErrorAction.setError({
            error: t("Payment failed or cancelled"),
            modal: true,
          })
        );
      } else if (queryParams.payment === "success") {
        verifyPaymentStatus();
        sessionStorage.setItem("paymentVerified", true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderRef, queryParams.payment]);

  const showErrors = (errors) => {
    const error = Object.values(errors).find((value) => value !== null);
    if (error) {
      logError(errorHandler(error), true, "info");
    }
  };

  const isLoading = initiateRequestLoader || verifyCheckoutDotLoader;
  return {
    showErrors,
    captureCheckoutDotComPayment,
    placeOrder,
    verifyPaymentStatus,
    isLoading,
    cardProcessing,
    process_stripe_payment_outlet_based,
  };
}

export default usePaymentHelper;
