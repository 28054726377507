import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

function useDetectBrowserWallets() {
  const stripe = useStripe();
  const elements = useElements();
  const [browserWallets, setBrowserWallets] = useState(null);

  useEffect(() => {
    let isMounted = true; // Add a flag to track component mounting

    if (!stripe || !elements) return;

    const pr = stripe.paymentRequest({
      country: "AE",
      currency: "aed",
      total: {
        label: "Total",
        amount: 1,
      },
      requestPayerName: true,
      //   disableWallets: ["link", "browserCard"],
    });

    pr.canMakePayment().then((result) => {
      if (isMounted) {
        // Check if the component is still mounted before updating the state
        setBrowserWallets(result);
      }
    });

    // Add a cleanup function to cancel any asynchronous tasks when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [stripe, elements]);

  return browserWallets;
}

export default useDetectBrowserWallets;
