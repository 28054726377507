import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "apiHelpers/api/ApiKit";
import { UTILS } from "utils";
const axiosBaseQuery =
  () =>
  async ({ url, method, body, params = {} }) => {
    let language = (await UTILS.getLanguage()) ?? "EN";
    let data = { ...body, language };
    try {
      const result = await axios({
        url: url,
        method,
        data,
        params,
      });
      return {
        data: result.data,
      };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
          error: axiosError?.error,
        },
      };
    }
  };

export const apiSlice = createApi({
  reducerPath: "api", // optional
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({}),
});

export const postConfig = {
  query: (body) => ({
    method: "POST",
    body: body,
  }),
  transformResponse: (response) => {
    return response;
  },
};

export const getConfig = {
  query: () => ({
    method: "GET",
  }),
  transformResponse: (response) => {
    return response;
  },
};
