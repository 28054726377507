import React, { useState } from "react";
import LoyaltyPolicyPopup from "./LoyaltyPolicyPopup";
import { DiamondIcon } from "icons";
import { t } from "i18next";

const LoyaltyPolicyWidget = React.memo(({ loyaltyPolicy, goToLogin }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <LoyaltyPolicyPopup {...{ loyaltyPolicy, open, setOpen, goToLogin }} />
        <div className="mt-4 px-4 ">
          <span
            className="flex border rounded px-2  py-2 shadowk-lg items-center text-sm leading-normal hover:no-underline hover:text-black-900 cursor-pointer"
            onClick={() => {
              setOpen(true);
            }}
          >
            <div className="bg-location p-3 rounded-full flex items-center justify-center">
              <DiamondIcon />
            </div>
            <div className="px-3">
              <p className="font-semibold text-17x mb-1">
                {t("Loyalty program")}
              </p>
              <p className="block text-xs leading-normal">
                {t("Earn cashback to spend in your next orders", {
                  cashback:
                    loyaltyPolicy?.specificToDelivery?.cashbackPercentage,
                })}
              </p>
            </div>
          </span>
        </div>
      </div>
    </>
  );
});

export default LoyaltyPolicyWidget;
