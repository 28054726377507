import { useDispatch, useSelector } from "react-redux";

import { useMapContext } from "contexts/googleMap";
import { useDeliveryAddressContext } from "contexts/userAddressess";
import useConfirmGeoLocation from "service-hooks/useConfirmGeoLocation";
import useGetStoresByLatLng from "service-hooks/useGetStoresByLatLng";
import { useEventTracker } from "hooks";
import { Loader, DeliveryAddressAction } from "store/actions";

function useFetchBrandStoresByLatLng() {
  const dispatch = useDispatch();
  const { googlemapState } = useMapContext();
  const { orderType } = useSelector((state) => state.AppReducer);

  const { confirmGeoLocation } = useConfirmGeoLocation();
  const getStoresByLatLong = useGetStoresByLatLng();

  const { setAddressState } = useDeliveryAddressContext();

  const logEvent = useEventTracker({
    name: "AddGeoLocationOnHomePage",
    orderType,
    shouldLog: false,
  });

  const updateTemporaryLocation = () => {
    dispatch(
      DeliveryAddressAction.setTempMapAddress(googlemapState?.mapAddress)
    );
    dispatch(DeliveryAddressAction.setTempZoom(googlemapState?.zoom || 16));
    dispatch(
      DeliveryAddressAction.setTempUserLocation(
        googlemapState?.userLocation?.coordinates
      )
    );
    dispatch(DeliveryAddressAction.setisGeoLocationAdded(true));
  };

  async function fetchBrandStoresByLatLng() {
    dispatch(Loader.setLoading(true));
    logEvent("CONFIRM_LOCATION_APP_START");
    const res = await confirmGeoLocation();
    if (res?.isValid) {
      await getStoresByLatLong(
        googlemapState?.userLocation?.coordinates,
        updateTemporaryLocation
      );
    } else {
      setAddressState("zoomErrorModal", {
        open: true,
        message: res.message,
      });
    }
    dispatch(Loader.setLoading(false));
  }
  return fetchBrandStoresByLatLng;
}

export default useFetchBrandStoresByLatLng;
