import React, { useCallback, useRef, useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import { LocationIcon, InputClose } from "icons";
import {
  GoogleMap,
  Circle,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import Overlay from "../Overlay";
import ErrorModal from "../ErrorModel";
import { logErrorOnSentry } from "utils/Utils";
import { getCityAndCountry, validateMapAddress } from "utils/googleGeoCode";
import { libraries, circleOptions } from "config/googleMapLibs";
import { useMapContext } from "contexts/googleMap";
import CustomModal from "components/CustomModal";
function WithMap({
  width,
  height,
  showInput,
  mapref,
  setMapRef,
  zoomChanging,
  googlemapState,
  class: mapClasss,
  zoomerror,
  isZoomModalOpen,
  setZoomErrorModal,
}) {
  const {
    setMapAddress,
    setUserLocation,
    setZoom,
    setStreetAddress,
    googlemapState: mapContext,
  } = useMapContext();
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const [open, setOpen] = useState({
    open: false,
    Error: "",
  });

  const getCenter = () => {
    if (googlemapState?.userLocation?.lat) {
      const mapCenter = {
        lat: googlemapState?.userLocation?.lat,
        lng: googlemapState?.userLocation?.lng,
      };
      return mapCenter;
    }
    return {
      lat: 23,
      lng: 54,
    };
  };

  const [center, setCenter] = useState(getCenter());

  useEffect(() => {
    setCenter(getCenter());
  }, [mapContext.addressUpdated]);

  const handleChange = (address) => {
    let m = { ...googlemapState?.mapAddress };
    m.streetAddress = address;
    setStreetAddress(address);
  };

  const formatCompleteMapAddess = (result, latLng, streetAddress) => {
    const updatedResult = validateMapAddress(result, latLng, streetAddress);
    setMapAddress(updatedResult);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        mapref.setCenter(new window.google.maps.LatLng(latLng.lat, latLng.lng));
        getCityAndCountry(latLng.lat, latLng.lng, function (result) {
          formatCompleteMapAddess(result, latLng, address);
        });

        setUserLocation(latLng);
        setZoom(16);
        inputRef.current.blur();
      })
      .catch((error) => {
        logErrorOnSentry("Eror in Google Places ", "error");
      });
  };

  const handleMapIdle = () => {
    if (mapref) {
      const lat = mapref?.center?.lat();
      const lng = mapref?.center?.lng();
      const mapCenter = {
        lat: lat,
        lng: lng,
      };

      if (mapCenter.lat !== center.lat || mapCenter.lng !== center.lng) {
        setCenter(mapCenter);
        setUserLocation(mapCenter);
      }
    }
  };

  const zoomChanged = () => {
    if (mapref) {
      const newLat = mapref?.getCenter()?.lat();
      const newLng = mapref?.getCenter()?.lng();
      const zoom = mapref.zoom;
      setCenter({ lat: newLat, lng: newLng });
      setZoom(zoom);
      setUserLocation({ lat: newLat, lng: newLng });
    }
  };

  function locateMe() {
    setLoading(true);
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, errorFn, {
        timeout: 10000,
        enableHighAccuracy: true,
        maximumAge: 0,
      });
    } else {
      handleMapError(t("This browser does not support live location"));
      logErrorOnSentry("This browser does not support live location", "info");
    }
  }

  function showPosition(position) {
    setLoading(false);
    const { latitude: lat, longitude: lng } = position?.coords || {};
    if (!lat || !lng) return;
    mapref.setCenter(new window.google.maps.LatLng(lat, lng));
    const mapCenter = {
      lat,
      lng,
    };
    setCenter(mapCenter);
    setZoom(16);
    setUserLocation(mapCenter);
    updateStreetAddress(mapCenter);
  }

  const handleMapError = (errorMessage) => {
    setOpen({
      open: true,
      Error: errorMessage,
    });
    logErrorOnSentry(errorMessage, "info");
  };
  function errorFn(error) {
    setLoading(false);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        handleMapError(
          t(
            "Location permissions are currently disabled on your device. Please enable them in order for us to automatically determine your current delivery location"
          )
        );
        break;
      case error.POSITION_UNAVAILABLE:
        handleMapError(
          t(
            "Location permissions are currently disabled on your device. Please enable them in order for us to automatically determine your current delivery location"
          )
        );
        break;
      case error.TIMEOUT:
        handleMapError(
          t(
            "Your current location detection is taking time. Please try again or enter your location manually if you prefer."
          )
        );
        break;
      case error.UNKNOWN_ERROR:
        handleMapError(
          t(
            "Your current location detection is taking time. Please try again or enter your location manually if you prefer."
          )
        );
        break;
      default:
        handleMapError(
          t(
            "Your current location detection is taking time. Please try again or enter your location manually if you prefer."
          )
        );
    }
  }

  const onDragEnd = () => {
    const lat = mapref?.center?.lat();
    const lng = mapref?.center?.lng();
    setUserLocation({ lat, lng });
  };

  const updateStreetAddress = (mapCenter) => {
    // if (mapCenter.lat !== 23 && mapCenter?.lng !== 54)
    if (zoomChanging) {
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: mapCenter }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            getCityAndCountry(mapCenter.lat, mapCenter.lng, function (result) {
              formatCompleteMapAddess(
                result,
                mapCenter,
                results[0].formatted_address
              );
            });

            setStreetAddress(results[0]?.formatted_address);
          }
        }
      });
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: "script-loader",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: "en",
    region: "us",
    libraries: libraries,
  });

  const [loading, setLoading] = useState(false);
  const onUnmount = useCallback(() => {
    setMapRef(null);
  }, []);

  const handleOnLoad = (map) => {
    setMapRef(map);
  };

  const handleError = () => {};
  const handleIncreaseZoom = () => {
    setZoom(16);
    setZoomErrorModal(false);
    // update the street address
    updateStreetAddress(center);
  };
  const [placeholder, setPlaceholder] = useState(
    t("Enter your delivery location")
  );
  useEffect(() => {
    const blinker = () => {
      setPlaceholder((prev) => (prev ? "" : t("Enter your delivery location")));
    };

    const intervalId = setInterval(blinker, 1000);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  const handleClear = () => {
    setCenter({ lat: 23, lng: 54 });
    setZoom(7);
    setStreetAddress("");
    setUserLocation({ lat: 23, lng: 54 });
  };

  const getStreetAddressLength = () => {
    if (!googlemapState?.mapAddress?.streetAddress) return false;
    if (googlemapState?.mapAddress?.streetAddress?.length > 0) return true;
  };

  return (
    <React.Fragment>
      <div
        className={`
      ${showInput ? mapClasss : "relative "}`}
      >
        {isLoaded ? (
          <>
            <div
              dir="ltr"
              className="felx jsutify-center px-4 mt-4 z-10 w-full absolute en-font "
            >
              <PlacesAutocomplete
                value={
                  googlemapState?.mapAddress?.streetAddress == null
                    ? ""
                    : googlemapState?.mapAddress?.streetAddress
                }
                onChange={handleChange}
                onSelect={handleSelect}
                onError={handleError}
                searchOptions={{
                  componentRestrictions: { country: ["ae"] },
                }}
                //don't show ... in the input
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <>
                    {showInput ? (
                      <div className="w-full rounded border bg-white px-2 py-3 flex border-gray-200 google-input-txt">
                        <input
                          ref={inputRef}
                          type="text"
                          className="focus:outline-none w-inline-block w-9/12 iinput-map text-16x"
                          {...getInputProps({
                            placeholder: placeholder,
                          })}
                        />
                        <div
                          className={`setmap w-3/12 flex ${
                            getStreetAddressLength()
                              ? "justify-around"
                              : "justify-end"
                          }  items-center inline-block cursor-pointer`}
                        >
                          {!getStreetAddressLength() ? (
                            ""
                          ) : (
                            <span className="w-6 h-6 " onClick={handleClear}>
                              <InputClose />
                            </span>
                          )}

                          <span className="w-4 h-4 mx-2" onClick={locateMe}>
                            <LocationIcon />
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className="autocomplete-dropdown-container"
                      style={{
                        position: "absolute",
                        zIndex: "1000",
                        width: "76.99%",
                      }}
                    >
                      {loading && <div>{t("Loading")}</div>}
                      {suggestions.map((suggestion, key) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            key={key}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </PlacesAutocomplete>
            </div>

            <Overlay loading={loading}>
              <GoogleMap
                onDragEnd={onDragEnd}
                onIdle={handleMapIdle}
                id="myMap"
                mapContainerClassName="App-map"
                zoom={googlemapState?.zoom}
                version="weekly"
                mapContainerStyle={{
                  width: width,
                  height: height,
                }}
                options={{
                  gestureHandling: showInput ? "greedy" : "none",
                  disableDefaultUI: showInput ? false : true,
                  keyboardShortcuts: false,
                  zoomControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_TOP,
                  },
                  mapTypeControl: false,
                  fullscreenControl: false,
                  streetViewControl: false,
                }}
                center={center}
                onLoad={handleOnLoad}
                onUnmount={onUnmount}
                onZoomChanged={zoomChanged}
              >
                <Marker position={center} />
                <Circle
                  onUnmount={onUnmount}
                  center={center}
                  options={circleOptions}
                />

                {showInput ? (
                  <div className="google_locateme_btn">
                    <button onClick={locateMe}>
                      <div className="px-1 flex justify-center items-center gap-1">
                        <LocationIcon />
                        <span className="mx-2 text-14x font-bold">
                          {t("Locate Me")}
                        </span>
                      </div>
                    </button>
                  </div>
                ) : null}
              </GoogleMap>
            </Overlay>
          </>
        ) : (
          <>{t("Loading")}</>
        )}
      </div>

      <CustomModal
        open={open?.open}
        setOpen={setOpen}
        bodyclasses="flex"
        overlayClasses="  fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ErrorModal setOpen={setOpen} message={open?.Error} />
      </CustomModal>
      <CustomModal
        open={isZoomModalOpen}
        setOpen={setZoomErrorModal}
        bodyclasses="flex"
        overlayClasses="  fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ErrorModal
          message={zoomerror}
          btnTitle={t("Increase zoom")}
          handleClik={handleIncreaseZoom}
        />
      </CustomModal>
    </React.Fragment>
  );
}

export default WithMap;
