import React from "react";
import { t } from "i18next";
import { useLang } from "../../hooks";
const ProductsDetail = ({ order }) => {
  const { currentLang } = useLang();
  return (
    <>
      <div className="bg-white p-4 pt-5">
        <h1 className="text-22x font-semibold">
          {t("Order")} #{order?.orderNumber}
        </h1>
        <ul className="basket-contents__item-list">
          {order?.orderedMenu?.map((item, key) => (
            <li
              key={key}
              className="basket-item flex justify-between items-start flex-row mt-5 flex-100"
            >
              <span className="mx-1">
                <div className="inline-flex center relative flex-4">
                  <span className="inline-flex w-10">
                    <span className="">
                      <span className="">{item?.quantity} x </span>
                    </span>
                  </span>
                </div>
              </span>
              <span className="item flex flex-auto justify-start flex-wrap px-1">
                <div className="flex-100">
                  <span className="text-outer-space block">
                    {item?.baseLanguage?.title}
                  </span>
                  {item?.sections?.map((section, index) => {
                    return (
                      <div className=" first-word-capital" key={index}>
                        <span className="bold-font-size text-15x text-gray-500 me-1">
                          {section?.baseLanguage?.sectionName}
                        </span>
                        {section?.options?.map((option, index) => {
                          return (
                            <div key={index}>
                              <span className="text-13x ml-2" key={index}>
                                <span className="me-1">
                                  {option?.quantity ? option?.quantity : 1} x
                                </span>
                                {option?.baseLanguage?.optionName}
                                <span className="ml-1">
                                  <span className="me-1">
                                    {option?.price?.toFixed(2)}
                                  </span>
                                  <span className="me-1">{t("AED")}</span>
                                </span>
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </span>
              <span className="">
                {currentLang === "AR"
                  ? item?.price?.toFixed(2) + " " + t("AED")
                  : t("AED") + " " + item?.price?.toFixed(2)}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <hr className="border-b-1 border-gray-200" />
    </>
  );
};

export default ProductsDetail;
