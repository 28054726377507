import { useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { DeliveryAddressAction } from "store/actions";

function useFormAddDeliveryAddress({ gotoCheckoutPage, onSubmit }) {
  const dispatch = useDispatch();

  const errorMessages = {
    address: t("Please enter full address"),
    minLenght: t("Minimum length for the full address is five characters."),
    addressType: t("Please select address type"),
  };

  const [formState, setFormState] = useState({
    values: {
      address: "",
      appartmentNo: "",
      floor: "",
      building: "",
      addressType: "",
    },
    errors: {
      address: null,
      appartmentNo: null,
      floor: null,
      building: null,
      addressType: null,
    },
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const errors = validateField(name, value);
    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: errors[name] ? errors[name] : null,
      },
    }));
  };

  const validateField = (name, value) => {
    const errors = {};
    if (!value || value?.trim() === "") {
      errors[name] = errorMessages[name];
    }

    if (name === "address" && value?.trim()?.length < 5) {
      errors[name] = errorMessages.minLenght;
    }
    return Object.keys(errors).length === 0 ? {} : errors;
  };

  const handleFormSubmit = async (e, geoLocationObj) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormState((prevState) => ({
        ...prevState,
        errors: errors,
      }));

      // Scroll to the first error
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorElement = document.querySelector(
        `[name="${firstErrorKey}"]`
      );
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ block: "center" });
      }
    } else {
      let d = {
        address: formState.values?.address,
        street: geoLocationObj?.temporarymapAddress?.streetAddress || "unknown",
        area: geoLocationObj?.temporarymapAddress?.area || "unknown",
        city: geoLocationObj?.temporarymapAddress?.city || "unknown",
        country: geoLocationObj?.temporarymapAddress?.country || "unknown",
        building: formState.values?.building,
        apartmentNo: formState.values?.appartmentNo,
        floor: formState.values?.floor,
        addressType: formState.values?.addressType,
        coordinates: {
          lat: geoLocationObj?.temporaryuserLocation?.coordinates?.lat,
          lng: geoLocationObj?.temporaryuserLocation?.coordinates?.lng,
        },
      };
      dispatch(DeliveryAddressAction.setZoom(geoLocationObj.temporaryzoom));
      dispatch(
        DeliveryAddressAction.setUserLocation(
          geoLocationObj.temporaryuserLocation?.coordinates
        )
      );
      dispatch(
        DeliveryAddressAction.setMapAddress(geoLocationObj.temporarymapAddress)
      );

      const res = await onSubmit(d);
      if (res) gotoCheckoutPage();
    }
  };

  const validateForm = () => {
    const errors = {};
    const { values } = formState;
    if (!values?.address || values?.address?.trim() === "") {
      errors.address = errorMessages["address"];
    } else if (values?.address?.trim()?.length < 5) {
      errors.address = errorMessages["minLenght"];
    }
    if (!values?.addressType || values?.addressType?.trim() === "") {
      errors.addressType = errorMessages["addressType"];
    }
    return errors;
  };
  return { formState, handleFormChange, handleFormSubmit };
}

export default useFormAddDeliveryAddress;
