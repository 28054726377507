import React, { useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { logError } from "utils/Utils";
import { logErrorOnSentry } from "utils/Utils";
import {
  stripeCreatePaymentLinkIntent,
  stripeVerifyPaymentLinkIntent,
} from "./utils";
import { PaymentLinkAction } from "store/actions";
import C from "Constants";
const WalletPay = ({ children, paymentLinkInfo, fetchPaymentLinkInfo }) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentLinkReducer
  );
  const [paymentRequest, setPaymentRequest] = useState(null);
  let fallBackMessage = t("Bank declined payment, please try again");

  const requestObj = {
    country: "AE",
    currency: "aed",
    total: {
      label: "Total",
      amount: Math.round(paymentLinkInfo?.amount * 100),
    },
    // disableWallets: ["link", "browserCard"],
  };

  const validatePaymentRequest = (e) => {
    if (!paymentLinkInfo?.amount && paymentLinkInfo?.amount < 0) {
      dispatch(PaymentLinkAction.toggleLoader(false));
      e.preventDefault();
      logError(t("Invalid Amount"), true);
      return;
    }
  };

  useEffect(() => {
    let isMounted = true;
    try {
      async function createPaymentRequest() {
        if (!stripe || !elements) return;
        const PaymentRequestRes = stripe.paymentRequest(requestObj);
        const result = await PaymentRequestRes.canMakePayment();
        if (isMounted && result) setPaymentRequest(PaymentRequestRes);

        PaymentRequestRes.on("cancel", () => {
          logErrorOnSentry(
            "google/apple pay customer cancelled the payment",
            "info"
          );
          dispatch(PaymentLinkAction.toggleLoader(false));
          window.location.reload(false);
        });

        PaymentRequestRes.on("paymentmethod", async (event) => {
          dispatch(PaymentLinkAction.toggleLoader(true));
          const createStripePaymentIntent = await stripeCreatePaymentLinkIntent(
            brandInfo?.brand?._id,
            paymentLinkInfo?.cartId
          );

          if (
            createStripePaymentIntent?.success === 1 &&
            createStripePaymentIntent?.gatewayData?.fetchedLink
              ?.transactionDetail?.client_secret &&
            createStripePaymentIntent?.gatewayData?.isVerified === false
          ) {
            event.complete("success");
            dispatch(PaymentLinkAction.toggleLoader(true));
            const confirmpayload = await stripe.confirmCardPayment(
              createStripePaymentIntent?.gatewayData?.fetchedLink
                ?.transactionDetail?.client_secret,
              {
                payment_method: event.paymentMethod.id,
              },
              { handleActions: false }
            );
            if (
              confirmpayload?.paymentIntent?.status === "succeeded" &&
              confirmpayload?.paymentIntent?.id
            ) {
              dispatch(PaymentLinkAction.toggleLoader(true));
              const verifyStripePaymentIntent =
                await stripeVerifyPaymentLinkIntent({
                  brandId: brandInfo?.brand?._id,
                  cartId: paymentLinkInfo?.cartId,
                  paymentIntentId: confirmpayload?.paymentIntent?.id,
                });
              if (
                verifyStripePaymentIntent?.success === 1 &&
                verifyStripePaymentIntent?.gatewayData?.isVerified === true
              ) {
                await fetchPaymentLinkInfo();
                dispatch(PaymentLinkAction.toggleLoader(false));
              } else {
                dispatch(PaymentLinkAction.toggleLoader(false));
                logError(
                  verifyStripePaymentIntent?.error?.message || fallBackMessage,
                  true
                );
                event.complete("fail");
                return;
              }
            }
            //confirm payment failed
            else {
              dispatch(PaymentLinkAction.toggleLoader(false));
              logError(confirmpayload?.error?.message || fallBackMessage, true);
            }
          } else {
            dispatch(PaymentLinkAction.toggleLoader(false));
            logError(
              createStripePaymentIntent?.error?.message || fallBackMessage,
              true
            );
            event.complete("fail");
            return;
          }
        });
      }
      createPaymentRequest();
    } catch (error) {
      dispatch(PaymentLinkAction.toggleLoader(false));
      logError(error?.message || fallBackMessage, true);
    } finally {
      dispatch(PaymentLinkAction.toggleLoader(false));
    }

    return () => {
      isMounted = false;
      setPaymentRequest(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements]);

  const shouldShowPaymentButton =
    paymentRequest &&
    (selectedPaymentType === C.WALLET.APPLE_PAY ||
      selectedPaymentType === C.WALLET.GOOGLE_PAY);
  return shouldShowPaymentButton ? (
    <div className="px-4">
      <PaymentRequestButtonElement
        options={{ paymentRequest }}
        onClick={(e) => {
          validatePaymentRequest(e);
          dispatch(PaymentLinkAction.toggleLoader(true));
        }}
      />
    </div>
  ) : (
    <>{children}</>
  );
};

export default WalletPay;
