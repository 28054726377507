import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SVGICONS } from "config";
import { NotPersistAction } from "store/actions";
import useGetStoresByAreaId from "service-hooks/useGetStoresByAreaId";
import { DeliverAreaAction } from "store/actions";

const AreaList = ({ area, lang }) => {
  const dispatch = useDispatch();
  const getStoresByArea = useGetStoresByAreaId();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  const getStoresByAreaId = async (area) => {
    area && dispatch(DeliverAreaAction.setSelectedArea(area));
    if (brandInfo && area?.areaInfo?._id) {
      await getStoresByArea(area?.areaInfo?._id, "area-list");
      dispatch(NotPersistAction.toggleAreaModal(false));
    }
  };

  return area?.cityAreas?.map((area, key) => (
    <li onClick={() => getStoresByAreaId(area)} key={key}>
      <span className="mx-4 py-3   text-16x flex items-center border-b border-gray-200">
        <SVGICONS.LocationIcon />
        <span className="mx-2 text-outer-space">
          {lang === "EN"
            ? area?.areaInfo?.areaNameEN
            : area?.areaInfo?.areaNameAR}
        </span>
      </span>
    </li>
  ));
};

export default AreaList;
