import C from "Constants";
function useWindowLocation() {
  const redirectPage = (customDomainPath, temporaryDomainPath, DomainType) => {
    if (DomainType === C.CUSTOM_DOMAIN) {
      window.location.href = customDomainPath;
    } else {
      window.location.href = temporaryDomainPath;
    }
  };

  return redirectPage;
}

export default useWindowLocation;
