import useGetItemById from "components/item/hooks/useGetItemById";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import C from "Constants";
import { ItemAction } from "store/actions";

function useUpdateUtmMedium() {
  const params = useParams();
  const dispatch = useDispatch();
  const { getItemById } = useGetItemById();

  const { orderType } = useSelector((state) => state.AppReducer);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { selectedStore, storeMenu } = useSelector((state) => state.store);
  const { brandMenu } = useSelector((state) => state?.brand);

  useEffect(() => {
    if (params?.itemId && brandInfo?.brand?._id) {
      getItemById(selectedStore, storeMenu, params, brandInfo);
    }
    updateUtmMedium();
  }, [
    params?.itemId,
    selectedStore?._id,
    brandInfo?.brand?._id,
    orderType?.serviceType,
    params?.catId,
    storeMenu?._id,
    brandMenu?._id,
  ]);

  // update Utm_Medium
  const updateUtmMedium = () => {
    switch (orderType?.serviceType) {
      case C.SERVICE_TYPE.DELIVERY:
        updateDeliveryUtmMedium();
        break;
      case C.SERVICE_TYPE.PICKUP:
        updatePickupUtmMedium();
        break;
      default:
        break;
    }
  };
  const updateDeliveryUtmMedium = () => {
    const app_loaded_through =
      !selectedStore && params?.itemId
        ? C.APP_LOADED_THROUGH.DirectLink
        : C.APP_LOADED_THROUGH.normalFlow;
    dispatch(ItemAction.updateAppLoadedThrough(app_loaded_through));
  };

  const updatePickupUtmMedium = () => {
    const app_loaded_through =
      !selectedStore && params?.itemId
        ? C.APP_LOADED_THROUGH.DirectLink
        : C.APP_LOADED_THROUGH.normalFlow;
    dispatch(ItemAction.updateAppLoadedThrough(app_loaded_through));
  };
}

export default useUpdateUtmMedium;
