import { useDispatch, useSelector } from "react-redux";
import { ItemAction } from "store/actions";
import C from "Constants";

function useGetItemById() {
  const dispatch = useDispatch();
  const { orderType } = useSelector((state) => state.AppReducer);

  const getItemById = async (selectedStore, storeMenu, params, brandInfo) => {
    if (!selectedStore?._id || !storeMenu?._id) {
      dispatch(
        ItemAction.getItemInfobyIdStart({
          type: C.WITH_OUT_STORE_ID,
          itemId: params.itemId,
          brandId: brandInfo.brand._id,
          ...(params?.catId && { categoryId: params.catId }),
        })
      );
    } else {
      dispatch(
        ItemAction.getItemInfobyIdStart({
          type: C.WITH_STORE_ID,
          itemId: params.itemId,
          brandId: brandInfo.brand._id,
          storeId: selectedStore._id,
          menuId: storeMenu?._id,
          serviceType: orderType?.serviceType,
          categoryId: params?.catId,
        })
      );
    }
  };

  return {
    getItemById,
  };
}

export default useGetItemById;
