import React, { useRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PopupHeader from "components/PopupHeader";
import Search from "components/Search";
import { useLang } from "hooks";
import CityList from "./CityList";
import { t } from "i18next";
import ErrorComponent from "../ErrorComponent";
import useTouchMoveBlur from "hooks/useTouchMoveBlur";
import MapAreaCityList from "../map-areas/MapCityList";
import C from "Constants";
const getHeader = (header, headerLabel) => {
  if (header) {
    return <PopupHeader heading={headerLabel} />;
  }
};

function SelectDeliveryArea({ styleClasses, header, headerLabel, page }) {
  const { deliveryAreas, areasError } = useSelector((state) => state?.brand);
  const { modalLoader } = useSelector((state) => state.loader);
  const [searchTerm, setSearch] = useState("");
  const [opened, setopened] = useState(false);
  const { currentLang } = useLang();
  const inputRef = useRef(null);
  useTouchMoveBlur({ inputRef });

  const handleSearch = (e) => {
    let term = e.target.value?.toLowerCase();
    setSearch(term);
  };
  const findArea = () => {
    return (area) =>
      currentLang === "AR" || currentLang === "ar"
        ? area?.areaInfo?.areaNameAR
            ?.toLowerCase()
            ?.includes(searchTerm?.trim())
        : area?.areaInfo?.areaNameEN
            ?.toLowerCase()
            ?.includes(searchTerm?.trim());
  };

  const findMapArea = () => {
    return (area) =>
      currentLang === "AR" || currentLang === "ar"
        ? area?.translations?.ar?.name
            ?.toLowerCase()
            ?.includes(searchTerm?.trim())
        : area?.translations?.en?.name
            ?.toLowerCase()
            ?.includes(searchTerm?.trim());
  };

  const Areas = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      setopened(false);
      return deliveryAreas?.deliveryAreas;
    }
    return deliveryAreas?.deliveryAreas?.reduce(
      (r, { cityInfo, cityAreas }) => {
        let item = cityAreas?.filter(findArea());
        if (item && item.length) {
          r.push({ cityInfo, cityAreas: [...item] });
          setopened(true);
        }

        return r;
      },
      []
    );
  }, [searchTerm, deliveryAreas]);

  const MappedAreas = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      setopened(false);
      return deliveryAreas?.deliveryAreas;
    }
    return deliveryAreas?.deliveryAreas?.reduce((r, { city, areas }) => {
      let item = areas?.filter(findMapArea());
      if (item && item.length) {
        r.push({ city, areas: [...item] });
        setopened(true);
      }

      return r;
    }, []);
  }, [searchTerm, deliveryAreas]);

  return (
    <div className="max-460">
      <div className={styleClasses}>
        {getHeader(header, headerLabel)}
        <div className="px-4">
          <Search
            label={t("Search for an area")}
            inputRef={inputRef}
            onChange={handleSearch}
            value={searchTerm}
          />
          {modalLoader && <div className="loader h-1">{t("Loading")}</div>}

          <div>{areasError && <ErrorComponent error={areasError} />}</div>
        </div>

        <div className="scr-area-set">
          {deliveryAreas?.deliveryMethod ===
          C.SHIPPING_METHODS?.MAPPED_AREA_BASED
            ? MappedAreas?.map((city, index) => {
                return (
                  <MapAreaCityList
                    city={city}
                    key={index}
                    opened={opened}
                    lang={currentLang}
                  />
                );
              })
            : Areas?.map((city, index) => {
                return (
                  <CityList
                    city={city}
                    key={index}
                    opened={opened}
                    lang={currentLang}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
}

export default SelectDeliveryArea;
