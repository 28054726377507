import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import API from "apiHelpers";
import { errorHandler } from "apiHelpers/api/errorHandler";
import * as Actions from "store/actions";

import { UTILS, RESET_ACTIONS } from "utils";
import useGetStoreInfo from "./useGetStoreInfo";
import { useCustomHistory } from "hooks";

function useGetStoresByAreaId() {
  const MAP_AREA_LIST = "map-area-list";

  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { DomainType } = useSelector((state) => state.AppReducer);

  const getStoreInfoWithMenu = useGetStoreInfo();
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const { shippingMethodTemp } = useSelector((state) => state.AppReducer);

  async function getStoresByArea(areaId, type) {
    dispatch(Actions.AppAction.setShippingMethod(shippingMethodTemp));
    dispatch(Actions.Loader.enableLoader());
    dispatch(Actions.Loader.setModalLoader(true));
    dispatch(Actions.ErrorAction.setStoreError(null));

    dispatch(Actions.StoreAction.setSelectedArea(null));
    const data = {
      brandId: brandInfo?.brand?._id,
      ...(type === MAP_AREA_LIST ? { mappedAreaId: areaId } : { areaId }),
    };

    try {
      const response =
        type === MAP_AREA_LIST
          ? await API.getStoresByMappedAreaId(data)
          : await API.getStoresByAreaId(data);
      if (response?.success === 1 && response?.data) {
        RESET_ACTIONS.resetCart();
        RESET_ACTIONS.resetDeliveryAddress();
        dispatch(Actions.DeliveryAddressAction.resetTempDeliveryAddress());
        dispatch(Actions.StoreAction.setMenu(null));
        dispatch(Actions.BrandAction.getBrandStoresSuccess(response.data));
        dispatch(Actions.ErrorAction.setStoreError(null));
        if (response?.data?.length === 1 && response?.data[0]?.storeId?._id) {
          const areaId =
            type === MAP_AREA_LIST
              ? response?.data[0]?._id
              : response?.data[0]?.areaID?._id;
          await getStoreInfoWithMenu(
            response?.data[0]?.storeId?._id,
            areaId,
            shippingMethodTemp
          );
          dispatch(Actions.NotPersistAction.setModalClose());
        } else if (response?.data?.length > 1) {
          dispatch(Actions.NotPersistAction.setModalOpen());
          dispatch(Actions.NotPersistAction.toggleAreaModal(false));
          dispatch(Actions.Loader.disableLoader());
        }
      } else {
        dispatch(Actions.ResetAction.resetOnChangeArea());
        RESET_ACTIONS.resetSelectedStore();
        UTILS.errorNotification(errorHandler(response.error));
        dispatch(Actions.Loader.disableLoader());
        router("/", `/${params.domainName}`, DomainType);
      }
    } catch (error) {
      dispatch(Actions.NotPersistAction.setModalClose());
      dispatch(Actions.NotPersistAction.toggleAreaModal(false));
      dispatch(Actions.ResetAction.resetOnChangeArea());
      RESET_ACTIONS.resetSelectedStore();
      UTILS.errorNotification(errorHandler(error.error));
      dispatch(Actions.Loader.disableLoader());
      router("/", `/${params.domainName}`, DomainType);
    } finally {
      dispatch(Actions.Loader.setModalLoader(false));
    }
  }
  return getStoresByArea;
}

export default useGetStoresByAreaId;
