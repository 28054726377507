import React from "react";
import { t } from "i18next";
import { useLang } from "hooks";
import C from "Constants";
const ItemsPaymentTotalDetail = (props) => {
  const { currentLang } = useLang();
  const {
    totalOrderPrice,
    totalItemsPrice,
    deliveryFee,
    taxRate,
    discountAmount = 0,
    loyaltyAmount = 0,
    orderType,
  } = props;
  const page = props.page === true ? true : false;

  const basketSubTotal = () => {
    const total = totalItemsPrice + discountAmount + loyaltyAmount;

    return total?.toFixed(2);
  };
  return (
    <div className="bg-white">
      <ul className="basket-contents__items pt-5 ">
        <li className=" pb-1 basket-item flex items-center flex-row justify-between kkkk flex-100 px-4 text-16x">
          <span className="item">
            <div className="orderweb__09f2b375">
              <span className="text-black">{t("Basket Total")} </span>
            </div>
          </span>

          <span className="text-black ">
            {currentLang === "AR"
              ? basketSubTotal() + " " + t("AED")
              : t("AED") + " " + basketSubTotal()}
          </span>
        </li>
        {discountAmount ? (
          <li className="text-green-500 pb-1 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
            <span className="item">
              <div className="orderweb__09f2b375">
                <span className=" text-green-500">{t("Discount Amount")} </span>
              </div>
            </span>

            {/* <span className=" text-green-500 ">
              {currentLang === "AR"
                ? discountAmount?.toFixed(2) + " " + t("-AED")
                : t("-AED") + " " + discountAmount?.toFixed(2)}
            </span> */}
            <span className="text-green-500">
              <span> - </span>
              {currentLang === "AR"
                ? discountAmount?.toFixed(2) + " " + t("AED")
                : t("AED") + " " + discountAmount?.toFixed(2)}
            </span>
          </li>
        ) : null}
        {loyaltyAmount ? (
          <li className="text-green-500 pb-1 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
            <span className="item">
              <div className="orderweb__09f2b375">
                <span className=" text-green-500">{t("Loyalty points")} </span>
              </div>
            </span>

            {/* <span className=" text-green-500 ">
              {currentLang === "AR"
                ? loyaltyAmount?.toFixed(2) + " " + t("-AED")
                : t("-AED") + " " + loyaltyAmount?.toFixed(2)}
            </span> */}
            <span className="text-green-500">
              <span> - </span>
              {currentLang === "AR"
                ? loyaltyAmount?.toFixed(2) + " " + t("AED")
                : t("AED") + " " + loyaltyAmount?.toFixed(2)}
            </span>
          </li>
        ) : null}
        {orderType === C.SERVICE_TYPE.DELIVERY ? (
          <li className="pb-1 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
            <span className="item">
              <div className="orderweb__09f2b375">
                <span className="text-black">{t("Delivery Fee")}</span>
              </div>
            </span>
            <span className="text-black">
              {currentLang === "AR"
                ? deliveryFee + " " + t("AED")
                : t("AED") + " " + deliveryFee}
            </span>
          </li>
        ) : null}
        <li className="pb-1 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
          <span className="item">
            <div className="orderweb__09f2b375">
              <span className="text-black">{t("Value-Added Tax")}</span>
            </div>
          </span>
          <span className="text-black">{`${taxRate}%`}</span>
        </li>
      </ul>
      <div className="py-1">
        <div className="py-4">
          <div className="flex items-center justify-between px-4 text-16x">
            <span className="flex items-center flex-auto px-.5">
              <span
                className={
                  page
                    ? "text-outer-space text-22x font-semibold"
                    : "text-black text-17x font-bold"
                }
              >
                {t("Total")}
              </span>
            </span>

            <span className="text-16x">
              <span
                className={
                  page
                    ? "text-outer-space text-22x"
                    : "text-black text-17x font-bold"
                }
              >
                {currentLang === "AR"
                  ? totalOrderPrice + " " + t("AED")
                  : t("AED") + " " + totalOrderPrice}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemsPaymentTotalDetail;
