import React from "react";
import { SVGICONS } from "config";
const Header = ({ goToLogin }) => {
  return (
    <div className="mb-5">
      <button className="w-5 h-5" onClick={goToLogin}>
        <SVGICONS.BackArrow />
      </button>
    </div>
  );
};

export default Header;
