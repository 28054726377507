import React from "react";
import { useSelector } from "react-redux";
import { CustomModal } from "components";
import { t } from "i18next";
import { StoreListForPickup } from "components/store/StoreList";
import { NotPersistAction } from "store/actions";
import ChangeActionPopup from "components/Modals/ChangeActionPopup";
import useChangePickupStore from "hooks/useChangePickupStore";

function ChangePickupStoreModal({ actionModal, setActionModal }) {
  const { stores } = useSelector((state) => state?.brand);
  const { pickupStoreModal } = useSelector((state) => state.NotPersist);
  const { handleChangePickupStore } = useChangePickupStore();

  return (
    <>
      <CustomModal
        open={pickupStoreModal}
        reduxAction={NotPersistAction.togglePickupStoreModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-20"
      >
        <StoreListForPickup
          page="checkout"
          stores={stores}
          header={false}
          headerLabel=""
          styleClasses="fixed bottom-0 h-5 bg-white min-h-70p max-460 overflow-y-auto rounded-t-lg min-h-60p  border-sc"
        />
      </CustomModal>

      <CustomModal
        open={actionModal}
        setOpen={setActionModal}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-20 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ChangeActionPopup
          title={t(
            "Your existing basket will be cleared if you change your store? Would you like to continue"
          )}
          handleClickYes={() => {
            handleChangePickupStore();
            setActionModal(false);
          }}
          handleClickNo={() => {
            setActionModal(false);
          }}
        />
      </CustomModal>
    </>
  );
}

export default ChangePickupStoreModal;
