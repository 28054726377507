import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartId: null,
  paymentLinkInfo: null,
  isLoading: false,
  selectedPaymentType: "new_card",
};

const paymentLinks = createSlice({
  name: "paymentLinks",
  initialState,
  reducers: {
    setCartId: (state, action) => {
      state.cartId = action.payload;
    },
    setpaymentLinkInfo: (state, action) => {
      state.paymentLinkInfo = action.payload;
    },
    toggleLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedPaymentType: (state, action) => {
      state.selectedPaymentType = action.payload;
    },
  },
});

export const {
  setCartId,
  setpaymentLinkInfo,
  toggleLoader,
  setSelectedPaymentType,
} = paymentLinks.actions;
export default paymentLinks.reducer;
