import React from "react";
import Spinner from "components/Spinner";
import { useSelector } from "react-redux";

function WithLoading(props) {
  const { isLoading, checkoutLoader, walletLoader } = useSelector(
    (state) => state.loader
  );
  const { loading } = useSelector((state) => state?.brand);

  const { children } = props;
  if (
    loading ||
    isLoading ||
    props?.loading ||
    // checkoutLoader ||
    walletLoader ||
    props?.googlePayLoading
  )
    return <Spinner />;
  else return children;
}

export default WithLoading;
