import React from "react";
import { SVGICONS } from "config";
import { useSelector } from "react-redux";
import { t } from "i18next";
import ChangePickupStoreModal from "./ChangePickupStoreModal";

const PickupFrom = () => {
  const { selectedStore } = useSelector((state) => state?.store);
  const [actionModal, setActionModal] = React.useState(false);

  return (
    <>
      <div className=" pt-2 bg-white border-b-4 border-black-100">
        <h4 className="text-outer-space text-17x font-semibold m-2 mx-4">
          {t("Pickup from")}
        </h4>
        <span className="flex gap-1 px-4 bg-white  items-center">
          <div className="ws-14 hs-14 flex-none rounded-lg flex items-center justify-center">
            <SVGICONS.LocationIcon />
          </div>
          <div className="p-4 px-2 grow flex-col flex">
            <div>
              <small className="block cursor-pointer text-15x  font-semibold  capitalize">
                {selectedStore?.baseLanguage?.name}
              </small>
              <small className="text-areac text-14x block uppercase">
                {selectedStore?.manualAddress}
              </small>
            </div>
          </div>

          <div
            className="ws-14  flex-none flex items-center justify-center cursor-pointer text-brand-base-primary border border-brand-base-primary px-1 rounded  text-13x"
            onClick={() => setActionModal(true)}
          >
            {t("Change")}
          </div>
        </span>
      </div>
      <ChangePickupStoreModal {...{ actionModal, setActionModal }} />
    </>
  );
};

export default PickupFrom;
