import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isGeoLocationAdded: false,
  userdeliveryAdress: null, //Main obj for delivery address rest help to create this
  mapAddress: {
    streetAddress: null,
    city: null,
    country: null,
    area: null,
  },
  userLocation: {
    type: "Point",
    coordinates: {
      lat: 23,
      lng: 54,
    },
  },
  zoom: 7,

  /*Temporary: the purpose of this is to store the temporary
   address (on home-page) and location when user is changing the address
   and when (checkout MAnual address screen) user click on the confirm button then we will
   update the main address and location*/

  temporarymapAddress: {
    streetAddress: null,
    city: null,
    country: null,
    area: null,
  },
  temporaryuserLocation: {
    type: "Point",
    coordinates: {
      lat: 23,
      lng: 54,
    },
  },
  temporaryzoom: 7,

  // temporary address for adding address on Home page
  temporarymapAddressHome: {
    streetAddress: null,
    city: null,
    country: null,
    area: null,
  },
  temporaryuserLocationHome: {
    type: "Point",
    coordinates: {
      lat: 23,
      lng: 54,
    },
  },
  temporaryzoomHome: 7,
};

const DeliveryAddress = createSlice({
  name: "DeliveryAddress",
  initialState,
  reducers: {
    setMapAddress: (state, action) => {
      state.mapAddress = action.payload;
    },
    setUserLocation: (state, action) => {
      state.userLocation.coordinates = action.payload;
    },
    updateStreetAddress: (state, action) => {
      state.mapAddress.streetAddress = action.payload;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
    //----------temporary: the purpose of this is to store the temporary address and location when user is changing the address
    setTempMapAddress: (state, action) => {
      state.temporarymapAddress = action.payload;
    },
    setTempUserLocation: (state, action) => {
      state.temporaryuserLocation.coordinates = action.payload;
    },

    updateTempStreetAddress: (state, action) => {
      state.temporarymapAddress.streetAddress = action.payload;
    },

    setTempZoom: (state, action) => {
      state.temporaryzoom = action.payload;
    },
    resetTempDeliveryAddress: (state, action) => {
      state.temporarymapAddress = {
        streetAddress: null,
        city: null,
        country: null,
        area: null,
      };
      state.temporaryuserLocation = {
        type: "Point",
        coordinates: {
          lat: 23,
          lng: 54,
        },
      };
      state.isGeoLocationAdded = false;
      state.temporaryzoom = 7;
    },

    // Home-------------------

    setTempMapAddressHome: (state, action) => {
      state.temporarymapAddressHome = action.payload;
    },
    setTempUserLocationHome: (state, action) => {
      state.temporaryuserLocationHome.coordinates = action.payload;
    },

    updateTempStreetAddressHome: (state, action) => {
      state.temporarymapAddressHome.streetAddress = action.payload;
    },

    setTempZoomHome: (state, action) => {
      state.temporaryzoomHome = action.payload;
    },
    resetTempDeliveryAddressHome: (state, action) => {
      state.temporarymapAddressHome = {
        streetAddress: null,
        city: null,
        country: null,
        area: null,
      };
      state.temporaryuserLocationHome = {
        type: "Point",
        coordinates: {
          lat: 23,
          lng: 54,
        },
      };
      state.temporaryzoomHome = 7;
    },

    /// ------

    setDeliveryAddress: (state, action) => {
      state.userdeliveryAdress = action.payload;
      state.error = null;
    },
    setisGeoLocationAdded: (state, action) => {
      state.isGeoLocationAdded = action.payload;
    },

    // set to intial state
    resetDeliveryAddress: (state, action) => {
      state.userdeliveryAdress = null;
      state.mapAddress = {
        streetAddress: null,
        city: null,
        country: null,
        area: null,
      };
      state.userLocation = {
        type: "Point",
        coordinates: {
          lat: 23,
          lng: 54,
        },
      };

      state.zoom = 7;
    },

    // reset to intial state
    resetReducer: (state, action) => {
      state = initialState;
    },
  },
});

export const {
  setTempZoomHome,
  setTempMapAddressHome,
  setTempUserLocationHome,
  updateTempStreetAddressHome,
  resetTempDeliveryAddressHome,
  resetReducer,
  updateTempStreetAddress,
  resetTempDeliveryAddress,
  setTempMapAddress,
  setTempUserLocation,
  setTempZoom,
  updateStreetAddress,
  resetDeliveryAddress,
  setMapAddress,
  setUserLocation,
  setDeliveryAddress,
  setisGeoLocationAdded,
  setZoom,
} = DeliveryAddress.actions;
export default DeliveryAddress.reducer;
