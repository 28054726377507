import React, { useState } from "react";
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import WithMap from "components/Hoc/WithMap";
import PageHeader from "components/PageHeader";
import FixedFooter from "components/FixedFooter";

import { ErrorModal, CustomModal } from "components";
import WithLoading from "components/Hoc/WithLoading";
import { useMapContext } from "contexts/googleMap";

import { useCustomHistory } from "hooks";
import usePreFillLocalStorageAddress from "../../hooks/usePreFillLocalStorageAddress";

import C from "Constants";
import * as AC from "store/actions";
import DeliveryAreaModal from "components/Modals/DeliveryAreaModal";
import DeliveryStoreList from "components/Modals/DeliveryStoreList";
import PickupStoresModal from "components/Modals/PickupStoresModal";
import { DeliveryAddressAction } from "store/actions";

import { useDeliveryAddressContext } from "contexts/userAddressess";
import useGetBrandAres from "service-hooks/useGetBrandAres";
import useFetchBrandStoresByLatLng from "service-hooks/useFetchBrandStoresByLatLng";

function AddGeoLocationOnHomePage() {
  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const fetchBrandStoresByLatLng = useFetchBrandStoresByLatLng();
  const [mapref, setMapRef] = useState(null);
  const { deliveryAreas } = useSelector((state) => state.brand);
  const { error, errorModaL } = useSelector((rstate) => rstate.errorSlice);
  const { orderType, DomainType } = useSelector((state) => state.AppReducer);
  const { zoom } = useSelector((state) => state.DeliveryAddressReducer);

  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state.store
  );

  const { googlemapState } = useMapContext();
  usePreFillLocalStorageAddress();

  const updateTemporaryLocation = () => {
    dispatch(
      DeliveryAddressAction.setTempMapAddress(googlemapState?.mapAddress)
    );
    dispatch(DeliveryAddressAction.setTempZoom(googlemapState?.zoom || 16));
    dispatch(
      DeliveryAddressAction.setTempUserLocation(
        googlemapState?.userLocation?.coordinates
      )
    );
    dispatch(DeliveryAddressAction.setisGeoLocationAdded(true));
  };

  const { fetchBrandAreas } = useGetBrandAres();
  const gotoStorePage = () => {
    const storePageUrl = "/store/store-detail";
    router(storePageUrl, `/${params?.domainName}${storePageUrl}`, DomainType);
  };

  const goBack = () => {
    if (
      orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
      selectedStore &&
      outletBasedSelectedStoreArea
    )
      gotoStorePage();
    else if (orderType?.serviceType === C.SERVICE_TYPE.PICKUP && selectedStore)
      gotoStorePage();
    else router("/", `/${params?.domainName}`, DomainType);
  };

  const getAreasLength = () => deliveryAreas?.deliveryAreas?.length || 0;
  const { setAddressState, userAddressState } = useDeliveryAddressContext();

  return (
    <WithLoading>
      <div className="relative w-screen h-app-height bg-white store-page-popup">
        <PageHeader
          pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full relative"
          title={t("Set Delivery Location")}
          page="selectMap"
          url={true}
          handleClick={goBack}
        />

        <div className="bg-gray-100">
          <div className="felx jsutify-center relative">
            <WithMap
              class={getAreasLength() >= 1 ? "map-height" : "map-height1"}
              width="100%"
              height="100%"
              showInput={true}
              zoomChanging={true}
              zoom={zoom}
              setMapRef={setMapRef}
              mapref={mapref}
              googlemapState={{
                userLocation: {
                  lat: googlemapState?.userLocation?.coordinates?.lat,
                  lng: googlemapState?.userLocation?.coordinates?.lng,
                },
                zoom: googlemapState?.zoom,
                mapAddress: googlemapState?.mapAddress,
              }}
              zoomerror={userAddressState?.zoomErrorModal?.message?.msg}
              isZoomModalOpen={userAddressState?.zoomErrorModal?.open}
              setZoomErrorModal={() =>
                setAddressState("zoomErrorModal", { open: false, message: "" })
              }
            />
          </div>

          <FixedFooter page={false} from="selec location">
            {getAreasLength() > 0 && (
              <div className="font-bold flex mb-1 justify-center gap-2 items-center w-full bg-white h-12">
                <h2 className="mx-2">{t("Having trouble searching")}</h2>
                <button
                  onClick={fetchBrandAreas}
                  className="flex group items-center text-brand-base-primary bg-transparent border border-brand-base-primary rounded-md px-3 py-1 transition duration-300 ease-in-out hover:bg-brand-base-primary hover:text-white"
                >
                  <span className="mx-2">{t("Select location")}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3 group-hover:text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a1 1 0 0 0-.707.293l-6 6a1 1 0 0 0 1.414 1.414L10 5.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6A1 1 0 0 0 10 3z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            )}
            <span className="block w-full">
              <button
                onClick={fetchBrandStoresByLatLng}
                className="block text-center py-3 rounded text-brand-text-primary bg-brand-base-primary cursor-pointer text-16x w-full"
              >
                <span className="">
                  <div className="">
                    <span className="font-bold">{t("Confirm Location")}</span>
                  </div>
                </span>
              </button>
            </span>

            <DeliveryAreaModal />
            <DeliveryStoreList
              updateTemporaryLocation={updateTemporaryLocation}
            />
            <PickupStoresModal />
            <CustomModal
              open={errorModaL}
              reduxAction={AC.ErrorAction.setErrorModal(false)}
              bodyclasses="flex"
              overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
            >
              <ErrorModal
                reduxAction={AC.ErrorAction.setErrorModal(false)}
                message={error}
              />
            </CustomModal>
            {/* <ZoomErrorModal /> */}
          </FixedFooter>
        </div>
      </div>
    </WithLoading>
  );
}

export default AddGeoLocationOnHomePage;
