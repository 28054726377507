import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import CustomModal from "components/CustomModal";
import ApplyCouponModal from "./ApplyCouponModal";
import { DiscountAction } from "store/actions";
import { NotPersistAction } from "store/actions";
import { Spin } from "antd";
import DiscounteditemsList from "components/DiscounteditemsList";

const DiscountButton = ({ buttonText, handleDiscount }) => {
  const { discountModal } = useSelector((state) => state?.NotPersist);
  const { discountInfo } = useSelector((state) => state?.Discounts);
  const [open, setOpen] = useState(discountModal);
  const dispatch = useDispatch();

  const removeDiscount = () => {
    dispatch(DiscountAction.removeDiscountCode());
    dispatch(DiscountAction.setCoponValidation(false));
  };

  //
  useEffect(() => {
    return () => {
      dispatch(NotPersistAction.toggleDiscountModal(false));
    };
  }, []);

  return (
    <>
      <React.Fragment>
        {discountInfo ? (
          <div className="flex items-center justify-between rounded border border-brand-base-primary mx-4 border-dashed py-4 cursor-pointer hover:border-solid px-2">
            <div className="font-bold text-17x text-brand-base-primary">
              {discountInfo?.discountCode}
            </div>
            <span
              className="text-bold text-17x text-black"
              onClick={removeDiscount}
            >
              {t("Remove")}
            </span>
          </div>
        ) : (
          <span
            href="#"
            onClick={handleDiscount}
            className="flex items-center rounded border border-brand-base-primary mx-4 border-dashed py-4 cursor-pointer hover:border-solid px-2"
          >
            <div className="px-2">
              <svg
                viewBox="0 0 512 512"
                xmlns="https://www.w3.org/2000/svg"
                fill="currentColor"
                className="promo-code__icon text-brand-base-primary w-4 h-4"
              >
                <path d="M497.941 225.941L286.059 14.059A48 48 0 00252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0014.059 33.941l211.882 211.882c18.745 18.745 49.137 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zm-22.627 45.255L271.196 475.314c-6.243 6.243-16.375 6.253-22.627 0L36.686 263.431A15.895 15.895 0 0132 252.117V48c0-8.822 7.178-16 16-16h204.118c4.274 0 8.292 1.664 11.314 4.686l211.882 211.882c6.238 6.239 6.238 16.39 0 22.628zM144 124c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.972-20-20 8.972-20 20-20m0-28c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"></path>
              </svg>
            </div>
            <div className="px-2 text-17x text-brand-base-primary">
              {t(buttonText)}
            </div>
          </span>
        )}
      </React.Fragment>

      <CustomModal
        open={discountModal}
        setOpen={setOpen}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ApplyCouponModal open={discountModal} />
      </CustomModal>
    </>
  );
};

export default DiscountButton;
