import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";

import Quantity from "components/item/Quantity";
import ItemHeader from "components/item/ItemHeader";
import WithLoading from "components/Hoc/WithLoading";
import AddressErrorModalPopup from "components/Modals/AddressErrorModalPopup";
import ItemNotFoundError from "components/item/ItemNotFound";
import ModiferGroups from "components/Modifier/ModiferGroups";
import DeliveryAreaModal from "components/Modals/DeliveryAreaModal";
import PickupStoresModal from "components/Modals/PickupStoresModal";
import useAddItemToBasket from "components/item/hooks/useAddItemToBasket";
import DeliveryStoreListModal from "components/Modals/DeliveryStoreList";

import AddressList from "../../delivery-address/modals/AddressList";
import RenderFooter from "../components/Footer";

import { NotPersistAction, ItemAction } from "store/actions";

import { useCustomHistory } from "hooks";
import useIsStoreSelected from "../../hooks/useIsStoreSelected";
import useUpdateUtmMedium from "../../../components/item/hooks/useUpdateUtmMedium";
import useGetSavedAddressList from "service-hooks/useGetSavedAddressList";

import C from "Constants";
import ZoomErrorModal from "components/Modals/ZoomErrorModal";
import MenuDisabledModal from "../components/MenuDisabledModal";
import useGetStoreInfo from "service-hooks/useGetStoreInfo";
import { isItemOutOfStock } from "utils/Utils";

const ItemContainer = () => {
  const STORE_PAGE_URL = "/store/store-detail";

  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { menuParams } = useSelector((state) => state.store);
  const getStoreInfoWithMenu = useGetStoreInfo();

  useUpdateUtmMedium();
  const isStoreSelected = useIsStoreSelected();
  const { addItemToBasket } = useAddItemToBasket();
  useGetSavedAddressList({
    fetch: true,
    type: "filtered",
  });

  const { isLoading } = useSelector((state) => state.loader);
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state.store
  );

  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { selectedItem, error, loading } = useSelector((state) => state?.item);

  // React state
  const [itemSection, setItemSection] = useState([]);
  const [singleItem, setSingleItem] = useState({
    quantity: 1,
    itemSection: [],
    price: selectedItem?.itemInfo?.price || 0,
  });

  useEffect(() => {
    updateLocalState("price", selectedItem?.itemInfo?.price || 0);
    return () => {
      dispatch(NotPersistAction.setRequiredMissingSections([]));
    };
  }, [selectedItem?.itemInfo?.price]);

  const updateLocalState = (key, value) => {
    setSingleItem((prevSingleItem) => ({
      ...prevSingleItem,
      [key]: value,
    }));
  };

  const decreaseQuantity = () => {
    if (singleItem?.quantity > 1) {
      updateLocalState("quantity", singleItem.quantity - 1);
    }
  };

  const increaseQuantity = () =>
    updateLocalState("quantity", singleItem.quantity + 1);

  const getBasketValue = () => {
    const p = singleItem?.price * singleItem?.quantity;
    return p?.toFixed(2);
  };

  const goBackFromItemScreen = () => {
    router(
      STORE_PAGE_URL,
      `/${params.domainName}${STORE_PAGE_URL}`,
      DomainType,
      { fromItemScreen: true }
    );
  };

  const addSelectedToBasket = () => {
    addItemToBasket(
      brandInfo,
      selectedItem,
      singleItem,
      itemSection,
      goBackFromItemScreen
    );
  };

  const goToHomePage = () => {
    if (
      (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
        selectedStore &&
        outletBasedSelectedStoreArea) ||
      (orderType?.serviceType === C.SERVICE_TYPE.PICKUP && selectedStore)
    ) {
      router(
        STORE_PAGE_URL,
        `/${params.domainName}${STORE_PAGE_URL}`,
        DomainType,
        { fromItemScreen: true }
      );
    } else
      router("/", `/${params?.domainName}`, DomainType, {
        fromItemScreen: true,
      });
  };
  const itemId = params?.itemId;

  const catId = params?.catId;
  const getPageUrl = () => {
    return `/store/item/${itemId}/${catId}`;
  };
  const updateMenuActiveStatus = () => {
    dispatch(ItemAction.setMenuActiveStatus(false));
    getStoreInfoWithMenu(
      menuParams.storeId,
      menuParams.areaID,
      menuParams.storeArea
    );
  };

  if (!isLoading && error)
    return <ItemNotFoundError {...{ error }} handleClick={goToHomePage} />;
  return (
    <WithLoading loading={loading}>
      <div className="flex w-full bg-white flex-col store-page-popup ">
        <ItemHeader
          {...{ selectedItem }}
          closeItemDetail={goToHomePage}
          menuType={C.OUTLET_BASED_CATALOGUE}
          stripText={
            isItemOutOfStock(selectedItem?.itemInfo, selectedStore)
              ? t("This item is out of stock, sorry for inconvenience")
              : null
          }
        />
        <div className="flex flex-grow overflow-y-auto overflow-x-hidden">
          <div className="w-full">
            <ModiferGroups
              isStoreSelected={isStoreSelected()}
              {...{
                selectedItem,
                setItemSection,
                itemSection,
                singleItem,
                setSingleItem,
              }}
            />

            {isStoreSelected() ? (
              <Quantity
                {...{ decreaseQuantity, increaseQuantity, singleItem }}
              />
            ) : null}

            <RenderFooter
              {...{
                goToHomePage,
                addSelectedToBasket,
                singleItem,
                getBasketValue,
              }}
            />
          </div>
          <DeliveryAreaModal />
          <DeliveryStoreListModal updateTemporaryLocation={() => {}} />
          <AddressErrorModalPopup />
          <PickupStoresModal />
          <ZoomErrorModal />
          <MenuDisabledModal handleRefresh={updateMenuActiveStatus} />
        </div>
      </div>

      <AddressList page={getPageUrl()} pageFrom="item-detail" />
    </WithLoading>
  );
};

export default ItemContainer;
