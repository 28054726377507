import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { SVGICONS } from "config";
import { useSelector } from "react-redux";
import { useLang } from "hooks";
import { calculateItemsNetAmount } from "utils/Utils";
function ViewBasket({ viewBasketPage }) {
  const { cart } = useSelector((state) => state?.cart);
  const [total, setTotal] = useState(0);
  const { currentLang } = useLang();
  const { discountInfo } = useSelector((state) => state?.Discounts);
  useEffect(() => {
    let itemNetPrice = calculateItemsNetAmount(cart);
    setTotal(itemNetPrice.itemsNetAmount);
  }, [cart, discountInfo]);
  return (
    <React.Fragment>
      {cart?.length > 0 && (
        <div className="closed-basket-overview-wrapper w-full block">
          <span className="w-full block">
            <span className="cursor-pointer" onClick={viewBasketPage}>
              <button
                type="button"
                className="bg-brand-base-primary w-full py-3 rounded px-6 
                 border border-brand-text-primary  text-brand-text-primary text-16x font-semibold"
              >
                <span className="flex items-center justify-center text-center">
                  <div className="flex relative w-full items-center pointer">
                    <span className="flex-flex-2 px-2 py-1 -ml-2 rounded">
                      <SVGICONS.BasketBag />
                      <span className="absolute mx-1 top-0 h-4 w-4 rounded-full bg-brand-bgtext text-brand-base-primary text-12x">
                        {cart.length ?? 0}
                      </span>
                    </span>
                    <span className="my-0 mx-auto">{t("View Basket")}</span>
                    <span className="mx-1">
                      {currentLang === "AR"
                        ? total + " " + t("AED")
                        : t("AED") + " " + total}
                    </span>
                  </div>
                </span>
              </button>
            </span>
          </span>
        </div>
      )}
    </React.Fragment>
  );
}

export default ViewBasket;
