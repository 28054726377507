import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PageHeader from "components/PageHeader";
import ItemsPaymentTotalDetail from "components/OrderHistoryPaymentDetail";
import OrderGeneralDetail from "components/order-detail/OrderGeneralDetails";
import ProductsDetail from "components/order-detail/ProductsDetail";
import { Spinner } from "components";
import { useCustomHistory } from "hooks";
import * as ROUTE_CONSTANTS from "routes/constants";
function OrderDetail(props) {
  const params = useParams();
  const history = useHistory();
  const [order, setOrder] = useState({});
  const { isLoading } = useSelector((state) => state?.loader);
  const router = useCustomHistory();
  const { DomainType } = useSelector((state) => state.AppReducer);

  let url = ROUTE_CONSTANTS.OUTLET_MY_ORDERS[1];
  const goBack = () => {
    router(url, `/${params?.domainName}${url}`, DomainType);
  };
  useEffect(() => {
    if (history?.location?.state?.order) {
      const { order } = history?.location?.state;
      setOrder(order);
    } else {
      history.push(`/${params?.domainName}${url}`);
    }
  }, []);
  if (isLoading) return <Spinner />;
  return (
    <div className="text-14x max-460">
      <PageHeader
        pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full relative "
        title={t("Order detail")}
        url={true}
        handleClick={goBack}
      />
      <OrderGeneralDetail order={order} />
      <ProductsDetail order={order} />
      <ItemsPaymentTotalDetail
        orderType={order?.orderType?.type}
        deliveryFee={order?.deliveryFee ?? 0}
        totalOrderPrice={order?.totalOrderPrice ?? 0}
        discountAmount={order?.discountApplied?.discountAmountAvailedByCustomer}
        totalItemsPrice={order?.itemsTotalPrice ?? 0}
        taxRate={order.taxRate ?? 0}
        loyaltyAmount={order?.loyaltyApplied?.redeemAmount ?? 0}
        page={true}
      />
    </div>
  );
}

export default OrderDetail;
