import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import C from "Constants";
const Seo = () => {
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const Title = brandInfo?.brand?.brandName
    ? `Order direct from ${brandInfo?.brand?.brandName} || RestHero`
    : process.env.REACT_APP_TITLE;

  return (
    <Helmet>
      <title>{Title}</title>
      <meta property="og:title" content={Title} />

      <meta name="twitter:title" content={Title} />

      <link
        rel="icon"
        type="image/x-icon"
        href={brandInfo?.setting?.brandFavicon || process.env.REACT_APP_FAVICON}
      />
      <meta
        property="og:image"
        content={
          brandInfo?.setting?.brandFavicon || process.env.REACT_APP_FAVICON
        }
      />
      <meta property="og:image" content={process.env.REACT_APP_COMPANY_LOGO} />
      {process.env.REACT_APP_COMPANY_NAME === C.COMPANY_NAMES.CUSINETTE ? (
        <link rel="manifest" href="/cusinette_manifest.json" />
      ) : (
        <link rel="manifest" href="/manifest.json" />
      )}
      <link
        rel="apple-touch-icon"
        href={brandInfo?.setting?.brandFavicon || process.env.REACT_APP_FAVICON}
      />
    </Helmet>
  );
};

export default Seo;
