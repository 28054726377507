import React from "react";

function Minus() {
  return (
    <svg
      height="34"
      width="34"
      viewBox="0 0 24 24"
      className="text-brand-base-primary"
    >
      <path
        fill="currentColor"
        d="M12 2C17.5228 2 22 6.47725 22 12C22 17.5228 17.5228 22 12 22C6.47717 22 2 17.5228 2 12C2 6.47725 6.47717 2 12 2ZM12 20C16.4113 20 20 16.4113 20 12C20 7.58875 16.4113 4 12 4C7.58875 4 4 7.58875 4 12C4 16.4113 7.58875 20 12 20ZM7 13.5V10.5H17V13.5H7Z"
      ></path>
    </svg>
  );
}

export default Minus;
