import React from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function WithStripe({ children }) {
  const { stripeKey } = useSelector((state) => state?.brandStorageReducer);
  let promise = null;
  if (stripeKey) {
    promise = loadStripe(stripeKey);
  }
  const { language } = useSelector((state) => state?.AppReducer);
  return (
    <Elements
      stripe={promise}
      options={{
        locale: language,
      }}
    >
      {children}
    </Elements>
  );
}

export default WithStripe;
