import { useDispatch, useSelector } from "react-redux";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { UTILS } from "utils";
import * as Actions from "store/actions";
import API from "apiHelpers";
import useSetStoreMenu from "./useSetStoreMenu";
import { isOpenedAnyStore, checkIsStoreAvailable } from "./utils";
function useGetStoresByLatLng() {
  const dispatch = useDispatch();
  const setStoreMenu = useSetStoreMenu();
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);

  async function getStoresByLatLong(
    latlng,
    updateTemporaryLocation = () => {}
  ) {
    try {
      handleStart();
      const response = await API.getStoresBylatLng({
        brandId: brandInfo?.brand?._id,
        ...latlng,
      });

      if (response?.success === 1 && response?.data) {
        handleSuccessResponse(response, latlng, updateTemporaryLocation);
        return response.data;
      } else {
        handleFailureResponse(response.error);
        return null;
      }
    } catch (error) {
      handleFailureResponse(error?.error);
      return null;
    }
  }

  async function handleSuccessResponse(res, latlng, updateTemporaryLocation) {
    if (res?.data?.length === 0) {
      const checkIsStore = checkIsStoreAvailable(res?.data[0]);
      if (!checkIsStore)
        UTILS.logPopupError(
          "Unfortunately, the store has paused delivery in your area for a short time. Change your delivery location or try again later.",
          true
        );
      setStoreMenu(res.data, latlng, updateTemporaryLocation);
    } else {
      const isOpened = isOpenedAnyStore(res.data);
      if (!isOpened) {
        UTILS.logPopupError(
          "Unfortunately, the store has paused delivery in your area for a short time. Change your delivery location or try again later.",
          true
        );
        return;
      }
      setStoreMenu(res.data, latlng, updateTemporaryLocation);
    }
  }

  function handleFailureResponse(error) {
    UTILS.logPopupError(errorHandler(error), true);
    handleEnd();
  }

  function handleStart() {
    dispatch(Actions.Loader.enableLoader());
    dispatch(Actions.Loader.setModalLoader(true));
  }
  function handleEnd() {
    dispatch(Actions.Loader.disableLoader());
    dispatch(Actions.Loader.setModalLoader(false));
  }

  return getStoresByLatLong;
}

export default useGetStoresByLatLng;
