import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";

import { SaveAddressesList, CustomModal } from "components";
import SelectDeliveryAddress from "components/checkout/SelectDeliveryAddress";

import { useDeliveryAddressContext } from "contexts/userAddressess";
import useDeleteDelAddress from "service-hooks/useDeleteDelAddress";
import useOpenSavedAddressListModal from "service-hooks/useOpenSavedAddressListModal";

import ChangeActionPopup from "components/Modals/ChangeActionPopup";
import useIsStoreSelected from "../../hooks/useIsStoreSelected";
import { ResetAction, DeliveryAddressAction } from "store/actions";
import ModalCloseButton from "components/ModalCloseButton";
import { useCustomHistory } from "hooks";
import C from "Constants";
import { useMapContext } from "contexts/googleMap";
import { useEventTracker } from "hooks";

function AddressList({ page }) {
  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const deleteAddress = useDeleteDelAddress();
  const [actionModal, setActionModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});

  const { userAddressState, setAddressState } = useDeliveryAddressContext();
  const { fetchStoresBySelectedAddressLatLng } = useOpenSavedAddressListModal();

  const { userdeliveryAdress } = useSelector(
    (state) => state.DeliveryAddressReducer
  );
  const { orderType, DomainType } = useSelector((state) => state.AppReducer);
  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state.store
  );

  const isStoreSelected = useIsStoreSelected();

  const handleUserAddress = (address) => {
    if (
      orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
      selectedStore &&
      outletBasedSelectedStoreArea
    ) {
      if (userdeliveryAdress?._id !== address?._id) setActionModal(true);
    } else {
      updateDeliveryAddress(address);
      setSelectedAddress(null);
    }
  };

  // if cart has items and user changes the address
  const confirmLocation = () => {
    // change address on store-page
    if (selectedAddress) {
      dispatch(ResetAction.resetOnChangeCartWithStore());
      updateDeliveryAddress(selectedAddress);
    }
  };

  const { setUserLocation } = useMapContext();

  const updateDeliveryAddress = (address) => {
    setUserLocation(address?.coordinates);
    fetchStoresBySelectedAddressLatLng(address);
    dispatch(DeliveryAddressAction.setDeliveryAddress(address));
    dispatch(DeliveryAddressAction.resetTempDeliveryAddressHome());
  };

  const redirectToNewMapAddressPage = () => {
    const NEW_MAP_ADD_URL = "/address/add-new-map-address";
    router(
      NEW_MAP_ADD_URL,
      `/${params.domainName}${NEW_MAP_ADD_URL}`,
      DomainType,
      { page }
    );
  };

  const logEvent = useEventTracker({
    name: "ADD_NEW_ADDRESS_ON_APP_START",
    orderType,
    shouldLog: false,
  });

  return (
    <>
      <CustomModal
        page="addressList"
        shouldCloseOnEsc="No"
        shouldCloseOnOverlayClick="No"
        open={userAddressState.savedAddressListModal}
        setOpen={() => setAddressState("savedAddressListModal", false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <SelectDeliveryAddress
          handleAddressClick={() => {
            redirectToNewMapAddressPage();
            logEvent("ADD_NEW_ADDRESS_ON_APP_START");
          }}
        >
          <ModalCloseButton
            onClick={() => setAddressState("savedAddressListModal", false)}
          />

          <SaveAddressesList
            {...{
              savedAddresses: userAddressState?.userAddresses,
              deleteSlectedAddress: deleteAddress,
              changeAddressModal: {},
              setChangeAddressModal: () => {},
              setAddress: (address) => {
                dispatch(
                  DeliveryAddressAction.resetTempDeliveryAddress(address)
                );
                handleUserAddress(address);
                setSelectedAddress(address);
                setAddressState("savedAddressListModal", false);
              },
              showTick: isStoreSelected() ? true : false,
            }}
          />
        </SelectDeliveryAddress>
      </CustomModal>
      <CustomModal
        open={actionModal}
        setOpen={setActionModal}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-20 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ChangeActionPopup
          title={t(
            "Your existing basket will be cleared if you change your address? Would you like to continue"
          )}
          open={actionModal}
          handleClickYes={() => {
            confirmLocation();
          }}
          handleClickNo={() => {
            setActionModal(false);
          }}
        />
      </CustomModal>
    </>
  );
}

export default AddressList;
