import React from "react";
import { t } from "i18next";
import C from "Constants";
import { useSelector } from "react-redux";
import { FormatPreOrderingTime } from "utils/OrderUtils";
import { SVGICONS } from "config";
import { PreOrderingDisplayTimingWidget } from "components/Pre-ordering";
import moment from "moment";

const DeliveryTime = ({ setPreOrderingSlotWidget }) => {
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);
  const { orderType } = useSelector((state) => state?.AppReducer);

  return (
    <>
      <div className=" pt-2 bg-white border-b-4 border-black-100">
        <h4 className="text-outer-space text-17x font-semibold m-2 mx-4">
          {orderType?.serviceType === C.SERVICE_TYPE.DELIVERY
            ? t("Delivery Time")
            : orderType?.serviceType === C.SERVICE_TYPE.PICKUP
            ? t("Pickup Time")
            : null}
        </h4>
        <div className="flex gap-1 px-4 bg-white  items-center ">
          <div className="ws-14 hs-14 flex-none rounded-lg flex items-center justify-center">
            <SVGICONS.TimeIcon />
          </div>
          <div className="px-4 px-2 font-semibold py-4 grow flex-col flex items-center">
            <>
              <small className="block cursor-pointer text-15x w-full font-semibold text-gray-400 capitalize"></small>
              <small className="break-all  text-14x w-full break-words block ">
                {orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER ? (
                  orderTiming?.isPreOrderAvailable &&
                  orderTiming?.preOrderSlot && (
                    <>
                      {orderTiming?.preOrderSlot?.day === C.TODAY
                        ? t(orderTiming?.preOrderSlot?.day)
                        : moment(orderTiming?.preOrderSlot?.day).format(
                            "dddd, DD MMM YYYY"
                          )}
                      {", "}
                      {FormatPreOrderingTime(orderTiming?.preOrderSlot?.time)}
                    </>
                  )
                ) : (
                  <> {t("ASAP")}</>
                )}
              </small>
            </>
          </div>
          <div
            onClick={() => setPreOrderingSlotWidget(true)}
            className="ws-14  flex-none flex items-center justify-center cursor-pointer  text-13x rounded text-brand-base-primary border border-brand-base-primary px-1"
          >
            {t("Change")}
          </div>
        </div>
        <div className="mb-2">
          <PreOrderingDisplayTimingWidget route="checkout" />
        </div>
      </div>
    </>
  );
};

export default DeliveryTime;
