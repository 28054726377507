import React, { useState } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import ItemHeader from "components/item/ItemHeader";
import { Spinner } from "components";
import { useLang } from "hooks";
import ModiferGroups from "components/Modifier/ModiferGroups";
import Quantity from "components/item/Quantity";
import C from "Constants";
import ItemFooter from "components/item/ItemFooter";
import { useHistory, useParams } from "react-router-dom";
const Item = () => {
  const { currentLang } = useLang();
  const history = useHistory();
  const params = useParams();
  const { isLoading } = useSelector((state) => state.loader);
  const { selectedItem } = useSelector((state) => state?.item);
  const [itemSection, setItemSection] = useState([]);
  const [singleItem, setSingleItem] = useState({
    quantity: 1,
    itemSection: [],
    price: selectedItem?.itemInfo?.price?.toFixed(2) ?? 0,
  });

  const addToBasket = () => {};
  const getBasketValue = () => singleItem?.price;
  const decreaseQuantity = () => {};
  const increaseQuantity = () => {};

  const closeItemDetail = () => {
    history.push(`/${params.domainName}/preview`);
  };

  if (isLoading) return <Spinner />;
  return (
    <div className="flex w-full bg-white flex-col store-page-popup">
      <ItemHeader {...{ selectedItem, closeItemDetail }} menuType={C.PREVIEW} />
      <div className="flex flex-grow overflow-y-auto overflow-x-hidden">
        <div className="w-full">
          {selectedItem?.itemInfo?.sections?.map((section) => (
            <ModiferGroups
              key={section._id}
              {...{
                section,
                setItemSection,
                itemSection,
                singleItem,
                setSingleItem,
              }}
              isStoreSelected={true}
            />
          ))}

          <Quantity {...{ decreaseQuantity, increaseQuantity, singleItem }} />
          <ItemFooter
            onButtonClick={addToBasket}
            btnText1={`${t("Add")} ${singleItem?.quantity ?? 1} ${t(
              "to basket"
            )}`}
            btnText2={`${
              currentLang === C.AR
                ? getBasketValue() + " " + t("AED")
                : t("AED") + " " + getBasketValue()
            }`}
            {...{ selectedItem }}
          />
        </div>
      </div>
    </div>
  );
};

export default Item;
