import { logErrorOnSentry } from "utils/Utils";
import i18next from "i18next";

export const errorHandler = (error) => {
  if (!error) return i18next.t("Something was incorrect.");
  if (typeof error === "string") {
    logErrorOnSentry(error, "info");
    return error;
  }
  if (typeof error?.data === "string") {
    logErrorOnSentry(error.data, "info");
    return error.data;
  }
  if (typeof error?.message === "string") {
    logErrorOnSentry(error.message, "info");
    return error.message;
  }
  if (typeof error?.response?.data === "string") {
    logErrorOnSentry(error?.response?.data, "info");
    return error?.response?.data;
  }
  if (typeof error?.error?.message === "string") {
    logErrorOnSentry(error?.error?.message, "info");
    return error?.error?.message;
  }
  if (typeof error?.error?.data?.message === "string") {
    logErrorOnSentry(error?.error?.data?.message, "info");
    return error?.error?.data?.message;
  } else return i18next.t("Something was incorrect.");
};
