import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NotPersistAction } from "store/actions";
import { createAction } from "@reduxjs/toolkit";

import Modal from "react-modal";
import { logErrorOnSentry } from "utils/Utils";
const CustomModal = (props) => {
  const dispatch = useDispatch();

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  const setModal = () => {
    try {
      if (props?.setOpen) {
        props.setOpen(false);
      }
      dispatch(NotPersistAction.setModalClose());
      if (props?.reduxAction) {
        if (props?.reduxAction?.type) {
          const action = createAction(props.reduxAction.type);
          dispatch(action(props?.reduxAction?.payload));
        }
      }
    } catch (error) {
      logErrorOnSentry(
        `Error in closing modal ${JSON.stringify(error)}`,
        "error"
      );
    }
  };
  function closeModal() {}

  const parentDivTop1 = document.body.getBoundingClientRect()?.top;
  useEffect(() => {
    try {
      if (props?.open) {
        document.body.style.position = "fixed";
        document.body.style.top = `${parentDivTop1}px`;
        document.body.style.overflow = "hidden";
        document.body.style.width = "100%";
      }

      return () => {
        document.body.removeAttribute("style");
        window?.scrollTo(0, parentDivTop1 * -1);
      };
    } catch (error) {
      logErrorOnSentry(
        `Error in closing modal ${JSON.stringify(error)}`,
        "error"
      );
      if (error instanceof TypeError) {
        window.scroll(0, 0);
      } else {
      }
    }
  }, [props?.open]);
  return (
    <Modal
      isOpen={props.open}
      onAfterOpen={afterOpenModal}
      onRequestClose={setModal}
      className={props.bodyclasses}
      overlayClassName={props.overlayClasses}
      shouldCloseOnEsc={props.shouldCloseOnEsc ? false : true}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick ? false : true}
      ariaHideApp={false}
      parentSelector={() => document.querySelector(".store-page-popup")}
    >
      {props.children}
    </Modal>
  );
};
export default CustomModal;
