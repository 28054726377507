import React from "react";
import { t } from "i18next";
import { SVGICONS } from "config";
import { UTILS } from "utils";

const CheckoutDeliveryArea = ({
  userdeliveryAdress,
  setChangeAddressModal,
}) => {
  return (
    <>
      <div className=" pt-2 bg-white border-b-4 border-black-100">
        <h4 className="text-outer-space text-17x font-semibold m-2 mx-4">
          {t("Delivering to")}
        </h4>
        <div className="flex gap-1 px-4 bg-white  items-center ">
          <div className="ws-14 hs-14 flex-none rounded-lg flex items-center justify-center">
            <SVGICONS.LocationIcon />
          </div>
          <div className="p-4 px-2 grow flex-col  flex items-center">
            {UTILS.getFormattedAddress(userdeliveryAdress) ? (
              <>
                <small
                  className="block cursor-pointer text-15x w-full font-semibold capitalize"
                  onClick={() => setChangeAddressModal(true)}
                >
                  {t(userdeliveryAdress?.addressType)}
                </small>
                <small className="break-all text-areac text-14x w-full break-words block">
                  {UTILS.getFormattedAddress(userdeliveryAdress)}
                </small>
              </>
            ) : (
              <small
                className="block cursor-pointer text-15x w-full font-semibold text-gray-400 capitalize"
                onClick={() => setChangeAddressModal(true)}
              >
                {t("SELECT LOCATION")}
              </small>
            )}
          </div>
          <div
            id="delivery-address-change"
            onClick={() => setChangeAddressModal(true)}
            className="ws-14  flex-none flex items-center justify-center cursor-pointer text-brand-base-primary border border-brand-base-primary px-1 rounded  text-13x"
          >
            {t("Change")}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutDeliveryArea;
