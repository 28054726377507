import useCustomHistory from "hooks/useCustomHistoryReplace";
import React, { useEffect } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

function useCheckIFQueryParmsExist() {
  const params = useParams();
  const router = useCustomHistory();
  const location = useLocation();
  const { DomainType } = useSelector((state) => state.AppReducer);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get("redirectedFrom");
    if (!queryParam) {
      const NEW_MAP_ADD_URL = "/address/add-new-map-address";
      router(
        NEW_MAP_ADD_URL,
        `/${params.domainName}${NEW_MAP_ADD_URL}`,
        DomainType,
        null
      );
    }
  }, [location?.search]);
}

export default useCheckIFQueryParmsExist;
