import React, { useMemo, useState } from "react";
import PopupHeader from "components/PopupHeader";
import Search from "components/Search";
import { useLang } from "hooks";
import { t } from "i18next";
import { SVGICONS } from "config";
import useTouchMoveBlur from "hooks/useTouchMoveBlur";

const getHeader = (header, headerLabel) => {
  if (header) {
    return <PopupHeader heading={headerLabel} />;
  }
};

// city componernt
const CityList = ({ city, opened, handleSelectedArea, currentLang }) => {
  const [open, setOpen] = useState(false);
  const setOpenState = () => {
    open ? setOpen(false) : setOpen(true);
  };
  return (
    <div onClick={setOpenState} className="cursor-pointer ">
      <h2 className="flex mx-4 py-4 items-center justify-between text-16x font-semibold border-b border-area_heading-b">
        {
          <span className="text-outer-space">
            {currentLang === "EN"
              ? city?.cityInfo?.cityNameEN
              : city?.cityInfo?.cityNameAR}
          </span>
        }

        <svg
          viewBox="0 0 24 24"
          className={`h-18x w-18x transform  text-brand-base-primary
                    ${open ? "rotate-180" : ""}
                  `}
        >
          <path
            fill="CurrentColor"
            d="M4.88398 7.11612L3.11621 8.88389L12.0001 17.7678L20.884 8.88389L19.1162 7.11612L12.0001 14.2322L4.88398 7.11612Z"
          ></path>
        </svg>
      </h2>

      <ul className="bg-area-bg cursor-pointer">
        {(open || opened) && (
          <AreaList {...{ city, handleSelectedArea, currentLang }} />
        )}
      </ul>
    </div>
  );
};

//  Area component
const AreaList = ({ city, handleSelectedArea, currentLang }) => {
  return city?.cityAreas?.map((area, key) => (
    <li onClick={() => handleSelectedArea(area, "area")} key={key}>
      <span className="mx-4 py-3   text-16x flex items-center border-b border-gray-200">
        <SVGICONS.LocationIcon />
        <span className="mx-2 text-outer-space">
          {currentLang === "EN"
            ? area?.areaInfo?.areaNameEN
            : area?.areaInfo?.areaNameAR}
        </span>
      </span>
    </li>
  ));
};

// Parent
function SelectDeliveryArea({
  styleClasses,
  header,
  headerLabel,
  handleSelectedArea,
  deliveryAreas,
}) {
  const [searchTerm, setSearch] = useState("");
  const [opened, setopened] = useState(false);
  const { currentLang } = useLang();
  const inputRef = React.useRef(null);
  useTouchMoveBlur({ inputRef });

  const handleSearch = (e) => {
    let term = e.target.value?.toLowerCase();
    setSearch(term);
  };
  const findArea = () => {
    return (area) =>
      currentLang === "AR" || currentLang === "ar"
        ? area?.areaInfo?.areaNameAR
            ?.toLowerCase()
            ?.includes(searchTerm?.trim())
        : area?.areaInfo?.areaNameEN
            ?.toLowerCase()
            ?.includes(searchTerm?.trim());
  };

  const Menu = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      setopened(false);
      return deliveryAreas;
    }
    return deliveryAreas?.reduce((r, { cityInfo, cityAreas }) => {
      let item = cityAreas?.filter(findArea());
      if (item && item.length) {
        r.push({ cityInfo, cityAreas: [...item] });
        setopened(true);
      }

      return r;
    }, []);
  }, [searchTerm, deliveryAreas]);

  return (
    <div className="max-460">
      <div className={styleClasses}>
        {getHeader(header, headerLabel)}
        <div className="px-4">
          <Search
            label={t("Search for an area")}
            onChange={handleSearch}
            value={searchTerm}
            inputRef={inputRef}
          />
        </div>
        <div className="scr-area-set">
          {Menu?.map((city, index) => {
            return (
              <CityList
                key={city?.cityInfo?._id}
                city={city}
                opened={opened}
                currentLang={currentLang}
                handleSelectedArea={handleSelectedArea}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SelectDeliveryArea;
