import React, { useMemo, useState } from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { TickIcon, LocationIcon } from "icons";
import { UTILS } from "utils";
import { Delete } from "icons";
import ConfirmModal from "./Modals/ConfirmModal";
import CustomModal from "./CustomModal";
import { logErrorOnSentry } from "utils/Utils";
const SaveAddressesList = ({
  savedAddresses,
  deleteSlectedAddress,
  setAddress,
  showTick,
}) => {
  const { userdeliveryAdress } = useSelector(
    (state) => state.DeliveryAddressReducer
  );

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState(
    () => () => {}
  );
  const handleDelete = (id) => {
    setOpenDeleteModal(true);
    setConfirmationCallback(() => () => {
      deleteSlectedAddress(id);
      setOpenDeleteModal(false);
    });
  };

  // log error on sentry if selected address not found in the list
  const addlogOnSentry = (selectedAddress, index) => {
    if (selectedAddress && index === -1) {
      logErrorOnSentry(
        `Saved address not found in the list `,
        "error",
        `selected address=${JSON.stringify(
          selectedAddress
        )} saved addresses=${JSON.stringify(savedAddresses)}`
      );
    }
  };

  const shipftedAddreses = useMemo(() => {
    if (userdeliveryAdress?._id && savedAddresses?.length > 0) {
      const index = savedAddresses.findIndex(
        (address) => address?._id === userdeliveryAdress?._id
      );

      addlogOnSentry(userdeliveryAdress, index);
      if (index > -1) {
        const temp = savedAddresses[index];
        const updatedAddresses = [...savedAddresses];
        updatedAddresses?.splice(index, 1);
        updatedAddresses?.unshift(temp);
        return updatedAddresses;
      } else return savedAddresses;
    } else return savedAddresses;
  }, [userdeliveryAdress?._id, savedAddresses?.length]);

  return (
    <>
      {shipftedAddreses?.length >= 1
        ? shipftedAddreses?.map((address) => (
            <li
              onClick={() => setAddress(address)}
              key={address?._id}
              className="
              cursor-pointer
            flex bg-white transition-all duration-200 ease-out hover:bg-black-100 focus:bg-black-100 border-b border-gray-200"
            >
              <div className="flex gap-2 px-4 bg-white w-screen items-center ">
                <div className=" flex-none rounded-lg flex items-center justify-center">
                  <LocationIcon />
                </div>
                <div className="p-4 grow flex-col break-all flex items-center">
                  <small className="font-semibold w-full text-black text-15x capitalize">
                    {t(address?.addressType)}
                  </small>

                  <small className="text-14x block w-full text-areac">
                    {UTILS.getFormattedAddress(address)}
                  </small>
                </div>
                <div className=" flex-none flex items-center justify-center text-brand-base-primary">
                  {userdeliveryAdress?._id === address?._id &&
                  showTick !== false ? (
                    <div className="">
                      <TickIcon classNames="w-5 h-5" />
                    </div>
                  ) : (
                    <div
                      className="  cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(address?._id);
                      }}
                    >
                      <Delete classNames="w-5 h-5" />
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))
        : null}

      <CustomModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ConfirmModal
          title={{
            title: t("Do you really want to delete this address?"),
            message: t("You cannot undo this action"),
          }}
          onCancel={() => {
            setOpenDeleteModal(false);
          }}
          onConfirm={() => {
            confirmationCallback();
          }}
        />
      </CustomModal>
    </>
  );
};

export default SaveAddressesList;
