import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import CityList from "components/address/CityList";
import CountryList from "components/address/ContryList";
import SelectDeliveryArea from "components/address/SelectDeliveryArea";
import { SHIPPING_METHODS } from "Constants/constants";
import WithLoading from "components/Hoc/WithLoading";
import { useGetStoreAreasMutation } from "store/brand-based-store/single-store-service";
import { CustomModal } from "components";
import { ErrorAction, DeliveryAddressAction, Loader } from "store/actions";
import { ErrorModal } from "components";
import AddDeliveryAddress from "components/checkout/AddDeliveryAddress";
import { useParams } from "react-router-dom";
import { useLang, useCustomHistory } from "hooks";
import { UTILS } from "utils";
import * as ROUTE_CONSTANTS from "routes/constants";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { useAddNewUserAddressMutation } from "store/deliveryAddress/delivery-address-service";
import TextInput from "components/TextInput";
import { DeliverAreaAction } from "store/actions";
import { handleLocalStorage } from "utils/storage";

function ShippingAreaHandLing({
  selectedValue,
  textLabel,
  errors,
  children,
  showAreaPoup,
  setshowAreaModal,
}) {
  return (
    <React.Fragment>
      <CustomModal
        saveAddressModal={true}
        open={showAreaPoup}
        setOpen={setshowAreaModal}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30"
      >
        {children}
      </CustomModal>
      <div onClick={() => setshowAreaModal(true)}>
        <TextInput
          id="shippingAreaTextView"
          label={t(textLabel)}
          value={selectedValue}
          onChange={() => {}}
          name="shippingArea"
          type="text"
          mandatory={true}
          labelClasses="text-14x text-black"
          inputClasses="focus:outline-none mb-4 flex-1 text-16x block w-full rounded border shadow-input px-2 py-3 border-gray-200"
          placeHolder={t(textLabel)}
          readOnly={true}
          error={errors?.selectedArea}
        />
      </div>
    </React.Fragment>
  );
}

function ManualAddressWithShippingModal(props) {
  let { setState, openModals, updatePartialDeliveryAddress } = props;
  //Hooks
  const params = useParams();
  const dispatch = useDispatch();
  const { currentLang } = useLang();
  const router = useCustomHistory();
  //RTK Queries
  const [addNewUserAddress] = useAddNewUserAddressMutation();
  // useSelector
  const { DomainType, shippingMethod } = useSelector(
    (state) => state.AppReducer
  );
  const { singleStoreAreas } = useSelector((state) => state.singleStoreReducer);
  const { selectedStore } = useSelector((state) => state.store);
  const { error, errorModaL } = useSelector((state) => state.errorSlice);
  const [getStoreAreas] = useGetStoreAreasMutation();

  const { brandInfo: brand } = useSelector(
    (state) => state?.brandStorageReducer
  );

  const [localState, setLocalState] = useState({
    showAreaPoup: false,
    errors: {
      mapAddress: "",
      fullAddress: "",
      selectedArea: "",
    },
    shippingAddress: {
      country: null,
      area: null,
      city: null,
    },
  });

  const updateLocalState = (key, val) => {
    setLocalState((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const handleSelectedArea = (area, key) => {
    if (area) {
      setLocalState((prevState) => ({
        ...prevState,
        shippingAddress: {
          ...prevState.shippingAddress,
          [key]: area,
        },
        errors: {
          ...prevState.errors,
          selectedArea: null,
        },
      }));
    }
    updateLocalState("showAreaPoup", false);
  };

  let sharedProps = {
    setState,
    openModals,
    updatePartialDeliveryAddress,
    showAreaPoup: localState.showAreaPoup,
    setshowAreaModal: (val) => updateLocalState("showAreaPoup", val),
    errors: localState.errors,
    setErrors: (val) => updateLocalState("errors", val),
    shippingAddress: localState.shippingAddress,
    setShippingAdd: (val) => updateLocalState("shippingAddress", val),
    isEnabled: true,
  };

  useEffect(() => {
    const getStoresDeliveryAreas = () => {
      let obj = {
        brandId: brand?.brand?._id,
        storeId: selectedStore._id,
      };
      if (obj?.brandId && obj?.storeId) getStoreAreas(obj);
    };
    getStoresDeliveryAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore?._id, brand?.brand?._id]);

  /*Validating user delivery selected area*/
  const validateArea = () => {
    const check = UTILS.getSelectedAreaData(
      shippingMethod,
      localState.shippingAddress
    );
    if (check?.isValid === false) {
      UTILS.scrollToError("shippingAreaTextView");
      setLocalState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          selectedArea: check?.msg,
        },
      }));
      return {
        isValid: false,
      };
    }
    return {
      isValid: true,
      obj: check?.obj,
    };
  };

  /*Adding user delivery address*/
  const addUserDeliveryAddress = async (formData) => {
    try {
      dispatch(Loader.enableLoader());
      const validate = validateArea();
      if (!validate?.isValid) return false;
      else {
        dispatch(
          DeliverAreaAction.setSelectedArea(localState.shippingAddress.area)
        );
        dispatch(
          DeliverAreaAction.setSelectedCity(localState.shippingAddress.city)
        );
        dispatch(
          DeliverAreaAction.setSelectedCountry(
            localState.shippingAddress.country
          )
        );
        let obj = {
          ...formData,
          ...validate?.obj,
          deliveryMethodType: shippingMethod,
        };
        const response = await addNewUserAddress(obj);
        if (response?.data?.success === 1 && response?.data?.data?.address) {
          dispatch(
            DeliveryAddressAction.setDeliveryAddress(
              response?.data?.data?.address
            )
          );
          handleLocalStorage.setItem(
            "deliveryAddress",
            response?.data?.data?.address
          );

          return true;
        } else {
          UTILS.logPopupError(errorHandler(response?.error?.data), true);
          return false;
        }
      }
    } catch (error) {
      UTILS.logPopupError(errorHandler(error?.error), true);
      return false;
    } finally {
      dispatch(Loader.disableLoader());
    }
  };

  // refeine Map location
  const handleRefineLocation = () => {
    const url = ROUTE_CONSTANTS.ECOMMERCE_MAP_DELIVERY_ADDRESS[1];
    router(url, `/${params?.domainName}${url}`, DomainType);
  };

  const gotoCheckoutPage = () => {
    const url = ROUTE_CONSTANTS.ECOMMERCE_CHECKOUT[1];
    router(url, `/${params?.domainName}${url}`, DomainType);
  };

  //Clearing address input fields
  const clearAddressInputFields = () => {};
  const { temporarymapAddress, temporaryuserLocation, temporaryzoom } =
    useSelector((state) => state.DeliveryAddressReducer);
  return (
    <div className="store-page-popup 44">
      <WithLoading>
        <AddDeliveryAddress
          {...{ temporarymapAddress, temporaryuserLocation, temporaryzoom }}
          gotoCheckoutPage={gotoCheckoutPage}
          onSubmit={addUserDeliveryAddress}
          errors={localState.errors}
          setErrors={(val) => updateLocalState("errors", val)}
          clearAddressInputFields={clearAddressInputFields}
          showAreaPoup={localState.showAreaPoup}
          handleRefineLocation={handleRefineLocation}
          isEnabled={shippingMethod ? true : false}
        >
          {shippingMethod === SHIPPING_METHODS.COUNTRY_BASED && (
            <ShippingAreaHandLing
              key="country"
              serachText={t("Search for a country")}
              errorMsg={t("Please select a country")}
              textLabel={t("Select country")}
              selectedShippingType={localState.shippingAddress.country}
              selectedValue={
                localState.shippingAddress?.country?.countryID?.[
                  `countryName${currentLang}`
                ]
              }
              {...sharedProps}
            >
              <CountryList
                handleSelectedArea={handleSelectedArea}
                header={false}
                deliveryAreas={singleStoreAreas}
                headerLabel=""
                styleClasses={`fixed bottom-0 country h-5 bg-white min-h-70p max-460 overflow-y-auto rounded-t-lg min-h-60p ${
                  currentLang === "AR" ? "border-sc-l" : "border-sc"
                }`}
              />
            </ShippingAreaHandLing>
          )}
          {shippingMethod === SHIPPING_METHODS.AREA_BASED && (
            <ShippingAreaHandLing
              key={"area"}
              serachText={t("Search for an area")}
              errorMsg={t("Please select an area")}
              textLabel={t("Select Area")}
              selectedShippingType={localState.shippingAddress.area}
              selectedValue={
                localState.shippingAddress?.area?.areaInfo?.[
                  `areaName${currentLang}`
                ]
              }
              {...sharedProps}
            >
              <SelectDeliveryArea
                key={"area"}
                handleSelectedArea={handleSelectedArea}
                header={false}
                deliveryAreas={singleStoreAreas}
                headerLabel=""
                styleClasses={`fixed bottom-0 area h-5 bg-white min-h-70p max-460  rounded-t-lg min-h-60p ${
                  currentLang === "AR" ? "border-sc-l" : "border-sc"
                }`}
              />
            </ShippingAreaHandLing>
          )}
          {shippingMethod === SHIPPING_METHODS.CITY_BASED && (
            <ShippingAreaHandLing
              key={"city"}
              serachText={t("Search for a city")}
              errorMsg={t("Please select a city")}
              textLabel={t("Select city")}
              selectedShippingType={localState.shippingAddress.city}
              selectedValue={
                localState.shippingAddress?.city?.cityID?.[
                  `cityName${currentLang}`
                ]
              }
              {...sharedProps}
            >
              <CityList
                handleSelectedArea={handleSelectedArea}
                header={false}
                deliveryAreas={singleStoreAreas}
                headerLabel=""
                styleClasses={`fixed bottom-0 city h-5 bg-white min-h-70p max-460 overflow-y-auto rounded-t-lg min-h-60p ${
                  currentLang === "AR" ? "border-sc-l" : "border-sc"
                }`}
              />
            </ShippingAreaHandLing>
          )}
        </AddDeliveryAddress>
        <CustomModal
          open={errorModaL}
          reduxAction={ErrorAction.setError({ modal: false, error: null })}
          bodyclasses="flex"
          overlayClasses="  fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
        >
          <ErrorModal
            reduxAction={ErrorAction.setErrorModal(false)}
            message={error}
          />
        </CustomModal>
      </WithLoading>
    </div>
  );
}

export default ManualAddressWithShippingModal;
