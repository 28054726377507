import { logPopupError } from "utils/Utils";
import { t } from "i18next";
import API from "apiHelpers";
import { Loader } from "store/actions";
import reduxstore from "store/store";

let fallBackMessage = t("Bank declined payment, please try again");

export const stripeCreatePaymentMethod = async (obj) => {
  try {
    const createStripePaymentIntent = await API.createStripePaymentIntent({
      amount: obj?.amount,
      storeId: obj?.storeId,
      isStoredCard: obj?.isStoredCard,
      ...(obj?.stripeSelectedCard?.isStoredCard && {
        cardId: obj?.cardId,
      }),
      ...(obj.currency && {
        currency: obj?.currency,
      }),
      paymentMethod: obj?.paymentMethod,
    });
    return createStripePaymentIntent;
  } catch (error) {
    logPopupError(error.message || fallBackMessage, true);
    return error;
  }
};

export const verifyStripePaymentIntent = async (confirmpayload, placeOrder) => {
  try {
    reduxstore.dispatch(Loader.togggleCheckoutLoader(true));
    const verifyStripePaymentIntent = await API.verifyStripePaymentIntent({
      paymentIntentId: confirmpayload,
    });
    if (
      verifyStripePaymentIntent?.success === 1 &&
      verifyStripePaymentIntent?.gatewayData?.isVerified
    ) {
      await placeOrder({
        paymentIntent: verifyStripePaymentIntent?.gatewayData,
      });
    }
    //verify payment failed
    else {
      logPopupError(
        verifyStripePaymentIntent?.error?.message || fallBackMessage,
        true
      );
      reduxstore.dispatch(Loader.togggleCheckoutLoader(false));
    }
  } catch (error) {
    logPopupError(error.message || fallBackMessage, true);
    reduxstore.dispatch(Loader.togggleCheckoutLoader(false));
    return error;
  }
};
