import { logErrorOnSentry } from "./Utils";

export const handleSessionStorage = {
  setItem: (key, value) => {
    try {
      if (!window || !window?.sessionStorage) {
        logErrorOnSentry(
          "Window object is null or undefined. Cannot use storage. to set item",
          "error"
        );
        return;
      }

      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      logErrorOnSentry("Error setting item in sessionStorage:", "error");
    }
  },

  getItem: (key) => {
    try {
      if (!window || !window?.sessionStorage) {
        logErrorOnSentry(
          "Window object is null or undefined. Cannot use storage. to get item",
          "error"
        );
        return null;
      }

      const item = window.sessionStorage.getItem(key);
      // Check for null or undefined
      return item !== null ? JSON.parse(item) : null;
    } catch (error) {
      logErrorOnSentry("Error getting item from sessionStorage:", "error");
      return null;
    }
  },

  removeItem: (key) => {
    try {
      if (!window || !window?.sessionStorage) {
        logErrorOnSentry(
          "Window object is null or undefined. Cannot use storage. to remove item",
          "error"
        );
        return;
      }

      window.sessionStorage.removeItem(key);
    } catch (error) {
      logErrorOnSentry("Error removing item from sessionStorage:", "error");
    }
  },
};

export const handleLocalStorage = {
  setItem: (key, value) => {
    try {
      if (!window || !window?.localStorage) {
        logErrorOnSentry(
          "Window object is null or undefined. Cannot use storage. to set item",
          "error"
        );
        return;
      }

      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      logErrorOnSentry("Error setting item in sessionStorage:", "error");
    }
  },

  getItem: (key) => {
    try {
      if (!window || !window?.localStorage) {
        logErrorOnSentry(
          "Window object is null or undefined. Cannot use storage. to get item",
          "error"
        );
        return null;
      }

      const item = window.localStorage.getItem(key);
      // Check for null or undefined
      return item !== null ? JSON.parse(item) : null;
    } catch (error) {
      logErrorOnSentry("Error getting item from sessionStorage:", "error");
      return null;
    }
  },

  removeItem: (key) => {
    try {
      if (!window || !window?.localStorage) {
        logErrorOnSentry(
          "Window object is null or undefined. Cannot use storage. to remove item",
          "error"
        );
        return;
      }

      window.localStorage.removeItem(key);
    } catch (error) {
      logErrorOnSentry("Error removing item from sessionStorage:", "error");
    }
  },
};
