import reduxstore from "store/store";
import { Store } from "react-notifications-component";
import * as Sentry from "@sentry/browser";
import copy from "copy-to-clipboard";
import { max, findIndex } from "lodash";
import C from "Constants";
import moment from "moment";

import {
  DiscountAction,
  CartAction,
  ErrorAction,
  BrandStorageAction,
  AppAction,
  PaymentMethodAction,
  StoreAction,
} from "store/actions";
import i18n from "i18n/i18n";
import i18next from "i18next";
import { UTILS } from "utils";
import { SHIPPING_METHODS } from "Constants/constants";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { handleSessionStorage } from "./storage";
import { NotPersistAction } from "store/actions";
import { errrorMessages as discountErrorMessages } from "./discounts.utils";

export const shouldTrackSessions = () => {
  const blacklist = ["beta", "development"];
  return !blacklist.includes(process.env.REACT_APP_ENV);
};

export const successNotification = (message, placement) => {
  let p = placement ? placement : "top-center";
  UTILS.HANDLE_NOTIFICATION(p, "success", message);
};

export const errorNotification = (message, placement) => {
  let p = placement ? placement : "top-center";
  UTILS.HANDLE_NOTIFICATION(p, "danger", message);
};
export const HANDLE_NOTIFICATION = (placement, type, message) => {
  Store.addNotification({
    message: message,
    type: type,
    insert: "top",
    container: placement,
    // animationIn: ["animate__animated", "animate__fadeIn"],
    // animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 4000,
      // onScreen: true
    },
  });
};
export const getSentryUserObj = () => {
  let brand = reduxstore.getState();
  const user = getCurrentUser();
  if (user?.user) {
    if (user?.user?.phoneNumber) {
      let obj = {
        id: user?.user?.userId,
        email: user?.user?.phoneNumber,
        username: user?.user?.brandId,
      };
      return obj;
    } else {
      if (brand?.brand?._id) {
        let obj = {
          id: "brandId=" + brand.brand._id,
        };
        return obj;
      }
    }
  }
};

export const ifNullOrUndefined = (value) => {
  if (value === null || value === undefined) {
    return true;
  }
  return false;
};

export const notUndefined = (value) => {
  if (value !== null || value !== undefined) {
    return true;
  }
  return false;
};

/*validating shipping methods*/
const validateShippingMethods = () => {
  const { shippingMethod } = reduxstore.getState().AppReducer;
  if (shippingMethod) {
    return {
      methodName: shippingMethod,
    };
  } else return false;
};

/*creating and validating dumb order object for outlet-buisness*/
export const validateOutletOrderObject = () => {
  let errorMsg =
    "outlet based critcal issue(store not selected on checkout page)";
  let errors = {};
  let isValid = true;
  let shippingMethod = null;

  const checkNullOrUndefined = (value, key, msg, level, sentryMsg = "") => {
    if (value === null || value === undefined) {
      isValid = false;
      let SntryMSg = `${key} is null or undefined: ${value} ->usermessage ${
        msg ? msg : "we're sorry but something went wrong"
      } ${sentryMsg}`;
      logErrorOnSentry(SntryMSg, level);
      errors[key] = i18n.t(msg || "Something was incorrect.");
    }
  };

  const { brandInfo: brand, loyaltyPolicy } =
    reduxstore.getState().brandStorageReducer;
  const { orderType } = reduxstore.getState().AppReducer;
  const selectedPaymentType = reduxstore.getState()?.PaymentMethodReducer;

  const { selectedPayment, outletBasedSelectedStoreArea, selectedStore } =
    reduxstore.getState()?.store;
  const cartSlice = reduxstore.getState()?.cart;
  const currentUser = UTILS.getCurrentUser(brand?._id);
  const { userdeliveryAdress } = reduxstore.getState().DeliveryAddressReducer;
  const { discountInfo, isDiscountApplied } = reduxstore.getState().Discounts;
  const loyaltySlice = reduxstore.getState().Loyalty;
  const {
    cart,
    amountAfterDiscount,
    discountAmount,
    orderGrossAmount,
    itemsGrossAmount,
    orderSplReq,
    pickupVehicleInfo,
  } = reduxstore.getState().cart;
  const loyaltyApplied = loyaltySlice.loyaltyApplied;
  const loyaltyRedeemAmount = loyaltyApplied ? loyaltySlice?.loyaltyAmount : 0;
  const loyaltyCashBackAmount = calculateLoyaltyPoint(
    loyaltyPolicy,
    orderGrossAmount
  );

  const pickupTiming = {
    asap: true,
    time: null,
  };

  if (orderType.serviceType === C.SERVICE_TYPE.PICKUP) {
    if (
      selectedStore?.vehicleInfoForPickup?.isActive === true &&
      selectedStore?.vehicleInfoForPickup?.isRequired === true
    ) {
      if (
        !isValidValue(pickupVehicleInfo) ||
        pickupVehicleInfo?.trim()?.length < 2
      ) {
        isValid = false;
        errors.pickupVehicleInfo = i18n.t(
          "Please enter your vehicle information"
        );
        try {
          const el = document.getElementById("pickupVehicleInfo");
          el?.scrollIntoView({
            block: "end",
          });
          return { isValid, errors };
        } catch (error) {}
        logErrorOnSentry("Please enter your vehicle information", "info");
      }
    }
  }

  checkNullOrUndefined(selectedStore?._id, `${errorMsg}storeId`, null, "error");
  checkNullOrUndefined(
    selectedStore?.taxRate,
    `${errorMsg}taxRate`,
    null,
    "error"
  );

  if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
    if (brand) {
      let isValid = validateShippingMethods();
      if (!isValid) {
        logErrorOnSentry(
          `no delivery method selected${brand.brand}->userMessage:There is no delivery method available in store, please contact the business.`,
          "error"
        );
        errors.deliveryMethod = i18n.t(
          "There is no delivery method available in store, please contact the business"
        );

        isValid = false;
        return { isValid, errors };
      }
      if (isValid?.methodName) {
        shippingMethod = isValid;
        isValid = true;
      } else {
        isValid = false;
        return { isValid, errors };
      }
    }
  }

  checkNullOrUndefined(brand?.brand?._id, "brandId", null, "error");
  if (
    ifNullOrUndefined(selectedPayment) ||
    ifNullOrUndefined(selectedPaymentType?.selectedPaymentType)
  ) {
    isValid = false;
    logErrorOnSentry("Please choose a payment method", "info");
    errors.selectedPayment = i18n.t("Please choose a payment method");
    try {
      const el = document.getElementById("payment-methods");
      el?.scrollIntoView({
        block: "start",
      });
    } catch (error) {
    } finally {
      return { isValid, errors };
    }
  }
  checkNullOrUndefined(
    currentUser?.user?.phoneNumber,
    "phoneNumber",
    null,
    "error"
  );

  if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
    checkNullOrUndefined(
      outletBasedSelectedStoreArea?.minOrder,
      "minOrder",
      null,
      "error",
      "outletBasedSelectedStoreArea selectedArea minOrder is null or undefined"
    );
    checkNullOrUndefined(
      outletBasedSelectedStoreArea?.deliveryFee,
      "deliveryFee",
      null,
      "error",
      "outletBasedSelectedStoreArea selectedArea deliveryFee is null or undefined"
    );
    checkNullOrUndefined(
      outletBasedSelectedStoreArea?.deliveryTime,
      "deliveryTime",
      null,
      "error",
      "outletBasedSelectedStoreArea selectedArea deliveryTime is null or undefined"
    );

    if (shippingMethod === C.SHIPPING_METHODS.MAPPED_AREA_BASED) {
      checkNullOrUndefined(
        outletBasedSelectedStoreArea?.mappedAreaInfo?.mappedAreaId?._id,
        "selectedArea",
        null,
        "error",
        "outletBasedSelectedStoreArea selectedArea id is null or undefined"
      );
    }
    if (shippingMethod === "AREA_BASED_DELIVERY_METHOD") {
      checkNullOrUndefined(
        outletBasedSelectedStoreArea?.areaID?._id,
        "selectedArea",
        null,
        "error",
        "outletBasedSelectedStoreArea selectedArea id is null or undefined"
      );
      checkNullOrUndefined(
        outletBasedSelectedStoreArea?.cityID,
        "cityID",
        null,
        "error",
        "outletBasedSelectedStoreArea selectedArea cityID is null or undefined"
      );
      checkNullOrUndefined(
        outletBasedSelectedStoreArea?.countryID,
        "countryID",
        null,
        "error",
        "outletBasedSelectedStoreArea selectedArea countryID is null or undefined"
      );
    }

    if (
      ifNullOrUndefined(userdeliveryAdress?.address) ||
      ifNullOrUndefined(userdeliveryAdress?.city) ||
      ifNullOrUndefined(userdeliveryAdress?.street) ||
      ifNullOrUndefined(userdeliveryAdress?.addressType)
    ) {
      isValid = false;
      logErrorOnSentry("Please choose an address.", "info", userdeliveryAdress);
      errors.selectedPayment = i18n.t("Please choose an address.");
      try {
        const el = document.getElementById("delivery-address-change");
        el?.scrollIntoView({
          block: "end",
        });
      } catch (error) {
      } finally {
        return { isValid, errors };
      }
    }
  }

  checkNullOrUndefined(
    selectedStore?.baseLanguage?.name,
    `${errorMsg}selectedStore?.baseLanguage?.name`,
    null,
    "error"
  );
  checkNullOrUndefined(
    selectedStore?.baseLanguage?.description,
    `${errorMsg}selectedStore?.baseLanguage?.description`,
    null,
    "error"
  );
  checkNullOrUndefined(
    selectedStore?.mapAddress,
    `${errorMsg}mapAddress`,
    null,
    "error"
  );

  checkNullOrUndefined(
    selectedStore?.manualAddress,
    `${errorMsg}selectedStore?.manualAddress`,
    null,
    "error"
  );

  checkNullOrUndefined(
    selectedStore?.storeLocation?.coordinates,
    `${errorMsg}selectedStore?.storeLocation?.coordinates`,
    null,
    "error"
  );

  checkNullOrUndefined(
    currentUser?.user?.userId,
    "currentUser?.user?.userId",
    null,
    "error"
  );
  checkNullOrUndefined(orderGrossAmount, "totalOrderPrice", null, "error");
  checkNullOrUndefined(itemsGrossAmount, "itemsTotalPrice", null, "error");

  if (isDiscountApplied && ifNullOrUndefined(discountInfo)) {
    isValid = false;
    logErrorOnSentry("Please enter a working promo code.", "info");
    errors.isDiscountApplied = i18n.t("Please enter a working promo code.");
  }
  if (isDiscountApplied && ifNullOrUndefined(discountAmount)) {
    isValid = false;
    logErrorOnSentry("Please enter a working promo code.", "info");
    errors.isDiscountApplied = i18n.t("Please enter a working promo code.");
  }

  const { orderTiming } = reduxstore.getState()?.orderTimingReducer;
  if (orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER) {
    if (!orderTiming?.preOrderSlot?.day || !orderTiming?.preOrderSlot?.time) {
      isValid = false;
      logErrorOnSentry("Please choose a time for pre order ", "info");
      errors.preOrder = i18n.t("Please choose a time for pre order");
      return { isValid, errors };
    }
  }

  const orderObject = {
    loyaltyPolicy,
    brand,
    outletBasedSelectedStoreArea,
    selectedStore,
    cart,
    selectedPayment,
    currentUser,
    userdeliveryAdress,
    discountInfo,
    amountAfterDiscount,
    isDiscountApplied,
    totalOrderPrice: orderGrossAmount,
    itemsTotalPrice: itemsGrossAmount,
    discountAmount,
    shippingMethod,
    loyaltyApplied,
    loyaltyRedeemAmount,
    loyaltyCashBackAmount,
    orderSplReq,
    orderType,
    pickupTiming,
    pickupVehicleInfo,
    orderTiming,
    cartSlice,
  };
  return { isValid, errors, orderObject };
};

export const getOutletOrderObject = (orderObj) => {
  const { selectedCard } = reduxstore.getState().PaymentCardsReducer;
  const { lifetimeSpending } = reduxstore.getState()?.user || {};
  const deliveryFee = reduxstore.getState()?.cart?.deliveryFee;
  const obj = {
    lifetimeSpending: lifetimeSpending || 0,
    orderingPlatform: sessionStorage.getItem("platform") ?? "web",
    storeId: orderObj.selectedStore._id,
    brandId: orderObj.brand?.brand._id,
    isStoredCard: selectedCard?.isStoredCard || false,
    userInfo: {
      brandId: orderObj.brand?.brand._id,
      phoneNumber: orderObj.currentUser.user.phoneNumber,
      ...(orderObj?.currentUser?.user?.name && {
        name: orderObj?.currentUser?.user?.name,
      }),
      ...(orderObj?.currentUser?.user?.email && {
        email: orderObj?.currentUser?.user?.email,
      }),
    },

    storeInfo: {
      baseLanguage: {
        name: orderObj.selectedStore.baseLanguage.name,
        description: orderObj.selectedStore.baseLanguage.description,
      },

      translations: {
        en: {
          name: orderObj.selectedStore.baseLanguage.name,
        },
        ar: {
          name: orderObj.selectedStore.baseLanguage.name,
        },
      },
      mapAddress: orderObj.selectedStore.mapAddress,
      mobileNumber: orderObj.selectedStore.mobileNumber,
      manualAddress: orderObj.selectedStore.manualAddress,
      orderPickupLoc: {
        type: "Point",
        coordinates: orderObj.selectedStore.storeLocation.coordinates,
      },
      city: orderObj.selectedStore.city,
      country: C.DEFAULT_COUNTRY,
      ...(orderObj?.selectedStore?.preOrderSettingsForDelivery && {
        preOrderSettingsForDelivery:
          orderObj?.selectedStore?.preOrderSettingsForDelivery,
      }),
      ...(orderObj?.selectedStore?.preOrderSettingsForPickup && {
        preOrderSettingsForPickup:
          orderObj?.selectedStore?.preOrderSettingsForPickup,
      }),
    },

    selectedPaymentMethod: {
      AR: {
        name: orderObj.selectedPayment.AR.name,
      },
      EN: {
        name: orderObj.selectedPayment.EN.name,
      },
    },
    activeIntegrations: orderObj.brand?.activeIntegrations,
    orderCurrency: {
      currencyId: "6139b3d2b5264d526a8fae24",
      EN: { name: "AED" },
    },
    orderType: {
      type: orderObj.orderType.serviceType,
      EN: {
        name: orderObj.orderType.EN.name,
      },
      AR: {
        name: orderObj.orderType.AR.name,
      },
    },
    itemsTotalPrice: +orderObj.itemsTotalPrice?.toFixed(2),
    totalOrderPrice: +orderObj.totalOrderPrice?.toFixed(2),
    orderLanguage: getLanguage(),
    taxRate: orderObj.selectedStore.taxRate,
    orderLevelSpecialRequest: orderObj?.orderSplReq ?? null,
    orderedMenu: orderObj.cart,
    paymentType: orderObj.selectedPayment.paymentType,
    ...(orderObj.isDiscountApplied === true && {
      discountApplied: {
        discountAmountAvailedByCustomer: +orderObj.discountAmount,
        discountValue: orderObj.discountInfo?.discountAmount,
        discountId: orderObj?.discountInfo?._id,
        discountCode: orderObj.discountInfo?.discountCode,
        discountType: orderObj.discountInfo?.discountType,
      },
    }),
    ...(orderObj.selectedPayment?.paymentType === C.CREDIT_CARD && {
      orderCheckoutOnlinePaymentMetadata: orderObj.transactionInfo,
    }),

    //PreOrder
    ...(orderObj?.orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER && {
      isPreOrder: true,
      preOrderSlot: orderObj?.orderTiming?.preOrderSlot?.time,
      preOrderDay: orderObj?.orderTiming?.preOrderSlot?.day,
    }),

    //Loyalty
    ...(orderObj?.loyaltyPolicy?.isLoyaltyActive === true &&
      (orderObj?.loyaltyCashBackAmount > 0 ||
        orderObj?.loyaltyRedeemAmount > 0) && {
        loyaltyApplied: {
          redeemAmount: orderObj.loyaltyRedeemAmount,
          cashBackAmount: orderObj.loyaltyCashBackAmount,
        },
      }),
  };

  if (orderObj?.orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
    obj.deliveryFee = deliveryFee;
    obj.deliveryTime = orderObj.outletBasedSelectedStoreArea.deliveryTime;
    obj.deliveryAddress = {
      userId: orderObj.currentUser.user.userId,
      address: orderObj.userdeliveryAdress.address,
      addressType: orderObj.userdeliveryAdress.addressType,
      street: orderObj.userdeliveryAdress.street,
      orderDeliveryLoc: {
        type: "Point",
        coordinates: [
          orderObj.userdeliveryAdress.coordinates.lat,
          orderObj.userdeliveryAdress.coordinates.lng,
        ],
      },
      city: orderObj.userdeliveryAdress.city?.toLowerCase(),
      country: orderObj.userdeliveryAdress.country?.toLowerCase(),
      area: orderObj.userdeliveryAdress.area?.toLowerCase(),

      building: orderObj.userdeliveryAdress.building,
      floor: orderObj.userdeliveryAdress.floor,
      appartmentNo: orderObj?.userdeliveryAdress?.apartmentNo,
      selectedDeliveryMethodInfo: {
        methodName: orderObj?.shippingMethod?.methodName,
      },
    };
    if (
      orderObj?.cartSlice?.deliveryChargesWaiverInfo?.isWaiver === true &&
      orderObj?.cartSlice?.deliveryFee === 0
    ) {
      obj.deliveryChargesWaiverInfo =
        orderObj?.cartSlice?.deliveryChargesWaiverInfo?.WaiverInfo;
    }
  }
  if (orderObj?.orderType?.serviceType === C.SERVICE_TYPE.PICKUP) {
    obj.pickupTiming = orderObj.pickupTiming;
    obj.pickupVehicleInfo = orderObj?.pickupVehicleInfo;
  }

  let tableNo = new URLSearchParams(window.location.search).get(
    C.DINE_SERVICE_TYPE.TABLE_NUMBER
  );
  if (orderObj?.orderType?.serviceType === C.SERVICE_TYPE.DINE_IN && tableNo) {
    obj.dineInTableNumber = tableNo;
  }
  return obj;
};
/* End for outelt based order object */

// calculate  loyalty points earning

export const calculateLoyaltyPoint = (loyaltyPolicy, orderGrossItem) => {
  const minOrderValueForCashback = loyaltyPolicy?.minOrderValueForCashback || 0;
  const isActive = loyaltyPolicy?.specificToDelivery?.isActive || 0;
  const cashbackPercentage =
    loyaltyPolicy?.specificToDelivery?.cashbackPercentage || 0;
  let cashBackAmount = 0;
  if (minOrderValueForCashback <= orderGrossItem && isActive) {
    cashBackAmount = Number(
      ((orderGrossItem / 100) * cashbackPercentage).toFixed(2)
    );
  }
  return cashBackAmount;
};

// CREATE OBJECT FOR ONLINE PAYMENT
export const getHostedPaymentObj = ({
  endpoint,
  amountPaid,
  storeId,
  domainName,
  DomainType,
  successRedirectUrl,
  failureRedirectUrl,
}) => {
  let isValid = false;
  let value = "";

  if (
    ifNullOrUndefined(amountPaid) ||
    ifNullOrUndefined(storeId) ||
    ifNullOrUndefined(DomainType)
  ) {
    logErrorOnSentry(
      `error while creting hosted payment obj ${amountPaid} ${storeId} ${DomainType}`,
      "error"
    );
    isValid = false;
  }

  if (DomainType === C.CUSTOM_DOMAIN) {
    isValid = true;
    value = {
      amountPaid: +Number(amountPaid)?.toFixed(2),
      successRedirectUrl: `${C.PAYMENT_REDIRECT_URL}${successRedirectUrl}`,
      failureRedirectUrl: `${C.PAYMENT_REDIRECT_URL}${failureRedirectUrl}`,
      language: "EN",
      storeId: storeId,
    };
    return { isValid, value };
  } else if (DomainType === C.TEMPORARAY_DOMAIN && domainName) {
    isValid = true;
    value = {
      amountPaid: +Number(amountPaid)?.toFixed(2),
      successRedirectUrl: `${C.PAYMENT_REDIRECT_URL}${domainName}/${successRedirectUrl}`,
      failureRedirectUrl: `${C.PAYMENT_REDIRECT_URL}${domainName}/${failureRedirectUrl}`,
      language: "EN",
      storeId: storeId,
    };
    return { isValid, value };
  } else {
    isValid = false;
    return { isValid, value };
  }
};

// ONLINE PAYMENT OBJECT FOR OUTLET BASED
export const getHostedPaymentPageObj = (params) =>
  getHostedPaymentObj({ ...params });

// ONLINE OBJECT FOR BRAND-BASED
export const getHostedEcommerceObj = (params) =>
  getHostedPaymentObj({ ...params });

// ONLINE OBJECT FOR DINE-IN
export const getHostedDineInObjDineIN = (params) =>
  getHostedPaymentObj({ ...params });

// GETTING DISCOUNT INFORMATION
export const getDiscontInfo = (discounts, couponCode) => {
  const discount = discounts.find((item) => item.discountCode === couponCode);
  if (discount) {
    return discount;
  }
  return null;
};

// CHEKING IF DISCOUNT IS APPLICABLE ON ALL STORE
const isApplicableOnSelectedStore = (discountStores, selectedStore) => {
  const selectedStoreId = selectedStore?._id;
  const storePresent = !!discountStores.find(
    (store) => store.storeId === selectedStoreId
  );
  return storePresent;
};

// when discount is percentage type get discount amount value
const getOfferValue = (total, discountAmount) => {
  let discountOfferValue = total / 100;
  discountOfferValue = discountOfferValue * discountAmount;
  return discountOfferValue >= 0 ? discountOfferValue : 0;
};

// GEETING DISCOUNT AMOUNT ,when APPLICABLE ON ALL ITEMS
const getDiscountOnAllItemsTotal = function (
  discountedItemsTotal,
  selectedDiscount
) {
  var discount = 0;
  if (selectedDiscount.discountType === "fixed") {
    if (selectedDiscount.discountAmount >= discountedItemsTotal) {
      discount = discountedItemsTotal;
    } else {
      discount = selectedDiscount.discountAmount;
    }
  }

  // if Discount Value is less than Max-discount then discount ===discount Amount
  //else if Discount is greater than Max-discount then discount ===Max-discount
  if (selectedDiscount.discountType === "percentage") {
    var itemDiscountOfferValue = discountedItemsTotal / 100;
    itemDiscountOfferValue =
      itemDiscountOfferValue * selectedDiscount.discountAmount;
    if (
      selectedDiscount.maximumDiscountValue > 0 &&
      itemDiscountOfferValue > selectedDiscount.maximumDiscountValue
    ) {
      discount = selectedDiscount.maximumDiscountValue;
    } else {
      discount = itemDiscountOfferValue;
    }
  }
  return Number(discount);
};

// GETting new price after applying discount   when applicable on all items+ discunt value
const getAllItemsPrice = (total, discount) => {
  let newPrice = 0;
  let discountValue = 0;
  const { discountType, maximumDiscountValue, discountAmount } = discount;
  if (discountType === "fixed") {
    newPrice = total - discountAmount;
    newPrice = newPrice <= 0 ? 0 : newPrice;
    discountValue = discountAmount;
  } else {
    let discountOfferValue = getOfferValue(total, discountAmount);
    if (maximumDiscountValue > 0 && discountOfferValue > maximumDiscountValue) {
      newPrice = total - maximumDiscountValue;
      newPrice = newPrice <= 0 ? 0 : newPrice;
      discountValue = discountOfferValue;
    } else {
      newPrice = total - discountOfferValue;
      newPrice = newPrice <= 0 ? 0 : newPrice;
      discountValue = discountOfferValue;
    }
  }
  return {
    newPrice,
    discountValue,
  };
};

// Helper function GET PRICE WHEN DISCOUNT APPLICABLE ON SELECTED ITEMS (returning each item price after applying dscount)
const discountCouponApplyOnSelectedItems = function (
  price,
  itemId,
  selectedDiscount
) {
  var itemPriceWithDiscount = 0;
  var discountItemTotal = 0;
  var selectedItems = selectedDiscount.discountAppliedOnSpecificItems;
  if (selectedItems.filter((e) => e.itemId === itemId)?.length > 0) {
    discountItemTotal = price;
    if (selectedDiscount.discountType === "percentage") {
      var itemDiscountOfferValue = price / 100;
      itemDiscountOfferValue =
        itemDiscountOfferValue * selectedDiscount.discountAmount;
      if (
        selectedDiscount.maximumDiscountValue > 0 &&
        itemDiscountOfferValue > selectedDiscount.maximumDiscountValue
      ) {
        itemPriceWithDiscount = price - selectedDiscount.maximumDiscountValue;
      } else {
        itemPriceWithDiscount = price - itemDiscountOfferValue;
      }
    } else if (selectedDiscount.discountType === "fixed") {
      if (selectedDiscount.discountAmount >= price) {
        itemPriceWithDiscount = 0;
      } else {
        itemPriceWithDiscount = price - selectedDiscount.discountAmount;
      }
    }
  } else {
    console.log("item not found");
    itemPriceWithDiscount = price;
  }

  return {
    ipwd: itemPriceWithDiscount,
    ap: price,
    discountItemTotal: discountItemTotal,
  };
};

// Main function GET PRICE WHEN DISCOUNT APPLICABLE ON SELECTED ITEMS (returning each item price after applying dscount)

const applyDiscountOnSelectedItems = function (price, id, discount) {
  var returnObj = discountCouponApplyOnSelectedItems(price, id, discount);

  var item_total = returnObj.ipwd;
  var item_full_price = returnObj.ap;
  var discountItemTotal = returnObj.discountItemTotal;

  return {
    item_total: item_total,
    item_full_price: item_full_price,
    discountItemTotal: discountItemTotal,
  };
};

// calling this function for each each applicable item
const getSelectedItemsPrice = (cart, discount) => {
  let itemPriceAfterDiscount = 0;
  let itemOriginalPrice = 0;
  let discountValue = 0;

  cart.forEach((item) => {
    let price = item.price * item.quantity;
    const id = item.itemId;
    const selectedItemsDiscountTotal = applyDiscountOnSelectedItems(
      price,
      id,
      discount
    );

    itemOriginalPrice += price;
    discountValue += selectedItemsDiscountTotal.discountItemTotal;
  });

  const value = getDiscountOnAllItemsTotal(discountValue, discount);
  itemPriceAfterDiscount = itemOriginalPrice - value;
  itemPriceAfterDiscount =
    itemPriceAfterDiscount >= 0 ? itemPriceAfterDiscount : 0;

  return {
    itemPriceAfterDiscount,
    itemOriginalPrice,
    discountValue: value,
  };
};

// applying discount where dicount is applicble any store
const couponAppliedItems = (cart, discount, total) => {
  let items = [];
  if (discount?.isApplicableOnAllItems) {
    const { newPrice } = getAllItemsPrice(total, discount);
    const discountValue = getDiscountOnAllItemsTotal(total, discount);

    return { newPrice, total, discountValue };
  } else {
    discount?.discountAppliedOnSpecificItems.map((item) => {
      return items.push({ itemId: item.itemId });
    });

    const {
      itemPriceAfterDiscount: newPrice,
      itemOriginalPrice,
      discountValue,
    } = getSelectedItemsPrice(cart, discount);
    return { newPrice, itemOriginalPrice, discountValue };
  }
};

function getItemsWithApplicableDiscount(storeMenu, itemIds) {
  const itemIdsSet = new Set(itemIds); // Create a set for O(1) lookup
  const uniqueItems = new Map(); // Use a map to track unique items

  storeMenu?.categories?.forEach((category) => {
    category?.items?.forEach((item) => {
      const itemId = item?.itemId?._id;
      if (itemIdsSet?.has(itemId) && !uniqueItems.has(itemId)) {
        uniqueItems?.set(itemId, item);
      }
    });
  });

  return Array.from(uniqueItems.values());
}

const validateIfSeletedItemsDiscountApplicableOnCart = (
  cart,
  discount,
  itemsNetAmount
) => {
  const { storeMenu } = reduxstore.getState().store;
  if (
    !discount?.isApplicableOnAllItems &&
    discount.discountAppliedOnSpecificItems?.length > 0
  ) {
    // applicable items
    const itemIds = discount.discountAppliedOnSpecificItems.map((item) => {
      return item.itemId;
    });
    // items in cart
    const itemIdsInCart = cart.map((item) => {
      return item.itemId;
    });

    // Check if no item in itemIds is in itemIdsInCart
    const isCouponInValid = itemIds.every(
      (item) => !itemIdsInCart.includes(item)
    );

    if (isCouponInValid) {
      reduxstore.dispatch(DiscountAction.setCoponValidation(false));
      const itemListWhereDiscountIsApplicableUnique =
        getItemsWithApplicableDiscount(storeMenu, itemIds);
      const selectedItemsErrorMsg =
        itemListWhereDiscountIsApplicableUnique.length > 0
          ? i18n.t(discountErrorMessages[0])
          : i18n.t(discountErrorMessages[1]);
      reduxstore.dispatch(
        DiscountAction.validateDiscountFailure(selectedItemsErrorMsg)
      );

      reduxstore.dispatch(
        NotPersistAction.setapplicableItemsDiscountListModal({
          isOpen: false,
          data: itemListWhereDiscountIsApplicableUnique,
        })
      );

      return {
        cartValue: itemsNetAmount,
        totalAmount: itemsNetAmount,
        success: 0,
        message: selectedItemsErrorMsg,
      };
    } else {
      return {
        success: 1,
      };
    }
  } else {
    return {
      success: 1,
    };
  }
};

// MAIN FUNCTIONS FOR HANDLINF DISCOUNT
export const handleDiscount = (cart, discount, selectedStore) => {
  reduxstore.dispatch(
    NotPersistAction.setapplicableItemsDiscountListModal({
      isOpen: false,
      data: [],
    })
  );
  if (discount === null) {
    const { itemsNetAmount } = calculateItemsNetAmount(cart);
    reduxstore.dispatch(DiscountAction.setCoponValidation(false));
    reduxstore.dispatch(DiscountAction.validateDiscountFailure(null));

    return {
      message: null,
      cartValue: itemsNetAmount,
      totalAmount: itemsNetAmount,
      success: 1,
    };
  } else if (discount != null && discount !== undefined) {
    const { isApplicableOnAllStores } = discount;
    const { itemsNetAmount } = calculateItemsNetAmount(cart);

    // min order value validation
    if (discount.minOrderValue > itemsNetAmount) {
      reduxstore.dispatch(DiscountAction.setCoponValidation(false));
      reduxstore.dispatch(
        DiscountAction.validateDiscountFailure(
          i18n.t("Sorry, Basket total must be greater than or equal to") +
            discount.minOrderValue
        )
      );

      return {
        cartValue: itemsNetAmount,
        totalAmount: itemsNetAmount,
        success: 0,
        message:
          i18n.t("Sorry, Basket total must be greater than or equal to") +
          discount.minOrderValue,
      };
    }
    // selected store validation
    else if (
      isApplicableOnAllStores ||
      isApplicableOnSelectedStore(
        discount.discountAppliedOnSpecificStores,
        selectedStore
      )
    ) {
      // validate if discount is applicable on selected items + item is not in cart
      const isValidDiscountCode =
        validateIfSeletedItemsDiscountApplicableOnCart(
          cart,
          discount,
          itemsNetAmount
        );
      if (isValidDiscountCode?.success === 0) {
        return isValidDiscountCode;
      } else {
        const { newPrice, discountValue } = couponAppliedItems(
          cart,
          discount,
          itemsNetAmount
        );
        reduxstore.dispatch(DiscountAction.validateDiscountFailure(null));

        const discountVal =
          typeof discountValue === "number" ? discountValue.toFixed(2) : 0;

        reduxstore.dispatch(CartAction.setDiscountAmount(discountVal));
        reduxstore.dispatch(DiscountAction.setCoponValidation(true));
        return {
          cartValue: newPrice,
          totalAmount: itemsNetAmount,
          message: null,
          discountValue: discountVal,
          success: 1,
        };
      }
    }
    // discount not applicable on selected store
    else {
      reduxstore.dispatch(
        DiscountAction.validateDiscountFailure(
          i18n.t("Sorry, Provided coupon code is not applicable on this store")
        )
      );

      return {
        message: i18n.t(
          "Sorry, Provided coupon code is not applicable on this store"
        ),
        cartValue: itemsNetAmount,
        totalAmount: itemsNetAmount,
        success: 0,
      };
    }
  } else {
    const { itemsNetAmount } = calculateItemsNetAmount(cart);
    reduxstore.dispatch(DiscountAction.validateDiscountFailure(null));
    return {
      cartValue: itemsNetAmount,
      totalAmount: itemsNetAmount,
      message: null,
      success: 1,
    };
  }
};

/* ----------Start Cart Handling-------------------- */

//calculate reeedmin amount
export const calculateItemsNetAmount = (basket) => {
  let cartValue = 0;
  if (basket) {
    basket?.map((item) => {
      item?.itemSection?.map((section) => {
        return section?.options?.map((option) => {
          return (cartValue += option.price);
        });
      });
      return (cartValue += item.price * item.quantity);
    });
  }
  let SubTotal = Number(cartValue)?.toFixed(2);
  return { itemsNetAmount: Number(SubTotal), itemsCount: basket.length };
};
// calculate total order Gross amount
export const calculateTotalOrderAmount = (
  itemsGrossAmont,
  deliveryFee,
  taxRate
) => {
  if (
    typeof itemsNetAmount !== "number" ||
    typeof deliveryFee !== "number" ||
    typeof taxRate !== "number"
  ) {
  }
  const subtotal = Number(itemsGrossAmont + deliveryFee);
  let taxPercent = subtotal / 100;
  taxPercent = taxPercent * taxRate;
  const orderGrossAmount = Number(taxPercent + subtotal);
  if (typeof orderGrossAmount !== "number") {
    throw new Error("Invalid output: output should be a number.");
  }
  if (ifNullOrUndefined(orderGrossAmount)) {
    logErrorOnSentry(
      `Error in basket order price calculation ,orderGrossAmount is null or undefined ${orderGrossAmount}`,
      "error"
    );
  }
  return Number(orderGrossAmount.toFixed(2));
};
// calculating the cart items total + delivery fee + tax
export const calculateCartGrossAmount = (cart, discountInfo) => {
  const { loyaltyApplied, loyaltyAmount } = reduxstore.getState().Loyalty;
  const { itemsNetAmount } = calculateItemsNetAmount(cart);
  const { selectedStore } = reduxstore.getState().store;
  const taxRate = selectedStore?.taxRate || 0;
  const DeliveryFee = reduxstore.getState().cart.deliveryFee;
  if (loyaltyApplied) {
    let basketTotal = Number(
      max([itemsNetAmount - loyaltyAmount, 0])?.toFixed(2)
    );
    return {
      itemsNetPrice: itemsNetAmount,
      itemsGrossAmount: basketTotal,
      orderGrossAmount: calculateTotalOrderAmount(
        basketTotal,
        DeliveryFee,
        taxRate
      ),
    };
  } else {
    const { cartValue } = handleDiscount(cart, discountInfo, selectedStore);
    const basketGrossTotal = Number(cartValue)?.toFixed(2);
    return {
      itemsNetPrice: itemsNetAmount,
      itemsGrossAmount: Number(basketGrossTotal),
      orderGrossAmount: calculateTotalOrderAmount(
        cartValue,
        DeliveryFee,
        taxRate
      ),
    };
  }
};
/* ----------End  Cart Handling-------------------- */

export const getOrderStatus = (orderInfo) => {
  if (orderInfo) {
    const status = orderInfo?.orderStatus;
    if (
      status === "COMPLETE" ||
      status === "CANCELLED" ||
      status === "REJECTED"
    )
      return false;
    else return true;
  }
};

/* Formatting user phone Number  */
export const formatPhoneNumber = (number) => {
  let phoneNumber = number?.toString();
  if (phoneNumber === null || phoneNumber === undefined || phoneNumber === "")
    return null;
  if (phoneNumber?.charAt(0) === "0") {
    return phoneNumber?.slice(1);
  }
  return phoneNumber;
};

/* Validating user delivery Address*/
export const validateAddress = (fullAddress) => {
  const { mapAddress } = reduxstore.getState().DeliveryAddressReducer;
  let isValid = true;
  let errors = {};
  if (!mapAddress?.streetAddress?.trim()) {
    errors.streetAddress = i18n.t("Add the map address, please.");

    isValid = false;
  }
  if (!mapAddress?.city?.trim()) {
    errors.city = i18n.t("Add the map address, please.");
    isValid = false;
  }
  if (!mapAddress?.country?.trim()) {
    errors.country = i18n.t("Add the map address, please.");
    isValid = false;
  }
  if (!fullAddress?.addressType?.trim()) {
    errors.addressType = i18n.t("Please select address type");
    isValid = false;
  }
  if (!fullAddress?.address?.trim()) {
    errors.fullAddress = i18n.t("Please provide your complete address.");
    isValid = false;
  }
  if (fullAddress?.address && fullAddress?.address?.trim()?.length < 5) {
    errors.fullAddress = i18n.t(
      "Minimum length for the full address is five characters."
    );
    isValid = false;
  }
  return { isValid, errors };
};

export const checkedBox = (modifier, selectedGroups, modifierGroup) => {
  const checkedSection = selectedGroups?.find(
    (section) => section?._id === modifierGroup?._id
  );

  if (checkedSection) {
    const checkedOption = checkedSection.options.find(
      (option) => option._id === modifier._id
    );

    if (checkedOption) {
      return checkedOption?.quantity; // Return the checked option if it exists
    }
  }

  return null; // Return null if the modifier is not checked
};

export const isValidLocation = (mapAddress, zoom) => {
  if (mapAddress === null || mapAddress === undefined) {
    return {
      error: i18n.t("Please choose a location."),
      isValid: false,
    };
  }
  if (zoom <= 15) {
    return {
      error: i18n.t(
        "Please increase the map zoom level, and select accurate location, it helps the delivery boy in fast delivery."
      ),
      isValid: false,
    };
  }
  return {
    error: null,
    isValid: true,
  };
};

export const setThemeColour = (baseColor, textColor) => {
  document.documentElement.style.setProperty(
    "--brand-base-primary",
    "#" + baseColor
  );
  if (textColor) {
    document.documentElement.style.setProperty(
      "--brand-text-primary",
      "#" + textColor
    );
  }
};

export function checkIfDomainExists() {
  let domain = "";
  let id = "";
  try {
    const name = localStorage.getItem("brandInfo");
    if (name) {
      const b = JSON.parse(name);
      if (b?.setting?.temporaryDomainName) {
        domain = b?.setting?.temporaryDomainName;
      }
      if (b?.brand?._id) {
        id = b?.brand?._id;
      }
    }
    return { domain, id };
  } catch (error) {}
}
export function saveLanguageInformation(lang) {
  if (lang === "en") {
    const obj = {
      direction: "ltr",
      lang: "en",
    };
    localStorage.setItem(C.DIRECTION, JSON.stringify(obj));
  } else if (lang === "ar") {
    const obj = {
      direction: "rtl",
      lang: "ar",
    };
    localStorage.setItem(C.DIRECTION, JSON.stringify(obj));
  } else {
    const obj = {
      direction: "ltr",
      lang: "en",
    };
    localStorage.setItem(C.DIRECTION, JSON.stringify(obj));
  }
}

export const getLanguage = () => {
  const l =
    i18next.language || handleSessionStorage.getItem("i18nextLng") || "EN";
  if (l) {
    let lang = l.toUpperCase();
    return lang;
  } else {
    return "EN";
  }
};

export const storePlatFormInfo = (platform) => {
  let l = Object.keys(platform).length;
  if (platform?.platform && l >= 1) {
    sessionStorage.setItem("platform", platform.platform);
  }
};

export const toFixedNumber = (digit) => {
  if (ifNullOrUndefined(digit)) return 0;
  else return Number(digit)?.toFixed(2);
};

//GEETING BRAND DESCRTIOIN BASED ON VIEW TYPE
export const getStoreHeadetailDes = (viewType) => {
  const { AppReducer, store, brandStorageReducer } = reduxstore.getState();
  const { selectedStore } = store;
  const { brandInfo, setting } = brandStorageReducer;
  let isDescrp =
    selectedStore?.baseLanguage?.description?.trim() ||
    setting?.taglineEN?.trim();
  if (
    (viewType === C.ECOMMERCE || viewType === C.PREVIEW) &&
    AppReducer?.catalogueType === C.BRAND_BASED_CATALOGUE &&
    isDescrp
  ) {
    return selectedStore?.baseLanguage?.description || setting.taglineEN;
  }
  if (
    viewType === C.PREVIEW &&
    AppReducer?.catalogueType === C.OUTLET_BASED_CATALOGUE
  ) {
    return brandInfo?.setting?.taglineEN;
  }
  if (
    viewType === C.PREVIEW &&
    AppReducer?.catalogueType === C.OUTLET_BASED_CATALOGUE
  ) {
    return brandInfo?.setting?.taglineEN;
  }
  if (
    viewType === C.SERVICE_TYPE.DINE_IN &&
    AppReducer?.catalogueType === C.OUTLET_BASED_CATALOGUE &&
    isDescrp
  ) {
    return selectedStore?.baseLanguage?.description || setting.taglineEN;
  }
  return null;
};

export const checkISAppUpdated = () => {
  let savedVersion = sessionStorage.getItem("APP-version");
  let envVersion = process.env.REACT_APP_VERSION;
  if (envVersion < savedVersion) {
    Sentry.captureMessage("App version Not updated from CDN", {
      level: "error",
    });
  } else {
    sessionStorage.removeItem("APP-version");
  }
};

// export const getHeaders = () => {
//   var req = new XMLHttpRequest();
//   req.open("GET", document.location, false);
//   req.send(null);
//   var headers = req.getAllResponseHeaders().toLowerCase();
//   headers = headers.split(/\n|\r|\r\n/g).reduce(function (a, b) {
//     if (b.length) {
//       var [key, value] = b.split(": ");
//       a[key] = value;
//     }
//     return a;
//   }, {});
//   return headers;
// };

export const getHeaders = async () => {
  try {
    const response = await fetch(document.location);
    const headers = response.headers;

    const headersObject = {};
    headers.forEach((value, key) => {
      headersObject[key.toLowerCase()] = value;
    });

    return headersObject;
  } catch (error) {
    console.error("Error fetching headers:", error);
    return {};
  }
};

// CONVERT  ARABIC  NUMBERS to ENGLISH without decimal points
export function NumberConversion(input) {
  if (typeof input === "string") {
    input = input.trim();
    const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    let output = "";
    for (let i = 0; i < input.length; i++) {
      if (arabicNums.indexOf(input[i]) !== -1) {
        output += arabicNums.indexOf(input[i]);
      } else if (!isNaN(input[i])) {
        output += input[i];
      }
    }
    return output;
  } else {
    return input;
  }
}

// CONVERT  ARABIC  NUMBERS to ENGLISH with one decimal point
export function ARtoENPrice(input) {
  if (typeof input === "string") {
    input = input.trim();
    const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    let output = "";
    let decimalCount = 0;
    for (let i = 0; i < input.length; i++) {
      if (arabicNums.indexOf(input[i]) !== -1) {
        output += arabicNums.indexOf(input[i]);
      } else if (input[i] === "." || !isNaN(input[i])) {
        if (input[i] === ".") {
          decimalCount++;
          if (decimalCount > 1) continue;
        }
        output += input[i];
      }
    }
    if (output.includes(".")) {
      const decimalIndex = output.indexOf(".");
      output = output.substring(0, decimalIndex + 3);
    }
    return output;
  } else {
    return input;
  }
}

export const logErrorOnSentry = (message, level, extraDat) => {
  try {
    let user = UTILS.getCurrentUser();
    const cUser = reduxstore.getState()?.register;
    const brand = reduxstore.getState()?.brandStorageReducer?.brandInfo;
    Sentry.withScope((scope) => {
      scope.setLevel(level);
      scope.setExtra("extra-data", extraDat || "");
      scope.setExtras({
        localstorage: user,
        reduxState: cUser?.currentUser,
        entireRedux: JSON.stringify({
          cUser,
          brand,
        }),
      });
      Sentry.captureMessage(message, {
        level: level ?? "error",
        extra: {
          url: window.location.href,
          userAgent: navigator.userAgent,
        },
      });

      Sentry.setTags({
        app_version: process.env.REACT_APP_VERSION,
        app_name: C.WEB_STORE_FRONT,
      });
    });
  } catch (error) {}
};

// copying code to clipboard
export const copyToClipboard = (code = "", mesaage) => {
  copy(code, {
    format: "text/plain",
    onCopy: () => {
      successNotification(mesaage);
    },
  });
};

// getting brandInfo
export function getDataForBrand(brandId) {
  let storedData = localStorage.getItem("brandData");
  if (!storedData) {
    return null;
  }
  storedData = JSON.parse(storedData);
  return storedData[brandId] || null;
}

// updating brandInfo
export function updateDataForBrand(brandId, newData) {
  let storedData = localStorage.getItem("brandData");

  if (!storedData) {
    storedData = {};
  } else {
    storedData = JSON.parse(storedData);
  }

  if (storedData[brandId]) {
    storedData[brandId] = newData;
  } else {
    storedData[brandId] = newData;
  }
}

// updating new version
export const updatenew = () => {
  checkISAppUpdated();
  window.location.reload();
};

export function withTryCatch(callback) {
  return async (...args) => {
    try {
      await callback(...args);
    } catch (error) {
      console.error("Error:", error);
    }
  };
}

// validating single value
export const isValidValue = (val) => {
  if (
    val === "" ||
    val === null ||
    val === undefined ||
    val === "null" ||
    val === "undefined"
  ) {
    return false;
  }
  return true;
};

// validating object
export const ValidateParams = (params) => {
  for (const key in params) {
    if (!params.hasOwnProperty(key)) {
      continue;
    }

    const value = params[key];

    if (value === null || value === undefined || value === "") {
      console.error(`Invalid value for key "${key}": ${value}`);

      return false;
    }

    // You can add any other conditions to check for invalid values here.
    // For example:
    // if (typeof value !== 'string') {
    //   return false;
    // }
  }

  return true;
};

// creating a dumb obj for payment link redirection after making payment
export const getPaymentLinkRedirectionObj = ({
  token,
  cartId,
  brandId,
  DomainType,
  domainName,
}) => {
  let value = {};
  if (DomainType === C.TEMPORARAY_DOMAIN && domainName) {
    value = {
      token,
      cartId,
      brandId: brandId,
      successUrl: `${C.PAYMENT_REDIRECT_URL}${domainName}/payment-link/${cartId}/?paymentStatus=success`,
      failureUrl: `${C.PAYMENT_REDIRECT_URL}${domainName}/payment-link/${cartId}/?paymentStatus=failure`,
    };
    return value;
  } else if (DomainType === C.CUSTOM_DOMAIN) {
    value = {
      token,
      cartId,
      brandId: brandId,
      successUrl: `${C.PAYMENT_REDIRECT_URL}payment-link/${cartId}/?paymentStatus=success`,
      failureUrl: `${C.PAYMENT_REDIRECT_URL}payment-link/${cartId}/?paymentStatus=failure`,
    };
    return value;
  }
};

// scrolling to error
export const scrollToError = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element?.scrollIntoView({ behavior: "smooth" });
  }
};

//static service type array
const serviceTypeArray = [
  {
    _id: "619f7b95709b147c8c27e3ff",
    EN: {
      name: "DELIVERY",
    },
    AR: {
      name: "توصيل",
    },
    serviceType: "DELIVERY",
  },
  {
    _id: "62965a3ed09395fa5359ed1c",
    EN: {
      name: "PICKUP",
    },
    AR: {
      name: "يلتقط",
    },
    serviceType: "PICKUP",
  },
];

// creating service type array based on settings from brandInfo
export const createServiceTypeArray = (settings) => {
  let array = [];
  if (settings?.isDeliveryEnabled) {
    array.push(serviceTypeArray[0]);
  }
  if (settings?.isPickupEnabled) {
    array.push(serviceTypeArray[1]);
  }
  return array;
};

export const getCurrentUser = (id) => {
  const brandInfo = reduxstore.getState()?.brandStorageReducer.brandInfo;

  let ID = brandInfo?.brand?._id || id;
  if (ID) {
    const user = localStorage.getItem(ID);
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const checkStripeKey = (activeIntegrations) => {
  if (activeIntegrations && activeIntegrations?.length >= 1) {
    const stripe = activeIntegrations?.find(
      (ai) => ai.name === C.STRIPE && ai.isActive
    );
    if (stripe?.metaData?.publishableKey) {
      return stripe?.metaData?.publishableKey;
    } else return false;
  } else return false;
};

// checking if stripe key is available and setting it in redux store
export const setStripeKey = (activeIntegrations) => {
  if (checkStripeKey(activeIntegrations)) {
    const stripeKey = checkStripeKey(activeIntegrations);
    reduxstore.dispatch(BrandStorageAction.setStripeKey(stripeKey));
    return stripeKey;
  }
  return false;
};

// checking if @name  payment gateway is active
// Param name: name of the payment gateway

const isActiveIntegration = (activeIntegrations, name) => {
  if (activeIntegrations && activeIntegrations?.length >= 1) {
    const integration = activeIntegrations?.find(
      (ai) => ai.name === name && ai.isActive === true
    );
    if (integration) {
      return true;
    }
    return false;
  }
  return false;
};

// validating payment link
export function validatePaymentLink(brandinfo, paymentLink) {
  if (
    paymentLink?.paymentGateway === C.STRIPE &&
    isActiveIntegration(brandinfo?.activeIntegrations, C.STRIPE) &&
    checkStripeKey(brandinfo?.activeIntegrations)
  ) {
    return true;
  }
  if (
    paymentLink?.paymentGateway === C.CHECKOUT_DOT_COM &&
    isActiveIntegration(brandinfo?.activeIntegrations, C.CHECKOUT_DOT_COM)
  ) {
    return true;
  }
  logErrorOnSentry(
    "Invalid payment link  either payment gateway is not stripe or integration is not active",
    "info"
  );
  return false;
}

//Log Popup Error
export const logPopupError = (msg, modal) => {
  reduxstore.dispatch(
    ErrorAction.setError({
      error: msg || "Something went wrong",
      modal: modal || true,
    })
  );
};

export const logError = (msg, modal) => {
  reduxstore.dispatch(
    ErrorAction.setError({
      error: msg,
      modal: modal,
    })
  );
};

//checking if coupon is applicable on selected store
export const checkValidStrores = (discount, selectedStore) => {
  if (!selectedStore) return true;
  if (discount?.isApplicableOnAllStores) return true;
  if (discount?.discountAppliedOnSpecificStores?.length <= 0) return false;
  let flag = false;
  for (let i = 0; i < discount?.discountAppliedOnSpecificStores?.length; i++) {
    if (
      discount?.discountAppliedOnSpecificStores[i]?.storeId ===
      selectedStore?._id
    ) {
      flag = true;
      break;
    } else {
      flag = false;
    }
  }
  return flag;
};

//
const checkcouponServiceType = (discount, serviceName) => {
  const ValidType = discount?.serviceType?.some((item) =>
    serviceName?.serviceType?.includes(item?.serviceName)
  );
  return ValidType;
};

// getting filtered coupons based on selected store and service type and discount application type
export const getFilteredCoupons = (
  discounts,
  selectedStore,
  selectedServiceType
) => {
  const array = [];
  discounts?.forEach((discount) => {
    if (
      checkValidStrores(discount, selectedStore) &&
      checkcouponServiceType(discount, selectedServiceType) &&
      discount?.discountApplication !== C.COUPUN_APPLICATION_TYPE.HIDDEN
    ) {
      array.push(discount);
    }
  });
  return array;
};

export const getManulCopons = (
  discounts,
  selectedStore,
  selectedServiceType
) => {
  const array = [];
  discounts?.forEach((discount) => {
    if (
      checkValidStrores(discount, selectedStore) &&
      checkcouponServiceType(discount, selectedServiceType)
    ) {
      array.push(discount);
    }
  });
  return array;
};
//return the active shipping method data
export const getActiveShippingMethod = (shippingMethods) => {
  if (shippingMethods?.length > 0) {
    let active_method = shippingMethods?.find(
      (method) => method?.status === true
    );
    if (active_method?.methodName) {
      reduxstore.dispatch(
        AppAction.setShippingMethod(active_method?.methodName)
      );
      reduxstore.dispatch(
        AppAction.setShippingMethodTemp(active_method?.methodName)
      );
      return active_method;
    }
    return false;
  }
  return false;
};

// Ecommerce
// Returns the selected area info when user adds a new address
export const getSelectedAreaData = (shippingMethod, shippingAddress) => {
  let msg = {
    country: i18n.t("Please select a country"),
    city: i18n.t("Please select a city"),
    area: i18n.t("Please select an area"),
  };
  let obj = null;
  if (!shippingMethod) return false;
  if (shippingMethod === SHIPPING_METHODS.COUNTRY_BASED) {
    if (!shippingAddress?.country?.countryID?._id) {
      return {
        isValid: false,
        msg: msg.country,
      };
    } else {
      obj = {
        countryId: shippingAddress?.country?.countryID?._id,
      };
      return {
        isValid: true,
        obj,
        msg: null,
      };
    }
  } else if (shippingMethod === SHIPPING_METHODS.CITY_BASED) {
    if (
      !shippingAddress?.city?.cityID?._id ||
      !shippingAddress?.city?.countryID?._id
    ) {
      return {
        isValid: false,
        msg: msg.city,
      };
    } else {
      obj = {
        cityId: shippingAddress?.city?.cityID?._id,
        countryId: shippingAddress?.city?.countryID?._id,
      };
      return {
        isValid: true,
        obj,
        msg: null,
      };
    }
  } else if (shippingMethod === SHIPPING_METHODS.AREA_BASED) {
    if (
      !shippingAddress?.area?.areaInfo?._id ||
      !shippingAddress?.area?.areaInfo?.cityID ||
      !shippingAddress?.area?.areaInfo?.countryID
    ) {
      return {
        isValid: false,
        msg: msg.area,
      };
    } else {
      obj = {
        areaId: shippingAddress?.area?.areaInfo?._id,
        cityId: shippingAddress?.area?.areaInfo?.cityID,
        countryId: shippingAddress?.area?.areaInfo?.countryID,
      };
      return {
        isValid: true,
        obj,
        msg: null,
      };
    }
  }
};

// Returns the saved address area  info when user select an address from a saved  address list
export const getSavedAddressAreaINfo = (shippingMethod, shippingAddress) => {
  let msg = i18n.t(
    "Unfortunately, the store does not deliver to your location at this time"
  );
  let obj = null;
  if (!shippingMethod) {
    logErrorOnSentry(
      "brand based saved address   list shipping method is not available",
      "error"
    );
    return {
      isValid: false,
      msg: i18n.t(
        "There is no delivery method available in store, please contact the business"
      ),
    };
  }

  if (shippingMethod === SHIPPING_METHODS.COUNTRY_BASED) {
    if (!shippingAddress?.countryId) {
      logErrorOnSentry(
        "brand based saved address country id is not available or  differcence in  local shipping method and live shipping method",
        "error"
      );
      return {
        isValid: false,
        msg: msg,
      };
    } else {
      obj = {
        countryId: shippingAddress?.countryId,
      };
      return {
        isValid: true,
        obj,
        msg: null,
      };
    }
  } else if (shippingMethod === SHIPPING_METHODS.CITY_BASED) {
    if (!shippingAddress?.cityId || !shippingAddress?.countryId) {
      logErrorOnSentry(
        "brand based saved address city id is not available or  differcence in  local shipping method and live shipping method",
        "error"
      );

      return {
        isValid: false,
        msg: msg,
      };
    } else {
      obj = {
        cityId: shippingAddress?.cityId,
        countryId: shippingAddress?.countryId,
      };
      return {
        isValid: true,
        obj,
        msg: null,
      };
    }
  } else if (shippingMethod === SHIPPING_METHODS.AREA_BASED) {
    if (
      !shippingAddress?.areaId ||
      !shippingAddress?.cityId ||
      !shippingAddress?.countryId
    ) {
      logErrorOnSentry(
        "brand based saved address area id is not available or  differcence in  local shipping method and live shipping method",
        "error"
      );
      return {
        isValid: false,
        msg: msg,
      };
    } else {
      obj = {
        areaId: shippingAddress?.areaId,
        cityId: shippingAddress?.cityId,
        countryId: shippingAddress?.countryId,
      };
      return {
        isValid: true,
        obj,
        msg: null,
      };
    }
  }
};

// format the delivery address  - adding comma between address fields
export const getFormattedAddress = (address) => {
  if (address) {
    let add = "";
    add += address?.address ? address?.address : "";
    if (address?.address && address?.apartmentNo) {
      add += ", ";
    }
    add += address?.apartmentNo
      ? i18next.t("Apartment Number") + " " + address?.apartmentNo
      : "";
    if ((address?.address || address?.apartmentNo) && address?.floor) {
      add += ", ";
    }
    add += address?.floor ? i18next.t("Floor") + " " + address?.floor : "";
    if (
      (address?.address || address?.apartmentNo || address?.floor) &&
      address?.building
    ) {
      add += ", ";
    }
    add += address?.building
      ? i18next.t("Building") + " " + address?.building
      : "";
    return add;
  } else return null;
};

export const togglePaymentType = (type) => {
  const OnlineMethods = ["applePay", "googlePay", "new_card", "saved_card"];
  const selectedPaymentType = reduxstore.getState()?.PaymentMethodReducer;
  const appReducer = reduxstore.getState()?.AppReducer;
  const storeSlice = reduxstore.getState()?.store;

  const isActivePaymentGateway = isActiveAnyPaymentGateway();
  if (!isActivePaymentGateway) return;
  console.log("selectedPaymentType", storeSlice?.selectedStore);
  if (appReducer?.orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
    const isOnLinePaymentActive =
      storeSlice?.selectedStore?.paymentOptions?.find(
        (payment) => payment.paymentType === C.CREDIT_CARD
      );
    if (!isOnLinePaymentActive) {
      return;
    }
  }
  if (appReducer?.orderType?.serviceType === C.SERVICE_TYPE.PICKUP) {
    const isOnLinePaymentActive =
      storeSlice?.selectedStore?.pickupPaymentOptions?.find(
        (payment) => payment.paymentType === C.CREDIT_CARD
      );
    if (!isOnLinePaymentActive) {
      return;
    }
  }

  if (appReducer?.orderType?.serviceType === C.SERVICE_TYPE.DINE_IN) {
    const isOnLinePaymentActive =
      storeSlice?.selectedStore?.dineInPaymentOptions?.find(
        (payment) => payment.paymentType === C.CREDIT_CARD
      );
    if (!isOnLinePaymentActive) {
      return;
    }
  }

  const validate = isValidValue(selectedPaymentType?.selectedPaymentType);
  if (!validate) {
    reduxstore.dispatch(PaymentMethodAction.setSelectedPaymentType(type));
    if (OnlineMethods.includes(type)) {
      const creditCardObj = getCreditCardObjByOrderType(
        appReducer?.orderType,
        storeSlice?.selectedStore
      );
      reduxstore.dispatch(StoreAction.setSelectedPaymentMethod(creditCardObj));
    }
  }
};
export const resetPaymentType = () => {
  reduxstore.dispatch(PaymentMethodAction.setSelectedPaymentType(null));
};

export const showErrors = (errors) => {
  const error = Object.values(errors).find((value) => value !== null);
  if (error) {
    logError(errorHandler(error), true);
  }
};

export const getCreditCardObjByOrderType = (
  selectedOrderType,
  selectedStore
) => {
  if (!selectedOrderType || !selectedStore) return;
  if (selectedOrderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
    const onlinePayment = selectedStore?.paymentOptions?.find(
      (item) => item?.paymentType === C.CREDIT_CARD
    );
    return onlinePayment;
  } else if (selectedOrderType?.serviceType === C.SERVICE_TYPE.PICKUP) {
    const onlinePayment = selectedStore?.pickupPaymentOptions?.find(
      (item) => item?.paymentType === C.CREDIT_CARD
    );
    return onlinePayment;
  } else if (selectedOrderType?.serviceType === C.SERVICE_TYPE.DINE_IN) {
    const onlinePayment = selectedStore?.dineInPaymentOptions?.find(
      (item) => item?.paymentType === C.CREDIT_CARD
    );
    return onlinePayment;
  } else {
    logErrorOnSentry("invalid service type selected", "error");
  }
};

export const getModifierDumbObj = (mod) => {
  const obj = {
    _id: mod?._id,
    optionId: mod?._id,
    price: mod?.price,
    ...(mod?.externalData && {
      externalData: mod?.externalData,
    }),
    quantity: mod?.quantity,
    baseLanguage: {
      optionName: mod?.baseLanguage?.name,
    },
    sku: mod?.sku ? mod?.sku : null,
    translations: {
      en: {
        optionName: mod?.baseLanguage?.name,
      },
      ar: {
        optionName: mod?.baseLanguage?.name,
      },
    },
  };
  return obj;
};

export const getStoreAndItemStatusText = (item, selectedStore) => {
  if (!selectedStore?._id || !selectedStore?.isOpened)
    return i18n.t("Store is closed");
  else if (isItemOutOfStock(item, selectedStore))
    return i18n.t("This item is out of stock, sorry for inconvenience");
  else return null;
};

export const isItemOutOfStock = (item, selectedStore) => {
  if (!item) return true;
  const unavailableIndex = findIndex(
    item?.unavailableStores,
    (store) => store?.storeId === selectedStore?._id
  );
  if (
    unavailableIndex >= 0 &&
    (item?.unavailableStores[unavailableIndex]?.nextAvailableTime >
      moment.utc().unix() ||
      !item?.unavailableStores[unavailableIndex]?.nextAvailableTime)
  ) {
    return true; //item is out of stock
  }
  return false; ///item is in stock
};

export const isModifierOutOfStock = (modifer, selectedStore) => {
  if (!modifer) return true;
  const unavailableIndex = findIndex(
    modifer?.unavailableStores,
    (store) => store?.storeId === selectedStore?._id
  );
  if (
    unavailableIndex >= 0 &&
    (modifer?.unavailableStores[unavailableIndex]?.nextAvailableTime >
      moment.utc().unix() ||
      !modifer?.unavailableStores[unavailableIndex]?.nextAvailableTime)
  ) {
    return true;
  }
  return false;
};

export const ValidateGeoLocation = (
  isGeoLocationAdded,
  mapAddress,
  userLocation
) => {
  if (
    isGeoLocationAdded &&
    mapAddress?.streetAddress &&
    userLocation?.coordinates?.lat &&
    userLocation?.coordinates?.lng
  ) {
    return true;
  }
  return false;
};

export const validateZoom = (googlemapState) => {
  if (googlemapState?.zoom <= 15) {
    return {
      error: {
        msg: i18n.t(
          "Please increase the map zoom level, and select accurate location, it helps the delivery boy in fast delivery."
        ),
        type: "zoom",
      },

      isValid: false,
    };
  }
  return {
    error: null,
    isValid: true,
  };
};
//is valid street address
export const isValidStreetAddress = (streetAddres, googlemapState) => {
  let isValid = true;
  let error = {
    msg: null,
    type: "streetAddress",
  };
  if (
    !googlemapState?.userLocation?.coordinates?.lat ||
    !googlemapState?.userLocation?.coordinates?.lng
  ) {
    isValid = false;
    error = {
      msg: i18n.t("Please make sure to type an address in the search bar"),
      type: "streetAddress",
    };
  }

  return {
    error,
    isValid,
  };
};

export const isIntegrationActive = (name) => {
  const activeIntegrations =
    reduxstore.getState()?.brandStorageReducer?.brandInfo?.activeIntegrations;

  const isActive = activeIntegrations?.find(
    (ai) => ai.name === name && ai.isActive
  );
  if (isActive) {
    if (name === C.STRIPE) {
      if (isActive?.metaData?.publishableKey) {
        return true;
      }
      return false;
    }
    return true;
  }
  return false;
};

export const isActiveAnyPaymentGateway = () => {
  const alowedValues = [C.TELR, C.STRIPE, C.CHECKOUT_DOT_COM];
  const isActive = alowedValues.some((name) => isIntegrationActive(name));
  return isActive;
};
