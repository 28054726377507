import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UTILS } from "utils";
import C from "Constants";
function StoreHeaderDetail({ page, brandInfo, description, headerTitle }) {
  const { selectedStore } = useSelector((state) => state?.store);
  const getDesc = useMemo(
    () => UTILS.getStoreHeadetailDes(description),
    [brandInfo, selectedStore, description]
  );
  const pageBrand = page === "brand" ? true : false;
  const params = useParams();
  return (
    <div
      className={`${pageBrand === true ? "pt-5" : "py-5"
        } px-4 bg-white flex justify-between flex-col`}
    >
      <div className="flex justify-between">
        {description === C.SERVICE_TYPE.DINE_IN ? <h1 className="font-nunito text-1.5 font-extrabold text-black mb-1">
          {headerTitle ? headerTitle : params?.domainName}
        </h1> :
          <h1 className="font-nunito text-1.5 font-extrabold text-black mb-1">
            {brandInfo ? brandInfo?.brand?.brandName : params?.domainName}
          </h1>
        }
      </div>

      <div className="p-3 bg-grey-green-off rounded text-sm leading-normal text-black mt-4">
        {getDesc ? getDesc : params?.domainName}
      </div>
    </div>
  );
}

export default StoreHeaderDetail;
