import React, { useState } from "react";
import MapAreaList from "./MapAreaList";
const MapCityList = ({ city, opened, lang }) => {
  const [open, setOpen] = useState(false);
  const setOpenState = () => setOpen(!open);

  return (
    <div onClick={setOpenState} className="cursor-pointer ">
      <h2 className="flex mx-4 py-4 items-center justify-between text-16x font-semibold border-b border-area_heading-b">
        {
          <span className="text-outer-space">
            {lang === "EN" ? city?.city?.en : city?.city?.ar}
          </span>
        }

        <svg
          viewBox="0 0 24 24"
          className={`h-18x w-18x transform  text-brand-base-primary
                    ${open || opened ? "rotate-180" : ""}
                  `}
        >
          <path
            fill="CurrentColor"
            d="M4.88398 7.11612L3.11621 8.88389L12.0001 17.7678L20.884 8.88389L19.1162 7.11612L12.0001 14.2322L4.88398 7.11612Z"
          ></path>
        </svg>
      </h2>

      <ul className="bg-area-bg cursor-pointer">
        {open || opened ? (
          <MapAreaList key={city?.city?.en} area={city} lang={lang} />
        ) : null}
      </ul>
    </div>
  );
};

export default MapCityList;
