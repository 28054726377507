import React from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import PageHeader from "components/PageHeader";
import FixedFooter from "components/FixedFooter";
import WithMap from "components/Hoc/WithMap";
import { ErrorModal, CustomModal } from "components";
import { useCustomHistory } from "hooks";
import { useMapContext } from "contexts/googleMap";

function AddGoogleMapLocation({
  checkoutPageUrl,
  handleConfirmLocation,
  error,
  open,
  setOpen,
  mapref,
  setMapRef,
  googlemapState,
}) {
  const params = useParams();
  const router = useCustomHistory();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { setZoom } = useMapContext();

  const goToCheckoutPage = () => {
    router(
      checkoutPageUrl,
      `/${params?.domainName}${checkoutPageUrl}`,
      DomainType
    );
  };
  const handleClik = () => {
    setOpen(false);
    if (error?.type === "zoom") {
      setZoom(16);
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-full store-page-popup">
        <PageHeader
          pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full relative"
          title={t("Set Delivery Location")}
          page="selectMap"
          url={true}
          handleClick={goToCheckoutPage}
        />
        <p className="text-14x text-gunsmoke leading-19x mb-2 mx-6">
          {t("Mark the exact location to help our driver deliver faster")}
        </p>
        <div className="bg-gray-100 relative " style={{ flex: 1 }}>
          <div className="relative min-h-full" style={{ flex: 1 }}>
            <WithMap
              class="map-height"
              screenName={null}
              width="100%"
              height="100%"
              showInput={true}
              zoomChanging={true}
              setMapRef={setMapRef}
              mapref={mapref}
              googlemapState={{
                userLocation: {
                  lat: googlemapState?.userLocation?.coordinates?.lat,
                  lng: googlemapState?.userLocation?.coordinates?.lng,
                },
                zoom: googlemapState?.zoom,
                mapAddress: googlemapState?.mapAddress,
              }}
              zoomerror={error?.msg || error}
              isZoomModalOpen={error?.type === "zoom" ? open : false}
              setZoomErrorModal={setOpen}
            />
          </div>

          <FixedFooter
            page={false}
            from="selec location"
            screen="confirmLocation"
          >
            <span className="block w-full">
              <button
                onClick={handleConfirmLocation}
                className="block text-center py-4 rounded text-brand-text-primary bg-brand-base-primary cursor-pointer text-16x w-full"
              >
                <span className="">
                  <div className="">
                    <span className="font-bold">{t("Confirm Location")}</span>
                  </div>
                </span>
              </button>
            </span>
            <CustomModal
              open={error?.type !== "zoom" ? open : false}
              setOpen={setOpen}
              bodyclasses="flex"
              overlayClasses="  fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
            >
              <ErrorModal
                message={error?.msg || error}
                btnTitle={error?.type === "zoom" ? t("Increase zoom") : null}
                handleClik={handleClik}
              />
            </CustomModal>
          </FixedFooter>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddGoogleMapLocation;
