import React from "react";
import C from "Constants";
import { useSelector } from "react-redux";
import { Route, Redirect, useParams } from "react-router-dom";
import * as ROUTE_CONSTANTS from "routes/constants";
import { UTILS } from "utils";

const ProtectedRoute = ({ component: Component, isAuthProtected, ...rest }) => {
  let params = useParams();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const currentUser = UTILS.getCurrentUser(brandInfo?.brand?._id);

  const { DomainType } = useSelector((state) => state.AppReducer);

  let pathname = ROUTE_CONSTANTS.OUTLET_LOGIN[1];
  if (DomainType !== C.CUSTOM_DOMAIN) {
    if (brandInfo?.setting?.temporaryDomainName) {
      pathname = `/${brandInfo?.setting?.temporaryDomainName}${pathname}`;
    } else if (params?.domainName) {
      pathname = `/${params?.domainName}${pathname}`;
    } else if (rest?.location?.pathname) {
      pathname = `${rest.location?.pathname}${pathname}`;
    } else {
      const splitPath = window?.location?.pathname?.split("/");
      const domainName = splitPath[1];
      pathname = `/${domainName}${pathname}`;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !currentUser?.token) {
          return (
            <Redirect
              to={{
                pathname,
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
