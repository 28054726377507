const generateEventId = () => {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

const getFacebookBrowserData = () => {
  let fbp = document.cookie
    .split(";")
    .filter((c) => c.includes("_fbp="))
    .map((c) => c.split("_fbp=")[1]);
  let fbc = document.cookie
    .split(";")
    .filter((c) => c.includes("_fbc="))
    .map((c) => c.split("_fbc=")[1]);
  fbp = (fbp.length && fbp[0]) || null;
  fbc = (fbc.length && fbc[0]) || null;

  if (!fbc && window.location.search.includes("fbclid=")) {
    fbc =
      "fb.1." + +new Date() + "." + window.location.search.split("fbclid=")[1];
  }

  return {
    fbp: fbp,
    fbclid: fbc,
    user_agent: navigator.userAgent,
    url: window.location.origin + window.location.pathname,
  };
};

const CAPI = (event, eventData, eventID) => {
  const { fbp, fbc, user_agent, url } = getFacebookBrowserData();

  const body = {
    event: event,
    event_id: eventID,
    event_data: eventData,
    fbp: fbp,
    fbclid: fbc,
    user_agent: user_agent,
    url: url,
  };
};

export const triggerFacebookEvent = (event, customData = {}, eventMeta) => {
  const eventID = generateEventId();
  const serverSideTracking = false;

  if (typeof window.fbq !== "undefined") {
    if (eventMeta.isCustomEvent) {
      window.fbq("trackCustom", event, customData, {
        eventID: eventID,
      });
    } else {
      window.fbq("track", event, customData, {
        eventID: eventID,
      });
    }
  }

  if (serverSideTracking) {
    CAPI(event, customData, eventID);
  }
};
