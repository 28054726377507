import React from "react";
function MapLocationIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      data-spec="icon-locate"
      className="w-4 h-4 text-brand-base-primary"
    >
      <path
        d="M8.333 11a2.667 2.667 0 105.333 0 2.667 2.667 0 00-5.333 0z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M4.333 11a6.667 6.667 0 1013.334 0 6.667 6.667 0 00-13.334 0v0zM11 1v3.333M1 11h3.333M11 21v-3.333M21 11h-3.333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
}
export default MapLocationIcon;
