import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storeModal: false,
  areaModal: false,
  outOfStockModal: false,
  discountModal: false,
  orderSuccess: false,
  requiredMissingSections: [],
  pageLoaded: true,
  refreshApp: false,
  isInternetConnected: true,
  orderFailureModal: false,
  storeListModal: false,
  pickupStoreModal: false,
  applicableItemsDiscountListModal: {
    isOpen: false,
    data: [],
  },
};

const notPersited = createSlice({
  name: "notPersited",
  initialState,
  reducers: {
    setModalOpen: (state) => {
      state.storeModal = true;
    },
    setModalClose: (state) => {
      state.storeModal = false;
    },
    togglePickupStoreModal: (state, action) => {
      state.pickupStoreModal = action.payload;
    },
    toggleAreaModal: (state, action) => {
      state.areaModal = action.payload;
    },

    setorderFailure: (state, action) => {
      state.orderFailureModal = action.payload;
    },
    setPageLoaded: (state, action) => {
      state.pageLoaded = action.payload;
    },

    //
    toggleDiscountModal: (state, action) => {
      state.discountModal = action.payload;
    },
    toggleLoyaltyPoints: (state) => {
      state.loyaltyPoints = !state.loyaltyPoints;
    },
    toggleOrderPlaceModel: (state, action) => {
      state.orderSuccess = action.payload;
    },

    setRequiredMissingSections: (state, action) => {
      state.requiredMissingSections = action.payload;
    },

    refreshApp: (state, action) => {
      state.refreshApp = true;
    },
    setInternetOnline: (state, action) => {
      state.isInternetConnected = true;
    },
    setInternetOffline: (state, action) => {
      state.isInternetConnected = false;
    },
    setapplicableItemsDiscountListModal: (state, action) => {
      state.applicableItemsDiscountListModal = action.payload;
    },
  },
});

export const {
  setapplicableItemsDiscountListModal,
  toggleAreaModal,
  setorderFailure,
  setInternetOffline,
  setInternetOnline,
  setPageLoaded,
  setModalClose,
  setModalOpen,
  toggleDiscountModal,
  toggleOrderPlaceModel,
  setRequiredMissingSections,
  refreshApp,
  togglePickupStoreModal,
} = notPersited.actions;

export default notPersited.reducer;
