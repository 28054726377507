import React from "react";
import App from "./App";
import { useOffLine } from "hooks";
import useSyncTabs from "hooks/useSyncTabs";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import routes from "routes/Routes";
import {
  ReportingObserver as ReportingObserverIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  Debug as DebugIntegration,
} from "@sentry/integrations";
import { matchPath } from "react-router-dom";
import { UTILS } from "utils";
import LogRocket from "logrocket";
import CONSTANTS from "Constants";
import { shouldTrackSessions } from "utils/Utils";

import { useSelector } from "react-redux";
import moment from "moment";
function Index() {
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  moment.locale(brandInfo?.brand?.baseLanguage || "en");

  const history = createBrowserHistory();
  useOffLine();
  useSyncTabs();

  shouldTrackSessions() &&
    LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new Integrations.BrowserTracing(),
      new ReportingObserverIntegration(),
      new ExtraErrorDataIntegration(),
      // new HttpClientIntegration(),

      new DebugIntegration(),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        ),
        // tracePropagationTargets: ["traceparent", "tracestate"],
      }),
    ],
    tracesSampleRate: 1.0,
    // maxBreadcrumbs: 50,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
    attachStacktrace: true,
    debug: false,
    normalizeDepth: 6,
    environment: process.env.REACT_APP_ENV,

    ignoreErrors: ["ReportingObserver", "Deprecation"],
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error && error.message && error.message.match(/ReportingObserver/i)) {
        return null;
      }
      return event;
    },
  });

  let user = UTILS.getSentryUserObj();
  const { email, id, username } = user || {};

  shouldTrackSessions() &&
    LogRocket.identify(id, {
      email,
      userId: id,
      brandId: username,
      appVersion: process.env.REACT_APP_VERSION,
      appName: CONSTANTS.WEB_STORE_FRONT,
      userPhone: email,
    });
  Sentry.setUser(user);
  window.external_SENTRY = Sentry;
  return (
    <div id="__layout">
      <div className="system-container">
        <App />
      </div>
    </div>
  );
}

export default Sentry.withProfiler(Index);
