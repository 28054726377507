import Confetti from "react-confetti";
import React from "react";
import { useWindowDimensions } from "hooks";

function ConfettiC({ modalHeight }) {
  const { height, width } = useWindowDimensions();

  return (
    <Confetti
      key={modalHeight}
      width={width}
      height={height - parseInt(modalHeight)}
      numberOfPieces={300}
      gravity={0.5}
    />
  );
}

export default ConfettiC;
