import React, { useState } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useLang, useUserWallet } from "hooks";
import useCurrentUser from "hooks/useCurrentUser";
import AddUserInfoForm from "components/Modals/AddUserInfoForm";
const SettingLinks = ({ handleAllOrders, handleLogout }) => {
  const params = useParams();
  const userWallet = useUserWallet();

  const { currentLang } = useLang();
  const { currentUser } = useCurrentUser();
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const [isOpenUserInfoModal, setUserInfoModal] = useState(false);
  const openUserProfileModal = () => {
    setUserInfoModal(!isOpenUserInfoModal);
  };

  return (
    <div className="store-page-popup">
      <div className="px-5">
        <h2 className="text-26x text-black">
          {t("Hey")}, <span dir="ltr">{currentUser?.user?.phoneNumber}</span>
        </h2>
        <p className="text-14x text-gray-400 mt-1">
          {params && t("This is your account for")}
          <span className="px-2">
            {brandInfo?.brand?.brandName
              ? brandInfo?.brand?.brandName
              : params?.domainName}
          </span>
        </p>

        <div
          className="basket-item flex justify-between items-start flex-row bg-white pt-4 cursor-pointer"
          onClick={handleAllOrders}
        >
          <div className="text-14x">{t("Order history")}</div>
          <div className="">
            <svg
              viewBox="0 0 24 24"
              className={`h-4 w-4 transform  ${
                currentLang === "AR" ? "rotate-90" : "-rotate-90"
              }`}
            >
              <path
                fill="#abadad"
                d="M4.88398 7.11612L3.11621 8.88389L12.0001 17.7678L20.884 8.88389L19.1162 7.11612L12.0001 14.2322L4.88398 7.11612Z"
              ></path>
            </svg>
          </div>
        </div>
        <hr className="my-5 border-t border-gray-100" />
        <div className="basket-item flex justify-between items-start flex-row bg-white">
          <div className="text-14x">
            {t("Wallet balance", { walletBalance: userWallet.toFixed(2) })}
          </div>
        </div>
        <hr className="my-5 border-t border-gray-100" />
        <div
          className="basket-item flex justify-between items-start flex-row bg-white cursor-pointer"
          onClick={openUserProfileModal}
        >
          <div className="text-14x">{t("Update profile")}</div>
        </div>
        <hr className="my-5 border-t border-gray-100" />
        <div className="basket-item flex justify-between items-start flex-row bg-white">
          <div className="text-14x cursor-pointer" onClick={handleLogout}>
            {t("Logout")}
          </div>
        </div>
      </div>
      <AddUserInfoForm
        {...{ isOpenUserInfoModal, setUserInfoModal }}
        closeOnBgClick={() => setUserInfoModal(false)}
      />
    </div>
  );
};

export default SettingLinks;
