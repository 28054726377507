import React from "react";
import { t } from "i18next";
import { SVGICONS } from "config";
import { CHECKED, UNCHECKED, PlusSquare, MinusSquare } from "icons";
import { useLang } from "hooks";
import { UTILS } from "utils";
import C from "Constants";
import { errorNotification } from "utils/Utils";
import { useSelector } from "react-redux";
import OutOfStockModifer from "./OutOfStockModifer";
import { isModifierOutOfStock } from "utils/Utils";

const SingleModifer = ({
  modifer,
  selectedModifierGroups,
  radioButtonClick,
  checkboxClick,
  modifierGroup,
  QuantityChange,
  updateRadioQuantity,
  isStoreSelected,
}) => {
  const { isSingleSelection } = modifierGroup;
  const { currentLang } = useLang();
  const addedQunatity = UTILS.checkedBox(
    modifer,
    selectedModifierGroups,
    modifierGroup
  );
  const RenderRadioBoxes = ({ modifer }) => {
    return (
      <span key={modifer?._id} className="inline-flex w-6 h-6">
        <span className="w-6 h-6 inline-flex ">
          {addedQunatity ? <SVGICONS.RadioCheck /> : <SVGICONS.RadioUnCheck />}
        </span>
      </span>
    );
  };

  const RenderCheckBoxes = ({ modifer }) => {
    return (
      <span key={modifer?._id} className="inline-flex w-6 h-6">
        <span className="w-6 h-6 inline-flex ">
          {addedQunatity ? <CHECKED /> : <UNCHECKED />}
        </span>
      </span>
    );
  };

  const IncremntCheckBoxQuntity = (addedQunatity, modifer, grpId, total) => {
    if (addedQunatity < total) QuantityChange(modifer, grpId, "increment");
    else {
      errorNotification(
        t("is the maximum quantity allowed for", {
          quantity: modifer?.maximumOrderQuantity,
          name: modifer?.baseLanguage?.name,
        }),
        "top-center"
      );
    }
  };

  const IncremntRadioBoxQuntity = (addedQunatity, modifer, grpId, total) => {
    if (addedQunatity < total) updateRadioQuantity(modifer, grpId, "increment");
    else
      errorNotification(
        t("is the maximum quantity allowed for", {
          quantity: modifer?.maximumOrderQuantity,
          name: modifer?.baseLanguage?.name,
        }),
        "top-center"
      );
  };
  const { selectedStore } = useSelector((state) => state.store);

  const isModifierOutOFStock = isModifierOutOfStock(modifer, selectedStore);
  if (isModifierOutOFStock || isStoreSelected === false)
    return (
      <OutOfStockModifer
        {...{
          modifer,
          isSingleSelection,
          isStoreSelected,
          isModifierOutOFStock,
        }}
      />
    );

  return (
    <div key={modifer?._id}>
      <span className="inline-flex flex-grow w-full">
        <div className="flex flex-grow  w-full">
          <div
            className="flex-grow flex-shrink inline-flex items-center"
            onClick={
              isSingleSelection === false
                ? () => checkboxClick(modifer, modifierGroup?._id)
                : () => radioButtonClick(modifer, modifierGroup?._id)
            }
          >
            <div className="flex flex-1 items-center py-3">
              <div className="flex">
                {isSingleSelection === false ? (
                  <RenderCheckBoxes {...{ modifer }} />
                ) : (
                  <RenderRadioBoxes {...{ modifer }} />
                )}
              </div>
              <div className="flex flex-1 items-center">
                <div className="flex flex-1 flex-col px-1.5">
                  <p className="text-16x text-outer-space">
                    {modifer?.baseLanguage?.name}
                  </p>
                  <p className="text-13x text-gunsmoke">
                    +
                    {currentLang === C.AR
                      ? modifer?.price?.toFixed(2) + " " + t("AED")
                      : t("AED") + " " + modifer?.price?.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            {modifer?.maximumOrderQuantity >= 2 ? (
              <span className="ms-2">
                <div className="inline-flex center relative flex-flex-4">
                  <span
                    className="inline-flex mx-1"
                    onClick={
                      isSingleSelection === false
                        ? () =>
                            QuantityChange(
                              modifer,
                              modifierGroup?._id,
                              "decrement"
                            )
                        : () =>
                            updateRadioQuantity(
                              modifer,
                              modifierGroup?._id,
                              "decrement"
                            )
                    }
                  >
                    <button className="" type="button">
                      <div className="">
                        <span>
                          <MinusSquare />
                        </span>
                      </div>
                    </button>
                  </span>
                  <span className="inline-flex self-center en-font">
                    <span className="text-20x mx-2">
                      {addedQunatity ? addedQunatity : 0}
                    </span>
                  </span>

                  <span
                    className="inline-flex mx-1"
                    onClick={
                      isSingleSelection === false
                        ? () =>
                            IncremntCheckBoxQuntity(
                              addedQunatity,
                              modifer,
                              modifierGroup?._id,
                              modifer?.maximumOrderQuantity
                            )
                        : () =>
                            IncremntRadioBoxQuntity(
                              addedQunatity,
                              modifer,
                              modifierGroup?._id,
                              modifer?.maximumOrderQuantity
                            )
                    }
                  >
                    <button
                      className=""
                      type="button"
                      aria-label="Increase Quantity"
                    >
                      <div className="">
                        <span className="">
                          <PlusSquare />
                        </span>
                      </div>
                    </button>
                  </span>
                </div>
              </span>
            ) : null}
          </div>
        </div>
      </span>
    </div>
  );
};

export default React.memo(SingleModifer);
