import React from "react";
import { t } from "i18next";
import { useLang } from "../hooks";
function PageHeaderWithButton(props) {
  const { currentLang } = useLang();
  const { title, pageHeaderClasses, redirectToStorePage } = props;
  const url = props.url ? props.url : null;

  return (
    <div className={pageHeaderClasses}>
      <div className="flex py-5 justify-between w-full">
        <div className="mx-2 pys-5 absoslute">
          {url ? (
            <button type="button" onClick={props.handleClick}>
              <span className="rounded-full flex items-center border border-white justify-center bg-circle p-2 hover:border hover:border-brand-base-primary cursor-pointer">
                <svg
                  viewBox="0 0 24 24"
                  className={`h-4 w-4 transform  text-brand-base-primary ${
                    currentLang === "AR" ? "-rotate-90" : "rotate-90"
                  }`}
                >
                  <path
                    fill="currentColor"
                    d="M4.88398 7.11612L3.11621 8.88389L12.0001 17.7678L20.884 8.88389L19.1162 7.11612L12.0001 14.2322L4.88398 7.11612Z"
                  ></path>
                </svg>
              </span>
            </button>
          ) : (
            <div>
              <button type="button" onClick={props.handleClick}>
                <span className="rounded-full flex items-center border border-white justify-center bg-circle p-2 hover:border hover:border-brand-base-primary cursor-pointer">
                  <svg
                    viewBox="0 0 24 24"
                    className="h-4 w-4 text-brand-base-primary"
                  >
                    <path
                      fill="currentColor"
                      d="M12.0001 10.2322L5.88398 4.11612L4.11621 5.88389L10.2323 12L4.11621 18.1161L5.88398 19.8839L12.0001 13.7678L18.1162 19.8839L19.884 18.1161L13.7679 12L19.884 5.88389L18.1162 4.11612L12.0001 10.2322Z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          )}
        </div>
        <div className="text-18x flex items-center xs:text-22x font-bold text-outer-space leading-28x text-center">
          {title}
        </div>
        <div className="flex items-center">
          <span
            onClick={redirectToStorePage}
            className="py-0 rounded px-2 text-brand-base-primary border border-brand-base-primary text-14x cursor-pointer"
          >
            {t("Add more items")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default PageHeaderWithButton;
