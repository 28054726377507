import React, { useState } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { Button } from "antd";
import { useParams } from "react-router-dom";

import Menu from "../../menu/containers";

import Search from "components/Search";
import UserLogo from "components/UserLogo";
import ImageHeader from "components/ImageHeader";
import WithLoading from "components/Hoc/WithLoading";
import { NotFound, ErrorComponent } from "components";
import CategoriesList from "components/menu/CategoriesList";
import LoginSignupButton from "components/LoginSignupButton";
import StoreHeaderDetail from "components/StoreHeaderDetail";
import StoreCategoryWithMenu from "components/menu/StoreCategoryWithMenu";
import DiscountHomeContainer from "components/discounts/DiscountHomeContainer";
import LoyaltyPolicyWidget from "components/brand-loyalty/LoyaltyPolicyWidget";

import useCurrentUser from "hooks/useCurrentUser";
import useQueryParam from "modules/dine-in/useQueryParam";
import { useMenuSearch, usePlatform, useCustomHistory } from "hooks";

import C from "Constants";
import { isItemOutOfStock } from "utils/Utils";
import useEventTracker from "hooks/useEventTracker";
import RecommendedITemList from "components/recommended-item/RecommendedITemList";
import useGetRecommendedItems from "service-hooks/useGetRecommendedItems";

const DienInHome = () => {
  usePlatform();
  const params = useParams();
  const router = useCustomHistory();
  const queryParam = useQueryParam();
  const { itemList } = useGetRecommendedItems();

  const [open, setOpen] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const handleSearch = (e) => {
    let term = e.target.value?.toLowerCase();
    setSearch(term);
  };
  const [searchTerm, setSearch] = useState("");
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { notFound, brandMenuError, discounts } = useSelector(
    (state) => state?.brand
  );
  const { storeMenu } = useSelector((state) => state?.store);
  const { selectedStore } = useSelector((state) => state.store);
  const { brandInfo, loyaltyPolicy } = useSelector(
    (state) => state?.brandStorageReducer
  );
  const { currentUser } = useCurrentUser();
  const { isLoading } = useSelector((state) => ({
    isLoading: state?.loader.isLoading,
  }));

  const { filteredMenu } = useMenuSearch(searchTerm, storeMenu);

  useEventTracker({
    name: "HOME_DINE_IN",
    orderType: "DINE_IN",
    shouldLog: true,
  });

  const goToSettings = () => {
    if (params) {
      router(
        `/dine-in/auth/settings`,
        `/${params?.domainName}/dine-in/auth/settings`,
        DomainType,
        null,
        `?${queryParam}`
      );
    }
  };

  const goToLogin = () => {
    router(
      "/dine-in/auth/login",
      `/${params?.domainName}/dine-in/auth/login`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };

  const openItemDetailPage = (item, category) => {
    if (!selectedStore?.isOpened) {
      setOpen(true);
    }
    if (
      selectedStore?.isOpened &&
      !isItemOutOfStock(item?.itemId, selectedStore) &&
      item?.itemId?._id &&
      category?._id
    ) {
      let MainURl = "/dine-in/item/";
      let TempUrl = `/${params?.domainName}${MainURl}${item?.itemId?._id}/${category?._id}`;
      let RootUrl = `${MainURl}${item?.itemId?._id}/${category?._id}`;
      router(RootUrl, TempUrl, DomainType, item, `?${queryParam}`);
    }
  };

  if (!isLoading && notFound) return <NotFound message={notFound} />;
  return (
    <WithLoading>
      <div className="store-page store-page-popup">
        <div className="location-bar bg-location py-2 px-4">
          <div className="flex justify-between items-center">
            <Button
              onClick={() => {}}
              className={`border border-brand-base-primary rounded-lg text-white bg-brand-base-primary py-1 px-2`}
            >
              {t("Dine In")}
            </Button>
            <div>
              {currentUser?.token ? (
                <UserLogo {...{ goToSettings }} />
              ) : (
                <LoginSignupButton {...{ goToLogin }} />
              )}
            </div>
          </div>
        </div>
        <ImageHeader page={true} />
        <StoreHeaderDetail
          page={C.BRAND}
          brandInfo={brandInfo}
          description={C.SERVICE_TYPE.DINE_IN}
          headerTitle={selectedStore?.baseLanguage?.name}
          activeServices={[]}
          orderType={null}
        />
        <DiscountHomeContainer
          {...{ discounts }}
          selectedStore={selectedStore}
        />
        {loyaltyPolicy?.isLoyaltyActive && (
          <LoyaltyPolicyWidget {...{ loyaltyPolicy, goToLogin }} />
        )}

        <div>{brandMenuError && <ErrorComponent error={brandMenuError} />}</div>

        <RecommendedITemList
          itemidList={itemList}
          setOpen={setOpen}
          openStoreItem={openItemDetailPage}
        />
        <Menu menu={storeMenu} {...{ filteredMenu, searchTerm, setSearch }}>
          <div className="sticky top-0 z-10 px-4 bg-white">
            <Search
              label={t("Search for menu items")}
              onChange={handleSearch}
              value={searchTerm}
              setInputFocus={setInputFocus}
            />
            {searchTerm?.length <= 0 && (
              <CategoriesList categoriesList={filteredMenu} />
            )}
          </div>
          {filteredMenu?.length === 0 && inputFocus ? (
            <div className="h-app-height"></div>
          ) : (
            <>
              <StoreCategoryWithMenu
                menu={filteredMenu}
                menuType={C.BRAND_BASED_CATALOGUE}
                onItemClick={openItemDetailPage}
                {...{ open, setOpen }}
                page="store"
              />
            </>
          )}
        </Menu>
      </div>
    </WithLoading>
  );
};

export default DienInHome;
