import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { CartAction, DiscountAction, NotPersistAction } from "store/actions";
import { UTILS } from "utils";
import CONSTANTS from "Constants";
import ButtonSpinner from "components/ButtonSpinner";
import { getLanguage, calculateCartGrossAmount } from "utils/Utils";
import { ErrorComponent } from "components";
import Close from "icons/Close";
import useCurrentUser from "hooks/useCurrentUser";
import { BrandAction } from "store/actions";
import Spinner from "components/Spinner";
import useEventTracker from "hooks/useEventTracker";
import { ViewApplicableDiscountedItemsList } from "components/DiscounteditemsList";
const ApplyCouponModal = () => {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const [couponCode, setCoupon] = useState("");
  const { cart } = useSelector((state) => state?.cart);
  const { discounts, isLoadingDiscounts } = useSelector(
    (state) => state?.brand
  );

  const { selectedStore } = useSelector((state) => state?.store);
  const { orderType } = useSelector((state) => state?.AppReducer);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { error, discountInfo, discountLoader } = useSelector(
    (state) => state?.Discounts
  );

  useEventTracker({ name: "APPLY_COUPON", orderType, shouldLog: true });

  const filteredDiscounts = UTILS.getFilteredCoupons(
    discounts,
    selectedStore,
    orderType
  );

  const closeModal = () => {
    dispatch(NotPersistAction.toggleDiscountModal(false));
    dispatch(DiscountAction.validateDiscountFailure(null));
    dispatch(
      NotPersistAction.setapplicableItemsDiscountListModal({
        isOpen: false,
        data: [],
      })
    );
  };

  const handleChange = (e) => {
    setCoupon(e.target.value);
  };

  const updateCartStatus = () => {
    const { itemsNetPrice, itemsGrossAmount, orderGrossAmount } =
      calculateCartGrossAmount(cart, discountInfo);
    CartAction.updateCartPrice({
      originalPrice: itemsNetPrice,
      amountAfterDiscount: itemsGrossAmount,
      itemsGrossAmount,
      orderGrossAmount,
    });
  };

  const applyCoupon = (e, typed = null) => {
    if (!typed) e.preventDefault();
    if (discounts && (couponCode || typed)) {
      let convertedcode = "";
      if (typed) convertedcode = typed?.toLowerCase()?.trim();
      else convertedcode = couponCode?.toLowerCase()?.trim();
      const discount = UTILS.getDiscontInfo(discounts, convertedcode);
      if (discount && selectedStore && currentUser && orderType?.serviceType) {
        const payload = {
          brandId: discount.brandId,
          discountId: discount._id,
          language: getLanguage(),
          storeId: selectedStore._id,
          userId: currentUser.user.userId,
          serviceType: orderType?.serviceType,
        };
        const obj = {
          payload,
          cart,
          discount,
          selectedStore,
        };
        dispatch(DiscountAction.validateDiscount(obj));
      }

      // if discount is null
      else if (discount === null) {
        updateCartStatus();
        dispatch(
          DiscountAction.validateDiscountFailure(t(CONSTANTS.COUPON_NOT_FOUND))
        );
        dispatch(DiscountAction.setCoponValidation(false));
      }
    }
  };

  useEffect(() => {
    if (!brandInfo?.brand?._id) return;
    dispatch(
      BrandAction.getDiscounts({
        brandId: brandInfo?.brand?._id,
        page: CONSTANTS.COUPONS_POPUP,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandInfo?.brand?._id]);

  return (
    <>
      <div className="jsutify-center w-full items-center justify-center fixced top-0 left-0 h-app-height modal-mask  overflow-y-auto flex">
        <div className="w-full relative h-fit mx-4 bg-white">
          <h2 className="w-fudll absolute max-460 top-0 flex justify-end px-4">
            <Close
              onClick={() => {
                closeModal();
              }}
              className="text-black"
            />
          </h2>
          <form className="pt-8 pb-4 px-2 xs:px-4">
            <div className="text-21x font-bold text-center">
              {t("Add discount code")}
            </div>
            <div className="modal__body">
              <p className="mb-4 text-16x text-center"></p>

              <div className="mb-4">
                <label className="text-14x  text-outer-space">
                  {t("Discount code")}
                  <span className="text-red-600"> *</span>
                </label>
                <div className="relative">
                  <input
                    onChange={handleChange}
                    value={couponCode}
                    type="text"
                    className="h-12 text-16x w-full pl-4 pr-20 rounded border-gray-200 border  z-0 focus:shadow focus:outline-none "
                    placeholder={t("Enter your code here")}
                  />
                  <div className=" absolute top-0 right-0">
                    <div className="flex justify-center bg-brand-base-primary rounded-r   text-brand-text-primary items-center text-16x text-bold">
                      {discountLoader ? (
                        <ButtonSpinner color="bg-brand-base-primary rounded   border border-brand-base-primary  text-brand-text-primary px-6 py-2 h-12" />
                      ) : (
                        <button
                          onClick={applyCoupon}
                          className="h-12 w-20 text-white rounded-r bg-brand-base-primary text-brand-text-primary text-16x text-bold"
                        >
                          {t("Apply")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <ViewApplicableDiscountedItemsList />
              </div>
            </div>
            {error && <ErrorComponent error={error} />}
            <div className="flex justify-between"></div>
          </form>
          {isLoadingDiscounts ? (
            <div className="flex justify-center items-center h-28 flex-col py-2">
              <Spinner />
            </div>
          ) : (
            filteredDiscounts?.length > 0 && (
              <div className="text-16x coupon-list overflow-y-scroll mb-4">
                {filteredDiscounts?.map((copon, index) => {
                  return (
                    <div
                      key={copon?._id}
                      className="mt-2 px-4 border-b-2 border-gray-300"
                    >
                      <div className="flex justify-between bg-white items-center">
                        <div className="bg-green-100 border-green-500 coupon-code border-2 text-center border-dashed px-2 py-2">
                          <span className="text-19x font-bold text-green-600 uppercase">
                            {copon?.discountCode}
                          </span>
                        </div>
                        <div className="flex1">
                          <button
                            disabled={discountLoader}
                            onClick={() => {
                              setCoupon(copon?.discountCode);
                              applyCoupon(null, copon?.discountCode);
                            }}
                            className="bg-brand-base-primary roundded  text-white  mx-1 text-brand-text-primary px-4 py-1"
                          >
                            {t("Apply")}
                          </button>
                        </div>
                      </div>
                      <div className="py-2">
                        {copon?.discountType === "percentage" ? (
                          <>
                            <span className="font-bold">
                              {copon?.discountAmount}
                            </span>
                            {t("x% off. Up to x AED", {
                              percentage: null,
                              amount: null,
                            })}{" "}
                            <span className="font-bold">
                              {copon?.maximumDiscountValue}
                            </span>
                            ,{" "}
                          </>
                        ) : (
                          <span className="font-bold">
                            {t("x AED off", {
                              amount: copon?.discountAmount,
                            })}
                            {", "}
                          </span>
                        )}
                        {copon?.minOrderValue > 0 ? (
                          <>
                            {t("Min order: AED")} {""}
                            <span className="font-bold">
                              {copon?.minOrderValue}
                            </span>
                            ,{" "}
                          </>
                        ) : null}

                        {copon?.perCustomerUse > 0 &&
                        copon?.perCustomerUse <= 10 ? (
                          <>
                            {t("Applicable")}
                            <span className="font-bold mx-1">
                              {copon?.perCustomerUse}
                            </span>
                            {copon?.perCustomerUse === 1
                              ? t("time per user.")
                              : t("time(s) per user.")}
                          </>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          )}
        </div>
      </div>
      {/* <DiscounteditemsList couponCode={couponCode} /> */}
    </>
  );
};

export default ApplyCouponModal;
