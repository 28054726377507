import React from "react";

function Dollar() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 128 128"
      className="w-18x h-18x text-brand-base-primary"
    >
      <path d="M124 21.5H4a4 4 0 00-4 4v77a4 4 0 004 4h120a4 4 0 004-4v-77a4 4 0 00-4-4zm-4 77H8v-69h112z"></path>
      <path d="M67.27 74.4H53.55a4 4 0 100 8h7v2.93a4 4 0 008 0v-3A11.22 11.22 0 0067.27 60h-6.54a3.18 3.18 0 010-6.36h13.72a4 4 0 000-8h-5.87v-2.97a4 4 0 00-8 0v2.93a11.18 11.18 0 00.15 22.4h6.54a3.22 3.22 0 010 6.44z"></path>
    </svg>
  );
}

export default Dollar;
