import React from "react";
import { useSelector } from "react-redux";
import Footer from "components/Footer";
import { FixedFooter } from "components";
import ViewBasket from "components/basket/ViewBasket";
import { useParams } from "react-router-dom";
import { useCustomHistory } from "hooks";
import WithLoading from "components/Hoc/WithLoading";
import useQueryParam from "modules/dine-in/useQueryParam";

const MenuContainer = (props) => {
  const { DomainType } = useSelector((state) => state.AppReducer);
  const params = useParams();
  const router = useCustomHistory();
  const queryParam = useQueryParam();

  const viewBasketPage = () => {
    router(
      `/dine-in/checkout`,
      `/${params.domainName}/dine-in/checkout`,
      DomainType,
      undefined,
      `?${queryParam}`
    );
  };
  return (
    <WithLoading>
      {props?.children}
      <Footer />
      <FixedFooter basketFooter={true} page={true} from="menu">
        <ViewBasket {...{ viewBasketPage }} />
      </FixedFooter>
    </WithLoading>
  );
};

export default MenuContainer;
