import React, { useState } from "react";
import { useSelector } from "react-redux";

import OrderType from "components/OrderType";
import CustomModal from "components/CustomModal";
import ConfirmModal from "components/Modals/ConfirmModal";
import SelectDeliveryArea from "components/outlet-based-areas/SelectDeliveryArea";
import { StoreListForPickup } from "components/store/StoreList";
import DeliveryPickupButton from "components/DeliveryPickupButton";

import useChangeOrderType from "../useChangeOrderType";
import { useLang } from "hooks";

const LocationBar = (props) => {
  const { currentLang } = useLang();
  const {
    setServiceType,
    confirmModal,
    setConfirmModal,
    confirmModalTitle,
    handleConfirmModal,
    open,
    setOpen,
    handleCancelModal,
  } = useChangeOrderType();
  const [mapOpen, setMapOpen] = useState(false);
  const { stores } = useSelector((state) => state?.brand);
  const [storeOptionOpen, setStoreOptionOpen] = useState(false);
  const { orderType } = useSelector((state) => state?.AppReducer);

  return (
    <>
      <div className="location-bar bg-location px-4 py-2">
        <div className="flex justify-between items-center">
          <DeliveryPickupButton
            activeServices={props?.services}
            handleServiceType={setServiceType}
            orderType={orderType}
          />

          {props?.children}
        </div>
      </div>
      <CustomModal
        open={open}
        setOpen={setOpen}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-20 border-sc"
      >
        <OrderType
          setOpen={setOpen}
          setServiceType={setServiceType}
          services={props?.services}
        />
      </CustomModal>
      <CustomModal
        open={mapOpen}
        setOpen={setMapOpen}
        bodyclasses="flex"
        overlayClasses={`fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30 ${
          currentLang == "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <SelectDeliveryArea
          header={false}
          headerLabel=""
          styleClasses={`fixed bottom-0 h-5 bg-white min-h-70p max-460  rounded-t-lg min-h-60p  ${
            currentLang == "AR" ? "border-sc-l" : "border-sc"
          }`}
        />
      </CustomModal>

      <CustomModal
        shouldCloseOnEsc="No"
        shouldCloseOnOverlayClick="No"
        open={storeOptionOpen}
        setOpen={setStoreOptionOpen}
        bodyclasses="flex"
        overlayClasses={`fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30 ${
          currentLang == "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <StoreListForPickup
          stores={stores}
          header={false}
          headerLabel=""
          styleClasses={`fixed bottom-0 h-5 bg-white min-h-70p max-460 o111verflow-y-auto rounded-t-lg min-h-60p  ${
            currentLang == "AR" ? "border-sc-l" : "border-sc"
          }`}
        />
      </CustomModal>
      <CustomModal
        open={confirmModal}
        setOpen={setConfirmModal}
        bodyclasses="flex"
        overlayClasses={`fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30 ${
          currentLang == "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <ConfirmModal
          onConfirm={handleConfirmModal}
          onCancel={handleCancelModal}
          title={confirmModalTitle}
        />
      </CustomModal>
    </>
  );
};
export default LocationBar;
