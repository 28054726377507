import React from "react";
import { t } from "i18next";

function AddMoreITemsButton({ handleRedirect }) {
  return (
    <div className="flex items-center justify-center mt-4">
      <button
        type="button"
        onClick={handleRedirect}
        className="bg-brand-base-primary  py-1 rounded px-2 text-brand-text-primary text-14x "
      >
        <span className="flex items-center justify-center text-center">
          <div className="flex relative w-full items-center pointer">
            <span className="my-0 mx-auto">{t("Add more items")}</span>
          </div>
        </span>
      </button>
    </div>
  );
}

export default AddMoreITemsButton;
