import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderTiming: {
    TimingType: null,
    isPreOrderAvailable: false,
    preOrderSlot: {
      date: null,
      time: null,
      slotIndex: null,
    },
  },
};

const orderTiming = createSlice({
  name: "orderTiming",
  initialState,
  reducers: {
    setOrderTiming: (state, action) => {
      state.orderTiming = action.payload;
    },
    resetOrderTiming: (state) => {
      state.orderTiming = {
        TimingType: null,
        isPreOrderAvailable: false,
        preOrderSlot: {
          date: null,
          time: null,
          slotIndex: null,
        },
      };
    },
  },
});

export const { setOrderTiming, resetOrderTiming } = orderTiming.actions;

export default orderTiming.reducer;
