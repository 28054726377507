import React from "react";
import LoyaltyRedeemSwitch from "components/brand-loyalty/LoyaltyRedeemSwitch";
import C from "Constants";
import { ItemsPaymentTotalDetail } from "components";
const OrderPaymentInfo = ({
  getAreaDeliverFee,
  loyaltyPolicy,
  amountAfterDiscount,
  cartTotal,
  selectedStore,
  userWallet,
  handleConfirmDiscountLoyalty,
  redeemAmount,
}) => {
  return (
    <ItemsPaymentTotalDetail
      original={cartTotal}
      deliveryFee={getAreaDeliverFee()?.deliveryFee || 0}
      taxRate={selectedStore?.taxRate || 0}
      amountAfterDiscount={amountAfterDiscount}
      userWallet={userWallet}
    >
      {loyaltyPolicy?.isLoyaltyActive && userWallet > 0 && (
        <LoyaltyRedeemSwitch
          loyaltyPolicy={loyaltyPolicy}
          cartTotal={cartTotal}
          amountAfterDiscount={cartTotal}
          userWallet={userWallet}
          switchLoyaltyStatus={() => handleConfirmDiscountLoyalty(C.LOYALTY)}
          redeemAmount={redeemAmount}
        />
      )}
    </ItemsPaymentTotalDetail>
  );
};
export default OrderPaymentInfo;
