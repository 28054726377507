import React from "react";
import C from "Constants";
import { AppleIcon } from "icons";
import { SVGICONS } from "config";
import { GooglePay } from "icons";
function SupportedWalletLis({
  supportedBrowserWallets,
  handleClick,
  selectedPayment,
  selectedPaymentType,
}) {
  const online_payment_obj = {
    EN: {
      name: "ONLINE PAYMENT (Credit/Debit Card)",
    },
    AR: {
      name: "(الدفع اونلاين (بطاقة الائتمان / الخصم",
    },
    _id: "619f7ae3709b147c8c27e3f7",
    updatedAt: "2021-11-25T12:00:35.032Z",
    createdAt: "2021-11-25T12:00:35.032Z",
    __v: 0,
    paymentType: "CREDIT_CARD",
    serviceType: "DELIVERY",
  };
  return (
    <>
      {supportedBrowserWallets?.applePay && (
        <div
          className="p4 mb-4 border border-gray-200 mx-4 rounded-md px-4 py-3"
          onClick={() => {
            handleClick(online_payment_obj, C.WALLET.APPLE_PAY);
          }}
        >
          <div className="flex justify-between items-center">
            <div className="text-16x font-normal">
              <span className="me-3">
                <AppleIcon />
              </span>
              Apple Pay
            </div>
            <div className="flex">
              <span className="inline-flex w-6 h-6">
                <input type="radio" name="Select Type" className="hidden" />
                <span className="w-6 h-6 inline-flex ">
                  {selectedPayment?.paymentType === C.CREDIT_CARD &&
                  selectedPaymentType === C.WALLET.APPLE_PAY ? (
                    <SVGICONS.RadioCheck />
                  ) : (
                    <SVGICONS.RadioUnCheck />
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
      {supportedBrowserWallets?.googlePay && (
        <div
          className="p4 mb-4 border border-gray-200 mx-4 rounded-md px-4 py-3"
          onClick={() => {
            handleClick(online_payment_obj, C.WALLET.GOOGLE_PAY);
          }}
        >
          <div className="flex justify-between items-center">
            <div className="text-16x font-normal">
              <span className="me-3">
                <GooglePay />
              </span>
              Google Pay
            </div>
            <div className="flex">
              <span className="inline-flex w-6 h-6">
                <input type="radio" name="Select Type" className="hidden" />
                <span className="w-6 h-6 inline-flex ">
                  {selectedPayment?.paymentType === C.CREDIT_CARD &&
                  selectedPaymentType === C.WALLET.GOOGLE_PAY ? (
                    <SVGICONS.RadioCheck />
                  ) : (
                    <SVGICONS.RadioUnCheck />
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SupportedWalletLis;
