import React from "react";
import OrderContainer from "components/order/OrdersContainer";
import { useParams } from "react-router-dom";
import * as ROUTE_CONSTANTS from "routes/constants";
import { useCustomHistory } from "hooks";
import C from "Constants";
import { useSelector } from "react-redux";
const settingsPageUrl = ROUTE_CONSTANTS.OUTLET_SETTINGS[1];
const orderDetailRoute = ROUTE_CONSTANTS.OUTLET_ORDER_DETAIL[1];
const storePageUrl = ROUTE_CONSTANTS.OUTLET_STORE[1];

function Orders() {
  const params = useParams();
  const router = useCustomHistory();
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state.store
  );
  const { selectedArea } = useSelector((state) => state?.DeliveryAreaReducer);

  const getOrderDetail = (order) => {
    router(
      orderDetailRoute,
      `/${params?.domainName}${orderDetailRoute}`,
      DomainType,
      order
    );
  };
  const gotoStorePage = () =>
    router(storePageUrl, `/${params?.domainName}${storePageUrl}`, DomainType);

  const goBack = () => {
    if (
      orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
      selectedArea &&
      selectedStore &&
      outletBasedSelectedStoreArea
    )
      gotoStorePage();
    else if (orderType?.serviceType === C.SERVICE_TYPE.PICKUP && selectedStore)
      gotoStorePage();
    else router("/", `/${params?.domainName}${settingsPageUrl}`, DomainType);
  };

  return (
    <OrderContainer handleBack={goBack} handleOrderDetail={getOrderDetail} />
  );
}

export default Orders;
