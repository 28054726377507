import React from "react";

function TextareaInput(props) {
  const { styleClasses, placeholder, value, onChange, id } = props;
  return (
    <textarea
      value={value}
      id={id}
      rows={3}
      cols={props?.cols ? props?.cols : ""}
      className={styleClasses}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}

export default TextareaInput;
