import React, { useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";

import { t } from "i18next";
import "./App.css";
import { logErrorOnSentry } from "utils/Utils";
export default function StripeForm() {
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
    logErrorOnSentry(event.error ? event.error.message : "", "info");
  };

  return (
    <div className="mt-4">
      <div className="mx-1 375:mx-2 px-2 pt-6  bg-gray-100 shadow-md">
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />

        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            <span className="text-12x">{t("Powered by")}</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-16"
              fill="#2d2d2d"
              y="0"
              x="0"
              id="Layer_1"
              version="1.1"
              viewBox="-54 -37.45 468 224.7"
            >
              {/* <style id="style41" type="text/css">.st0{fillRule:evenodd;clip-rule:evenodd;fill:#fff}</style> */}
              <g transform="translate(-54 -36)" id="g57">
                <path
                  id="path43"
                  d="M414 113.4c0-25.6-12.4-45.8-36.1-45.8-23.8 0-38.2 20.2-38.2 45.6 0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5v-20c-6.8 3.4-14.6 5.5-24.5 5.5-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16z"
                  className="st0"
                />
                <path
                  id="path45"
                  d="M301.1 67.6c-9.8 0-16.1 4.6-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4z"
                  className="st0"
                />
                <path
                  id="polygon47"
                  className="st0"
                  d="M248.9 36l-25.1 5.3v20.4l25.1-5.4z"
                />
                <path
                  id="rect49"
                  className="st0"
                  d="M223.8 69.3h25.1v87.5h-25.1z"
                />
                <path
                  id="path51"
                  d="M196.9 76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4z"
                  className="st0"
                />
                <path
                  id="path53"
                  d="M146.9 47.6l-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V135c-3.2 1.3-19 5.9-19-8.9V90.6h19V69.3h-19z"
                  className="st0"
                />
                <path
                  id="path55"
                  d="M79.3 94.7c0-3.9 3.2-5.4 8.5-5.4 7.6 0 17.2 2.3 24.8 6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6C67.5 67.6 54 78.2 54 95.9c0 27.6 38 23.2 38 35.1 0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8c9.3 4 18.7 5.7 27.3 5.7 20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7z"
                  className="st0"
                />
              </g>
            </svg>
          </div>
          <div className="text-green-500 font-semibold items-center h-3.5 hidden 375:!flex">
            <svg
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              className="w-3.5 h-3.5 text-green-500 inline-block me-1"
            >
              <path d="M75.5 39.1h-.8v-9.3C74.7 16.1 63.6 5 50 5S25.3 16.1 25.3 29.7V39h-.8c-5.2 0-9.5 4.2-9.5 9.5v37c0 5.2 4.2 9.5 9.5 9.5h51c5.2 0 9.5-4.2 9.5-9.5v-37c0-5.2-4.3-9.4-9.5-9.4zm-18 41.4c.3 1.6-.9 3-2.5 3H45c-1.6 0-2.8-1.4-2.5-3l2.7-15.1c-2-1.5-3.3-3.9-3.3-6.6 0-4.5 3.7-8.2 8.2-8.2s8.2 3.7 8.2 8.2c0 2.7-1.3 5.1-3.3 6.6l2.5 15.1zm7.2-41.4H35.3v-9.3c0-8.1 6.6-14.7 14.7-14.7s14.7 6.6 14.7 14.7v9.3z"></path>
            </svg>
            <span className="text-12x">{t("256-bit encryption")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
