import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import * as CommonUI from "components";
import WithLoading from "components/Hoc/WithLoading";
import AddDeliveryAddress from "components/checkout/AddDeliveryAddress";

import * as Actions from "store/actions";
import { useCustomHistory } from "hooks";
import * as ROUTE_CONSTANTS from "routes/constants";
import useAddUserDeliveryAddress from "service-hooks/useAddUserDeliveryAddress";
import useCheckIfStoreAndCart from "hooks/useCheckIfStoreAndCart";

function AddDeliveryManualaddress({ openModals, setState }) {
  const params = useParams();
  const history = useHistory();
  const router = useCustomHistory();
  useCheckIfStoreAndCart();

  const addUserDeliveryAddress = useAddUserDeliveryAddress(history, false);

  const { DomainType } = useSelector((state) => state.AppReducer);
  const { error, errorModaL } = useSelector((rstate) => rstate.errorSlice);

  //refine geo location
  const handleRefineLocation = () => {
    const url = ROUTE_CONSTANTS.OUTLET_MAP_DELIVERY_ADDRESS[1];
    router(url, `/${params?.domainName}${url}`, DomainType);
  };

  const gotoCheckoutPage = () => {
    const url = ROUTE_CONSTANTS.OUTLET_CHECKOUT[1];
    router(url, `/${params?.domainName}${url}`, DomainType);
  };

  const [isSaveAddress, setIsSaveAddress] = useState(false);

  const goBacktomapscreen = (val) => {
    setIsSaveAddress(val);
  };
  const { temporarymapAddress, temporaryuserLocation, temporaryzoom } =
    useSelector((state) => state.DeliveryAddressReducer);
  return (
    <div className="store-page-popup 46">
      <WithLoading>
        <AddDeliveryAddress
          {...{ temporarymapAddress, temporaryuserLocation, temporaryzoom }}
          goBacktomapscreen={goBacktomapscreen}
          isSaveAddress={isSaveAddress}
          clearAddressInputFields={() => {}}
          gotoCheckoutPage={gotoCheckoutPage}
          isEnabled={true}
          setState={setState}
          openModals={openModals}
          onSubmit={addUserDeliveryAddress}
          handleRefineLocation={handleRefineLocation}
        />

        <CommonUI.CustomModal
          open={errorModaL}
          reduxAction={Actions.ErrorAction.setErrorModal(false)}
          bodyclasses="flex"
          overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
        >
          <CommonUI.ErrorModal
            reduxAction={Actions.ErrorAction.setErrorModal(false)}
            message={error}
          />
        </CommonUI.CustomModal>
      </WithLoading>
    </div>
  );
}

export default AddDeliveryManualaddress;
