import { TRACKING_UTILS } from "utils";
import reduxstore from "store/store";
import C from "Constants";
const isFaceBookPixelEnabled = () => {
  let { brandInfo } = reduxstore.getState()?.brandStorageReducer;
  let isActive = !!brandInfo?.activeIntegrations?.find(
    (integration) =>
      integration?.name === C.FACEBOOK_PIXEL && integration?.isActive === true
  );

  return isActive;
};

export const AddToCart = () => {
  if (isFaceBookPixelEnabled()) {
    TRACKING_UTILS.triggerFacebookEvent(
      "AddToCart",
      {},
      { isCustomEvent: false }
    );
  }
};

export const purchase = (cartTotal) => {
  if (isFaceBookPixelEnabled()) {
    let item = { value: cartTotal?.toString(), currency: C.AED };
    TRACKING_UTILS.triggerFacebookEvent("Purchase", item, {
      isCustomEvent: false,
    });
  }
};
