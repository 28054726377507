import React from "react";
import { SVGICONS } from "config";
import { useDispatch, useSelector } from "react-redux";
import { PaymentCardsAction } from "store/actions";
import { StoreAction } from "store/actions";
import { PaymentMethodAction } from "store/actions";
import { CreditcardIcon } from "icons";
import { getCreditCardObjByOrderType } from "utils/Utils";

const SaveCardComponent = ({ last4, children, id, onClick }) => {
  return (
    <div
      className="p4 mb-3 border border-gray-200 mx-4 rounded-md px-4 py-3"
      key={id}
      onClick={onClick}
    >
      <div className="flex justify-between">
        <div className="text-16x font-normal">
          <span className="me-3">
            <CreditcardIcon color="text-black" />
          </span>
          {last4}
        </div>
        <div className="flex">{children}</div>
      </div>
    </div>
  );
};

const StripeRadio = ({ card, selectedCardId }) => {
  const { selectedPayment } = useSelector((state) => state.store);
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );

  const isCheked =
    !!selectedCardId &&
    card?.id &&
    selectedCardId === card?.id &&
    selectedPayment?.paymentType === "CREDIT_CARD" &&
    selectedPaymentType === "saved_card";
  return (
    <span className="inline-flex w-6 h-6">
      <input
        type="radio"
        name="card"
        className="hidden"
        value={JSON.stringify(card)}
        checked={isCheked}
        onChange={(e) => {}}
      />
      <span className="w-6 h-6 inline-flex ">
        {isCheked ? (
          <SVGICONS.RadioCheck color="text-black" />
        ) : (
          <SVGICONS.RadioUnCheck color="text-black" />
        )}
      </span>
    </span>
  );
};

const StripeSaveCardComponent = () => {
  const dispatch = useDispatch();
  const {
    stripeSavedPaymentCards,
    stripeSelectedCard: { cardInfo },
  } = useSelector((state) => state.PaymentCardsReducer);
  const { selectedStore } = useSelector((state) => state.store);
  const { orderType } = useSelector((state) => state.AppReducer);
  const handleCheckboxChange = (value) => {
    dispatch(
      PaymentCardsAction.setstripeSelectedCard({
        cardInfo: JSON.parse(value),
        isStoredCard: true,
      })
    );
    dispatch(
      StoreAction.setSelectedPaymentMethod(
        getCreditCardObjByOrderType(orderType, selectedStore)
      )
    );

    dispatch(PaymentMethodAction.setSelectedPaymentType("saved_card"));
  };

  return (
    <>
      {stripeSavedPaymentCards?.map((card, _) => {
        return (
          <SaveCardComponent
            key={card?.id}
            id={card?.id}
            brand={card?.card?.brand}
            last4={card?.card?.last4}
            onClick={() => handleCheckboxChange(JSON.stringify(card))}
          >
            <StripeRadio
              card={{
                id: card?.id,
                brand: card?.card?.brand,
                last4: card?.card?.last4,
                paymentGateway: card?.paymentGateway,
                expiryMonth: card?.exp_month,
                expiryYear: card?.exp_year,
              }}
              selectedCardId={cardInfo?.id}
            />
          </SaveCardComponent>
        );
      })}
    </>
  );
};

const CheckoutRadio = ({ card, selectedCardId }) => {
  const { selectedPayment } = useSelector((state) => state.store);

  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );

  const isCheked =
    !!selectedCardId &&
    card?._id &&
    selectedCardId === card?._id &&
    selectedPayment?.paymentType === "CREDIT_CARD" &&
    selectedPaymentType === "saved_card";

  return (
    <span className="inline-flex w-6 h-6">
      <input
        type="radio"
        name="card"
        className="hidden"
        value={JSON.stringify(card)}
        checked={isCheked}
        onChange={(e) => {}}
      />
      <span className="w-6 h-6 inline-flex ">
        {isCheked ? (
          <SVGICONS.RadioCheck color="text-black" />
        ) : (
          <SVGICONS.RadioUnCheck color="text-black" />
        )}
      </span>
    </span>
  );
};
const CheckoutSaveCardComponent = () => {
  const {
    savedPaymentCards,
    selectedCard: { cardInfo },
  } = useSelector((state) => state.PaymentCardsReducer);
  const { selectedStore } = useSelector((state) => state.store);
  const { orderType } = useSelector((state) => state.AppReducer);

  const dispatch = useDispatch();

  const handleCheckboxChange = (value) => {
    dispatch(
      PaymentCardsAction.setSelectedCard({
        cardInfo: JSON.parse(value),
        isStoredCard: true,
      })
    );
    dispatch(
      StoreAction.setSelectedPaymentMethod(
        getCreditCardObjByOrderType(orderType, selectedStore)
      )
    );

    dispatch(PaymentMethodAction.setSelectedPaymentType("saved_card"));
  };
  return (
    <>
      {savedPaymentCards?.map((card, _) => {
        return (
          <SaveCardComponent
            key={card?._id}
            id={card?._id}
            brand={card?.cardType}
            last4={card?.lastFourDigit}
            onClick={() => handleCheckboxChange(JSON.stringify(card))}
          >
            <CheckoutRadio card={card} selectedCardId={cardInfo?._id} />
          </SaveCardComponent>
        );
      })}
    </>
  );
};

export { CheckoutSaveCardComponent, StripeSaveCardComponent };
