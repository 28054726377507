function DiamondIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      className="w-5 h-5 text-brand-base-primary"
    >
      <path d="M21.531 14.32l-14 20c-.74 1.067-.673 2.625.156 3.624l40 48c1.122 1.357 3.504 1.357 4.626 0l40-48c.829-1 .896-2.557.156-3.625l-14-20A3.053 3.053 0 0076 13.04H24c-1.056 0-1.99.51-2.469 1.28zm6.844 4.718H43.25L32.875 30.601zm28.375 0h14.875l-4.469 11.563zM50 20.507l11.281 12.531H38.72zM23.25 22.35l4.156 10.687H15.781zm53.5 0l7.469 10.687H72.594zM16.406 39.038H29.72l11.593 29.875zm19.75 0h27.719L50 74.726zm34.125 0h13.313L58.688 68.913z"></path>
    </svg>
  );
}

export default DiamondIcon;
