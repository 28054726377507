import { PreOrderingSlotWidget } from "components/Pre-ordering";
import DeliveryTime from "components/checkout/DeliveryTime";
import React, { useState } from "react";
import { useSelector } from "react-redux";

function PreORderingWidget() {
  const [isPreOrderingSlotWidgetOpen, setPreOrderingSlotWidget] =
    useState(false);
  const { selectedStore } = useSelector((state) => state?.store);
  return (
    <>
      <DeliveryTime setPreOrderingSlotWidget={setPreOrderingSlotWidget} />
      <PreOrderingSlotWidget
        isPreOrderingSlotWidget={isPreOrderingSlotWidgetOpen}
        {...{ setPreOrderingSlotWidget }}
        selectedStore={selectedStore}
      />
    </>
  );
}

export default PreORderingWidget;
