import React from "react";
function CHECKED() {
  return (
    <svg
      height="24"
      width="24"
      viewBox="0 0 24 24"
      className="text-brand-base-primary"
    >
      <path
        fill="currentColor"
        d="M3.9 3H20.1L21 3.9V20.1L20.1 21H3.9L3 20.1V3.9L3.9 3ZM8.02132 10.9L5.9 13.0213L10.0213 17.1426L18.1426 9.02132L16.0213 6.9L10.0213 12.9L8.02132 10.9Z"
      ></path>
    </svg>
  );
}

export default CHECKED;
