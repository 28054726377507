import React from "react";

const Button = (props) => {
  const { classes, title } = props;
  return (
    <button
      type={props?.type ? props?.type : "button"}
      className={classes}
      onClick={props.onClick}
      disabled={props?.disabled ? props?.disabled : false}
    >
      {title}
      {props.children}
    </button>
  );
};

export default Button;
