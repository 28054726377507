import React from "react";
import Spinner from "components/Spinner";
import { useSelector } from "react-redux";

function WithLoading(props) {
  const { checkoutLoader } = useSelector((state) => state.loader);

  if (props?.loading || checkoutLoader)
    return (
      <div className="fixed z-5s0 max-460 min-h-app-height overflow-hidden w-full h-full back-filter">
        <Spinner />
      </div>
    );
  return null;
}

export default WithLoading;
