import React from "react";
import { GoogleMapContextProvider } from "contexts/googleMap";
import { UserAddressContextProvider } from "contexts/userAddressess";
import { ErrorContextProvider } from "contexts/errorContext";

function WithContextProviders({ children }) {
  const providersArray = [
    GoogleMapContextProvider,
    UserAddressContextProvider,
    ErrorContextProvider,
  ];
  return (
    <>
      {providersArray.reduce((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </>
  );
}

export default WithContextProviders;
