import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  error: null,
  lifetimeSpending: null,
  loading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLifetimeSpending: (state, action) => {
        state.lifetimeSpending = action.payload;
    },
  },
});

export const {
  setLifetimeSpending,
} = userSlice.actions;

export default userSlice.reducer;
