import React from "react";
import { t } from "i18next";
import { UTILS } from "utils";

const ErrorComponent = ({ error }) => {
  UTILS.logErrorOnSentry(error, "info");
  if (
    typeof error === "string" &&
    (error.includes("null") || error.includes("undefined"))
  ) {
    return (
      <div className="">
        <div className="text-red-500 text-center font-bold mt-3 mb-3">
          {t("Something was incorrect.")}
        </div>
      </div>
    );
  } else if (error === null || error === undefined) {
    return null;
  } else {
    return (
      <div className="">
        <div className="text-red-500 text-center font-bold mt-3 mb-3 px-4">
          {typeof error === "string" ? error : t("Something was incorrect.")}
        </div>
      </div>
    );
  }
};

export default ErrorComponent;
