import FixedFooter from "components/FixedFooter";
import React from "react";
import PlaceOrderButton, { MinOrder } from "./PlaceOrderButton";
import WalletPay from "helpers/payment_helpers/stripe/WalletPay";
import { useSelector } from "react-redux";
import C from "Constants";
function FooterWithWallet({
  cartTotal,
  integrationsByName,
  selectedStore,
  placeORder,
  Place_Order,
  cardProcessing,
  validateOrderShipping,
  minOrder,
  storePageUrl,
}) {
  const { orderType } = useSelector((state) => state.AppReducer);

  if (minOrder > cartTotal && orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY)
    return (
      <FixedFooter>
        <MinOrder
          minOrder={minOrder || 0}
          cartTotal={cartTotal}
          storePageUrl={storePageUrl}
        />
      </FixedFooter>
    );
  else if (integrationsByName[C.STRIPE])
    return (
      <WalletPay
        selectedStore={selectedStore}
        placeOrder={placeORder}
        payUsingWallet={validateOrderShipping}
      >
        <FixedFooter>
          <PlaceOrderButton placeOrder={Place_Order} loading={cardProcessing} />
        </FixedFooter>
      </WalletPay>
    );
  return (
    <FixedFooter>
      <PlaceOrderButton placeOrder={Place_Order} loading={cardProcessing} />
    </FixedFooter>
  );
}

export default FooterWithWallet;
