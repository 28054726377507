import { lazy } from "react";
import Order from "pages/order/containers/Order";
import EcommerceOrders from "ecommerce/orders/Orders";

import Store from "pages/store/Store";
import Item from "pages/item/containers/Item";
import Zoom from "components/item/Zoom";
import Basket from "pages/basket/containers/Basket";
import Checkout from "pages/checkout/containers/Checkout";
import OrderDetail from "pages/order-detail/containers/OrderDetail";
import Setting from "pages/setting/containers/Setting";
import NotFound from "pages/not-found/containers/NotFound";
import TrackOrder from "features/track-order/containers/TrackOrder";
import Login from "pages/login/containers/Login";
import Verification from "pages/verification/containers/Verification";
import Home from "pages/home/container/Home";
import * as C from "./constants";
import StaticPages from "pages/static-pages/static";
import AddGeoLocationOnHomePage from "features/delivery-address/add-geo-location-onHome-page/AddGeoLocationOnHomePage";
//Preview Pages
import { PreviewHome, ItemPreview } from "preview/index";
import {
  BrandBasedHome,
  BrandBasedItem,
  BrandBasedVerification,
  BrandBasedCheckout,
} from "ecommerce/index";

// payment Links
import PaymentLinkHome from "modules/payment-links/pages/HomePage";
import AddMapAddress from "ecommerce/checkout/containers/SelectMapLocation";
import ManualAddressWithShippingModal from "ecommerce/checkout/containers/ManualAddressWithShippingModal";
import OutletMapAddress from "features/delivery-address/add-new-address-on-checkout-page/AddDeliveryMapAddress";
import OutletAddDeliveryAddress from "features/delivery-address/add-new-address-on-checkout-page/AddDeliveryManualAddress";
import OutletBasedOrderSuccess from "features/order-success/Index";
import DineInOrderSuccess from "modules/dine-in/order-success/Index";

//add address on Item-Page && store-Page
import AddMapAddressOnItemPage from "features/delivery-address/add-new-address-on-item-page/AddMapAddress";
import AddManualAddressOnItemPage from "features/delivery-address/add-new-address-on-item-page/AddManualAddress";

//Dine-in
import {
  DineInHome,
  DineInItem,
  DineInVerification,
  DineInCheckout,
  DineInOrderList,
  DineInOrderDetail,
  DineInLogin,
  DineInSetting,
  DineInTrackOrder,
} from "modules/dine-in/index";
const OrderFeedback = lazy(() => import("pages/order-feedback/OrderFeedback"));

const ProtectedRoutesArray = [
  {
    path: C.OUTLET_BASKET,
    component: Basket,
    exact: true,
  },
  {
    path: C.OUTLET_MY_ORDERS,
    component: Order,
    exact: true,
  },
  {
    path: C.OUTLET_ORDER_DETAIL,
    component: OrderDetail,
    exact: true,
  },
  {
    path: C.OUTLET_CHECKOUT,
    component: Checkout,
    exact: true,
  },
  {
    path: C.OUTLET_SETTINGS,
    component: Setting,
    exact: true,
  },
  {
    path: C.OUTLET_MAP_DELIVERY_ADDRESS,
    component: OutletMapAddress,
  },
  {
    path: C.OUTLET_MANUAL_DELIVERY_ADDRESS,
    component: OutletAddDeliveryAddress,
  },
  {
    path: [
      "/:domainName/order/order-success/:orderId",
      "/order/order-success/:orderId",
    ],
    component: OutletBasedOrderSuccess,
  },
];

const PublicRoutesArray = [
  {
    path: C.HOME,
    component: Home,
    exact: true,
  },
  {
    path: [
      "/address/select-delivery-area",
      "/:domainName/address/select-delivery-area",
    ],
    component: AddGeoLocationOnHomePage,
    exact: true,
  },
  {
    path: [
      "/address/add-new-map-address",
      "/:domainName/address/add-new-map-address",
    ],
    component: AddMapAddressOnItemPage,
    exact: true,
  },
  {
    path: [
      "/address/add-new-manual-address",
      "/:domainName/address/add-new-manual-address",
    ],
    component: AddManualAddressOnItemPage,
    exact: true,
  },
  {
    path: [
      "/:domainName/order/order-feedback/:orderId",
      "/order/order-feedback/:orderId",
    ],
    component: OrderFeedback,
    exact: true,
  },

  {
    path: C.OUTLET_ITEM,
    component: Item,
    exact: true,
  },
  {
    path: C.OUTLET_ITEM_IMAGE,
    component: Zoom,
    exact: true,
  },

  {
    path: C.OUTLET_STORE,
    component: Store,
    exact: true,
  },

  {
    path: C.OUTLET_LOGIN,
    exact: true,
    component: Login,
  },

  {
    path: C.OUTLET_VERIFICATION,
    component: Verification,
    exact: true,
  },
  {
    path: C.OUTLET_TRACK_ORDER,
    component: TrackOrder,
    exact: true,
  },

  {
    path: "/:domainName:/not-found",
    component: NotFound,
    exact: true,
  },
];

const PreviewRoutes = [
  {
    path: "/:domainName/preview",
    component: PreviewHome,
    exact: true,
  },

  {
    path: "/:domainName/preview/item-detail",
    component: ItemPreview,
    exact: true,
  },
  {
    path: "/:domainName/preview/item-image",
    component: Zoom,
    exact: true,
  },
];
const StaticPublicPages = [
  {
    path: C.STATIC_PAGE_LINK,
    component: StaticPages,
    exact: true,
  },
];

const EcommercePublicRoutes = [
  {
    path: C.HOME,
    component: BrandBasedHome,
    exact: true,
  },
  {
    path: [
      "/:domainName/order/order-feedback/:orderId",
      "/order/order-feedback/:orderId",
    ],
    component: OrderFeedback,
    exact: true,
  },
  {
    path: C.ECOMMERCE_TRACK_ORDER,
    component: TrackOrder,
    exact: true,
  },
  {
    path: C.ECOMMERCE_ITEM,
    component: BrandBasedItem,
    exact: true,
  },
  {
    path: C.ECOMMERCE_ITEM_IMAGE,
    component: Zoom,
    exact: true,
  },
  {
    path: C.ECOMMERCE_VERIFICATION,
    component: BrandBasedVerification,
    exact: true,
  },
  {
    path: C.ECOMMERCE_LOGIN,
    exact: true,
    component: Login,
  },
];
const EcommerceProtectedRoutes = [
  {
    path: C.ECOMMERCE_MY_ORDERS,
    component: EcommerceOrders,
    exact: true,
  },

  {
    path: C.ECOMMERCE_ORDER_DETAIL,
    component: OrderDetail,
    exact: true,
  },
  {
    path: C.ECOMMERCE_CHECKOUT,
    component: BrandBasedCheckout,
    exact: true,
  },
  {
    path: C.ECOMMERCE_SETTINGS,
    component: Setting,
    exact: true,
  },
  {
    path: C.ECOMMERCE_MAP_DELIVERY_ADDRESS,
    component: AddMapAddress,
    exact: true,
  },
  {
    path: C.ECOMMERCE_MANUAL_DELIVERY_ADDRESS,
    component: ManualAddressWithShippingModal,
    exact: true,
  },
];

export const PaymentLinks = [
  {
    path: C.PAYMENT_LINK_HOME,
    component: PaymentLinkHome,
    exact: true,
  },
];

export const DineInPublicRoutes = [
  {
    path: ["/:domainName/dine-in", "/dine-in"],
    component: DineInHome,
    exact: true,
  },
  {
    path: [
      "/:domainName/dine-in/item/:itemId/:catId",
      "/dine-in/item/:itemId/:catId",
    ],
    component: DineInItem,
    exact: true,
  },
  {
    path: [
      "/:domainName/dine-in/auth/verification",
      "/dine-in/auth/verification",
    ],
    component: DineInVerification,
    exact: true,
  },
  {
    path: ["/:domainName/dine-in/item-image", "/dine-in/item-image"],
    component: Zoom,
    exact: true,
  },
  {
    path: ["/:domainName/dine-in/auth/login", "/dine-in/auth/login"],
    component: DineInLogin,
    exact: true,
  },
  {
    path: [
      "/:domainName/dine-in/track-order/:orderId",
      "/dine-in/track-order/:orderId",
    ],
    component: DineInTrackOrder,
    exact: true,
  },
  {
    path: [
      "/:domainName/dine-in/order/order-feedback/:orderId",
      "/dine-in/order/order-feedback/:orderId",
    ],
    component: OrderFeedback,
    exact: true,
  },
];
export const DineInProtectedRoutes = [
  {
    path: ["/:domainName/dine-in/checkout", "/dine-in/checkout"],
    component: DineInCheckout,
    exact: true,
  },
  {
    path: ["/:domainName/dine-in/order-detail", "/dine-in/order-detail"],
    component: DineInOrderDetail,
    exact: true,
  },
  {
    path: ["/:domainName/dine-in/my-orders", "/dine-in/my-orders"],
    component: DineInOrderList,
    exact: true,
  },

  {
    path: ["/:domainName/dine-in/auth/settings", "/dine-in/auth/settings"],
    component: DineInSetting,
    exact: true,
  },
  {
    path: [
      "/:domainName/dine-in/order/order-success/:orderId",
      "/dine-in/order/order-success/:orderId",
    ],
    component: DineInOrderSuccess,
    exact: true,
  },
];

export {
  StaticPublicPages,
  PublicRoutesArray,
  ProtectedRoutesArray,
  PreviewRoutes,
  EcommercePublicRoutes,
  EcommerceProtectedRoutes,
};

const nodes = [
  StaticPublicPages,
  PublicRoutesArray,
  ProtectedRoutesArray,
  PreviewRoutes,
  EcommerceProtectedRoutes,
  EcommercePublicRoutes,
  DineInPublicRoutes,
];

var routes = nodes.reduce(function (r, o) {
  r = r.concat(o);
  return r;
}, []);
export default routes;
