import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  discountLoader: false,
  error: null,
  message: null,
  discountInfo: null,
  isDiscountApplied: false,
};

const discountsSlice = createSlice({
  name: "discounts",
  initialState,
  reducers: {
    validateDiscount: (state) => {
      state.discountLoader = true;
      state.error = false;
    },
    validateDiscountSuccess: (state, action) => {
      state.discountLoader = false;
      state.error = false;
      state.message = action.payload;
    },
    validateDiscountFailure: (state, action) => {
      state.discountLoader = false;
      state.error = action.payload;
    },
    setDiscountCode: (state, action) => {
      state.error = null;
      state.discountInfo = action.payload;
    },
    removeDiscountCode: (state) => {
      state.discountInfo = null;
      state.error = null;
    },
    setCoponValidation: (state, action) => {
      state.isDiscountApplied = action.payload;
    },
  },
});

export const {
  setCoponValidation,

  validateDiscount,
  validateDiscountFailure,
  validateDiscountSuccess,
  setDiscountCode,
  removeDiscountCode,
} = discountsSlice.actions;
export default discountsSlice.reducer;
