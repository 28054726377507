import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Footer from "components/Footer";
import { Spinner } from "components";
import { FixedFooter } from "components";
import ViewBasket from "components/basket/ViewBasket";
import { useParams } from "react-router-dom";
import { useCustomHistory } from "hooks";
import * as ROUTE_CONSTANTS from "routes/constants";
const MenuContainer = (props) => {
  const { DomainType } = useSelector((state) => state.AppReducer);
  const router = useCustomHistory();
  const params = useParams();
  const { isLoading } = useSelector((state) => state?.loader);
  const viewBasketPage = () => {
    let url = ROUTE_CONSTANTS.ECOMMERCE_CHECKOUT[1];
    router(url, `/${params?.domainName}${url}`, DomainType);
  };
  if (isLoading) return <Spinner />;
  return (
    <Fragment>
      {props?.children}
      <Footer />
      <FixedFooter basketFooter={true} page={true} from="menu">
        <ViewBasket {...{ viewBasketPage }} />
      </FixedFooter>
    </Fragment>
  );
};

export default MenuContainer;
