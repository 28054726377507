import { useMapContext } from "contexts/googleMap";
import { useEffect } from "react";
import { handleLocalStorage } from "utils/storage";
import { useDispatch, useSelector } from "react-redux";
import { DeliveryAddressAction } from "store/actions";

function usePreFillLocalStorageAddress() {
  const {
    setUserLocation,
    setStreetAddress,
    setZoom,
    googlemapState,
    updateMapContext,
  } = useMapContext();

  const dispatch = useDispatch();
  const { temporarymapAddress } = useSelector(
    (state) => state.DeliveryAddressReducer
  );
  const ifAddressStoredOnLocalStorage = (storedAddress) => {
    if (
      storedAddress?.coordinates?.lat &&
      storedAddress?.coordinates?.lng &&
      storedAddress?.street &&
      storedAddress?.street !== "" &&
      storedAddress?.coordinates?.lat !== 23 &&
      storedAddress?.coordinates?.lng !== 54
    )
      return true;
  };
  useEffect(() => {
    const storedAddress = handleLocalStorage.getItem("deliveryAddress");
    if (
      ifAddressStoredOnLocalStorage(storedAddress) &&
      !temporarymapAddress?.streetAddress
    ) {
      updateMapContext("addressUpdated", !googlemapState?.addressUpdated);
      const latlng = {
        lat: storedAddress.coordinates.lat,
        lng: storedAddress.coordinates.lng,
      };
      dispatch(
        DeliveryAddressAction.updateTempStreetAddress(storedAddress.street)
      );
      dispatch(DeliveryAddressAction.setTempZoom(16));
      dispatch(DeliveryAddressAction.setTempUserLocation(latlng));
    }
    if (
      ifAddressStoredOnLocalStorage(storedAddress) &&
      !googlemapState?.mapAddress?.streetAddress
    ) {
      // updating the map context
      updateMapContext("addressUpdated", !googlemapState?.addressUpdated);
      setStreetAddress(storedAddress.street);
      const latlng = {
        lat: storedAddress.coordinates.lat,
        lng: storedAddress.coordinates.lng,
      };
      setUserLocation(latlng);
      setZoom(16);
    }
  }, []);

  // You can remove the return statement since it's not necessary
}

export default usePreFillLocalStorageAddress;
