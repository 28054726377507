import { useEffect } from "react";
import { orderTimingAction } from "store/actions";
import { getOrderTimingType } from "utils/storeUtils";
import { useDispatch, useSelector } from "react-redux";

function useSetOrderTiming() {
  const dispatch = useDispatch();
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);
  const { orderType } = useSelector((state) => state?.AppReducer);
  const { selectedStore } = useSelector((state) => state?.store);
  const setORderTiming = () => {
    if (orderTiming?.TimingType) return;
    const newTiming = getOrderTimingType(selectedStore, orderType?.serviceType);
    dispatch(orderTimingAction.setOrderTiming(newTiming));
  };

  useEffect(() => {
    setORderTiming();
  }, [selectedStore?._id, orderType?.serviceType]);
  return null;
}

export default useSetOrderTiming;
