import React from "react";
import moment from "moment";
import { t } from "i18next";
import { useLang } from "hooks";
import C from "Constants";

const OrderGeneralDetail = ({ order }) => {
  const { currentLang } = useLang();

  const getDeliveryAddress = () => {
    let add = "";
    add += order.deliveryAddress ? order.deliveryAddress.address : "....";
    add +=
      order?.deliveryAddress?.building ||
      order?.deliveryAddress?.floor ||
      order?.deliveryAddress?.appartmentNo
        ? "("
        : "";

    add += order?.deliveryAddress?.appartmentNo
      ? t("Apartment") + order?.deliveryAddress?.appartmentNo
      : "";

    add +=
      order?.deliveryAddress?.appartmentNo && order?.deliveryAddress?.building
        ? ", "
        : "";

    add += order?.deliveryAddress?.building
      ? t("Building") + order?.deliveryAddress?.building
      : "";
    add +=
      order?.deliveryAddress?.building && order?.deliveryAddress?.floor
        ? ", "
        : "";

    add += order?.deliveryAddress?.floor
      ? t("Floor") + order?.deliveryAddress?.floor
      : "";

    add +=
      order?.deliveryAddress?.building ||
      order?.deliveryAddress?.floor ||
      order?.deliveryAddress?.appartmentNo
        ? ")"
        : "";

    return add;
  };

  return (
    <>
      <div className="p-4 flex flex-row justify-between text-brand-text-primary bg-brand-base-primary text-white">
        <div className="text-16x text-brand-text-primary">
          {t(order?.orderStatus)}
        </div>
        <div className="text-16x font-semibold text-brand-text-primary">
          {moment.unix(order?.orderPlacedTime).format("DD-MM-YYYY")}
        </div>
      </div>
      <div className="p-4 flex flex-row justify-between pt-6 bg-white">
        <div>
          <h2 className="text-16x font-semibold">{t("Submitted on")}</h2>

          <p className="text-left">
            {moment.unix(order?.orderPlacedTime).format("DD-MM-YYYY")}
            <br /> {moment.unix(order?.orderPlacedTime).format("hh:mm A")}
          </p>
        </div>
        <div>
          <h2 className="text-16x font-semibold">{t(order?.orderStatus)}</h2>
          <p className="text-right">
            {moment.unix(order?.orderPlacedTime).format("DD-MM-YYYY")}
            <br /> {moment.unix(order?.orderPlacedTime).format("hh:mm A")}
          </p>
        </div>
      </div>
      <div className="flex flex-col p-4 bg-white">
        <div>{t("From")}</div>
        <div className="text-16x font-semibold">
          {order?.storeInfo?.baseLanguage?.name}
        </div>
        <div> {order?.storeInfo?.manualAddress}</div>
        <div>{order?.storeInfo?.city}</div>
        <div>
          <span dir="ltr">{order?.storeInfo?.mobileNumber}</span>
        </div>
      </div>
      <div className="flex flex-col p-4 bg-white">
        <div>{t("To")}</div>
        {/* <div className="text-16x font-semibold">Muhammad Hassan</div> */}
        {order?.orderType?.type === C.SERVICE_TYPE.DELIVERY ? (
          <div className="break-words">{getDeliveryAddress()}</div>
        ) : null}
        <div className="break-words">
          {order?.selectedArea?.[`areaName${currentLang}`]}
        </div>

        <div>
          <span dir="ltr">{order?.userInfo?.phoneNumber}</span>
        </div>
      </div>
      <hr className="border-b-1 border-gray-200" />
    </>
  );
};

export default OrderGeneralDetail;
