import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import rootReducer from "./reducers";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import rootSaga from "./sagas";
import { parse, stringify } from "flatted";
import { apiSlice } from "apiHelpers/ApiSlice";
import * as Sentry from "@sentry/react";
import crashReporter from "utils/crash_reporter";
import sessionStorage from "redux-persist/lib/storage/session";
import createSentryMiddleware from "redux-sentry-middleware";
import LogRocket from "logrocket";

export const transformCircular = createTransform(
  (inboundState, key) => stringify(inboundState),
  (outboundState, key) => parse(outboundState)
);
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const persistConfig = {
  key: "root",
  transforms: [transformCircular],
  storage: sessionStorage,
  blacklist: [
    "logout",
    "brand",
    "basket",
    "selectedItem",
    "NotPersist",
    "discounts",
    "payment",
    "loader",
    "userAddresses",
    // "AddNewAddress",
    "errorSlice",
  ],
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [
  apiSlice.middleware,
  sagaMiddleware,
  crashReporter,
  createSentryMiddleware(Sentry, {}),
];

const shouldTrack = !["beta", "development"].includes(
  process.env.REACT_APP_ENV
);

if (shouldTrack) {
  middlewares.push(LogRocket.reduxMiddleware());
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
  devTools:
    process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_ENV === "prelive"
      ? false
      : true,
  enhancers: [sentryReduxEnhancer],
});

if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "prelive"
) {
  console.log = console.error = console.assert = console.warn = () => {};
}

sagaMiddleware.run(rootSaga);
setupListeners(store.dispatch);
export const persistor = persistStore(store);
export default store;
