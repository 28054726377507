import React from "react";
import Verification from "components/verification/containers/Verification";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCustomHistory } from "hooks";
import useQueryParam from "modules/dine-in/useQueryParam";
function VerificationC() {
  const params = useParams();
  const router = useCustomHistory();
  const queryParam = useQueryParam();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const goToLogin = () => {
    router(
      "/dine-in/auth/login",
      `/${params?.domainName}/dine-in/auth/login`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };

  const goToBasket = () => {
    router(
      `/dine-in/`,
      `/${params.domainName}/dine-in/checkout/`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };

  const goToHomePage = () => {
    router(
      `/dine-in/`,
      `/${params.domainName}/dine-in/`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };

  return (
    <Verification
      {...{
        goToLogin,
        goToBasket,
        goToHomePage,
      }}
    />
  );
}

export default VerificationC;
