import React from "react";
import C from "Constants";
import { useSelector } from "react-redux";
import { Route, Redirect, useParams } from "react-router-dom";
import queryString from "query-string";
import { UTILS } from "utils";

const ProtectedRoute = ({ component: Component, isAuthProtected, ...rest }) => {
  let params = useParams();

  const { DomainType } = useSelector((state) => state.AppReducer);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const currentUser = UTILS.getCurrentUser(brandInfo?.brand?._id);

  const queryParams = queryString.parse(window.location.search);
  let pathname = "/dine-in/auth/login";
  // if (DomainType !== C.CUSTOM_DOMAIN) {
  //   pathname = `/${
  //     brandInfo?.setting?.temporaryDomainName ||
  //     params?.domainName ||
  //     rest.location?.pathname
  //   }${pathname}`;
  // }
  if (DomainType !== C.CUSTOM_DOMAIN) {
    if (brandInfo?.setting?.temporaryDomainName) {
      pathname = `/${brandInfo?.setting?.temporaryDomainName}${pathname}`;
    } else if (params?.domainName) {
      pathname = `/${params?.domainName}${pathname}`;
    } else if (rest?.location?.pathname) {
      pathname = `${rest.location?.pathname}${pathname}`;
    } else {
      const splitPath = window?.location?.pathname?.split("/");
      const domainName = splitPath[1];
      pathname = `/${domainName}${pathname}`;
    }
  }
  const queryParam = `${
    queryParams?.[C.DINE_SERVICE_TYPE.STORE_ID]
      ? `${C.DINE_SERVICE_TYPE.STORE_ID}=${
          queryParams?.[C.DINE_SERVICE_TYPE.STORE_ID]
        }&`
      : ""
  }${C.DINE_SERVICE_TYPE.SERVICE_TYPE}=${C.DINE_SERVICE_TYPE.QUERY_PARAM}${
    queryParams?.[C.DINE_SERVICE_TYPE.TABLE_NUMBER]
      ? `&${C.DINE_SERVICE_TYPE.TABLE_NUMBER}=${
          queryParams?.[C.DINE_SERVICE_TYPE.TABLE_NUMBER]
        }`
      : ""
  }`;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !currentUser?.token) {
          return (
            <Redirect
              to={{
                pathname,
                state: { from: props.location },
                search: `?${queryParam}`,
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
