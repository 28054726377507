import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { MinusIconSmall, PlusIconSmall } from "icons";
import TextareaInput from "components/TextareaInput";
import EmptyBasket from "components/basket/EmptyBasket";
import ConfirmModal from "components/Modals/ConfirmModal";
import DiscountButton from "components/basket/DiscountButton";
import { Spinner, ErrorComponent, CustomModal } from "components";
import ItemsPaymentTotalDetail from "components/ItemsPaymentTotalDetail";
import LoyaltyCreditWidget from "components/brand-loyalty/LoyaltyCreditWidget";
import LoyaltyRedeemSwitch from "components/brand-loyalty/LoyaltyRedeemSwitch";
import { min } from "lodash";
import { useCustomHistory, useLang, useUserWallet } from "hooks";

import {
  CartAction,
  LoyaltyAction,
  DiscountAction,
  NotPersistAction,
} from "store/actions";

import C from "Constants";
import * as ROUTE_CONSTANTS from "routes/constants";
import { calculateCartGrossAmount } from "utils/Utils";
import DiscounteditemsList from "components/DiscounteditemsList";
import AddMoreITemsButton from "components/AddMoreITemsButton";
import { ViewApplicableDiscountedItemsList } from "components/DiscounteditemsList";

const STORE_PAGE_URL = ROUTE_CONSTANTS.OUTLET_STORE[1];

function BasketBody() {
  //hooks
  const dispatch = useDispatch();
  const params = useParams();
  const { currentLang } = useLang();
  const router = useCustomHistory();
  const userWallet = useUserWallet();

  //redux state
  const { error } = useSelector((state) => state?.Discounts);
  const { discountModal } = useSelector((state) => state?.NotPersist);
  const { selectedStore } = useSelector((state) => state?.store);
  const { loyaltyPolicy } = useSelector((state) => state?.brandStorageReducer);
  const { isLoading } = useSelector((state) => state?.loader);
  const { discountInfo, isDiscountApplied } = useSelector(
    (state) => state?.Discounts
  );
  const { cart, cartTotal, amountAfterDiscount, orderSplReq, deliveryFee } =
    useSelector((state) => state?.cart);

  const { loyaltyApplied, loyaltyAmount } = useSelector(
    (state) => state?.Loyalty
  );

  //local state
  const [confirmationCallback, setConfirmationCallback] = useState(
    () => () => {}
  );
  const [confirmModalTitle, setConfirmModalTitle] = useState({
    title: "",
    message: "",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [redeemAmount, setRedeemAmount] = useState(0);
  const { DomainType } = useSelector((state) => state.AppReducer);
  useEffect(() => {
    updateCart();
    //eslint-disable-next-line
  }, [
    cart,
    discountInfo,
    loyaltyAmount,
    loyaltyApplied,
    userWallet,
    deliveryFee,
  ]);

  //updating cart info
  const updateCart = () => {
    const { itemsGrossAmount, itemsNetPrice, orderGrossAmount } =
      calculateCartGrossAmount(cart, discountInfo, userWallet);
    dispatch(
      CartAction.updateCartPrice({
        originalPrice: itemsNetPrice,
        amountAfterDiscount: itemsGrossAmount,
        itemsGrossAmount,
        orderGrossAmount,
      })
    );
  };

  //increase and decrease item quantity
  const increaseItemQuantity = (id, index) => {
    const payload = { id, index };
    dispatch(CartAction.increaseItemQuantity(payload));
    updateCart();
  };
  const decreaseItemQuantity = (id, index) => {
    const payload = { id, index };
    dispatch(CartAction.decreaseItemQuantity(payload));
    updateCart();
  };

  const emptyBasket = () => {
    router(
      `${STORE_PAGE_URL}`,
      `/${params?.domainName}${STORE_PAGE_URL}`,
      DomainType
    );
  };

  const switchLoyaltyStatus = () => {
    if (loyaltyApplied) {
      dispatch(LoyaltyAction.removeLoyalty());
      updateCart();
    } else {
      dispatch(LoyaltyAction.applyLoyalty(Number(redeemAmount)));
      updateCart();
    }
  };
  const calculateRedeemAmount = () => {
    const maxRedeemableValuePerOrder =
      loyaltyPolicy?.maxRedeemableValuePerOrder || 0;
    const redeemablePercentagePerOrder =
      loyaltyPolicy?.redeemablePercentagePerOrder || 0;
    const redeemableAmount = Number(
      (cartTotal / 100) * redeemablePercentagePerOrder
    );
    let minimum = min([
      maxRedeemableValuePerOrder,
      redeemableAmount,
      userWallet,
    ])?.toFixed(2);
    setRedeemAmount(Number(minimum));
    dispatch(LoyaltyAction.updateloyaltyAmount(Number(minimum)));
  };

  useEffect(() => {
    calculateRedeemAmount();
    //eslint-disable-next-line
  }, [cartTotal, loyaltyPolicy, cart, userWallet]);

  //
  const openModal = () => {
    dispatch(NotPersistAction.toggleDiscountModal(true));
  };

  const handleConfirmDiscountLoyalty = (wantsToApply) => {
    if (loyaltyApplied && wantsToApply === C.DISCOUNT) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use discount code"),
        message: t(
          "To use discount code, the loyalty points will be removed. Do you want to continue"
        ),
      });
      setConfirmationCallback(() => () => {
        dispatch(LoyaltyAction.removeLoyalty());
        openModal();
        updateCart();
      });
    } else if (
      (isDiscountApplied || discountInfo) &&
      wantsToApply === C.LOYALTY
    ) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use loyalty credits"),
        message: t(
          "To use loyalty points, the discount code will be removed. Do you want to continue"
        ),
      });
      setConfirmationCallback(() => () => {
        dispatch(DiscountAction.setCoponValidation(false));
        dispatch(DiscountAction.removeDiscountCode());
        switchLoyaltyStatus();
        updateCart();
      });
    } else if (wantsToApply === C.LOYALTY) {
      switchLoyaltyStatus();
    } else if (wantsToApply === C.DISCOUNT) {
      openModal();
    }
  };

  const handleConfirmModal = () => {
    setConfirmModal(false);
    confirmationCallback();
  };

  const handleCancelModal = () => setConfirmModal(false);

  const redirectToStorePage = () => {
    const STORE_PAGE_URL = "/store/store-detail";
    router(
      STORE_PAGE_URL,
      `/${params?.domainName}${STORE_PAGE_URL}`,
      DomainType
    );
  };

  if (isLoading) return <Spinner />;
  if (cart?.length > 0) {
    return (
      <div className="overflow-y-auto self-stretch">
        <div className="basket-contents__items px-4 py-6 border-b-4 border-black-100">
          <ul className="basket-contents__item-list">
            {cart?.map((item, index) => {
              return (
                <li
                  className="basket-item flex justify-between items-start flex-row flex-100 mb-4 text-16x"
                  key={index}
                >
                  <span className="item flex flex-auto justify-start flex-wrap">
                    <div className="flex-100">
                      <div className="text-outer-space block leading-22x">
                        {item?.baseLanguage?.title}
                      </div>
                      <div className="text-outer-space leading-22x">
                        {currentLang === "AR"
                          ? item?.price?.toFixed(2) + " " + t("AED")
                          : t("AED") + " " + item?.price?.toFixed(2)}
                      </div>
                      {item?.sections?.map((section, index) => {
                        return (
                          <div className=" first-word-capital" key={index}>
                            <span className="bold-font-size text-15x text-gray-500">
                              {section?.baseLanguage?.sectionName}
                            </span>

                            {section?.options?.map((option, index) => {
                              return (
                                <div key={option._id}>
                                  <span className="text-13x ml-2">
                                    <span className="me-1">
                                      {option?.quantity ? option?.quantity : 1}{" "}
                                      x
                                    </span>
                                    {option?.baseLanguage?.optionName}
                                    <span className="ml-1  text-13x text-gray-400 ">
                                      {`(${option?.price?.toFixed(2)} AED)`}
                                    </span>
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </span>
                  <span className="mx-2">
                    <div className="inline-flex center relative flex-flex-4">
                      <span
                        className="inline-flex mx-1"
                        onClick={() => decreaseItemQuantity(item?._id, index)}
                      >
                        <button className="" type="button">
                          <div className="">
                            <span>
                              <MinusIconSmall />
                            </span>
                          </div>
                        </button>
                      </span>
                      <span className="inline-flex self-center en-font">
                        <span className="text-20x mx-2">{item?.quantity}</span>
                      </span>

                      <span
                        className="inline-flex mx-1"
                        onClick={() => increaseItemQuantity(item?._id, index)}
                      >
                        <button
                          className=""
                          type="button"
                          aria-label="Increase Quantity"
                        >
                          <div className="">
                            <span className="">
                              <PlusIconSmall />
                            </span>
                          </div>
                        </button>
                      </span>
                    </div>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <AddMoreITemsButton handleRedirect={redirectToStorePage} />

        <div className="my-4">
          <div className="px-4 pb-6 mt-6">
            <label className="text-16x font-bold mb-4">
              {t("Special instructions")}
            </label>
            <TextareaInput
              onChange={(e) =>
                dispatch(CartAction.setOrderSplreq(e.target.value))
              }
              value={orderSplReq}
              placeholder={t("Add a note or any instructions.")}
              styleClasses="mt-1 block w-full text-16x text-outer-space mt-2 p-1 bg-gray-100  border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <DiscountButton
            buttonText={t("Get discount code")}
            handleDiscount={() => handleConfirmDiscountLoyalty(C.DISCOUNT)}
          />
          {!discountModal && error && cart?.length >= 1 && (
            <ErrorComponent error={error} />
          )}
          <ViewApplicableDiscountedItemsList />
          <DiscounteditemsList couponCode={null} />
          {loyaltyPolicy?.isLoyaltyActive && (
            <LoyaltyCreditWidget {...{ loyaltyPolicy }} />
          )}

          <ItemsPaymentTotalDetail
            original={cartTotal}
            deliveryFee={deliveryFee}
            taxRate={selectedStore?.taxRate ?? 0}
            amountAfterDiscount={amountAfterDiscount}
            userWallet={userWallet}
          >
            {loyaltyPolicy?.isLoyaltyActive && userWallet > 0 && (
              <LoyaltyRedeemSwitch
                loyaltyPolicy={loyaltyPolicy}
                cartTotal={cartTotal}
                amountAfterDiscount={amountAfterDiscount}
                userWallet={userWallet}
                switchLoyaltyStatus={() =>
                  handleConfirmDiscountLoyalty(C.LOYALTY)
                }
                redeemAmount={redeemAmount}
              />
            )}
          </ItemsPaymentTotalDetail>
        </div>
        <CustomModal
          open={confirmModal}
          setOpen={setConfirmModal}
          bodyclasses="flex"
          overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
        >
          <ConfirmModal
            onConfirm={handleConfirmModal}
            onCancel={handleCancelModal}
            title={confirmModalTitle}
          />
        </CustomModal>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <EmptyBasket backToHome={emptyBasket} />
      </div>
    );
  }
}

export default BasketBody;
