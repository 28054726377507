import React from "react";
import { useLang } from "hooks";
function RadioCheck({ color }) {
  return (
    <svg
      height="24"
      width="24"
      viewBox="0 0 24 24"
      // className="text-brand-base-primary"
      className={` ${color ? color : "text-brand-base-primary"}`}
    >
      <path
        fill="currentColor"
        d="M12 2C17.5228 2 22 6.47725 22 12C22 17.5228 17.5228 22 12 22C6.47717 22 2 17.5228 2 12C2 6.47725 6.47717 2 12 2ZM12 20C16.4113 20 20 16.4113 20 12C20 7.58875 16.4113 4 12 4C7.58875 4 4 7.58875 4 12C4 16.4113 7.58875 20 12 20ZM12 7C14.7571 7 17 9.24302 17 12C17 14.7571 14.7571 17 12 17C9.24302 17 7 14.7571 7 12C7 9.24302 9.24302 7 12 7Z"
      ></path>
    </svg>
  );
}

function Close() {
  return (
    <svg
      height="24"
      width="24"
      viewBox="0 0 24 24"
      className="h-4 w-4 text-brand-base-primary"
    >
      <path
        fill="currentColor"
        d="M12.0001 10.2322L5.88398 4.11612L4.11621 5.88389L10.2323 12L4.11621 18.1161L5.88398 19.8839L12.0001 13.7678L18.1162 19.8839L19.884 18.1161L13.7679 12L19.884 5.88389L18.1162 4.11612L12.0001 10.2322Z"
      ></path>
    </svg>
  );
}
function RadioUnCheck({ color }) {
  return (
    <svg
      height="24"
      width="24"
      viewBox="0 0 24 24"
      className={` ${color ? color : "text-brand-base-primary"}`}
    >
      <path
        fill="currentColor"
        d="M12 20C7.58875 20 4 16.4113 4 12C4 7.58875 7.58875 4 12 4C16.4113 4 20 7.58875 20 12C20 16.4113 16.4113 20 12 20ZM12 2C6.47717 2 2 6.47725 2 12C2 17.5228 6.47717 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47725 17.5228 2 12 2Z"
      ></path>
    </svg>
  );
}

function BackArrow() {
  const { currentLang } = useLang();
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      data-spec="icon-arrow-left"
      className={`w-5 h-5 text-brand-base-primary bb-ar ${
        currentLang === "AR" ? "rotate-180" : ""
      }`}
    >
      <path
        d="M18 10.467H2M9.467 17.933L2 10.467 9.467 3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
}
function Tick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-5 h-5"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.962 15.548l-3.255-3.255-1.414 1.414 4 4a1 1 0 001.45-.038l9-10-1.486-1.338-8.295 9.217z"
        fill="CurrentColor"
      ></path>
    </svg>
  );
}
function CycleIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="https://www.w3.org/2000/svg"
      className="w-5 h-5 text-brand-base-primary"
    >
      <path
        d="M15.625 8.206c-.39 0-.762.073-1.11.2l-1.14-2.16v-.877h1.254c.181 0 .328.154.328.343a.335.335 0 01-.328.342.575.575 0 00-.563.587c0 .324.252.587.563.587.801 0 1.453-.68 1.453-1.516 0-.74-.51-1.355-1.183-1.488a.524.524 0 00-.165-.029h-1.921a.575.575 0 00-.563.587V5.81H8.072l-.274-.636h.89c.31 0 .562-.263.562-.587A.575.575 0 008.687 4H5.172a.575.575 0 00-.563.587c0 .324.252.587.563.587h1.393l.316.734a.57.57 0 00-.186.205l-1.21 2.293c-.348-.127-.72-.2-1.11-.2C2.514 8.206 1 9.786 1 11.728c0 1.942 1.514 3.522 3.375 3.522 1.669 0 3.055-1.272 3.324-2.935H10c.024 0 .05-.004.073-.007.014-.002.028-.002.041-.005.007-.002.013-.005.02-.006l.033-.011c.019-.006.039-.012.058-.02l.005-.002c.012-.006.021-.013.032-.02.01-.005.021-.01.032-.017l.027-.018c.015-.01.029-.024.043-.037.011-.01.024-.019.035-.03l.005-.007c.014-.014.024-.03.036-.045.01-.015.022-.028.031-.043l.015-.027.007-.009 2.32-4.397.716 1.359a3.57 3.57 0 00-1.28 2.755c0 1.942 1.515 3.522 3.376 3.522S19 13.67 19 11.728c0-1.942-1.514-3.522-3.375-3.522zm-3.764-1.223l-1.81 3.43-1.474-3.43h3.284zm-4.438.184l1.708 3.974H7.7a3.55 3.55 0 00-1.23-2.169l.952-1.805zm-.872 3.974H5.327l.59-1.119c.303.3.526.685.634 1.119zm-2.176 2.934c-1.24 0-2.25-1.052-2.25-2.347s1.01-2.348 2.25-2.348c.192 0 .378.028.557.076l-1.05 1.988-.01.027a.48.48 0 00-.04.112.55.55 0 00-.016.117c0 .01-.003.019-.003.028l.002.025a.9.9 0 00.016.116.59.59 0 00.036.107.585.585 0 00.026.05.44.44 0 00.064.091.611.611 0 00.127.111l.02.014c.008.005.017.007.025.012a.494.494 0 00.108.041.466.466 0 00.114.018l.024.002h2.176c-.251 1.01-1.13 1.76-2.176 1.76zm11.25 0c-1.24 0-2.25-1.052-2.25-2.347 0-.672.273-1.278.708-1.706l1.05 1.989a.558.558 0 00.493.303.538.538 0 00.27-.072.6.6 0 00.222-.798l-1.05-1.989a2.19 2.19 0 01.558-.075c1.24 0 2.25 1.053 2.25 2.347 0 1.296-1.01 2.348-2.25 2.348z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
function DownArrowFilled() {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="https://www.w3.org/2000/svg"
      className="h-5 w-5 text-brand-base-primary"
    >
      <path
        d="M7 8l3 3.667L13 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
}
function DownArrow() {
  return (
    <svg viewBox="0 0 24 24" className="h-18x w-18x">
      <path
        fill="currentColor"
        className="text-brand-base-primary"
        d="M4.88398 7.11612L3.11621 8.88389L12.0001 17.7678L20.884 8.88389L19.1162 7.11612L12.0001 14.2322L4.88398 7.11612Z"
      ></path>
    </svg>
  );
}
function BasketBag() {
  return (
    <svg
      viewBox="0 0 23 23"
      xmlns="https://www.w3.org/2000/svg"
      fill="currentColor"
      className="w-6 h-6 text-brand-text-primary"
    >
      <path d="M21.133 7.89a3.836 3.836 0 00-3.906-3.421h-1.11a4.687 4.687 0 00-9.234 0H5.758A3.836 3.836 0 001.852 7.89L.875 18.328a3.656 3.656 0 00.953 2.828 3.984 3.984 0 002.938 1.282h13.468a3.984 3.984 0 002.938-1.282 3.655 3.655 0 00.953-2.828l-.992-10.437zM11.5 2.126a3.125 3.125 0 013.016 2.344H8.484A3.125 3.125 0 0111.5 2.125zm8.516 17.969a2.398 2.398 0 01-1.782.781H4.766a2.398 2.398 0 01-1.782-.781 2.101 2.101 0 01-.554-1.625l1-10.43a2.266 2.266 0 012.328-2.008h1.054v1.563a.781.781 0 001.563 0V6.03h6.25v1.563a.781.781 0 101.563 0V6.03h1.054a2.266 2.266 0 012.344 2.008l1 10.438a2.1 2.1 0 01-.57 1.617z"></path>
    </svg>
  );
}

function TimeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="15 15 70 70"
      fill="currentColor"
      className="w-18x h-18x text-brand-base-primary"
    >
      <path d="M50 18c-17.638 0-32 14.363-32 32s14.362 32 32 32c17.638 0 32-14.363 32-32 0-17.638-14.362-32-32-32zm0 6c14.395 0 26 11.605 26 26S64.395 76 50 76 24 64.395 24 50s11.605-26 26-26zm0 6a3 3 0 00-3 3v17c0 .828.332 1.582.875 2.125L58.5 62.719a2.971 2.971 0 004.219 0 2.971 2.971 0 000-4.219L53 48.75V33a3 3 0 00-3-3z"></path>
    </svg>
  );
}
function LocationIcon() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      className="w-18x h-18x text-brand-base-primary"
    >
      <path d="M49.6 95c-3.3 0-5.4-2.4-6.5-3.6l-.3-.4c-.3-.3-6.9-7.4-13.3-17.2C21 60.9 16.6 49.5 16.6 39.9c0-19.1 14.5-34 33-34s33 14.9 33 34c0 9.6-4.3 21-12.9 33.9-6.4 9.8-13 16.9-13.3 17.2l-.3.3C55 92.6 52.9 95 49.6 95zm0-79.1c-12.9 0-23 10.5-23 24 0 7.5 3.9 17.3 11.2 28.4 5 7.6 10.2 13.5 11.8 15.3 1.6-1.8 6.7-7.6 11.8-15.3 7.3-11.1 11.2-20.9 11.2-28.4 0-13.5-10.1-24-23-24z"></path>
      <ellipse cx="49.6" cy="38.4" rx="8.5" ry="8.6"></ellipse>
    </svg>
  );
}

export {
  RadioCheck,
  RadioUnCheck,
  BackArrow,
  CycleIcon,
  DownArrowFilled,
  BasketBag,
  LocationIcon,
  DownArrow,
  Tick,
  Close,
  TimeIcon,
};
