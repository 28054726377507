import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  modalLoader: false,
  checkoutLoader: false,
  walletLoader: false,
};

const loadingSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    enableLoader: (state) => {
      state.isLoading = true;
    },
    disableLoader: (state, action) => {
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setModalLoader: (state, action) => {
      state.modalLoader = action.payload;
    },
    togggleCheckoutLoader: (state, action) => {
      state.checkoutLoader = action.payload;
    },
    togglewalletLoader: (state, action) => {
      state.walletLoader = action.payload;
    },
  },
});

export const {
  togglewalletLoader,
  enableLoader,
  disableLoader,
  setLoading,
  setModalLoader,
  togggleCheckoutLoader,
} = loadingSlice.actions;
export default loadingSlice.reducer;
