import React from "react";
import { useTranslation } from "react-i18next";

const LoginSignupButton = ({ goToLogin }) => {
  const { t } = useTranslation();
  return (
    <span
      onClick={goToLogin}
      className="flex justify-center items-center bg-white px-3 py-1 border border-brand-base-primary rounded-lg text-black cursor-pointer"
    >
      {t("Log in")} / {t("Sign up")}
    </span>
  );
};

export default LoginSignupButton;
