import { useEffect, useState } from "react";

function useTouchMoveBlur({ inputRef }) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    const handleFocus = () => {
      setIsInputFocused(true);
    };

    const handleBlur = () => {
      setIsInputFocused(false);
    };

    const handleTouchMove = () => {
      if (isInputFocused) {
        inputRef?.current?.blur(); // Close the keyboard
      }
    };

    // Attach focus and blur event listeners to the input
    inputRef?.current?.addEventListener("focus", handleFocus);
    inputRef?.current?.addEventListener("blur", handleBlur);

    // Check if the window object is available before adding the event listener
    if (typeof window !== "undefined") {
      window.addEventListener("touchmove", handleTouchMove);

      // Clean up the event listener when the component is unmounted
      return () => {
        window.removeEventListener("touchmove", handleTouchMove);
      };
    }

    // Return a dummy cleanup function if the window object is not available
    return () => {};
  }, [isInputFocused, inputRef]);

  return null;
}

export default useTouchMoveBlur;
