import { BrandAction } from "store/actions";
import { StoreAction } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { logErrorOnSentry } from "utils/Utils";
import {
  useGetStoresMenuMutation,
  useGetStoresMenuForPickupMutation,
} from "store/brand-based-store/single-store-service";

function useGetMenu() {
  const [getStoresMenu] = useGetStoresMenuMutation();
  const [getStoresMenuForPickup] = useGetStoresMenuForPickupMutation();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const dispatch = useDispatch();
  const handleSuccessResponse = (data) => {
    if (data?.success === 1 && data?.response === 200) {
      dispatch(BrandAction.getBrandMenuSuccess(data.data.storeMenu));
      dispatch(StoreAction.setSelectedStore(data.data.storeInfo));
      dispatch(BrandAction.getDiscounts({ brandId: brandInfo.brand?._id }));
    }
  };

  const getMenuForPickup = async () => {
    if (!brandInfo?.brand?._id) {
      logErrorOnSentry(
        "brand id is not available for pickup menu ecommerce->Home"
      );
      return;
    }
    const { data } = await getStoresMenuForPickup({
      brandId: brandInfo?.brand?._id,
    });
    handleSuccessResponse(data);
  };
  const getMenuForDelivery = async () => {
    if (!brandInfo?.brand?._id) {
      logErrorOnSentry(
        "brand id is not available for delivery menu ecommerce->Home"
      );
      return;
    }
    const { data } = await getStoresMenu({
      brandId: brandInfo?.brand?._id,
    });
    handleSuccessResponse(data);
  };

  return {
    getMenuForPickup,
    getMenuForDelivery,
  };
}

export default useGetMenu;
