import React from "react";
import { useSelector } from "react-redux";
import C from "Constants";
import moment from "moment";
import { t } from "i18next";

const WidegtUI = ({ children }) => {
  return (
    <div className="mx-4 mb-2 py-3 flex items-center bg-gray-100 px-2 rounded-lg">
      <div className="px-2">
        <div className="bg-primary-light rounded-full flex items-center justify-center w-9 h-9">
          <svg
            viewBox="0 0 20 20"
            xmlns="https://www.w3.org/2000/svg"
            class="w-6 h-6 text-brand-base-primary"
          >
            <path
              d="M15.625 8.206c-.39 0-.762.073-1.11.2l-1.14-2.16v-.877h1.254c.181 0 .328.154.328.343a.335.335 0 01-.328.342.575.575 0 00-.563.587c0 .324.252.587.563.587.801 0 1.453-.68 1.453-1.516 0-.74-.51-1.355-1.183-1.488a.524.524 0 00-.165-.029h-1.921a.575.575 0 00-.563.587V5.81H8.072l-.274-.636h.89c.31 0 .562-.263.562-.587A.575.575 0 008.687 4H5.172a.575.575 0 00-.563.587c0 .324.252.587.563.587h1.393l.316.734a.57.57 0 00-.186.205l-1.21 2.293c-.348-.127-.72-.2-1.11-.2C2.514 8.206 1 9.786 1 11.728c0 1.942 1.514 3.522 3.375 3.522 1.669 0 3.055-1.272 3.324-2.935H10c.024 0 .05-.004.073-.007.014-.002.028-.002.041-.005.007-.002.013-.005.02-.006l.033-.011c.019-.006.039-.012.058-.02l.005-.002c.012-.006.021-.013.032-.02.01-.005.021-.01.032-.017l.027-.018c.015-.01.029-.024.043-.037.011-.01.024-.019.035-.03l.005-.007c.014-.014.024-.03.036-.045.01-.015.022-.028.031-.043l.015-.027.007-.009 2.32-4.397.716 1.359a3.57 3.57 0 00-1.28 2.755c0 1.942 1.515 3.522 3.376 3.522S19 13.67 19 11.728c0-1.942-1.514-3.522-3.375-3.522zm-3.764-1.223l-1.81 3.43-1.474-3.43h3.284zm-4.438.184l1.708 3.974H7.7a3.55 3.55 0 00-1.23-2.169l.952-1.805zm-.872 3.974H5.327l.59-1.119c.303.3.526.685.634 1.119zm-2.176 2.934c-1.24 0-2.25-1.052-2.25-2.347s1.01-2.348 2.25-2.348c.192 0 .378.028.557.076l-1.05 1.988-.01.027a.48.48 0 00-.04.112.55.55 0 00-.016.117c0 .01-.003.019-.003.028l.002.025a.9.9 0 00.016.116.59.59 0 00.036.107.585.585 0 00.026.05.44.44 0 00.064.091.611.611 0 00.127.111l.02.014c.008.005.017.007.025.012a.494.494 0 00.108.041.466.466 0 00.114.018l.024.002h2.176c-.251 1.01-1.13 1.76-2.176 1.76zm11.25 0c-1.24 0-2.25-1.052-2.25-2.347 0-.672.273-1.278.708-1.706l1.05 1.989a.558.558 0 00.493.303.538.538 0 00.27-.072.6.6 0 00.222-.798l-1.05-1.989a2.19 2.19 0 01.558-.075c1.24 0 2.25 1.053 2.25 2.347 0 1.296-1.01 2.348-2.25 2.348z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="text-15x text-black">{children}</div>
    </div>
  );
};

function DeliveryWaveOffWidget() {
  const { orderType } = useSelector((state) => state?.AppReducer);
  const { selectedStore } = useSelector((state) => state?.store);

  if (orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY) {
    if (selectedStore?.deliveryChargesWaiverInfo?.onOrderAmount?.isEnabled) {
      return (
        <WidegtUI>
          <p>
            {t("Special offer: we are offering free delivery on orders over")}{" "}
            <strong>
              {selectedStore?.deliveryChargesWaiverInfo?.onOrderAmount?.amount}
              {t("AED")}
            </strong>
          </p>
        </WidegtUI>
      );
    } else if (
      selectedStore?.deliveryChargesWaiverInfo?.onDateRange?.isEnabled
    ) {
      const { startDateTime, endDateTime } =
        selectedStore?.deliveryChargesWaiverInfo?.onDateRange;

      const currentMoment = moment();
      const endMoment = moment.unix(endDateTime);
      const offerEndTime = moment(endMoment);
      const startMoment = moment.unix(startDateTime);

      if (currentMoment.isBetween(startMoment, offerEndTime)) {
        const remainingTime = offerEndTime.diff(currentMoment, "minutes") + 1;
        let message = "";
        if (remainingTime >= 120) {
          message = t(
            "Happy Hours: order within the next X and get free delivery.",
            {
              remainingTime: `2 hours`,
            }
          );
        } else if (remainingTime >= 60 && remainingTime < 120) {
          message = t(
            "Happy Hours: order within the next X and get free delivery.",
            {
              remainingTime: `1 hour`,
            }
          );
        } else {
          message = t(
            "Happy Hours: order within the next X and get free delivery.",
            {
              remainingTime: `${remainingTime} minutes`,
            }
          );
        }

        return <WidegtUI>{message && <p>{message}</p>}</WidegtUI>;
      } else if (
        currentMoment.isAfter(offerEndTime) &&
        currentMoment.diff(offerEndTime, "minutes") < 15
      ) {
        return (
          <WidegtUI>
            <p>
              {t(
                "We regret to inform you that the happy hour free delivery offer has expired."
              )}
            </p>
          </WidegtUI>
        );
      } else {
        return null; // Offer has ended and more than 15 minutes have passed, don't render anything
      }
    }
  }

  return null;
}

export default DeliveryWaveOffWidget;
