import React from "react";

const OrderStarRating = ({ totalStars, handleStarClick, rating }) => {
  const starElements = [...Array(totalStars)].map((_, index) => (
    <span
      key={index}
      style={{ fontSize: "50px" }}
      className={index < rating ? "star selected" : "star"}
      onClick={() => handleStarClick(index + 1)}
    >
      ★
    </span>
  ));

  return (
    <div className="horizontal-star-rating cursor-pointer">{starElements}</div>
  );
};

export default OrderStarRating;
