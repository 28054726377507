import React from "react";
import { t } from "i18next";

const isStoreClosed = (store) => {
  return (
    store?.isOpened === false && store?.isPreOrderEnabledForDelivery === false
  );
};

function StoreRow({
  name,
  manualAddress,
  avgPrepTime,
  store,
  redirectToStorePage,
  storeObj,
}) {
  return (
    <div
      onClick={() => {
        if (isStoreClosed(store)) {
          return;
        }
        redirectToStorePage(storeObj);
      }}
      className="flex items-center justify-between px-4 w-full py-3 border-b border-gray-200"
    >
      <div className="">
        <div className="font-semibold mb-1 text-16x capitalize ">{name} </div>
        <small className="text-gray-400 mb-1 text-14x uppercase block">
          {manualAddress}
        </small>
        <div className="py-1">
          {store?.isOpened || store?.isPreOrderEnabledForDelivery ? (
            <>
              <span className="me-1 ">{t("Available for")}</span>
              {store?.isOpened && (
                <span className="bg-brand-base-primary text-brand-text-primary text-xs me-2 px-2 py-1 rounded ">
                  {t("Quick Orders")}
                </span>
              )}
              {store?.isPreOrderEnabledForDelivery && (
                <span className=" text-brand-text-primary bg-brand-base-primary text-xs me-2 px-2 py-1 rounded ">
                  {t("Pre Orders")}
                </span>
              )}
            </>
          ) : isStoreClosed(store) ? (
            <span className="bg-red-500 text-white text-xs me-2 px-2 py-1 rounded ">
              {t(" Store is closed")}
            </span>
          ) : null}
        </div>
      </div>
      {/* {avgPrepTime ? (
        <div className="ms-2 flex justify-center items-center text-center">
          {avgPrepTime}
        </div>
      ) : null} */}
    </div>
  );
}

export default StoreRow;
