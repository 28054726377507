import React from "react";
import C from "Constants";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { UTILS } from "utils";
import { useParams } from "react-router-dom";

const getRedirectionPath = (DomainType, brandInfo, params, rest) => {
  let pathname = "/";
  if (DomainType !== C.CUSTOM_DOMAIN) {
    if (brandInfo?.setting?.temporaryDomainName) {
      pathname = `/${brandInfo?.setting?.temporaryDomainName}${pathname}`;
    } else if (params?.domainName) {
      pathname = `/${params?.domainName}${pathname}`;
    } else {
      const splitPath = window?.location?.pathname?.split("/");
      const domainName = splitPath[1];
      pathname = `/${domainName}${pathname}`;
    }
    return pathname;
  } else return "/";
};

const PublicMiddelware = ({ ...rest }) => {
  const params = useParams();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const pathname = getRedirectionPath(DomainType, brandInfo, params, rest);
  UTILS.logErrorOnSentry("user trying to access 404 page", "error");

  return <Redirect to={{ pathname }} />;
};

export default PublicMiddelware;
