import React from "react";
import { useSelector } from "react-redux";
const FixedFooter = (props) => {
  const basket = props.basketFooter === true ? true : false;
  const { cart } = useSelector((state) => state?.cart);
  if (props?.page === true) {
    return (
      <div className={` ${cart?.length > 0 ? "h-20 relative" : "hidden"} `}>
        <div
          className={
            "fixed max-460 flex flex-wrap justify-between bottom-0 p-4 " +
            (basket == true ? " z-19 " : "")
          }
        >
          {props.children}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`relative ${
          props?.screen === "confirmLocation" ? "" : "h-20 "
        }`}
      >
        <div
          className={
            "fixed max-460 flex flex-wrap justify-between bottom-0 p-4 " +
            (basket === true ? " z-19 " : "")
          }
        >
          {props?.children}
        </div>
      </div>
    );
  }
};

export default FixedFooter;
