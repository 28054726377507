import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ConfirmModal from "components/Modals/ConfirmModal";
import { useLang, useCustomHistory } from "hooks";
import { NotPersistAction } from "store/actions";
import { ErrorModal, CustomModal } from "components";
import SelectDeliveryAddress from "components/checkout/SelectDeliveryAddress";
import SaveAddressesList from "components/SaveAddressesList";
import OrderPlaced from "components/checkout/OrderPlaced";
import { ErrorAction } from "store/actions";
import * as ROUTE_CONSTANTS from "routes/constants";
function CheckoutModals(props) {
  let { changeAddressModal, setChangeAddressModal, setDeliveryAddress } = props;
  // Hooks
  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { currentLang } = useLang();
  // Redux States
  const { placedOrder } = useSelector((state) => state.order);
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { orderSuccess } = useSelector((state) => state.NotPersist);
  const { error, errorModaL } = useSelector((state) => state.errorSlice);

  // Functions
  const trackOrder = () => {
    let url = "/track-order/";
    document.body.removeAttribute("style");
    dispatch(NotPersistAction.toggleOrderPlaceModel(false));
    if (placedOrder?.savedOrder) {
      const { savedOrder } = placedOrder;
      router(
        `${url}${savedOrder?.orderNumber}`,
        `/${params.domainName}${url}${savedOrder?.orderNumber}`,
        DomainType
      );
    }
  };

  const goToMapScreen = () => {
    let url = ROUTE_CONSTANTS.ECOMMERCE_MAP_DELIVERY_ADDRESS[1];
    router(url, `/${params?.domainName}${url}`, DomainType);
  };

  return (
    <>
      <CustomModal
        open={errorModaL}
        reduxAction={ErrorAction.setErrorModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ErrorModal
          reduxAction={ErrorAction.setErrorModal(false)}
          message={error}
        />
      </CustomModal>
      <CustomModal
        open={props.confirmModal}
        setOpen={props.setConfirmModal}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ConfirmModal
          onConfirm={props.handleConfirmModal}
          onCancel={props.handleCancelModal}
          title={props.confirmModalTitle}
        />
      </CustomModal>
      <CustomModal
        saveAddressModal={true}
        open={changeAddressModal}
        setOpen={setChangeAddressModal}
        bodyclasses="flex"
        overlayClasses={`modal-container fixed h-screen w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-20 ${
          currentLang === "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <SelectDeliveryAddress handleAddressClick={goToMapScreen}>
          <SaveAddressesList
            savedAddresses={props.addressList}
            deleteSlectedAddress={props.deleteSlectedAddress}
            setAddress={setDeliveryAddress}
          />
        </SelectDeliveryAddress>
      </CustomModal>

      <CustomModal
        open={orderSuccess}
        reduxAction={NotPersistAction.toggleOrderPlaceModel(false)}
        bodyclasses="flex"
        overlayClasses="fixed overflow-y-auto h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40 border-sc"
      >
        <OrderPlaced {...{ trackOrder }} />
      </CustomModal>
    </>
  );
}

export default CheckoutModals;
