import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

import {
  BrandAction,
  NotPersistAction,
  AppAction,
  PaymentCardsAction,
  StoreAction,
  PaymentMethodAction,
  ResetAction,
} from "store/actions";

import { useDeliveryAddressContext } from "contexts/userAddressess";
import useGetSavedAddressList from "service-hooks/useGetSavedAddressList";

import C from "Constants";

function useChangeOrderType({
  setConfirmModal,
  setConfirmModalTitle,
  setConfirmationCallback,
  setOpen,
}) {
  const dispatch = useDispatch();
  const { orderType, shippingMethod } = useSelector(
    (state) => state.AppReducer
  );
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);

  const resetPaymentType = () => {
    dispatch(
      PaymentCardsAction.setstripeSelectedCard({
        cardInfo: {},
        isStoredCard: false,
      })
    );
    dispatch(PaymentCardsAction.setstripePaymentCards([]));
    dispatch(StoreAction.setSelectedPaymentMethod(null));
    dispatch(PaymentMethodAction.setSelectedPaymentType(null));
  };

  const { setAddressState } = useDeliveryAddressContext();
  const { getAddresses } = useGetSavedAddressList({
    fetch: false,
    type: "filtered",
  });
  const openDeliveryModal = async () => {
    if (shippingMethod === C.SHIPPING_METHODS.MAPPED_AREA_BASED) {
      const response = await getAddresses(null, "all");
      response && setAddressState("savedAddressListModal", true);

      return;
    } else {
      dispatch(
        BrandAction.getBrandDeliveryAreas({
          brandId: brandInfo?.brand?._id,
          updateMethod: true,
        })
      );
      dispatch(NotPersistAction.toggleAreaModal(true));
    }
  };

  const openModal = (type) => {
    if (orderType?.EN?.name === type?.serviceType || !type) {
      setOpen(false);
      return;
    }
    if (
      orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY &&
      type?.serviceType === C.SERVICE_TYPE.PICKUP
    ) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use X service", {
          serviceType: t("PICKUP"),
        }),
        message: t("Are you sure you want to choose the service", {
          serviceType: t("PICKUP"),
        }),
      });

      setConfirmationCallback(() => () => {
        dispatch(ResetAction.removeOrderData());
        resetPaymentType();
        dispatch(BrandAction.getBrandStores(brandInfo?.brand?._id));
        dispatch(BrandAction.getDiscounts({ brandId: brandInfo.brand?._id }));
        dispatch(NotPersistAction.togglePickupStoreModal(true));
        dispatch(AppAction.setOrderType(type));
      });
    } else if (
      orderType?.EN?.name === C.SERVICE_TYPE.PICKUP &&
      type?.serviceType === C.SERVICE_TYPE.DELIVERY
    ) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use X service", {
          serviceType: t("DELIVERY"),
        }),
        message: t("Are you sure you want to choose the service", {
          serviceType: t("DELIVERY"),
        }),
      });
      setConfirmationCallback(() => () => {
        openDeliveryModal();
        dispatch(ResetAction.removeOrderData());
        resetPaymentType();
        dispatch(BrandAction.getDiscounts({ brandId: brandInfo.brand?._id }));
        dispatch(AppAction.setOrderType(type));
      });
    } else {
      dispatch(AppAction.setOrderType(type));
    }
    setOpen(false);
  };
  return openModal;
}

export default useChangeOrderType;
