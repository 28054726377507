import { useSelector } from "react-redux";
import C from "Constants";

function useIsStoreSelected() {
  const { orderType } = useSelector((state) => state.AppReducer);
  const { selectedStore, outletBasedSelectedStoreArea } = useSelector(
    (state) => state.store
  );

  const isDeliveryStoreSelected = () => {
    return (
      orderType?.serviceType === C.SERVICE_TYPE.DELIVERY &&
      (!outletBasedSelectedStoreArea || !selectedStore)
    );
  };

  const isPickupStoreSelected = () => {
    return orderType?.serviceType === C.SERVICE_TYPE.PICKUP && !selectedStore;
  };

  const isStoreSelected = () => {
    if (isDeliveryStoreSelected() || isPickupStoreSelected()) {
      return false;
    }
    return true;
  };
  return isStoreSelected;
}

export default useIsStoreSelected;
