import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Frames } from "frames-react";
import { t } from "i18next";
import {
  useGetPaymentLinkInfoMutation,
  useInitiatePaymentLinkMutation,
  useVerifyPaymentLinkMutation,
} from "../../services/service";
import queryString from "query-string";
import { errorHandler } from "apiHelpers/api/errorHandler";
import RestheroSmallFooter from "components/RestheroSmallFooter";
import WithLoading from "components/Hoc/WithLoading";
import { CustomModal, ErrorModal } from "components";
import PaymentLinkVerified from "../components/PaymentLinkVerified";
import { ErrorAction, PaymentLinkAction, OrderAction } from "store/actions";
import { isValidValue, logErrorOnSentry } from "utils/Utils";
import PaymentLinkError from "../components/PaymentLinkError";
import PaymentLinkNotVerified from "../components/PaymentLinkNotVerified";
import C from "Constants";
import { getPaymentLinkRedirectionObj } from "utils/Utils";
import { Loader } from "store/actions";
import WithStripe from "components/Hoc/WithStripe";
import { UTILS } from "utils";
import { useDetectBrowserWallets } from "hooks";

function Index() {
  const supportedBrowserWallets = useDetectBrowserWallets();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [cardProcessing, setcardProcessing] = useState(false);
  const [getPaymentLinkInfo, { isLoading: PaymentLinkInfoLoader }] =
    useGetPaymentLinkInfoMutation();
  const [initiatePaymentLink, { isLoading: initiatePaymentLinkLoader }] =
    useInitiatePaymentLinkMutation();
  const [verifyPaymentLink, { isLoading: verifyPaymentLinkLoader }] =
    useVerifyPaymentLinkMutation();
  const { error, isError, errorModaL } = useSelector(
    (state) => state.errorSlice
  );
  const { orderRef } = useSelector((state) => state.order);
  const {
    cartId,
    paymentLinkInfo,
    isLoading: loader,
  } = useSelector((state) => state.PaymentLinkReducer);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { DomainType } = useSelector((state) => state.AppReducer);

  async function fetchPaymentLinkInfo() {
    if (isValidValue(params?.cartId) && brandInfo?.brand?._id) {
      dispatch(PaymentLinkAction.setCartId(params?.cartId));
      const { data } = await getPaymentLinkInfo({
        cartId: params.cartId,
        brandId: brandInfo.brand._id,
      });
      if (data?.success === 1) {
        const isValidLink = UTILS.validatePaymentLink(brandInfo, data?.data);
        if (!isValidLink) {
          dispatch(
            ErrorAction.setError({
              error: t(
                "The payment link gateway is not enabled, please contact the store"
              ),
              modal: true,
            })
          );
          dispatch(PaymentLinkAction.setpaymentLinkInfo(null));
          return;
        }
      }
    } else {
      dispatch(
        ErrorAction.setError({
          error: "Invalid cart Id",
          modal: false,
        })
      );
    }
  }
  useEffect(() => {
    fetchPaymentLinkInfo();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandInfo?.brand?._id]);

  const payNow = async () => {
    sessionStorage.removeItem("paymentVerified");
    dispatch(OrderAction.setOrderRef(null));
    try {
      dispatch(
        ErrorAction.setError({
          error: null,
          modal: false,
        })
      );
      const isCardValid = Frames.isCardValid();
      if (isCardValid) {
        setcardProcessing(true);
        await Frames.submitCard();
        setcardProcessing(false);
      } else {
        dispatch(
          ErrorAction.setError({
            error: t("pleaseEnterValidCardDetails"),
            modal: true,
          })
        );
      }
    } catch (e) {
      dispatch(
        ErrorAction.setError({
          error: errorHandler(e),
          modal: true,
        })
      );
    }
  };

  const captureCheckoutDotComPayment = async (token) => {
    if (!isValidValue(token)) {
      dispatch(
        ErrorAction.setError({
          error: t("cardProccesingError"),
          modal: true,
        })
      );
      return;
    }
    if (isValidValue(cartId) && brandInfo?.brand?._id) {
      let obj = {
        token,
        cartId,
        brandId: brandInfo.brand._id,
        DomainType: DomainType,
        domainName: params?.domainName,
      };
      const paymentRedirectionObj = getPaymentLinkRedirectionObj(obj);
      const { data: res } = await initiatePaymentLink(paymentRedirectionObj);
      if (
        res?.success === 1 &&
        res?.gatewayData?.fetchedLink?.transactionDetail?.id
      ) {
        let paymentId = res.gatewayData.fetchedLink.transactionDetail.id;
        await dispatch(OrderAction.setOrderRef(paymentId));
        if (
          res?.gatewayData?.three_d_secure_redirection_required &&
          res.gatewayData?.fetchedLink?.transactionDetail?.redirectLink
        ) {
          window.location.replace(
            res.gatewayData?.fetchedLink?.transactionDetail?.redirectLink
          );
        } else if (
          res?.gatewayData?.three_d_secure_redirection_required === false
        ) {
          verifyPaymentStatus(paymentId);
        } else {
          logErrorOnSentry(
            `3Dsecure_redirection_required is undefined ||null ${errorHandler(
              res.message
            )}`,
            "error"
          );
          dispatch(
            ErrorAction.setError({
              error: errorHandler(res.message),
              modal: true,
            })
          );
          return;
        }
      } else if (
        res?.success === 1 &&
        !isValidValue(res?.gatewayData?.fetchedLink?.transactionDetail?.id)
      ) {
        logErrorOnSentry(
          `transactionDetail?.id is undefined||null ${t(
            "cardProccesingError"
          )}`,
          "error"
        );
        dispatch(
          ErrorAction.setError({
            error: t("cardProccesingError"),
            modal: true,
          })
        );
        return;
      } else {
        dispatch(
          ErrorAction.setError({
            error: errorHandler(res.message),
            modal: true,
          })
        );
        return;
      }
    } else {
      logErrorOnSentry(
        "paymnet link not verified,  brandId or cartId is not valid +brandId or cartId is not valid, please try again again",
        "error"
      );
      dispatch(
        ErrorAction.setError({
          error: t("brandId or cartId is not valid, please try again again"),
          modal: true,
        })
      );
      return;
    }
  };

  useEffect(() => {
    const queryParams = queryString.parse(search);
    const sessionId = queryParams["cko-session-id"];
    const paymentVerified = sessionStorage.getItem("paymentVerified");
    if (paymentVerified) {
      return;
    }
    if (
      sessionId &&
      orderRef &&
      ["success", "failure"].includes(queryParams.paymentStatus) &&
      paymentLinkInfo?.paymentStatus === C.PAYMENT_LINKS.NOT_VERIFIED
    ) {
      verifyPaymentStatus(orderRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, paymentLinkInfo?.paymentStatus, orderRef]);

  const verifyPaymentStatus = async (paymentId) => {
    dispatch(Loader.enableLoader());
    if (brandInfo?.brand?._id && cartId) {
      const { data: res } = await verifyPaymentLink({
        cartId,
        paymentId: paymentId,
        brandId: brandInfo?.brand?._id,
      });
      if (
        res?.success === 1 &&
        res?.gatewayData?.isVerified &&
        res?.gatewayData?.fetchedLink?.transactionDetail
      ) {
        sessionStorage.setItem("paymentVerified", true);
        fetchPaymentLinkInfo();
      } else if (res?.success === 0) {
        const error = {
          error: errorHandler(res.message),
          modal: true,
        };
        dispatch(ErrorAction.setError(error));
      }
      //return;
    } else {
      logErrorOnSentry(
        "paymnet link not verified,  brandId or cartId is not valid + brandId or cartId is not valid, please try again",
        "error"
      );
      dispatch(
        ErrorAction.setError({
          error: t("brandId or cartId is not valid, please try again"),
          modal: true,
        })
      );
      // return;
    }
    dispatch(Loader.disableLoader());
  };

  const isLoading =
    loader ||
    PaymentLinkInfoLoader ||
    initiatePaymentLinkLoader ||
    verifyPaymentLinkLoader;
  return (
    <WithLoading loading={isLoading}>
      <div className="store-page store-page-popup">
        <>
          {(isError || error) && !errorModaL && !paymentLinkInfo && (
            <PaymentLinkError error={error} />
          )}

          <CustomModal
            open={errorModaL}
            reduxAction={ErrorAction.setErrorModal(false)}
            bodyclasses="flex"
            overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
          >
            <ErrorModal
              reduxAction={ErrorAction.setErrorModal(false)}
              message={error}
            />
          </CustomModal>

          {paymentLinkInfo?.paymentStatus === C.PAYMENT_LINKS.VERIFIED && (
            <PaymentLinkVerified {...{ paymentLinkInfo }} />
          )}
          {paymentLinkInfo?.paymentStatus === C.PAYMENT_LINKS.NOT_VERIFIED && (
            <PaymentLinkNotVerified
              {...{
                paymentLinkInfo,
                captureCheckoutDotComPayment,
                payNow,
                cardProcessing,
                setcardProcessing,
                fetchPaymentLinkInfo,
              }}
            />
          )}

          {paymentLinkInfo?.paymentStatus ===
          C.PAYMENT_LINKS.NOT_VERIFIED ? null : !errorModaL ? (
            <button
              onClick={() => {
                history.replace(`/${params.domainName}`);
              }}
              type="submit"
              className="mb-6 mx-4 bg-brand-base-primary text-brand-text-primary py-3 rounded px-6 text-17x font-medium cursor-pointer"
            >
              {t("Explore  other products")}
            </button>
          ) : null}
        </>
        {!errorModaL ? <RestheroSmallFooter /> : null}
      </div>
    </WithLoading>
  );
}

// const PaymentLinkContainer = () => {
//   return (
//     <WithStripe>
//       <Index />
//     </WithStripe>
//   );
// };

export default Index;
