import CloseButton from "components/CloseButton";
import { t } from "i18next";
import { logErrorOnSentry } from "utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { ItemAction } from "store/actions";
import { useGetGetMenuByIdMutation } from "store/menu/service";

const ItemNotFoundError = ({ error, handleClick }) => {
  logErrorOnSentry(error, "info");
  const dispatch = useDispatch();
  const [getGetMenuById] = useGetGetMenuByIdMutation();
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const { storeMenu } = useSelector((state) => state.store);

  const handleClicked = () => {
    dispatch(ItemAction.clearItem());
    handleClick();
    const menuObj = {
      brandId: brandInfo?.brand._id,
      menuId: storeMenu?._id,
    };
    if (menuObj?.brandId && menuObj?.menuId) getGetMenuById(menuObj);
  };
  return (
    <div className="h-full">
      <CloseButton handleClick={handleClicked} />
      <div className="p-4 h-full cursor-pointer" onClick={handleClicked}>
        <div className="h-full w-full flex items-center justify-center">
          <div className="text-red-500 text-center font-bold">
            <div>
              {typeof error?.message === "string"
                ? error?.message
                : t(
                    "We apologize, but the restaurant owner has removed this item from the menu. Please click the button below to view the current store menu"
                  )}
            </div>
            <span className="inline-block text-brand-text-primary py-2 mt-2 px-4 rounded bg-brand-base-primary">
              {t("Go to menu")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemNotFoundError;
