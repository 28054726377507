import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  brandInfo: null,
  loyaltyPolicy: null,
  isLoading: false,
  stripeKey: null,
};

const brandStorageReducer = createSlice({
  name: "brand",
  initialState,
  reducers: {
    getbrandInfoSuccess: (state, action) => {
      state.brandInfo = action.payload;
    },
    getBrandLoyaltyPolicy: (state, action) => {
      state.error = null;
      state.loyaltyPolicy = action.payload;
    },
    setStripeKey: (state, action) => {
      state.stripeKey = action.payload;
    },
  },
});

export const { getbrandInfoSuccess, getBrandLoyaltyPolicy, setStripeKey } =
  brandStorageReducer.actions;
export default brandStorageReducer.reducer;
