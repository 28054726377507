import React from "react";
import { useSelector } from "react-redux";
import OrderSuccess from "components/checkout/OrderPlaced";
import { useCustomHistory } from "hooks";
import { useParams } from "react-router-dom";
import { errorNotification } from "utils/Utils";
import useQueryParam from "../useQueryParam";

function Index() {
  const params = useParams();
  const router = useCustomHistory();
  const { placedOrder } = useSelector((rstate) => rstate.order);
  const { DomainType } = useSelector((rstate) => rstate.AppReducer);
  const queryParam = useQueryParam();

  const trackOrder = () => {
    const url = "/dine-in/track-order/";
    document.body.removeAttribute("style");
    if (placedOrder?.savedOrder) {
      const { savedOrder } = placedOrder;
      router(
        `${url}${savedOrder?.orderNumber}`,
        `/${params.domainName}${url}${savedOrder?.orderNumber}`,
        DomainType,
        null,
        `?${queryParam}`
      );
    } else if (params?.orderId) {
      router(
        `${url}${params?.orderId}`,
        `/${params?.domainName}${url}${params?.orderId}`,
        DomainType
      );
    } else errorNotification("Order not found");
  };
  const gotoHomePage = () => {
    router(
      "/dine-in",
      `/${params.domainName}/dine-in/`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };
  return <OrderSuccess {...{ trackOrder, gotoHomePage }} />;
}

export default Index;
