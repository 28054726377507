import CONSTANTS from "Constants";
import { apiSlice } from "apiHelpers/ApiSlice";
import * as URL from "apiHelpers/api/ApiUrls";
import { errorHandler } from "apiHelpers/api/errorHandler";
import i18next from "i18next";
import LogRocket from "logrocket";
import { RegisterAction } from "store/actions";
import { NotPersistAction } from "store/actions";
import { ErrorAction } from "store/actions";
import { Loader } from "store/actions";
import { logErrorOnSentry } from "utils/Utils";
import * as Sentry from "@sentry/react";
import { shouldTrackSessions } from "utils/Utils";

let message = i18next?.t("Something was incorrect.");
const registerService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendVerificationCode: builder.mutation({
      query: (body) => ({
        url: URL.LOGIN_USER,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(RegisterAction.sendVerificationCode());
        dispatch(ErrorAction.resetRegisterError());
        dispatch(NotPersistAction.setPageLoaded(false));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            setTimeout(() => {
              dispatch(RegisterAction.sendVerificationCodeSuccess(data.data));
            }, 1000);
          } else {
            dispatch(ErrorAction.setRegisterError(errorHandler(data?.message)));
            dispatch(
              RegisterAction.sendVerificationCodeFailure(
                errorHandler(data?.message)
              )
            );
          }
        } catch (err) {
          dispatch(
            ErrorAction.setRegisterError(errorHandler(err?.error?.data))
          );
          dispatch(
            RegisterAction.sendVerificationCodeFailure(
              errorHandler(err?.error?.data)
            )
          );
        }
      },
    }),
    verifyPin: builder.mutation({
      query: (body) => ({
        url: URL.VERRIFY_VERIFICATION_CODE,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        dispatch(ErrorAction.resetRegisterError());
        dispatch(NotPersistAction.setPageLoaded(false));
        const brand = getState().brandStorageReducer?.brandInfo;
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data?.token) {
            let dataA = {
              token: data.data.token,
              user: {
                brandId: data.data.user?.brandId,
                phoneNumber: data?.data?.user?.phoneNumber,
                userId: data.data.user?.userId,
                email: data?.data?.user?.email || null,
                name: data?.data?.user?.name || null,
              },
              createdAt: new Date(),
            };
            dispatch(RegisterAction.optSuccess(dataA));
            localStorage.setItem(brand.brand._id, JSON.stringify(dataA));
            Sentry.setUser({
              email: data.data.user?.phoneNumber,
              id: data.data.user?.userId,
              username: data.data.user?.brandId,
            });
            shouldTrackSessions() &&
              LogRocket.identify(data.data.user?.userId, {
                username: data.data.user?.phoneNumber,
                email: data.data.user?.phoneNumber,
                userId: data.data.user?.userId,
                brandId: data.data.user?.brandId,
                appVersion: process.env.REACT_APP_VERSION,
                appName: CONSTANTS.WEB_STORE_FRONT,
                userPhone: data.data.user?.phoneNumber,
              });
          } else {
            logErrorOnSentry(
              data?.message,
              "error",
              JSON.stringify(data?.message ?? message)
            );
            dispatch(ErrorAction.setRegisterError(data?.message ?? message));
            dispatch(Loader.disableLoader());
            dispatch(RegisterAction.optFailure(data?.message ?? message));
          }
        } catch (error) {
          logErrorOnSentry(error?.message, "error", JSON.stringify(error));
          dispatch(Loader.disableLoader());
          dispatch(ErrorAction.setRegisterError(error?.message ?? message));
          dispatch(RegisterAction.optFailure(error));
        } finally {
          dispatch(Loader.disableLoader());
        }
      },
    }),
    resendOtp: builder.mutation({
      query: (body) => ({
        url: URL.RESEND_VERIFICATION_CODE,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(ErrorAction.resetRegisterError());
        dispatch(Loader.enableLoader());
        dispatch(NotPersistAction.setPageLoaded(false));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            dispatch(RegisterAction.reSendVerificationCodeSuccess(data.data));
          } else {
            dispatch(
              RegisterAction.reSendVerificationCodeFailure(
                data.message ?? message
              )
            );
            dispatch(Loader.disableLoader());
            dispatch(ErrorAction.setRegisterError(data?.message ?? message));
          }
        } catch (error) {
          dispatch(
            RegisterAction.reSendVerificationCodeFailure(
              error?.message ?? message
            )
          );
          dispatch(ErrorAction.setRegisterError(error?.message ?? message));
        } finally {
          dispatch(Loader.disableLoader());
        }
      },
    }),
  }),
});

export default registerService;
export const {
  useSendVerificationCodeMutation,
  useVerifyPinMutation,
  useResendOtpMutation,
} = registerService;
