import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import moment from "moment";

import CustomModal from "components/CustomModal";
import Close from "icons/Close";
import { useLang } from "hooks";
import { FormatPreOrderingTime } from "utils/OrderUtils";
import { getStoreAreaName } from "utils/storeUtils";
import C from "Constants";
import useOpenSavedAddressListModal from "service-hooks/useOpenSavedAddressListModal";

function ChangeDeliveringToModalContainer({
  isContainerModalopen,
  setContainerModalopen,
  setPreOrderingSlotWidget,
  isPreORderEnabled,
  preORderSlots,
}) {
  const elementRef = useRef(null);
  const { currentLang } = useLang();

  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);
  const { outletBasedSelectedStoreArea } = useSelector((state) => state?.store);
  const { shippingMethod } = useSelector((state) => state?.AppReducer);
  const { openAddressListModal } = useOpenSavedAddressListModal();

  return (
    <>
      <CustomModal
        open={isContainerModalopen}
        setOpen={setContainerModalopen}
        bodyclasses="flex"
        overlayClasses=" fixed h-screen overflow-y-auto w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40 border-sc"
      >
        <div
          ref={elementRef}
          id="discount-info-popup"
          className=" fixed inset-x-0 bottom-0 flex justify-center"
        >
          <div className="bg-white w-full max-460  shadow-md ">
            <h2 className="w-fudll absolute max-460 -top-9 flex justify-end px-1">
              <Close onClick={() => setContainerModalopen(false)} />
            </h2>
            <div
              className="mb-2 text-2xl font-bold p-2"
              onClick={() => {
                openAddressListModal("fetch-all");
                // handleChangeOrderStore();
                setContainerModalopen(false);
              }}
            >
              <div className="flex justify-between items-center">
                <div className="text center">
                  <h2 className="text-outer-space text-17x font-semibold my-4">
                    {t("Delivering to")}
                  </h2>
                  <h2 className="block cursor-pointer text-15x w-full font-semibold text-gray-400 capitalize">
                    {getStoreAreaName(
                      currentLang,
                      shippingMethod,
                      outletBasedSelectedStoreArea
                    )}
                  </h2>
                </div>
                <div className="cursor-pointer">
                  <h2 className="text-brand-base-primary cursor-pointer rounded border-brand-base-primary border px-1">
                    {t("Change")}
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="mb-6 text-2xl font-bold p-2"
              onClick={() => {
                if (isPreORderEnabled && preORderSlots?.length > 0) {
                  setPreOrderingSlotWidget(true);
                  setContainerModalopen(false);
                }
              }}
            >
              <div className="flex justify-between items-center">
                <div className="text center">
                  <h2 className="text-outer-space text-17x font-semibold my-4">
                    {t("Delivery Time")}
                  </h2>

                  {orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER &&
                  orderTiming?.preOrderSlot?.day &&
                  orderTiming?.preOrderSlot?.time ? (
                    <span className="block cursor-pointer text-15x w-full font-semibold text-gray-400 capitalize">
                      {orderTiming?.preOrderSlot?.day === C.TODAY
                        ? t(orderTiming?.preOrderSlot?.day)
                        : moment(orderTiming?.preOrderSlot?.day).format(
                            "dddd, DD MMM YYYY"
                          )}
                      {", "}
                      {FormatPreOrderingTime(orderTiming?.preOrderSlot?.time)}
                    </span>
                  ) : (
                    <span className="block cursor-pointer text-15x w-full font-semibold text-gray-400 capitalize ">
                      {t("ASAP")}
                    </span>
                  )}
                </div>
                {isPreORderEnabled && preORderSlots?.length > 0 ? (
                  <div
                    onClick={() => {
                      setPreOrderingSlotWidget(true);
                      setContainerModalopen(false);
                    }}
                  >
                    <h2 className="cursor-pointer text-brand-base-primary border-brand-base-primary border px-1 rounded">
                      {t("Change")}
                    </h2>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default ChangeDeliveringToModalContainer;
