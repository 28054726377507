import React from "react";

function OrderFailedTick() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      fill="currentColor"
      className="w-20 h-20 checkmark-cross mt-3 bg-red-500"
    >
      <circle cx="25.9" cy="25.9" r="25"></circle>
      <path d="M15.7 16.9L36.2 35M36.2 16.9L15.7 35"></path>
    </svg>
  );
}

export default OrderFailedTick;
