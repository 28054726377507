import { apiSlice } from "apiHelpers/ApiSlice";
import * as URL from "apiHelpers/api/ApiUrls";
import { Loader } from "store/actions";
import { PaymentCardsAction } from "store/actions";
import { togglePaymentType } from "utils/Utils";
import C from "Constants";
import { isActiveAnyPaymentGateway } from "utils/Utils";

const paymentService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserSavedPaymentCards: builder.mutation({
      //checkout.com saved cards fetching
      query: (body) => ({
        url: URL.GET_USER_SAVED_PAYMENT_CARDS,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        dispatch(PaymentCardsAction.setstripePaymentCards([]));
        const isActivePaymentGateway = isActiveAnyPaymentGateway();

        dispatch(
          PaymentCardsAction.setstripeSelectedCard({
            cardInfo: {},
            isStoredCard: false,
          })
        );
        try {
          const { data } = await queryFulfilled;
          if (data?.success && data?.data?.paymentCards?.length > 0) {
            dispatch(
              PaymentCardsAction.setSavedPaymentCards(data?.data?.paymentCards)
            );
            dispatch(
              PaymentCardsAction.setSelectedCard({
                cardInfo: data?.data?.paymentCards[0],
                isStoredCard: true,
              })
            );

            togglePaymentType(C.WALLET.SAVED_CARDS);
          } else {
            togglePaymentType(C.WALLET.NEW_CARD);
            dispatch(PaymentCardsAction.setSavedPaymentCards([]));
          }
        } catch (err) {
          dispatch(PaymentCardsAction.setSavedPaymentCards([]));
        }
        dispatch(Loader.disableLoader());
      },
    }),
    getStripeSavedCards: builder.mutation({
      query: (body) => ({
        url: URL.GET_STRPE_SAVED_CARDS,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        const isActivePaymentGateway = isActiveAnyPaymentGateway();

        dispatch(PaymentCardsAction.setSavedPaymentCards([]));
        dispatch(
          PaymentCardsAction.setSelectedCard({
            cardInfo: {},
            isStoredCard: false,
          })
        );
        try {
          const { data } = await queryFulfilled;
          if (data?.success && data?.cards?.length > 0) {
            dispatch(PaymentCardsAction.setstripePaymentCards(data?.cards));
            dispatch(
              PaymentCardsAction.setstripeSelectedCard({
                cardInfo: data?.cards[0],
                isStoredCard: true,
              })
            );

            togglePaymentType(C.WALLET.SAVED_CARDS);
          } else {
            togglePaymentType(C.WALLET.NEW_CARD);
            dispatch(PaymentCardsAction.setstripePaymentCards([]));
          }
        } catch (err) {
          dispatch(PaymentCardsAction.setstripePaymentCards([]));
        }
        dispatch(Loader.disableLoader());
      },
    }),
  }),
});

export default paymentService;
export const {
  useGetUserSavedPaymentCardsMutation,
  useGetStripeSavedCardsMutation,
} = paymentService;
