import React, { Fragment } from "react";
import { ErrorModal, CustomModal } from "components";
import { t } from "i18next";
import StoreItem from "components/StoreItem";
import { useIntersectionObsever } from "hooks";
const StoreCategoryWithMenu = ({
  menu,
  menuType,
  onItemClick,
  open,
  setOpen,
  page,
}) => {
  const { newRef } = useIntersectionObsever(menu, menuType);
  return (
    <Fragment>
      {menu?.map(({ categoryId, items }, key) => (
        <div key={key}>
          <div>
            <h3
              className="font-nunito text-1.5 font-extrabold px-4 pt-6 p-3 scroll-checker"
              id={categoryId?._id}
              ref={newRef()}
            >
              {categoryId?.baseLanguage?.name}
            </h3>
          </div>
          <ul className="flex flex-row flex-wrap justify-between items-start border-b-8 border-gray-100">
            {items?.map((item, key) => (
              <li
                onClick={() => {
                  sessionStorage.setItem(
                    "scroll-positon",
                    window?.scrollY || window?.pageYOffset
                  );
                }}
                key={key}
                className="flex-100  relative flex-shrink-0 flex rounded-sm flex-row justify-between self-stretch bg-white p-4 border-b hover:bg-gray-50"
              >
                <StoreItem
                  showItem={onItemClick}
                  {...{ item, open, setOpen }}
                  categoryId={categoryId}
                  page={page}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}

      <CustomModal
        open={open}
        setOpen={setOpen}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ErrorModal setOpen={setOpen} message={t("store is closed")} />
      </CustomModal>
    </Fragment>
  );
};

export default StoreCategoryWithMenu;
