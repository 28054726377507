import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Menu from "../menu/index";
import { Spinner, NotFound, ErrorComponent } from "components";
import CategoriesList from "components/menu/CategoriesList";
import StoreCategoryWithMenu from "components/menu/StoreCategoryWithMenu";
import ImageHeader from "components/ImageHeader";
import StoreHeaderDetail from "components/StoreHeaderDetail";
import C from "Constants";
import { useMenuSearch } from "hooks";
import { useHistory, useParams } from "react-router-dom";
import { ItemAction } from "store/actions";
import { t } from "i18next";
import Search from "components/Search";
const PreviewHome = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { error, notFound, brandMenu } = useSelector((state) => state?.brand);
  const [searchTerm, setSearch] = useState("");
  const { filteredMenu } = useMenuSearch(searchTerm, brandMenu);
  const { isLoading } = useSelector((state) => state?.loader);

  const openItem = (item) => {
    history.push(`/${params?.domainName}/preview/item-detail`);
    dispatch(ItemAction.getItemInfobyIdSuccess(item));
  };

  const [inputFocus, setInputFocus] = useState(false);
  const handleSearch = (e) => {
    let term = e.target.value?.toLowerCase();
    setSearch(term);
  };
  if (isLoading) return <Spinner />;
  else if (!isLoading && notFound) return <NotFound message={notFound} />;
  return (
    <div className="store-page store-page-popup">
      <ImageHeader />
      <StoreHeaderDetail
        page={C.BRAND}
        brandInfo={brandInfo}
        description={C.PREVIEW}
      />
      <div>{error && <ErrorComponent error={error} />}</div>
      <Menu menu={brandMenu} {...{ filteredMenu, searchTerm, setSearch }}>
        <div className="sticky top-0 z-10 px-4 bg-white">
          <Search
            label={t("Search for menu items")}
            onChange={handleSearch}
            value={searchTerm}
            setInputFocus={setInputFocus}
          />
          {searchTerm?.length <= 0 && (
            <CategoriesList categoriesList={filteredMenu} />
          )}
        </div>
        {filteredMenu?.length === 0 && inputFocus ? (
          <div className="h-app-height"></div>
        ) : (
          <>
            <StoreCategoryWithMenu
              menu={filteredMenu}
              menuType={C.PREVIEW}
              onItemClick={openItem}
              page="preview"
            />
          </>
        )}
      </Menu>
    </div>
  );
};

export default PreviewHome;
