import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UTILS } from "utils";
function useShowUserInfoModal() {
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { currentUser: reduxUser } = useSelector((state) => state?.register);
  const [currentUser, setUser] = useState({});
  const [isOpenUserInfoModal, setUserInfoModal] = useState(false);

  const setUserInfo = () => {
    const user = UTILS.getCurrentUser(brandInfo?.brand?._id);
    if (!user?.user?.name) {
      setUser(user);
      setUserInfoModal(true);
    } else {
      setUser({});
      setUserInfoModal(false);
    }
  };
  useEffect(() => {
    if (brandInfo?.brand?._id) {
      setUserInfo();
    }
  }, [brandInfo?.brand?._id, reduxUser?.user?.name]);
  return {
    currentUser,
    setUserInfo,
    isOpenUserInfoModal,
    setUserInfoModal,
  };
}

export default useShowUserInfoModal;
