import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import * as CommonUI from "components";
import CheckoutModals from "../components/Modals";
import WithLoading from "components/Hoc/WithLoading";
import PickupFrom from "../components/PickupFrom";
import * as CommonCheckoutUI from "components/checkout/index";
import PickupVehicleInfo from "components/PickupVehicleInfo";
import { PreOrderingSlotWidget } from "components/Pre-ordering/index";
import LoyaltyCreditWidget from "components/brand-loyalty/LoyaltyCreditWidget";
import WithTransparentSpinner from "components/Hoc/WithTransparentSpinner";
import useUpdateCart from "hooks/useUpdateCart";
import useSuccesOrder from "features/hooks/useSuccesOrder";
import { useDeliveryAddressContext } from "contexts/userAddressess";
import useDeleteDelAddress from "service-hooks/useDeleteDelAddress";
import usePaymentHelper from "features/payment-helpers/usePaymentHelper";
import useGetSavedAddressList from "service-hooks/useGetSavedAddressList";
import usePlaceOrderHelper from "features/payment-helpers/usePlaceOrderHelper";

import * as Actions from "store/actions";

import C from "Constants";
import { UTILS } from "utils";
import * as commonHooks from "hooks";
import useCheckIfStoreAndCart from "hooks/useCheckIfStoreAndCart";
import { Spinner } from "components";

function Checkout() {
  const redirectLink = C.PAYMENT_RDX_LINKS.OUTLET_BASE;
  const params = useParams();
  const dispatch = useDispatch();

  useUpdateCart();
  useCheckIfStoreAndCart();

  commonHooks.useUpdateCart();
  const deleteAddress = useDeleteDelAddress();
  const router = commonHooks.useCustomHistory();
  const userWallet = commonHooks.useUserWallet();
  const handleSuceessOrderRoute = useSuccesOrder();
  const { integrationsByName } = commonHooks.useBrandLosgisticIntegrations();

  const {
    placeOrder,
    captureCheckoutDotComPayment,
    cardProcessing,
    isLoading,
  } = usePaymentHelper(redirectLink, handleSuceessOrderRoute);
  const { outlet_based_place_order } = usePlaceOrderHelper(
    handleSuceessOrderRoute
  );

  commonHooks.useUpdateDeliveryFee();

  const { loyaltyPolicy } = useSelector((state) => state?.brandStorageReducer);
  const { orderType, DomainType } = useSelector((state) => state.AppReducer);
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );

  const { cart, cartTotal, amountAfterDiscount, deliveryFee } = useSelector(
    (state) => state.cart
  );
  const { discountInfo } = useSelector((state) => state.Discounts);
  const { outletBasedSelectedStoreArea, selectedStore, selectedPayment } =
    useSelector((state) => state.store);
  const { userdeliveryAdress } = useSelector(
    (state) => state.DeliveryAddressReducer
  );

  const { userAddressState } = useDeliveryAddressContext();
  useGetSavedAddressList({
    fetch: true,
    type: "filtered",
    autoSelectAddress: true,
  });
  const [changeAddressModal, setChangeAddressModal] = useState(false);
  const [isPreOrderingSlotWidgetOpen, setPreOrderingSlotWidget] =
    useState(false);

  const updateCart = () => {
    const { itemsGrossAmount, itemsNetPrice, orderGrossAmount } =
      UTILS.calculateCartGrossAmount(cart, discountInfo, userWallet);
    dispatch(
      Actions.CartAction.updateCartPrice({
        originalPrice: itemsNetPrice,
        amountAfterDiscount: itemsGrossAmount,
        itemsGrossAmount,
        orderGrossAmount,
      })
    );
  };

  // Updating Cart
  useEffect(() => {
    updateCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discountInfo]);

  const validateOrderShipping = () => {
    const { errors, isValid, obj } = UTILS.validateOutletOrderObject();
    return {
      errors,
      isValid,
      obj,
    };
  };

  const goBack = () => {
    const url = "/store/basket";
    router(url, `/${params?.domainName}${url}`, DomainType);
  };
  const redirectToStorePage = () => {
    const STORE_PAGE_URL = "/store/store-detail";
    router(
      STORE_PAGE_URL,
      `/${params?.domainName}${STORE_PAGE_URL}`,
      DomainType
    );
  };

  const OrderPaymentInfo = () => {
    let rate = selectedStore?.taxRate || 0;
    return (
      <CommonUI.ItemsPaymentTotalDetail
        original={cartTotal}
        deliveryFee={deliveryFee}
        taxRate={rate}
        amountAfterDiscount={amountAfterDiscount}
        userWallet={userWallet}
        loyaltyPolicy={loyaltyPolicy}
        page="checkout"
      />
    );
  };

  return (
    <WithLoading loading={isLoading}>
      <div className="checkout-page min-h-app-height store-page-popup">
        <CommonUI.PageHeaderWithButton
          pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full border-b-2 relative"
          title={t("Your Order")}
          url={true}
          handleClick={goBack}
          redirectToStorePage={redirectToStorePage}
        />
        {orderType?.serviceType === C.SERVICE_TYPE.PICKUP && <PickupFrom />}
        {/* <AddMoreITemsButton handleRedirect={redirectToStorePage} /> */}

        {orderType?.serviceType === C.SERVICE_TYPE.DELIVERY && (
          <CommonCheckoutUI.CheckoutDeliveryArea
            {...{ userdeliveryAdress, setChangeAddressModal }}
          />
        )}

        <CommonCheckoutUI.DeliveryTime
          setPreOrderingSlotWidget={setPreOrderingSlotWidget}
        />
        <PreOrderingSlotWidget
          isPreOrderingSlotWidget={isPreOrderingSlotWidgetOpen}
          {...{ setPreOrderingSlotWidget }}
          selectedStore={selectedStore}
        />

        <PickupVehicleInfo className="pb-0 mt-4" />
        {loyaltyPolicy?.isLoyaltyActive && (
          <LoyaltyCreditWidget {...{ loyaltyPolicy }} />
        )}
        <OrderPaymentInfo />
        <CommonCheckoutUI.CheckoutPaymentMethod
          paymentOptions={
            orderType?.serviceType === C.SERVICE_TYPE.DELIVERY
              ? selectedStore?.paymentOptions
              : selectedStore?.pickupPaymentOptions
          }
        >
          <CommonCheckoutUI.SavedCardsList
            {...{
              integrationsByName,
              captureCheckoutDotComPayment,
            }}
          />
          {selectedPayment?.paymentType === C.CREDIT_CARD &&
          selectedPaymentType === C.WALLET.NEW_CARD ? (
            <CommonCheckoutUI.CardWidgets
              {...{
                integrationsByName,
                captureCheckoutDotComPayment,
                redirectLink: redirectLink,
              }}
            />
          ) : null}
        </CommonCheckoutUI.CheckoutPaymentMethod>
        <CheckoutModals
          {...{
            changeAddressModal,
            setChangeAddressModal,
            addressList: userAddressState?.userAddresses,
            deleteSlectedAddress: deleteAddress,
          }}
        />

        <CommonCheckoutUI.FooterWithWallet
          storePageUrl={`/store/store-detail`}
          minOrder={outletBasedSelectedStoreArea?.minOrder || 0}
          placeORder={outlet_based_place_order}
          Place_Order={placeOrder}
          {...{
            outletBasedSelectedStoreArea,
            cartTotal,
            integrationsByName,
            selectedStore,
            cardProcessing,
            validateOrderShipping,
          }}
        />
        <WithTransparentSpinner loading={cardProcessing || isLoading} />
      </div>
    </WithLoading>
  );
}

export default Checkout;
