const IconPhone = ({ stroke, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke={stroke}
      strokeWidth={2}
    >
      <path d="M24 56a4 4 0 0 0 4-4v-4a4 4 0 0 0-4-4c-8 0-8-8-8-12s0-12 8-12a4 4 0 0 0 4-4v-4a4 4 0 0 0-4-4C14 8 8 12 8 32s6 24 16 24z" />
      <path d="M47.4 52a27.33 27.33 0 0 0 0-38.63" />
      <path d="M39.67 44.27a16.38 16.38 0 0 0 0-23.17" />
      <path d="M32 36.55a5.47 5.47 0 0 0 0-7.73" />
    </svg>
  );
};

export default IconPhone;
