export const getStripeWalletCreateIntentObj = ({
  amount,
  storeId,
  stripeSelectedCard,
}) => {
  return {
    amount: amount,
    storeId: storeId,
    paymentMethodType: null,
    currency: "aed",
    paymentMethod: "CARD",
    isStoredCard: stripeSelectedCard ? true : false,
    cardId: stripeSelectedCard?.cardInfo?.id
      ? stripeSelectedCard?.cardInfo?.id
      : null,
    stripeSelectedCard: stripeSelectedCard,
  };
};
