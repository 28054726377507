import { useEffect } from "react";
function useFocus(id) {
  console.count();
  useEffect(() => {
    const element = document.getElementById(id);
    element?.focus();
  }, []);
}

export default useFocus;
