import React, { useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { FixedFooter } from "components";
import C from "Constants";
import { stripeCreatePaymentMethod, verifyStripePaymentIntent } from "./utils";
import { showErrors, logError } from "utils/Utils";
import { Loader } from "store/actions";
import { logErrorOnSentry } from "utils/Utils";
const WalletPay = ({ selectedStore, children, placeOrder, payUsingWallet }) => {
  const { selectedPayment } = useSelector((state) => state.store);
  const { orderGrossAmount } = useSelector((state) => state.cart);
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [paymentRequest, setPaymentRequest] = useState(null);
  let fallBackMessage = t("Bank declined payment, please try again");

  const requestObj = {
    country: "AE",
    currency: "aed",
    total: {
      label: "Total",
      amount: Math.round(orderGrossAmount * 100),
    },
    // disableWallets: ["link", "browserCard"],
  };

  // updating the request
  useEffect(() => {
    if (paymentRequest && orderGrossAmount && !paymentRequest?.isShowing()) {
      paymentRequest.update({
        total: {
          label: "Total",
          amount: Math.round(orderGrossAmount * 100),
        },
      });
    } else if (paymentRequest && paymentRequest?.isShowing()) {
      logError(t("You have already an order ,complete that first"), true);
    }
  }, [paymentRequest, orderGrossAmount]);

  const validatePaymentRequest = (e) => {
    const orderDetail = payUsingWallet();
    if (orderDetail?.isValid === false) {
      e.preventDefault();
      showErrors(orderDetail?.errors);
      return;
    }
    if (+parseFloat(orderGrossAmount) <= 2) {
      e.preventDefault();
      logError(t("addMoreThanTwoAED"), true);
      return;
    }
    if (paymentRequest) {
      const isSheetOpen = paymentRequest.isShowing();
      if (isSheetOpen) {
        e.preventDefault();
        logError(
          t(
            "Online payment is underway; please go back to the checkout screen to complete the payment"
          ),
          true
        );
        logErrorOnSentry(
          "user tries to update the cart while the stripe sheet is already open",
          "error"
        );
        return;
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    try {
      async function createPaymentRequest() {
        if (!stripe || !elements) return;
        const PaymentRequestRes = stripe.paymentRequest(requestObj);
        const result = await PaymentRequestRes.canMakePayment();
        if (isMounted && result) {
          setPaymentRequest(PaymentRequestRes);
        }
        PaymentRequestRes.on("cancel", () => {
          logErrorOnSentry(
            "google/apple pay customer cancelled the payment",
            "info"
          );
          window.location.reload(false);
        });

        PaymentRequestRes.on("paymentmethod", async (event) => {
          dispatch(Loader.togggleCheckoutLoader(true));
          const createObj = {
            amount: orderGrossAmount,
            storeId: selectedStore?._id,
            isStoredCard: false,
            cardId: null,
            stripeSelectedCard: null,
            paymentMethodType: "card",
            currency: "aed",
            paymentMethod: "WALLET",
          };
          const createStripePaymentIntent = await stripeCreatePaymentMethod(
            createObj
          );
          if (
            createStripePaymentIntent?.success === 1 &&
            createStripePaymentIntent?.gatewayData?.data?.client_secret &&
            createStripePaymentIntent?.gatewayData?.isVerified === false
          ) {
            event.complete("success");

            const confirmpayload = await stripe.confirmCardPayment(
              createStripePaymentIntent?.gatewayData?.data?.client_secret,
              {
                payment_method: event.paymentMethod.id,
              },
              { handleActions: false }
            );

            if (
              confirmpayload?.paymentIntent?.status === "succeeded" &&
              confirmpayload?.paymentIntent?.id
            ) {
              verifyStripePaymentIntent(
                confirmpayload?.paymentIntent?.id,
                placeOrder
              );
            }
            //confirm payment failed
            else {
              dispatch(Loader.togggleCheckoutLoader(false));
              logError(confirmpayload?.error?.message || fallBackMessage, true);
            }
          } else {
            dispatch(Loader.togggleCheckoutLoader(false));
            logError(
              createStripePaymentIntent?.error?.message || fallBackMessage,
              true
            );
            event.complete("fail");
            return;
          }
        });
      }
      createPaymentRequest();
    } catch (error) {
      dispatch(Loader.togggleCheckoutLoader(false));
      logError(error?.message || fallBackMessage, true);
    } finally {
      dispatch(Loader.togggleCheckoutLoader(false));
    }

    return () => {
      isMounted = false;
      setPaymentRequest(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements, selectedStore?._id, orderGrossAmount]);

  const shouldShowPaymentButton =
    paymentRequest &&
    selectedPayment?.paymentType === C.CREDIT_CARD &&
    (selectedPaymentType === C.WALLET.APPLE_PAY ||
      selectedPaymentType === C.WALLET.GOOGLE_PAY);
  return shouldShowPaymentButton ? (
    <div id="apple_wallet">
      <FixedFooter>
        <PaymentRequestButtonElement
          options={{ paymentRequest }}
          onClick={(e) => {
            validatePaymentRequest(e);
          }}
        />
      </FixedFooter>
    </div>
  ) : (
    <>{children}</>
  );
};

export default WalletPay;
