import React from "react";
import SingleGroup from "./SingleGroup";
function ModiferGroups({
  selectedItem,
  setItemSection,
  itemSection,
  singleItem,
  setSingleItem,
  isStoreSelected,
}) {
  return (
    <>
      {selectedItem?.itemInfo?.modifierGroups?.map((modifierGroup) => (
        <SingleGroup
          isStoreSelected={isStoreSelected}
          key={modifierGroup?.modifierGroupId?._id}
          {...{
            singleItem,
            setSingleItem,
          }}
          setModifierGroups={setItemSection}
          selectedModifierGroups={itemSection}
          modifierGroup={modifierGroup?.modifierGroupId}
        />
      ))}
    </>
  );
}

export default ModiferGroups;
