import CustomModal from "components/CustomModal";
import ErrorModal from "components/ErrorModel";
import React from "react";
import { useSelector } from "react-redux";
import { ErrorAction } from "store/actions";

function ErrorModalPopup() {
  const { error, errorModaL } = useSelector((rstate) => rstate.errorSlice);

  return (
    <>
      <CustomModal
        open={errorModaL}
        reduxAction={ErrorAction.setErrorModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ErrorModal
          reduxAction={ErrorAction.setErrorModal(false)}
          message={error}
        />
      </CustomModal>
    </>
  );
}

export default ErrorModalPopup;
