import React from "react";
import { t } from "i18next";
import CustomModal from "./CustomModal";
import { NotPersistAction } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button } from "antd";
import { Close } from "icons";

const { Title } = Typography;

const DiscountApplicableItems = ({
  applicableItemsDiscountListModal,
  couponCode,
}) => {
  if (
    !applicableItemsDiscountListModal?.data ||
    applicableItemsDiscountListModal?.data?.length <= 0
  )
    return null;
  const data = applicableItemsDiscountListModal?.data?.slice(0, 10);
  const code = couponCode?.toUpperCase();
  return (
    <div className="mx-4 bg-white px-2  ">
      <Title level={2} className="text-16x pt-3 text-center pb-2 font-bold">
        {t("Discount code is valid only for the following items", {
          couponCode: code,
        })}
      </Title>
      <ul className="max-w-md h-80 pb-4 min-h-40 overflow-y-scroll px-2 dividke-y  divide-gray-400 ">
        {data.map((item) => (
          <li
            className="truncate-line-2 py-1 text-15x bg-gray-100   mt-2 px-2 "
            key={item?.itemId?._id}
          >
            {item?.itemId?.baseLanguage?.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

function DiscounteditemsList({ couponCode }) {
  const dispatch = useDispatch();
  const { applicableItemsDiscountListModal } = useSelector(
    (rstate) => rstate.NotPersist
  );
  const obj = {
    isOpen: false,
    data: applicableItemsDiscountListModal.data,
  };
  const closemodal = () => {
    dispatch(NotPersistAction.setapplicableItemsDiscountListModal(obj));
  };

  return (
    <>
      <CustomModal
        open={applicableItemsDiscountListModal?.isOpen}
        reduxAction={NotPersistAction.setapplicableItemsDiscountListModal(obj)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <div
          onClick={closemodal}
          className="jsutify-center w-full items-center justify-center fixced top-0 left-0 h-app-height modal-mask  overflow-y-auto flex"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full relative h-fit"
          >
            <h2 className="w-fudll absolute max-460 -top-10 px-3 flex justify-end ">
              <Close onClick={closemodal} className="text-white" />
            </h2>
            <div className="w-full ">
              <DiscountApplicableItems
                applicableItemsDiscountListModal={
                  applicableItemsDiscountListModal
                }
                couponCode={couponCode}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

function ViewApplicableDiscountedItemsList() {
  const dispatch = useDispatch();
  const { applicableItemsDiscountListModal } = useSelector(
    (rstate) => rstate.NotPersist
  );

  const openmodal = () => {
    const obj = {
      ...applicableItemsDiscountListModal,
      isOpen: true,
    };
    dispatch(NotPersistAction.setapplicableItemsDiscountListModal(obj));
  };
  return (
    <>
      {applicableItemsDiscountListModal?.data?.length > 0 && (
        <div
          className="mt-2 px-2 flex items-center justify-center"
          onClick={openmodal}
        >
          <Button
            type="primary"
            className="mx-2   flex items-center"
            onClick={openmodal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-binoculars w-4 h-4 inline-block"
              viewBox="0 0 16 16"
            >
              <path d="M3 2.5A1.5 1.5 0 0 1 4.5 1h1A1.5 1.5 0 0 1 7 2.5V5h2V2.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5v2.382a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V14.5a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 14.5v-3a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5v3A1.5 1.5 0 0 1 5.5 16h-3A1.5 1.5 0 0 1 1 14.5V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882zM4.5 2a.5.5 0 0 0-.5.5V3h2v-.5a.5.5 0 0 0-.5-.5zM6 4H4v.882a1.5 1.5 0 0 1-.83 1.342l-.894.447A.5.5 0 0 0 2 7.118V13h4v-1.293l-.854-.853A.5.5 0 0 1 5 10.5v-1A1.5 1.5 0 0 1 6.5 8h3A1.5 1.5 0 0 1 11 9.5v1a.5.5 0 0 1-.146.354l-.854.853V13h4V7.118a.5.5 0 0 0-.276-.447l-.895-.447A1.5 1.5 0 0 1 12 4.882V4h-2v1.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5zm4-1h2v-.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm4 11h-4v.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-8 0H2v.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5z" />
            </svg>
            <span className="mx-2">{t("View applicable items")}</span>
          </Button>
        </div>
      )}
    </>
  );
}

export { ViewApplicableDiscountedItemsList };

export default DiscounteditemsList;
