import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "components";
import Form from "components/verification/components/Form";
import Header from "components/verification/components/Header";
import { useIsLoggedIn, useFocus } from "hooks";
import { UTILS } from "utils";
import { t } from "i18next";
import {
  useVerifyPinMutation,
  useResendOtpMutation,
} from "store/auth/register/registerService";
import { useCustomHistory } from "hooks";
import C from "Constants";
import * as ROUTE_CONSTANTS from "routes/constants";
import { RegisterAction } from "store/actions";
import { BrandAction } from "store/actions";
import { logErrorOnSentry } from "utils/Utils";

const Verification = () => {
  useIsLoggedIn();
  useFocus("verificationInput");
  const history = useHistory();
  const params = useParams();
  const router = useCustomHistory();
  const [verifyPin] = useVerifyPinMutation();
  const [resendOtp] = useResendOtpMutation();
  const [phoneCountryObj, setPhoneCountryObj] = useState(null);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const dispatch = useDispatch();
  const { DomainType, isLoading, pageLoaded, cart, error } = useSelector(
    (state) => ({
      DomainType: state.AppReducer.DomainType,
      isLoading: state.loader.isLoading,
      pageLoaded: state.NotPersist.pageLoaded,
      cart: state.cart.cart,
      error: state.register.error,
    })
  );

  const [isMounted, setIsMounted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [verificationCode, setVerificationCode] = useState("");
  let url = ROUTE_CONSTANTS.ECOMMERCE_LOGIN[1];

  const goToLogin = () => {
    router(url, `/${params?.domainName}${url}`, DomainType);
  };
  useEffect(() => {
    if (pageLoaded) {
      if (params?.domainName) {
        history.push(`/${params.domainName}${url}`);
      } else {
        window.history.back();
      }
    }
  }, [pageLoaded]);

  const handleChange = (e) => {
    e.preventDefault();
    let d = UTILS.NumberConversion(e.target.value);
    setVerificationCode(d);
  };

  let url2 = ROUTE_CONSTANTS.ECOMMERCE_CHECKOUT[1];

  const verifyOtp = async () => {
    const phoneNumberZeroLess = phoneNumber.replace(/^0+/, "");
    const brandId = brandInfo?.brand?._id;
    if (verificationCode?.length === 4 && isMounted) {
      const obj = {
        brandId: brandId,
        phoneNumber: phoneNumberZeroLess,
        countryObj: phoneCountryObj,
        verificationCode: verificationCode,
      };

      const { data } = await verifyPin(obj);
      if (data?.success === 1 && data?.data?.token) {
        dispatch(BrandAction.getDiscounts({ brandId }));
        if (cart?.length > 0) {
          router(url2, `/${params?.domainName}${url2}`, DomainType);
        } else {
          router("/", `/${params?.domainName}/`, DomainType);
        }
        localStorage.setItem("loginEvent", Date.now().toString());
      } else {
        logErrorOnSentry(`invalid data for verify otp ${data?.data}}`);
      }
    }
  };

  useEffect(() => {
    verifyOtp();
  }, [verificationCode]);

  useEffect(() => {
    if (isMounted) {
      const state = history?.location?.state;
      if (state?.phoneNumber && state?.countryObj) {
        setPhoneNumber(state.phoneNumber);
        setPhoneCountryObj(state.countryObj);
      } else {
        history.goBack();
      }
    } else {
      setIsMounted(true);
    }
  }, [isMounted]);

  const resendCode = async () => {
    // const number = UTILS.formatPhoneNumber(phoneNumber);
    const phoneNumberZeroLess = phoneNumber.replace(/^0+/, "");
    if (brandInfo?.brand?._id) {
      const obj = {
        brandId: brandInfo?.brand?._id,
        phoneNumber: phoneNumberZeroLess,
        countryObj: phoneCountryObj,
        userType: C.CUSTOMER,
      };
      const { data } = await resendOtp(obj);
      let d = data?.success === 1 ? setSeconds(30) : "";
    } else {
      dispatch(
        RegisterAction.reSendVerificationCodeFailure(
          t("Something was incorrect.")
        )
      );
    }
  };
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  if (isLoading) return <Spinner />;
  return (
    <>
      <div className="max-w-400x  store-page store-page-popup verify-page  min-h-app-height my-0 mx-auto py-4 px-5 bg-white">
        <Header {...{ goToLogin }} />
        <Form
          {...{
            phoneNumber,
            verificationCode,
            handleChange,
            seconds,
            error,
            resendCode,
            phoneCountryObj,
          }}
        />
      </div>
    </>
  );
};

export default Verification;
