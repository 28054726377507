import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n/i18n";
import { t } from "i18next";
import C from "Constants";
import { UTILS } from "utils";
import { useWindowDimensions } from "hooks";
import {
  useGetBrandInfoMutation,
  useGetBrandInfoByIdMutation,
  useGetLoyaltyPolicyMutation,
} from "store/home/brand/brandService";
import { AppAction, StoreAction, BrandAction } from "store/actions";
import {
  useGetStoresMenuMutation,
  useGetStoresMenuForPickupMutation,
  useGetStoresMenuForDineInMutation,
} from "store/brand-based-store/single-store-service";
import { RegisterAction } from "store/actions";
import { BrandStorageAction } from "store/actions";
import { Logout } from "store/actions";
import { createAction } from "@reduxjs/toolkit";
import queryString from "query-string";
import { createServiceTypeArray } from "utils/Utils";
import { useCustomHistory } from "hooks";
import { useGetBrandMenuMutation } from "store/home/brand/brandService";
import { handleSessionStorage } from "utils/storage";
function UpdateSetting() {
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const queryParams = queryString.parse(window?.location?.search);
  const { height } = useWindowDimensions();
  const [getBrandInfo] = useGetBrandInfoMutation();
  const [getBrandSettingsById] = useGetBrandInfoByIdMutation();
  const [getBrandLoyaltyPolicy] = useGetLoyaltyPolicyMutation();
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { orderType, DomainType } = useSelector((state) => state?.AppReducer);

  document.documentElement.style.setProperty("--app-height", height + "px");
  const [getStoresMenu] = useGetStoresMenuMutation();
  const [getBrandMenu] = useGetBrandMenuMutation();
  const [getStoresMenuForPickup] = useGetStoresMenuForPickupMutation();
  const [getStoresMenuForDineIn] = useGetStoresMenuForDineInMutation();

  const processBrandData = async (data) => {
    if (data?.success === 1 && data?.data?.setting) {
      handleSessionStorage.setItem(
        "i18nextLng",
        data?.data?.brand?.baseLanguage
      );
      await i18n.changeLanguage(data.data.brand.baseLanguage);
      document.body.dir = i18n.dir();
      await UTILS.setThemeColour(
        data.data.setting.color,
        data.data.setting.textColor
      );
      UTILS.updateDataForBrand(data?.data?.brand?._id, data.data);
      dispatch(BrandAction.getbrandInfoSuccess(data.data));
      dispatch(BrandStorageAction.getbrandInfoSuccess(data.data));
      sessionStorage.setItem("themeColor", data.data.setting.color);
      if (data?.data?.brand?.catalogueType && data?.data?.brand?._id) {
        await getBrandLoyaltyPolicy({ brandId: data?.data?.brand?._id });
        dispatch(
          RegisterAction.setBrandInfo({
            id: data?.data?.brand?._id,
            domainName: data.data.setting.temporaryDomainName,
          })
        );
        dispatch(AppAction.setcatalogueType(data.data.brand.catalogueType));
        fetchData(
          data.data.brand.catalogueType,
          data.data.brand._id,
          data.data.setting
        );
      }
      window.external_plugin_file_integration_brandInfo = data.data;
    }
  };

  const getBrandSettinsgByID = async (obj) => {
    if (obj?.brandId !== brandInfo?.brand?._id) {
      dispatch(Logout.removeBrandData());
      sessionStorage.setItem("logoutEvent", Date.now().toString());
    } else {
      const color = sessionStorage.getItem("themeColor");
      if (color) {
        UTILS.setThemeColour(color);
      }
    }

    const { data } = await getBrandSettingsById(obj);
    await processBrandData(data);
  };

  const getBrandSettinsgByName = async (obj) => {
    if (obj?.temporaryDomainName !== brandInfo?.setting?.temporaryDomainName) {
      dispatch(Logout.removeBrandData());
      sessionStorage.setItem("logoutEvent", Date.now().toString());
    } else {
      const color = sessionStorage.getItem("themeColor");
      if (color) {
        UTILS.setThemeColour(color);
      }
    }
    const { data } = await getBrandInfo(obj);
    await processBrandData(data);
  };

  const fetchData = (ctype, id, setting) => {
    if (ctype === "OUTLET_BASED_CATALOGUE") {
      return outletBased({
        id,
        setting,
        domainName: setting?.temporaryDomainName,
      });
    }
    if (ctype === "BRAND_BASED_CATALOGUE") {
      return brandBased({
        brandId: id,
        setting,
        domainName: setting?.temporaryDomainName,
      });
    }
  };

  const DineInServiceType = {
    EN: {
      name: "DINE IN",
    },
    AR: {
      name: "تناول الطعام في المطعم",
    },
    serviceType: "DINE IN",
  };

  const brandBased = async (brandData) => {
    const obj = {
      brandId: brandData?.brandId,
    };
    const removeData = createAction("removeOrderData");
    if (obj?.brandId) {
      let result = null;
      let services = createServiceTypeArray(brandData.setting);
      if (services?.length > 0) {
        //Updating
        if (orderType?.EN?.name) {
          const service = services?.find(
            (service) => service?.serviceType === orderType?.EN?.name
          );
          if (!service) {
            const service = services?.[0];
            dispatch(removeData());
            UTILS.errorNotification(
              t(
                "The service is disabled from the store’s side. Alternatively, you can place an order using some other method",
                {
                  serviceType: i18n.t(orderType?.EN?.name),
                }
              )
            );
            dispatch(AppAction.setOrderType(service));
            if (service?.serviceType === C.SERVICE_TYPE.DELIVERY) {
              result = await getStoresMenu(obj);
            }
            if (service?.serviceType === C.SERVICE_TYPE.PICKUP) {
              result = await getStoresMenuForPickup(obj);
            }
            router("/", `/${brandData?.domainName}`, DomainType);
          } else {
            if (orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY) {
              dispatch(AppAction.setOrderType(service));
              result = await getStoresMenu(obj);
            } else if (orderType?.EN?.name === C.SERVICE_TYPE.PICKUP) {
              dispatch(AppAction.setOrderType(service));
              result = await getStoresMenuForPickup(obj);
            }
          }
        }
        //setting default service ist time
        else {
          const service = services?.[0];
          dispatch(AppAction.setOrderType(service));
          if (service?.serviceType === C.SERVICE_TYPE.DELIVERY) {
            result = await getStoresMenu(obj);
          }
          if (service?.serviceType === C.SERVICE_TYPE.PICKUP) {
            result = await getStoresMenuForPickup(obj);
          }
        }
        const data = result?.data;
        if (data?.success === 1 && data?.response === 200) {
          dispatch(BrandAction.getBrandMenuSuccess(data?.data?.storeMenu));
          dispatch(StoreAction.setSelectedStore(data.data.storeInfo));
        }
        dispatch(
          BrandAction.getDiscounts({
            brandId: brandData?.brandId,
          })
        );
      } else {
        dispatch(
          BrandAction.getBrandMenuFailure(
            i18n.t("The store is currently not serving, please try again later")
          )
        );
        dispatch(StoreAction.setSelectedStore(null));
        dispatch(AppAction.setOrderType(null));
        dispatch(removeData());
        router("/", `/${brandData?.domainName}`, DomainType);
      }
    }
  };

  const fetchDineStoreMenu = async (brandData) => {
    const { data } = await getStoresMenuForDineIn({
      brandId: brandData?.id,
      storeId: queryParams?.["store-id"],
    });
    if (data?.success === 1 && data?.response === 200) {
      // dispatch(BrandAction.getBrandMenuSuccess(data.data.storeMenu));
      dispatch(StoreAction.setMenu(data.data.storeMenu));

      dispatch(StoreAction.setSelectedStore(data.data.storeInfo));
    }
  };

  const outletBased = async (brandData) => {
    if (queryParams?.["service-type"] === C.DINE_SERVICE_TYPE.QUERY_PARAM) {
      dispatch(AppAction.setOrderType(DineInServiceType));
      fetchDineStoreMenu(brandData);
    } else if (brandData?.setting) {
      let services = createServiceTypeArray(brandData.setting);
      const removeData = createAction("removeOrderData");
      let result = null;
      if (services?.length > 0) {
        //Updating
        if (orderType?.EN?.name) {
          const service = services?.find(
            (service) => service?.serviceType === orderType?.EN?.name
          );
          if (!service) {
            const SERVICE = services?.[0];
            dispatch(removeData());
            UTILS.errorNotification(
              t(
                "The service is disabled from the store’s side. Alternatively, you can place an order using some other method",
                {
                  serviceType: i18n.t(orderType?.EN?.name),
                }
              )
            );
            dispatch(AppAction.setOrderType(SERVICE));
            router("/", `/${brandData?.domainName}`, DomainType);
          } else {
            if (
              orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY ||
              orderType?.EN?.name === C.SERVICE_TYPE.PICKUP
            ) {
              dispatch(AppAction.setOrderType(service));
            }
          }
        }
        //setting default service ist time
        else {
          const service = services?.[0];
          dispatch(AppAction.setOrderType(service));
        }
        const data = result?.data;
        if (data?.success === 1 && data?.response === 200) {
          dispatch(BrandAction.getBrandMenuSuccess(data.data.storeMenu));
          dispatch(StoreAction.setSelectedStore(data.data.storeInfo));
        }
        await getBrandMenu({ brandId: brandData?.id });
      } else {
        dispatch(
          BrandAction.getBrandMenuFailure(
            i18n.t("The store is currently not serving, please try again later")
          )
        );
        dispatch(StoreAction.setSelectedStore(null));
        dispatch(AppAction.setOrderType(null));
        dispatch(removeData());
        router("/", `/${brandData?.domainName}`, DomainType);
      }
    }
    dispatch(BrandAction.getDiscounts({ brandId: brandData?.id }));
  };

  return {
    getBrandSettinsgByID,
    getBrandSettinsgByName,
    fetchDineStoreMenu,
  };
}

export default UpdateSetting;
