import { createSlice } from "@reduxjs/toolkit";
import CONSTANTS from "Constants";
const initialState = {
  error: null,
  areasError: null,
  storesError: null,
  activeServices: [],
  deliveryAreas: [],
  stores: [],
  notFound: null,
  brandMenuError: null,
  brandMenu: null,
  loading: false,
  discounts: [],
  isLoadingDiscounts: false,
};

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    getBrandInfo: (state, action) => {
      state.error = null;
      state.notFound = null;
    },

    getbrandInfoSuccess: (state, action) => {
      state.error = null;
      state.notFound = null;
      state.brandMenuError = null;
    },
    getbrandInfoFailure: (state, action) => {
      state.notFound = action.payload;
      state.error = action.payload;
      state.loading = false;
    },
    getBrandMenu: (state) => {
      state.brandMenuError = null;
      state.brandMenu = [];
      state.loading = true;
    },
    getBrandMenuSuccess: (state, action) => {
      state.brandMenu = action.payload;
      state.loading = false;
    },
    getBrandMenuFailure: (state, action) => {
      state.brandMenuError = action.payload;
      state.brandMenu = null;
      state.loading = false;
    },
    getBrandDeliveryAreas: (state) => {
      state.areasError = null;
      state.deliveryAreas = null;
    },
    getBrandDeliveryAreasSuccess: (state, action) => {
      state.deliveryAreas = action.payload;
      state.areasError = null;
    },
    getBrandDeliveryAreasFailure: (state, action) => {
      state.areasError = action.payload;
    },
    getBrandStores: (state) => {
      state.storesError = null;
      state.stores = null;
    },
    getBrandStoresSuccess: (state, action) => {
      state.stores = action.payload;
      state.storesError = null;
    },
    getBrandStoresFailure: (state, action) => {
      state.storesError = action.payload;
    },
    getDiscounts: (state, action) => {
      state.isLoadingDiscounts = true;
      state.error = null;
      if (action.payload?.page !== CONSTANTS.COUPONS_POPUP)
        state.discounts = [];
    },
    getDiscountsSuccess: (state, action) => {
      state.isLoadingDiscounts = false;
      state.error = null;
      state.discounts = action.payload;
    },
    getDiscountsFailure: (state, action) => {
      state.isLoadingDiscounts = false;
      state.error = action.payload?.error;
      state.loading = false;
      if (action.payload?.page !== CONSTANTS.COUPONS_POPUP)
        state.discounts = [];
    },
  },
});

export const {
  getDiscounts,
  getDiscountsSuccess,
  getDiscountsFailure,
  getBrandInfo,
  getbrandInfoSuccess,
  getbrandInfoFailure,
  getBrandDeliveryAreas,
  getBrandDeliveryAreasSuccess,
  getBrandDeliveryAreasFailure,
  getBrandStores,
  getBrandStoresSuccess,
  getBrandStoresFailure,
  getBrandMenu,
  getBrandMenuFailure,
  getBrandMenuSuccess,
} = brandSlice.actions;
export default brandSlice.reducer;
