import { apiSlice } from "apiHelpers/ApiSlice";
import * as URL from "apiHelpers/api/ApiUrls";
import i18n from "i18n/i18n";
import {
  Loader,
  BrandAction,
  BrandStorageAction,
  AppAction,
} from "store/actions";
import { UTILS } from "utils";
import C from "Constants";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { StoreAction } from "store/actions";
const fonts = {
  en: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  ar: `"Noto Naskh Arabic", serif`,
};
const secondary = {
  en: `"Nunito Sans"`,
  ar: `"Noto Naskh Arabic", serif`,
};

const brandAPi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrandInfo: builder.mutation({
      query: (body) => ({
        url: URL.GET_BRAND_SETTINGS,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(AppAction.setDomainType(C.TEMPORARAY_DOMAIN));
        dispatch(Loader.enableLoader());
        try {
          const { data } = await queryFulfilled;
          if (data) {
            if (
              data?.success === 1 &&
              data?.data?.brand &&
              data?.data?.brand?.baseLanguage &&
              data?.data?.setting?.temporaryDomainName
            ) {
              UTILS.setStripeKey(data?.data?.activeIntegrations);
              const selectedDeliveryAddress =
                getState()?.DeliveryAddressReducer?.userdeliveryAdress;
              const { shippingMethod } = getState()?.AppReducer;
              if (!selectedDeliveryAddress && !shippingMethod) {
                UTILS.getActiveShippingMethod(
                  data.data?.brand?.deliveryMethods
                );
              }
              UTILS.saveLanguageInformation(data.data.brand.baseLanguage);
              dispatch(AppAction.setLocale(data.data.brand.baseLanguage));
              if (data.data.brand.baseLanguage === "en") {
                const el = document.querySelector("html");
                el.style.fontFamily = fonts.en;
                document.documentElement.style.setProperty(
                  "--secondary-app-font",
                  secondary.en
                );
              } else {
                const el = document.querySelector("html");
                el.style.fontFamily = fonts.ar;
                document.documentElement.style.setProperty(
                  "--secondary-app-font",
                  secondary.ar
                );
              }
              //
            } else {
              dispatch(Loader.disableLoader());
              dispatch(
                BrandAction.getbrandInfoFailure(
                  data.error ??
                    i18n.t(
                      "We're sorry, but something went wrong. Please try again"
                    )
                )
              );
            }
          }
        } catch (err) {
          dispatch(
            BrandAction.getbrandInfoFailure(
              err?.error?.data?.message ??
                i18n.t(
                  "We're sorry, but something went wrong. Please try again"
                )
            )
          );
          dispatch(Loader.disableLoader());
        }
      },
    }),
    getBrandInfoById: builder.mutation({
      query: (body) => ({
        url: URL.GET_BRAND_SETTINGS_BYID,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        dispatch(AppAction.setDomainType(C.CUSTOM_DOMAIN));

        try {
          const { data } = await queryFulfilled;
          if (data) {
            if (
              data?.success === 1 &&
              data?.data?.brand &&
              data?.data?.brand?.baseLanguage &&
              data?.data?.setting?.temporaryDomainName
            ) {
              UTILS.setStripeKey(data?.data?.activeIntegrations);
              const selectedDeliveryAddress =
                getState()?.DeliveryAddressReducer?.userdeliveryAdress;
              const { shippingMethod } = getState()?.AppReducer;

              if (!selectedDeliveryAddress && !shippingMethod) {
                UTILS.getActiveShippingMethod(
                  data.data?.brand?.deliveryMethods
                );
              }
              UTILS.saveLanguageInformation(data.data.brand.baseLanguage);
              dispatch(AppAction.setLocale(data.data.brand.baseLanguage));
              if (data.data.brand.baseLanguage === "en") {
                const el = document.querySelector("html");
                el.style.fontFamily = fonts.en;
                document.documentElement.style.setProperty(
                  "--secondary-app-font",
                  secondary.en
                );
              } else {
                const el = document.querySelector("html");
                el.style.fontFamily = fonts.ar;
                document.documentElement.style.setProperty(
                  "--secondary-app-font",
                  secondary.ar
                );
              }
            } else {
              dispatch(Loader.disableLoader());
              dispatch(
                BrandAction.getbrandInfoFailure(
                  data.error ??
                    i18n.t(
                      "We're sorry, but something went wrong. Please try again"
                    )
                )
              );
            }
          }
        } catch (err) {
          dispatch(
            BrandAction.getbrandInfoFailure(
              err?.error?.data?.message ??
                i18n.t(
                  "We're sorry, but something went wrong. Please try again"
                )
            )
          );
          dispatch(Loader.disableLoader());
        }
      },
    }),
    getLoyaltyPolicy: builder.mutation({
      query: (body) => ({
        url: URL.GET_BRAND_LOYALTY_POLICY,
        method: "POST",
        body,
      }),
      transformResponse: (response) => {
        return response;
      },

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        dispatch(Loader.togggleCheckoutLoader(true));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            dispatch(BrandStorageAction.getBrandLoyaltyPolicy(data.data));
          } else {
            dispatch(BrandStorageAction.getBrandLoyaltyPolicy(null));
          }
        } catch (err) {
          dispatch(BrandStorageAction.getBrandLoyaltyPolicy(null));
        }
        dispatch(Loader.disableLoader());
        dispatch(Loader.togggleCheckoutLoader(false));
      },
    }),
    getBrandMenu: builder.mutation({
      query: (body) => ({
        url: URL.GET_BRAND_MENU,
        method: "POST",
        body,
      }),
      transformResponse: (response) => {
        return response;
      },

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(Loader.enableLoader());
          dispatch(Loader.togggleCheckoutLoader(true));
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data) {
            dispatch(BrandAction.getBrandMenuSuccess(data.data));
            if (data?.data?.categories?.length >= 1) {
              dispatch(
                StoreAction.setSelectedCategory(
                  data?.data?.categories[0]?.categoryId?._id
                )
              );
            }
          } else {
            dispatch(
              BrandAction.getBrandMenuFailure(errorHandler(data?.message))
            );
          }
        } catch (error) {
          dispatch(
            BrandAction.getBrandMenuFailure(errorHandler(error?.message))
          );
        } finally {
          dispatch(Loader.disableLoader());
          dispatch(Loader.togggleCheckoutLoader(false));
        }
      },
    }),
  }),
  overrideExisting: true,
});

export default brandAPi;
export const {
  useGetBrandInfoMutation,
  useGetBrandInfoByIdMutation,
  useGetLoyaltyPolicyMutation,
  useGetBrandMenuMutation,
} = brandAPi;
