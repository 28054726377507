import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfilePageHeader from "components/ProfilePageHeader";
import SettingLinks from "components/settings/SettingLinks";
import { useParams } from "react-router-dom";
import { useCustomHistory } from "hooks";
import { Logout } from "store/actions";
import { useWindowLocation } from "hooks";
import WithLoading from "components/Hoc/WithLoading";
import useQueryParam from "../useQueryParam";
const Setting = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const queryParam = useQueryParam();
  const router = useCustomHistory();

  const windowRouter = useWindowLocation();
  const [settings, setSettings] = useState();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { brandInfo: Brand } = useSelector(
    (state) => state?.brandStorageReducer
  );

  useEffect(() => {
    const getBrand = async () => {
      if (Brand) {
        const { setting } = Brand;
        setSettings(setting);
      }
    };
    getBrand();
  }, []);

  const goBack = () => {
    router(
      `/dine-in/`,
      `/${params.domainName}/dine-in`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };

  const handleAllOrders = () => {
    router(
      `/dine-in/my-orders`,
      `/${params.domainName}/dine-in/my-orders`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };
  const handleLogout = () => {
    window.sessionStorage.clear();
    localStorage.removeItem(Brand?.brand?._id);
    localStorage.setItem("logoutEvent", Date.now().toString());
    dispatch(Logout.logout());
    windowRouter(
      `/dine-in/?${queryParam}`,
      `/${params.domainName}/dine-in/?${queryParam}`,
      DomainType
    );
  };

  return (
    <WithLoading>
      <div className="profile-page min-h-app-height max-460">
        {params && (
          <React.Fragment>
            <ProfilePageHeader
              pageHeaderClasses="flex justify-between px-5 w-full"
              url={true}
              image={true}
              handleClick={goBack}
              img={settings}
            />
            <SettingLinks
              handleAllOrders={handleAllOrders}
              handleLogout={handleLogout}
            />
          </React.Fragment>
        )}
      </div>
    </WithLoading>
  );
};

export default Setting;
