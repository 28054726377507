import { useDispatch } from "react-redux";
import { t } from "i18next";
import { errorHandler } from "apiHelpers/api/errorHandler";

//actions
import { LoyaltyAction } from "store/actions";
import { ErrorAction } from "store/actions";
import { NotPersistAction } from "store/actions";

import { usePlaceMyOrderMutation } from "store/checkout/order/orderService";
import { logErrorOnSentry } from "utils/Utils";

import C from "Constants";
import { UTILS } from "utils";
import {
  validateBrandOrderObject,
  getBrandBasedOrderObject,
} from "utils/OrderUtils";

function usePlaceOrderHelper(handleSuceessOrderRoute) {
  const dispatch = useDispatch();
  const [placeMyOrder] = usePlaceMyOrderMutation();

  const PLACE_order = async (obj) => {
    try {
      const { data } = await placeMyOrder(obj);
      if (data?.success === 1 && data?.data?.savedOrder?.orderNumber) {
        dispatch(LoyaltyAction.removeLoyalty());
        const orderNumber = data?.data?.savedOrder?.orderNumber;
        handleSuceessOrderRoute(orderNumber);
      } else {
        dispatch(NotPersistAction.toggleOrderPlaceModel(false));
        logError(data?.message || t(C.DEFAULT_ERROR_MSG), true, "error");
        return;
      }
    } catch (error) {
      logError(error?.error?.message || t(C.DEFAULT_ERROR_MSG), true, "error");
    }
  };

  const outlet_based_place_order = async (paymentObj = null) => {
    const { orderObject, isValid, errors } = UTILS.validateOutletOrderObject();
    if (!isValid) {
      showErrors(errors);
      return;
    }
    orderObject.transactionInfo = paymentObj?.paymentIntent;
    const orderobj = UTILS.getOutletOrderObject(orderObject);
    PLACE_order(orderobj);
  };

  const showErrors = (errors) => {
    const error = Object.values(errors).find((value) => value !== null);
    if (error) {
      logError(errorHandler(error), true, "error");
    }
  };

  const logError = (msg, modal, level) => {
    dispatch(
      ErrorAction.setError({
        error: msg,
        modal: modal,
      })
    );
    if (msg && msg !== C.DEFAULT_ERROR_MSG) {
      logErrorOnSentry(msg, level);
    }
  };

  // const validateSavedCard = (cardType, selectedCard) => {
  //   if (
  //     selectedCard?.cardInfo?._id &&
  //     selectedCard?.cardInfo?.paymentGateway === cardType
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };
  const brand_based_place_order = async (paymentObj = null) => {
    const { errors, isValid, obj } = await validateBrandOrderObject();
    if (!isValid) {
      showErrors(errors);
      return;
    }
    obj.transactionInfo = paymentObj?.paymentIntent;
    const orderObject = getBrandBasedOrderObject(obj);
    PLACE_order(orderObject);
  };
  return {
    PLACE_order,
    outlet_based_place_order,
    brand_based_place_order,
    logError,
  };
}

export default usePlaceOrderHelper;
