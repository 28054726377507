import { Loader } from "store/actions";
import { logErrorOnSentry } from "./Utils";
import reduxstore from "store/store";

export function getCityAndCountry(latitude, longitude, callback) {
  const geocoder = new window.google.maps.Geocoder();
  const latlng = new window.google.maps.LatLng(latitude, longitude);
  // let streetAddress;
  geocoder.geocode(
    { location: latlng, language: "en" },
    function (results, status) {
      if (status === "OK") {
        if (results[0]) {
          let city, country, area;
          for (let i = 0; i < results[0].address_components.length; i++) {
            const component = results[0].address_components[i];
            const types = component.types;
            if (
              types.includes("locality") ||
              types.includes("administrative_area_level_1")
            ) {
              city = component.long_name;
            }
            if (types.includes("country")) {
              country = component.long_name;
            }
            if (
              types.includes("sublocality_level_1") ||
              types.includes("- [ ] sublocality") ||
              types.includes("neighborhood")
            ) {
              area = component.long_name;
            }
          }
          if (city || country || area) {
            callback({ city, country, area });
          } else {
            if (results[1]) {
              var countr1 = null,
                city1 = null,
                cityAlt = null,
                area1 = null;
              var c, lc, component;
              for (var r = 0, rl = results.length; r < rl; r += 1) {
                var result = results[r];

                if (!city1 && result.types[0] === "locality") {
                  for (
                    c = 0, lc = result.address_components.length;
                    c < lc;
                    c += 1
                  ) {
                    component = result.address_components[c];

                    if (component.types[0] === "locality") {
                      city = component.long_name;
                      break;
                    }
                  }
                } else if (
                  !city1 &&
                  !cityAlt &&
                  result.types[0] === "administrative_area_level_1"
                ) {
                  for (
                    c = 0, lc = result.address_components.length;
                    c < lc;
                    c += 1
                  ) {
                    component = result.address_components[c];

                    if (component.types[0] === "administrative_area_level_1") {
                      cityAlt = component.long_name;
                      break;
                    }
                  }
                } else if (!countr1 && result.types[0] === "country") {
                  countr1 = result.address_components[0].long_name;
                }
                if (!area1 && result.types[0] === "sublocality_level_1") {
                  area1 = result.address_components[0].long_name;
                }

                if (city1 && countr1) {
                  break;
                }
              }

              callback({ city: city1, country: countr1, area: area1 });
            }
          }
        } else {
          logErrorOnSentry(
            `geocoder results returned empty results ${latlng}`,
            "info"
          );
          callback(null);
        }
      } else {
        logErrorOnSentry(
          `geocoder results returned empty results ${latlng}`,
          "info"
        );
        callback(null);
      }
    }
  );
}

export const validateMapAddress = (result, latLng, streetAddress) => {
  let updatedResult = { ...result }; // create a copy of the object to modify
  if (!updatedResult?.city) {
    logErrorOnSentry(
      `city not found from google geocoder for latlng Lat:${latLng?.lat} Lng:${latLng?.lng}`,
      "info"
    );
    updatedResult.city = "unknown";
  }
  if (!updatedResult?.country) {
    updatedResult.country = "unknown";
    logErrorOnSentry(
      `country not found from google geocoder for latlng  Lat:${latLng?.lat} Lng:${latLng?.lng}`,
      "info"
    );
  }
  if (!updatedResult?.area) {
    updatedResult.area = "unknown";
    logErrorOnSentry(
      `area not found from google geocoder for latlng  Lat:${latLng?.lat} Lng:${latLng?.lng}`,
      "info"
    );
  }

  updatedResult.streetAddress = streetAddress;
  return updatedResult;
};

export const geoCodeConverter = (userLocation = null) => {
  if (userLocation?.lat && userLocation?.lng) {
    const lat = userLocation?.lat;
    const lng = userLocation?.lng;
    const mapCenter = {
      lat: lat,
      lng: lng,
    };

    return new Promise((resolve, reject) => {
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: mapCenter }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]?.formatted_address) {
            getCityAndCountry(mapCenter.lat, mapCenter.lng, function (result) {
              validateMapAddress(
                result,
                mapCenter,
                results[0].formatted_address
              );
            });
            resolve(results[0].formatted_address);
          } else {
            logErrorOnSentry(
              `street address not found from google geocoder status:${status} lat:${lat} lng:${lng}`,
              "info"
            );
            resolve(null);
          }
        } else {
          logErrorOnSentry(
            `street address not found from google geocoder status:${status} lat:${lat} lng:${lng}`,
            "info"
          );
          resolve(null);
        }
      });
    });
  }
};

export function getAreaCityAndCountry(latitude, longitude, lang) {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    const locale = lang?.toLowerCase() || "en";
    const latlng = new window.google.maps.LatLng(latitude, longitude);

    geocoder.geocode(
      { location: latlng, language: locale },
      function (results, status) {
        if (status === "OK") {
          if (results[0]) {
            let city, country, area;
            for (let i = 0; i < results[0].address_components.length; i++) {
              const component = results[0].address_components[i];
              const types = component.types;
              if (
                types.includes("locality") ||
                types.includes("administrative_area_level_1")
              ) {
                city = component.long_name;
              }
              if (types.includes("country")) {
                country = component.long_name;
              }
              if (
                types.includes("sublocality_level_1") ||
                types.includes("- [ ] sublocality") ||
                types.includes("neighborhood")
              ) {
                area = component.long_name;
              }
            }
            if (city || country || area || results[0]?.formatted_address) {
              reduxstore.dispatch(Loader.setLoading(false));
              resolve({
                city,
                country,
                area,
                streetAddress: results[0]?.formatted_address,
              });
            } else {
              if (results?.length > 1)
                for (let i = 1; i < results?.length; i++) {
                  const result = results[i];
                  let city1 = null,
                    countr1 = null,
                    area1 = null;
                  for (let j = 0; j < result.address_components.length; j++) {
                    const component = result.address_components[j];
                    const types = component.types;
                    if (!city1 && types.includes("locality")) {
                      city1 = component.long_name;
                    }
                    if (!countr1 && types.includes("country")) {
                      countr1 = component.long_name;
                    }
                    if (!area1 && types.includes("sublocality_level_1")) {
                      area1 = component.long_name;
                    }
                    if (city1 && countr1 && area1) {
                      break;
                    }
                  }
                  if (
                    city1 ||
                    countr1 ||
                    area1 ||
                    results[i]?.formatted_address
                  ) {
                    resolve({
                      city: city1,
                      country: countr1,
                      area: area1,
                      streetAddress: results[i]?.formatted_address,
                    });
                    break;
                  }
                }
            }
          } else {
            reject(`Geocoder results returned empty results for ${latlng}`);
          }
        } else {
          reject(`Geocoder failed due to: ${status}`);
        }
      }
    );
  }).catch((error) => {
    logErrorOnSentry(error, "error");
  });
}
