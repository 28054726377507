import React, { useEffect } from "react";

const Search = (props) => {
  const { label } = props;
  const checkAtrFocus = props?.setInputFocus ? true : false;
  useEffect(() => {
    const el = document.getElementById("company-website");
    el?.addEventListener("focusin", (event) => {
      if (checkAtrFocus) {
        props?.setInputFocus(true);
      }
    });

    el.addEventListener("focusout", (event) => {
      if (checkAtrFocus) {
        props?.setInputFocus(false);
      }
    });
  }, []);
  return (
    <div className="flex rounded-md pb-1 pt-4 search">
      <span className="inline-flex items-center h-10 px-3  border  border-gray-200">
        <svg viewBox="0 0 24 24" className="h-18x w-18x">
          <path
            fill="#abadad"
            d="M10.5 17C14.0841 17 17 14.0841 17 10.5C17 6.91592 14.0841 4 10.5 4C6.91592 4 4 6.91592 4 10.5C4 14.0841 6.91592 17 10.5 17ZM10.5 2C15.1944 2 19 5.80558 19 10.5C19 12.3368 18.4173 14.0374 17.4268 15.4273L22 20.0049L20.0049 22L15.4272 17.4269C14.0373 18.4173 12.3368 19 10.5 19C5.80558 19 2 15.1944 2 10.5C2 5.80558 5.80558 2 10.5 2Z"
          ></path>
        </svg>
      </span>
      <input
        ref={props?.inputRef ? props.inputRef : null}
        onChange={props.onChange}
        type="text"
        name="company-website"
        id="company-website"
        className="focus:outline-none flex-1 text-16x block w-full input rounded-none  border  border-gray-200"
        placeholder={label}
        value={props?.value}
      />
    </div>
  );
};

export default Search;
