import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPaymentType: null,
};

const PaymentMethod = createSlice({
  name: "payment_methods",
  initialState,
  reducers: {
    setSelectedPaymentType: (state, action) => {
      state.selectedPaymentType = action.payload;
    },
  },
});

export const { setSelectedPaymentType } = PaymentMethod.actions;

export default PaymentMethod.reducer;
