import React from "react";
import { DiamondIcon } from "icons";
import { UTILS } from "utils";
import { useSelector } from "react-redux";
import { t } from "i18next";

const LoyaltyCreditWidget = React.memo(({ loyaltyPolicy }) => {
  const { orderGrossAmount } = useSelector((state) => state?.cart);
  return (
    <span className="flex mt-5 items-center text-black leading-normal hover:no-underline py-3 px-2 rounded-lg mbdj-6 bg-gray-100 mx-4">
      <div className="px-1">
        <div className="bg-primary-light w-9 h-9 rounded-full flex items-center justify-center">
          <DiamondIcon />
        </div>
      </div>
      <span className="text-15x m-0 px-1">
        {t("You will earn", {
          amount: UTILS.calculateLoyaltyPoint(loyaltyPolicy, orderGrossAmount),
        })}
      </span>
    </span>
  );
});

export default LoyaltyCreditWidget;
