import React from "react";
import { useEffect } from "react";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { OrderAction } from "store/actions";
import PageHeader from "components/PageHeader";
import OrderList from "./OrderList";
import WithLoading from "components/Hoc/WithLoading";

const OrderContainer = ({ handleBack, handleOrderDetail }) => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state?.order);
  const { myorders } = useSelector((state) => state?.order);

  useEffect(() => {
    dispatch(OrderAction.getMyOrders());
  }, []);

  if (error)
    return (
      <h2 className="text-center">
        {error?.message ?? "Error in Fetching orders"}
      </h2>
    );
  return (
    <WithLoading>
      <div className="order-page min-h-app-height max-460">
        <PageHeader
          pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full border-b-2 relative"
          title={t("Orders")}
          url={true}
          handleClick={handleBack}
        />

        {myorders?.orders?.length > 0 ? (
          <OrderList
            orderList={myorders}
            handleOrderDetail={handleOrderDetail}
          />
        ) : (
          <div className="text-center mt-3 font-semibold">
            {t("You don't Have any order yet.")}
          </div>
        )}
      </div>
    </WithLoading>
  );
};
export default OrderContainer;
