import React from "react";
const VerificationInput = ({ value, handleChange, name }) => {
  return (
    <input
      maxLength={4}
      type="tel"
      id="verificationInput"
      autoComplete="off"
      name={name}
      value={value}
      onChange={handleChange}
      className="m-1 bg-default-bg border border-gray-200 border-brand-base-primary outline-none verify-input font-bold text-21x text-outer-space h-12 text-center inline-block rounded p-1"
    />
  );
};
export default VerificationInput;
