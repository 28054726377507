import React from "react";
import { t } from "i18next";

const EmptyBasket = ({ backToHome }) => {
  return (
    <div
      className="text-center flex items-center justify-center flex-col"
      style={{ minHeight: "calc(100vh - 70px)" }}
    >
      <div className="flex items-center justify-center mb-4 w-20 h-20 border rounded-full border-brand-base-primary mx-auto">
        <svg
          fill="currentColor"
          xmlns="https://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          className="w-8 h-8 text-brand-base-primary inline-block"
        >
          <path d="M94.2 90.6l-4.2-21V29.5c0-2-1.6-3.7-3.7-3.7H71.5V13.2c0-4.5-3.7-8.2-8.2-8.2H36.2C31.7 5 28 8.7 28 13.2v12.6H13.1c-2 0-3.7 1.6-3.7 3.7v40.2l-3.7 21c-.2 1.1.1 2.2.8 3 .8.8 1.8 1.3 2.9 1.3H90.6c1.1 0 2.1-.5 2.8-1.3.8-.9 1-2 .8-3.1zM35.3 13.2c0-.5.4-.9.9-.9h27.1c.5 0 .9.4.9.9v12.6H35.3V13.2zM16.8 33.1h54.5v36.6l-2.9 18H13.7l2.5-14h49.1c2 0 3.7-1.6 3.7-3.7 0-2-1.6-3.7-3.7-3.7H16.8V33.1zm59.1 54.6l2.7-17.1v-.3V33.1h4V70c0 .2 0 .5.1.7l3.4 16.9H75.9z"></path>
        </svg>
      </div>
      <p className="text-16x font-bold">{t("Your basket is empty")}</p>
      <span
        className="text-14x text-brand-base-primary font-bold
        cursor-pointer"
        onClick={backToHome}
      >
        {t("Add more items")}
      </span>
    </div>
  );
};

export default EmptyBasket;
