import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import PowerBy from "components/PowerBy";
import { Spinner } from "components";
const MenuContainer = (props) => {
  const { isLoading } = useSelector((state) => state?.loader);
  if (isLoading) return <Spinner />;
  return (
    <Fragment>
      {props?.children}
      <PowerBy />
    </Fragment>
  );
};

export default MenuContainer;
