import React, { useRef } from "react";
import { useSelector } from "react-redux";
import ConfettiC from "components/Confetti";
import CustomModal from "components/CustomModal";
import useEventTracker from "hooks/useEventTracker";
import { t } from "i18next";
import Close from "icons/Close";
import { copyToClipboard } from "utils/Utils";

function DiscountInfoPopup({ open, discount, setOpen }) {
  const { welcomeCouponModal } = useSelector((state) => state.AppReducer);
  const { orderType } = useSelector((state) => state?.AppReducer);
  const [ModdalHeight, setModdalHeight] = React.useState("0px");
  const elementRef = useRef(null);

  useEventTracker({ name: "DISCOUNT_INFO_POPUP", shouldLog: open, orderType });

  const checkHeight = () => {
    if (elementRef.current) {
      const divStyles = window.getComputedStyle(elementRef.current);
      const newHeight = divStyles.height;
      if (newHeight !== ModdalHeight) {
        setModdalHeight(newHeight);
      }
    }
  };

  const intervalId = setInterval(checkHeight, 500);
  // Clean up the interval when the component unmounts
  React.useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);
  return (
    <>
      {discount?.discountType && discount?.discountCode && (
        <CustomModal
          open={open}
          setOpen={setOpen}
          bodyclasses="flex"
          overlayClasses="fixed h-screen overflow-y-auto w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40 border-sc"
        >
          {welcomeCouponModal && <ConfettiC modalHeight={ModdalHeight} />}
          <div
            ref={elementRef}
            id="discount-info-popup"
            className="fixed inset-x-0 bottom-0 flex justify-center"
          >
            <div className="bg-white w-full max-460  shadow-md ">
              <h2 className="w-fudll absolute max-460 -top-9 flex justify-end px-1">
                <Close onClick={() => setOpen(false)} />
              </h2>

              <div className="border-b p-6 py-4 border-gray-300  items-center flex justify-between">
                <h3 className="text-21x uppercase">
                  <span className="font-bold">
                    {discount?.discountType === "percentage"
                      ? t("x% off", {
                          percentage: discount?.discountAmount,
                        })
                      : t("x AED off", {
                          amount: discount?.discountAmount,
                        })}
                  </span>
                </h3>
                <div
                  className="text-end "
                  onClick={() => {
                    copyToClipboard(
                      discount.discountCode,
                      t("The COUPON code has been successfully copied")
                    );
                    setOpen(false);
                  }}
                >
                  <span className=" rounded cursor-pointer bg-brand-base-primary  p-2   text-brand-text-primary">
                    {t("Copy code")}
                  </span>
                </div>
              </div>

              <div className="p-6">
                <div className="flex text-17x">
                  <div className="coupon-code">
                    {t("Code")}:{" "}
                    <span className="text-19x font-bold text-green-600 uppercase">
                      {discount?.discountCode}
                    </span>
                  </div>
                </div>
                <ul className="pt-2 list-disc list-inside text-15x">
                  {discount?.minOrderValue > 0 ? (
                    <li className="pb-2 marker:text-brand-base-primary">
                      {t("valid on orders of at least AED", {
                        amount: discount?.minOrderValue,
                      })}{" "}
                    </li>
                  ) : null}
                  <li className="pb-2 marker:text-brand-base-primary">
                    {discount?.isApplicableOnAllStores
                      ? t("Available on all outlets")
                      : t("Available on selected outlets only")}
                  </li>
                  <li className="pb-2 marker:text-brand-base-primary">
                    {discount?.isApplicableOnAllItems
                      ? t("Applicable to all items")
                      : t("Applicable to selected items")}
                  </li>
                  {discount?.discountType === "percentage" ? (
                    <li className="pb-2 marker:text-brand-base-primary">
                      {t("Discount value up to AED", {
                        amount: discount?.maximumDiscountValue,
                      })}
                    </li>
                  ) : null}

                  {discount?.perCustomerUse > 0 &&
                  discount?.perCustomerUse <= 10 ? (
                    discount?.perCustomerUse === 1 ? (
                      <li className="pb-2 marker:text-brand-base-primary">
                        {t("Applicable x time per user", {
                          usageLimit: discount?.perCustomerUse,
                        })}
                      </li>
                    ) : (
                      <li className="pb-2 marker:text-brand-base-primary">
                        {t("Applicable x time(s) per user", {
                          usageLimit: discount?.perCustomerUse,
                        })}
                      </li>
                    )
                  ) : null}
                </ul>
                <div className=" text-center text-brand-text-primary bg-brand-base-primary px-2 py-1 mx-1 rounded">
                  {t(
                    "Put items in your cart, then apply the coupon code at checkout page"
                  )}
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
}

export default DiscountInfoPopup;
