import { useDispatch, useSelector } from "react-redux";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { Loader } from "store/actions";
import { logPopupError } from "utils/Utils";
import { useDeleteAddressMutation } from "store/deliveryAddress/delivery-address-service";
import { DeliveryAddressAction } from "store/actions";
import useGetSavedAddressList from "service-hooks/useGetSavedAddressList";
import { useDeliveryAddressContext } from "contexts/userAddressess";

function useDeleteDelAddress() {
  const dispatch = useDispatch();
  const [deleteAddress] = useDeleteAddressMutation();
  const { getAddresses } = useGetSavedAddressList({
    fetch: false,
    type: "filterd",
  });

  const { userdeliveryAdress } = useSelector(
    (state) => state.DeliveryAddressReducer
  );
  const { setAddressState } = useDeliveryAddressContext();

  const deleteSlectedAddress = async (userAddressId) => {
    try {
      if (!userAddressId) return;
      dispatch(Loader.togggleCheckoutLoader(true));
      if (userAddressId === userdeliveryAdress?._id) {
        dispatch(DeliveryAddressAction.setDeliveryAddress(null));
      }
      const data = await deleteAddress({ userAddressId });
      if (data?.data?.success === 1) {
        await getAddresses();
        setAddressState("savedAddressListModal", false);
      } else logPopupError(errorHandler(data?.error?.data), true);
    } catch (error) {
      logPopupError(errorHandler(error?.error), true);
    } finally {
      dispatch(Loader.togggleCheckoutLoader(false));
    }
  };

  return deleteSlectedAddress;
}

export default useDeleteDelAddress;
