import { all } from "redux-saga/effects";
import brandSaga from "./home/brand/brandSaga";
// import storeSaga from "./outlet-based-store/storeSaga";
import discounts from "./checkout/discounts/saga";
import orderSaga from "./checkout/order/orderSaga";
import itemSaga from "./item/itemSaga";
export default function* rootSaga() {
  yield all([
    brandSaga(),
    // storeSaga(),
    orderSaga(),
    discounts(),
    itemSaga(),
  ]);
}
