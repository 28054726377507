import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreAction } from "store/actions";
import C from "Constants";
import { resetPaymentType } from "utils/Utils";
import { keyBy } from "lodash";
import {
  useGetUserSavedPaymentCardsMutation,
  useGetStripeSavedCardsMutation,
} from "store/payment-cards/paymentCards-service";
import { togglePaymentType } from "utils/Utils";
import { isActiveAnyPaymentGateway } from "utils/Utils";
function useSetDefaultPaymentType() {
  const dispatch = useDispatch();
  const { orderType } = useSelector((state) => state.AppReducer);
  const { selectedPayment, selectedStore } = useSelector(
    (state) => state.store
  );
  const [getStripeSavedCards] = useGetStripeSavedCardsMutation();
  const [getUserSavedPaymentCards] = useGetUserSavedPaymentCardsMutation();
  const { activeIntegrations } = useSelector(
    (state) => state?.brandStorageReducer?.brandInfo
  );

  const { savedPaymentCards, stripeSavedPaymentCards } = useSelector(
    (state) => state.PaymentCardsReducer
  );

  const integrationsByName = useMemo(
    () =>
      keyBy(
        activeIntegrations.filter(
          (ai) => ai.category === C.PAYMENT_GATEWAY && ai.isActive
        ),
        "name"
      ),
    [activeIntegrations]
  );

  //checkout dot com
  const getSavedCards = async () => {
    try {
      await getUserSavedPaymentCards();
    } catch (error) {}
  };

  //stripe
  const getStripeCards = async () => {
    try {
      await getStripeSavedCards();
    } catch (error) {}
  };

  useEffect(() => {
    async function setDefaultPaymentMethod() {
      if (!selectedPayment) {
        resetPaymentType();
        let defaultPaymentMethod = null;
        if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
          defaultPaymentMethod = selectedStore?.paymentOptions?.find(
            (payment) => payment.paymentType === C.CREDIT_CARD
          );
        }
        if (orderType?.serviceType === C.SERVICE_TYPE.PICKUP) {
          defaultPaymentMethod = selectedStore?.pickupPaymentOptions?.find(
            (payment) => payment.paymentType === C.CREDIT_CARD
          );
        }

        if (orderType?.serviceType === C.SERVICE_TYPE.DINE_IN) {
          defaultPaymentMethod = selectedStore?.dineInPaymentOptions?.find(
            (payment) => payment.paymentType === C.CREDIT_CARD
          );
        }
        if (defaultPaymentMethod && isActiveAnyPaymentGateway()) {
          dispatch(StoreAction.setSelectedPaymentMethod(defaultPaymentMethod));
        }
      }
      if (
        integrationsByName[C.STRIPE] &&
        stripeSavedPaymentCards?.length <= 0
      ) {
        await getStripeCards();
      } else if (
        integrationsByName[C.STRIPE] &&
        stripeSavedPaymentCards?.length >= 1
      ) {
        togglePaymentType("saved_card");
      } else if (
        integrationsByName[C.CHECKOUT_DOT_COM] &&
        savedPaymentCards?.length <= 0 &&
        !integrationsByName[C.STRIPE]
      ) {
        await getSavedCards();
      } else if (
        integrationsByName[C.CHECKOUT_DOT_COM] &&
        savedPaymentCards?.length >= 1
      ) {
        togglePaymentType("saved_card");
      } else if (
        integrationsByName[C.TELR] &&
        savedPaymentCards?.length <= 0 &&
        stripeSavedPaymentCards?.length <= 0
      ) {
        togglePaymentType("new_card");
      }
    }
    setDefaultPaymentMethod();
  }, [selectedStore, orderType?.serviceType]);

  return {};
}

export default useSetDefaultPaymentType;
