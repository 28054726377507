import React from "react";
import moment from "moment";
import { t } from "i18next";

import { useLang } from "hooks";
import Card from "components/Card";
function PaymentLinkVerified({ paymentLinkInfo }) {
  const language = useLang();

  return (
    <>
      <div className="text-15x ">
        <div className="mx-4">
          <div className="flex justify-center mb-8 ">
            <svg
              xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
              fill="currentColor"
              className=" w-20 h-20 checkmark mt-3"
            >
              <circle cx="26" cy="26" r="25" fill="none" />
              <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <div className="mx-4 my-8 ">
            <h2 className="text-24x font-bold text-center">
              {t("Payment completed")}
            </h2>
          </div>
          <Card>
            <div className="mx-4 py-4">
              <h2 className=" text-19x font-semibold">{t("Your Receipt")}</h2>

              <div className="py-2">
                <p className="text-gray-800 font-medium">{t("Receipt number")}</p>
                <p>#{paymentLinkInfo?.cartId}</p>
              </div>
              <div className="py-2">
                <p className="text-gray-800 font-medium">{t("Payment date")}</p>
                <p>{moment(paymentLinkInfo?.updatedAt).format("DD-MM-YYYY")}</p>
              </div>
              <div className="py-2">
                <p className="text-gray-800 font-medium">{t("Total")}</p>
                <p>
                  {paymentLinkInfo?.amount?.toLocaleString(language?.currentLang, {
                    style: "currency",
                    currency: "AED",
                  })}
                </p>
              </div>
              <div className="py-2">
                <p className="text-gray-800 font-medium">{t("Message")}</p>
                <p>{paymentLinkInfo?.message}</p>
              </div>
              <p className="text-gray-800 font-medium">{t("Purpose of payment")}</p>
              {/* <p>Jhon doe</p> */}
              <p>{paymentLinkInfo?.purposeOfPayment}</p>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default PaymentLinkVerified;
