import React, { createContext, useContext, useReducer } from "react";

const ErrorContext = createContext();
const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
};

// Create a provider component
export const ErrorContextProvider = ({ children }) => {
  const [errorState, dispatch] = useReducer(appReducer, {
    addressError: {
      message: null,
      show: false,
    },
  });

  const setErrorContext = (key, val) => {
    console.log("setError", val);
    dispatch({
      type: "SET_STATE",
      payload: {
        key,
        value: val,
      },
    });
  };

  return (
    <ErrorContext.Provider
      value={{
        errorState,
        setErrorContext,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

// Create a custom hook for using the context
export const useErrorContext = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
};
