import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ErrorModal, CustomModal } from "components";
import OrderPlaced from "components/checkout/OrderPlaced";
import { NotPersistAction, ErrorAction } from "store/actions";
import { useCustomHistory } from "hooks";
import ConfirmModal from "components/Modals/ConfirmModal";
import useQueryParam from "modules/dine-in/useQueryParam";

function Checkout(props) {
  const params = useParams();
  const router = useCustomHistory();
  const queryParam = useQueryParam();

  const dispatch = useDispatch();
  const reduxState = useSelector((rstate) => ({
    cart: rstate?.cart,
    order: rstate?.order,
    AppReducer: rstate.AppReducer,
    NotPersist: rstate?.NotPersist,
    SelectedAddress: rstate.DeliveryAddressReducer,

  }));
  const { error, errorModaL } = useSelector((rstate) => rstate.errorSlice);
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { orderSuccess } = reduxState.NotPersist;
  const { placedOrder } = reduxState.order;



  const trackOrder = () => {
    let url = "/dine-in/track-order/";
    document.body.removeAttribute("style");
    dispatch(NotPersistAction.toggleOrderPlaceModel(false));
    if (placedOrder?.savedOrder) {
      const { savedOrder } = placedOrder;
      router(
        `${url}${savedOrder?.orderNumber}`,
        `/${params.domainName}${url}${savedOrder?.orderNumber}`,
        DomainType,
        null,
        `?${queryParam}`
      );
    }
  };

  const gotoHomePage = () => {
    router(
      "/dine-in",
      `/${params.domainName}/dine-in/`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };

  return (
    <Fragment>
      <CustomModal
        open={errorModaL}
        reduxAction={ErrorAction.setErrorModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ErrorModal
          reduxAction={ErrorAction.setErrorModal(false)}
          message={error}
        />
      </CustomModal>
      <CustomModal
        open={orderSuccess}
        reduxAction={NotPersistAction.toggleOrderPlaceModel(false)}
        bodyclasses="flex"
        overlayClasses="fixed overflow-y-auto h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40 border-sc"
      >
        <OrderPlaced {...{ trackOrder, gotoHomePage }} />
      </CustomModal>
      <CustomModal
        open={props.confirmModal}
        setOpen={props.setConfirmModal}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <ConfirmModal
          onConfirm={props.handleConfirmModal}
          onCancel={props.handleCancelModal}
          title={props.confirmModalTitle}
        />
      </CustomModal>
    </Fragment>
  );
}

export default Checkout;
