import { combineReducers } from "@reduxjs/toolkit";
import brand from "./home/brand/brandSlice"; //don't remove
import AppReducer from "./app/AppReducer"; //don't remove
import store from "./outlet-based-store/storeSlice";
import item from "./item/itemSlice";
import cart from "./checkout/cart/cartSlice";
import order from "./checkout/order/orderSlice";
import NotPersist from "./NotPersited/NotPersist";
import Discounts from "./checkout/discounts/discountSlice";
import Loyalty from "./checkout/loyalty/loyaltySlice";
import loader from "./Loader/loader";
import register from "./auth/register/registerSlice";
import { apiSlice } from "apiHelpers/ApiSlice";
import orderService from "./checkout/order/orderService";
import DeliveryAddressReducer from "./deliveryAddress/delivery-address-reducer";
import storeApi from "./brand-based-store/single-store-service";
import errorSlice from "./Errors/errorSlice";
import singleStoreReducer from "./brand-based-store/single-store-reducer";
import registerApi from "./auth/register/registerService";
import brandStorageReducer from "./home/brand/brandStorage";
import PaymentLinkReducer from "../modules/payment-links/reducers/reducer";
import PaymentCardsReducer from "./payment-cards/paymentCards-reducer";
import PaymentMethodReducer from "./paymentMethods/slice";
import storage from "redux-persist/lib/storage/session";
import UserReducer from "./user/slice";
import orderTimingReducer from "./order-timing/reducer";
import DeliveryAreaReducer from "./delivery-area/reducer";

const appReducer = combineReducers({
  brandStorageReducer, //dont
  brand, //don't
  store,
  item,
  cart,
  order,
  NotPersist,
  Discounts,
  Loyalty,
  loader,
  register,
  DeliveryAddressReducer,
  errorSlice,
  singleStoreReducer,
  AppReducer,
  PaymentLinkReducer,
  PaymentCardsReducer,
  PaymentMethodReducer,
  orderTimingReducer,
  user: UserReducer,
  DeliveryAreaReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [orderService.reducerPath]: orderService.reducer,
  [storeApi.reducerPath]: storeApi.reducer,
  [registerApi.reducerPath]: registerApi.reducer,
});

const rootReducer = (state, action) => {
  let reduxStore = state;
  if (action?.type === "logout") {
    window?.sessionStorage?.clear();
    storage.removeItem("persist:root");
    state = undefined;
  }
  if (action?.type === "removeBrandData") {
    storage.removeItem("persist:root");
    state = undefined;
    sessionStorage.clear();
    //remove everything except brandStorageReducer and AppReducer
  }

  if (action?.type === "order/placeOrderSuccess") {
    state.item = undefined;
    state.orderTimingReducer = undefined;
    state.DeliveryAddressReducer = undefined;
    state.cart = undefined;
    state.PaymentCardsReducer = undefined;
    state.DeliveryAreaReducer = undefined;
    if (
      reduxStore?.AppReducer?.catalogueType === "OUTLET_BASED_CATALOGUE" &&
      reduxStore?.AppReducer?.orderType?.EN.name !== "DINE IN"
    ) {
      state.store = undefined;
    }
  }
  if (action?.type === "newAddress/resetNewAddressReducer") {
    // state.AddNewAddress = undefined;
  }
  if (action.type === "removeOrderData") {
    state.item = undefined;
    state.DeliveryAddressReducer = undefined;
    state.DeliveryAreaReducer = undefined;
    state.cart = undefined;
    // state.AddNewAddress = undefined;
    state.store = undefined;
    state.Discounts = undefined;
    state.Loyalty = undefined;
    state.PaymentMethodReducer = undefined;
    state.PaymentCardsReducer = undefined;
  }
  if (action.type === "resetAreaData") {
    // state.DeliveryAddressReducer = undefined;
    state.DeliveryAreaReducer = undefined;
    state.cart = undefined;
    state.store = undefined;
    state.Discounts = undefined;
    state.Loyalty = undefined;
    state.orderTimingReducer = undefined;
  }
  if (action.type === "resetStoreData") {
    state.cart = undefined;
    state.store = undefined;
    state.Discounts = undefined;
    state.Loyalty = undefined;
    state.orderTimingReducer = undefined;
    state.PaymentMethodReducer = undefined;
    state.PaymentCardsReducer = undefined;
  }

  if (action.type === "resetCartWithStoreData") {
    state.cart = undefined;
    state.Discounts = undefined;
    state.Loyalty = undefined;
    state.PaymentMethodReducer = undefined;
    state.PaymentCardsReducer = undefined;
    state.orderTimingReducer = undefined;
    state.store = undefined;
    state.item = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
