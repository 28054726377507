import React, { useMemo, useState } from "react";
import Coupons from "../home/Coupons";
import { useDispatch, useSelector } from "react-redux";
import DiscountInfoPopup from "../home/DiscountInfoPopup";
import { AppAction } from "store/actions";
import { UTILS } from "utils";

function DiscountContainer({ discounts, selectedStore }) {
  const dispatch = useDispatch();
  const { orderType } = useSelector((state) => state.AppReducer);
  const { welcomeCouponModal } = useSelector((state) => state.AppReducer);
  const [discountPopup, setdiscountPopup] = useState({
    open: false,
    discount: null,
  });

  const openDiscountModal = (dis, value) => {
    setdiscountPopup({
      open: value,
      discount: dis,
    });
  };

  const filteredDiscounts = useMemo(
    () => UTILS.getFilteredCoupons(discounts, selectedStore, orderType),
    [discounts, selectedStore, orderType]
  );

  return (
    <>
      <Coupons
        {...{ discounts }}
        selectedStore={selectedStore}
        openDiscountModal={openDiscountModal}
      />
      {filteredDiscounts?.length > 0 && (
        <DiscountInfoPopup
          open={welcomeCouponModal}
          setOpen={() => dispatch(AppAction.setWelcomeCouponModal(false))}
          discount={filteredDiscounts?.[0]}
        />
      )}
      <DiscountInfoPopup
        open={discountPopup.open}
        setOpen={openDiscountModal}
        discount={discountPopup.discount}
      />
    </>
  );
}

export default DiscountContainer;
