import React from "react";
import { SVGICONS } from "config";
import { t } from "i18next";
const LoginHeader = ({ goBack }) => {
  return (
    <React.Fragment>
      <div className="mb-5">
        <button className="w-5 h-5" onClick={goBack}>
          <SVGICONS.BackArrow />
        </button>
      </div>

      <h2 className=" pb-5 text-26x font-bold leading-24x">
        <span>{t("Enter your")}</span>
        <span className=" blinking-login-header px-2">
          {t("WhatsApp")}
        </span>{" "}
      </h2>
      <p className="text-15x leading-22x text-outer-space">
        {t(
          "We will send you a WhatsApp message with a one-time verification code"
        )}
      </p>
    </React.Fragment>
  );
};

export default LoginHeader;
