import { createAction } from "@reduxjs/toolkit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandAction } from "store/actions";
import { AppAction } from "store/actions";
import C from "Constants";
import { useTranslation } from "react-i18next";
import { PaymentCardsAction } from "store/actions";
import { StoreAction } from "store/actions";
import { PaymentMethodAction } from "store/actions";
function useServiceType() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { orderType } = useSelector((state) => state?.AppReducer);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState({
    title: "",
    message: "",
  });
  const [confirmationCallback, setConfirmationCallback] = useState(
    () => () => {}
  );
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const handleConfirmModal = () => {
    setConfirmModal(false);
    confirmationCallback();
  };

  const handleCancelModal = () => {
    setConfirmModal(false);
  };

  const resetPaymentType = () => {
    dispatch(
      PaymentCardsAction.setstripeSelectedCard({
        cardInfo: {},
        isStoredCard: false,
      })
    );
    dispatch(PaymentCardsAction.setstripePaymentCards([]));
    dispatch(StoreAction.setSelectedPaymentMethod(null));
    dispatch(PaymentMethodAction.setSelectedPaymentType(null));
  };

  const removeData = createAction("removeOrderData");
  const setServiceType = (type) => {
    if (orderType?.EN?.name === type?.serviceType || !type) {
      setOpen(false);
      return;
    }
    if (
      orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY &&
      type?.serviceType === C.SERVICE_TYPE.PICKUP
    ) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use X service", {
          serviceType: t("PICKUP"),
        }),
        message: t("Are you sure you want to choose the service", {
          serviceType: t("PICKUP"),
        }),
      });

      setConfirmationCallback(() => () => {
        dispatch(removeData());
        resetPaymentType();
        dispatch(BrandAction.getBrandStores(brandInfo?.brand?._id));
        dispatch(AppAction.setOrderType(type));
        dispatch(BrandAction.getDiscounts({ brandId: brandInfo.brand?._id }));
      });
    } else if (
      orderType?.EN?.name === C.SERVICE_TYPE.PICKUP &&
      type?.serviceType === C.SERVICE_TYPE.DELIVERY
    ) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use X service", {
          serviceType: t("DELIVERY"),
        }),
        message: t("Are you sure you want to choose the service", {
          serviceType: t("DELIVERY"),
        }),
      });
      setConfirmationCallback(() => () => {
        dispatch(removeData());
        resetPaymentType();
        dispatch(
          BrandAction.getBrandDeliveryAreas({
            brandId: brandInfo?.brand?._id,
            updateMethod: true,
          })
        );
        dispatch(AppAction.setOrderType(type));
        dispatch(BrandAction.getDiscounts({ brandId: brandInfo.brand?._id }));
      });
    } else {
      dispatch(AppAction.setOrderType(type));
    }
    setOpen(false);
  };
  return {
    setServiceType,
    confirmModal,
    setConfirmModal,
    confirmModalTitle,
    handleConfirmModal,
    handleCancelModal,
    open,
    setOpen,
  };
}

export default useServiceType;
