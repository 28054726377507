import { useDispatch, useSelector } from "react-redux";

import * as Actions from "store/actions";

import useGetSavedAddressList from "service-hooks/useGetSavedAddressList";
import { useAddNewUserAddressMutation } from "store/deliveryAddress/delivery-address-service";

import { handleLocalStorage } from "utils/storage";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { logPopupError, logErrorOnSentry } from "utils/Utils";

import C from "Constants";
import { useDeliveryAddressContext } from "contexts/userAddressess";
import { DeliveryAddressAction } from "store/actions";
import { useMapContext } from "contexts/googleMap";

function useAddUserDeliveryAddress(history, fetch) {
  const dispatch = useDispatch();
  const { reset } = useMapContext();

  const { setAddressState } = useDeliveryAddressContext();

  const [addNewUserAddress] = useAddNewUserAddressMutation();
  const { getAddresses, setAddress } = useGetSavedAddressList({
    fetch: false,
    type: "filtered",
  });
  const { shippingMethod } = useSelector((state) => state.AppReducer);
  const { outletBasedSelectedStoreArea } = useSelector((state) => state.store);

  const getUserObjectParams = (historyState, formData) => {
    if (shippingMethod === C.SHIPPING_METHODS?.MAPPED_AREA_BASED) {
      if (formData?.area_type === "distance") {
        const objectParams = {
          deliveryMethodType: C.SHIPPING_METHODS.MAPPED_AREA_BASED,
        };
        return objectParams;
      }

      if (
        historyState?.mappedAreaInfo?.mappedAreaId?._id &&
        historyState?.mappedAreaType !== C.DISTANCE
      ) {
        const objectParams = {
          mappedAreaId: historyState?.mappedAreaInfo?.mappedAreaId?._id,
          deliveryMethodType: C.SHIPPING_METHODS.MAPPED_AREA_BASED,
        };
        return objectParams;
      } else if (historyState?.mappedAreaType === C.DISTANCE) {
        const objectParams = {
          deliveryMethodType: C.SHIPPING_METHODS.MAPPED_AREA_BASED,
        };
        return objectParams;
      } else if (
        outletBasedSelectedStoreArea?.mappedAreaInfo?.mappedAreaId?._id
      ) {
        const objectParams = {
          mappedAreaId:
            outletBasedSelectedStoreArea?.mappedAreaInfo?.mappedAreaId?._id,
          deliveryMethodType: C.SHIPPING_METHODS.MAPPED_AREA_BASED,
        };
        return objectParams;
      } else {
        const saved = JSON.stringify(outletBasedSelectedStoreArea);
        const historyS = JSON.stringify(historyState);
        logErrorOnSentry(
          "Info log===>Mapped Area Id is not available==>check if user selected zone or not",
          "info",
          `stored=${saved}history = ${historyS}`
        );
        const objectParams = {
          deliveryMethodType: C.SHIPPING_METHODS.MAPPED_AREA_BASED,
        };
        return objectParams;
      }
    } else {
      const objectParams = {
        areaId: outletBasedSelectedStoreArea?.areaID?._id,
        countryId: outletBasedSelectedStoreArea?.countryID,
        cityId: outletBasedSelectedStoreArea?.cityID,
        deliveryMethodType: C.SHIPPING_METHODS.AREA_BASED,
      };
      return objectParams;
    }
  };
  const addUserDeliveryAddress = async (formData) => {
    try {
      dispatch(Actions.Loader.enableLoader());
      const obj = {
        ...formData,
        ...getUserObjectParams(history?.location?.state?.data, formData),
      };
      const response = await addNewUserAddress(obj);
      if (response?.data?.success === 1 && response?.data?.data?.address) {
        dispatch(
          Actions.DeliveryAddressAction.setDeliveryAddress(
            response?.data?.data?.address
          )
        );
        handleLocalStorage.setItem(
          "deliveryAddress",
          response?.data?.data?.address
        );

        if (history?.location?.state?.data) {
          dispatch(
            Actions.StoreAction.setSelectedArea(history?.location?.state?.data)
          );
        }
        setAddress(response?.data?.data?.address);
        setAddressState("savedAddressListModal", false);
        const addedAddressId = response?.data?.data?.address?._id;
        if (fetch) await getAddresses(addedAddressId);
        return response?.data?.data?.address;
      } else {
        logPopupError(errorHandler(response?.error?.data), true);
        return false;
      }
    } catch (error) {
      logPopupError(errorHandler(error), true);
      return false;
    } finally {
      dispatch(DeliveryAddressAction.resetTempDeliveryAddressHome());
      dispatch(DeliveryAddressAction.resetTempDeliveryAddress());
      reset();
      dispatch(Actions.Loader.disableLoader());
    }
  };
  return addUserDeliveryAddress;
}

export default useAddUserDeliveryAddress;
