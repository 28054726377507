function Delete({ onClick, classNames }) {
  return (
    <svg
      onClick={onClick}
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        classNames ? classNames : "w-4 h-4"
      }fill-success text-brand-base-primary`}
    >
      <path d="M9.85 8.789H8.53v7.269h1.32V8.789zM13.47 8.789h-1.32v7.269h1.32V8.789z"></path>
      <path d="M12.487 1.804H9.513a1.76 1.76 0 00-1.76 1.76v1.085H3.5a.66.66 0 100 1.32h1.173l.64 11.523a2.86 2.86 0 002.86 2.702h5.66a2.86 2.86 0 002.86-2.702l.64-11.523h1.173a.66.66 0 100-1.32h-4.259V3.564a1.76 1.76 0 00-1.76-1.76zm-3.412 1.76a.44.44 0 01.44-.44h2.972a.44.44 0 01.44.44v1.085H9.073l.002-1.085zm6.932 2.405l-.636 11.453a1.54 1.54 0 01-1.54 1.454H8.166a1.54 1.54 0 01-1.54-1.454L5.993 5.969h10.014z"></path>
    </svg>
  );
}
export default Delete;
