import LogRocket from "logrocket";
import { useEffect } from "react";
import { shouldTrackSessions } from "utils/Utils";

const useEventTracker = ({ name, orderType, shouldLog }) => {
  const logEvent = (eventName) => {
    shouldTrackSessions() && console.log(eventName);
    shouldTrackSessions() && LogRocket.track(eventName);
  };

  useEffect(() => {
    shouldTrackSessions() &&
      shouldLog &&
      console.log(`${name}_${orderType?.serviceType ?? ""}`);
    shouldTrackSessions() &&
      shouldLog &&
      LogRocket.track(`${name}_${orderType?.serviceType ?? ""}`);
  }, [shouldLog, orderType?.serviceType, name]);

  return logEvent;
};

export default useEventTracker;
