import React from "react";
const Card = (props) => {
  return (
    <div className="bg-white  mb-5 rounded-lg border border-gray-200 shadow-xl">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="overflow-hidden">
          <div className={props.styleClasses}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};
export default Card;
