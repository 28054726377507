import { apiSlice, postConfig } from "apiHelpers/ApiSlice";
import * as URL from "apiHelpers/api/ApiUrls";
import { Loader, ErrorAction, PaymentLinkAction } from "store/actions";
import { errorHandler } from "apiHelpers/api/errorHandler";

const paymentLinkService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentLinkInfo: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_PAYMENT_LINK_INFO,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        // dispatch(Loader.enableLoader());
        dispatch(PaymentLinkAction.toggleLoader(true));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            dispatch(PaymentLinkAction.setpaymentLinkInfo(data.data));
          } else {
            dispatch(PaymentLinkAction.setpaymentLinkInfo(null));
            let error = {
              error: errorHandler(data),
              modal: false,
            };
            dispatch(ErrorAction.setError(error));
          }
        } catch (e) {
          dispatch(PaymentLinkAction.setpaymentLinkInfo(null));
          let error = {
            error: errorHandler(e.error),
            modal: false,
          };
          dispatch(ErrorAction.setError(error));
        }
        dispatch(PaymentLinkAction.toggleLoader(false));
        // dispatch(Loader.disableLoader());
      },
    }),
    initiatePaymentLink: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.INITIATE_PAYMENT_LINK_REQUEST,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        // dispatch(Loader.enableLoader());
        dispatch(PaymentLinkAction.toggleLoader(true));
        try {
          const { data } = await queryFulfilled;
          if (data) {
          } else {
            let error = {
              error: errorHandler(data),
              modal: true,
            };
            dispatch(ErrorAction.setError(error));
          }
        } catch (err) {
          let error = {
            error: errorHandler(err.error),
            modal: true,
          };
          dispatch(ErrorAction.setError(error));
        }
        setTimeout(() => {
          // dispatch(Loader.disableLoader());
          dispatch(PaymentLinkAction.toggleLoader(false));
        }, 3000);
      },
    }),
    verifyPaymentLink: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.VERIFY_PAYMENT_LINK,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        // dispatch(Loader.enableLoader());
        dispatch(PaymentLinkAction.toggleLoader(true));
        try {
          const { data } = await queryFulfilled;
          if (data) {
          } else {
            let error = {
              error: errorHandler(data),
              modal: true,
            };
            dispatch(ErrorAction.setError(error));
          }
        } catch (err) {
          let error = {
            error: errorHandler(err.error),
            modal: true,
          };
          dispatch(ErrorAction.setError(error));
        }
        // dispatch(Loader.disableLoader());
        dispatch(PaymentLinkAction.toggleLoader(false));
      },
    }),
  }),
  overrideExisting: true,
});

export default paymentLinkService;
export const {
  useGetPaymentLinkInfoMutation,
  useInitiatePaymentLinkMutation,
  useVerifyPaymentLinkMutation,
} = paymentLinkService;
