import React, { useState, useEffect } from "react";
import { t } from "i18next";
const Step1 = ({ step }) => {
  return (
    <div className={`status ${step > 1 ? "status-line" : ""}`}>
      <span
        className={`status--checked ${
          step >= 1 ? "bg-brand-base-primary" : "uncheck"
        } w-5 h-5 border border-brand-base-primary `}
      ></span>
      <div className="py-5 border-b  w-full">
        <div className="mb-1 font-medium">{t("New")}</div>
        <div className={`${step === 1 ? "font-bold" : "status__desc"}`}>
          {t("Your order has been sent")}
        </div>
      </div>
    </div>
  );
};
const Step2 = ({ step }) => {
  return (
    <div className={`status ${step > 2 ? "status-line" : ""}`}>
      <span
        className={`status--checked ${
          step >= 2 ? "bg-brand-base-primary" : "uncheck"
        } w-5 h-5 border border-brand-base-primary `}
      ></span>
      <div className="py-5 border-b w-full">
        <div className="font-medium"> {t("In Progress")}</div>
        <div className={`${step === 2 ? "font-bold" : "status__desc"}`}>
          {t("Your order is being prepared!")}
        </div>
      </div>
    </div>
  );
};

const Step4 = ({ step, orderInfo }) => {
  return (
    <div className={`status ${step > 3 ? "status-line" : ""}`}>
      <span
        className={`status--checked ${
          step >= 3 ? "bg-brand-base-primary" : "uncheck"
        } w-5 h-5 border border-brand-base-primary `}
      ></span>
      <div className="py-5 border-b  w-full">
        {orderInfo?.orderType?.type !== "DELIVERY" ? (
          <>
            <div className="text-gray-500 font-medium"> {t("Ready")}</div>
            <div className={`${step === 3 ? "font-bold" : "status__desc"}`}>
              {t("Your order is ready for the X service", {
                serviceType: t(orderInfo?.orderType?.type),
              })}
            </div>
          </>
        ) : (
          <>
            <div className="text-gray-500 font-medium"> {t("In route")}</div>
            <div className={`${step === 3 ? "font-bold" : "status__desc"}`}>
              {t("Your order is on its way!")}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const StepTracking = ({ orderStatus, orderInfo }) => {
  const [step, setSteps] = useState(0);
  useEffect(() => {
    if (orderInfo?.orderStatus === "PENDING") setSteps(1);
    if (
      orderInfo?.orderStatus === "PREPARING" ||
      orderInfo?.orderStatus === "SCHEDULED"
    )
      setSteps(2);
    if (orderInfo?.orderStatus === "ON_THE_WAY") setSteps(3);
  }, [orderInfo]);
  return (
    <div className="step-tracking-page">
      {orderInfo && orderStatus && (
        <div>
          <Step1 step={step} />
          <Step2 step={step} />
          <Step4 step={step} orderInfo={orderInfo} />
        </div>
      )}
    </div>
  );
};

export default StepTracking;
