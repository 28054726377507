import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { t } from "i18next";

import Search from "components/Search";
import Menu from "../../menu/containers";
import UserLogo from "components/UserLogo";
import ImageHeader from "components/ImageHeader";
import WithLoading from "components/Hoc/WithLoading";
import { NotFound, ErrorComponent, CustomModal } from "components";
import ConfirmModal from "components/Modals/ConfirmModal";
import CategoriesList from "components/menu/CategoriesList";
import StoreHeaderDetail from "components/StoreHeaderDetail";
import LoginSignupButton from "components/LoginSignupButton";
import DeliveryPickupButton from "components/DeliveryPickupButton";
import StoreCategoryWithMenu from "components/menu/StoreCategoryWithMenu";
import LoyaltyPolicyWidget from "components/brand-loyalty/LoyaltyPolicyWidget";
import DiscountHomeContainer from "components/discounts/DiscountHomeContainer";

import useCurrentUser from "hooks/useCurrentUser";
import useChangeOrderType from "../useChangeOrderType";
import { useMenuSearch, usePlatform, useCustomHistory, useLang } from "hooks";

import { isItemOutOfStock, createServiceTypeArray } from "utils/Utils";

import C from "Constants";
import * as ROUTE_CONSTANTS from "routes/constants";
import PreORderingWidget from "../components/PreORderingWidget";
import useGetMenu from "../hooks/useGetMenu";
import useSetOrderTiming from "hooks/useSetOrderTiming";
import { isStoreIsOpen } from "utils/storeUtils";

const EcommerceHome = () => {
  usePlatform();
  useSetOrderTiming();
  const params = useParams();
  const { getMenuForPickup, getMenuForDelivery } = useGetMenu();
  const router = useCustomHistory();
  const { currentLang } = useLang();
  const [open, setOpen] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const handleSearch = (e) => {
    let term = e.target.value?.toLowerCase();
    setSearch(term);
  };

  const [searchTerm, setSearch] = useState("");
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  const { notFound, brandMenu, brandMenuError, discounts } = useSelector(
    (state) => state?.brand
  );
  const { selectedStore } = useSelector((state) => state.store);
  const { brandInfo, loyaltyPolicy } = useSelector(
    (state) => state?.brandStorageReducer
  );
  const { currentUser } = useCurrentUser();

  const { isLoading } = useSelector((state) => ({
    isLoading: state?.loader.isLoading,
  }));
  const { filteredMenu } = useMenuSearch(searchTerm, brandMenu);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState({
    title: "",
    message: "",
  });
  const [confirmationCallback, setConfirmationCallback] = useState(
    () => () => {}
  );
  const handleConfirmModal = () => {
    setConfirmModal(false);
    confirmationCallback();
  };

  const handleCancelModal = () => setConfirmModal(false);

  const goToSettings = () => {
    let url = ROUTE_CONSTANTS.ECOMMERCE_SETTINGS[1];
    if (params) {
      router(url, `/${params?.domainName}${url}`, DomainType);
    }
  };
  const goToLogin = () => {
    let url = ROUTE_CONSTANTS.ECOMMERCE_LOGIN[1];
    router(url, `/${params?.domainName}${url}`, DomainType);
  };

  const openItem = (item, category) => {
    if (!isStoreIsOpen(selectedStore, orderType?.serviceType)) {
      setOpen(true);
    } else if (
      !isItemOutOfStock(item?.itemId, selectedStore) &&
      item?.itemId?._id &&
      category?._id
    ) {
      let MainURl = "/item/";
      let TempUrl = `/${params?.domainName}${MainURl}${item.itemId?._id}/${category?._id}`;
      let RootUrl = `${MainURl}${item.itemId?._id}/${category?._id}`;
      router(RootUrl, TempUrl, DomainType, item);
    } else {
    }
  };

  const openModal = useChangeOrderType({
    setConfirmModal,
    setConfirmModalTitle,
    setConfirmationCallback,
    getMenuForPickup,
    getMenuForDelivery,
  });
  return (
    <WithLoading>
      {!isLoading && notFound ? (
        <NotFound message={notFound} />
      ) : (
        <div className="store-page store-page-popup">
          <div className="location-bar bg-location py-2 px-4">
            <div className="flex justify-between items-center">
              <DeliveryPickupButton
                activeServices={createServiceTypeArray(brandInfo.setting)}
                orderType={orderType}
                handleServiceType={openModal}
              />
              <div>
                {currentUser?.token ? (
                  <UserLogo {...{ goToSettings }} />
                ) : (
                  <LoginSignupButton {...{ goToLogin }} />
                )}
              </div>
            </div>
          </div>
          <ImageHeader page={true} />
          <StoreHeaderDetail
            page={C.BRAND}
            brandInfo={brandInfo}
            description={C.ECOMMERCE}
            activeServices={createServiceTypeArray(brandInfo.setting)}
            orderType={orderType}
            handleServiceType={openModal}
          />

          <DiscountHomeContainer discounts={discounts} selectedStore={null} />
          {loyaltyPolicy?.isLoyaltyActive && (
            <LoyaltyPolicyWidget {...{ loyaltyPolicy, goToLogin }} />
          )}
          <PreORderingWidget />
          <div>
            {brandMenuError && <ErrorComponent error={brandMenuError} />}
          </div>
          <Menu menu={brandMenu} {...{ filteredMenu, searchTerm, setSearch }}>
            <div className="sticky top-0 z-10 px-4 bg-white">
              <Search
                label={t("Search for menu items")}
                onChange={handleSearch}
                value={searchTerm}
                setInputFocus={setInputFocus}
              />
              {searchTerm?.length <= 0 && (
                <CategoriesList categoriesList={filteredMenu} />
              )}
            </div>
            {filteredMenu?.length === 0 && inputFocus ? (
              <div className="h-app-height"></div>
            ) : (
              <>
                <StoreCategoryWithMenu
                  menu={filteredMenu}
                  menuType={C.BRAND_BASED_CATALOGUE}
                  onItemClick={openItem}
                  {...{ open, setOpen }}
                  page="brand-based"
                />
              </>
            )}
          </Menu>
          <CustomModal
            open={confirmModal}
            setOpen={setConfirmModal}
            bodyclasses="flex"
            overlayClasses={`fixed h-screen w-screen top-0  bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-30 ${
              currentLang === "AR" ? "border-sc-l" : "border-sc"
            }`}
          >
            <ConfirmModal
              onConfirm={handleConfirmModal}
              onCancel={handleCancelModal}
              title={confirmModalTitle}
            />
          </CustomModal>
        </div>
      )}
    </WithLoading>
  );
};

export default EcommerceHome;
