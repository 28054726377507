import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ShowDeliveryTime from "components/ShowDeliveryTime";
import { WhatsappIcon } from "icons";
import { useLang } from "hooks";
import C from "Constants";
function StoreHeaderDetail({ selectedStore, selectedArea }) {
  const { t } = useTranslation();
  const { currentLang } = useLang();
  const { orderType } = useSelector((state) => state.AppReducer);
  return (
    <div className="pt-5 px-4 bg-white">
      <div className="flex justify-between">
        <h1 className="font-nunito text-1.5 font-extrabold text-black  mb-1">
          {selectedStore?.baseLanguage?.name}
        </h1>
        {selectedStore?.whatsAppContact && (
          <a
            href={`https://wa.me/${selectedStore?.whatsAppContact}`}
            className="cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsappIcon />
          </a>
        )}
      </div>
      <p className="text-black mb-3 text-sm">{selectedStore?.manualAddress}</p>
      <div className="flex flex-wrap items-center justify-between">
        <span className="flex items-center">
          <svg
            xmlns="https://www.w3.org/2000/svg"
            viewBox="11 11 70 70"
            fill="currentColor"
            className="h-3 w-3 text-brand-base-primary"
          >
            <path d="M50 18c-17.638 0-32 14.363-32 32s14.362 32 32 32c17.638 0 32-14.363 32-32 0-17.638-14.362-32-32-32zm0 6c14.395 0 26 11.605 26 26S64.395 76 50 76 24 64.395 24 50s11.605-26 26-26zm0 6a3 3 0 00-3 3v17c0 .828.332 1.582.875 2.125L58.5 62.719a2.971 2.971 0 004.219 0 2.971 2.971 0 000-4.219L53 48.75V33a3 3 0 00-3-3z"></path>
          </svg>
          <span className="px-2 text-13x text-black ">
            {orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY ? (
              <ShowDeliveryTime val={selectedArea?.deliveryTime} />
            ) : orderType.EN.name === C.SERVICE_TYPE.PICKUP ? (
              <> {selectedStore?.averagePrepTimeForPickup} </>
            ) : orderType.EN.name === C.SERVICE_TYPE.DINE_IN ? (
              <> {selectedStore?.averagePrepTimeForDineIn} </>
            ) : null}
          </span>
        </span>
        {orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY ? (
          <span className="flex items-center">
            <svg
              className="h-3 w-3 text-brand-base-primary"
              viewBox="0 0 23 23"
              fill="currentColor"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path d="M21.133 7.89a3.836 3.836 0 00-3.906-3.421h-1.11a4.687 4.687 0 00-9.234 0H5.758A3.836 3.836 0 001.852 7.89L.875 18.328a3.656 3.656 0 00.953 2.828 3.984 3.984 0 002.938 1.282h13.468a3.984 3.984 0 002.938-1.282 3.655 3.655 0 00.953-2.828l-.992-10.437zM11.5 2.126a3.125 3.125 0 013.016 2.344H8.484A3.125 3.125 0 0111.5 2.125zm8.516 17.969a2.398 2.398 0 01-1.782.781H4.766a2.398 2.398 0 01-1.782-.781 2.101 2.101 0 01-.554-1.625l1-10.43a2.266 2.266 0 012.328-2.008h1.054v1.563a.781.781 0 001.563 0V6.03h6.25v1.563a.781.781 0 101.563 0V6.03h1.054a2.266 2.266 0 012.344 2.008l1 10.438a2.1 2.1 0 01-.57 1.617z"></path>
            </svg>
            <span className="px-2  text-13x text-black">
              {t("Minimum Order")} :
              {selectedArea?.minOrder && (
                <span>
                  {currentLang === "AR"
                    ? selectedArea?.minOrder + " " + t("AED")
                    : t("AED") + " " + selectedArea?.minOrder}
                </span>
              )}
            </span>
          </span>
        ) : null}
      </div>
      <div className="p-3 bg-grey-green-off rounded text-sm leading-normal text-black mt-4">
        {selectedStore?.baseLanguage?.description}
      </div>
    </div>
  );
}

export default StoreHeaderDetail;
