import queryString from "query-string";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import C from "Constants";
function useQueryParam() {
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const queryParam = `${
    queryParams?.[C.DINE_SERVICE_TYPE.STORE_ID]
      ? `${C.DINE_SERVICE_TYPE.STORE_ID}=${
          queryParams?.[C.DINE_SERVICE_TYPE.STORE_ID]
        }&`
      : ""
  }${C.DINE_SERVICE_TYPE.SERVICE_TYPE}=${C.DINE_SERVICE_TYPE.QUERY_PARAM}${
    queryParams?.[C.DINE_SERVICE_TYPE.TABLE_NUMBER]
      ? `&${C.DINE_SERVICE_TYPE.TABLE_NUMBER}=${
          queryParams?.[C.DINE_SERVICE_TYPE.TABLE_NUMBER]
        }`
      : ""
  }`;
  return queryParam;
}

export default useQueryParam;
