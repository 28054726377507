import { useCustomHistory } from "hooks";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useQueryParam from "./useQueryParam";

function useSuccesOrder() {
  const params = useParams();
  const router = useCustomHistory();
  const queryParam = useQueryParam();
  const { DomainType } = useSelector((state) => state.AppReducer);

  const successPlaceOrder = (orderNumber) => {
    const url = "/dine-in/order/order-success/";
    router(
      `${url}${orderNumber}`,
      `/${params.domainName}${url}${orderNumber}`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };
  return successPlaceOrder;
}

export default useSuccesOrder;
