import API from "apiHelpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserAction } from 'store/actions';
import { UTILS } from "utils";
import { logErrorOnSentry } from "utils/Utils";

function useUserWallet() {
  const dispatch = useDispatch();
  const [userWallet, setUserWallet] = useState(0);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  useEffect(() => {
    let isMounted = true; // Set a flag to track whether the component is mounted
    const currentUser = UTILS.getCurrentUser(brandInfo?.brand?._id);
    if (!currentUser) {
      return;
    }

    async function getUserWallet() {
      try {
        const userWallet = await API.getUserWallet({
          userId: currentUser?.user?.userId,
          brandId: currentUser?.user?.brandId,
        });

        if (isMounted) {
          // Check if the component is still mounted before updating state
          if (userWallet.success === 1) {
            setUserWallet(userWallet?.data?.balance || 0);
            dispatch(UserAction.setLifetimeSpending(userWallet?.data?.lifetimeSpending || 0));
          }
        }
      } catch (err) {
        if (isMounted) {
          logErrorOnSentry(
            `user wallet api error ${err?.message}`,
            "error",
            `user-info:${JSON.stringify(currentUser)}`
          );
        }
      }
    }

    if (
      currentUser?.token &&
      currentUser?.user?.userId &&
      currentUser?.user?.brandId
    ) {
      getUserWallet();
    }

    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
      setUserWallet(0);
    };
  }, [brandInfo]);

  return userWallet;
}

export default useUserWallet;
