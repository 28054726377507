import { DiscountAction } from "store/actions";
import { DeliveryAddressAction } from "store/actions";
import { ErrorAction } from "store/actions";
import { DeliverAreaAction } from "store/actions";
import { StoreAction } from "store/actions";
import { LoyaltyAction } from "store/actions";
import { CartAction } from "store/actions";
import reduxstore from "store/store";

//  Reset all the data related to Cart
export const resetCart = () => {
  reduxstore.dispatch(CartAction.clearCart());
  reduxstore.dispatch(DiscountAction.removeDiscountCode());
  reduxstore.dispatch(LoyaltyAction.removeLoyalty());
};

//  Reset selected Store
export const resetSelectedStore = () => {
  reduxstore.dispatch(StoreAction.setMenu(null));
  reduxstore.dispatch(StoreAction.setSelectedArea(null));
  reduxstore.dispatch(StoreAction.setSelectedStore(null));
};

export const resetSelectedStoreErrors = () => {
  reduxstore.dispatch(ErrorAction.setStoreError(null));
};

// Reset delivery Area
export const resetDeliveryArea = () => {
  reduxstore.dispatch(DeliverAreaAction.setSelectedArea(null));
};

// Reset delivery Address
export const resetDeliveryAddress = () => {
  reduxstore.dispatch(DeliveryAddressAction.resetDeliveryAddress());
};
