import React from "react";
import { t } from "i18next";
import Button from "./Button";
import { useDispatch } from "react-redux";

const ErrorModal = ({
  setOpen,
  message,
  children,
  reduxAction,
  btnTitle,
  handleClik,
}) => {
  const dispatch = useDispatch();
  const closeModal = (e) => {
    e.preventDefault();
    if (reduxAction) {
      dispatch(reduxAction);
    } else if (setOpen) {
      setOpen(false);
    } else if (handleClik) {
      handleClik();
    }
  };
  if (message === null || message === undefined) return null;
  return (
    <div className="jsutify-center w-full items-center justify-center fixced top-0 left-0 h-app-height modal-mask  overflow-y-auto flex">
      <div className="w-full h-fit mx-4 bg-white">
        <form className="py-8 px-8">
          <div className="text-21x font-bold text-center">{t("Message")}</div>
          <div className="mt-4 mb-2">
            <p className="text-16x text-center">
              {message
                ? t(message)
                : t("We're sorry, but something went wrong. Please try again")}
            </p>
            {children && children}
          </div>
          <div className="flex justify-center">
            <div className="">
              <Button
                onClick={closeModal}
                classes="rounded bg-gray-300 text-gray-800 hover:bg-brand-base-primary hover:text-brand-text-primary px-6 py-2 text-16x text-bold"
                title={btnTitle || t("Close")}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ErrorModal;
