import * as Sentry from "@sentry/browser";
export const ERROR = "ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
const doError = (error) => {
  Sentry.captureException(error);
  return { type: ERROR, error };
};

const doClearError = () => ({ type: CLEAR_ERROR });

export { doError, doClearError };

const crashReporter = (store) => (next) => (action) => {
  // we got a thunk, prep it to be handled by redux-thunk middleware
  if (typeof action === "function") {
    // wrap it in a function to try/catch the downstream invocation
    const wrapAction = (fn) => (dispatch, getState, extraArgument) => {
      try {
        fn(dispatch, getState, extraArgument);
      } catch (e) {
        dispatch(doError(e));
      }
    };
    // send wrapped function to the next middleware
    // this should be upstrem from redux-thunk middleware
    return next(wrapAction(action));
  }

  try {
    return next(action);
  } catch (e) {
    store.dispatch(doError(e));
  }
};

export default crashReporter;
