import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "components/CustomModal";
import { useDeliveryAddressContext } from "contexts/userAddressess";
import { ResetAction } from "store/actions";
import ChangeActionPopup from "./ChangeActionPopup";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { AppAction } from "store/actions";
import { useCustomHistory } from "hooks";

function LatLatNotFallInCurrentStore() {
  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { userAddressState, setAddressState } = useDeliveryAddressContext();
  const msg =
    "Unfortunately, the selected branch does not deliver to your location. Thankfully, we’ve found other nearby branches ready to serve you. Changing your location will change the branch & clear your basket. Do you want to continue ?";
  const handleClickNo = () => {
    setAddressState("latLongNotFallInCurrentStore", false);
    dispatch(
      AppAction.setTriggerStoreApiAfterAddressSaveOnAppStrt({
        status: false,
        page: null,
      })
    );
  };
  const handleClickYes = () => {
    dispatch(
      AppAction.setTriggerStoreApiAfterAddressSaveOnAppStrt({
        status: true,
        page: "store",
      })
    );
    dispatch(ResetAction.resetOnChangeCartWithStore());
    setAddressState("latLongNotFallInCurrentStore", false);
    const NEW_MAP_ADD_URL = "/address/add-new-manual-address";
    router(
      NEW_MAP_ADD_URL,
      `/${params.domainName}${NEW_MAP_ADD_URL}`,
      DomainType,
      null,
      "redirectedFrom=map-address"
    );
  };
  return (
    <CustomModal
      open={userAddressState.latLongNotFallInCurrentStore}
      setOpen={() => setAddressState("latLongNotFallInCurrentStore", false)}
      bodyclasses="flex"
      overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
    >
      <ChangeActionPopup
        handleClickYes={handleClickYes}
        handleClickNo={handleClickNo}
        title={msg}
        dynamicText={null}
      />
    </CustomModal>
  );
}

export default LatLatNotFallInCurrentStore;
