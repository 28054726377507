import React, { Fragment } from "react";
import { Switch } from "react-router-dom";
import {
  PublicRoutesArray,
  ProtectedRoutesArray,
  PreviewRoutes,
  EcommerceProtectedRoutes,
  EcommercePublicRoutes,
  StaticPublicPages,
  PaymentLinks,
  DineInPublicRoutes,
  DineInProtectedRoutes,
} from "./Routes";
import ProtectedRoute from "./middlewares/ProtectedRoute";
import DineProtectedRoute from "./middlewares/DineInProtectedRoute";

import NotFoundMiddelware from "./middlewares/404Middelware";
import { useSelector } from "react-redux";
import C from "Constants";
import RefreshModal from "components/app-update/RefreshApp";

import queryString from "query-string";

import { UTILS } from "utils";
import { Route } from "react-router-dom";
export const updatenew = () => {
  UTILS.checkISAppUpdated();
  window.location.reload();
};
const handleRefreshClick = () => window.location.reload();

function Index() {
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const queryParams = queryString.parse(window.location.search);
  const { refreshApp, isInternetConnected } = useSelector(
    (state) => state?.NotPersist
  );

  if (refreshApp) {
    return <RefreshModal {...C.refreshAppProps} onClick={updatenew} />;
  }
  if (!isInternetConnected) {
    return <RefreshModal {...C.noInternetProps} onClick={handleRefreshClick} />;
  }

  return (
    <Fragment>
      <Switch>
        {renderRoutes(PreviewRoutes, false)}
        {renderRoutes(PaymentLinks, false)}
        {renderRoutes(StaticPublicPages, false)}

        {queryParams?.["service-type"] === "dine-in" &&
        queryParams?.["store-id"] ? (
          // brandInfo?.brand?.catalogueType === C.OUTLET_BASED_CATALOGUE ? (
          <>
            {renderDineRoutes(DineInPublicRoutes, false)}
            {renderDineRoutes(DineInProtectedRoutes, true)}
          </>
        ) : (
          // ) : null
          renderRoutes(
            brandInfo?.brand?.catalogueType === C.BRAND_BASED_CATALOGUE
              ? EcommercePublicRoutes
              : PublicRoutesArray,
            false
          )
        )}

        {renderRoutes(
          brandInfo?.brand?.catalogueType === C.BRAND_BASED_CATALOGUE
            ? EcommerceProtectedRoutes
            : ProtectedRoutesArray,
          true
        )}
        <Route path="*" component={NotFoundMiddelware} />
      </Switch>
    </Fragment>
  );
}

const renderRoutes = (routesArray, isAuthProtected) => {
  return routesArray.map((route, index) => (
    <ProtectedRoute
      key={index}
      path={route.path}
      isAuthProtected={isAuthProtected}
      exact={route.exact}
      component={route.component}
    />
  ));
};

const renderDineRoutes = (routesArray, isAuthProtected) => {
  return routesArray.map((route, index) => (
    <DineProtectedRoute
      key={index}
      path={route.path}
      isAuthProtected={isAuthProtected}
      exact={route.exact}
      component={route.component}
    />
  ));
};

export default Index;
