import { errorHandler } from "apiHelpers/api/errorHandler";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

import { Loader } from "store/actions";
import { logPopupError } from "utils/Utils";
import {
  useGetUserAddressListMutation,
  useDeleteAddressMutation,
} from "store/deliveryAddress/delivery-address-service";
import { DeliveryAddressAction } from "store/actions";
import { useGetDeliveryFeeOfSingleStoreMutation } from "store/deliveryAddress/delivery-address-service";
import { SHIPPING_METHODS } from "Constants/constants";
import { UTILS } from "utils";
import { logErrorOnSentry } from "utils/Utils";
import { DeliverAreaAction } from "store/actions";
import { handleLocalStorage } from "utils/storage";
function DeliveryAddressHandling() {
  const dispatch = useDispatch();
  const [changeAddressModal, setChangeAddressModal] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [deleteAddress] = useDeleteAddressMutation();
  const [getDeliveryFeeOfSingleStore] =
    useGetDeliveryFeeOfSingleStoreMutation();
  // Redux States
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const { shippingMethod } = useSelector((state) => state.AppReducer);
  const { selectedStore } = useSelector((state) => state.store);
  const [getUserAddressList] = useGetUserAddressListMutation();
  const { userdeliveryAdress } = useSelector(
    (state) => state.DeliveryAddressReducer
  );

  // Get saved Address List
  async function getAddresses() {
    try {
      if (!shippingMethod) {
        logPopupError(
          t(
            "There is no delivery method available in store, please contact the business"
          ),
          true
        );
        return;
      }
      dispatch(Loader.togggleCheckoutLoader(true));
      const data = await getUserAddressList({
        deliveryMethod: shippingMethod,
      });
      if (data?.data?.success === 1 && data.data?.data?.userAddresses) {
        setAddressList(data.data?.data?.userAddresses);
        if (
          !userdeliveryAdress &&
          data.data?.data?.userAddresses?.length >= 1
        ) {
          setDeliveryAddress(data.data?.data?.userAddresses[0]);
        }
      } else {
        dispatch(DeliveryAddressAction.setDeliveryAddress(null));
        logPopupError(errorHandler(data?.error), true);
      }
    } catch (error) {
      dispatch(DeliveryAddressAction.setDeliveryAddress(null));
      logPopupError(errorHandler(error.error), true);
    }
    dispatch(Loader.togggleCheckoutLoader(false));
  }

  // Delete saved Address
  const deleteSlectedAddress = async (userAddressId) => {
    try {
      if (!userAddressId) return;
      dispatch(Loader.togggleCheckoutLoader(true));
      if (userAddressId === userdeliveryAdress?._id) {
        dispatch(DeliveryAddressAction.setDeliveryAddress(null));
        dispatch(DeliverAreaAction.setSelectedCountry(null));
        dispatch(DeliverAreaAction.setSelectedCity(null));
        dispatch(DeliverAreaAction.setSelectedArea(null));
      }
      const data = await deleteAddress({
        userAddressId,
      });
      if (data?.data?.success === 1) {
        await getAddresses();
      } else logPopupError(errorHandler(data?.error?.data), true);
    } catch (error) {
      logPopupError(errorHandler(error?.error), true);
    } finally {
      dispatch(Loader.togggleCheckoutLoader(false));
    }
  };

  // set delivery address from saved address list
  const setDeliveryAddress = async (address) => {
    try {
      dispatch(Loader.togggleCheckoutLoader(true));
      dispatch(Loader.enableLoader());
      let MEssage = t(
        "Unfortunately, the store does not deliver to your location at this time"
      );
      if (address?._id === userdeliveryAdress?._id) {
        setChangeAddressModal(false);
        return;
      }
      const isValid = UTILS.getSavedAddressAreaINfo(shippingMethod, address);
      if (!isValid.isValid) {
        logPopupError(isValid.msg, true);
        return;
      }
      const obj = {
        ...isValid.obj,
        brandId: brandInfo?.brand?._id,
        storeId: selectedStore?._id,
        deliveryMethodType: shippingMethod,
      };
      if (!UTILS.ValidateParams(obj)) return;
      const { data } = await getDeliveryFeeOfSingleStore(obj);
      if (data?.success === 1) {
        if (shippingMethod === SHIPPING_METHODS.COUNTRY_BASED) {
          const country =
            data?.data?.countryMethod?.countryBaseRes?.countryDeliveryData?.[0];
          if (!country) {
            logErrorOnSentry(
              "shipping method is country based but selected country is null while calulting fee from saved address api=GET_SINGLE_STORE_DELIVERY_AREA"
            );
            logPopupError(MEssage, true);
            return;
          } else dispatch(DeliverAreaAction.setSelectedCountry(country));
        }
        if (shippingMethod === SHIPPING_METHODS.CITY_BASED) {
          const city =
            data?.data.cityMethod?.cityBaseRes?.cityDeliveryData?.[0];
          if (!city) {
            logPopupError(MEssage, true);
            logErrorOnSentry(
              "shipping method is city based but selected city is null while calulting fee from saved address api=GET_SINGLE_STORE_DELIVERY_AREA"
            );
            return;
          } else dispatch(DeliverAreaAction.setSelectedCity(city));
        }
        if (shippingMethod === SHIPPING_METHODS.AREA_BASED) {
          const area = data?.data.areaMethod?.areaBaseRes;
          if (!area) {
            logPopupError(MEssage, true);
            logErrorOnSentry(
              "shipping method is area based but selected area is null while calulting fee from saved address api=GET_SINGLE_STORE_DELIVERY_AREA"
            );
            return;
          } else dispatch(DeliverAreaAction.setSelectedArea(area));
        }
        dispatch(DeliveryAddressAction.setDeliveryAddress(address));
        handleLocalStorage.setItem("deliveryAddress", address);
        setChangeAddressModal(false);
      }
    } catch (error) {
      logErrorOnSentry(error, "error");
      logPopupError(errorHandler(error?.error), true);
    } finally {
      dispatch(Loader.disableLoader(false));
      dispatch(Loader.togggleCheckoutLoader(false));
    }
  };
  return {
    addressList,
    getAddresses,
    deleteSlectedAddress,
    setDeliveryAddress,
    changeAddressModal,
    setChangeAddressModal,
  };
}

export default DeliveryAddressHandling;
