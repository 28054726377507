import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import ButtonSpinner from "components/ButtonSpinner";
import React, { useState, useRef } from "react";
import { t } from "i18next";
import { ErrorAction } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSendVerificationCodeMutation } from "store/auth/register/registerService";
import C from "Constants";
import { UTILS } from "utils";
const LoginForm = ({ gotoVerification }) => {
  const dispatch = useDispatch();
  const phoneNumberRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [sendVerificationCode] = useSendVerificationCodeMutation();
  const { loading } = useSelector((state) => state.register);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCountryObj, setPhoneCountryObj] = useState(null);
  const { brandInfo: brand } = useSelector(
    (state) => state?.brandStorageReducer
  );

  const setNumber = (userInput) => {
    let result = userInput?.replace(/ /g, "")?.trim();
    return result;
  };
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (disabled) return;
    if (isNaN(phoneNumber)) {
      return dispatch(
        ErrorAction.setRegisterError(
          t("Please provide a working mobile number.")
        )
      );
    }
    const countryObj = phoneCountryObj;
    const phoneNumberZeroLess = phoneNumber.replace(/^0+/, "");
    const obj = {
      brandId: brand.brand._id,
      phoneNumber: phoneNumberZeroLess,
      countryObj: countryObj,
      userType: C.CUSTOMER,
    };
    const { data } = await sendVerificationCode(obj);
    if (data?.success !== 1) return;
    const state = { phoneNumber, countryObj };
    gotoVerification(state);
  };

  const onChange = (isValid, phoneNumber, countryData, internationalNumber) => {
    let phone = UTILS.NumberConversion(phoneNumber);
    processPhoneNumberInput(isValid, phone, countryData, internationalNumber);
  };
  const onCountryChange = (
    phoneNumber,
    countryData,
    internationalNumber,
    isValid
  ) => {
    processPhoneNumberInput(
      isValid,
      phoneNumber,
      countryData,
      internationalNumber
    );
  };
  const processPhoneNumberInput = (
    isValid,
    phoneNumber,
    countryData,
    internationalNumber
  ) => {
    if (isValid && isValidMobileNumber(internationalNumber)) {
      checkMobileNumberHasLeadingZero(phoneNumber);
      setDisabled(false);
    } else {
      setDisabled(true);
      setPhoneNumber(setNumber(phoneNumber));
    }
    setPhoneCountryObj(countryData);
  };
  const isValidMobileNumber = (mobileNumber) => {
    const numberType = window.intlTelInputUtils.getNumberType(mobileNumber);
    return (
      numberType === window.intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE ||
      numberType === window.intlTelInputUtils.numberType.MOBILE
    );
  };
  const checkMobileNumberHasLeadingZero = (phoneNumber) => {
    if (!phoneNumber.startsWith("0")) {
      phoneNumber = "0" + phoneNumber;
      setPhoneNumber(setNumber(phoneNumber));
      const input = phoneNumberRef.current?.tel;
      setTimeout(() => {
        input.setSelectionRange(phoneNumber.length, phoneNumber.length);
      }, 0);
    } else {
      setPhoneNumber(setNumber(phoneNumber));
    }
  };

  return (
    <form onSubmit={handleVerifyCode}>
      <div className="mt-6" dir="">
        <label className="text-15x">{t("WhatsApp number")}</label>
        <br />
        <div dir="ltr">
          <IntlTelInput
            containerClassName="intl-tel-input intl-input-custom"
            inputClassName="intl-input-box text-16x"
            defaultCountry="ae"
            nationalMode={true}
            separateDialCode={true}
            onPhoneNumberChange={onChange}
            preferredCountries={[]}
            numberType="MOBILE"
            value={phoneNumber?.trim()}
            ref={phoneNumberRef}
            onSelectFlag={onCountryChange}
            placeholder={t("Enter WhatsApp number")}
          />
        </div>
      </div>
      <div className="">
        <button
          type="submit"
          disabled={disabled || loading}
          className={`mt-6 ${
            disabled
              ? "bg-gray-300"
              : "bg-brand-base-primary text-brand-text-primary"
          } w-full py-3 rounded px-6 text-17x font-medium cursor-pointer`}
        >
          {loading && (
            <ButtonSpinner
              height="30px"
              width="30px"
              color="text-white mx-1 inline-block"
            />
          )}
          {t("Send Code")}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
