import React from "react";
import { Link, useParams } from "react-router-dom";
import * as ROUTE_CONSTANTS from "routes/constants";

function CheckoutHeader() {
  const params = useParams();
  let url = ROUTE_CONSTANTS.OUTLET_BASKET[1];
  return (
    <div className="bg-white flex items-center justify-center px-10 h-14 text-17x border-b border-gray-200 font-semibold text-black">
      <Link
        to={`/${params.domainName}${url}`}
        className="absolute px-2 top-0 left-0 h-14 flex items-center"
      >
        <div className="rounded-full flex items-center  justify-center bg-circle p-2">
          <svg viewBox="0 0 24 24" className="h-4 w-4 transform rotate-90">
            <path
              fill="currentColor"
              d="M4.88398 7.11612L3.11621 8.88389L12.0001 17.7678L20.884 8.88389L19.1162 7.11612L12.0001 14.2322L4.88398 7.11612Z"
            ></path>
          </svg>
        </div>
      </Link>
      Your order
    </div>
  );
}

export default CheckoutHeader;
