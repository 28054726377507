import { t } from "i18next";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function RecommendedITemList({ itemidList, setOpen, openStoreItem }) {
  const params = useParams();
  const { storeMenu } = useSelector((state) => state.store);
  const getItemsByIds = useCallback(
    (ids) => {
      if (!storeMenu || !itemidList) {
        return null;
      }

      const uniqueItemIds = new Set();
      const filteredItems = [];

      storeMenu?.categories?.forEach((category) => {
        const categoryItems = category?.items?.filter((item) =>
          ids?.includes(item?.itemId?._id)
        );

        categoryItems?.forEach((item) => {
          if (!uniqueItemIds.has(item?.itemId?._id)) {
            filteredItems.push({
              ...item,
              categoryId: category?.categoryId,
            });
            uniqueItemIds.add(item?.itemId?._id);
          }
        });
      });

      return filteredItems?.length > 0 ? filteredItems : null;
    },
    [storeMenu, itemidList]
  );
  const openITemDetailPage = (item, category) => {
    openStoreItem(item, category, setOpen, params);
  };

  const filteredItems = getItemsByIds(itemidList);

  if (!filteredItems) return null; // Return null if there are no filtered items

  return (
    <div className="bg-gray-k100 p-6 rounded-lg shadow-md">
      <h2 className="font-nunito text-1.5 font-extrabold py-3">
        {t("Recommended Items")}
      </h2>
      <div className="flex flex-nowrap gap-2 overflow-scroll pb-4">
        {filteredItems?.map((item) => (
          <div
            onClick={() => openITemDetailPage(item, item?.categoryId)}
            key={item.itemId._id}
            className="bg-white border border-gray-200  shadow-lg cursor-pointer"
          >
            <div className="iiii text-center">
              <img
                src={
                  item?.itemId?.itemImageURLs?.length > 0
                    ? item.itemId.itemImageURLs[0]
                    : ""
                }
                alt={item?.itemId?.baseLanguage?.title}
                className=" object-cover p-s2"
              />
            </div>
            <div className="p-2 w-32">
              <p className="text-15x  h-12 font-normal text-outer-space truncate-line-2 overflow-wrap-anywhere">
                {item?.itemId?.baseLanguage?.title}
              </p>

              <p className="text-16x mt-2 text-gunsmoke">
                {item?.itemId?.price} {t("AED")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default RecommendedITemList;
