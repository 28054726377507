import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedArea: null,
  selectedCountry: null,
  selectedCity: null,
};

const selectedArea = createSlice({
  name: "selectedArea",
  initialState,
  reducers: {
    setSelectedArea: (state, action) => {
      state.selectedArea = action.payload;
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },
  },
});

export const { setSelectedCity, setSelectedCountry, setSelectedArea } =
  selectedArea.actions;
export default selectedArea.reducer;
