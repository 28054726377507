import { usePushBack } from "hooks";
import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useHistory, useParams } from "react-router-dom";

function Zoom() {
  const history = useHistory();
  const params = useParams();
  const [images, setImages] = useState([]);
  const [photoIndex, setphotoIndex] = useState(0);

  const gotoNext = () => {
    setphotoIndex((prev) => (prev + images.length + 1) % images.length);
  };
  const gotoPrev = () => {
    setphotoIndex((prev) => (prev + images.length - 1) % images.length);
  };
  const closeZoom = () => {
    setphotoIndex(0);
    history.goBack();
  };
  usePushBack(history?.location?.state?.urls, params.domainName);

  useEffect(() => {
    if (history?.location?.state?.urls?.length > 0) {
      setImages(history.location.state.urls);
      history?.location?.state?.index &&
        setphotoIndex([history?.location?.state?.index]);
    } else {
      setImages([]);
      setphotoIndex([history?.location?.state?.urls[0]]);
    }
  }, [history?.location?.state?.urls]);

  return (
    <div>
      {images?.length >= 1 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => closeZoom()}
          onMovePrevRequest={() => gotoPrev()}
          onMoveNextRequest={() => gotoNext()}
          imageLoadErrorMessage={"Image not found"}
        />
      )}
    </div>
  );
}

export default Zoom;
