import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loyaltyApplied: false,
  loyaltyAmount: 0,
  reedemAmount: 0
};

const loyaltySlice = createSlice({
  name: "loyalty",
  initialState,
  reducers: {
    applyLoyalty: (state, action) => {
      state.loyaltyApplied = true;
    },
    removeLoyalty: (state) => {
      state.loyaltyApplied = false;
    },
    updateloyaltyAmount: (state, action) => {
      state.loyaltyAmount = action.payload
    }
  },
});

export const {
  updateloyaltyAmount,
  applyLoyalty,
  removeLoyalty
} = loyaltySlice.actions;
export default loyaltySlice.reducer;
