import { SHIPPING_METHODS } from "Constants/constants";
import i18n from "i18n/i18n";
import {
  calculateLoyaltyPoint,
  getLanguage,
  ifNullOrUndefined,
  isValidValue,
  logErrorOnSentry,
} from "./Utils";
import reduxstore from "store/store";
import C from "Constants";
import { UTILS } from "utils";
import moment from "moment";
import { CartAction } from "store/actions";

export const getRatingLabel = (rating) => {
  switch (rating) {
    case 1:
      return i18n.t("Bad");
    case 2:
    case 3:
    case 4:
      return i18n.t("Average");
    case 5:
      return i18n.t("Excellent");
    default:
      return "";
  }
};

export const getDeliveryAreaFeeForBrandBased = (
  shippingMethod,
  selectedCountry,
  selectedArea,
  selectedCity
) => {
  let charges = { deliveryFee: 0, minOrder: 0 };
  if (shippingMethod === SHIPPING_METHODS.COUNTRY_BASED && selectedCountry) {
    charges.deliveryFee = selectedCountry?.deliveryFee || 0;
    charges.minOrder = selectedCountry?.minOrder || 0;
  } else if (shippingMethod === SHIPPING_METHODS.AREA_BASED && selectedArea) {
    charges.deliveryFee = selectedArea?.deliveryFee || 0;
    charges.minOrder = selectedArea?.minOrder || 0;
  } else if (shippingMethod === SHIPPING_METHODS.CITY_BASED && selectedCity) {
    charges.deliveryFee = selectedCity.deliveryFee || 0;
    charges.minOrder = selectedCity?.minOrder || 0;
  }
  return charges;
};

/***----------------------------Brand Based---------------------------------- */
// /*creating and validating dumb order object for brand-buisness*/
export const validateBrandOrderObject = () => {
  let errors = {};
  let isValid = true;
  const { brandInfo: brand, loyaltyPolicy } =
    reduxstore.getState().brandStorageReducer;
  const { orderType, shippingMethod } = reduxstore.getState().AppReducer;
  const selectedPaymentType = reduxstore.getState()?.PaymentMethodReducer;
  const storeSlice = reduxstore.getState().store;
  const cartSlice = reduxstore.getState().cart;
  const discountSlice = reduxstore.getState().Discounts;
  const loyaltySlice = reduxstore.getState().Loyalty;
  const cart = cartSlice.cart;
  const { userdeliveryAdress } = reduxstore.getState().DeliveryAddressReducer;
  const { selectedCountry, selectedArea, selectedCity } =
    reduxstore.getState().DeliveryAreaReducer;

  const selectedStore = storeSlice?.selectedStore;
  const orderGrossAmount = cartSlice?.orderGrossAmount;

  const cartTotal = cartSlice.cartTotal;
  const pickupVehicleInfo = cartSlice?.pickupVehicleInfo;
  const orderSplRequest = cartSlice?.orderSplReq;
  const itemsGrossAmount = cartSlice.itemsGrossAmount;
  const selectedPayment = storeSlice?.selectedPayment;
  const currentUser = UTILS.getCurrentUser(brand?._id);
  const isDiscountApplied = discountSlice?.isDiscountApplied;
  const discountAmount = cartSlice?.discountAmount;
  const discountInfo = discountSlice.discountInfo;
  const loyaltyApplied = loyaltySlice.loyaltyApplied;
  const loyaltyRedeemAmount = loyaltyApplied ? loyaltySlice?.loyaltyAmount : 0;
  const checkNullOrUndefined = (value, key, msg, level) => {
    if (value === null || value === undefined) {
      logErrorOnSentry(`${key} is null or undefined: ${value}`, level);
      isValid = false;
      logErrorOnSentry(
        `${key} is null or undefined: ${value}->userMessage:${
          msg ? msg : "we'are sorry but something went wrong"
        }`,
        level
      );
      errors[key] = i18n.t(msg || "Something was incorrect.");
      return false;
    }
    return true;
  };

  //shipping method country based
  const validateCountryAttributes = () => {
    let isValidCountry = true;
    isValidCountry = checkNullOrUndefined(
      selectedCountry?.deliveryFee,
      "selectedCountry?.deliveryFee brand-based",
      "Please choose an address.",
      "info"
    );

    isValidCountry = checkNullOrUndefined(
      selectedCountry?.deliveryTime,
      " selectedCountry?.deliveryTime brand-based",
      "Please choose an address.",
      "info"
    );
    isValidCountry = checkNullOrUndefined(
      selectedCountry?.minOrder,
      " selectedCountry?.minOrder brand-based",
      "Please choose an address.",
      "info"
    );

    return isValidCountry;
  };
  const validateAreaAttributes = () => {
    let isValidArea = true;
    isValidArea = checkNullOrUndefined(
      selectedArea?.deliveryFee,
      "selectedStore?.deliveryFee brand-based",
      "Please choose an address.",
      "info"
    );
    isValidArea = checkNullOrUndefined(
      selectedArea?.deliveryTime,
      "selectedArea?.deliveryTime brand-based",
      "Please choose an address.",
      "info"
    );
    isValidArea = checkNullOrUndefined(
      selectedArea?.minOrder,
      "selectedArea?.minOrder brand-based",
      "Please choose an address.",
      "info"
    );

    return isValidArea;
  };
  const validateCityAttributes = () => {
    let isValidCity = true;
    isValidCity = checkNullOrUndefined(
      selectedCity?.deliveryFee,
      "selectedCity?.deliveryFee brand-based",
      "Please choose an address.",
      "info"
    );
    isValidCity = checkNullOrUndefined(
      selectedCity?.deliveryTime,
      "selectedCity?.deliveryTime brand-based",
      "Please choose an address.",
      "info"
    );
    isValidCity = checkNullOrUndefined(
      selectedCity?.minOrder,
      "selectedCity?.minOrder brand-based",
      "Please choose an address.",
      "info"
    );
    return isValidCity;
  };

  const pickupTiming = {
    asap: true,
    time: null,
  };

  let userSelectedArea = {};
  let userSelectedAreaFee = {};
  if (orderType.serviceType === C.SERVICE_TYPE.DELIVERY) {
    if (shippingMethod) {
      if (shippingMethod === C.SHIPPING_METHODS.COUNTRY_BASED) {
        let isValidCountry = validateCountryAttributes();
        if (isValidCountry) {
          userSelectedAreaFee = {
            deliveryFee: selectedCountry?.deliveryFee,
            deliveryTime: selectedCountry?.deliveryTime,
          };
        }
      }
      if (shippingMethod === C.SHIPPING_METHODS.AREA_BASED) {
        const isValidArea = validateAreaAttributes();
        if (isValidArea) {
          userSelectedAreaFee = {
            deliveryFee: selectedArea?.deliveryFee,
            deliveryTime: selectedArea?.deliveryTime,
          };
        }
      }
      if (shippingMethod === C.SHIPPING_METHODS.CITY_BASED) {
        const isValidcity = validateCityAttributes();
        if (isValidcity) {
          userSelectedAreaFee = {
            deliveryFee: selectedCity?.deliveryFee,
            deliveryTime: selectedCity?.deliveryTime,
          };
        }
      }
    }
    checkNullOrUndefined(
      userdeliveryAdress?.city,
      "selectedAddress?.city map city is null or undefined",
      "Please choose an address.",
      "info"
    );
    checkNullOrUndefined(
      userdeliveryAdress?.addressType,
      "addressType -> brand-based",
      "Please choose an address.",
      "info"
    );
    checkNullOrUndefined(
      userdeliveryAdress?.address,
      "address -> brand-based",
      "Please choose an address.",
      "info"
    );
    checkNullOrUndefined(
      userdeliveryAdress?.street,
      "street -> map street is null or undefined",
      "Please choose an address.",
      "info"
    );

    checkNullOrUndefined(
      shippingMethod,
      "deliveryMethod",
      "There is no delivery method available in store, please contact the business",
      "error"
    );
  }
  if (orderType?.serviceType === C.SERVICE_TYPE.PICKUP) {
    if (
      selectedStore?.vehicleInfoForPickup?.isActive === true &&
      selectedStore?.vehicleInfoForPickup?.isRequired === true
    ) {
      if (
        !isValidValue(cartSlice?.pickupVehicleInfo) ||
        cartSlice?.pickupVehicleInfo?.trim()?.length < 2
      ) {
        isValid = false;
        errors.pickupVehicleInfo = i18n.t(
          "Please enter your vehicle information"
        );
        try {
          const el = document.getElementById("pickupVehicleInfo");
          el?.scrollIntoView({
            block: "end",
          });
          return { isValid, errors };
        } catch (error) {}
        logErrorOnSentry("Please enter your vehicle information", "info");
      }
    }
  }

  checkNullOrUndefined(selectedStore?._id, "storeId", null, "error");
  checkNullOrUndefined(
    selectedStore?.taxRate,
    "selectedStore?.taxRate",
    null,
    "error"
  );
  checkNullOrUndefined(brand?.brand?._id, "brand?.brand?._id", null, "error");
  // checkNullOrUndefined(
  //   selectedPayment,
  //   "selectedPayment",
  //   "Please choose a payment method",
  //   "info"
  // );

  // checkNullOrUndefined(
  //   selectedPaymentType?.selectedPaymentType,
  //   "selectedPaymentType",
  //   "Please choose a payment method",
  //   "info"
  // );

  if (
    ifNullOrUndefined(selectedPayment) ||
    ifNullOrUndefined(selectedPaymentType?.selectedPaymentType)
  ) {
    isValid = false;
    logErrorOnSentry("Please choose a payment method", "info");
    errors.selectedPayment = i18n.t("Please choose a payment method");
    try {
      const el = document.getElementById("payment-methods");
      el?.scrollIntoView({
        block: "start",
      });
    } catch (error) {
    } finally {
      return { isValid, errors };
    }
  }

  checkNullOrUndefined(
    currentUser?.user?.phoneNumber,
    "currentUser?.user?.phoneNumber",
    null,
    "error"
  );

  checkNullOrUndefined(
    selectedStore?.baseLanguage?.name,
    "selectedStore?.baseLanguage?.name",
    null,
    "error"
  );

  checkNullOrUndefined(
    selectedStore?.baseLanguage?.description,
    "selectedStore?.baseLanguage?.description",
    null,
    "error"
  );
  checkNullOrUndefined(
    selectedStore?.mapAddress,
    "selectedStore?.mapAddress->selected store issue",
    "Add the map address, please.",
    "error"
  );

  checkNullOrUndefined(
    selectedStore?.manualAddress,
    "selectedStore?.manualAddress",
    null,
    "error"
  );

  checkNullOrUndefined(
    selectedStore?.storeLocation?.coordinates,
    "selectedStore?.storeLocation?.coordinates",
    null,
    "error"
  );

  checkNullOrUndefined(
    currentUser?.user?.userId,
    "currentUser?.user?.userId",
    null,
    "error"
  );

  checkNullOrUndefined(orderGrossAmount, "totalOrderPrice", null, "error");
  checkNullOrUndefined(itemsGrossAmount, "itemsTotalPrice", null, "error");

  if (isDiscountApplied && ifNullOrUndefined(discountInfo)) {
    isValid = false;
    errors.isDiscountApplied = i18n.t("Please enter a working promo code.");
  }
  if (isDiscountApplied && ifNullOrUndefined(discountAmount)) {
    isValid = false;
    errors.isDiscountApplied = i18n.t("Please enter a working promo code.");
  }

  const loyaltyCashBackAmount = calculateLoyaltyPoint(
    loyaltyPolicy,
    orderGrossAmount
  );
  const { orderTiming } = reduxstore.getState()?.orderTimingReducer;
  if (orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER) {
    if (!orderTiming?.preOrderSlot?.day || !orderTiming?.preOrderSlot?.time) {
      isValid = false;
      logErrorOnSentry("Please choose a time for pre order ", "info");
      errors.preOrder = i18n.t("Please choose a time for pre order");
      return { isValid, errors };
    }
  }
  const obj = {
    loyaltyPolicy,
    brand,
    selectedArea,
    selectedStore,
    cart,
    selectedPayment,
    currentUser,
    userdeliveryAdress,
    discountInfo,
    cartTotal,
    isDiscountApplied,
    loyaltyApplied,
    totalOrderPrice: orderGrossAmount,
    itemsTotalPrice: itemsGrossAmount,
    discountAmount,
    loyaltyRedeemAmount,
    loyaltyCashBackAmount,
    shippingMethod,
    userSelectedArea,
    userSelectedAreaFee,
    orderSplRequest,
    orderType,
    pickupTiming,
    pickupVehicleInfo,
    orderTiming,
  };
  return { isValid, errors, obj };
};
export const getBrandBasedOrderObject = (orderObj) => {
  const { lifetimeSpending = 0 } = reduxstore.getState()?.user || {};
  const { selectedCard } = reduxstore.getState().PaymentCardsReducer;

  const obj = {
    lifetimeSpending: lifetimeSpending,
    orderingPlatform: sessionStorage.getItem("platform") ?? "web",
    storeId: orderObj.selectedStore._id,
    brandId: orderObj.brand?.brand._id,
    isStoredCard: selectedCard?.isStoredCard || false,
    userInfo: {
      brandId: orderObj.brand?.brand._id,
      phoneNumber: orderObj.currentUser.user.phoneNumber,
      ...(orderObj?.currentUser?.user?.name && {
        name: orderObj?.currentUser?.user?.name,
      }),
      ...(orderObj?.currentUser?.user?.email && {
        email: orderObj?.currentUser?.user?.email,
      }),

      name: "",
    },
    ...(orderObj?.orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER && {
      isPreOrder: true,
      preOrderSlot: orderObj?.orderTiming?.preOrderSlot?.time,
      preOrderDay: orderObj?.orderTiming?.preOrderSlot?.day,
    }),

    storeInfo: {
      baseLanguage: {
        name: orderObj.selectedStore.baseLanguage.name,
        description: orderObj.selectedStore.baseLanguage.description,
      },
      translations: {
        en: {
          name: orderObj.selectedStore.baseLanguage.name,
        },
        ar: {
          name: orderObj.selectedStore.baseLanguage.name,
        },
      },

      mapAddress: orderObj.selectedStore.mapAddress,
      mobileNumber: orderObj.selectedStore.mobileNumber,
      manualAddress: orderObj.selectedStore.manualAddress,
      orderPickupLoc: {
        type: "Point",
        coordinates: orderObj.selectedStore.storeLocation.coordinates,
      },
      city: orderObj.selectedStore.city,
      country: C.DEFAULT_COUNTRY,
      ...(orderObj?.selectedStore?.preOrderSettingsForDelivery && {
        preOrderSettingsForDelivery:
          orderObj?.selectedStore?.preOrderSettingsForDelivery,
      }),
      ...(orderObj?.selectedStore?.preOrderSettingsForPickup && {
        preOrderSettingsForPickup:
          orderObj?.selectedStore?.preOrderSettingsForPickup,
      }),
    },
    selectedPaymentMethod: {
      AR: {
        name: orderObj.selectedPayment.AR.name,
      },
      EN: {
        name: orderObj.selectedPayment.EN.name,
      },
    },
    activeIntegrations: orderObj.brand?.activeIntegrations,
    orderCurrency: {
      currencyId: "6139b3d2b5264d526a8fae24",
      EN: { name: "AED" },
    },
    orderType: {
      type: orderObj.orderType.serviceType,
      EN: {
        name: orderObj.orderType.EN.name,
      },
      AR: {
        name: orderObj.orderType.AR.name,
      },
    },
    itemsTotalPrice: +orderObj.itemsTotalPrice?.toFixed(2),
    totalOrderPrice: +orderObj.totalOrderPrice?.toFixed(2),
    orderLanguage: getLanguage(),
    taxRate: orderObj.selectedStore.taxRate,
    orderLevelSpecialRequest: orderObj.orderSplRequest,
    orderedMenu: orderObj.cart,
    paymentType: orderObj.selectedPayment.paymentType,
    ...(orderObj?.isDiscountApplied === true && {
      discountApplied: {
        discountAmountAvailedByCustomer: +orderObj.discountAmount,
        discountValue: orderObj.discountInfo?.discountAmount,
        discountId: orderObj.discountInfo?._id,
        discountCode: orderObj.discountInfo?.discountCode,
        discountType: orderObj.discountInfo?.discountType,
      },
    }),
    ...(orderObj?.selectedPayment?.paymentType === C.CREDIT_CARD && {
      orderCheckoutOnlinePaymentMetadata: orderObj.transactionInfo,
    }),
    ...(orderObj?.loyaltyPolicy?.isLoyaltyActive === true &&
      (orderObj?.loyaltyCashBackAmount > 0 ||
        orderObj?.loyaltyRedeemAmount > 0) && {
        loyaltyApplied: {
          redeemAmount: orderObj.loyaltyRedeemAmount,
          cashBackAmount: orderObj.loyaltyCashBackAmount,
        },
      }),
  };

  if (orderObj?.orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
    obj.deliveryFee = orderObj.userSelectedAreaFee.deliveryFee;
    obj.deliveryTime = orderObj.userSelectedAreaFee.deliveryTime;
    obj.deliveryAddress = {
      userId: orderObj.currentUser.user.userId,
      address: orderObj.userdeliveryAdress?.address,
      addressType: orderObj.userdeliveryAdress.addressType,
      street: orderObj.userdeliveryAdress.street,
      orderDeliveryLoc: {
        type: "Point",
        coordinates: [
          orderObj.userdeliveryAdress.coordinates.lat,
          orderObj.userdeliveryAdress.coordinates.lng,
        ],
      },
      city: orderObj.userdeliveryAdress.city?.toLowerCase(),
      country: orderObj.userdeliveryAdress.country?.toLowerCase(),
      area: orderObj.userdeliveryAdress.area?.toLowerCase(),

      building: orderObj.userdeliveryAdress.building,
      floor: orderObj.userdeliveryAdress.floor,
      appartmentNo: orderObj.userdeliveryAdress.apartmentNo,
      selectedDeliveryMethodInfo: {
        methodName: orderObj?.shippingMethod,
      },
    };
  }
  if (orderObj?.orderType?.serviceType === C.SERVICE_TYPE.PICKUP) {
    obj.pickupTiming = orderObj.pickupTiming;
    obj.pickupVehicleInfo = orderObj?.pickupVehicleInfo;
  }
  return obj;
};

export const FormatPreOrderingTime = (timeRange) => {
  if (!timeRange) return "";
  const [startTime, endTime] = timeRange.split("-").map((time) => time.trim());
  const formattedStartTime = moment(startTime, "HH:mm").format("h:mm A");
  const formattedEndTime = moment(endTime, "HH:mm").format("h:mm A");
  const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;
  return formattedTimeRange;
};
