import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";

import useCustomHistoryReplace from "hooks/useCustomHistoryReplace";
import { isStoreIsOpen, getOrderTimingType } from "utils/storeUtils";
import { isItemOutOfStock, errorNotification } from "utils/Utils";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { useMapContext } from "contexts/googleMap";
import { useCustomHistory } from "hooks";
import * as Ac from "store/actions";
import C from "Constants";
import API from "apiHelpers";
import { logErrorOnSentry } from "utils/Utils";
import { isActiveAnyPaymentGateway } from "utils/Utils";

function useGetStoreInfo() {
  const dispatch = useDispatch();
  const history = useCustomHistory();
  const { domainName } = useParams();
  const { reset } = useMapContext();
  const historyreplace = useCustomHistoryReplace();

  let url = "/store/store-detail";
  const { selectedPayment } = useSelector((state) => state.store);
  const { orderType, DomainType, shippingMethod } = useSelector(
    (state) => state.AppReducer
  );
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);
  const { app_loaded_through, selectedItem } = useSelector(
    (state) => state.item
  );

  // if order type is delivery
  const isValidStoreArea = (response, storeArea, isStoreOpened) => {
    if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
      if (storeArea && response?.data?.mappedAreaType === C.DISTANCE) {
        isStoreOpened && dispatch(Ac.StoreAction.setSelectedArea(storeArea));
        return true;
      } else if (response?.data?.storeArea) {
        isStoreOpened &&
          dispatch(Ac.StoreAction.setSelectedArea(response?.data?.storeArea));
        return true;
      } else if (
        (!response?.data?.storeArea &&
          response?.mappedAreaType !== C.DISTANCE) ||
        (!storeArea && response?.data?.mappedAreaType === C.DISTANCE)
      ) {
        logErrorOnSentry(
          `Store Area is not available || store area not passed latLngArea=${storeArea} 
          areaType=${response?.data?.mappedAreaType === C.DISTANCE} storeArea=${
            response?.data?.storeArea
          }`,
          "info",
          response?.data?.storeArea
        );
        errorNotification(
          t("The store is currently closed, please try again soon")
        );
        history(`/`, `/${domainName}`, DomainType);
        return false;
      }
    }
    return true;
  };

  //set default payment method
  function setDefaultPaymentMethod(data, selectedPayment, orderType) {
    if (orderType === "DELIVERY") {
      if (
        data?.data?.storeInfo[0] &&
        data?.data?.storeInfo[0]?.paymentOptions?.length >= 1 &&
        !selectedPayment
      ) {
        const onlinePayment = data?.data?.storeInfo[0]?.paymentOptions?.find(
          (item) => item?.paymentType === C.CREDIT_CARD
        );
        return onlinePayment;
      }
    }
    if (orderType === "PICKUP") {
      if (
        data?.data?.storeInfo[0] &&
        data?.data?.storeInfo[0]?.pickupPaymentOptions?.length >= 1 &&
        !selectedPayment
      ) {
        const onlinePayment =
          data?.data?.storeInfo[0]?.pickupPaymentOptions?.find(
            (item) => item?.paymentType === C.CREDIT_CARD
          );
        return onlinePayment;
      }
    }
  }

  function setDefaultCategory(response) {
    dispatch(Ac.ErrorAction.setStoreError(null));
    dispatch(Ac.StoreAction.setMenu(response?.data?.storeMenu));
    if (response?.data?.storeMenu?.categories?.length >= 1) {
      dispatch(
        Ac.StoreAction.setSelectedCategory(
          response?.data?.storeMenu?.categories?.categoryId?._id
        )
      );
    }
  }

  function handleErrorResponse(response) {
    errorNotification(errorHandler(response?.message));
    dispatch(Ac.ErrorAction.setStoreError(errorHandler(response?.message)));
    dispatch(Ac.NotPersistAction.setModalClose());
    dispatch(Ac.NotPersistAction.togglePickupStoreModal(false));
    history(url, `/${domainName}${url}`, DomainType);
  }

  // main function
  async function getStoreInfoWithMenu(storeId, areaID, storeArea) {
    handleStart(dispatch);
    try {
      const data = {
        brandId: brandInfo?.brand?._id,
        storeId,
        ...(areaID && { areaID }),
        deliveryMethod: shippingMethod,
      };
      dispatch(
        Ac.StoreAction.setMenuParams({
          storeId,
          areaID,
          storeArea,
        })
      );

      const response =
        orderType?.serviceType === C.SERVICE_TYPE.DELIVERY
          ? await API.getDeliveryStoreMenu(data)
          : await API.getStoreMenuForPickup(data);

      if (ifSuccessResponse(response)) {
        const isStoreOpened = isStoreIsOpen(
          response?.data?.storeInfo,
          orderType?.serviceType
        );
        if (!isValidStoreArea(response, storeArea, isStoreOpened)) {
          return;
        }
        setDefaultCategory(response);

        //Closing Modal delivery Modal + Pickup Modal if open
        dispatch(Ac.NotPersistAction.setModalClose());
        dispatch(Ac.NotPersistAction.togglePickupStoreModal(false));

        if (isStoreOpened) {
          const orderTiming = getOrderTimingType(
            response?.data?.storeInfo,
            orderType?.serviceType
          );

          dispatch(Ac.StoreAction.setSelectedStore(response?.data?.storeInfo));
          const onlinePayment = setDefaultPaymentMethod(
            response,
            selectedPayment,
            orderType?.serviceType
          );
          if (onlinePayment && isActiveAnyPaymentGateway()) {
            dispatch(Ac.StoreAction.setSelectedPaymentMethod(onlinePayment));
          }
          dispatch(Ac.orderTimingAction.setOrderTiming(orderTiming));
        }

        // if its sharable link
        if (app_loaded_through === C.APP_LOADED_THROUGH.DirectLink) {
          let isItemInStock = isItemAvailable(response, selectedItem, history);
          //if store is closed
          if (isStoreOpened) {
            if (isItemInStock?.isItemInStock) {
              dispatch(
                Ac.ItemAction.getItemInfobyIdStart({
                  type: C.WITH_STORE_ID,
                  itemId: isItemInStock?.itemId,
                  brandId: brandInfo.brand._id,
                  storeId,
                  // menuId: brandMenu?._id,
                  menuId: response?.data?.storeMenu?._id,
                  serviceType: orderType?.serviceType,
                  categoryId: isItemInStock?.categoryId,
                })
              );

              //check if item is in stock
              historyreplace(
                `/store/item/${isItemInStock?.itemId}/${isItemInStock?.categoryId}`,
                `/${domainName}/store/item/${isItemInStock?.itemId}/${isItemInStock?.categoryId}`,
                DomainType
              );
            }
            //Out of stock || Not in same Store
            else {
              errorNotification(
                t("This item is out of stock, sorry for inconvenience")
              );
              history(url, `/${domainName}${url}`, DomainType);
            }
          } else {
            errorNotification(
              t("The store is currently closed, please try again soon")
            );
            history("/", `/${domainName}`, DomainType);
          }
        }

        // if its not a sharable link
        else {
          if (isStoreOpened) {
            historyreplace(url, `/${domainName}${url}`, DomainType);
          } else {
            errorNotification(
              t("The store is currently closed, please try again soon")
            );
            history("/", `/${domainName}`, DomainType);
          }
        }

        reset();
      } else handleErrorResponse(response);
    } catch (error) {
      throwError(error, dispatch);
    } finally {
      handleFinally(dispatch, brandInfo);
    }
  }

  return getStoreInfoWithMenu;
}

// check if item is available
const isItemAvailable = (response, selectedItem) => {
  if (response?.data?.storeMenu?.categories && response?.data?.storeInfo) {
    const selectedStoreID = response?.data?.storeInfo;
    let storeMenu = response?.data?.storeMenu;
    const itemName = selectedItem?.itemInfo?.baseLanguage?.title?.toLowerCase();

    for (let i = 0; i < storeMenu?.categories?.length; i++) {
      const category = storeMenu?.categories[i];
      // Loop through each item in the category
      for (let j = 0; j < category?.items?.length; j++) {
        const item = category?.items[j];
        const catItemName = item?.itemId?.baseLanguage?.title?.toLowerCase();
        if (
          catItemName === itemName &&
          !isItemOutOfStock(item?.itemId, selectedStoreID)
        ) {
          const categoryId = category?.categoryId?._id;
          return {
            itemId: item?.itemId?._id,
            isItemInStock: true,
            categoryId,
          };
        }
      }
    }

    return {
      isItemInStock: false,
    }; // Item is not available in any category
  }
  return {
    isItemInStock: false,
  }; // Item is not available in any category
};

const handleStart = (dispatch) => {
  dispatch(Ac.Loader.enableLoader());
  dispatch(Ac.Loader.setModalLoader(true));
  dispatch(Ac.ResetAction.resetOnChangeStore());
  dispatch(Ac.StoreAction.getStoreInfo());
};

const throwError = (error, dispatch) => {
  errorNotification(errorHandler(error.message));
  dispatch(Ac.ErrorAction.setStoreError(error.message));
};

const ifSuccessResponse = (response) => {
  return (
    response?.success === 1 &&
    response?.data?.storeMenu?.categories &&
    response?.data?.storeInfo
  );
};

const handleFinally = (dispatch, brandInfo) => {
  dispatch(Ac.BrandAction.getDiscounts({ brandId: brandInfo?.brand?._id }));
  setTimeout(() => {
    dispatch(Ac.Loader.disableLoader());
    dispatch(Ac.Loader.setModalLoader(false));
  }, 300);
};

export default useGetStoreInfo;
