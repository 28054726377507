import React, { createContext, useContext, useReducer } from "react";

// Create a context
const GoogleMapContext = createContext();

// Define action types

// Reducer function
const appReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_ZOOM":
      return {
        ...state,
        zoom: action.payload,
      };
    case "UPDATE_MAP_ADDRESS":
      return {
        ...state,
        mapAddress: action.payload,
      };
    case "UPDATE_USER_LOCATION":
      return {
        ...state,
        userLocation: {
          ...state?.userLocation,
          coordinates: action.payload,
        },
      };
    case "UPDATE_STREET_ADDRESS":
      return {
        ...state,
        mapAddress: {
          ...state.mapAddress,
          streetAddress: action.payload,
        },
      };
    case "SET_STATE":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "RESET":
      return {
        zoom: 7,
        mapAddress: {
          streetAddress: null,
          city: null,
          country: null,
          area: null,
        },
        userLocation: {
          lat: 23,
          lng: 54,
        },
      };

    default:
      return state;
  }
};

// Create a provider component
export const GoogleMapContextProvider = ({ children }) => {
  const [googlemapState, dispatch] = useReducer(appReducer, {
    zoom: 7,
    mapAddress: {
      streetAddress: null,
      city: null,
      country: null,
      area: null,
    },
    userLocation: {
      type: "Point",
      coordinates: {
        lat: 23,
        lng: 54,
      },
    },

    addressUpdated: false,
  });

  const setStreetAddress = (val) => {
    dispatch({
      type: "UPDATE_STREET_ADDRESS",
      payload: val,
    });
  };

  const setZoom = (val) => {
    dispatch({
      type: "UPDATE_ZOOM",
      payload: val,
    });
  };

  const setUserLocation = (val) => {
    dispatch({
      type: "UPDATE_USER_LOCATION",
      payload: val,
    });
  };

  const setMapAddress = (val) => {
    dispatch({
      type: "UPDATE_MAP_ADDRESS",
      payload: val,
    });
  };
  const reset = () => {
    dispatch({
      type: "RESET",
    });
  };

  const updateMapContext = (key, value) => {
    dispatch({
      type: "SET_STATE",
      payload: { key, value },
    });
  };

  return (
    <GoogleMapContext.Provider
      value={{
        updateMapContext,
        googlemapState,
        setMapAddress,
        setUserLocation,
        setZoom,
        setStreetAddress,
        reset,
      }}
    >
      {children}
    </GoogleMapContext.Provider>
  );
};

// Create a custom hook for using the context
export const useMapContext = () => {
  const context = useContext(GoogleMapContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
};
