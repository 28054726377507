import _ from "lodash";

export const handleAxiosErrorResponse = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    if (isValidObject(["response", "data", "message"], error)) {
      if (
        isValidObject(["response", "data", "errors"], error) &&
        error?.response?.data?.errors?.length > 0
      ) {
        return {
          err: {
            msg: error?.response?.data?.errors[0],
            statusCode: error?.response?.status,
          },
        };
      }

      return {
        err: {
          msg: error?.response?.data?.message,
          statusCode: error?.response?.status,
        },
      };
    } else {
      return {
        err: {
          msg: "Request Failed",
          statusCode: error.response ? error.response.status : "",
        },
      };
    }
  } else if (error.request) {
    return { err: { msg: "Request Failed" } };
  } else {
    return { err: { msg: "Request Failed" } };
  }
};

export const isValidObject = (attributesArray, object) => {
  if (attributesArray === null) {
    if (object === null || typeof object === "undefined") return false;
    return true;
  } else if (object === null || typeof object === "undefined") return false;

  // NULL will not be considered as valid object
  const res = attributesArray.reduce((xs, x) => {
    return isValidObjectHelper(xs) && isValidObjectHelper(xs[x]) ? xs[x] : null;
  }, object);

  if (res === null) return false;
  return true;
};

function isValidObjectHelper(obj) {
  if (obj !== null && typeof obj !== "undefined") return true;
  return false;
}

export const isString = function (obj) {
  let res = _.isString(obj);
  if (res === true && obj.trim().length > 0) return true;
  return false;
};

export const isNumber = function (obj) {
  if (_.isFinite(obj)) return true;

  return false;
};

export const isEmailValid = function (email) {
  let reg =
    /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
  const emailValidationRegex = RegExp(reg);

  return emailValidationRegex.test(email);
};

export const toNumber = (obj) => _.toNumber(obj);
