import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLang } from "hooks";

function OrderType(props) {
  const { currentLang } = useLang();
  const { t } = useTranslation();
  const { orderType } = useSelector((state) => state?.AppReducer);
  return (
    <div className="fixed bottom-0 max-460 bg-white overflow-y-auto">
      <div className="bg-white py-5">
        <div className="pt-4">
          <h4 className="text-outer-space text-16x font-semibold mb-2 mx-4">
            {t("Order Type")}
          </h4>
          <ul>
            {props?.services.map((item, index) => {
              return (
                <li
                  onClick={() => props.setServiceType(item)}
                  className="flex bg-white transition-all duration-200 ease-out hover:bg-black-100 
                focus:bg-black-100 border-b border-gray-200"
                  key={index}
                >
                  <div className="flex items-center w-full py-4 px-2">
                    <div className="px-2">
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="https://www.w3.org/2000/svg"
                        data-spec="icon-delivery"
                        className="w-8 h-8"
                      >
                        <path
                          d="M15.625 8.206c-.39 0-.762.073-1.11.2l-1.14-2.16v-.877h1.254c.181 0 .328.154.328.343a.335.335 0 01-.328.342.575.575 0 00-.563.587c0 .324.252.587.563.587.801 0 1.453-.68 1.453-1.516 0-.74-.51-1.355-1.183-1.488a.524.524 0 00-.165-.029h-1.921a.575.575 0 00-.563.587V5.81H8.072l-.274-.636h.89c.31 0 .562-.263.562-.587A.575.575 0 008.687 4H5.172a.575.575 0 00-.563.587c0 .324.252.587.563.587h1.393l.316.734a.57.57 0 00-.186.205l-1.21 2.293c-.348-.127-.72-.2-1.11-.2C2.514 8.206 1 9.786 1 11.728c0 1.942 1.514 3.522 3.375 3.522 1.669 0 3.055-1.272 3.324-2.935H10c.024 0 .05-.004.073-.007.014-.002.028-.002.041-.005.007-.002.013-.005.02-.006l.033-.011c.019-.006.039-.012.058-.02l.005-.002c.012-.006.021-.013.032-.02.01-.005.021-.01.032-.017l.027-.018c.015-.01.029-.024.043-.037.011-.01.024-.019.035-.03l.005-.007c.014-.014.024-.03.036-.045.01-.015.022-.028.031-.043l.015-.027.007-.009 2.32-4.397.716 1.359a3.57 3.57 0 00-1.28 2.755c0 1.942 1.515 3.522 3.376 3.522S19 13.67 19 11.728c0-1.942-1.514-3.522-3.375-3.522zm-3.764-1.223l-1.81 3.43-1.474-3.43h3.284zm-4.438.184l1.708 3.974H7.7a3.55 3.55 0 00-1.23-2.169l.952-1.805zm-.872 3.974H5.327l.59-1.119c.303.3.526.685.634 1.119zm-2.176 2.934c-1.24 0-2.25-1.052-2.25-2.347s1.01-2.348 2.25-2.348c.192 0 .378.028.557.076l-1.05 1.988-.01.027a.48.48 0 00-.04.112.55.55 0 00-.016.117c0 .01-.003.019-.003.028l.002.025a.9.9 0 00.016.116.59.59 0 00.036.107.585.585 0 00.026.05.44.44 0 00.064.091.611.611 0 00.127.111l.02.014c.008.005.017.007.025.012a.494.494 0 00.108.041.466.466 0 00.114.018l.024.002h2.176c-.251 1.01-1.13 1.76-2.176 1.76zm11.25 0c-1.24 0-2.25-1.052-2.25-2.347 0-.672.273-1.278.708-1.706l1.05 1.989a.558.558 0 00.493.303.538.538 0 00.27-.072.6.6 0 00.222-.798l-1.05-1.989a2.19 2.19 0 01.558-.075c1.24 0 2.25 1.053 2.25 2.347 0 1.296-1.01 2.348-2.25 2.348z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="w-full px-2">
                      <small className="font-semibold text-black text-15x">
                        {item?.[currentLang ?? "EN"]?.name}
                      </small>
                    </div>

                    {orderType?._id === item._id && (
                      <div className="px-2">
                        <svg
                          fill="currentColor"
                          xmlns="https://www.w3.org/2000/svg"
                          viewBox="20 20 60 60"
                          data-spec="icon-check-green"
                          className="w-4 h-4 fill-success"
                        >
                          <path
                            d="M72.88 28.971a5 5 0 00-3.438 1.531c-9.685 9.706-18.52 19.253-27.875 28.781l-11.47-9.156a5.002 5.002 0 10-6.25 7.813l15 12a5 5 0 006.657-.375c10.77-10.793 20.436-21.414 31-32a5 5 0 00-3.625-8.594z"
                            overflow="visible"
                            color="currentColor"
                          ></path>
                        </svg>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

    </div>
  );
}

export default OrderType;
