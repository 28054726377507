import { apiSlice } from "apiHelpers/ApiSlice";
import * as URL from "apiHelpers/api/ApiUrls";
import { Loader } from "store/actions";

const setLoading = (dispatch, loading) => {
  dispatch(Loader.setLoading(loading));
};

const ItemService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecommendedItemsList: builder.mutation({
      query: (body) => ({
        url: URL.GET_RECOMMENDED_ITEMS,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        console.log("transformResponse", response);
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          setLoading(dispatch, true);
        } catch (err) {
        } finally {
          setLoading(dispatch, false);
        }
      },
    }),
  }),
});
export const { useGetRecommendedItemsListMutation } = ItemService;
export default ItemService;
