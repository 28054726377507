import { createSlice } from "@reduxjs/toolkit";
import C from "Constants";
const initialState = {
  selectedItem: null,
  selectedCategory: null,
  error: null,
  app_loaded_through: C.APP_LOADED_THROUGH.normalFlow,
  loading: false,
  ismenuDisabled: false,
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    getItemInfobyIdStart: (state, action) => {
      state.error = null;
      state.ismenuDisabled = false;
      state.selectedItem = null;
      state.selectedCategory = null;
      state.loading = true;
    },
    setMenuActiveStatus: (state, action) => {
      state.ismenuDisabled = action.payload;
    },
    getItemInfobyIdSuccess: (state, action) => {
      state.selectedItem = action.payload;
      state.error = null;
      state.loading = false;
    },
    getItemInfobyIdFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    updateAppLoadedThrough: (state, action) => {
      state.app_loaded_through = action.payload;
    },
    setCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    clearItem: (state) => {
      state.app_loaded_through = C.APP_LOADED_THROUGH.normalFlow;
      state.selectedItem = null;
      state.error = null;
      state.selectedCategory = null;
    },
  },
});

export const {
  setMenuActiveStatus,
  getItemInfobyIdStart,
  getItemInfobyIdSuccess,
  getItemInfobyIdFailed,

  updateAppLoadedThrough,
  clearItem,
  setCategory,
} = itemSlice.actions;

export default itemSlice.reducer;
