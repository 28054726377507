import ItemFooter from "components/item/ItemFooter";
import { t } from "i18next";
import { useLang } from "hooks";
import C from "Constants";
import { useSelector } from "react-redux";
import { isStoreIsOpen } from "utils/storeUtils";
import { isItemOutOfStock } from "utils/Utils";

const RenderFooter = ({
  selectedStore,
  goHomePage,
  selectedItem,
  addSelectedToBasket,
  singleItem,
  getBasketValue,
}) => {
  const { currentLang } = useLang();
  const { orderType } = useSelector((state) => state.AppReducer);

  if (!selectedStore) {
    return (
      <ItemFooter onButtonClick={goHomePage} btnText1="Something Went wrong" />
    );
  }

  if (isItemOutOfStock(selectedItem?.itemInfo, selectedStore)) {
    return (
      <ItemFooter
        onButtonClick={goHomePage}
        {...{ selectedItem }}
        btnText1={t("continue shopping")}
      />
    );
  }

  if (!isStoreIsOpen(selectedStore, orderType?.serviceType)) {
    return (
      <ItemFooter
        onButtonClick={goHomePage}
        {...{ selectedItem }}
        btnText1={t("continue shopping")}
      />
    );
  }

  return (
    <ItemFooter
      onButtonClick={addSelectedToBasket}
      btnText1={`${t("Add")} ${singleItem?.quantity ?? 1} ${t("to basket")}`}
      btnText2={`${
        currentLang === C.AR
          ? getBasketValue() + " " + t("AED")
          : t("AED") + " " + getBasketValue()
      }`}
      {...{ selectedItem }}
    />
  );
};

export default RenderFooter;
