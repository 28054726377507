import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import ItemHeader from "components/item/ItemHeader";
import ModiferGroups from "components/Modifier/ModiferGroups";
import Quantity from "components/item/Quantity";
import C from "Constants";
import { NotPersistAction } from "store/actions";
import { useCustomHistory } from "hooks";
import WithLoading from "components/Hoc/WithLoading";
import ItemNotFoundError from "components/item/ItemNotFound";
import useGetItemById from "components/item/hooks/useGetItemById";
import useAddItemToBasket from "components/item/hooks/useAddItemToBasket";
import Footer from "./components/Footer";
import { isStoreIsOpen } from "utils/storeUtils";

const Item = () => {
  // Hooks
  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const { getItemById } = useGetItemById();
  const { addItemToBasket } = useAddItemToBasket();

  /* Destructuring the state from the redux store. */
  const { isLoading } = useSelector((state) => state.loader);
  const { selectedStore } = useSelector((state) => state.store);
  const { storeMenu } = useSelector((state) => state.store);

  const { brandMenu } = useSelector((state) => state.brand);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { selectedItem, error } = useSelector((state) => state?.item);
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  // States
  const [itemSection, setItemSection] = useState([]);
  const [singleItem, setSingleItem] = useState({
    quantity: 1,
    itemSection: [],
    price: selectedItem?.itemInfo?.price || 0,
  });

  useEffect(() => {
    setSingleItem((prevSingleItem) => ({
      ...prevSingleItem,
      price: selectedItem?.itemInfo?.price || 0,
    }));
    return () => {
      dispatch(NotPersistAction.setRequiredMissingSections([]));
    };
  }, [selectedItem?.itemInfo?.price]);

  const decreaseQuantity = () => {
    if (singleItem.quantity > 1) {
      setSingleItem({
        ...singleItem,
        quantity: singleItem.quantity - 1,
      });
    }
  };
  const increaseQuantity = () => {
    setSingleItem({
      ...singleItem,
      quantity: singleItem.quantity + 1,
    });
  };

  const goBackFromItemScreen = () => {
    router("/", `/${params.domainName}`, DomainType, {
      fromItemScreen: true,
    });
  };

  const addSelectedToBasket = () => {
    addItemToBasket(
      brandInfo,
      selectedItem,
      singleItem,
      itemSection,
      goBackFromItemScreen
    );
  };

  const getBasketValue = () => {
    const p = singleItem?.price * singleItem?.quantity;
    return p?.toFixed(2);
  };

  useEffect(() => {
    if (params?.itemId && brandInfo?.brand && brandMenu?._id) {
      getItemById(selectedStore, storeMenu, params, brandInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params?.itemId,
    selectedStore?._id,
    brandInfo?.brand?._id,
    brandMenu?._id,
    orderType?.serviceType,
    params?.catId,
  ]);

  const goHomePage = () => router("/", `/${params.domainName}`, DomainType);

  const closeItemDetail = () =>
    router("/", `/${params.domainName}`, DomainType, { fromItemScreen: true });

  const renderHeader = () => {
    const commonProps = {
      selectedItem,
      closeItemDetail,
      menuType: C.BRAND_BASED_CATALOGUE,
    };

    const stripText =
      (!selectedStore && t("Something was incorrect.")) ||
      t("This item is out of stock, sorry for inconvenience") ||
      (!isStoreIsOpen(selectedStore, orderType?.serviceType) &&
        t("The store is currently closed, please try again soon"));
    return <ItemHeader {...commonProps} stripText={stripText} />;
  };

  if (!isLoading && error)
    return <ItemNotFoundError {...{ error }} handleClick={goHomePage} />;
  return (
    <WithLoading isLoading={isLoading}>
      <div className="flex w-full bg-white flex-col store-page-popup">
        <>{renderHeader()}</>
        <div className="flex flex-grow overflow-y-auto overflow-x-hidden">
          <div className="w-full">
            <ModiferGroups
              {...{
                selectedItem,
                setItemSection,
                itemSection,
                singleItem,
                setSingleItem,
              }}
              isStoreSelected={true}
            />
            <Quantity {...{ decreaseQuantity, increaseQuantity, singleItem }} />
            <Footer
              {...{
                selectedStore,
                goHomePage,
                selectedItem,
                addSelectedToBasket,
                singleItem,
                getBasketValue,
              }}
            />
          </div>
        </div>
      </div>
    </WithLoading>
  );
};

export default Item;
