import React from "react";
import { useLang } from "../hooks";
import C from "Constants";
const ProfilePageHeader = (props) => {
  const { pageHeaderClasses } = props;
  const image = props.image ? props.image : false;
  const { currentLang } = useLang();

  return (
    <div className="mt-3">
      <div className={pageHeaderClasses}>
        <div className={image ? "py-7 absolute" : "py-7 relative"}>
          <button type="button" onClick={props.handleClick}>
            <svg
              viewBox="0 0 20 20"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
              data-spec="icon-arrow-left"
              className={`atom-icon mol-icon-button__icon w-5 h-5 text-brand-base-primary  ${
                currentLang === "AR" ? "rotate-180" : ""
              }`}
            >
              <path
                d="M18 10.467H2M9.467 17.933L2 10.467 9.467 3"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </div>
        {image ? (
          <div className="flex justify-center my-0 mx-auto py-5 text-22x font-bold text-outer-space leading-28x w-full text-center">
            <div
              className="w-12 h-12 bg-cover "
              style={{
                backgroundImage: `url(${props?.img?.brandLogoFirst ?? C.Logo})`,
              }}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProfilePageHeader;
