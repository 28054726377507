import { useEffect } from "react";
import { useSelector } from "react-redux";
import useCustomHistory from "./useCustomHistory";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function useCheckIfStoreAndCart() {
  const params = useParams();
  const router = useCustomHistory();
  const { cart } = useSelector((state) => state.cart);
  const { selectedStore } = useSelector((state) => state.store);
  const { DomainType } = useSelector((state) => state.AppReducer);

  useEffect(() => {
    if (!selectedStore || !cart?.length) {
      router("/", `/${params.domainName}/`, DomainType);
    }
  }, [selectedStore, cart]);
}

export default useCheckIfStoreAndCart;
