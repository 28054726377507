import React from "react";

const PopupHeader = (props) => {
  const { title } = props;
  return (
    <div className="flex justify-between border-b-2 border-black-100 px-4 w-full">
      <div className="mx-2 py-5 absolute">
        <button type="button" onClick={() => props.setOpen(false)}>
          <span className="rounded-full flex items-center justify-center bg-circle p-2 hover:border border-red-500 cursor-pointer">
            <svg height="24" width="24" viewBox="0 0 24 24" className="h-4 w-4">
              <path
                fill="#828585"
                d="M12.0001 10.2322L5.88398 4.11612L4.11621 5.88389L10.2323 12L4.11621 18.1161L5.88398 19.8839L12.0001 13.7678L18.1162 19.8839L19.884 18.1161L13.7679 12L19.884 5.88389L18.1162 4.11612L12.0001 10.2322Z"
              ></path>
            </svg>
          </span>
        </button>
      </div>
      <div className="my-0 mx-auto py-5 text-22x font-bold text-outer-space leading-28x w-full text-center">
        {title}
      </div>
    </div>
    // <div className="bg-white border-b border-gray-200 flex flex-flex-3 items-center self-stretch flex-row flex-nowrap justify-between px-4">
    //   <h3 className="py-4 text-16x font-bold text-outer-space">{heading}</h3>
    //   <span className="inline-block">
    //     <button type="button">
    //       <span className="rounded-full flex items-center justify-center bg-circle p-2">
    //         <svg height="24" width="24" viewBox="0 0 24 24" className="h-4 w-4">
    //           <path
    //             fill="#828585"
    //             d="M12.0001 10.2322L5.88398 4.11612L4.11621 5.88389L10.2323 12L4.11621 18.1161L5.88398 19.8839L12.0001 13.7678L18.1162 19.8839L19.884 18.1161L13.7679 12L19.884 5.88389L18.1162 4.11612L12.0001 10.2322Z"
    //           ></path>
    //         </svg>
    //       </span>
    //     </button>
    //   </span>
    // </div>
  );
};

export default PopupHeader;
