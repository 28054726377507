import React from "react";
import { OrderSuccessTick, OrderFailedTick } from "icons";
import { t } from "i18next";
const TrackOrderStatus = ({ orderStatus, orderInfo }) => {
  if (orderStatus && orderInfo) {
    return (
      <div className="flex flex-col items-center flex-grow justify-center">
        {orderInfo.orderStatus === "COMPLETE" ? (
          <OrderSuccessTick />
        ) : (
          <OrderFailedTick />
        )}
        {orderInfo.orderStatus === "COMPLETE" ? (
          <p className="mt-8 font-bold text-17x">
            {t("Your order has been delivered")}
          </p>
        ) : (
          <p className="mt-8 font-bold text-17x">
            {t("Your order has been Rejected")}
          </p>
        )}
      </div>
    );
  } else {
    return <div>{t("Sorry, we couldn't find the order.")}</div>;
  }
};

export default TrackOrderStatus;
