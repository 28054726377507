import React from "react";
import { t } from "i18next";
import { UTILS } from "utils";
import DeliveryTime from "./DeliveryTime";
const TrackOrderHeader = ({ orderStatus, orderInfo }) => {
  const getOrderStatus = (order) => {
    const status = UTILS.getOrderStatus(order);
    return status;
  };
  if (orderStatus && orderInfo) {
    return (
      <div className="flex justify-between font-semibold">
        <div className="mt-2 w-1/3">
          <p className="text-gray-400 mb-1 text-sm leading-normal">
            {" "}
            {t("Order ID")}
          </p>
          <span
            data-v-00779ab4=""
            className="text-brand-base-primary border-b-2 border-primary"
          >
            # {orderInfo?.orderNumber}
          </span>
        </div>
        {getOrderStatus(orderInfo) && orderInfo?.deliveryTime && (
          <div className="mt-2 text-center w-1/3">
            <p className="text-gray-400 mb-1 text-sm leading-normal">
              {t("Est. delivery")}
            </p>
            <p data-test="eta">
              <DeliveryTime
                deliveryTime={orderInfo?.deliveryTime}
                orderPlacedtime={orderInfo.orderPlacedTime}
              />
            </p>
          </div>
        )}
      </div>
    );
  } else {
    return <div className="flex justify-between font-semibold"></div>;
  }
};

export default TrackOrderHeader;
