import React from "react";

function AddressOffice() {
  return (
    <svg
      data-v-361fb5f2=""
      viewBox="0 0 16 12"
      xmlns="https://www.w3.org/2000/svg"
      fill="currentColor"
      data-spec="icon-briefcase"
      //   style="width: 16px; height: 16px;"
      className="h-4 w-4 text-brand-base-primary inline-block mx-1"
    >
      <path d="M5.23.4a.48.48 0 00-.43.48V2H1.23a.48.48 0 00-.43.48v8.64a.48.48 0 10.96 0V5.235l4.64 1.59C6.414 7.692 7.13 8.4 8 8.4c.871 0 1.59-.711 1.6-1.58l4.64-1.59v5.41H2.88a.48.48 0 100 .96h11.84a.48.48 0 00.48-.48V4.08a.481.481 0 00-.825-.344.48.48 0 00-.135.344v.135l-4.92 1.69A1.603 1.603 0 008 5.2c-.546 0-1.03.282-1.32.705l-4.92-1.69V2.96h12.96a.48.48 0 100-.96H11.2V.88a.48.48 0 00-.48-.48H5.28a.48.48 0 00-.05 0zm.53.96h4.48V2H5.76v-.64zM8 6.16c.36 0 .64.28.64.64s-.28.64-.64.64c-.359 0-.64-.28-.64-.64s.28-.64.64-.64z"></path>
    </svg>
  );
}

export default AddressOffice;
