import React from "react";

function OrderSuccessTick() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      fill="currentColor"
      className=" w-20 h-20 checkmark mt-3"
    >
      <circle cx="26" cy="26" r="25" fill="none"></circle>
      <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
    </svg>
  );
}

export default OrderSuccessTick;
