import { isItemOutOfStock } from "utils/Utils";
import { isStoreIsOpen } from "utils/storeUtils";
import { logErrorOnSentry } from "utils/Utils";
import { useSelector } from "react-redux";
import { useCustomHistory } from "hooks";

function useOpenItemDetailPage() {
  const router = useCustomHistory();
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);
  const { selectedStore } = useSelector((state) => state?.store);
  const openStoreItem = (item, category, setOpen, params) => {
    if (!isStoreIsOpen(selectedStore, orderType?.serviceType)) setOpen(true);
    else if (
      !isItemOutOfStock(item?.itemId, selectedStore) &&
      item?.itemId?._id &&
      category?._id
    ) {
      let MainURl = "/store/item/";
      let TempUrl = `/${params?.domainName}${MainURl}${item.itemId._id}/${category?._id}`;
      let RootUrl = `${MainURl}${item.itemId._id}/${category?._id}`;
      router(RootUrl, TempUrl, DomainType, item);
    } else {
      logErrorOnSentry(
        `error in opening store item ${JSON.stringify(item)}`,
        "error"
      );
    }
  };
  return openStoreItem;
}

export default useOpenItemDetailPage;
