import React from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useLang } from "hooks";
import C from "Constants";
import DeliveryWaveOffWidget from "./DeliveryWaveOffWidget";

const ItemsPaymentTotalDetail = (props) => {
  const { currentLang } = useLang();
  //global state
  const { discountInfo, isDiscountApplied } = useSelector(
    (state) => state?.Discounts
  );
  const { loyaltyApplied, loyaltyAmount } = useSelector(
    (state) => state?.Loyalty
  );
  const { discountAmount, amountAfterDiscount, orderGrossAmount } = useSelector(
    (state) => state?.cart
  );
  const { orderType } = useSelector((state) => state?.AppReducer);
  const { original, deliveryFee, taxRate, children, userWallet } = props;
  const page = props.page === true ? true : false;
  console.log("props", props);

  const PercentageType = (discount) => {
    const showDiscountValue = (value) => {
      const discount_Value = Number(value);
      if (discount_Value && discount_Value > 100) return 100;
      else return discount_Value;
    };
    const getPercentAmount = (value) => {
      const discount_Value = Number(value);
      const items_Total = Number(original);
      if (discount_Value && discount_Value > items_Total) return items_Total;
      return discount_Value;
    };

    return (
      <li className="pb-1 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
        <span className="item max-w-[50%]">
          <div className="">
            <span className="text-green-500">
              <span>{t("Discount")} </span>
              <span className=" text-10x block " style={{ fontSize: "10px" }}>
                {`(  
                  ${
                    currentLang === "AR"
                      ? `%${showDiscountValue(
                          discount?.dicount?.discountAmount
                        )}`
                      : `${showDiscountValue(
                          discount?.dicount?.discountAmount
                        )}% `
                  }
                
               
                
                ${t("AED")} ${
                  discount?.dicount?.isApplicableOnAllItems
                    ? t("all Items")
                    : t("Selected Items")
                }
             
                 ${
                   discount?.dicount?.maximumDiscountValue !== 0
                     ? `${t("Up to")} 
                     ${discount?.dicount?.maximumDiscountValue} 
                       ${t("AED")} `
                     : ""
                 }
              )`}
              </span>
            </span>
          </div>
        </span>
        <span className="text-green-500">
          <span> - </span>
          {currentLang === "AR"
            ? getPercentAmount(discountAmount) + " " + t("AED")
            : t("AED") + " " + getPercentAmount(discountAmount)}
        </span>
      </li>
    );
  };
  const FixedType = (discount) => {
    return (
      <li className="pb-1 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
        <span className="item item max-w-[50%]">
          <div className="">
            <span className="text-green-500">
              <span>{t("Discount")} </span>
              <span className="text-10x block" style={{ fontSize: "10px" }}>
                {`( ${discount?.dicount?.discountAmount} ${t("AED")} ${
                  discount?.dicount?.isApplicableOnAllItems
                    ? t("all Items")
                    : t("Selected Items")
                }
              )
              `}
              </span>
            </span>
          </div>
        </span>
        <span className="text-green-500">
          <span> - </span>

          {currentLang === "AR"
            ? discountAmount + " " + t("AED")
            : t("AED") + " " + discountAmount}
        </span>
      </li>
    );
  };

  return (
    <>
      {children ? <div className="mt-5">{children}</div> : null}
      <div
        className={`bg-white  pt-4 ${
          !props?.loyaltyPolicy?.isLoyaltyActive && props.page === "checkout"
            ? ""
            : "mt-5 border-t-4 border-gray-200"
        }`}
      >
        <DeliveryWaveOffWidget />
        <h4 className="text-outer-space text-17x font-semibold mx-4">
          {t("Payment summary")}
        </h4>
        <ul className="basket-contents__items pt-5 ">
          {((discountInfo && isDiscountApplied) ||
            (loyaltyApplied && userWallet > 0)) && (
            <li className=" pb-1 basket-item flex items-center flex-row  justify-between flex-100 px-4 text-16x">
              <span className="item">
                <div className="orderweb__09f2b375">
                  <span className="line-through text-black">
                    {t("Original Basket Total")}
                  </span>
                </div>
              </span>
              {original && (
                <span className="text-black line-through">
                  {currentLang === "AR"
                    ? original + " " + t("AED")
                    : t("AED") + " " + original}
                </span>
              )}
            </li>
          )}
          {discountInfo &&
            isDiscountApplied &&
            (discountInfo?.discountType === "percentage" ? (
              <PercentageType dicount={discountInfo} />
            ) : (
              <FixedType dicount={discountInfo} />
            ))}

          {loyaltyApplied && userWallet > 0 && (
            <li className="py-1 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
              <span className="item">
                <div className="orderweb">
                  <span className="text-green-500">{t("Loyalty points")} </span>
                </div>
              </span>
              <span className="text-green-500">
                <span> - </span>
                {currentLang === "AR"
                  ? loyaltyAmount.toFixed(2) + " " + t("AED")
                  : t("AED") + " " + loyaltyAmount.toFixed(2)}
              </span>
            </li>
          )}

          <li className=" pb-1 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
            <span className="item">
              <div className="orderweb">
                <span className="text-black">{t("Basket Total")} </span>
              </div>
            </span>
            {discountInfo && isDiscountApplied && amountAfterDiscount ? (
              <span className="text-black">
                {currentLang === "AR"
                  ? amountAfterDiscount + " " + t("AED")
                  : t("AED") + " " + amountAfterDiscount}
              </span>
            ) : (
              <span className="text-black">
                {currentLang === "AR"
                  ? amountAfterDiscount + " " + t("AED")
                  : t("AED") + " " + amountAfterDiscount}
              </span>
            )}
          </li>

          {orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY ? (
            <li className="pb-1 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
              <span className="item">
                <div className="orderweb__09f2b375">
                  <span className="text-black">{t("Delivery Fee")} </span>
                </div>
              </span>
              {deliveryFee && (
                <span className="text-black">
                  {currentLang === "AR"
                    ? deliveryFee + " " + t("AED")
                    : t("AED") + " " + deliveryFee}
                </span>
              )}
            </li>
          ) : null}
          <li className="pb-0 basket-item flex items-center flex-row justify-between flex-100 px-4 text-16x">
            <span className="item">
              <div className="orderweb__09f2b375">
                <span className="text-black">{t("Value-Added Tax")} </span>
              </div>
            </span>
            <span className="text-black">{`${taxRate}%`}</span>
          </li>
        </ul>
        <div className="py-0">
          <div className="py-4">
            <div className="flex items-center justify-between px-4 text-16x">
              <span className="flex items-center flex-auto px-.5">
                <span
                  className={
                    page
                      ? "text-outer-space text-22x font-semibold"
                      : "text-black text-17x font-bold"
                  }
                >
                  {t("Total")}
                </span>
              </span>

              <span className="text-16x">
                <span
                  className={
                    page
                      ? "text-outer-space text-22x"
                      : "text-black text-17x font-bold"
                  }
                >
                  {currentLang === "AR"
                    ? orderGrossAmount + " " + t("AED")
                    : t("AED") + " " + orderGrossAmount}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemsPaymentTotalDetail;
