import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreListForPickup } from "components/store/StoreList";
import CustomModal from "components/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { BrandAction } from "store/actions";
import ChangePickupStoreModalContainer from "./ChangePickupStoreModalContainer";
import { PreOrderingSlotWidget } from "components/Pre-ordering";
import moment from "moment";
import { FormatPreOrderingTime } from "utils/OrderUtils";
import C from "Constants";
import { NotPersistAction } from "store/actions";

function PickupChangeStoreTo({ lang }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { stores } = useSelector((state) => state?.brand);
  const { selectedStore } = useSelector((state) => state?.store);
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);

  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { pickupStoreModal } = useSelector((state) => state?.NotPersist);
  const [isContainerModalopen, setContainerModalopen] = useState(false);
  const [isPreOrderingSlotWidget, setPreOrderingSlotWidget] = useState(false);
  //change store
  const handleChnagePickupStore = () => {
    dispatch(BrandAction.getBrandStores(brandInfo?.brand._id));
    dispatch(NotPersistAction.togglePickupStoreModal(true));
  };

  return (
    <>
      <div className="bg-white px-4 pb-2 border-t border-gray-200 mt-4">
        <div className="flex items-center pt-2">
          <div className=" grow px-2 content-area cursor-pointer">
            <p className="font-semibold mb-2">{t("Select Store")}</p>

            <div
              className="flex w-fit items-center rounded bg-brand-base-primary p-1 py-2"
              onClick={() => setContainerModalopen(true)}
            >
              <span className="inline-block px-1">
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  className="w-4 h-4 text-brand-text-primary"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z" />
                </svg>
              </span>
              <div className="inline-block px-2 text-brand-text-primary text-xs">
                {lang === "EN"
                  ? selectedStore?.baseLanguage?.name
                  : selectedStore?.baseLanguage?.name ?? "Select your store"}
                (
                {orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER &&
                orderTiming?.preOrderSlot?.day &&
                orderTiming?.preOrderSlot?.time ? (
                  <span className="text-xs">
                    {orderTiming?.preOrderSlot?.day === C.TODAY
                      ? orderTiming?.preOrderSlot?.day
                      : moment(
                          orderTiming?.preOrderSlot?.day,
                          "dddd, DD MMM YYYY HH:mm"
                        ).format("DD MMM")}
                    {", "}
                    {FormatPreOrderingTime(orderTiming?.preOrderSlot?.time)}
                  </span>
                ) : (
                  <span className="text-xs">{t("ASAP")}</span>
                )}
                )
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        shouldCloseOnEsc="No"
        shouldCloseOnOverlayClick="No"
        open={pickupStoreModal}
        reduxAction={NotPersistAction.togglePickupStoreModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-20"
      >
        <StoreListForPickup
          stores={stores}
          header={false}
          headerLabel=""
          styleClasses="fixed bottom-0 h-5 bg-white min-h-70p max-460 o111verflow-y-auto rounded-t-lg min-h-60p  border-sc"
        />
      </CustomModal>

      <ChangePickupStoreModalContainer
        isContainerModalopen={isContainerModalopen}
        setContainerModalopen={setContainerModalopen}
        handleChangeOrderStore={handleChnagePickupStore}
        setPreOrderingSlotWidget={setPreOrderingSlotWidget}
        preORderSlots={selectedStore?.availablePreOrderSlotsForPickup}
        isPreORderEnabled={selectedStore?.isPreOrderEnabledForPickup}
      />

      <PreOrderingSlotWidget
        selectedStore={selectedStore}
        isPreOrderingSlotWidget={isPreOrderingSlotWidget}
        setPreOrderingSlotWidget={setPreOrderingSlotWidget}
      />
    </>
  );
}

export default PickupChangeStoreTo;
