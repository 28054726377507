import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import * as CommonUI from "components";
import SelectDeliveryAddress from "components/checkout/SelectDeliveryAddress";
import OrderPlaced from "components/checkout/OrderPlaced";
import { useLang, useCustomHistory } from "hooks";
import * as Actions from "store/actions";
import useTrackOrder from "features/hooks/useTrackOrder";
import { ValidateGeoLocation } from "utils/Utils";
import { handleLocalStorage } from "utils/storage";
import { getAreaCityAndCountry } from "utils/googleGeoCode";
import { StoreAction } from "store/actions";

function CheckoutModals(props) {
  const {
    changeAddressModal,
    setChangeAddressModal,
    addressList,
    deleteSlectedAddress,
  } = props;

  const params = useParams();
  const dispatch = useDispatch();
  const { currentLang } = useLang();
  const router = useCustomHistory();

  const { placedOrder } = useSelector((state) => state.order);
  const { outletBasedSelectedStoreArea } = useSelector((state) => state.store);
  const { error, errorModaL } = useSelector((rstate) => rstate.errorSlice);
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { orderSuccess } = useSelector((state) => state.NotPersist);
  const { isGeoLocationAdded, temporarymapAddress, temporaryuserLocation } =
    useSelector((state) => state.DeliveryAddressReducer);
  const trackOrder = useTrackOrder({ placedOrder });

  const goToGoogleMapScreen = () => {
    const mapPage = "/store/checkout/map-address";
    const refinePage = "/store/checkout/add-delivery-address";
    if (
      ValidateGeoLocation(
        isGeoLocationAdded,
        temporarymapAddress,
        temporaryuserLocation
      )
    ) {
      router(refinePage, `/${params?.domainName}${refinePage}`, DomainType);
    } else {
      router(mapPage, `/${params?.domainName}${mapPage}`, DomainType);
    }
  };
  const setAddress = async (address) => {
    dispatch(Actions.DeliveryAddressAction.setDeliveryAddress(address));
    handleLocalStorage.setItem("deliveryAddress", address);
    setChangeAddressModal(false);
    if (
      outletBasedSelectedStoreArea?.mappedAreaType === "DISTANCE" &&
      address?.coordinates?.lat &&
      address?.coordinates?.lng &&
      outletBasedSelectedStoreArea?.areaName
    ) {
      const lat = address?.coordinates?.lat;
      const lng = address?.coordinates?.lng;
      const storeAreaName = await getAreaCityAndCountry(lat, lng, currentLang);
      dispatch(Actions.StoreAction.setDistanceBasedAreaName(storeAreaName));
    }
  };

  return (
    <>
      <CommonUI.CustomModal
        open={errorModaL}
        reduxAction={Actions.ErrorAction.setErrorModal(false)}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <CommonUI.ErrorModal
          reduxAction={Actions.ErrorAction.setErrorModal(false)}
          message={error}
        />
      </CommonUI.CustomModal>
      <CommonUI.CustomModal
        open={changeAddressModal}
        setOpen={setChangeAddressModal}
        bodyclasses="flex"
        overlayClasses={`modal-container fixed h-screen w-screen top-0 bg-black bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-20 ${
          currentLang === "AR" ? "border-sc-l" : "border-sc"
        }`}
      >
        <SelectDeliveryAddress handleAddressClick={goToGoogleMapScreen}>
          <CommonUI.SaveAddressesList
            savedAddresses={addressList}
            deleteSlectedAddress={deleteSlectedAddress}
            setAddress={setAddress}
          />
        </SelectDeliveryAddress>
      </CommonUI.CustomModal>
      <CommonUI.CustomModal
        open={orderSuccess}
        reduxAction={Actions.NotPersistAction.toggleOrderPlaceModel(false)}
        bodyclasses="flex"
        overlayClasses="fixed overflow-y-auto h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40 border-sc"
      >
        <OrderPlaced {...{ trackOrder }} />
      </CommonUI.CustomModal>
    </>
  );
}

export default CheckoutModals;
