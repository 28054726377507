import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { IconLocation, IconDelivery, IconPhone } from "icons";
import { useCustomHistory, useLang } from "hooks";
const Footer = () => {
  const params = useParams();
  const { currentLang } = useLang();
  const router = useCustomHistory();
  const [showLinks, setShowLinks] = useState(false);
  const [pages, setPages] = useState([]);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);

  const { DomainType, selectedStore } = useSelector((state) => ({
    DomainType: state.AppReducer.DomainType,
    selectedStore: state.store.selectedStore,
  }));
  const getActiveLinks = useMemo(() => {
    const activeLinks = Object.values(
      brandInfo?.setting?.staticPages || {}
    ).filter((key) => key?.isActive);
    return activeLinks.length > 0;
  }, [brandInfo?.setting?.staticPages]);

  useEffect(() => {
    setPages(brandInfo?.setting?.staticPages);
    setShowLinks(getActiveLinks);
    return () => setShowLinks(false);
  }, []);

  const gotoMyPage = (pageData) => {
    const DominaName =
      brandInfo?.setting?.temporaryDomainName || params?.domainName;
    if (pageData) {
      router(
        `/pages/s/${pageData[0]}`,
        `/${DominaName}/pages/s/${pageData[0]}`,
        DomainType,
        pageData[1]
      );
    }
  };

  const paymentOptions = [
    {
      alt: "master-card",
      src: "/images/card_master.png",
    },
    {
      alt: "visa-card",
      src: "/images/card_visa.png",
    },
    {
      alt: "union-bank",
      src: "/images/unioun-bank.png",
    },
  ];
  return (
    <div
      id="footer"
      className="p-5 text-brand-text-primary bg-brand-base-primary"
    >
      <h4 className="font-nunito ft-head">{brandInfo?.brand?.brandName}</h4>
      <div>{brandInfo?.setting?.taglineEN}</div>

      <ul className="ft-contact mt-6">
        {selectedStore?.averagePrepTime && (
          <li>
            <span className="svg-icon">
              <IconDelivery stroke="#FFFFFF" size="24" />
            </span>
            {t("Delivery happens within")} {selectedStore?.averagePrepTime}
          </li>
        )}
        {selectedStore?.mobileNumber && (
          <li>
            <span className="svg-icon">
              <IconPhone stroke="#FFFFFF" size="24" />
            </span>
            <span dir="ltr"> {selectedStore?.mobileNumber}</span>
          </li>
        )}
        {selectedStore?.manualAddress && (
          <li>
            <span className="svg-icon">
              <IconLocation stroke="#FFFFFF" size="24" />
            </span>
            {selectedStore?.manualAddress}
          </li>
        )}
      </ul>
      {showLinks && (
        <React.Fragment>
          <div className="ft-head mt-6 font-nunito">{t("Links")}</div>
          <ul>
            {pages &&
              Object.entries(pages)
                .filter((key) => key[1]?.isActive)
                .map((key) => (
                  <li key={key[0]} onClick={() => gotoMyPage(key)}>
                    <span className="cursor-pointer">
                      {currentLang === "EN"
                        ? key[1]?.linkTitle?.en
                        : key[1]?.linkTitle?.ar}
                    </span>
                  </li>
                ))}
          </ul>
        </React.Fragment>
      )}

      <div className="ft-head mt-6 font-nunito">{t("Accepted Cards")}</div>
      <ul className="flex">
        {paymentOptions?.map((pay) => (
          <li key={pay.alt}>
            <img
              alt={pay.alt}
              src={pay.src}
              className="inline-block mx-2"
              width={50}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Footer;
