import React from "react";
function UNCHECKED({ onClick }) {
  return (
    <svg
      onClick={onClick ? onClick : () => {}}
      height="24"
      width="24"
      viewBox="0 0 24 24"
      className="text-brand-base-primary"
    >
      <path
        fill="currentColor"
        d="M3.9 3H20.1L21 3.9V20.1L20.1 21H3.9L3 20.1V3.9L3.9 3ZM5 19H19.0001V5H5V19Z"
      ></path>
    </svg>
  );
}

export default UNCHECKED;
