import React from "react";
import { useSelector } from "react-redux";
import { UTILS } from "utils";
import { t } from "i18next";
const Coupons = React.memo(
  ({ discounts = [], selectedStore = null, openDiscountModal }) => {
    const { orderType } = useSelector((state) => state.AppReducer);
    const filteredDiscounts = UTILS.getFilteredCoupons(
      discounts,
      selectedStore,
      orderType
    );
    const couponClass =
      "me-3 coupon border-2 rounded-md border border-dashed border-green-500  cursor-pointer";
    if (discounts?.length <= 0) return null;
    else
      return (
        <div className="coupon-sec flex flex-col">
          <div className="flex flex-row overflow-x-scroll justify-start mx-4 pt-4">
            {filteredDiscounts?.length > 0 ? (
              filteredDiscounts?.map((discount) => (
                <div
                  key={discount?._id}
                  className={couponClass}
                  onClick={() => {
                    openDiscountModal(discount, true);
                  }}
                >
                  <div className="bg-green-100 border-b-2 text-gray-600 border-dashed px-4 border-green-500 py-4 text-center w-100 text-19x font-bold">
                    <div className="min-w-80">
                      {discount?.discountType === "percentage"
                        ? discount?.discountAmount + "%"
                        : discount?.discountAmount + t("AED")}
                    </div>
                    <div>{t("Off")}</div>
                  </div>
                  <div className="block text-green-600 uppercase px-4 text-center text-19x font-bold py-2 ">
                    {discount?.discountCode}
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      );
  }
);

export default Coupons;
