import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import C from "Constants";
import { PaymentCardsAction } from "store/actions";
import CheckoutDotComContainer from "components/payment-gateway/CheckoutDotCom";
import {
  StripeSaveCardComponent,
  CheckoutSaveCardComponent,
} from "./SaveCardComponent";
import StripeForm from "components/payment-gateway/stripe/StripeForm";

function OnlinePaymentTabs({
  integrationsByName,
  captureCheckoutDotComPayment,
  redirectLink,
}) {
  const dispatch = useDispatch();
  const {
    savedPaymentCards,
    selectedCard,
    stripeSavedPaymentCards,
    stripeSelectedCard,
  } = useSelector((state) => state.PaymentCardsReducer);

  const { selectedPayment } = useSelector((state) => state.store);
  const handleTabSelection = (tab) => {
    if (tab === 1) {
      dispatch(
        PaymentCardsAction.setSelectedCard({
          cardInfo: {},
          isStoredCard: false,
        })
      );
    }
    if (tab === 2) {
      if (savedPaymentCards?.length > 0 && !selectedCard?.cardInfo?._id) {
        dispatch(
          PaymentCardsAction.setSelectedCard({
            cardInfo: savedPaymentCards[0],
            isStoredCard: true,
          })
        );
      }
    }
  };

  const handleSripeTabSelection = (tab) => {
    if (tab === 1) {
      dispatch(
        PaymentCardsAction.setstripeSelectedCard({
          cardInfo: {},
          isStoredCard: false,
        })
      );
    }
    if (tab === 2) {
      if (
        stripeSavedPaymentCards?.length > 0 &&
        !stripeSelectedCard?.cardInfo?.id
      ) {
        dispatch(
          PaymentCardsAction.setstripeSelectedCard({
            cardInfo: stripeSavedPaymentCards[0],
            isStoredCard: true,
          })
        );
      }
    }
  };

  if (selectedPayment?.paymentType === C.CREDIT_CARD) {
    if (integrationsByName[C.STRIPE]) {
      if (stripeSavedPaymentCards?.length > 0) {
        return (
          <>
            <div className="flex my-4 text-15x text-center font-semibold mx-2">
              <span
                className="flex-grow  border-b-2  py-2 cursor-pointer px-1 border-brand-base-primary text-brand-base-primary"
                onClick={() => handleSripeTabSelection(1)}
              >
                {t("Add new card")}
              </span>
              <span
                onClick={() => handleSripeTabSelection(2)}
                className="flex-grow  border-b-2  py-2 cursor-pointer px-1
                  border-brand-base-primary text-brand-base-primary"
              >
                {t("Saved cards")}
              </span>
            </div>
            <div className="mt-4">
              <StripeSaveCardComponent />
            </div>
          </>
        );
      } else {
        return <StripeForm />;
      }
    } else if (integrationsByName[C.CHECKOUT_DOT_COM]) {
      if (savedPaymentCards?.length > 0) {
        return (
          <>
            <div className="flex my-4 text-15x text-center font-semibold mx-2">
              <span
                className="flex-grow  border-b-2  py-2 cursor-pointer px-1 border-brand-base-primary text-brand-base-primary
                     border-gray-300"
                onClick={() => handleTabSelection(1)}
              >
                {t("Add new card")}
              </span>
              <span
                onClick={() => handleTabSelection(2)}
                className="flex-grow  border-b-2  py-2 cursor-pointer px-1
                 border-brand-base-primary text-brand-base-primary
                  "
              >
                {t("Saved cards")}
              </span>
            </div>
            <div className="mt-4">
              <CheckoutSaveCardComponent />
            </div>
          </>
        );
      } else {
        return (
          <CheckoutDotComContainer
            capturePayment={(token) =>
              captureCheckoutDotComPayment(token, redirectLink)
            }
          />
        );
      }
    }
  }

  return null;
}

export default OnlinePaymentTabs;
