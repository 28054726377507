import React, { useEffect } from "react";
import { t } from "i18next";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { CartAction } from "store/actions";
import * as ROUTE_CONSTANTS from "routes/constants";
import { useCustomHistory } from "hooks";
import { calculateCartGrossAmount } from "utils/Utils";
import C from "Constants";
import useCurrentUser from "hooks/useCurrentUser";
function BasketFooterOverview() {
  //hooks
  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  //global state
  const { outletBasedSelectedStoreArea } = useSelector((state) => state?.store);
  const { currentUser } = useCurrentUser();

  const { cart, cartTotal } = useSelector((state) => state?.cart);
  const { discountInfo } = useSelector((state) => state?.Discounts);
  const { DomainType, orderType } = useSelector((state) => state.AppReducer);

  //redirect to login page
  const redirectToPage = () => {
    let url = ROUTE_CONSTANTS.OUTLET_CHECKOUT[1];
    let url2 = ROUTE_CONSTANTS.OUTLET_LOGIN[1];
    if (currentUser?.token) {
      router(url, `/${params.domainName}${url}`, DomainType);
    } else {
      router(url2, `/${params.domainName}${url2}`, DomainType);
    }
  };

  useEffect(() => {
    const { itemsGrossAmount, itemsNetPrice, orderGrossAmount } =
      calculateCartGrossAmount(cart, discountInfo);
    dispatch(
      CartAction.updateCartPrice({
        originalPrice: itemsNetPrice,
        amountAfterDiscount: itemsGrossAmount,
        itemsGrossAmount,
        orderGrossAmount,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  //get value
  const getValue = () => {
    const orderValue = outletBasedSelectedStoreArea?.minOrder - cartTotal;
    return orderValue >= 0 ? orderValue?.toFixed(2) : 0;
  };

  const gotoStorePage = () =>
    router(
      `/store/store-detail`,
      `/${params?.domainName}/store/store-detail`,
      DomainType
    );

  return (
    <div className="closed-basket-overview-wrapper w-full">
      {cart?.length > 0 && (
        <span className="w-full block ">
          {outletBasedSelectedStoreArea?.minOrder > cartTotal &&
          orderType?.EN?.name === C.SERVICE_TYPE.DELIVERY ? (
            <button
              onClick={gotoStorePage}
              type="button"
              className="bg-brand-base-primary w-full py-3 rounded px-6 text-brand-text-primary text-16x font-semibold"
            >
              <span className="flex items-center justify-center text-center">
                <div className="flex relative w-full items-center pointer">
                  <span className="my-0 mx-auto">
                    {t("Add")}
                    {getValue()}
                    {t("AED")}
                    {t("more")}
                  </span>
                </div>
              </span>
            </button>
          ) : (
            <button
              type="button"
              onClick={redirectToPage}
              className="bg-brand-base-primary w-full py-3 rounded px-6 text-brand-text-primary text-16x font-semibold"
            >
              <span className="flex items-center justify-center text-center">
                <div className="flex relative w-full items-center pointer">
                  <span className="my-0 mx-auto">{t("Review Order")}</span>
                </div>
              </span>
            </button>
          )}
        </span>
      )}
    </div>
  );
}

export default BasketFooterOverview;
