import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LoginHeader from "components/login/LoginHeader";
import LoginForm from "components/login/LoginForm";
import { useIsLoggedIn } from "hooks";
import { ErrorComponent } from "components";
import * as ROUTE_CONST from "routes/constants";
import { useCustomHistory } from "hooks";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import WithLoading from "components/Hoc/WithLoading";
import { logErrorOnSentry } from "utils/Utils";
let URL = ROUTE_CONST.ECOMMERCE_VERIFICATION[1];

const Login = () => {
  useIsLoggedIn();
  const params = useParams();
  const history = useHistory();
  const router = useCustomHistory();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { registerError } = useSelector((state) => state.errorSlice);

  useEffect(() => {
    try {
      window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } catch (error) {
      if (error) {
        logErrorOnSentry(error);
      }
    }
  }, []);

  const gotoVerification = (state) => {
    router(URL, `/${params.domainName}${URL}`, DomainType, state);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <WithLoading>
      <div className="max-w-400x register-page min-h-app-height my-0 mx-auto py-4 px-5 bg-white">
        <div style={{ color: "white", backgroundColor: "red" }}></div>
        <LoginHeader {...{ goBack }} />
        <LoginForm gotoVerification={gotoVerification} />
        {registerError && (
          <ErrorComponent error={registerError.message ?? registerError} />
        )}
      </div>
    </WithLoading>
  );
};
export default Login;
