import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/Index";
import store from "store/store";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import { persistor } from "store/store";
import "./i18n/i18n";
import { Spinner } from "components";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { ScrollToTop } from "components/ScrollToTop";
import Seo from "components/Seo";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import FallbackUi from "components/ErrorBoundaryFallbackComponent";
import { ErrorBoundary } from "react-error-boundary";
import WithStripe from "components/Hoc/WithStripe";
import WithContextProviders from "components/Hoc/WithContextProviders";
import "moment/locale/ar";

const history = createBrowserHistory();

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <ErrorBoundary
      FallbackComponent={FallbackUi}
      resetKeys={[history.location.pathname]}
    >
      <BrowserRouter history={history}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ReactNotifications isMobile={true} breakpoint={480} />
            <ScrollToTop />
            <Seo />
            <WithStripe>
              <WithContextProviders>
                <App />
              </WithContextProviders>
            </WithStripe>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
