import Button from "components/Button";
import React from "react";
import { t } from "i18next";

function ChangeActionPopup({
  handleClickYes,
  handleClickNo,
  title,
  dynamicText,
}) {
  return (
    <div className="jsutify-center w-full items-center justify-center top-0 left-0 h-app-height modal-mask  overflow-y-auto flex">
      <div className="w-full h-fit mx-4 bg-white">
        <form
          className="py-8 px-4 xs:px-8"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="">
            <p className="mb-6 text-16x text-center">{t(title)}</p>
          </div>
          {dynamicText && (
            <div className="mb-6 text-16x text-center">{dynamicText}</div>
          )}
          <div className="flex justify-center">
            <div className="cursor-pointer" onClick={handleClickNo}>
              <Button
                type="button"
                classes="rounded bg-gray-300 text-gray-800 hover:bg-brand-base-primary mx-1 hover:text-brand-text-primary px-6 py-2 h-12 text-16x text-bold"
                title={t("No")}
              />
            </div>
            <div className="cursor-pointer" onClick={handleClickYes}>
              <div className="flex justify-center bg-brand-base-primary rounded  mx-1 border border-brand-base-primary  text-brand-text-primary px-6 py-2 h-12 text-16x text-bold">
                <Button
                  type="button"
                  classes="text-brand-text-primary text-16x text-bold"
                  title={t("Yes")}
                ></Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangeActionPopup;
