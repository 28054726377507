import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PageHeader from "components/PageHeader";
import ItemsPaymentTotalDetail from "components/OrderHistoryPaymentDetail";
import OrderGeneralDetail from "components/order-detail/OrderGeneralDetails";
import ProductsDetail from "components/order-detail/ProductsDetail";
import { useCustomHistory } from "hooks";
import WithLoading from "components/Hoc/WithLoading";
import useQueryParam from "../useQueryParam";
function OrderDetail(props) {
  const params = useParams();
  const history = useHistory();
  const router = useCustomHistory();
  const queryParam = useQueryParam();

  const [order, setOrder] = useState({});
  const { DomainType } = useSelector((state) => state.AppReducer);


  const goBack = () => {
    router(
      `/dine-in/my-orders`,
      `/${params.domainName}/dine-in/my-orders`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };
  useEffect(() => {
    if (history?.location?.state?.order) {
      const { order } = history?.location?.state;
      setOrder(order);
    } else {
      router(
        `/dine-in/`,
        `/${params.domainName}/dine-in/`,
        DomainType,
        null,
        `?${queryParam}`
      );
    }
  }, []);

  return (
    <WithLoading>
      <div className="text-14x max-460">
        <PageHeader
          pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full relative "
          title={t("Order detail")}
          url={true}
          handleClick={goBack}
        />
        <OrderGeneralDetail order={order} />
        <ProductsDetail order={order} />
        <ItemsPaymentTotalDetail
          orderType={order?.orderType?.type}
          deliveryFee={order?.deliveryFee ?? 0}
          totalOrderPrice={order?.totalOrderPrice ?? 0}
          discountAmount={
            order?.discountApplied?.discountAmountAvailedByCustomer
          }
          totalItemsPrice={order?.itemsTotalPrice ?? 0}
          taxRate={order.taxRate ?? 0}
          loyaltyAmount={order?.loyaltyApplied?.redeemAmount ?? 0}
          page={true}
        />
      </div>
    </WithLoading>
  );
}

export default OrderDetail;
