import { logErrorOnSentry } from "utils/Utils";
import { apiSlice, postConfig } from "../../apiHelpers/ApiSlice";
import * as URL from "../../apiHelpers/api/ApiUrls";
import {
  StoreAction,
  Loader,
  SingleStoreAction,
  BrandAction,
  ErrorAction,
  AppAction,
  DeliveryAddressAction,
  orderTimingAction,
} from "../actions";
import { errorHandler } from "apiHelpers/api/errorHandler";
import C from "Constants";
import i18next from "i18next";
import { resetPaymentType } from "utils/Utils";
import { isStoreIsOpen } from "utils/storeUtils";
import { getOrderTimingType } from "utils/storeUtils";
const updatedShippingMethod = (data) => {
  let isValid = Object.values(data).find(
    (value) => value !== null && value !== undefined && value !== ""
  );
  if (isValid) {
    return isValid;
  }
  return false;
};

const updateContryBasedInfo = (countryBaseRes, dispatch) => {
  let CountryList = countryBaseRes?.countryDeliveryData;
  if (CountryList?.length >= 1) {
    dispatch(SingleStoreAction.setsingleStoreAreas(CountryList));
  } else {
    dispatch(SingleStoreAction.setsingleStoreAreas([]));
    logErrorOnSentry("zero country  added", "info");
  }
};

//
const updateAreaBasedInfo = (areaBaseRes, dispatch) => {
  if (areaBaseRes?.length >= 1) {
    dispatch(SingleStoreAction.setsingleStoreAreas(areaBaseRes));
  } else {
    dispatch(SingleStoreAction.setsingleStoreAreas([]));
    logErrorOnSentry("zero area added", "info");
  }
};

const updateCityBasedInfo = (cityBaseRes, dispatch) => {
  let CityList = cityBaseRes?.cityDeliveryData;
  if (CityList?.length >= 1) {
    dispatch(SingleStoreAction.setsingleStoreAreas(CityList));
  } else {
    dispatch(SingleStoreAction.setsingleStoreAreas([]));
    logErrorOnSentry("zero city added", "info");
  }
};

const handleSuccessResponse = (data, dispatch) => {
  let active_method = updatedShippingMethod(data);
  if (active_method?.methodName) {
    // dispatch(SingleStoreAction.setshippingMethod(active_method.methodName));
    dispatch(AppAction.setShippingMethod(active_method?.methodName));

    if (
      active_method?.methodName === C.SHIPPING_METHODS.COUNTRY_BASED &&
      active_method.countryBaseRes
    ) {
      updateContryBasedInfo(active_method.countryBaseRes, dispatch);
    }
    //city handling
    if (
      active_method?.methodName === C.SHIPPING_METHODS.CITY_BASED &&
      active_method.cityBaseRes
    ) {
      updateCityBasedInfo(active_method.cityBaseRes, dispatch);
    }
    //area handling
    if (
      active_method?.methodName === C.SHIPPING_METHODS.AREA_BASED &&
      active_method.areaBaseRes
    ) {
      updateAreaBasedInfo(active_method.areaBaseRes, dispatch);
    }
  } else {
    dispatch(
      ErrorAction.setError({
        error: i18next.t(C.DEFAULT_ERROR_MSG),
        modal: true,
      })
    );
    dispatch(AppAction.setShippingMethod(null));
    dispatch(SingleStoreAction.setsingleStoreAreas([]));
  }
};
//set default payment method
const setDefaultPaymentMethod = (
  data,
  dispatch,
  selectedPayment,
  orderType
) => {
  if (orderType === "delivery") {
    if (
      data?.data?.storeInfo[0] &&
      data?.data?.storeInfo[0]?.paymentOptions?.length >= 1 &&
      !selectedPayment
    ) {
      const onlinePayment = data?.data?.storeInfo[0]?.paymentOptions?.find(
        (item) => item?.paymentType === C.CREDIT_CARD
      );
      dispatch(StoreAction.setSelectedPaymentMethod(onlinePayment));
    }
    // if selected payment  but that payment is not available in pickup payment option then set null
    if (
      data?.data?.storeInfo[0] &&
      data?.data?.storeInfo[0]?.paymentOptions?.length >= 1 &&
      selectedPayment
    ) {
      const isSelected = data?.data?.storeInfo[0]?.paymentOptions?.find(
        (item) => item?.paymentType === selectedPayment?.paymentType
      );

      if (!isSelected) {
        dispatch(StoreAction.setSelectedPaymentMethod(null));
        resetPaymentType();
      }
    }
  }
  if (orderType === "pickup") {
    if (
      data?.data?.storeInfo[0] &&
      data?.data?.storeInfo[0]?.pickupPaymentOptions?.length >= 1 &&
      !selectedPayment
    ) {
      const onlinePayment =
        data?.data?.storeInfo[0]?.pickupPaymentOptions?.find(
          (item) => item?.paymentType === C.CREDIT_CARD
        );

      dispatch(StoreAction.setSelectedPaymentMethod(onlinePayment));
    }

    // if selected payment  but that payment is not available in pickup payment option then set null
    if (
      data?.data?.storeInfo[0] &&
      data?.data?.storeInfo[0]?.pickupPaymentOptions?.length >= 1 &&
      selectedPayment
    ) {
      const isSelected = data?.data?.storeInfo[0]?.pickupPaymentOptions?.find(
        (item) => item?.paymentType === selectedPayment?.paymentType
      );
      if (!isSelected) {
        dispatch(StoreAction.setSelectedPaymentMethod(null));
        resetPaymentType();
      }
    }
  }
  if (orderType === "dinein") {
    if (
      data?.data?.storeInfo[0] &&
      data?.data?.storeInfo[0]?.dineInPaymentOptions?.length >= 1 &&
      !selectedPayment
    ) {
      const onlinePayment =
        data?.data?.storeInfo[0]?.dineInPaymentOptions?.find(
          (item) => item?.paymentType === C.CREDIT_CARD
        );
      dispatch(StoreAction.setSelectedPaymentMethod(onlinePayment));
    }
    // if selected payment  but that payment is not available in pickup payment option then set null
    if (
      data?.data?.storeInfo[0] &&
      data?.data?.storeInfo[0]?.dineInPaymentOptions?.length >= 1 &&
      selectedPayment
    ) {
      const isSelected = data?.data?.storeInfo[0]?.dineInPaymentOptions?.find(
        (item) => item?.paymentType === selectedPayment?.paymentType
      );
      if (!isSelected) {
        dispatch(StoreAction.setSelectedPaymentMethod(null));
        resetPaymentType();
      }
    }
  }
};

const setORderTiming = (data, orderType, dispatch, selectedTiming) => {
  if (selectedTiming?.TimingType) return;

  if (isStoreIsOpen(data?.data?.storeInfo, orderType?.serviceType)) {
    const orderTiming = getOrderTimingType(
      data?.data?.storeInfo,
      orderType?.serviceType
    );
    dispatch(orderTimingAction.setOrderTiming(orderTiming));
  }
};

const storeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStoresMenu: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_DELIVERY_STORE_MENU,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        const seletedpayment = getState().store.selectedPayment;
        try {
          const { data } = await queryFulfilled;
          const orderType = getState().AppReducer?.orderType;
          const { orderTiming } = getState()?.orderTimingReducer;
          setORderTiming(data, orderType, dispatch, orderTiming);

          if (data && data?.data?.storeMenu) {
            if (data?.data?.storeMenu?.length >= 1) {
              dispatch(
                StoreAction.setSelectedCategory(
                  data.data.storeMenu[0]?.categoryInfo?._id
                )
              );
            }
            setDefaultPaymentMethod(data, dispatch, seletedpayment, "delivery");
          } else {
            dispatch(BrandAction.getBrandMenuFailure(errorHandler(data)));
          }
        } catch (err) {
          dispatch(
            BrandAction.getBrandMenuFailure(errorHandler(err?.error?.data))
          );
        } finally {
          dispatch(Loader.disableLoader());
        }
      },
    }),
    getStoresMenuForPickup: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_STORE_MENU_FOR_PICKUP,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        const seletedpayment = getState().store.selectedPayment;

        try {
          const { data } = await queryFulfilled;
          const orderType = getState().AppReducer?.orderType;
          const { orderTiming } = getState()?.orderTimingReducer;
          setORderTiming(data, orderType, dispatch, orderTiming);
          if (data?.data?.storeMenu) {
            if (data?.data?.storeMenu?.categories?.length >= 1) {
              dispatch(
                StoreAction.setSelectedCategory(
                  data.data.storeMenu?.categories[0]?.categoryId?._id
                )
              );
            }
            setDefaultPaymentMethod(data, dispatch, seletedpayment, "pickup");
          } else {
            dispatch(BrandAction.getBrandMenuFailure(errorHandler(data)));
          }
        } catch (err) {
          dispatch(BrandAction.getBrandMenuFailure(errorHandler(err)));
        }
        dispatch(Loader.disableLoader());
      },
    }),
    getStoresMenuForDineIn: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_STORE_MENU_FOR_DINE_IN,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.enableLoader());
        const seletedpayment = getState().store.selectedPayment;

        try {
          const { data } = await queryFulfilled;
          if (data?.data?.storeMenu) {
            console.log("fine", data?.data?.storeMenu);
            if (data?.data?.storeMenu?.categories?.length >= 1) {
              dispatch(
                StoreAction.setSelectedCategory(
                  data.data.storeMenu?.categories[0]?.categoryId?._id
                )
              );
            }
            setDefaultPaymentMethod(data, dispatch, seletedpayment, "dinein");
          } else {
            dispatch(BrandAction.getBrandMenuFailure(errorHandler(data)));
          }
        } catch (err) {
          dispatch(
            BrandAction.getBrandMenuFailure(errorHandler(err?.error?.data))
          );
        }
        dispatch(Loader.disableLoader());
      },
    }),
    getStoreAreas: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_STORE_DELIVERY_AREAS,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            ErrorAction.setError({
              error: null,
              modal: false,
            })
          );
          if (data?.success === 1 && data?.data) {
            const shippingMethod = getState().AppReducer?.shippingMethod;
            const active_method = updatedShippingMethod(data.data);
            if (active_method !== shippingMethod) {
              dispatch(DeliveryAddressAction.setDeliveryAddress(null));
            }
            handleSuccessResponse(data.data, dispatch);
            // check if shipping method is differnt fom selected shipping method
          } else {
            let error = errorHandler(data);
            dispatch(
              ErrorAction.setError({
                error,
                modal: true,
              })
            );
            // dispatch(SingleStoreAction.setshippingMethod(null));
            dispatch(AppAction.setShippingMethod(null));

            dispatch(SingleStoreAction.setsingleStoreAreas([]));
          }
        } catch (err) {
          let error = errorHandler(err);
          dispatch(
            ErrorAction.setError({
              error,
              modal: true,
            })
          );
          // dispatch(SingleStoreAction.setshippingMethod(null));
          dispatch(AppAction.setShippingMethod(null));
          dispatch(SingleStoreAction.setsingleStoreAreas([]));
        }
      },
    }),
  }),
  overrideExisting: true,
});

export default storeApi;
export const {
  useGetStoresMenuMutation,
  useGetStoresMenuForPickupMutation,
  useGetStoresMenuForDineInMutation,
  useGetStoreAreasMutation,
} = storeApi;
