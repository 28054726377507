import React from "react";
import { useLocation } from "react-router-dom";

import NotFound from "components/NotFound";
const NotFoundComponent = () => {
  let location = useLocation();
  return <NotFound location={location.pathname} />;
};

export default NotFoundComponent;
