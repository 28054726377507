import React from "react";
import moment from "moment";
import { t } from "i18next";
import { useLang } from "hooks";

function OrderList({ orderList, handleOrderDetail }) {
  const { currentLang } = useLang();

  const getOrderDetail = (order) => {
    let state = {
      order,
    };
    handleOrderDetail(state);
  };
  return (
    <React.Fragment>
      {orderList?.orders?.map((order, key) => (
        <div
          onClick={() => {
            getOrderDetail(order);
          }}
          key={order?._id || key}
          className="cursor-pointer basket-item flex justify-between items-start flex-row bg-white border-b boder-gray-200 p-4"
        >
          <div className="flex flex-auto flex-col">
            <div className="text-16x font-semibold">
              {order?.storeInfo?.baseLanguage?.name}
            </div>
            <div>{t(order?.orderStatus)}</div>
            <div>
              <span>
                {currentLang === "AR"
                  ? order?.totalOrderPrice + " " + t("AED")
                  : t("AED") + " " + order?.totalOrderPrice}
              </span>
              &nbsp;-&nbsp;
              <span>
                {moment.unix(order?.orderPlacedTime).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>

          <div className="flex items-center mt-5">
            <svg
              height="24"
              width="24"
              viewBox="0 0 24 24"
              className={`h-18x w-18x transform text-brand-base-primary ${
                currentLang === "AR" ? "rotate-90" : "-rotate-90"
              }`}
            >
              <path
                fill="currentColor"
                d="M4.88398 7.11612L3.11621 8.88389L12.0001 17.7678L20.884 8.88389L19.1162 7.11612L12.0001 14.2322L4.88398 7.11612Z"
              ></path>
            </svg>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}

export default OrderList;
