import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
//components
import ItemHeader from "components/item/ItemHeader";
import ModiferGroups from "components/Modifier/ModiferGroups";
import WithLoading from "components/Hoc/WithLoading";
import Quantity from "components/item/Quantity";
import ItemFooter from "components/item/ItemFooter";
//actions
import { NotPersistAction, ItemAction } from "store/actions";
import { UTILS } from "utils";
//hooks
import { useLang, useCustomHistory, useWindowLocation } from "hooks";
//constants
import C from "Constants";
import useQueryParam from "modules/dine-in/useQueryParam";
import ItemNotFoundError from "components/item/ItemNotFound";
import useGetItemById from "components/item/hooks/useGetItemById";
import useAddItemToBasket from "components/item/hooks/useAddItemToBasket";
import MenuDisabledModal from "features/item/components/MenuDisabledModal";
import { isItemOutOfStock } from "utils/Utils";
import UpdateSetting from "../../../../app/util";
import { ResetAction } from "store/actions";

const Item = () => {
  // Hooks
  const dispatch = useDispatch();
  const params = useParams();
  const { fetchDineStoreMenu } = UpdateSetting();
  const { currentLang } = useLang();
  const queryParam = useQueryParam();
  const router = useCustomHistory();
  const { getItemById } = useGetItemById();
  const { addItemToBasket } = useAddItemToBasket();
  const windowRouter = useWindowLocation();

  // Redux selectors
  const { isLoading } = useSelector((state) => state.loader);
  const { selectedStore, storeMenu } = useSelector((state) => state?.store);
  const { DomainType } = useSelector((state) => state.AppReducer);

  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { selectedItem, error } = useSelector((state) => state?.item);
  // React state
  const [itemSection, setItemSection] = useState([]);
  const [singleItem, setSingleItem] = useState({
    quantity: 1,
    itemSection: [],
    price: selectedItem?.itemInfo?.price || 0,
  });

  useEffect(() => {
    updateLocaleState("price", selectedItem?.itemInfo?.price || 0);
    return () => {
      dispatch(NotPersistAction.setRequiredMissingSections([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem?.itemInfo?.price]);

  const updateLocaleState = (key, val) => {
    setSingleItem((prevSingleItem) => ({
      ...prevSingleItem,
      [key]: val,
    }));
  };

  const decreaseQuantity = () => {
    if (singleItem?.quantity > 1) {
      updateLocaleState("quantity", singleItem.quantity - 1);
    }
  };
  const increaseQuantity = () => {
    updateLocaleState("quantity", singleItem.quantity + 1);
  };

  const addSelectedToBasket = () => {
    addItemToBasket(
      brandInfo,
      selectedItem,
      singleItem,
      itemSection,
      closeItemDetail
    );
  };

  useEffect(() => {
    if (params?.itemId && brandInfo?.brand?._id) {
      getItemById(selectedStore, storeMenu, params, brandInfo);
    }
    updateUtmMedium();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params?.itemId,
    selectedStore?._id,
    brandInfo?.brand?._id,
    params?.catId,
    storeMenu?._id,
  ]);

  // update Utm_Medium
  const updateUtmMedium = () => {
    const app_loaded_through =
      !selectedStore && params?.itemId
        ? C.APP_LOADED_THROUGH.DirectLink
        : C.APP_LOADED_THROUGH.normalFlow;
    dispatch(ItemAction.updateAppLoadedThrough(app_loaded_through));
  };

  const getBasketValue = () =>
    (singleItem?.price * singleItem?.quantity)?.toFixed(2);

  const RenderFooter = () => {
    const commonProps = {
      onButtonClick: closeItemDetail,
      btnText1: t("continue shopping"),
      ...{ selectedItem }, // Include other common props here
    };

    if (
      !selectedStore?._id ||
      !selectedStore?.isOpened ||
      isItemOutOfStock(selectedItem?.itemInfo, selectedStore)
    ) {
      return <ItemFooter {...commonProps} />;
    } else
      return (
        <ItemFooter
          onButtonClick={addSelectedToBasket}
          btnText1={`${t("Add")} ${singleItem?.quantity ?? 1} ${t(
            "to basket"
          )}`}
          btnText2={`${
            currentLang === C.AR
              ? getBasketValue() + " " + t("AED")
              : t("AED") + " " + getBasketValue()
          }`}
          {...{ selectedItem }}
        />
      );
  };

  const closeItemDetail = () => {
    router(
      "/dine-in",
      `/${params.domainName}/dine-in`,
      DomainType,
      { fromItemScreen: true },
      `?${queryParam}`
    );
  };

  const updateMenuActiveStatus = () => {
    dispatch(ItemAction.setMenuActiveStatus(false));
    dispatch(ResetAction.removeOrderData());
    const brandData = {
      id: brandInfo?.brand._id,
    };
    if (brandInfo?.brand?._id) {
      fetchDineStoreMenu(brandData);
    } else {
      windowRouter(
        `/dine-in/?${queryParam}`,
        `/${params.domainName}/dine-in/?${queryParam}`,
        DomainType
      );
    }
    closeItemDetail();
  };
  if (!isLoading && error)
    return <ItemNotFoundError {...{ error }} handleClick={closeItemDetail} />;
  return (
    <WithLoading>
      <div className="flex w-full bg-white flex-col store-page-popup">
        <ItemHeader
          {...{ selectedItem, closeItemDetail }}
          menuType="DINE_IN"
          stripText={UTILS.getStoreAndItemStatusText(
            selectedItem?.itemInfo,
            selectedStore
          )}
        />
        <div className="flex flex-grow overflow-y-auto overflow-x-hidden">
          <div className="w-full">
            <ModiferGroups
              {...{
                selectedItem,
                setItemSection,
                itemSection,
                singleItem,
                setSingleItem,
              }}
              isStoreSelected={true}
            />
            <Quantity {...{ decreaseQuantity, increaseQuantity, singleItem }} />
            <RenderFooter />
          </div>
        </div>
      </div>

      <MenuDisabledModal handleRefresh={updateMenuActiveStatus} />
    </WithLoading>
  );
};

export default Item;
