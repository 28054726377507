import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";

import useGetStoresByLatLng from "service-hooks/useGetStoresByLatLng";
import { useDeliveryAddressContext } from "contexts/userAddressess";
import { useCustomHistory, useWindowLocation } from "hooks";

import {
  DeliveryAddressAction,
  NotPersistAction,
  BrandAction,
} from "store/actions";

import { handleLocalStorage } from "utils/storage";
import { errorNotification, logErrorOnSentry } from "utils/Utils";

import C from "Constants";
import { errorHandler } from "apiHelpers/api/errorHandler";
import { useEventTracker } from "hooks";

function useOpenSavedAddressListModal() {
  const params = useParams();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const windowRouter = useWindowLocation();
  const getStoresByLatLong = useGetStoresByLatLng();

  const { setAddressState, userAddressState } = useDeliveryAddressContext();
  const { orderType, shippingMethod, DomainType } = useSelector(
    (state) => state.AppReducer
  );
  const { brandInfo } = useSelector((state) => state.brandStorageReducer);

  const openAddressListModal = async () => {
    setAddressState("savedAddressListModal", true);
  };
  const logevent = useEventTracker({
    name: "SELECT_ADDRESS_ON_ITEM_PAGE",
    shouldLog: true,
    orderType,
  });
  const fetchStoresBySelectedAddressLatLng = async (address) => {
    logevent("SELECT_ADDRESS_ON_ITEM_PAGE");

    try {
      if (!address?.coordinates?.lat || !address?.coordinates?.lng) {
        errorNotification(
          t("We're sorry, but something went wrong. Please try again")
        );
        logErrorOnSentry(
          "coordinates are not available--useOpenSavedAddressListModal--fetchStoresByLatLng",
          "error",
          JSON.stringify(address)
        );
        return;
      }
      const coordinates = {
        lat: address?.coordinates?.lat,
        lng: address?.coordinates?.lng,
      };
      const response = await getStoresByLatLong(coordinates);
      if (response) {
        setAddress(address);
      }
    } catch (error) {
      errorNotification(errorHandler(error?.error));
    }
  };

  const setAddress = (address) => {
    dispatch(DeliveryAddressAction.setDeliveryAddress(address));
    handleLocalStorage.setItem("deliveryAddress", address);
    setAddressState("savedAddressListModal", false);
  };

  const handleFooterClick = () => {
    if (orderType?.serviceType === C.SERVICE_TYPE.DELIVERY) {
      if (shippingMethod === C.SHIPPING_METHODS.MAPPED_AREA_BASED) {
        if (userAddressState?.userAddresses?.length >= 1) {
          openAddressListModal();
          return;
        }
        router(
          "/address/select-delivery-area",
          `/${params.domainName}/address/select-delivery-area`,
          DomainType
        );
        return;
      } else {
        fetchDeliveryAreas();
      }
    } else if (orderType?.serviceType === C.SERVICE_TYPE.PICKUP) {
      fetchPickupStores();
    } else {
      errorNotification(
        t("We're sorry, but something went wrong. Please try again")
      );
      windowRouter("/", `/${params.domainName}`, DomainType);
      logErrorOnSentry(
        "service type is not defined",
        "error",
        JSON.stringify(orderType)
      );
    }
  };

  const fetchDeliveryAreas = () => {
    dispatch(NotPersistAction.toggleAreaModal(true));
    dispatch(
      BrandAction.getBrandDeliveryAreas({
        brandId: brandInfo?.brand?._id,
        updateMethod: true,
      })
    );
  };
  const fetchPickupStores = () => {
    dispatch(BrandAction.getBrandStores(brandInfo?.brand._id));
    dispatch(NotPersistAction.togglePickupStoreModal(true));
  };

  return {
    openAddressListModal: handleFooterClick,
    setAddress,
    fetchStoresBySelectedAddressLatLng,
  };
}

export default useOpenSavedAddressListModal;
