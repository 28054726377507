// outlet protected routes
export const OUTLET_BASKET = ["/:domainName/store/basket", "/store/basket"];

export const OUTLET_MY_ORDERS = [
  "/:domainName/order/my-orders",
  "/order/my-orders",
];

export const OUTLET_ORDER_DETAIL = [
  "/:domainName/order/order-detail",
  "/order/order-detail",
];
export const OUTLET_CHECKOUT = [
  "/:domainName/store/checkout",
  "/store/checkout",
];

// global routes
export const HOME = ["/:domainName", "/"];

// outlet public routes
export const OUTLET_ITEM = [
  "/:domainName/store/item/:itemId/:catId",
  "/store/item/:itemId/:catId",
];

export const OUTLET_ITEM_IMAGE = [
  "/:domainName/store/item-image",
  "/store/item-image",
];

export const OUTLET_STORE = [
  "/:domainName/store/store-detail",
  "/store/store-detail",
];
// auth routes
export const OUTLET_LOGIN = ["/:domainName/auth/login", "/auth/login"];
export const OUTLET_VERIFICATION = [
  "/:domainName/auth/verify-otp/",
  "/auth/verify-otp",
];
export const OUTLET_SETTINGS = ["/:domainName/auth/settings", "/auth/settings"];
export const OUTLET_TRACK_ORDER = [
  "/:domainName/track-order/:orderId",
  "/track-order/:orderId",
];

// ecommerce public routes

export const ECOMMERCE_ITEM = [
  "/:domainName/item/:itemId/:catId",
  "/item/:itemId/:catId",
];
export const ECOMMERCE_ITEM_IMAGE = [
  "/:domainName/item/item-image",
  "/item/item-image",
];
export const ECOMMERCE_LOGIN = ["/:domainName/auth/login", "/auth/login"];
export const ECOMMERCE_VERIFICATION = [
  "/:domainName/auth/verify-otp/",
  "/auth/verify-otp",
];
export const ECOMMERCE_TRACK_ORDER = [
  "/:domainName/track-order/:orderId",
  "/track-order/:orderId",
];
export const ECOMMERCE_SETTINGS = [
  "/:domainName/auth/settings",
  "/auth/settings",
];

// ecommerce privete
export const ECOMMERCE_MY_ORDERS = [
  "/:domainName/order/my-orders",
  "/order/my-orders",
];
export const ECOMMERCE_ORDER_DETAIL = [
  "/:domainName/order/order-detail",
  "/order/order-detail",
];
export const ECOMMERCE_CHECKOUT = [
  "/:domainName/order/checkout",
  "/order/checkout",
];
export const STATIC_PAGE_LINK = [
  "/:domainName/pages/s/:pageId",
  "/pages/s/:pageId",
];

//payment Link

export const PAYMENT_LINK_HOME = [
  "/:domainName/payment-link/:cartId",
  "/payment-link/:cartId",
];

//delivery address
export const OUTLET_MAP_DELIVERY_ADDRESS = [
  "/:domainName/store/checkout/map-address",
  "/store/checkout/map-address",
];
export const OUTLET_MANUAL_DELIVERY_ADDRESS = [
  "/:domainName/store/checkout/add-delivery-address",
  "/store/checkout/add-delivery-address",
];
export const ECOMMERCE_MAP_DELIVERY_ADDRESS = [
  "/:domainName/checkout/map-address",
  "/checkout/map-address",
];
export const ECOMMERCE_MANUAL_DELIVERY_ADDRESS = [
  "/:domainName/checkout/add-delivery-address",
  "/checkout/add-delivery-address",
];
