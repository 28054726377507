import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  selectedPayment: null,
  outletBasedSelectedStoreArea: null,
  selectedStore: null,
  storeMenu: null,
  selectedCategory: null,
  menuParams: null,
};

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    getStoreInfo: (state) => {
      state.selectedStore = null;
      state.selectedCategory = null;
      state.error = null;
      state.selectedPayment = null;
      state.outletBasedSelectedStoreArea = null;
      state.storeMenu = null;
    },
    setMenuParams: (state, action) => {
      state.menuParams = action.payload;
    },
    setSelectedPaymentMethod: (state, action) => {
      state.selectedPayment = action.payload;
    },

    setSelectedArea: (state, action) => {
      state.outletBasedSelectedStoreArea = action.payload;
    },
    setDistanceBasedAreaName: (state, action) => {
      state.outletBasedSelectedStoreArea.areaName = action.payload;
    },
    setSelectedStore: (state, action) => {
      state.selectedStore = action.payload;
    },
    setMenu: (state, action) => {
      state.storeMenu = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
});

export const {
  setMenuParams,
  setDistanceBasedAreaName,
  getAreaStores,
  getStoreInfo,
  setSelectedArea,
  setSelectedStore,
  setMenu,
  setSelectedPaymentMethod,
  setSelectedCategory,
} = storeSlice.actions;

export default storeSlice.reducer;
